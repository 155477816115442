import React, { useEffect, useState } from "react";
import { Container, CardHoc } from "../../../hoc";
import { TextField } from "../../../molecules";
import {
  fieldIds,
  NavigateTo,
  SidebarMenus,
} from "../../../themes";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { changePassword } from "../../../redux/actions/Common";
import { useDispatch } from "react-redux";

const ChangePaswword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const passSuccess = useSelector((state) => state?.common?.passSuccess);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const breadCrumbItems = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.PROFILE,
      label: t("profile"),
    },
    {
      link: "",
      label: t("change_password"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t('profile'),
  };
  const initialValues = {
    OldPassword: "",
    NewPassword: "",
    ConfirmNewPassword: "",
  };

  const validationSchema = Yup.object().shape({
    OldPassword: Yup.string().required(t('enter_old_password')),
    NewPassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,20}$/,
        t("paswrd_condtn")
      )
      .required(t("plz_entr_new_pass")),
    ConfirmNewPassword: Yup.string()
      .oneOf(
        [Yup.ref("NewPassword"), null],
        t("cnfrm_new_pass_mst_match_wid_new_pass")
      )
      .required(t("enter_confrm_password")),
  });
  const onSubmit = (values) => {
    const payloadForChangePass = {
      password: values?.OldPassword,
      new_password: values?.NewPassword,
    };
    isInternetConnected() &&
      dispatch(changePassword(payloadForChangePass, navigate));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const passwordFormFields = [
    {
      error: formik.errors.OldPassword,
      placeholder: t("old_password"),
      inputTrue: true,
      type: "password",
      id: fieldIds.OLD_PASSWORD,
      fieldLabel: t("old_password"),
      onKeyDownTrue: true,

      value: formik.values.OldPassword.trimLeft(),
    },
    {
      error: formik.errors.NewPassword,
      placeholder: t("new_password"),
      inputTrue: true,
      type: "password",
      id: fieldIds.NEW_PASSWORD,
      fieldLabel: t("new_password"),
      onKeyDownTrue: true,
      inputMaxLength: 20,
      value: formik.values.NewPassword.trimLeft(),
    },
    {
      error: formik.errors.ConfirmNewPassword,
      placeholder: t('cnfrm_new_pass'),
      inputTrue: true,
      type: "password",
      id: fieldIds.CONFIRM_NEW_PASSWORD,
      fieldLabel: t('cnfrm_new_pass'),
      onKeyDownTrue: true,
      inputMaxLength: 20,
      value: formik.values.ConfirmNewPassword.trimLeft(),
    },
  ];

  useEffect(() => {
    document.title = "WosoM";
    if (formik.values.OldPassword == " ") {
      formik.setFieldError("OldPassword", "Please enter old password.");
    }
    if (formik.values.NewPassword == " ") {
      formik.setFieldError("NewPassword", "Please enter new password.");
    }
    if (formik.values.ConfirmNewPassword == " ") {
      formik.setFieldError("ConfirmNewPassword", "Please enter confirm new password.");
    }
  }, [formik.isSubmitting, formik.errors]);
  useEffect(() => {
    Object.keys(formik.errors).forEach(fieldName => {
      formik.setFieldTouched(fieldName)
    });
  }, [lang])
  return (
    <Container
      {...containerProps}
      languageChanged={() => {
        setLang(localStorage.getItem("lang"));
        // it is required to re render this whole component
      }}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <CardHoc cardInnerPadding={"content-e"}>
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
          <h6 className="text-white text-capitalize ps-3 Pb-12">
            {t("change_password")}
          </h6>
          <input
            type="text"
            className="search-bar"
            placeholder={t("search")}
            style={{ visibility: "hidden" }}
          />
        </div>
        <form noValidate onSubmit={formik.handleSubmit}>
          {passwordFormFields.map((items) => {
            return (
              <div className="wrapper_line password_size" key={items}>
                <TextField
                  {...items}
                  errorClassName={
                    localStorage.getItem("lang") == "ar"
                      ? "error ar"
                      : "error en"
                  }
                  labelTitle={items.fieldLabel}
                  onChange={formik.handleChange}
                />
              </div>
            );
          })}
          {/* <Link to={NavigateTo.LOGIN}> */}
          <button
            type="submit"
            className="button-list bottom_button pt-10 focus"
            style={{
              marginLeft: "20px",
              marginBottom: "20px",
            }}
          >
            {t("update")}
          </button>
          {/* </Link> */}
        </form>
      </CardHoc>
    </Container>
  );
};

export default ChangePaswword;
