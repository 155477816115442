import { baseUrl } from "../Env";

const appconstant = {
  //client web live url :http://1.6.98.141:3005/

  // backend server live base url

  imageBaseUrlForLocalStorge: baseUrl, // live baseUrl
  imageBaseUrl: baseUrl, // live baseurl
  pdfBaseUrl: baseUrl,
  baseUrl: baseUrl + "api/", // live server
  /* 
  imageBaseUrlForLocalStorge: "https://api2.beta.wosom.ai", // live baseUrl
  imageBaseUrl: "https://api2.beta.wosom.ai", // live baseurl
  pdfBaseUrl: "https://api2.beta.wosom.ai/",
  baseUrl: "https://api2.beta.wosom.ai/api/",
  */

  // backend server ml5 local base url
  // imageBaseUrlForLocalStorge : "http://192.168.3.164:8000",// 145:3000 piyush sir
  // imageBaseUrl : "http://192.168.3.164:8000",// 145:3000 piyush sir
  // pdfBaseUrl : "http://192.168.3.164:8000/",// 145:3000 piyush sir
  // baseUrl : "http://192.168.3.164:8000/api/", // 145:3000 piyush sir

  /* */

  /* Landing Page Constants */

  /* Hero Section */
  HeroSectionHeader: `Next generation of Artificial Intelligence automation
  `,
  HeroSectionDescription: `Harness the power of multimedia to improve your artificial intelligence models  with WosoM. Use data annotation to identify trends before your competition, create new leads and make sense of marketing performance results, and quantify your next-level objectives more accurately. Since Wosom is a tool available to the general public that doesn’t require deep expertise to use effectively, it is literally bringing AI capacities to everyone!
  ` /* Hero Section */,

  /* Hero Section */

  /* Hero Section */

  /* Application Main Features Section */

  AppplicationMainFeatureHeading: "Application Main Features",
  AppplicationMainFeatureDescription: `WosoM was designed as a multifunctional system with several key 
  features that have widespread application in daily work of many organizations. Here is a detailed overview of 
  each individual category of tasks that can be supported with this state-of-the-art tool:`,

  /* Application Main Features Section */

  /* About Us */
  AboutUsDescription: `WosoM is the first language-specific data annotation tool developed specifically for the Arabic 
  language content with full regard to its unique semantic and syntactic structures.
   It is a comprehensive tool developed to simplify a number of tasks and empower organizations to take full control
    of the massive amounts of relevant information that are routinely collected through various channels and often neglected 
    due to sheer volume. By enabling automated data cleaning and data annotation for several types of content, 
    Wosom.ai liberates data analytics departments while at the same time providing them with powerful features that drive 
    innovation and change across the board. Its narrow focus on Arabic linguistic and cultural space allows this tool to 
    be better optimized, more precise, and more context aware than general purpose data management tools for the global market typically are.`,
  /* About Us */

  /* Contact Us Section */
  Message: "Message",
  /* Contact Us Section */

  /* Footer Section  */
  LeftSideHeader: "Useful Links",
  TermsConditions: "Terms and Conditions",

  RightSideHeader: "Contact Information",
  Contact: "+91 7032895320",
  AdressInfo: "Riyadh, KSA",
  /* Footer Section  */

  /* Landing Page Constants */

  /* */

  /* Privacy Policy */

  PrivacyPolicyDescription1: `Contrary to popular belief, Lorem Ipsum is not simply random
    text. It has roots in a piece of classical Latin literature
    from 45 BC, making it over 2000 years old. Richard McClintock,
    a Latin professor at Hampden-Sydney College in Virginia,
    looked up one of the more obscure Latin words, consectetur,
    from a Lorem Ipsum passage, and going through the cites of the
    word in classical literature, discovered the undoubtable
    source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of
    "de Finibus Bonorum et Malorum" (The Extremes of Good and
    Evil) by Cicero, written in 45 BC. This book is a treatise on
    the theory of ethics, very popular during the Renaissance. The
    first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..",
    comes from a line in section 1.10.32.`,

  PrivacyPolicyDescription2: `The standard chunk of Lorem Ipsum used since the 1500s is
    reproduced below for those interested. Sections 1.10.32 and
    1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are
    also reproduced in their exact original form, accompanied by
    English versions from the 1914 translation by H. Rackham.
    <br></br>
    Contrary to popular belief, Lorem Ipsum is not simply random
    text. It has roots in a piece of classical Latin literature
    from 45 BC, making it over 2000 years old. Richard McClintock,
    a Latin professor at Hampden-Sydney College in Virginia,
    looked up one of the more obscure Latin words, consectetur,
    from a Lorem Ipsum passage, and going through the cites of the
    word in classical literature, discovered the undoubtable
    source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of
    "de Finibus Bonorum et Malorum" (The Extremes of Good and
    Evil) by Cicero, written in 45 BC. This book is a treatise on
    the theory of ethics, very popular during the Renaissance. The
    first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..",
    comes from a line in section 1.10.32.`,

  PrivacyPolicyDescription3: `The standard chunk of Lorem Ipsum used since the 1500s is
    reproduced below for those interested. Sections 1.10.32 and
    1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are
    also reproduced in their exact original form, accompanied by
    English versions from the 1914 translation by H. Rackham.`,

  PrivacyPolicyDescription4: `Contrary to popular belief, Lorem Ipsum is not simply random
    text. It has roots in a piece of classical Latin literature
    from 45 BC, making it over 2000 year eory of ethics, very
    popular during the Renaissance. The first line of Lorem Ipsum,
    "Lorem ipsum dolor sit amet..", comes from a line in section
    1.10.32.`,
  /* Privacy Policy */

  ctayp: "Click to upload your photo.",

  ClickToEdit: "Click to edit the field.",
  /* */
  /* table columns Label  */

  srNo: "Sr. No.",
  title: "Title",

  /* table columns Label  */

  /* Validator card labels */

  tnopaiad: "Total no. of projects allocated in a day",
  tnopciad: "Total no. of projects completed in a day",
  pct: "Projects completed today",
  ppo: "Projects pending overall ",

  /* Validator card labels */

  /* Validator chart labels */

  paiaw: "Projects allocated in a week",
  paiam: "Projects allocated in a month",
  pciaw: "Projects completed in a week",
  pciam: "Projects completed in a month",

  /* Validator chart labels */

  /* owner statistics tab */

  pcitw: "Projects created in this week",
  pcitm: "Projects created in this month",
  pcitq: "Projects created in this quarter",
  pcity: "Projects created in this year",
  pcmitw: "Projects completed in this week",
  pcmitm: "Projects completed in this month",
  pcmitq: "Projects completed in this quarter",
  pcmity: "Projects completed in this year",
  /* owner statistics tab */

  /* owner quick links tab */

  aotpuiap: "Average of task in all projects",
  tnowdoap: "Total no. of workers deployed on all projects",
  tnovdoap: "Total no. of validator deployed on all projects",
  /* owner quick links tab */

  /* Worker card labels */

  ttail1d: "Total Tasks allocated in last 1 day",
  ttcil1d: "Total Tasks completed in last 1 day",
  notct: "Numbers of tasks completed today",
  notpo: "Numbers of tasks pending overall",

  /* Worker card labels */

  /*  Worker chart labels */

  tail1w: "Tasks allocated in last 1 week",
  tail1m: "Tasks allocated in last 1 month",
  tail1y: "Tasks allocated in last 1 year",
  tcil1w: "Tasks completed in last 1 week",
  tcil1m: "Tasks completed in last 1 month",
  tcil1y: "Tasks completed in last 1 year",

  /*  Worker chart labels */

  /* Placeholders  => alt+t */

  Skill_Name_Ph: "Select Name",
  Skill_Category_Ph: "Select Category",
  Skill_Experience_Ph: "Select Experience",
  Skill_Description_Ph: "Enter description",
  Skill_AdditionalInfo_Ph: "Enter additional information",
  selectFromCurrentlyActive: "Select From Currently Active",

  Class_List: "Class List",
  Search: "Search",
  /* Placeholders */

  /* Field Labels => alt+t */
  add_skills: "Add Skills",
  Old_Password: "Old Password",
  New_Password: "New Password",
  Confirm_Password: "Confirm Password",
  Skill_Name_Label: "Name",
  Skill_Category_Label: "Category",
  Skill_Experience_Label: "Experience",
  Skill_Description_Label: "Description",
  Skill_AdditionalInfo_Label: "Additional Information",
  Worker_Skills: "Worker Skills",
  AvailabilityLabel: " Availability",
  /* Field Labels */

  /* Tab Labels */

  WORKER: "Worker",
  PENDING: "Pending",
  COMPLETED: "Completed",
  VALIDATOR: "Validator",
  WORKER_TASKS: "Worker Tasks",
  VALIDATOR_TASKS: "Validator Tasks",

  /* Tab Labels */

  /* Side Bar Labels*/

  Notifications: "Notifications",
  SetPassword: "Set Password",
  Task_Management: "Task Management",

  /* Side Bar Labels*/

  /* Action Dialogues */
  Delete_Group_Confirmation: "Are you sure, you want to delete this group?",
  Accept_Confirmation: "Are you sure, you want to accept this work request?",
  Reject_Confirmation: "Are you sure, you want to reject this work request?",
  Report_Confirmation: "Are you sure, you want to report this user?",
  Registration_Complete: "You are successfully registered with us.",
  Successfully_Registered: "You are successfully registered with us.",
  /* Action Dialogues*/

  /* Table headers */

  Task_Start_Date: "Task Start Date",
  Task_Status: "Task Status",

  /* Table headers */

  /* Bread Crumb Labels */

  Worker_Pending_Task_Details: "Worker Pending Task Details",
  Worker_Completed_Task_Details: "Worker Completed Task Details",
  Validator_Pending_Task_Details: "Validator Pending Task Details",
  Validator_Completed_Task_Details: "Validator Completed Task Details",
  /* Bread Crumb Labels */

  /* button Labels */
  Save: "Save",
  Start_Task: "Start Task",
  CreateAccount: "Create Account",
  LearnMore: "Learn More",
  RaiseDispute: "Raise Dispute",
  /* button Labels */

  ResetPassword: "Reset Password",
  Reset: "Reset",
  OK: "Ok",
  Yes: "Yes",
  No: "No",
  Skip: "Skip",
  Login: "Login",
  Gender: "Gender",
  Register: "Register",
  Firstname: "firstName",
  Education: "Education",
  SkillsAdd: "Add Skills",
  Availability: "30 hours",
  Phd_Degree: "PHD Degree",
  Address: "6 no. street...",
  AddressLabel: "Address",
  LanguagesLabel: "Languages",
  ContactNum: "Contact Number",
  ForgotPassword: "Forgot Password",
  ConfirmPassword: "Confirm Password",
  ProfileCompletion: "Profile Completion",
  Citizenship: "Citizenship",
  DummyEd: "Primary Eductation",
  Primary: "Primary",
  Master_Degree: "Master Degree",
  Secondary: "Secondary",
  Bachelor_Degree: "Bachelor Degree",
  Languages: "English,Spanish,German",
  Device_Available: "Device Available",
  pastProjectDetails: "Past Project Details",
  Oldpassword: "Old Password",
  viewmessage: "View Message",
  GroupMessages: "Group Messages ",
  IndividualMessages: "Individual Messages",
  export: "Export",
  AddedWorkers: "Added workers will appear here",
  AddedOwner: "Added owners will appear here",
  AddedUsers: "Added users will appear here",
  AddedTask: "Added tasks will appear here",
  AddedProjects: "Added projects will appear here",
  AddedGroup: "Added groups will appear here",
  ConfrmPassword: "Confrm Password",

  UserDetails: "User Details",
  gamesplayed: "games played",
  DateofJoining: "Date of Joining",
  GameOrganized: "Game Organized",
  edituserdetails: "Edit User Details",
  voucheruserdetails: "Voucher Code Management",
  addnewvoucher: "Add New Voucher",
  AddNewgroup: "Add New Group",
  CurrentlyHosting: "Currently Hosting",

  //
  prelistedwords: "Pre-Listed Words",
  gamemanagement: "Game Management",

  //
  EditAboutUs: "Edit About Us",
  EditPrivacyPolicy: "Edit Privacy Policy",
  AboutUs: "About Us",
  GameSettings: "Game Settings",
  ContactInfo: "Contact Info",
  FAQs: "FAQs",
  EditContactInfo: "Edit Contact Info",
  AddNewFAQ: "Add New FAQ",
  phonenumber: "Phone Number",
  submit: "Submit",
  GamePlayed: "Game Played",

  //
  buttonupate: "Update",
  Statistics: "Statistics",
  QuickLinks: "Quick Links",
  AddGroup: "Add Group",
  poolofWorker: "Select from pool of worker",
  AddworkerthroughEmail: "Add Worker Through Email",
  ProjectStartDate: "Project Start Date",
  sourcetype: "Source Type",
  Annotations: "Annotations",
  description: "Description",
  Instruction: "Instruction",
  SelectGroups: "Select Groups",
  Pleaseenterdescription: "Please enter description",
  Selectfrompoolofvalidator: "Select from pool of validator",
  taskfile: "Task File",
  ChangeValidator: "Change Validator",
  RemoveValidator: "Remove Validator",
  ReportValidator: "Report Validator",
  ViewAnnotations: "View Annotations",
  AddWorker: "Add Worker",
  report: "Are you sure, you want to report this user?",
  disputedetails: "Dispute Annotation Details",
  FileImage: "File Image ",
  AnnotationName: "Annotation Name",
  dummyannotation: "Dummy annotation",
  image: "Image",
  ValidatorComments: "Validator Comments",
  WorkerDetails: "Worker Details",
  Workername: "Worker Name",
  Workermessage: "Worker Message",
  pastDetails: "Past Project Details",
  completiondetails: "Project Completion Date",
  TaskDescription: "Task Description",
  TaskCompletionDate: "Task Completion Date",
  AllocatedTasks: "Allocated Tasks",
  GroupDetials: "Group Details",
  allocted: "Allocated Task Name",
  taskstatus: "Task Status",
  RateDeveloper: "Rate Validator",
  done: "Done",
  FirstName: "First Name",
  LastName: "Last Name",
  Logout: "Logout",
  editprofile: "Edit Profile",
  Verify: "Verify",
  Success: "Success",
  ImportClassFile: "Import Class File",
  removeuser: "Are you sure, you want to remove this user?",
  Add_text: "Add validator through email address",
  reportone: "Report",
  removetask: "Are you sure, you want to remove this task?",

  // Admin Module constants compare and remove
  changepassword: "Change Password",
  NewPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",
  contactus: "Contact us",
  home: "Dashboard",
  delete: "Delete",
  GroupManagement: "Group Management",
  view: "View",
  edit: "Edit",
  block: "Block",
  update: "Update",
  add: "Add",
  Password: "Password",
  editgroup: "Edit Group",

  Groupname: "Group Name",
  UserName: "User Name",
  //
  PrivacyPolicy: "Privacy Policy",
  cancel: "Cancel",

  //

  PastProjects: "Past Projects",
  OngoingProjects: "Ongoing Projects",
  Projects: "Projects",
  DisputeAnnotaions: "Dispute Annotations",
  ProjectName: "Project Name",
  TotalNoOfTasks: "Total No. Of Tasks",
  completedtasks: "Total No. Of Tasks Completed",
  taskspending: "Total No. Of Tasks Pending",
  Addtask: "Add Task",
  ongoingText: "Ongoing Project Details",
  GroupMembers: "Group Members",
  CreateNewProject: "Create New Project",
  TaskList: "Task List",
  InvitationList: "Invitation List",
  ProfileImage: "Profile Image",
  WorkersList: "Workers List",
  remove: "Remove",
  TaskName: "Task Name",
  questions: "Questions",
  tasktype: "Task Type",
  texttype: "Text Type",
  classname: "Class Name",
  SelectValidatorforTask: "Select Validator for Task",
  AddQuestions: "Add Questions",
  AddQuestion: "Add Question",
  AddAnswers: "Add Answers",
  AnswerLists: "Answer Lists",
  QuestionLists: "Question Lists",
  AddQuestionsForTask: "Add Questions for task",
  VList: "Validators List",
  poolofvalidator: "Pool of Validator",
  Taskdescription: "Task Description",
  ValidatorName: "Validator Name",
  Report: "Report",
  AnnotationsList: "Annotations List",
  AddWorkers: "Add Workers",
  TaskDetails: "Task Details",
  Accept: "Accept",
  Reject: "Reject",
  workerdata: "Are you sure, you want to accept dispute request?",
  Confirmation: "Confirmation",
  GroupAllocatedList: "Group Allocated List",
  ReportedWorker: "Reported Worker",
  Action: "Action",
  name: "Name",
  NameOfGroups: "Name of Groups",
  status: "Status",
  type: "Type",
  GroupName: "Group Name",
  completedtasksd: "Complete Task Details",
  workerlist: "Worker List",
  Rating: "Rating",
  ExportData: "Export Data",
  SelectFileFormat: "Select file format",
  ContactNumber: "Contact Number",
  EmailAddress: "Email Address",
  Profile: "Profile",
  Send: "Send",
  sentdata:
    "Reset password link has been successfully sent to your registered email address.",
  successResetPass: "Your password is reset successfully.",

  UserManagement: "User Management",
  ProjectManagement: "Project Management",
  OwnerDetails: "Owner Details",
  OwnerManagement: "Owner Management",
  OwnerName: "Owner Name",
  EditOwnerDetails: "Edit Owner Details",
  AddNewOwner: "Add New Owner",
  owner_block: "Are you sure, you want to block this owner?",
  owner: "Are you sure, you want to delete this owner?",
  userType: "User Type",
  LanguageSpoken: "Language Spoken",
  EducationLevel: "Education Level",
  Skills: "Skills",
  NumberofReports: "Number of Reports",
  userblock: "Are you sure, you want to block this user?",
  deleteuser: "Are you sure, you want to delete this user?",
  ProjectDetails: "Project Details",
  ProjectCompletionDate: "Project Completion Date",
  NoTasks: "No. of Tasks",
  Pending: "Total No. of Tasks Pending",
  projectdelete: "Are you sure, you want to delete this project?",
  ValidatorDetails: "Validator Details",
  deletetask: "Are you sure, you want to delete this task?",
  overallrating: "Overall Rating",
  // Admin Module constants compare and remove
};

export const fieldIds = {
  NEW_PASSWORD: "NewPassword",
  OWNER_NAME: "ownerName",
  OWNER_FIRST_NAME: "ownerFirstName",
  OWNER_LAST_NAME: "ownerLastName",
  VALIDATORNAME: "validatorName",
  /* alt+t */
  EMAIL: "email",
  GENDER: "gender",
  EDUCATION: "education",
  SKILLS: "Skills",
  ADDRESS: "address",
  CATEGORY: "Category",
  PRIMARY: "primaryEd",
  WORKERSKILLS: "workerSkills",
  LASTNAME: "lastName",
  PASSWORD: "password",
  LANGUAGES: "languages",
  FIRSTNAME: "firstName",
  EDUCATION_LEVEL: "educationLevel",
  SECONDARY: "secondaryEd",
  EPERIENCE: "Eperience",
  PHD_DEGREE: "phdEd",
  SKILL_NAME: "SkillName",
  CONTACTNUM: "contactNum",
  CITIZENSHIP: "citizenship",
  DESCRIPTION: "Description",
  AVAILABILITY: "availability",
  MASTER_DEGREE: "masterEd",
  BACHELOR_DEGREE: "bachelorEd",
  DEVICE_AVAILABLE: "deviceAvailable",
  CONFIRM_PASSWORD: "confirmPassword",
  CONFIRM_NEW_PASSWORD: "ConfirmNewPassword",
  OLD_PASSWORD: "OldPassword",
  RATING: "Rating",
};
export const textAreaSize = {
  rows: "50",
  columns: "4",
};
export default appconstant;
