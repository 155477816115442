import images from "../../../themes/appImage";
import React, { useEffect, useState } from "react";
import { HeaderLandingInner } from "../../../layout/LandingModule";
import { CustomFooter } from "../../../molecules";

const Features = () => {
  const sticky = false;
  const [toogled, setToogle] = useState(false);
  const isVisible = false;

  const handletoogle = () => {
    setToogle(!toogled);
  };
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="landing-page back-pic">
      <HeaderLandingInner
        handle={handletoogle}
        toogled={toogled}
        sticky={sticky}
      />

      <section className="aboutus data_about_us privacy_details">
        <div className="container">
          <div className="row align-item-center">
            <h2
              className="heading  slideInUp wow"
              style={{ marginTop: "69px" }}
            >
              <img src={images.leftimg12}></img>
              Application Main Features
              <img src={images.rightimg12} />
            </h2>
            <div className="">
              <div className="d-flex w-100 justify-content-center appss">
                {/* <div className="mobile_circle ser-width">
                  <img src={images.privacypolicyimg} />
                </div> */}
                <div className="mobile_circle ser-width">
                  <div
                    className="para-parent"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="para"

                    >
                      &nbsp;&nbsp;&nbsp;
                      <span style={{ fontSize: "45px" }}>W</span>
                      osoM was designed as a multifunctional system with several
                      key features that have widespread application in daily
                      work of many organizations. Here is a detailed overview of
                      each individual category of tasks that can be supported
                      with this state-of-the-art tool:
                    </p>
                    <p></p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "-32px",
                    }}
                  >
                    <img src="/static/media/privacy-policy.ba072408.png" />
                  </div>
                </div>
              </div>

              <div className="text-center slideInDown home_trrr">
                <h4>Data annotation</h4>

                <p>
                  Based on the advanced technological procedures including
                  computer vision (CV) and natural language processing (NLP),
                  WosoM can perform several crucial data annotation tasks.
                </p>

                <h4>Object detection</h4>

                <p>
                  Using CV capacities, it is possible to recognize objects of
                  greatly variable scale, from very large such as buildings or
                  terrain features to very small such as people, vehicles, or
                  even food items. Another possibility is to look for minute
                  variance in images of the same object, for example identifying
                  damaged items in a large series. This service is very
                  effective even when the size of the database is immense.
                </p>
                <h4>Entity extraction</h4>

                <p>
                  Analysis of semantic rules of the Arabic language allows for
                  very precise identification of named entities in a written or
                  spoken format. WosoM is also capable of tracking subtle
                  relations between multiple named entities that consist of one
                  or more words, and deducing contextual clues based on
                  associations and intersections of words.
                </p>

                <h4>Classification (text or image)</h4>

                <p>
                  Regardless of the medium, WosoM is able to verify and
                  categorize items based on a predefined set of criteria, with
                  an unlimited number of classes. In case of images, label
                  recognition and map geotagging options are available. Text
                  classification is frequently used to sort user responses such
                  as opinions or emotions, or to automate topic identification
                  in large collections of documents.
                </p>

                <h4>Transcription</h4>
                <p>
                  This function can be used to facilitate several types of text
                  conversions useful in everyday operations. In addition to
                  AI-assisted automated translations, WosoM can also perform
                  speech-to-text and text-to-speech operations, thus improving
                  the capacity of organizations based in the Arabic world to
                  communicate with partners and customers both locally and
                  internationally.
                </p>
                <h4>Data cleaning</h4>
                <p>
                  There are several different options at clients’ disposal for
                  this function. It’s possible to perform all routine data
                  cleansing tasks through the WosoM app, or to request
                  customized service on a project basis. Several automation
                  methodologies are used to perform this task in the most
                  optimal manner and produce trimmed down datasets that feature
                  high concentration of valuable data.
                </p>
                <h4>Data management</h4>
                <p>
                  By removing duplicate or low-value data and delivering pruned
                  and downsized collections of properly formatted data, WosoM
                  streamlines complex data management tasks. Following or
                  managing ongoing projects that rely on data processing is
                  easier with real time notifications and reminders, while
                  acting on new information requires just a well-timed command
                  on the part of the user.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CustomFooter />

      {isVisible && (
        <div onClick={scrollToTop}>
          <a href="#" className="scrollup"></a>
        </div>
      )}
    </div>
  );
};

export default Features;
