import React, { useEffect, useState } from "react";
import { Modal, Pagination, Table, Tooltip } from "antd";
import { NavigateTo } from "../../../../themes";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn";
import {
  acceptRejectNotification,
  getUserNotification,
} from "../../../../redux/actions/Notification";
import { useDispatch } from "react-redux";
import { CustomEmptyDiv } from "../../../../molecules";
import { toast } from "react-toastify";
import moment from 'moment'
const TabScreen = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { user = "worker" } = { ...props };

  const [ids, setIds] = useState({
    noti_id: "",
    task_id: "",
  });
  const [pageNum, setPageNum] = useState(1);
  const [data_source_worker, setData_source_worker] = useState([]);
  const [data_source_validator, setData_source_validator] = useState([]);
  const [totalNtoifications, setTotalNtoifications] = useState(0); // total workers and validators
  const [showAcceptRejectModal, setShowAcceptRejectModal] = useState([
    false,
    "",
  ]); //second element is for label

  const buttonActionClicks = (action, noti_id, task_id, type_approved) => {
    switch (action) {
      case t('acpt'): {
        setIds({
          noti_id,
          task_id,
        });
        setShowAcceptRejectModal([true, t("acpt")]);
        break;
      }
      case t('reject'): {
        setIds({
          noti_id,
          task_id,
        });
        setShowAcceptRejectModal([true, t("reject")]);
        break;
      }
      case t('view'): {
        if (type_approved == 1) {
          // completed case / accepted annotation case
          localStorage.setItem("workerCompletedTaskId", task_id);
          navigation(NavigateTo.WORKER_COMPLETED_TASK_DETAILS + `/${task_id}`);
        }
        if (type_approved == 0) {
          // pending case / rejected annotation case
          localStorage.setItem("workerPendingTaskId", task_id);
          navigation(NavigateTo.WORKER_PENDING_TASK_DETAILS + `/${task_id}`);
        }
        break;
      }
      default:
        break;
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "srno",
      key: "srno",
      width: 100,
      render: (value, item, index) => pageNum * 10 - 10 + (index + 1),
    },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
      width: "40%",
      sorter: true,
      render: (value) => {
        return <Tooltip title={value} placement="topLeft">
          {value}
        </Tooltip>
      }
    },
    {
      title: t("date"),
      dataIndex: "created_at",
      key: "created_at",
      width: 50,
      sorter: {
        compare: (a, b) => a.created_at - b.created_at,
        multiple: 3,
      },
      render: (value) => {
        return <Tooltip title={value} placement="topLeft">
          {
            moment(value).format('MMM D - HH:mm a')
          }
        </Tooltip>
      }
    },
    {
      title: t("action"),
      dataIndex: "actions",
      key: "actions",
      width: 50,
      render: (actions, record) => {
        const tempactions = record?.showButtons
          ? [t("acpt"), t("reject")] // accept/reject proj invitation
          : record?.type_approved == 0 || record?.type_approved == 1
            ? [t("view")] // view rejected / accepted annotations
            : record?.showButtons; // accepted / rejected text  

        return (
          <div className="button_duplicate" style={{ marginRight: 30 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: Array.isArray(tempactions) && tempactions.length == 1 ? '78%' : null,
                justifyContent: Array.isArray(tempactions) && tempactions.length == 1 ? 'center' : null
              }}
            >
              {Array.isArray(tempactions) ? (
                tempactions.map((action) => {
                  return (
                    <button
                      key={action}
                      className="focus"
                      type="submit"
                      style={{
                        width: "20px",
                      }}
                      onClick={() => {
                        buttonActionClicks(
                          action,
                          record?.notification_id,
                          record?.task_id,
                          record?.type_approved
                        );
                      }}
                    >
                      {t(action)}
                    </button>
                  );
                })
              ) : record.accepted ? (
                <div
                  style={{
                    height: "20px",
                    margin: '0px 39px'
                  }}
                >
                  <p>{t('accepted')}</p>
                </div>
              ) : tempactions == 0 ? (
                <div
                  style={{
                    height: "20px",
                    margin: '0px 39px'
                  }}
                >
                  <p>{t('rejected')}</p>
                </div>
              ) : null}
            </div>
          </div>
        );
      },
    },
  ];
  function fillNotifications(res) {
    const tempForWorker = [];
    const tempForValidator = [];
    res.map((item, index) => {
      if (item.notification_type === "invite") {
        // for worker
        tempForWorker.push({
          key: index,
          created_at: item?.created_at,
          title: t("u_invited_to_proj") + " " + item?.invite?.task_name + " " + t("as_a") + " " + item?.invite?.invite_type,
          notification_id: item?.invite?.id,
          task_id: item?.invite?.task_id,
          accepted: item?.invite?.is_accepted == "accepted" ? true : false,
          showButtons: item?.invite?.is_accepted == "pending",
          actions:
            item?.invite?.is_accepted == "pending"
              ? [t('acpt'), t('reject')] // accept/reject proj invitation
              : item?.invite?.is_accepted, // accepted / rejected text
          type_approved: item?.type_approved,
        });
      }
      if (item.notification_type == "assignment") {
        // for validator
        tempForWorker.push({
          key: index,
          created_at: item?.created_at,
          title: item?.description,
          notification_id: item?.id,
          task_id: item?.task_id,
          accepted: true,
          showButtons: false,
          actions: "",
          type_approved: item?.type_approved,
        });
      }
    });
    setData_source_worker(tempForWorker);
    setData_source_validator(tempForValidator);
    setTotalNtoifications(res?.count);
  }
  const payloadNotification = {
    body: {
      pageNum,
      type: user == "worker" ? 1 : 2,
    },
    success: (res) => {
      fillNotifications(res);
    },
    // fail: (err) => { },
  };
  const acceptRejectNotificationApi = (isAccept) => {
    const payload = {
      body: {
        task_id: ids?.task_id,
        is_accepted: isAccept,
        notificationId: ids?.noti_id,
      },
      success: (res) => {
        isInternetConnected() &&
          dispatch(getUserNotification(payloadNotification));
        toast.success(res?.data?.message, {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      },
      fail: (resf) => {
        toast.error(resf?.data, {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      },
    };
    isInternetConnected() && dispatch(acceptRejectNotification(payload));
  };

  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [showAcceptRejectModal[0]]);

  useEffect(() => {
    isInternetConnected() && dispatch(getUserNotification(payloadNotification));
  }, []);

  return (
    <div className="form-group">
      <div className="inner_box_box">
        {user == "worker" ? (
          data_source_worker.length ? (
            <div id={"noti-table-parent"}>
              <Table
                dataSource={data_source_worker}
                columns={columns}
                className="worker-pending-table user-notification"
                showSorterTooltip={false}
                pagination={false}
              />
              <Pagination
                showTitle={false}
                style={{
                  // paddingTop: "30px",
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  maxWidth: "fit-content",
                  right: "33px",
                  left: "auto",
                  position: "absolute",
                }}
                className="pagination-component"
                defaultCurrent={1}
                total={totalNtoifications}
                size="medium"
                onChange={(item) => {
                  setPageNum(item);
                }}
              />
            </div>
          ) : (
            <CustomEmptyDiv
              title={t('noti_appear_here')}
              marginLeft={15}
              marginRight={15}
              marginBottom={2}
            />
          )
        ) : null}
        {user == "validator" ? (
          data_source_validator.length > 0 ? (
            <>
              <Table
                dataSource={data_source_validator}
                columns={columns}
                className="worker-pending-table user-notification"
                showSorterTooltip={false}
                pagination={false}
              />
              <Pagination
                showTitle={false}
                style={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  maxWidth: "fit-content",
                  right: "33px",
                  left: "auto",
                  position: "absolute",
                }}
                className="pagination-component"
                defaultCurrent={1}
                total={totalNtoifications}
                size="medium"
                onChange={(item) => {
                  setPageNum(item);
                }}
              />
            </>
          ) : (
            <CustomEmptyDiv
              title={t("noti_appear_here")}
              marginLeft={15}
              marginRight={15}
              marginBottom={2}
            />
          )
        ) : null}

        {/* <AcceptRejectModal /> */}
        <Modal
          title={t("confirmation")}
          open={showAcceptRejectModal[0]}
          onOk={() => {
            setShowAcceptRejectModal([false, ""]);
          }}
          onCancel={() => {
            setShowAcceptRejectModal([false, ""]);
          }}
          footer={null}
          centered
        >
          <div className="text-center  model1">
            <p>
              {showAcceptRejectModal[1] == t("acpt")
                ? t("u_sure_to_acpt_wrk_req")
                : t("u_sure_to_rej_wrk_req")}
            </p>
          </div>
          <div
            className="text-center button_d_inline model1"
            style={{
              direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
            }}
          >
            <button
              type="button"
              className="button-list top_new focus"
              onClick={() => {
                setShowAcceptRejectModal([false, ""]);
                showAcceptRejectModal[1] == t("acpt")
                  ? acceptRejectNotificationApi(true)
                  : acceptRejectNotificationApi(false);
              }}
            >
              {showAcceptRejectModal[1] == t("acpt")
                ? t('acpt')
                : t('reject')}
            </button>
            <button
              type="button"
              className="focus"
              onClick={() => {
                setShowAcceptRejectModal([false, ""]);
              }}
            >
              {t("cancel")}
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default TabScreen;
