import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { saveAnnotations } from "../../../../../../redux/actions/Annotations";
import { getDocument } from "../../../../../../redux/actions/Tasks";
import {
  stopLoading,
  startLoading,
} from "../../../../../../redux/actions/Common";
import Wavesurfer from "wavesurfer.js";
import { isInternetConnected } from "../../../../../../themes/utils/internetConnectionn";
import { Select, Modal, Input, Form, Switch, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { CheckOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackward,
  faForward,
  faPause,
  faPlay,
  faChevronLeft,
  faChevronRight,
  faMinus,
  faPlus,
  faRedo
} from '@fortawesome/free-solid-svg-icons';
import { SettingOutlined, InfoCircleOutlined, SaveOutlined } from "@ant-design/icons";
import parse from "html-react-parser";


const AudioTranscript = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const arabicRegex = /[\u0600-\u06FF]/;
  let limit = 10;
  let waveformRef = useRef(null);
  const waveform = useRef(null);
  const [isPlaying, setPlay] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [isArabic, setIsArabic] = useState(false);
  const [annotField, setannotField] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableDataTotal, setTableDataTotal] = useState(0);
  const [offset, setOffset] = useState(-1);
  const [counter, setCounter] = useState(0);
  const [counterDisplay, setCounterDisplay] = useState(0);
  const [pageNumber, setPageNumber] = useState(counter + 1);
  const [settingsClicked, setSettingsClicked] = useState(false);
  const [infoClicked, setInfoClicked] = useState(false);
  const [autoTextNextPage, setAutoTextNextPage] = useState(
    localStorage.getItem("autoTextNextPage") == "true" ? true : false
  );
  const [saveError, setSaveError] = useState(false);
  const [timeArray, setTimeArray] = useState([]);
  const playAudio = () => {
    // Check if the audio is already playing
    if (waveform.current.isPlaying()) {
      waveform.current.pause();
      setPlay(false);
    } else {
      waveform.current.play();
      setPlay(true);
    }
  };
  const frowardAudio = () => {
    waveform.current.skip(0.5);
  };
  const backwardAudio = () => {
    waveform.current.skip(-0.5);
  };
  const zoomIn = () => {
    //currnet zoom+10
    if (zoomLevel >= 1000) return;
    setZoomLevel(zoomLevel + 100);

    waveform.current.zoom(zoomLevel + 100);
  };
  const resetAll = () => {
    waveform.current.zoom(0);
    waveform.current.seekTo(0);
    waveform.current.setPlaybackRate(1);
  };
  const changeRate = (e) => {
    waveform.current.setPlaybackRate(e, true);
  };
  const zoomOut = () => {
    if (zoomLevel < 0) return;
    setZoomLevel(zoomLevel - 100);
    waveform.current.zoom(zoomLevel - 100);
  };
  const saveAnnotationApiCall = (source) => {

    setSaveError(false);
    // return false
    let data = tableData?.[counter];
    let note = annotField;
    let annotations = [{
      text: note ? note : "",
      metadata: data.jsonFile ? data.jsonFile : [],
    }];

    let allAnswers = true;
    annotations.map((item) => {
      if (!item.text) {
        allAnswers = false;
      }
    });

    if (!allAnswers) {
      setSaveError(true);
      if (source != "change") {
        toast.error(t("write_transcription"), {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
          toastId: "answerAll",
        });
      }
      return true;
    }
    else if (source != "change" || (source == "change" && autoTextNextPage)) {

      let timeArr = { ...timeArray };
      // if counter > length then it is last page use 0

      let cnt = counter >= Object.keys(timeArr).length ? Object.keys(timeArr).length - 1 : counter;
      if (timeArr && timeArr[cnt] && timeArr[cnt].end == 0)
        timeArr[cnt].end = performance.now();
      setTimeArray(timeArr);

      let annotation = {
        document_id: data?.id,
        annotation: {
          document: data?.id,
          annotation: annotations,
          metrics: timeArr[cnt],
          user: data?.annotations?.[0]?.user
        },
      };

      const payloadToSaveAnnotation = {
        body: {
          task_id: localStorage.getItem("workerPendingTaskId"),
          annotation: annotation,
        },
        success: () => {
          if (source == "save") {
            toast.success(t("ann_svd"), {
              className:
                localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
              position:
                localStorage.lang == "ar"
                  ? toast.POSITION.TOP_LEFT
                  : toast.POSITION.TOP_RIGHT,

              toastId: "annotSaved",
            });
          }
          let dt = [...tableData];
          dt[cnt].annotations = [annotation.annotation]
          setTableData(dt);

          setSaveError(false);

        },
        fail: (e) => {
          console.log(e)
          setSaveError(true);
        },
      };

      isInternetConnected() && dispatch(saveAnnotations(payloadToSaveAnnotation))

      return false;
    }
  };
  function fillTable(res, cnt = -1) {
    let dt = res?.documents;
    setTableData(dt);
    setTableDataTotal(res?.total);
    let cntDisplay = cnt > -1 ? cnt : counter;
    setCounterDisplay(res?.offset + cntDisplay);
    setOffset(res?.offset);
    let curr = dt[cntDisplay];
    if (curr?.annotations?.length > 0) {
      let note = curr?.annotations[0]?.annotation[0]?.text;
      setannotField(note);
      setIsArabic(arabicRegex.test(note))
    } else {
      setannotField("");
    }
    if (!waveform.current) {
      waveform.current = Wavesurfer.create({
        barGap: 1,
        height: 50,
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 3,
        barMinHeight: 1,
        responsive: true,
        waveColor: "rgb(52 124 210)",
        container: "#waveform",
        cursorColor: "rgb(31, 71, 118)",
        backend: 'MediaElement',
        // minPxPerSec: 100,
        //hideScrollbar: true,
      });
      waveformRef = waveform.current;
    }
    waveform.current.load(curr?.url);
    waveform.current.on("ready", () => {
      dispatch(stopLoading());
    });
    let timeArr = { ...timeArray };
    timeArr[cntDisplay] == undefined ?
      timeArr[cntDisplay] = {
        start: performance.now(),
        clicks: [],
        idle: [],
        end: 0
      } : timeArr[cntDisplay].start = performance.now();

    setTimeArray(timeArr);
  }

  function changePage(next) {
    let call = false
    let offs = offset;
    let cnt = counter;
    if (next && cnt == 9) {
      setCounter(0);
      cnt = 0;
      offs = offset + limit > tableDataTotal ? offset : offset + limit;
      call = true
    }
    else if (next) {
      setCounter(cnt + 1);
      setCounterDisplay(counterDisplay + 1);
      cnt = cnt + 1;
      let timeArr = { ...timeArray };
      if (!timeArr[counter + 1]) {
        timeArr[counter + 1] = {
          start: performance.now(),
          clicks: [],
          idle: [],
          end: 0
        };
        setTimeArray(timeArr);
      }
    }
    if (!next && cnt == 0) {
      if (offset - limit >= 0) {
        offs = offset - limit;
        setCounter(9);
        cnt = 9;
      } else {
        offs = offset - 1 >= 0 ? offset - 1 : 0;
        setCounter(0);
        cnt = 0;
      }
      call = true
    } else if (!next) {
      setCounter(cnt - 1);
      setCounterDisplay(counterDisplay - 1);
      cnt = cnt - 1;
      let timeArr = { ...timeArray };
      if (!timeArr[counter - 1]) {
        timeArr[counter - 1] = {
          start: performance.now(),
          clicks: [],
          idle: [],
          end: 0
        };
        setTimeArray(timeArr);
      }
    }
    if (call) {
      let payload = {
        body: {
          "offset": offs,
          "limit": limit,
          "task_id": localStorage.getItem("workerPendingTaskId"),
        },
        success: (res) => {
          fillTable(res, cnt)
        }
      }
      isInternetConnected() && dispatch(startLoading())
      isInternetConnected() && dispatch(getDocument(payload))
    } else {
      let curr = tableData[cnt];
      if (curr?.annotations?.length > 0) {
        let note = curr?.annotations[0]?.annotation[0]?.text;
        setIsArabic(arabicRegex.test(note))
        setannotField(note ? note : "");
      } else {
        setannotField("");
      }
      dispatch(startLoading());
      waveform.current.load(curr?.url);
      waveform.current.on("ready", () => {
        setPlay(false);
        waveform.current.seekTo(0);
        dispatch(stopLoading());
      });
    }

  }

  useEffect(() => {
    let payload = {
      body: {
        "offset": offset,
        "limit": limit,
        "task_id": localStorage.getItem("workerPendingTaskId"),
      },
      success: (res) => {
        fillTable(res)
      }
    }
    isInternetConnected() && dispatch(startLoading())
    isInternetConnected() && dispatch(getDocument(payload))
    setCounter(0);
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    toast.error(t('smthng_went_wrong'), {
      className:
        localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
      position:
        localStorage.lang == "ar"
          ? toast.POSITION.TOP_LEFT
          : toast.POSITION.TOP_RIGHT,

      toastId: "annotSaved",
    });
  }, [saveError])
  return (
    (tableData?.length > 0 && (
      <div className="udt_container">
        {/*page number and settings and info */}
        <div
          className="header-sticky"
          style={{
            justifyContent: localStorage.lang == "ar" ? "left" : "right",
            flexDirection: localStorage.lang == "ar" ? "row-reverse" : "row",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              border: "0px",
              display: "flex",
              cursor: "pointer",
              borderRadius: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
              background: "rgb(31 71 118)",
            }}
            onClick={() => {
              setInfoClicked(true);
            }}
          >
            <InfoCircleOutlined style={{ color: "#fff", fontSize: "20px" }} />
          </div>
          <div
            style={{
              width: "40px",
              height: "40px",
              border: "0px",
              display: "flex",
              cursor: "pointer",
              borderRadius: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
              background: "rgb(31 71 118)",
            }}
            onClick={() => {
              setSettingsClicked(true);
            }}
          >
            <SettingOutlined style={{ color: "#fff", fontSize: "20px" }} />
          </div>
          <div
            style={{
              width: "40px",
              height: "40px",
              border: "0px",
              display: "flex",
              cursor: "pointer",
              borderRadius: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
              background: "rgb(31 71 118)",
            }}
            onClick={() => {
              saveAnnotationApiCall("save");
            }}
          >
            <SaveOutlined style={{ color: "#fff", fontSize: "20px" }} />
          </div>
          <div
            style={{
              background: "rgb(31 71 118 / 16%)",
              borderRadius: "10px",
              padding: " 8px",
              fontSize: "14px",
            }}
          >
            {localStorage.lang == "ar" ?
              <>
                <span
                  style={{
                    fontWeight: "800",
                    marginTop: "-2px",
                  }}
                >
                  {tableDataTotal}
                </span> / {counterDisplay + 1}
              </> :
              <>
                <span
                  style={{
                    fontWeight: "800",
                    marginTop: "-2px",
                  }}
                >
                  {counterDisplay + 1}
                </span> / {tableDataTotal}
              </>}

          </div>
          <Tooltip title={t("prev")}>
            <button
              style={{
                marginLeft: "10px",
                width: "60px",
                height: "40px",
                border: "0px",
                display: "flex",
                cursor: "pointer",
                borderRadius: "10px",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
                cursor: counterDisplay > 0 ? "pointer" : "no-drop",
                background: counterDisplay > 0 ? "rgb(31 71 118)" : "#a6b3c1",
              }}
              title={t("prev")}
              onClick={() => changePage(false)}
              disabled={counterDisplay > 0 ? false : true}
            >
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#fff", fontSize: "20px" }} />
            </button>
          </Tooltip>
          <Tooltip title={t("next")}>
            <button
              style={{
                marginLeft: "1px",
                width: "60px",
                height: "40px",
                border: "0px",
                display: "flex",
                cursor: "pointer",
                borderRadius: "10px",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
                background:
                  counterDisplay < tableDataTotal - 1 ? "rgb(31 71 118)" : "#a6b3c1",
                cursor: counterDisplay < tableDataTotal - 1 ? "pointer" : "no-drop",
              }}
              disabled={counterDisplay < tableDataTotal - 1 ? false : true}
              title={t("next")}
              onClick={() => {
                let err = saveAnnotationApiCall("next");
                if (!err && counterDisplay < tableDataTotal - 1) {
                  changePage(true);
                }
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} style={{ color: "#fff", fontSize: "20px" }} />
            </button>
          </Tooltip>
          <Modal
            title={t("settings")}
            open={settingsClicked}
            footer={[]}
            onCancel={() => {
              setSettingsClicked(false);
            }}
          >
            <label
              htmlFor="page"
              style={{
                fontWeight: "500",
                color: "rgb(20, 60, 100)",
              }}
            >
              {t("select_page")}
            </label>
            <Form layout="inline">
              <Input
                placeholder={`${t("select_page")} (${counterDisplay + 1} / ${tableDataTotal})`}
                type="number"
                onChange={(e) => {
                  setPageNumber(e.target.value);
                }}
                name="page"
                min={1}
                max={tableDataTotal}
                size="large"
                style={{
                  width: "80%",
                }}
              //value={currentIndex + 1}
              />
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  border: "0px",
                  display: "flex",
                  cursor: "pointer",
                  borderRadius: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(31 71 118)",
                }}
                onClick={() => {
                  if (pageNumber > 0 && pageNumber <= tableDataTotal) {
                    let payload = {
                      body: {
                        "offset": pageNumber - 1,
                        "limit": limit,
                        "task_id": localStorage.getItem("workerPendingTaskId"),
                      },
                      success: (res) => {
                        fillTable(res)
                        setSettingsClicked(false);
                      }
                    }
                    isInternetConnected() && dispatch(startLoading())
                    isInternetConnected() && dispatch(getDocument(payload))

                  } else {
                    toast.error(t("page_not_found"), {
                      className:
                        localStorage.lang == "ar"
                          ? "Select_data ar"
                          : "Select_data en",
                      position:
                        localStorage.lang == "ar"
                          ? toast.POSITION.TOP_LEFT
                          : toast.POSITION.TOP_RIGHT,

                      toastId: "pageNotFound",
                    });
                  }
                }}
              >
                <CheckOutlined style={{ color: "#fff", fontSize: "20px" }} />
              </div>
              <div
                className="mt-2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <label
                  htmlFor="page"
                  style={{
                    fontWeight: "500",
                    color: "rgb(20, 60, 100)",
                  }}
                >
                  {t("auto_next_page")}
                </label>
                <Switch
                  checked={autoTextNextPage}
                  onChange={(e) => {
                    setAutoTextNextPage(e);
                    localStorage.setItem("autoTextNextPage", e);
                  }}
                />
              </div>
            </Form>
          </Modal>
          <Modal
            title={t("instructions")}
            open={infoClicked}
            footer={[]}
            onCancel={() => {
              setInfoClicked(false);
            }}
            width={1000}
          >
            {/*  instructions  */}
            <div className="form-group">
              <label
                htmlFor="end"
                style={{
                  fontWeight: "500",
                  color: "rgb(20, 60, 100)",
                  fontFamily: 'font-family: "Montserrat", sans-serif',
                }}
              >
                {t("instructions")}
              </label>
              <div
                className="get-original-text-color"
                style={{
                  marginTop: "12px",
                  padding: "20px",
                  minHeight: "500px",
                  fontSize: "calc(12px + 0.5vw)"
                }}
              >
                <div className="ck-content">
                  {parse(localStorage.getItem("instruction"))}
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {/*page number and settings and info end */}
        <div className="player">
          {/* waveform */}
          <div
            id="waveform"
            style={{
              marginTop: 10,
              position: "relative",
              background: "rgb(206 215 232)",
              boxSizing: "border-box",
              borderRadius: "10px 10px 0px 0px",
            }}
            ref={waveformRef}
          />
          <div
            className="mobile-wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* zoom buttons and slider */}
            <div
              className="mobile-hide"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "right",
                background: "rgb(206 215 232)",
                boxSizing: "border-box",
                // bottom corner radius
                borderRadius: "0px 0px 0 10px",
                padding: "10px",
              }}
            >

              {/* minus zoom button */}
              <div
                style={{
                  width: "40px",
                  height: "50px",
                  border: "0px",
                  display: "flex",
                  cursor: "pointer",
                  borderRadius: "10px",
                  alignItems: "center",
                  margin: "1px",
                  justifyContent: "center",
                  background: "rgb(31 71 118)",
                }}
                onClick={resetAll}
              >
                <FontAwesomeIcon icon={faRedo} style={{ color: "#fff" }} />
              </div>

              {/* plus button */}
              <div
                style={{
                  width: "50%",
                  border: "0px",
                  display: "flex",
                  cursor: "pointer",
                  borderRadius: "10px",
                  margin: "1px",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(31 71 118)",
                  padding: "1%"
                }}
              >
                <Select
                  defaultValue="1"
                  className="select_blue"
                  onChange={changeRate}
                >
                  <option title="" color="#fff" className="language-dropdown" value="1">
                    {t("normal_speed")}
                  </option>
                  <option title="" value="0.25">
                    {t("slow_speed")}
                  </option>
                  <option title="" value="0.5">
                    {t("slower_speed")}
                  </option>
                  <option title="" value="1.5">
                    {t("fast_speed")}
                  </option>
                  <option title="" value="2">
                    {t("faster_speed")}
                  </option>
                </Select>
              </div>
            </div>
            {/* zoom buttons and slider */}
            {/* backward,play,forward */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "right",
                background: "rgb(206 215 232)",
                boxSizing: "border-box",
                // bottom corner radius
                borderRadius: "0px 0px 0 0",
                padding: "10px",
              }}
            >

              {/* forward button */}
              <div
                className="mobile-hide"
                style={{
                  width: "50px",
                  height: "50px",
                  border: "0px",
                  display: "flex",
                  cursor: "pointer",
                  borderRadius: "10px",
                  alignItems: "center",
                  margin: "1px",
                  justifyContent: "center",
                  background: "rgb(31 71 118)",
                }}
                onClick={backwardAudio}
              >
                <FontAwesomeIcon icon={faBackward} style={{ color: "#fff" }} />
              </div>
              {/* play pause button */}
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  border: "0px",
                  display: "flex",
                  cursor: "pointer",
                  borderRadius: "10px",
                  alignItems: "center",
                  margin: "1px",
                  justifyContent: "center",
                  background: "rgb(31 71 118)",
                }}
                onClick={playAudio}
              >
                <FontAwesomeIcon
                  icon={isPlaying ? faPause : faPlay}
                  style={{ color: "#fff" }}
                />
              </div>
              {/* backward button */}
              <div
                className="mobile-hide"
                style={{
                  width: "50px",
                  border: "0px",
                  height: "50px",
                  display: "flex",
                  cursor: "pointer",
                  borderRadius: "10px",
                  margin: "1px",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(31 71 118)",
                }}
                onClick={frowardAudio}
              >
                <FontAwesomeIcon icon={faForward} style={{ color: "#fff" }} />
              </div>
            </div>
            {/* backward,play,forward */}

            {/* zoom buttons and slider */}
            <div
              className="mobile-hide"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "right",
                background: "rgb(206 215 232)",
                boxSizing: "border-box",
                // bottom corner radius
                borderRadius: "0px 0px 10px 0px",
                padding: "10px",
              }}
            >

              {/* minus zoom button */}
              <div
                style={{
                  width: "40px",
                  height: "50px",
                  border: "0px",
                  display: "flex",
                  cursor: "pointer",
                  borderRadius: "10px",
                  alignItems: "center",
                  margin: "1px",
                  justifyContent: "center",
                  background: "rgb(31 71 118)",
                }}
                onClick={zoomOut}
              >
                <FontAwesomeIcon icon={faMinus} style={{ color: "#fff" }} />
              </div>

              {/* plus button */}
              <div
                style={{
                  width: "40px",
                  border: "0px",
                  height: "50px",
                  display: "flex",
                  cursor: "pointer",
                  borderRadius: "10px",
                  margin: "1px",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(31 71 118)",
                }}
                onClick={zoomIn}
              >
                <FontAwesomeIcon icon={faPlus} style={{ color: "#fff" }} />
              </div>
            </div>
            {/* zoom buttons and slider */}

          </div>
        </div>
        <div className="mt-2">
          <div className="form-group">
            <label
              htmlFor="note"
              style={{
                fontWeight: "500",
                color: "rgb(20, 60, 100)",
                fontFamily: 'font-family: "Montserrat", sans-serif',
              }}
            >
              {t("transcription")}
            </label>
            <textarea
              id="note"
              rows="3"
              name="note"
              style={{
                marginTop: "12px",
                direction: isArabic == true ? "rtl!important" : "ltr",
                textAlign: isArabic == true ? "right!important" : "left",
              }}
              className="form-control"
              value={annotField}
              onChange={(e) => {
                // if(e.target.value.trimLeft()){
                setIsArabic(arabicRegex.test(e.target.value))
                setannotField(e.target.value);
                // }
              }}
              placeholder={t("entr_wrds_u_hear")}
            ></textarea>
          </div>
        </div>
      </div >
    ))
  );
};

export default AudioTranscript;
