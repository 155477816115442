import { Modal, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AcceptModal, RejectModal } from "..";
import { CustomEmptyDiv } from "../../../../../../molecules";
import { NavigateTo, SidebarMenus } from "../../../../../../themes";

const AnnotationModal = (props) => {
  const {
    // taskId,
    classNames,
    imageArray,
    imageIndex,
    annotDetails,
    projId,
    annotationDS,
    isModalVisible,
    annotationAudioDS,
    annotationTextDS,
    classLists, // segmentation
    getTaskDetails = () => {},
    hideModal = () => {},
  } = { ...props };

  const navigate = useNavigate();
  const { t } = useTranslation();
  const taskId = useSelector((state) => state?.userTaskManagement?.taskId);

  localStorage.removeItem("showTickOrNot");

  const taskType = localStorage.getItem("taskType");
  const [showAccpetModal, setShowAcceptModal] = useState(false); // accept modal
  const [acceptRejectApiBody, setAcceptRejectApiBody] = useState({}); // acceptRejectApiBody
  const [showRejectModal, setShowRejectModal] = useState(false); // reject modal
  const [showCommentModal, setShowCommentModal] = useState(false); // comment modal

  const udtBreadCrumbsEn = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: "Dashboard",
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: "Task Management",
    },
    {
      link: NavigateTo.VALIDATOR_PENDING_TASK_DETAILS + `/${taskId}`,
      label: "Validator Pending Task Details",
    },
    {
      link: "",
      label: "Annotate",
    },
  ];
  const udtBreadCrumbsAr = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: "لوحة القيادة",
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: "ادارة المهام",
    },
    {
      link: NavigateTo.VALIDATOR_PENDING_TASK_DETAILS + `/${taskId}`,
      label: "المدقق تفاصيل المهمة المعلقة",
    },
    {
      link: "",
      label: "علق",
    },
  ];
  const udtContainerProps = {
    breadCrumbItems: {
      en: udtBreadCrumbsEn,
      ar: udtBreadCrumbsAr,
    },
    menuProps: SidebarMenus("user"),
    active: t("task_management"),
  };

  // image table columns
  const annotationC = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      width: 150,
      render: (value, item, index) => index + 1,
    },
    {
      title: t("worker_name"),
      dataIndex: "worker",
      key: "worker",
      width: 100,
      sorter: false,
      render: (value) => {
        return <Tooltip title={value}>{value}</Tooltip>;
      },
    },
    {
      title: t("annot_name"),
      dataIndex: "annotation_name",
      key: "annotation_name",
      width: 200,
      sorter: false,
      render: (value) => {
        return <Tooltip title={value}>{value}</Tooltip>;
      },
    },
    {
      title: t("file_id"),
      dataIndex: "file_id",
      key: "file_id",
      width: "11%",
      sorter: false,
      render: (value) => {
        const tempVal =
          localStorage.lang == "ar"
            ? value
                .split("")
                .reverse()
                .concat("")
            : value;

        return (
          <Tooltip
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
            title={tempVal}
          >
            {tempVal}
          </Tooltip>
        );
      },
    },
    {
      title: t("class"),
      dataIndex: "class",
      key: "class",
      width: 250,
      sorter: true,
      render: (value) => {
        return <Tooltip title={value}>{value}</Tooltip>;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      width: 200,
      sorter: false,
      render: (i, record) => t(record?.statusToShow),
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text, record) => {
        return (
          <div
            style={{
              marginLeft: "12px",
            }}
          >
            {/* view button */}
            <button
              type="button"
              className="focus"
              onClick={() => {
                const body = {
                  id: record?.annotationId, //simple id

                  project_id: projId,
                  comment: null, // blank for accept case

                  task_id: annotDetails?.task_id,
                  worker_id: record?.workerId,
                  validator_id: record?.validator_id,
                  annotation_id: record?.annotation_id,
                };
                let tempRegions = [];
                let tempDetails = { ...record?.annotDetails };
                tempDetails?.regions.map((item) => {
                  if (item?.id.includes(record?.file_id)) {
                    tempRegions.push(item);
                    tempDetails["regions"] = tempRegions;
                    return;
                  }
                });
                localStorage.setItem("annotStatus", record?.status);

                navigate(NavigateTo.USER_UDT + `/${taskId}`, {
                  state: {
                    udtContainerProps,
                    taskType,
                    removeIcos: true,
                    classLists: classNames,
                    images: imageArray,
                    imageIndex: imageIndex,
                    particularAnnotation: tempDetails,
                    fileId: record?.flie_id,
                    status: "Accpeted",
                    showTick:
                      record?.status == "Pending"
                        ? localStorage.setItem("showTickOrNot", true)
                        : localStorage.setItem("showTickOrNot", false),
                    acceptRejectApiBody: body,
                  },
                });
              }}
            >
              {t("view")}
            </button>
          </div>
        );
      },
    },
  ];

  //audio table columns. and text
  const annotationAudioC = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      width: 150,
      render: (value, item, index) => index + 1,
    },
    {
      title: t("worker_name"),
      dataIndex: "worker",
      key: "worker",
      width: 100,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip title={val} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("annot_name"),
      dataIndex: "annotation_name",
      key: "annotation_name",
      width: 200,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip title={val} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      width: 200,
      sorter: false,
      render: (i, record) => t(record?.statusToShow),
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text, record, index) => {
        return (
          <div
            style={{
              marginLeft: "12px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* view button */}
            <button
              type="button"
              className="focus"
              onClick={() => {
                if (localStorage.getItem("taskType") == "Audio") {
                  let singleAudio = [
                    {
                      url: annotDetails?.audioUrl,
                      jsonFile: record?.individualAnnots, //annotDetails?.jsonFile[index]?.Anotation_id?.jsonFile, // jsonFile
                      task_id: 1,
                      anotation_id: 1,
                      worker_id: 1,
                    },
                  ];
                  navigate(NavigateTo.USER_UDT + `/${taskId}`, {
                    state: {
                      taskType,
                      udtContainerProps,
                      audio: singleAudio,
                    },
                  });
                }
                if (localStorage.getItem("taskType") == "Text") {
                  if (localStorage.getItem("textType") == "questions") {
                    navigate(NavigateTo.USER_UDT + `/${taskId}`, {
                      state: {
                        taskType,
                        udtContainerProps,
                        pdfUrl: record?.pdfUrl,
                        questions: record?.questions,
                        particularAnnotation: record?.savedAnnotation,
                        pdfUrl: record?.pdfUrl,
                        qnindex: index,
                      },
                    });
                  }
                  if (localStorage.getItem("textType") == "segmentation") {
                    navigate(NavigateTo.USER_UDT + `/${taskId}`, {
                      state: {
                        taskType,
                        udtContainerProps,
                        particularAnnotation: record?.particularAnnot,
                        segmentAllAnnots: record?.segmentAllAnnots,
                        classes: classLists,
                        paragraph: record?.paragraph,
                      },
                    });
                  }
                }
              }}
            >
              {t("view")}
            </button>

            {record?.status == "Pending" ? (
              <>
                {/* accept button */}
                <button
                  type="button"
                  className="focus"
                  onClick={() => {
                    setAcceptRejectApiBody({
                      id: record?.annotationId, //anotation id
                      status: 2, //2 approve, 3 reject
                      project_id: projId,
                      comment: null, // blank for accept case

                      task_id: annotDetails?.task_id,
                      worker_id: record?.workerId,
                      validator_id: record?.validator_id,
                      annotation_id: record?.annotation_id,
                    });
                    setShowAcceptModal(true);
                  }}
                >
                  {t("approve")}
                </button>

                {/* reject button */}
                <button
                  type="button"
                  className="focus"
                  onClick={() => {
                    setAcceptRejectApiBody({
                      id: record?.annotationId, //anotation id
                      status: 3, //2 approve, 3 reject
                      project_id: projId,

                      task_id: annotDetails?.task_id,
                      worker_id: record?.workerId,
                      validator_id: record?.validator_id,
                      annotation_id: record?.annotation_id,
                    });
                    setShowRejectModal(true);
                    // setCommentValue("");
                  }}
                >
                  {t("reject")}
                </button>
              </>
            ) : null}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isModalVisible]);
  return (
    <>
      {/* annotation modal */}
      <Modal
        title={t("annot_list")}
        centered
        footer={null}
        onOk={() => {
          hideModal(false);
        }}
        width="88%"
        onCancel={() => {
          {
            hideModal(false);
          }
        }}
        open={isModalVisible}
        wrapClassName="poolworkers"
        className={
          localStorage.lang == "ar"
            ? "validator-pending-annot-modal new_layout ar"
            : "validator-pending-annot-modal new_layout en"
        }
      >
        {
          <div
            className={`text-center modal2 remove-left-pad vct-modal  AnnotClassName `}
          >
            <div className="form-group wrapper_line models">
              {annotationAudioDS.length ||
              annotationDS?.length ||
              annotationTextDS?.length ? (
                <Table
                  pagination={true}
                  columns={
                    taskType == "Audio"
                      ? annotationAudioC
                      : taskType == "Image"
                      ? annotationC
                      : annotationAudioC
                  }
                  showSorterTooltip={false}
                  dataSource={
                    taskType == "Audio"
                      ? annotationAudioDS
                      : taskType == "Image"
                      ? annotationDS
                      : annotationTextDS
                  }
                  className="udt worker-completed-taskdetails-annot val-pending-annot-pop-table"
                />
              ) : (
                <CustomEmptyDiv title={t("annots_wil_appear_here")} />
              )}
            </div>
          </div>
        }
      </Modal>

      {/* accept modal start */}
      <AcceptModal
        acceptRejectApiBody={acceptRejectApiBody}
        showAccpetModal={showAccpetModal}
        get_TaskDetails={() => {
          getTaskDetails(true);
        }}
        hideModal={(hide, hideAnnot = true) => {
          setShowAcceptModal(hide);
          hideAnnot && hideModal(false);
        }}
      />
      {/* accept modal end */}

      {/* reject modal start */}
      <RejectModal
        get_TaskDetails={() => {
          getTaskDetails(true);
        }}
        acceptRejectApiBody={acceptRejectApiBody}
        showRejectModal={showRejectModal}
        showCommentModal={showCommentModal}
        hideRejectModal={(hide) => {
          setShowRejectModal(hide);
        }}
        hideCommentModal={(hide, hideAnnot = true) => {
          setShowCommentModal(hide);
          !hide && hideAnnot && hideModal(false);
        }}
      />
      {/* reject modal start */}
    </>
  );
};

export default AnnotationModal;
