import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import GavelIcon from "@material-ui/icons/Gavel";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PolicyIcon from "@material-ui/icons/Policy";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import WorkIcon from "@material-ui/icons/Work";
import PersonIcon from "@material-ui/icons/Person";

import HelpIcon from "@material-ui/icons/Help";

export var iconMapping = {
  historyedu: LocalLibraryIcon,
  gavel: GavelIcon,
  gavel: GavelIcon,
  formatquote: FormatQuoteIcon,
  accountbalance: AccountBalanceIcon,
  policy: PolicyIcon,
  accountbox: AccountBoxIcon,
  business: BusinessIcon,
  locationcity: LocationCityIcon,
  menubook: MenuBookIcon,
  librarybooks: LibraryBooksIcon,
  work: WorkIcon,
  person: PersonIcon,
  help: HelpIcon,
};
export default iconMapping;
