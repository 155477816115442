import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const TasksChart = ({ data }) => {

  const { t } = useTranslation();
  let colors = [
    am4core.color("#123a65"),
    am4core.color("#184f8b"),
    am4core.color("#1e64b1"),
    am4core.color("#2479d7"),
    am4core.color("#2a8efd"),
    am4core.color("#2a80ff")
  ];
  useEffect(() => {
    let chart = am4core.create('tasks_chart', am4charts.XYChart)
    chart.colors.list = colors;

    chart.legend = new am4charts.Legend()
    chart.legend.position = 'top'
    chart.legend.paddingBottom = 20
    chart.legend.labels.template.maxWidth = 95

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = 'category'
    xAxis.renderer.cellStartLocation = 0.1
    xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.grid.template.location = 0;

    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;

    function createSeries(value, name) {
      var series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = value
      series.dataFields.categoryX = 'category'
      series.name = name
      series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]'
      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);

      var bullet = series.bullets.push(new am4charts.LabelBullet())
      bullet.interactionsEnabled = false
      bullet.dy = 30;
      bullet.label.text = '{valueY}'
      bullet.label.fill = am4core.color('#ffffff')

      return series;
    }

    chart.data = data.map(item => {
      return {
        category: item.name,
        first: item.document_count,
        second: item.total_annotated,
      }
    })
    chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
    chart.zoomOutButton.background.fill = am4core.color("#194c7f");
    chart.zoomOutButton.icon.stroke = am4core.color("#FFF");
    chart.zoomOutButton.icon.strokeWidth = 2;
    chart.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#194c7f");
    // add zoom bar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();
    createSeries('first', t('documents'));
    createSeries('second', t('annotated'));
    // createSeries('third', 'The Third');

    function arrangeColumns() {

      var series = chart.series.getIndex(0);

      var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        var delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          var middle = chart.series.length / 2;

          var newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            }
            else {
              series.dummyData = chart.series.indexOf(series);
            }
          })
          var visibleCount = newIndex;
          var newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            var trueIndex = chart.series.indexOf(series);
            var newIndex = series.dummyData;

            var dx = (newIndex - trueIndex + middle - newMiddle) * delta

            series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
            series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
          })
        }
      }
    }
  }, [data]);

  return (
    <div
      id="tasks_chart"
      style={{
        width: "100%",
        height: "400px",
      }}
    ></div>
  );
};

TasksChart.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.array,
};

export default TasksChart;
