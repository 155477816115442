import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../../hoc";
import {
  CustomEmptyDiv,
  CustomPageHeader,
  CustomTable,
  TextField,
} from "../../../../molecules";
import {
  AppImage,
  NavigateTo,
  SidebarMenus,
} from "../../../../themes";
import { Collapse } from "antd";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { getTaskDetails, saveTaskId } from "../../../../redux/actions/Tasks";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn";
import { useDispatch } from "react-redux";
import appconstant from "../../../../themes/appconstant";
import parse from "html-react-parser";
import AnnotationModal from "./Modals/AnnotationModal";
import { toast } from "react-toastify";

const { Panel } = Collapse;

const WorkerCompletedTaskDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { taskId } = useParams(); //localStorage.getItem("workerCompletedTaskId");

  // modals
  const [isModalVisible, setIsModalVisible] = useState(false); // annotation modal


  // modals

  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [annotationDS, setAnnotationDS] = useState([]); // annotationImageDS
  const [annotationAudioDS, setAnnotationAudioDS] = useState([]);
  const [annotDetails, setAnnotDetails] = useState([]);
  const [imageIndex, setImageIndex] = useState("");
  const [audioIndex, setAudioIndex] = useState("");

  const [textLists, setTextLists] = useState([]);
  const [annotationTextDS, setAnnotationTextDS] = useState([]);

  // image states start
  const [classNames, setClassNames] = useState([]);
  const [taskInfo, setTaskInfo] = useState({
    taskName: "",
    taskType: "",
    instruction: "",
    description: "",
    taskCompletionDate: null,
  });

  const [classLists, setClassLists] = useState([]);
  const [audioLists, setAudioLists] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  // image states end

  // text states start

  const [questions, setQuestions] = useState(null);
  // text states end

  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: t("task_management"),
    },
    {
      link: "",
      label: t("wrkr_cmpltd_task_details"),
    },
  ];

  /* page description */
  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500, //localStorage.getItem('lang')=='ar' ? 600 :
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: "break-all",
  };
  const tnl = [
    { labelTitle: t("tsk_name") + " : ", customLabelStyle: leftFontstyle },
    {
      labelTitle: taskInfo.taskName,
      customLabelStyle: rightFontStyle,
    },
  ]; // task name
  const ttl = [
    { labelTitle: t("tsk_typ") + " : ", customLabelStyle: leftFontstyle },
    {
      labelTitle: " " + t(taskInfo.taskTypeToShow),
      customLabelStyle: rightFontStyle,
    },
  ]; // task type

  const tdl = [
    {
      labelTitle: t("tsk_desc") + " : ",
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo.description,
      customLabelStyle: rightFontStyle,
    },
  ]; // task description
  const tcdl = [
    {
      labelTitle: t("task_cmpltn_date") + " : ",
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: " " + taskInfo.taskCompletionDate,
      customLabelStyle: rightFontStyle,
    },
  ];
  let textFieldlabels;
  if (taskInfo.taskCompletionDate) {
    textFieldlabels = [tnl, ttl, tdl, tcdl];
  } else {
    textFieldlabels = [tnl, ttl, tdl];
  }

  /* page description */

  /* drop down */

  const classListHeaders = [
    {
      label: "",
      headerWidth: "150px",
    },
    {
      label: t("clss_name"),
      headerWidth: "150px",
    },
    {
      label: t("annotations"),
      headerWidth: "250px",
    },
    {
      label: t("action"),
      paddingLeft: "70px",
      headerWidth: "80px",
    },
  ];
  const audioLisHeaders = [
    {
      label: t("file_img"),
      headerWidth: "150px",
    },
    {
      label: t("file_name"),
      headerWidth: "150px",
    },
    {
      label: t("annotations"),
      headerWidth: "250px",
    },
    {
      label: t("action"),
      paddingLeft: "70px",
      headerWidth: "80px",
    },
  ]; // outer list table

  const AnnotationModalProps = {
    title: t("annot_list"),
    handleOk: () => {
      setIsModalVisible(false);
    },
    onSubmit: () => {
      setIsModalVisible(false);
    },
    modalWidth: 1000,
    handleCancel: () => {
      setIsModalVisible(false);
    },
    isModalVisible: isModalVisible,
  };

  const dropDownGroup = [
    {
      table: {
        placeholder: t("cls_list"),
        tableHeaders: classListHeaders,
        tableContents: classLists,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        AnnotClassName: "wptd",
        ModalProps: AnnotationModalProps,
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data user-task-manage-worker-completed-task-details-class-list-ar"
            : "text_data user-task-manage-worker-completed-task-details-class-list-en",
      },
    }, // class Lists
    {
      table: {
        placeholder:
          taskInfo?.taskType == "Audio" ? t("audio_list") : t("text_lists"),

        tableHeaders: audioLisHeaders,
        tableContents: taskInfo?.taskType == "Audio" ? audioLists : textLists,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        tdImageWidth: taskInfo?.taskType == "Text" ? "unset" : null,
        tdImageHeight: taskInfo?.taskType == "Text" ? "unset" : null,
        tdImageBorderRadius: taskInfo?.taskType == "Text" ? "unset" : null,
        AnnotClassName: "wptd",
        ModalProps: AnnotationModalProps,
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data user-task-manage-worker-completed-task-details-class-list-ar"
            : "text_data user-task-manage-worker-completed-task-details-class-list-en",
      },
    }, // audio Lists
  ];

  /* drop down */

  /*  modal methods */

  /*  modal methods */

  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("user"),
    active: t("task_management"),
  };
  const payload = {
    body: {
      taskId,
      user_id: Number(localStorage.getItem("loggedInUserId")),
    },
    success: (res) => {
      if (res?.data?.task_type == 1) localStorage.setItem("taskType", "Image");
      if (res?.data?.task_type == 2) localStorage.setItem("taskType", "Text");
      if (res?.data?.task_type == 3) localStorage.setItem("taskType", "Audio");

      setTaskInfo({
        taskName: res?.data?.name,
        taskType:
          res?.data?.task_type == 1
            ? "Image"
            : res?.data?.task_type == 2
              ? "Text"
              : "Audio",
        taskTypeToShow: res?.data?.task_type == 1
          ? "image"
          : res?.data?.task_type == 2
            ? "text"
            : "audio",
        instruction: res?.data?.instruction,
        description: res?.data?.description,
        taskCompletionDate: res?.data?.end_date
          ? res?.data?.end_date
            ?.split("T")[0]
            .split("-")
            .reverse()
            .join("-")
          : null,
      });
      // image
      if (res?.data?.task_type == 1) {
        // localStorage.setItem("taskType", "Image");

        let classNames = [];
        res?.data?.classes.map((item) => {
          classNames.push(item?.name);
        });

        setClassNames(classNames);

        let tempClassLists = [];
        let tempImageArray = [];
        // let tempApiJsonImage = [];
        res?.data?.media.map((item, index) => {
          let imageDetails = {};
          imageDetails["jsonFile"] = item?.annotation; //jsonFile?.regions;

          tempClassLists.push({
            0: { image: appconstant.imageBaseUrl + item?.path },
            1: classNames.join(","),
            2: item?.annotation ? item?.annotation?.length : 0,
            3: {
              button: "View Annotations",
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: imageDetails, //item?.jsonFile?.regions, // send anything like image id item?.id or annot details
            },
          }); // tempClassLists
          tempImageArray.push({
            name: `Image-${index + 1}`,
            src: appconstant.imageBaseUrl + item?.path,
            regions:
              item?.annotation.length != 0
                ? item?.annotation[item?.annotation.length - 1].jsonFile
                  ?.regions
                : [],
            id: item?.id,
          }); // tempImageArray
        });
        setClassLists(tempClassLists);
        setImageArray(tempImageArray);
      } // image

      // text
      if (res?.data?.task_type == 2) {
        // localStorage.setItem("taskType", "Text");
        if (res?.data?.text_type == "segmentation") {
          localStorage.setItem("textType", "segmentation");

          let tempTextLists = [];
          let tempClassLists = [];
          res?.data?.media.map((item) => {
            let textFileName = item?.path; //.substring(0, 15) + "..."; //.split("/");
            let textDetails = {
              jsonFileForStartTask:
                item?.annotation[item?.annotation.length - 1]?.jsonFile,
              jsonFile: item?.annotation,
              paragraph: item?.path,
              totalTasks: res?.data?.media?.length,

              task_id: item?.task_id,
              anotation_id: item?.id,
              worker_id: item?.worker_id,
              media_id: item?.id,
            };
            tempTextLists.push({
              0: { image: AppImage.file_n },
              1: textFileName, //textFileName[textFileName.length - 1],
              2: item?.annotation ? item?.annotation?.length : 0,
              3: {
                button: "View Annotations",
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: textDetails, // send anything like image id item?.id or annot details
              },
            }); // tempTextLists
          });
          setTextLists(tempTextLists);

          res?.data?.classes.map((item) => {
            tempClassLists.push({
              id: item?.id,
              displayName: item?.name,
              task_id: item?.task_id,
            });
          });
          setClassLists(tempClassLists);
        }
        if (res?.data?.text_type == "questions") {
          localStorage.setItem("textType", "questions");
          let tempTextLists = [];
          const tempQuestions = [];

          JSON.parse(res?.data?.question?.questionsData)?.map((item, index) => {
            tempQuestions.push({
              name: `input${index}`,
              type:
                item?.answerType == "Check-Box"
                  ? "checkbox"
                  : "radio",
              title: item?.question,
              choices: item?.answers,
            });
          });

          res?.data?.media.map((item) => {
            let textFileName = item?.path.split("/");
            let textDetails = {
              jsonFileForStartTask:
                item?.annotation[item?.annotation.length - 1]?.jsonFile,
              jsonFile: item?.annotation,
              pdfUrl: appconstant?.pdfBaseUrl + item?.path,
              task_id: item?.task_id,
              anotation_id: item?.id,
              worker_id: item?.worker_id,
              media_id: item?.id,
              totalTasks: res?.data?.media?.length,
            };
            tempTextLists.push({
              0: { image: AppImage.file_n },
              1: textFileName[textFileName.length - 1],
              2: item?.annotation ? item?.annotation?.length : 0,
              3: {
                button: "View Annotations",
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: textDetails, // send anything like image id item?.id or annot details
              },
            }); // tempTextLists
          });
          // setPdfUrl(tempPdfUrls);//res?.data?.pdfUrls
          setQuestions(tempQuestions);
          setTextLists(tempTextLists);
        }
      } // text

      // audio
      if (res?.data?.task_type == 3) {
        // localStorage.setItem("taskType", "Audio");

        let tempAudioArray = [];
        let tempAudioLists = [];

        res?.data?.media.map((item) => {
          let audioFileName = item?.path.split("/");
          let audioDetails = {};

          audioDetails["jsonFile"] = item?.annotation;
          audioDetails["audioUrl"] = appconstant.imageBaseUrl + item?.path;

          tempAudioLists.push({
            0: { image: AppImage.volumeFileImg },
            1: audioFileName[audioFileName.length - 1],
            2: item?.annotation ? item?.annotation?.length : 0,
            // 2: item?.jsonFile ? item?.jsonFile.length : 0,
            3: {
              button: "View Annotations",
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: audioDetails, // send anything like image id item?.id or annot details
            },
          }); // tempAudioLists

          // audio id
          tempAudioArray.push({
            // name: `Audio-${index + 1}`,
            url: appconstant.imageBaseUrl + item?.path,
            jsonFile: item?.annotation != null ? item?.annotation : [], // jsonFile
            task_id: item?.task_id,
            anotation_id: item?.id,
            worker_id: item?.worker_id,
          });
        });
        setAudioLists(tempAudioLists);

      } // audio
    },
    fail: (resf) => {
      resf?.data &&
        toast.error(resf?.data, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
        });
      resf?.message &&
        toast.error(resf?.message, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
        });
    },
  }; //  task details info payload

  useEffect(() => {
    window.document.title = "WosoM";
    const annotModalTableCells = document.querySelectorAll(
      ".worker-completed-annot-modal th:not(:last-child),.worker-completed-annot-modal td:not(:last-child)"
    );

    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );

    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      if (isModalVisible) {
        modalPopUpCrossButton[0].style.right = "unset";
        modalPopUpCrossButton[0].style.left = "0";
        document.querySelector(".ant-modal-content").style.direction = "rtl";
        for (let i = 0; i < annotModalTableCells.length; i++) {
          const element = annotModalTableCells[i];
          element.style.textAlign = "right";
        }
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      if (isModalVisible) {
        modalPopUpCrossButton[0].style.right = "0";
        modalPopUpCrossButton[0].style.left = "unset";
        document.querySelector(".ant-modal-content").style.direction = "ltr";
        for (let i = 0; i < annotModalTableCells.length; i++) {
          const element = annotModalTableCells[i];
          element.style.textAlign = "left";
        }
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    dispatch(saveTaskId(taskId));
    isInternetConnected() && dispatch(getTaskDetails(payload));
  }, []);
  return (
    // NOTE : ON LANGUAGE CHANGE EXCEPT STATUS SECTION IN HEADER AND DROPDOWN STYLING OTHER STYLINGS ARE CONTROLLED FROM WORKER PENDING TASK DETAILS
    <Container
      {...containerProps}
      languageChanged={() => {
        setLang(localStorage.getItem("lang"));
      }}
    >
      <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        <CustomPageHeader
          label={t("wrkr_cmpltd_task_details")}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data custom-page-header-wrapper"
          }
          customWrapperStyle={{
            marginBottom: "20px",
          }}
          islanguageChanged={lang}
        >
          <CustomPageHeader
            label={` ${t("status")} : ${t("completed")}`}
            wrapperClassName={
              "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
            }
            headerStyle={"text-white text-capitalize ps-3 statusHeader"}
            customWrapperStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-16px",
            }}
          >
            <div style={{ marginTop: "-6px" }}>
              <img src={AppImage.completedTask} />
            </div>
          </CustomPageHeader>
        </CustomPageHeader>
        <div className="wrapper_line">
          {/* pageDescription */}
          {textFieldlabels.map((item) => {
            return (
              <div className="userdetials home" key={item}>
                <TextField labels={item} />
              </div>
            );
          })}
          {/* pageDescription */}

          {/* task Instruction */}
          <label style={leftFontstyle}>{t("instructions")} :</label>

          <div
            className="get-original-text-color"
            style={{
              marginTop: "12px",
            }}
          >
            <div className="ck-content">
              {taskInfo?.instruction ? (
                parse(taskInfo?.instruction)
              ) : (
                <p style={{ color: "#acb2ba" }}>
                  {t('inst_wil_appear_here')}
                </p>
              )}
            </div>
          </div>
          {/* task Instruction */}

          {/* dropdowns */}

          {/* class lists */}
          {taskInfo.taskType == "Image" ||
            taskInfo.taskType == "Segmentation" ? (
            // class list
            <Collapse defaultActiveKey={["1"]} onChange={() => { }}>
              <Panel header={dropDownGroup[0].table.placeholder} key="1">
                {classLists.length > 0 ? (
                  <CustomTable
                    {...dropDownGroup[0].table}
                    handleClick={(label, id, link, row2, trIndex) => {
                      switch (label) {
                        case "View Annotations": {
                          if (id != null) {
                            let tempAnnotDetails = [];
                            const annots = id;
                            annots?.jsonFile?.map((item) => {
                              item.jsonFile.regions = [item?.regions];

                              tempAnnotDetails.push({
                                annotation_name: item?.regions.type,
                                class: item?.regions.cls,
                                file_id: item?.regions.id,
                                annotDetails: item.jsonFile,
                                status:
                                  annots?.annotStatus == 3
                                    ? "Rejected"
                                    : annots?.annotStatus == 2
                                      ? "Approved"
                                      : "Pending",
                                statusToShow:
                                  annots?.annotStatus == 3
                                    ? "rejected"
                                    : annots?.annotStatus == 2
                                      ? "approved"
                                      : "pending",
                                workerId: item?.worker_id?.id,
                              });
                            });
                            setAnnotationDS(tempAnnotDetails);
                            setAnnotDetails(id);
                          }
                          localStorage.setItem("imageIndex", trIndex);
                          setImageIndex(trIndex);
                          setIsModalVisible(true);
                          break;
                        }

                        default:
                          break;
                      }
                    }} // for buttons in a row of table
                  />
                ) : (
                  <CustomEmptyDiv title={t('clss_appear_here')} />
                )}

                {/* Image */}
              </Panel>
            </Collapse> // Image
          ) : (
            // audio list
            <Collapse defaultActiveKey={["1"]} onChange={() => { }}>
              <Panel header={dropDownGroup[1].table.placeholder} key="1">
                {audioLists.length > 0 || textLists.length > 0 ? (
                  <CustomTable
                    {...dropDownGroup[1].table}
                    handleClick={(label, id, link, row2, trIndex) => {
                      switch (label) {
                        case "View Annotations": {
                          if (taskInfo?.taskType == "Audio") {
                            if (id != null) {
                              let tempAnnotDetails = [];
                              const audioDetails = id;
                              audioDetails?.jsonFile.map((item) => {
                                tempAnnotDetails.push({
                                  annotation_name: item?.regions?.data?.note, // this only for worker case
                                  annotationId: item?.id,
                                  url: item?.url,
                                  jsonFile: [item?.regions],
                                  workerId: item?.worker_id?.id,
                                });
                              });
                              setAnnotationAudioDS(tempAnnotDetails); // data to show for Audio annotation list table of modal
                              setAnnotDetails(id); // all annotations for single audio
                            }
                            setAudioIndex(trIndex);
                            setIsModalVisible(true);
                          }

                          if (taskInfo?.taskType == "Text") {
                            if (id != null) {
                              if (
                                localStorage.getItem("textType") == "questions"
                              ) {
                                const textDetails = id;
                                let tempAnnotDetails = [];
                                textDetails?.jsonFile.map((item) => {
                                  const questionIndex = item?.region_id;
                                  const annotName =
                                    questions[
                                      questionIndex.split("")[
                                      questionIndex.length - 1
                                      ]
                                    ]?.title;
                                  tempAnnotDetails.push({
                                    annotation_name: annotName,
                                    status:
                                      item?.status == 3
                                        ? "Rejected"
                                        : item?.status == 2
                                          ? "Approved"
                                          : item?.status == 4
                                            ? "Disputed"
                                            : "Pending",
                                    statusToShow:
                                      item?.status == 3
                                        ? "rejected"
                                        : item?.status == 2
                                          ? "approved"
                                          : item?.status == 4
                                            ? "disputed"
                                            : "pending",
                                    // for raise dispute
                                    annotationId: item?.id,
                                    // for buttons to show if raise dispute or view comment or disable raise dispute
                                    annotStatus: item?.status,
                                    // to show vComments
                                    vComments: item?.comment,
                                    questions: [
                                      questions[
                                      questionIndex.split("")[
                                      questionIndex.length - 1
                                      ]
                                      ],
                                    ],
                                    pdfUrl: item?.media_id?.path,

                                    savedAnnotation: {
                                      [item?.region_id]: item?.regions,
                                    },
                                    workerId: item?.worker_id?.id,
                                    valId:
                                      item?.comment[item?.comment.length - 1]
                                        ?.validator_id,
                                  });
                                });
                                setAnnotationTextDS(tempAnnotDetails);
                              }
                              if (
                                localStorage.getItem("textType") ==
                                "segmentation"
                              ) {
                                const textDetails = id;

                                let tempAnnotDetails = [];
                                textDetails?.jsonFile.map((item) => {
                                  const annotName = item?.regions?.text;
                                  tempAnnotDetails.push({
                                    annotation_name: annotName,
                                    status:
                                      item?.status == 3
                                        ? "Rejected"
                                        : item?.status == 2
                                          ? "Approved"
                                          : item?.status == 4
                                            ? "Disputed"
                                            : "Pending",
                                    statusToShow:
                                      item?.status == 3
                                        ? "rejected"
                                        : item?.status == 2
                                          ? "approved"
                                          : item?.status == 4
                                            ? "disputed"
                                            : "pending",
                                    // for raise dispute
                                    annotationId: item?.id,
                                    // for buttons to show if raise dispute or view comment or disable raise dispute
                                    annotStatus: item?.status,
                                    // to show vComments
                                    vComments: item?.comment,
                                    paragraph: item?.paragraph,
                                    particularAnnot: [item?.regions],
                                    segmentAllAnnots:
                                      textDetails?.jsonFileForStartTask,
                                    totalAnnots: item?.jsonFile,
                                    workerId: item?.worker_id?.user_id?.id,
                                    valId:
                                      item?.comment[item?.comment.length - 1]
                                        ?.validator_id,
                                  });
                                });
                                setAnnotationTextDS(tempAnnotDetails);
                              }
                            }
                            setIsModalVisible(true);
                          }
                          break;
                        }
                        default:
                          break;
                      }
                    }} // for buttons in a row of table
                  />
                ) : (
                  <CustomEmptyDiv
                    title={`${taskInfo?.taskType == "Audio"
                        ? t("audio_list")
                        : t("text_lists")
                      }  ${t('wil_appear_here')}`}
                  />
                )}
              </Panel>
              {/* Audio */}
            </Collapse>
          ) // Audio 
          }

          {/* dropdowns */}

          {/* Anntation Modal */}
          <AnnotationModal
            audioLists={audioLists}
            classNames={classNames}
            imageArray={imageArray}
            imageIndex={imageIndex}
            annotDetails={annotDetails}
            annotationDS={annotationDS}
            // audio details
            annotationAudioDS={annotationAudioDS}
            audioIndex={audioIndex}
            // text
            annotationTextDS={annotationTextDS}
            classLists={classLists}
            // projId={projId}
            isModalVisible={isModalVisible}
            hideModal={(hide) => {
              setIsModalVisible(hide);
            }}
          />
        </div>
      </CardHoc>
    </Container>
  );
};

export default WorkerCompletedTaskDetails;
