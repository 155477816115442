import images from "../../../themes/appImage";
import React, { useEffect } from "react";
import { HeaderLandingInner } from "../../../layout/LandingModule";
import { Constants } from "../../../themes";
import { CustomFooter } from "../../../molecules";

const PrivacyPolicy = () => {
  const sticky = false;
  const toogled = false;
  const isVisible = false;

  const handletoogle = () => {
    setToogle(!toogled);
  };
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="landing-page">
      <HeaderLandingInner
        handle={handletoogle}
        toogled={toogled}
        sticky={sticky}
      />

      <section className="aboutus data_about_us privacy_details">
        <div className="container">
          <div className="row align-item-center">
            <h2
              className="heading  slideInUp wow"
              style={{ marginTop: "69px" }}
            >
              <img src={images.leftimg12}></img>
              {Constants.PrivacyPolicy}
              <img src={images.rightimg12} />
            </h2>
            <div className="">
              <div className="d-flex w-100 justify-content-center appss">
                <div className="mobile_circle">
                  <img src={images.privacypolicyimg} />
                </div>
              </div>

              <div className="text-center slideInDown home_trrr">
                <p>
                  {Constants.PrivacyPolicyDescription1}
                </p>

                <p>
                  {Constants.PrivacyPolicyDescription2}
                </p>

                <p>
                  {Constants.PrivacyPolicyDescription3}
                </p>

                <p>
                  {Constants.PrivacyPolicyDescription4}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CustomFooter />

      {isVisible && (
        <div onClick={scrollToTop}>
          <a href="#" className="scrollup"></a>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicy;
