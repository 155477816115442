import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavigateTo, AppImage, Constants } from "../../../themes";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { loginAction, otpVerification } from "../../../redux/actions/Actions";
import { toast } from "react-toastify";

import { t } from "i18next";

const OTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [startTimer, setStartTimer] = useState(60);
  const onSubmit = (values) => {
    // navigate(NavigateTo.USER_DASHBOARD);

    apiCall();
    function apiCall() {
      let flag = true;
      if (flag) {
        flag = false;
        const paylaodForOtp = {
          success: (res) => {

            const setToken = res?.result?.access_token;
            localStorage.setItem('tokenCheckForRouting', setToken); // check for routing without otp verification

            localStorage.setItem('goToDashboard', 'true');
            localStorage.setItem("isRegistration", "false");
            flag = true;
            formik.resetForm();
            localStorage.removeItem('otp');
            localStorage.removeItem('password');
            localStorage.setItem("token", res?.result?.access_token);
            localStorage.setItem("user_type", res?.result?.user_type);
            localStorage.setItem("user", values.email);
            localStorage.setItem("fullName", res?.result?.full_name); // DO IT HERE
            if (res?.result?.image) {
              localStorage.setItem("userImage", res?.result?.image);
            } else {
              localStorage.setItem("userImage", "");
            }
            toast.success("Logged in successfully.", {
              className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
              position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
              toastId: "loginSuccess",
            });
            if (localStorage.getItem("user_type") == 'owner')
              navigate(NavigateTo.OWNER_DASHBOARD);
            if (localStorage.getItem("user_type") == 'performer')
              navigate(NavigateTo.USER_DASHBOARD);

            localStorage.setItem("otp", "false"); // to restrict to open otp page after login

            // resend otp timer for 2 mins start

            // resend otp timer for 2 mins end
          },
          fail: (res) => {
            flag = true;
            toast.error(t(res?.result?.error), {
              className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
              position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
              toastId: "otpToast",
            });
          },
          body: {
            email: values.email,
            password: values.password,
            otp: values.otp,
          },
        };
        isInternetConnected() && dispatch(otpVerification(paylaodForOtp));
      }
    }
    //
  };

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .matches(/^[0-9]{6}$/, "Please enter 6 digit OTP.")
      .required("Please enter OTP."),
  });
  const initialValues = {
    email: localStorage.getItem("user"),
    password: localStorage.getItem("password"),
    otp: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
    // toast otp sent
    if (localStorage.getItem("otp") == "true") {
      toast.success(t("otp_sent"), {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        toastId: "otpSent",
      });
    }
    if (
      localStorage.getItem("otp") == null ||
      localStorage.getItem("otp") == "false"
    ) {
      navigate(NavigateTo.LOGIN);
    }

    return () => {
      localStorage.setItem("otp", "false");
    };
  }, []);

  const handleOnChange = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("otp", e.target.value);
      }
    } else {
      formik.setFieldValue("otp", "");
    }
  };
  const gethours = (secTotal, type) => {
    const m = Math.floor(secTotal / 60);
    let secondsLeft = secTotal - m * 60;

    var mDisplay = m > 9 ? `${m}:` : m > 0 && m < 10 ? "0" + `${m}:` : "00:";
    var sDisplay =
      secondsLeft > 9
        ? secondsLeft
        : secondsLeft > 0 && secondsLeft < 10
          ? "0" + secondsLeft
          : "00";
    if (type == "m") {
      return mDisplay;
    } else {
      return sDisplay;
    }
  };
  useEffect(() => {
    if (startTimer) {
      setTimeout(() => {
        setStartTimer(startTimer - 1);
      }, 1000);
    }
  }, [startTimer]);

  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="line_true stopOverFlow">
          <div className="content-e ">
            <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <div className="text-center cost">
                <img src={AppImage.logo3} />
                <h6 className="text-white text-capitalize ps-3 headg_d">OTP</h6>
              </div>
            </div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className="wrapper_line login">
                <div className="form-group field_n">
                  {/* <label>Email Address</label> */}
                  <input
                    id="email"
                    type="text"
                    className="form-control"
                    // {...register('email')}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    disabled
                  />
                </div>
                <div className="form-group field_n">
                  {/* <label>Password</label> */}
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    disabled
                  />
                </div>
                <div className="form-group">
                  {/* <label>Password</label> */}
                  <input
                    id="otp"
                    type="text"
                    className="form-control"
                    placeholder="OTP"
                    name="otp"
                    // {...register('otp')}
                    maxLength={6}
                    onKeyDown={(e) => {
                      if (
                        e.key === "." ||
                        e.key === "{" ||
                        e.key === "}" ||
                        e.key === "*"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    autoComplete="off"
                    value={formik.values.otp}
                    onChange={(e) => handleOnChange(formik, e)} //formik.handleChange
                  // onChange={formik.handleChange}
                  />
                  {formik.errors.otp ? (
                    <div className="error">{formik.errors.otp}</div>
                  ) : null}
                </div>
                <div className="otp_new">
                  {/* resend otp */}
                  <div className="forrgot">
                    <Link
                      style={{
                        color: startTimer != 0 ? "#9aa0a5" : "#143c64",
                        cursor: startTimer != 0 ? "no-drop" : "pointer",
                      }}
                      to="/otp"
                      onClick={() => {
                        const newData = {
                          body: {
                            email: localStorage.getItem("user"), //location?.state?.email,
                            password: localStorage.getItem("password"), //</div></div>location?.state?.pass,
                          },
                          success: () => {
                            toast.success("OTP sent successfully.", {
                              className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
                              position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
                              toastId: "otpSent",
                            });

                            setStartTimer(60);
                          },
                          fail: (resf) => {
                            console.log(resf, "failed API");
                          },
                        };

                        isInternetConnected() &&
                          startTimer == 0 &&
                          dispatch(loginAction(newData));
                      }}
                    >
                      Resend OTP?
                    </Link>
                  </div>
                  {/* timer */}
                  {startTimer != 0 ? (
                    <div
                      style={{
                        marginLeft: 10,
                        color: "#193d64",
                        fontWeight: "bold",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      {/* {startTimer} */}
                      {gethours(startTimer, "m")}
                      {gethours(startTimer, "s")}
                    </div>
                  ) : null}

                  {/* forgot pass */}
                  <div className="forrgot">
                    <Link to="/forgotpassword">Forgot Password?</Link>
                  </div>
                </div>
                {/* login button */}
                <div className="data_line">
                  <button
                    type="submit"
                    onClick={() => {

                      switch (String(location?.state?.userType).toLowerCase()) {
                        case "user":
                          navigate(NavigateTo.USER_DASHBOARD);
                          break;
                        case "owner":
                          navigate(NavigateTo.OWNER_DASHBOARD);
                          break;
                        case "admin":
                          navigate(NavigateTo.ADMIN_DASH_BOARD);
                          break;
                        default:
                          break;
                      }
                    }}
                    className="button-list focus"
                  >
                    {Constants.Login}
                  </button>
                </div>
                <div></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OTP;
