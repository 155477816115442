import Fetch from "../Fetch/Group";
import { call, put } from "redux-saga/effects";

import { startLoading, stopLoading } from "../actions/Common";

import { delay } from 'redux-saga/effects'

export function* shouldMemberBeRemovedSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.shouldMemberBeRemovedApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      // yield call(authanicationUser, result, action.history) 
      fail(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getGroupListsApi({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield delay(500);
    yield put(startLoading());
    const result = yield call(Fetch.getGroupListsApi, body);
    console.log(result, 'result===');
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    }
    else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    }
    else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}

export function* createGroupApi({
  payload: {
    success = () => { },
    fail = () => { },
    body
  } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.createGroupApi, body);
    if (result.status === 1) {
      success(result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}

export function* updateGroupApi({
  payload: {
    success = () => { },
    fail = () => { },
    body
  } = {},

}) {
  try {
    const result = yield call(Fetch.updateGroupDataApi, body);
    yield put(startLoading());
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* deleteGroupApi({
  payload: {
    success = () => { },
    fail = () => { },
    groupId
  } = {},

}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.deleteGroupDataApi, groupId);

    if (result.status === 1) {
      success();
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getGroupInvitationsApi({
  payload: {
    success = () => { },
    fail = () => { },
    groupId
  } = {},

}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getGroupInvitationsApi, groupId);

    if (result.status === 1) {
      success(result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}

export default getGroupListsApi;
