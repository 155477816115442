import React from "react";
import { useNavigate } from "react-router";
import { AppImage, NavigateTo } from "../../themes";
const EmailConfirmed = () => {
    const navigate =useNavigate();
  return (
    <div
      style={{
        backgroundImage: `url(${AppImage.confirm})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "100vh",
        margin: "auto",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div className="text_button">
        {/* <Link to="/profile"> */}
        <button
          type="submit"
          onClick={()=>{
              navigate(NavigateTo.LOGIN)
          }}
          className="button-list bottom_button pt-10 focus"
          style={{
            padding: "5px 0px",
            position: "absolute",
            marginLeft: "auto",
            left: "0",
            right: "0",
            marginRight: "auto",
            width: "100px",
            bottom: "20%",
          }}
        >
          {"Go to Login"}
        </button>
        {/* </Link> */}
      </div>
      <p
        style={{
            fontSize : '30px',
            color : '#193d64',
            fontFamily: "Montserrat, sans-serif" ,
            fontWeight : '600',
            position: "absolute",
            marginLeft: "auto",
            left: "34px",
            right: "0",
            marginRight: "auto",
            width: "300px",
            bottom: "-3%",
        }}
      >
          Email Confirmed Successfully
      </p>
    </div>
  );
};

export default EmailConfirmed;
