import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { loginAction } from "../../../redux/actions/Actions";
// import { getLanguageList } from "../../../reduxw/actions/Common";
import { AppImage, Constants, NavigateTo } from "../../../themes";
import ValidationConstants from "../../../themes/validationConstants";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { toast } from "react-toastify";
import { Modal } from "antd";
// import { log } from "../../../redux/actions/Common";
// import { messaging } from "../../../init-fcm";
import { useTranslation } from "react-i18next";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { t } = useTranslation();
  // const location = useLocation();

  const onSubmit = () => {
    const values = formik.values;
    // console.log("values", values);
    localStorage.setItem("isRegistration", "true");
    const newData = {
      body: {
        email: (values?.email).toLocaleLowerCase(),
        password: values?.password,
      },
      success: (res) => {

        // const logPayload = {
        //   "code": res?.status,
        //   "request": JSON.stringify(values),
        //   "response": JSON.stringify(res),
        //   "created_at": new Date().toISOString(),
        // }
        // isInternetConnected() && dispatch(log(logPayload));

        localStorage.setItem("lang", "en");
        const userType = res?.result?.user_type;

        localStorage.setItem(
          "tokenCheckForRouting",
          res?.result?.access_token
        );
        localStorage.setItem("otp", res?.result?.access_token ? "false" : "true");
        localStorage.setItem("user", values?.email);
        localStorage.setItem("password", values?.password);
        if (res?.result?.access_token) {
          toast.success("Logged in successfully.", {
            className:
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
            position:
              localStorage.lang == "ar"
                ? toast.POSITION.TOP_LEFT
                : toast.POSITION.TOP_RIGHT,
            toastId: "loginSuccess",
          });
          localStorage.setItem("goToDashboard", "true");
          localStorage.setItem("userType", userType);
          localStorage.setItem("isRegistration", false);
          localStorage.setItem('tokenCheckForRouting', res?.result?.access_token); // check for routing without otp verification

          localStorage.removeItem('otp');
          localStorage.removeItem('password');
          localStorage.setItem("token", res?.result?.access_token);
          localStorage.setItem("user_type", res?.result?.user_type);
          localStorage.setItem("user", values.email);
          localStorage.setItem("fullName", res?.result?.full_name); // DO IT HERE
          if (res?.result?.image) {
            localStorage.setItem("userImage", res?.result?.image);
          } else {
            localStorage.setItem("userImage", "");
          }
        }
        switch (
        userType // use userType to check navigate accordingly
        ) {
          case 'admin': {
            navigate(NavigateTo.ADMIN_DASH_BOARD);
            break;
          }
          case 'owner':
            res?.result?.access_token ? navigate(NavigateTo.OWNER_DASHBOARD) : navigate(NavigateTo.OTP);
            break;
          case 'performer':
            res?.result?.access_token ? navigate(NavigateTo.USER_DASHBOARD) : navigate(NavigateTo.OTP);
            break;
        }
      },
      fail: (resf) => {
        if (resf?.error === "email_not_confirmed") {
          setShowSuccessModal(true);
        } else {
          toast.error(t(resf?.error), {
            className:
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
            position:
              localStorage.lang == "ar"
                ? toast.POSITION.TOP_LEFT
                : toast.POSITION.TOP_RIGHT,
            toastId: "loginFail",
          });

        }
      },
    };

    isInternetConnected() && dispatch(loginAction(newData));
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqEmail),
        (value) => value?.trim()?.length > 0
      )
      .matches(
        /^(([a-zA-Z0-9]+)||([a-zA-Z0-9_.-]+[a-zA-Z0-9]+))@([0-9a-zA-Z]+)([^0-9])([0-9a-zA-Z]+)||([a-zA-Z0-9])(\.[a-zA-z]{2,5})$/,
        t(ValidationConstants.valEmail)
      )
      .email(t(ValidationConstants.valEmail))
      .max(320, t(ValidationConstants.maxEmail))
      .required(t(ValidationConstants.reqEmail)),
    password: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqPassword),
        (value) => value?.trim()?.length > 0
      )
      .matches(/^(\S+$)/, t(ValidationConstants.noSpace))
      .required(t(ValidationConstants.reqPassword)),
  });
  const initialValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);

    // window.history.pushState(null, null, location.href);

    // window.onpopstate = function () {
    //  		 window.history.go(1);
    // };

    // messaging.requestPermission()
    // .then(async function() {
    //   const token = await messaging.getToken();
    // })
    // .catch(function(err) {
    //   console.log("Unable to get permission to notify.", err);
    // });
    // navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
  }, []);
  const SuccessModal = () => {
    return (
      <Modal
        // title={"Alert"}
        open={showSuccessModal}
        closable={false}
        footer={null}
        centered
      >
        <div className="text-center  model1">
          <p>{t("verify_email")}</p>
        </div>
        <div className="text-center button_d_inline model1">
          <button
            type="button"
            className="button-list top_new focus"
            onClick={() => {
              setShowSuccessModal(false);
            }}
          >
            {t("Ok")}
          </button>
        </div>
      </Modal>
    );
  };
  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="line_true stopOverFlow">
          <div className="content-e  ">
            <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <div className="text-center cost">
                <img src={AppImage.logo3} />
                <h6 className="text-white text-capitalize ps-3 headg_d">
                  Login
                </h6>
              </div>
            </div>

            <form noValidate onSubmit={formik.handleSubmit}>
              <div className="wrapper_line login">
                {/* <label>Email Address</label> */}
                <div className="form-group">
                  <input
                    id="email"
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                    // autoComplete={'off'}
                    maxLength={320}
                    value={formik.values.email.trimLeft()}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </div>
                {/* <label>Password</label> */}
                <div className="form-group">
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    autoComplete={"off"}
                    value={formik.values.password.trimLeft()}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="forrgot">
                    <Link className="focus" to={NavigateTo.REGISTER}>
                      {Constants.CreateAccount}?
                    </Link>
                  </div>
                  <div className="forrgot">
                    <Link className="focus" to={NavigateTo.FORGOT_PASSWORD}>
                      {Constants.ForgotPassword}?
                    </Link>
                  </div>
                </div>
                <div className="data_line">
                  {/* <Link to="/otp"> */}
                  <button type="submit" className="button-list focus">
                    {Constants.Login}
                  </button>
                  {/* </Link> */}
                </div>
                <div></div>
              </div>
            </form>

            <SuccessModal />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
