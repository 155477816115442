import React, { useEffect, useState } from "react";
import { Container, ContainerAdmin } from "../../../../../hoc";
import { useLocation, useParams } from "react-router";
import {
  ImageAnnotate,
  AudioTranscript,
  QuestionAnnotate,
  SegmentationAnnotate,
} from "../Udts";
import { NavigateTo, SidebarMenus } from "../../../../../themes";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
const UDT_Screen = () => {
  let classLists,
    images,
    imageIndex,
    audio,
    audioUrl,
    questions,
    pdfUrl,
    apiJsonImage,
    particularAnnotation,
    acceptRejectApiBody,
    segmentAllAnnots,
    showTick,
    fileId,
    qnindex,
    tableData,
    status,
    disputed,
    paragraph;
  const textType = localStorage.getItem("textType"); // segmentation,questions
  const location = useLocation();
  const { t } = useTranslation();
  const { taskId } = useParams();

  const ownerOngoingUdtBreadCrumns = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.PROJECT,
      label: t("projects"),
    },
    {
      link: NavigateTo.ONGOING_PROJECT_DETAILS,
      label: t("ongoing_prj_details"),
    },
    {
      link: NavigateTo.TASK_DETAILS + `/${taskId}`,
      label: t("task_details"),
    },
    {
      link: "",
      label: t("annotate"),
    },
  ];
  const ownerPastUdtBreadCrumbs = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.PROJECT,
      label: t("projects"),
    },
    {
      link: NavigateTo.PAST_PROJECT_DETAILS,
      label: t("past_prj_details"),
    },
    {
      link: NavigateTo.PAST_TASK_DETAILS + `/${taskId}`,
      label: t("task_details"),
    },
    {
      link: "",
      label: t("annotate"),
    },
  ];
  const disputedUdtBreadCrumbs = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.PROJECT,
      label: t("projects"),
    },
    {
      link: NavigateTo.ONGOING_PROJECT_DETAILS,
      label: t("ongoing_prj_details"),
    },
    {
      link: NavigateTo.TASK_DETAILS + `/${taskId}`,
      label: t("task_details"),
    },
    {
      link:
        NavigateTo.DISPUTE_ANNOTATION + `/${localStorage.getItem("disputeId")}`,
      label: t("dis_annot_details"),
    },
    {
      link: "",
      label: t("annotate"),
    },
  ];
  const ownerUdtContainerProps = {
    breadCrumbItems: location?.state?.disputed
      ? disputedUdtBreadCrumbs
      : localStorage.getItem("ongoingProjectTaskId")
        ? ownerOngoingUdtBreadCrumns
        : ownerPastUdtBreadCrumbs,
    menuProps: SidebarMenus("owner"),
    active: t("projects"),
  };
  const otherUdtContainerProps = {
    breadCrumbItems:
      localStorage.lang == "en"
        ? location?.state?.udtContainerProps?.breadCrumbItems?.en
        : location?.state?.udtContainerProps?.breadCrumbItems?.ar,
    // menuProps: location?.state?.udtContainerProps?.menuProps ,
    menuProps: localStorage.user_type == '3' ? SidebarMenus('user') : localStorage.user_type == '1' ? SidebarMenus('admin') : SidebarMenus('owner'), //tempContainerProps.menuProps,
    active: localStorage.user_type == '3' ? t('task_management') : localStorage.user_type == '1' ? t('prj_mngmnt') : t('projects') //location?.state?.udtContainerProps?.active
  };
  const containerProps =
    localStorage.getItem("user_type") == 2
      ? ownerUdtContainerProps
      : otherUdtContainerProps;
  const UdtContainer = ({ children }) => {

    UdtContainer.propTypes = {
      children: PropTypes.node.isRequired,
    };

    return localStorage.getItem("user_type") == 1 ? (
      <ContainerAdmin {...containerProps}>{children}</ContainerAdmin>
    ) : (
      <Container {...containerProps}>{children}</Container>
    );
  };

  const [taskType, setTaskType] = useState(localStorage.getItem("taskType"));

  if (taskType == "Image") {
    classLists = location?.state?.classLists;
    images = location?.state?.images;
    imageIndex = location?.state?.imageIndex;
    apiJsonImage = location?.state?.apiJsonImage;
    particularAnnotation = location?.state?.particularAnnotation;
    acceptRejectApiBody = location?.state?.acceptRejectApiBody;
    showTick = location?.state?.showTick;
    fileId = location?.state?.fileId;
    disputed = location?.state?.disputed;
    status = location?.state?.status;
  }
  if (taskType == "Audio") {
    audio = location?.state?.audio;
    audioUrl = location?.state?.audio?.url;
  }
  if (taskType == "Text") {
    if (textType == "questions") {
      questions = location?.state?.questions;
      pdfUrl = location?.state?.pdfUrl;
      particularAnnotation = location?.state?.particularAnnotation;
      qnindex = location?.state?.qnindex;
      tableData = location?.state?.tableData;
    }
    if (textType == "segmentation") {
      paragraph = location?.state?.paragraph;
      classLists = location?.state?.classes;
      particularAnnotation = location?.state?.particularAnnotation;
      segmentAllAnnots = location?.state?.segmentAllAnnots;
    }
  }
  useEffect(() => {
    setTaskType(localStorage.getItem("taskType"));
    const elements = [];
    for (let i = 0; i < 500; i++) {
      const len = document.getElementsByClassName(`makeStyles-container-${i}`)
        .length;
      if (len > 0) {
        elements.push(
          document.getElementsByClassName(`makeStyles-container-${i}`)
        );
      }
    }
  }, []);
  // let tempContainerProps = location?.state?.udtContainerProps;

  // const containerProps= {
  //     breadCrumbItems : localStorage.lang == 'en' ? tempContainerProps.breadCrumbItems.en : tempContainerProps.breadCrumbItems.ar,
  //     menuProps : tempContainerProps.menuProps,
  //     active : tempContainerProps.active
  // };
  return (
    <UdtContainer>
      <div
        className={`udt-annotate remove-next-prev remove-save marign-right  `}
      >
        {taskType == "Image" ? (
          <ImageAnnotate
            classLists={classLists}
            images={images}
            imageIndex={imageIndex}
            apiJsonImage={apiJsonImage}
            particularAnnotation={particularAnnotation}
            acceptRejectApiBody={acceptRejectApiBody}
            showTick={showTick}
            fileId={fileId}
            status={status}
            disputed={disputed}
          />
        ) : taskType == "Text" ? (
          textType == "segmentation" ? (
            <SegmentationAnnotate
              classLists={classLists}
              particularAnnotation={particularAnnotation}
              segmentAllAnnots={segmentAllAnnots}
              paragraph={paragraph}
            />
          ) : (
            <QuestionAnnotate
              questions={questions}
              pdfUrls={pdfUrl}
              pdfUrl={pdfUrl}
              savedAnnots={particularAnnotation}
              qnindex={qnindex}
              tableData={tableData}
            />
          )
        ) : (
          <AudioTranscript audio={audio} audioUrl={audioUrl} />
        )}
      </div>
    </UdtContainer>
  );
};



export default UDT_Screen;
