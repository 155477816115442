import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import NLPAnnotator from "../../../../../../../common/named-entity-relations/NLPAnnotator";
import { saveAnnotations } from "../../../../../../../redux/actions/Annotations";
import { isInternetConnected } from "../../../../../../../themes/utils/internetConnectionn";
import Modal from "antd/lib/modal/Modal";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const SegmentationAnnotate = (props) => {
  const {t} = useTranslation();
  let {
    classes,
    task_id,
    media_id,
    paragraph,
    totalTasks,
    isCompleted,
    anotation_id,
    segmentAllAnnots,
  } = { ...props };

  const dispatch = useDispatch();
  const [isModalRemove, setModalRemove] = useState(false);
  const taskInstruction = localStorage.getItem('instruction');

  
  const saveButtonClicked = (res) => {
    let hasAnyAnnots = false;
    res.map((item) => {
      if (item.label) {
        hasAnyAnnots = true;
      }
    });
    if (hasAnyAnnots) {
      isCompleted = isCompleted + 1;
    }
    const payloadToSaveAnnotation = {
      body: JSON.stringify([
        {
          task_id,
          media_id,
          anotation_id,
          media_type: 3,
          jsonFile: res,
          text_type: "segmentation",
          is_completed: isCompleted >= totalTasks ? true : false,
        },
      ]),
      success: (res) => {
        toast.success(res?.data?.message, {
          
        className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

          toastId: "annotSaved",
        });
       },
    };

    isInternetConnected() && dispatch(saveAnnotations(payloadToSaveAnnotation));
  };
  useEffect(() => {
     if (!document.getElementById("exclam")) {
      const infoButton = document.createElement("button");

      infoButton.setAttribute(
        "class",
        "MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButtonBase-root ForwardRef(Button)-root-31 ForwardRef(Button)-root-33 css-1e6y48t-MuiButtonBase-root-MuiButton-root"
      );

      infoButton.setAttribute("tabindex", "0");
      infoButton.setAttribute("type", "button");
      infoButton.setAttribute("id", "exclam");
      infoButton.setAttribute(
        "style",
        "margin-left: -20px;margin-right: 0px;margin-top:2px;"
      );
      function clicked() {
        setModalRemove(true);
      }
      infoButton.addEventListener("click", clicked);

      const divParent = document.createElement("div");
      divParent.setAttribute("style", "");

      const divChild1 = document.createElement("div");

      divChild1.setAttribute(
        "style",
        "color:#193d64;font-size: 16px;padding-bottom: 18px;"
      );
      divChild1.innerText = "!";

      const divChild2 = document.createElement("div");
      divChild2.setAttribute(
        "style",
        "color:#193d64;font-size: 11px;font-weight: 600;"
      );
      divChild2.innerText = "Info";

      divParent.appendChild(divChild1);
      divParent.appendChild(divChild2);
      infoButton.appendChild(divParent);

      // const getButtonParents = document.querySelectorAll(
      //   ".div-root-1>div:first-child"
      // );
      // //getButtonParents[0].appendChild(infoButton);
      // const getNextEleParent = document.querySelectorAll(
      //   ".udt-annotate.keep-save-button button.MuiButton-root:nth-child(3)>div>div:last-child"
      // );
      //getNextEleParent[0].setAttribute("class", "div-root-30");
    }
  }, []);
  return (
    <div className="segmentation-annotate">
      <NLPAnnotator
        hotkeysEnabled
        labels={classes}
        document={paragraph}  
        multipleLabels={false}
        entityLabels={classes}
        type="label-relationships"
        relationshipLabels={classes}
        onFinish={(res) => {
          saveButtonClicked(res?.sequence);
        }}
        t={t}
        initialSequence={
          segmentAllAnnots ? segmentAllAnnots : [{ text: paragraph, textId: 1 }]
        } 
      /> 
       {/* info modal */}
       <Modal 
        centered
        footer={null}
        okType={false}
        title={t("instructions")}
        closable={true}
        onCancel={()=> setModalRemove(false)}
        open={isModalRemove}
      >
        {/* Annotate each image..... */}
        <div className="wrapper_line">
        <div
            className="no-custom-input"
            style={{
              marginTop: "12px",
            }}
          >
             <div className="ck-content">

               {parse(taskInstruction)}
             </div>
          </div>
        </div>

        {/* close button */}
        <div className="text-center  model1">
          <button
            type="button"
            style={{
              cursor: "pointer",
            }}
            className="focus"
            onClick={() => setModalRemove(false)}
          >
            {"Close"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SegmentationAnnotate;
