import {
  AppImage,
  fieldIds,
} from "../../../../themes";
import { useFormik } from "formik";
import { Tooltip, Rate, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { NavigateTo, SidebarMenus } from "../../../../themes";
import { CardHoc, ContainerAdmin } from "../../../../hoc";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  // CustomModal,
  CustomPageHeader,
  TextField,
} from "../../../../molecules";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn/index";
import { customFunctions } from "../../../../themes/utils/CustomFunctions";

import { useTranslation } from "react-i18next";
import { getUserListAdmin } from "../../../../redux/actions/Dashboard";
import { constants } from "../../../../common/fileConstants/Admin/UserManagement";

const EditUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.common.profileLoad);

  const [genderVal, setGender] = useState(null);
  const [availVal, setValAvail] = useState(null);

  const [background, setBackground] = useState();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    availability: "",
    address: "",
    // languages: languageChildren,

    gender: "",

    education: "",
    // deviceAvailable: [],

    // skills: [],
    // rate: "",

    countryCode: "+",
    otp: "",
    // citizenship: citizenshipOptions,
    // country: "",
  };
  const onSubmit = (values) => {

    const payloadUpdate =
    {
      body: {
        user_id: localStorage.getItem("userId"),
        first_name: values?.firstName,
        last_name: values?.lastName,
        address: values?.address,
        availability: availVal,
        gender: genderVal ? genderVal : null,
        phone_number: values?.otp,
        country_code: values?.countryCode,
      },
      success: () => {
        navigate(NavigateTo.ADMIN_USER_MANAGEMENT);
      },

    }
    isInternetConnected() && dispatch(getUserListAdmin(payloadUpdate));

  };
  const breadCrumbItems = [
    {
      link: NavigateTo.ADMIN_DASH_BOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.ADMIN_USER_MANAGEMENT,
      label: t("user_mngmnt"),
    },
    {
      link: "",
      label: t("edit_user_details"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("admin"),
    active: t('user_mngmnt'),
  };
  const formik = useFormik({
    initialValues,
    validationSchema: constants.formikValidationSchemaEditUser,
    onSubmit,
  });


  /* Container props */
  const ProfileImage = () => {
    return (
      <div
        style={{
          width: "100%",
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Tooltip
            title={formik.values.firstName + " " + formik.values.lastName}
            key={"#143C64"}
            placement="right"
          >
            <label
              style={{
                position: "relative",
              }}
            >
              <img
                style={{
                  width: "101px",
                  height: "101px",
                  marginTop: "-35px",
                  borderRadius: "101px",
                }}
                id="base-image"
                src={background}
              />
            </label>
          </Tooltip>
          <Tooltip
            title={t("rating") + " " + formik.values.rate}
            key={"#143d4"}
            placement="right"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Rate
                style={{
                  fontSize: "20px",
                  marginTop: "9px",
                }}
                className={
                  localStorage.lang == "ar"
                    ? "edit-group-star user-profile ar"
                    : "edit-group-star en"
                }
                allowHalf
                value={formik.values.rate}
                disabled={true}
              />
            </div>
          </Tooltip>
          {/* </div> */}
        </div>

      </div>
    );
  };

  /* personal details */
  const increaseDecrease = (
    <div className="form-group">
      <input
        id={"id"}
        value={availVal}
        onChange={(e) => e.preventDefault}
        className="id-control form-control"
        readOnly={true} // it prevents user to write any value
        autoComplete="off"
      />
    </div>
  );

  const personalFormFields = [
    {
      type: "text",
      error: formik.errors.firstName,
      inputTrue: true,
      id: fieldIds.FIRSTNAME,
      value:
        formik.values.firstName &&
        customFunctions.capitalizeFirstLetter(
          formik.values.firstName.trimLeft()
        ), // configure later
      placeholder: t("first_name"),
      fieldLabel: t("first_name"),
    },
    {
      type: "text",
      error: formik.errors.lastName,
      // labelIcon: AppImage.plus,
      inputTrue: true,
      id: fieldIds.LASTNAME,
      value:
        formik.values.lastName &&
        customFunctions.capitalizeFirstLetter(
          formik.values.lastName.trimLeft()
        ), // configure later
      placeholder: t("last_name"),
      fieldLabel: t("last_name"),
      // isDisabled: lDisable,
      inputMaxLength: 20,
      // onLabelIconTap: () => {
      //   document.getElementById(fieldIds.LASTNAME).focus();

      //   setLDisable(false);
      // },
    },
    {
      type: "text",
      error: formik.errors.email,
      // labelIcon: AppImage.plus,
      inputTrue: true,
      id: fieldIds.EMAIL,
      value: formik.values.email,
      placeholder: t("email_address"),
      fieldLabel: t("email_address"),
      isDisabled: true,
      inputMaxLength: 320,
      // onLabelIconTap: () => {
      //   document.getElementById(fieldIds.EMAIL).focus();

      //   setEDisable(false);
      // },
    },
  ];
  const personalFormFields2 = [
    {
      error: formik.errors.address,
      type: "text",
      // labelIcon: AppImage.plus,
      inputTrue: true,
      id: fieldIds.ADDRESS,
      fieldLabel: t("address"),
      value:
        formik.values.address &&
        customFunctions.capitalizeFirstLetter(formik.values.address.trimLeft()),
      placeholder: t("address"),
      inputMaxLength: 500,
    },
    {
      type: "text",
      fieldLabel: t("gender"),
      selectTrue: true,
      // labelIcon: AppImage.plus,
      id: fieldIds.GENDER,
      value: formik.values.gender[0]?.key || null,
      error: formik.errors.gender,
      placeholder: t("select_gender"),
      selectOptions: [
        { name: t("male"), value: "male" },
        { name: t("female"), value: "female" },
        { name: t("other"), value: "other" },
      ],
      // isDisabled: gDisable,
      // onLabelIconTap: () => setGDisable(false),
      selectOnChange: (val, options) => {
        setGender(val);
        formik.setValues({
          ...formik.values,
          gender: [{ key: val, val: options.children }],
        });
      },
    },
    {
      error: formik.errors.availability,
      fieldIcons: [AppImage.upArrow, AppImage.downArrow],
      child: increaseDecrease,
      id: fieldIds.AVAILABILITY,
      fieldLabel: t("availability") + ' ' + `(${t('hours')})`,
      value: formik.values.availability,
      placeholder: t("availability"), // 30 hours
      setValAvail: setValAvail,
    },
  ];
  useEffect(() => {
    document.title = "WosoM";
    const userId = localStorage.getItem("userId");
    const payloadGetUserDetails = {
      body: {
        user_id: userId,
      },
      success: (res) => {
        const userDetails = res?.length > 0 ? res[0] : null;
        setValAvail(userDetails?.availability || 20);

        setGender(userDetails?.gender);
        formik.setValues({
          firstName: userDetails?.first_name, //userDetails?.name.split(" ")[0],
          lastName: userDetails?.last_name, //userDetails?.name.split(" ")[1],
          email: userDetails?.email,
          availability: userDetails?.availability || 20,
          address: userDetails?.address || "",
          gender: [{ key: userDetails?.gender, val: userDetails?.gender }] || null, //userDetails?.gender,
          countryCode: userDetails?.country_code || "+91",
          otp: userDetails?.phone_number || "83838389811",
          rate: userDetails?.rating || 0,
        });

        setBackground(userDetails?.image ? userDetails?.image : AppImage.defaultImage);
      },
    };
    isInternetConnected() &&
      dispatch(getUserListAdmin(payloadGetUserDetails));
  }, []); //addTaskModalDataToShow


  const handleOnChange = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("otp", e.target.value);
      }
    } else {
      formik.setFieldValue("otp", "");
    }
  };
  const handleOnChangeCountryCode = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}+\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("countryCode", temp);
      }
    } else {
      formik.setFieldValue("countryCode", "");
    }
  };
  const formFeild = (item, index) => {
    return (
      <div
        key={index}
        className="wrapper_line form_wrapper"
        style={{ padding: "0px 15px 5px" }}
      >
        <TextField
          {...item}
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              [e.target.id]: e.target.value.trimLeft(),
            });
          }}
          labelTitle={item.fieldLabel}
          errorClassName={
            localStorage.getItem("lang") == "ar"
              ? "error ar"
              : "error en"
          }
        />
      </div>
    );
  }
  return (
    <ContainerAdmin
      {...containerProps}
    >
      <CardHoc cardInnerPadding={"content-e "}>
        {
          <>
            <CustomPageHeader
              label={t("edit_user_details")}
              wrapperClassName={
                "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
              }
              customWrapperStyle={{
                marginBottom: "20px",
              }}
            />

            <ProfileImage />

            <form
              className="user-profile "

              noValidate
              onSubmit={formik.handleSubmit}
            >
              <Row>
                <Col span={12}>
                  {personalFormFields.map((items, index) => {
                    return formFeild(items, index);
                  })}
                </Col>
                <Col span={12}>

                  {personalFormFields2.map((items, index) => {
                    return formFeild(items, index);
                  })}
                  {/* t("contact_num") */}
                  <div
                    className="wrapper_line form_wrapper"
                    style={{ padding: "0px 15px 5px" }}
                  >
                    <div className="form-group">
                      {/* TODO: disable this but enable with verify */}
                      <label
                        style={{
                          fontWeight: "500",
                          color: "#143c64",
                          fontSize: "15px",
                          display: "block",
                        }}
                      >
                        {t("contact_num")}
                      </label>
                      <div
                        className="form-group"
                        style={{
                          display: "flex",
                        }}
                      >
                        <input
                          id="countryCode"
                          type="text"
                          className="form-control"
                          placeholder={"+"}
                          name="countryCode"
                          maxLength={5}
                          style={{
                            width: "91px",
                            borderEndEndRadius: "9px",
                            borderStartEndRadius: "9px",
                            marginLeft:
                              localStorage.lang == "ar" ? "10px" : "unset",
                            marginRight:
                              localStorage.lang == "en" ? "12px" : "unset",
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === "." ||
                              e.key === "{" ||
                              e.key === "}" ||
                              e.key === "*"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          value={formik.values.countryCode}
                          onChange={(e) => handleOnChangeCountryCode(formik, e)}
                        />
                        <input
                          id="otp"
                          style={{
                            width: "84%",
                            marginLeft: "16px",
                            borderStartStartRadius: "9px",
                            borderEndStartRadius: "9px",
                            marginLeft:
                              localStorage.lang == "ar" ? "unset" : "unset",
                            width: localStorage.lang == "ar" ? "88.5%%" : "90%",
                          }}
                          type="text"
                          className="form-control"
                          placeholder={t('contact_num')}
                          name="otp"
                          maxLength={15}
                          onKeyDown={(e) => {
                            if (
                              e.key === "." ||
                              e.key === "{" ||
                              e.key === "}" ||
                              e.key === "*"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          value={formik.values.otp}
                          onChange={(e) => handleOnChange(formik, e)}
                        />
                      </div>
                      <div>
                        {formik.errors.countryCode ? (
                          <div>
                            {formik.errors.countryCode ? (
                              <div
                                className="error"
                                style={{
                                  display:
                                    localStorage.lang == "ar" ? "flex" : "unset",
                                  marginRight:
                                    localStorage.lang == "ar" ? "10px" : "unset",
                                }}
                              >
                                {formik.errors.countryCode}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div>
                            {formik.errors.otp ? (
                              <div
                                className="error"
                                style={{
                                  display:
                                    localStorage.lang == "ar" ? "flex" : "unset",
                                  marginRight:
                                    localStorage.lang == "ar" ? "10px" : "unset",
                                }}
                              >
                                {formik.errors.otp}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {/* submit buttons */}
              <div className="subheader" style={{ padding: "0px 15px 39px" }}>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="button-list bottom_button pt-10 focus"
                  style={{ marginLeft: "20px" }}
                >
                  {t("save")}
                </button>
              </div>
            </form>
          </>
        }
      </CardHoc>
    </ContainerAdmin>
  );
};

export default EditUser;
