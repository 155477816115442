import { LOGOUT, USER_DASHBOARD_LOAD } from "./../../actionTypes/actionTypes";


const initialState = {
    isLoading: false,
    error: null,
    status: 0,
    data:
    {
        sixMonths: 0,
        thisMonth: 0,
        thisQuarter: 0,
        thisWeek: 0,
        thisYear: 0,
        totalUsers: 0

    }

}

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT :
            return {
                ...state,
                isLoading: false,
                error: null,
                status: 0,
                data:
                {
                    sixMonths: 0,
                    thisMonth: 0,
                    thisQuarter: 0,
                    thisWeek: 0,
                    thisYear: 0,
                    totalUsers: 0
            
                }
            };
        case USER_DASHBOARD_LOAD:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        
        default:
            return {
                ...state
            }
    }

}

export default dashboardReducer;