import React from 'react'
import NLPAnnotator from 'react-nlp-annotate'

const SegmentationAnnotate = (props) => {
    const {
        paragraph,
        classLists,
particularAnnotation,
segmentAllAnnots,
    }={...props}; 

    const textId = particularAnnotation[0]?.textId ;
    
    segmentAllAnnots.map(item=>{
      if(item.hasOwnProperty('label')){
          if(textId!=item?.textId){
            delete item?.label ;
          };
      };
      
    }); 
 
  return (
    <NLPAnnotator
    hotkeysEnabled 
    type="label-relationships"
    labels={classLists}//labels
    multipleLabels={false}
    document={paragraph}  
    // this is just for label-relationships
    entityLabels={classLists}//labels
    relationshipLabels={classLists}//labels
    initialSequence= {
      segmentAllAnnots
      // particularAnnotation
    } // to show previously done annotations 
  />
  )
}

export default SegmentationAnnotate ;