import * as Yup from "yup";
import i18n from "i18next";

export const constants = {
  formikValidationSchemaAG : (lang) => {
    switch (lang) {
      case "en":
        return Yup.object().shape({
            groupName: Yup.string()
              .matches(
                /^[^\s!#%^*]+[\w\d\s@,.<>:;'"?/\\{}()\]\[\-_*&$]+([-a-zA-Z]+[^\s]+)*[\w\d\s@,.<>:;'"?/\\{}()\]\[\-_*&$]$/,
                i18n.t("plz_entr_vld_grp_name")
              )
              .required(i18n.t("plz_entr_grp_name"))
              .max(15, i18n.t("max_15chars")),
          });
      case "ar":
        return Yup.object().shape({
            groupName: Yup.string()
              .required(i18n.t("plz_entr_grp_name"))
              .max(15, i18n.t("max_15chars")),
          });
      default:
        break;
    }
  },
  formikValidationSchemaEG : (lang)=>{ 
      switch (lang) {
        case "en":
          return Yup.object().shape({
            grpName: Yup.string()
              .matches(
                /^[^\s!#%^*]+[\w\d\s@,.<>:;'"?/\\{}()\]\[\-_*&$]+([-a-zA-Z]+[^\s]+)*[\w\d\s@,.<>:;'"?/\\{}()\]\[\-_*&$]$/,
                i18n.t("plz_entr_vld_grp_name")
              )
              .required(i18n.t("plz_entr_grp_name"))
              .max(15, i18n.t("max_15chars")),
          });
        case "ar":
            return Yup.object().shape({
                grpName: Yup.string()
                  .required(i18n.t("plz_entr_grp_name"))
                  .max(15, i18n.t("max_15chars")),
              });
        default:
          break;
      }
  }
};
