import { Get } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl;

const Fetch = {
  // Common

  // language not done
  skillCategoriesApi() {
    let url = baseUrl + "skill_category/";
    return Get(url);
  } 
  
  // Common 
};

export default Fetch;
