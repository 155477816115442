/* 
  
 tableHeaders : [
      {
        label: "Name",
        headerWidth: "300px",
      },
      {
        label: "Type",
        headerWidth: "300px",
      } 
    ];
tableContents : [
      {
        0: "Task 1",
        1: "Dummy Type" 
      } 
]

*/
import React, { useEffect, useState } from "react";
// import ReportModal from "../../components/UserModule/taskManagement/validatorPendingTaskDeatils/ReportModal";
import { Modal, Table, Rate, Tooltip } from "antd";
// import "antd/dist/antd.css";
import local_Storage from "../../themes/local-storage";
import i18n from "i18next";


const CustomTable = (props) => {
  const [isRatingVisible, setIsRatingModalVisible] = useState(false);

  const [pageSize, setPageSize] = useState(10);

  const [_o, setOrdering] = useState("");
  const {
    tableHeaders = [],
    tableContents = [],
    trClassName = "",
    tdClassName = "",
    tBodyClassName = "",
    tableContentsHasArrayOFObj = false,
    tableContentsIsArray = false,
    tdImageHeight,
    tdImageBorderRadius,
    tdImageWidth,
    handleClick = () => { }, // buttons onclick
    buttonCallbackTrue = true,
    pagination = false,
    paginationVisible = true,
    tableClassName = "",
    paginationCallback = () => { },
    paginationTotal = 0,
    lang,
  } = { ...props };

  const RatingModal = () => {
    return (
      <Modal
        title={i18n.t("rating")}
        open={isRatingVisible}
        onOk={() => {
          setIsRatingModalVisible(false);
        }}
        onCancel={() => {
          setIsRatingModalVisible(false);
        }}
        footer={null}
        centered
        className="rating-modal"
      >
        <div className="text-center  model1" style={{ marginBottom: 40 }}>
          {/* <img src={AppImage.star12} /> */}
          <Rate className="stars test1" allowHalf defaultValue={2.5} />
        </div>
        <div
          className="text-center  model1"
          style={{
            marginBottom: "10px",
          }}
        >
          <button
            type="submit"
            className="button-list top_new"
            onClick={() => setIsRatingModalVisible(false)}
          >
            {i18n.t("done")}
          </button>
        </div>
      </Modal>
    );
  };



  const tableColumns = tableHeaders.map((row, index) => {
    return {
      title: row.label,
      dataIndex: index,
      key: index,
      render: (item2, record, index) => {
        const trIndex = index;
        let item = item2 !== undefined ? item2 : record[0]
        return item.hasOwnProperty("image") ? (
          <div key={trIndex}>
            <img
              src={item.image}
              alt=""
              style={{
                height: tdImageHeight ? tdImageHeight : "50px",
                width: tdImageWidth ? tdImageWidth : "50px",
                borderRadius: tdImageBorderRadius
                  ? tdImageBorderRadius
                  : "10px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
            {item.hasOwnProperty("tooltip") ? (
              <Tooltip
                title={item?.tooltip}
                placement={
                  localStorage.lang == "ar" ? "topRight" : "topLeft"
                }
              >
                <span
                  style={{
                    left: item?.ar ? "auto" : "10px",
                  }}
                >
                  {item?.text}
                </span>
              </Tooltip>
            ) : item?.text ? item?.text : null}
          </div>
        ) : item.hasOwnProperty("button") ? (
          <span
            key={trIndex}
            className={tdClassName}
          >
            {Array.isArray(item?.button) ? (
              item?.button.map((item, index) => {
                return (
                  <button
                    key={index}
                    type="submit"
                    className="button-list accordin"
                    style={item?.customBtnStyle || { fontWeight: "bold" }}
                    onClick={() => {
                      if (buttonCallbackTrue) {
                        handleClick(item?.label, item?.id, null, null, row[1]);
                      } else {
                        if (item?.label == i18n.t("report"))
                          handleClick(item?.label);
                        if (item?.label == i18n.t("rate"))
                          setIsRatingModalVisible(true);
                        if (item?.label == "View Annotations") {
                          handleClick(item?.label);
                          local_Storage.annotaionClassName("set", row[1]);

                        }
                      }
                    }}
                  >
                    {item?.label == "View Annotations"
                      ? i18n.t("view_annotations")
                      : item?.label == "Start Task"
                        ? i18n.t("start_task")
                        : item?.label == "Delete"
                          ? i18n.t("delete")
                          : item?.label == "View"
                            ? i18n.t("view")
                            : item?.label == "Rate"
                              ? i18n.t("rate")
                              : item?.label == "Report"
                                ? i18n.t("report")
                                : item?.label == "Remove"
                                  ? i18n.t("remove")
                                  : item?.button == "Remove Invite"
                                    ? i18n.t("remove_invite")
                                    : null}
                  </button>
                );
              })
            ) : (
              <button
                type="submit"
                className="button-list accordin"
                onClick={() => {
                  handleClick(
                    item?.button,
                    item?.id,
                    item?.link,
                    record,
                    trIndex // table row index
                  );
                }}
                style={item?.customBtnStyle || {}}
              >
                {item?.button == "View Annotations"
                  ? i18n.t("view_annotations")
                  : item?.button == "Start Task"
                    ? i18n.t("start_task")
                    : item?.button == "Delete"
                      ? i18n.t("delete")
                      : item?.button == "View"
                        ? i18n.t("view")
                        : item?.button == "Rate"
                          ? i18n.t("rate")
                          : item?.button == "Report"
                            ? i18n.t("report")
                            : item?.button == "Remove"
                              ? i18n.t("remove")
                              : item?.button == "Remove Invite"
                                ? i18n.t("remove_invite")
                                : item?.button}
              </button>
            )}
          </span>
        ) : (
          item
        );
      },
    };
  });

  useEffect(() => {
    const sorterHeadings = document.querySelectorAll(
      ".ant-table-column-sorters"
    );
    const annotModalTableContent = document.querySelectorAll(
      "td.ant-table-cell"
    );

    if (localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < sorterHeadings.length; i++) {
        sorterHeadings[i].setAttribute(
          "style",
          "flex-direction: row-reverse;text-align:end;"
        );
      }
      for (let i = 0; i < annotModalTableContent.length; i++) {
        annotModalTableContent[i].style.textAlign = "end";
      }
    } else {
      for (let i = 0; i < sorterHeadings.length; i++) {
        sorterHeadings[i].setAttribute("style", "flex-direction: row;");
      }
      for (let i = 0; i < annotModalTableContent.length; i++) {
        annotModalTableContent[i].style.textAlign = "start";
      }
    }
  }, [lang]);

  return !pagination ? (
    <table className={tableClassName ? tableClassName : "text_data"}>
      {tableHeaders.length ? (
        <thead>
          <tr>
            {tableHeaders.map((item, index) => {
              return (
                <th
                  id={index}
                  key={index}
                  style={{
                    width: item?.headerWidth || 100,
                    textAlign: item?.textAlign,
                    paddingLeft: item?.paddingLeft || null,
                    paddingRight: item?.paddingRight || null,
                    fontWeight: item?.fontWeight || "bold",
                  }}
                >
                  {item.label}
                </th>
              );
            })}
          </tr>
        </thead>
      ) : null}

      <tbody className={tBodyClassName}>
        {tableContents.map((items, index) => {
          const row = items;
          const trIndex = index;
          return tableContentsHasArrayOFObj ? (
            <tr className={trClassName} id={trIndex} key={index}>
              {Object.values(row).map((item, index) => {
                const tdIndex = index;
                if (item == null || item == undefined) {
                  item = "";
                }
                return item.hasOwnProperty("image") ? (
                  <td className={
                    tdClassName
                  } id={tdIndex} key={tdIndex}>
                    <img
                      src={item.image}
                      style={{
                        height: tdImageHeight ? tdImageHeight : "50px",
                        width: tdImageWidth ? tdImageWidth : "50px",
                        borderRadius: tdImageBorderRadius
                          ? tdImageBorderRadius
                          : "10px",
                      }}
                    />
                  </td>
                ) : item.hasOwnProperty("button") ? (
                  <td
                    // id={index}
                    key={tdIndex}
                    className={tdClassName}
                    // style={{
                    //   display: item?.tdCustomstyle?.display || "inline-block",
                    //   paddingLeft: item?.tdCustomstyle?.paddingLeft
                    //     ? item?.tdCustomstyle?.paddingLeft
                    //     : "5px",
                    //   paddingRight: item?.tdCustomstyle?.paddingRight
                    //     ? item?.tdCustomstyle?.paddingLeft
                    //     : "5px",
                    //   textAlign: item?.tdCustomstyle?.textAlign || "start",
                    // }}
                    id={tdIndex}
                  >
                    {Array.isArray(item?.button) ? (
                      item?.button.map((item, index) => {
                        return (
                          <button
                            key={index}
                            type="submit"
                            className="button-list accordin"
                            style={
                              item?.customBtnStyle || { fontWeight: "bold" }
                            }
                            onClick={() => {
                              if (buttonCallbackTrue) {
                                handleClick(
                                  item?.label,
                                  item?.id,
                                  null,
                                  null,
                                  row[1]
                                );
                              } else {
                                if (item?.label == i18n.t("report"))
                                  handleClick(item?.label);
                                if (item?.label == i18n.t("rate"))
                                  setIsRatingModalVisible(true);
                                if (item?.label == "View Annotations") {
                                  handleClick(item?.label);
                                  local_Storage.annotaionClassName(
                                    "set",
                                    row[1]
                                  );

                                }
                              }
                            }}
                          >
                            {item?.label == "View Annotations"
                              ? i18n.t("view_annotations")
                              : item?.label == "Start Task"
                                ? i18n.t("start_task")
                                : item?.label == "Delete"
                                  ? i18n.t("delete")
                                  : item?.label == "View"
                                    ? i18n.t("view")
                                    : item?.label == "Rate"
                                      ? i18n.t("rate")
                                      : item?.label == "Report"
                                        ? i18n.t("report")
                                        : item?.label == "Remove"
                                          ? i18n.t("remove")
                                          : item?.button == "Remove Invite"
                                            ? i18n.t("remove_invite")
                                            : null}
                          </button>
                        );
                      })
                    ) : (
                      <button
                        type="submit"
                        className="button-list accordin"
                        onClick={() => {
                          handleClick(
                            item?.button,
                            item?.id,
                            item?.link,
                            row[1],
                            trIndex // table row index
                          );
                        }}
                        style={item?.customBtnStyle || {}}
                      >
                        {item?.button == "View Annotations"
                          ? i18n.t("view_annotations")
                          : item?.button == "Start Task"
                            ? i18n.t("start_task")
                            : item?.button == "Delete"
                              ? i18n.t("delete")
                              : item?.button == "View"
                                ? i18n.t("view")
                                : item?.button == "Rate"
                                  ? i18n.t("rate")
                                  : item?.button == "Report"
                                    ? i18n.t("report")
                                    : item?.button == "Remove"
                                      ? i18n.t("remove")
                                      : item?.button == "Remove Invite"
                                        ? i18n.t("remove_invite")
                                        : null}
                      </button>
                    )}
                  </td>
                ) : (
                  <td
                    key={tdIndex}
                    className={tdClassName}
                    id={tdIndex}
                    style={{ position: "relative" }}
                  >
                    <Tooltip
                      title={i18n.t(item)}
                      placement={
                        localStorage.lang == "ar" ? "topRight" : "topLeft"
                      }
                    >
                      {i18n.t(item)}
                    </Tooltip>
                  </td>
                );
              })}
            </tr>
          ) : tableContentsIsArray ? (
            <tr id={index} className={trClassName} key={index}>
              <td className={tdClassName}>{row}</td>
            </tr>
          ) : null;
        })}
      </tbody>
      {/* <ReportModal /> */}
      <RatingModal />
    </table>
  ) : (
    <>
      <Table
        className={tableClassName ? tableClassName : ""}
        showSorterTooltip={false}
        columns={tableColumns}
        dataSource={tableContents}
        rowClassName={trClassName}
        pagination={paginationVisible ? {
          total: paginationTotal,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          pageSize: pageSize,
          defaultCurrent: 1,
          position: ["bottomRight"],
        } : false}
        onChange={(pagination, filters, sorter) => {
          if (sorter.order == undefined) {
            setOrdering("");
          }
          if (
            sorter.order == "ascend" &&
            sorter.order != undefined &&
            sorter.columnKey == "Projectname"
          ) {
            setOrdering("-name");
          }
          //page
          if (paginationCallback) {
            setPageSize(pagination.pageSize);
            paginationCallback(pagination);
          }
        }}
      />
    </>
  );
};

export default CustomTable;
