import { Delete, Get, Post, Put, UpdatePost } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl; // "https://beta.WosoM.ai/api/"

const Fetch = { 
  // Owner
   
  shouldMemberBeRemovedApi(payload) {
    let url = baseUrl + `user_delete_status`;
    
    return Post(payload,url);
  } ,
   
  getGroupListsApi(payload) {
    let pageNum = payload.pageNum!='' ? payload.pageNum : 1 ;
    let ordering = payload.ordering!='' ? payload.ordering : '' ;
    let searchValue = payload.searchValue!='' ? payload.searchValue : '' ;
    let url; 
    url = baseUrl + `group_listing?limit=${payload.limit ? payload.limit :10}&offset=${(pageNum*10)-10}&ordering=${ordering}&search=${searchValue}` ;//
    
    const auth = true ;
    return Get(url,auth);
  } ,
  createGroupApi(payload) {
    let url = baseUrl + "create_group/"; 
    return UpdatePost(payload,url);
  },
  updateGroupDataApi(payload) {
    let url = baseUrl + `create_group/${payload.groupId}`;
    delete payload.groupId ;  
    return Put(payload,url,true);
  },
  deleteGroupDataApi(groupId) { 
    let url = baseUrl + `create_group/${groupId}`;
    return Delete(url,true); 
  },
  getGroupInvitationsApi(groupId) { 
    let url = baseUrl + `non_users_group/${groupId}`;
    const auth = true ;
    return Get(url,auth);  
  },
};

export default Fetch;
