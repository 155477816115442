import { Get, Post } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl;

const Fetch = {
  // DASHBOARD
  // USER not done
  userDashboardWorkerApi(payload) {
    let url = baseUrl + "dashboard/performer/";
    return Post(payload, url);
  },
  userDashboardValidatorApi() {
    let url = baseUrl + "user_quicklinks/";
    return Get(url, true);
  },
  // OWNER not done
  ownerDashboardStatisTicsApi() {
    let url = baseUrl + "owner_stats/";
    return Get(url, true);
  },
  ownerDashboardQuickLinksApi() {
    let url = baseUrl + "owner_quicklinks/";
    return Get(url, true);
  },

  ownerDashboardApi(payload) {
    let url = baseUrl + "dashboard/owner/";
    return Post(payload, url);
  },
  // ADMIN
  adminDashboarQuickLinksdApi(payload) {
    let url = baseUrl + "admin/dashboard/";
    return Post(payload, url);
  },
  adminDashboarStatisticsdApi() {
    let url = baseUrl + "admin_stats/";
    return Get(url, true);
  },
  getOwnerListAdminApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let searchValue = payload.searchValue != "" ? payload.searchValue : "";
    let url;
    url =
      baseUrl +
      `admin_user_or_owner_listing?&type=2&limit=${10}&offset=${pageNum * 10 -
      10}&ordering=${ordering}&search=${searchValue ? searchValue : ""}`; //

    const auth = true;
    return Get(url, auth);
  },
  getUserListAdminApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let searchValue = payload.searchValue != "" ? payload.searchValue : "";
    let user_id = payload.user_id != "" ? payload.user_id : "";
    let is_update = payload.first_name != undefined;
    let url;
    url =
      baseUrl +
      `admin/users/?&type=3&limit=${10}&offset=${pageNum * 10 -
      10}&ordering=${ordering}&search=${searchValue}&user_id=${user_id}`; //
    if (is_update) {
      url += '&is_update=true'
    }
    return Post(payload, url);
  },
  impersonateUserApi(payload) {
    let url = baseUrl + `admin/users/impersonate/`;
    return Post(payload, url);
  },
  getProjCreatedAdminApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let url;
    url =
      baseUrl +
      `admin_project_created_or_completed_listing?type=1&limit=${10}&offset=${pageNum *
      10 -
      10}&ordering=${ordering}`; //
    const auth = true;
    return Get(url, auth);
  },
  getProjCompletedAdminApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let url =
      baseUrl +
      `admin_project_created_or_completed_listing?type=2&?limit=${10}&offset=${pageNum *
      10 -
      10}&ordering=${ordering}`; //
    const auth = true;
    return Get(url, auth);
  },
  getImagesAdminApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let url =
      baseUrl +
      `admin_media_listing?limit=${10}&offset=${pageNum * 10 -
      10}&ordering=${ordering}`; //
    const auth = true;
    return Get(url, auth);
  },
  getAnnotationAdminApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let url =
      baseUrl +
      `admin_annotation_listing?limit=${10}&offset=${pageNum * 10 -
      10}&ordering=${ordering}`; //
    const auth = true;
    return Get(url, auth);
  },
  // DASHBOARD
};

export default Fetch;
