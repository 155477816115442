import { UPDATE_PROFILE,GET_USER_PROFILE, SET_USER_PROFILE,PROFILE_COMPLETION } from "../actionTypes/actionTypes"

// done
export const getUserProfile = (payload) => {
    return {
        type: GET_USER_PROFILE, 
        payload: payload 
    }

}
export const setUserProfileInStore = (payload) => {
    return {
        type: SET_USER_PROFILE,
        payload: payload 
    } 
}

export const profileCompletion = (payload) => { 
    return{
        type : PROFILE_COMPLETION,
        payload : payload
    }
}
  
// user 
export const udateUserProfile = (payload,navigate) => { 
    return{
        type : UPDATE_PROFILE,
        payload : payload,
        navigate
    }
}