import { Modal, Table, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { CustomEmptyDiv } from "../../../../../../molecules";
import { NavigateTo, SidebarMenus } from "../../../../../../themes";

const AnnotationModal = (props) => {
  const {
    classNames,
    imageArray,
    imageIndex,
    annotationTextDS,
    classLists,
    annotationDS,
    isModalVisible,
    annotationAudioDS,
    hideModal = () => { },
  } = { ...props };

  const navigate = useNavigate();
  const { t } = useTranslation();
  const taskId = useSelector((state) => state?.userTaskManagement?.taskId);

  const udtBreadCrumbsEn = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: "Dashboard",
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: "Task Management",
    },
    {
      link: NavigateTo.WORKER_COMPLETED_TASK_DETAILS + `/${taskId}`,
      label: "Worker Completed Task Details",
    },
    {
      link: "",
      label: "Annotate",
    },
  ];
  const udtBreadCrumbsAr = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: "لوحة القيادة",
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: "ادارة المهام",
    },
    {
      link: NavigateTo.WORKER_COMPLETED_TASK_DETAILS + `/${taskId}`,
      label: "تفاصيل المهمة المنجزة للعامل",
    },
    {
      link: "",
      label: "وسم",
    },
  ];
  const udtContainerProps = {
    breadCrumbItems: {
      en: udtBreadCrumbsEn,
      ar: udtBreadCrumbsAr,
    },
    menuProps: SidebarMenus("user"),
    active: t("task_management"),
  };
  // image table columns
  const annotationC = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      width: 150,
      render: (value, item, index) => index + 1,
    },
    {
      title: t("annot_name"),
      dataIndex: "annotation_name",
      key: "annotation_name",
      width: 200,
      sorter: false,
      render: (value) => {
        return <Tooltip title={t(value)}>{t(value)}</Tooltip>;
      },
    },
    {
      title: t("file_id"),
      dataIndex: "file_id",
      key: "file_id",
      width: "14%",
      sorter: false,
      render: (value) => {
        const tempVal =
          localStorage.lang == "ar"
            ? value
              .split("")
              .reverse()
              .concat("")
            : value;

        return (
          <Tooltip
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
            title={tempVal}
          >
            {tempVal}
          </Tooltip>
        );
      },
    },
    {
      title: t("class"),
      dataIndex: "class",
      key: "class",
      width: 250,
      sorter: false,
    },

    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text, record) => {
        return (
          <div
            style={{
              marginLeft: "12px",
            }}
          >
            {/* view button */}
            <button
              type="button"
              className="focus"
              onClick={() => {
                navigate(NavigateTo.USER_UDT + `/${taskId}`, {
                  state: {
                    udtContainerProps,
                    taskType: localStorage.getItem("taskType"),
                    removeIcos: true,
                    classLists: classNames,
                    images: imageArray,
                    imageIndex: imageIndex,
                    particularAnnotation: record?.annotDetails, //annotDetails,
                  },
                });
              }}
            >
              {t("view")}
            </button>
          </div>
        );
      },
    },
  ];

  //audio table columns.
  const annotationAudioC = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      width: 150,
      render: (value, item, index) => index + 1,
    },
    {
      title: t("annot_name"),
      dataIndex: "annotation_name",
      key: "annotation_name",
      width: 200,
      sorter: false,
    },

    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text, record, index) => {
        return (
          <div
            style={{
              marginLeft: "12px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* view button */}
            <button
              type="button"
              className="focus"
              onClick={() => {
                if (localStorage.getItem("taskType") == "Audio") {
                  let singleAudio = [
                    {
                      url: record?.url,
                      jsonFile: record?.jsonFile, // jsonFile
                      task_id: 1,
                      anotation_id: 1,
                      worker_id: 1,
                    },
                  ];

                  navigate(NavigateTo.USER_UDT + `/${taskId}`, {
                    state: {
                      taskType: localStorage.getItem("taskType"),
                      udtContainerProps,
                      audio: singleAudio,
                    },
                  });
                }
                if (localStorage.getItem("taskType") == "Text") {
                  if (localStorage.getItem("textType") == "questions") {
                    navigate(NavigateTo.USER_UDT + `/${taskId}`, {
                      state: {
                        taskType: localStorage.getItem("taskType"),
                        udtContainerProps,
                        pdfUrl: record?.pdfUrl,
                        questions: record?.questions,
                        particularAnnotation: record?.savedAnnotation,
                        pdfUrl: record?.pdfUrl,
                        qnindex: index,
                      },
                    });
                  }
                  if (localStorage.getItem("textType") == "segmentation") {
                    navigate(NavigateTo.USER_UDT + `/${taskId}`, {
                      state: {
                        taskType: localStorage.getItem("taskType"),
                        udtContainerProps,
                        particularAnnotation: record?.particularAnnot,
                        segmentAllAnnots: record?.segmentAllAnnots,
                        classes: classLists,
                        paragraph: record?.paragraph,
                      },
                    });
                  }
                }
              }}
            >
              {t("view")}
            </button>
          </div>
        );
      },
    },
  ];


  return (
    <>
      {/* annotation modal */}
      <Modal
        title={t("annot_list")}
        centered
        footer={null}
        onOk={() => {
          hideModal(false);
        }}
        width="75%"
        onCancel={() => {
          {
            hideModal(false);
          }
        }}
        open={isModalVisible}
        wrapClassName="poolworkers"
        className={
          localStorage.lang == "ar"
            ? "validator-pending-annot-modal new_layout ar"
            : "validator-pending-annot-modal new_layout en"
        }
      >
        {
          <div
            className={`text-center modal2 remove-left-pad vct-modal  AnnotClassName `}
          >
            <div className="form-group wrapper_line models">
              {annotationAudioDS.length ||
                annotationDS?.length ||
                annotationTextDS?.length ? (
                <Table
                  pagination={true}
                  columns={
                    localStorage.getItem("taskType") == "Audio"
                      ? annotationAudioC
                      : localStorage.getItem("taskType") == "Image"
                        ? annotationC
                        : annotationAudioC
                  }
                  showSorterTooltip={false}
                  dataSource={
                    localStorage.getItem("taskType") == "Audio"
                      ? annotationAudioDS
                      : localStorage.getItem("taskType") == "Image"
                        ? annotationDS
                        : annotationTextDS
                  }
                  className="udt worker-completed-taskdetails-annot worker-annot-completed"
                />
              ) : (
                <CustomEmptyDiv title={t("annots_wil_appear_here")} />
              )}
            </div>
          </div>
        }
      </Modal>
    </>
  );
};

export default AnnotationModal;
