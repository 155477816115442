import {
  Constants,
  fieldIds,
  AppImage,
} from "../../../themes/index";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import React, { useRef, useState } from "react";
import TextField from "../../../molecules/Textfield";
import { registerUserAction } from "../../../redux/actions/Actions";
import ValidationConstants from "../../../themes/validationConstants";
import { customFunctions } from "../../../themes/utils/CustomFunctions";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
// import { Tabs } from "antd";
import { disableProfileCompletion } from "../../../redux/actions/Common";
import { useTranslation } from "react-i18next";
const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [background, setBackground] = useState(AppImage.registerProfile);
  const [uploadedImage, setImage] = useState(null);
  const [user_type, _setUserType] = useState("performer");

  const firstnameRef = useRef(null);

  const onSubmit = (values) => {
    localStorage.setItem("isRegistration", "true");
    const payload = {
      body: {
        email: (values.email).toLowerCase(),
        password: values.password,
        first_name: customFunctions.capitalizeFirstLetter(values.firstName),
        last_name: customFunctions.capitalizeFirstLetter(values.lastName),
        phone_number: values.otp,
        country_code: values.countryCode,
        image: uploadedImage,
        user_type: user_type,
      },
      success: (res) => {
        localStorage.setItem("isFormData", "false");
        localStorage.setItem("isRegistration", "false");
        localStorage.setItem("userId", res?.user?.id);
        localStorage.setItem("registerToken", res?.token);
        localStorage.setItem("showProfileCompletion", "true");
        // navigate(`/profilecompletion/${res?.token}`);
        dispatch(disableProfileCompletion(true));
        if (res?.user_type == "performer") {
          localStorage.setItem("isPerformer", "true");
          localStorage.setItem("profile_uid", res?.uid);
          localStorage.setItem("profile_token", res?.token);
          navigate(`/profile_completion`);
        }
        else {
          //toast register success and time out
          toast.success(t("register_success"), {
            className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
            toastId: "registerToast",
          });
          setTimeout(() => {
            navigate(`/login`);

          }, 3000);
        }
      },
      fail: (resf) => {
        toast.error(resf?.result.errors.email[0], {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
          toastId: "registerToast",
        });
      },
    };
    isInternetConnected() && dispatch(registerUserAction(payload))
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqEmail),
        (value) => value?.trim()?.length > 0
      )
      .matches(
        /^(([a-zA-Z0-9]+)||([a-zA-Z0-9_.-]+[a-zA-Z0-9]+))@([0-9a-zA-Z]+)([^0-9])([0-9a-zA-Z]+)||([a-zA-Z0-9])(\.[a-zA-z]{2,5})$ /,
        t(ValidationConstants.valEmail)
      )
      .email(t(ValidationConstants.valEmail))
      .required(t(ValidationConstants.reqEmail))
      .max(320, t("Maximum 320 characters are allowed.")),
    password: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqPassword),
        (value) => value?.trim()?.length > 0
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};\':"\\|,.<>\/?])[A-Za-z\d!@#$&()\\-`.+,/\"^*?%-\[\]=,<>{}]{8,1000}$/,
        t(ValidationConstants.passValid)
      )
      .required(t(ValidationConstants.reqPassword)),

    firstName: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqFirstName),
        (value) => value?.trim()?.length > 0
      )
      .matches(
        /^[a-zA-Z.'\s]+$/,
        t(ValidationConstants.firstNameValidate)
      )
      .required(t(ValidationConstants.reqFirstName))
      .max(20, t("Maximum 20 characters are allowed."))
      .min(2, t("First name must be minimum 2 characters long.")),
    lastName: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqLastName),
        (value) => value?.trim()?.length > 0
      )
      .matches(/^[a-zA-Z.']+$/, t(ValidationConstants.lastNameValidate))
      .required(t(ValidationConstants.reqLastName))
      .max(20, t("Maximum 20 characters are allowed."))
      .min(2, t("Last name must be minimum 2 characters long.")),

    // for contact num
    otp: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqContactNum),
        (value) => value?.trim()?.length > 0
      )
      .matches(/^\+?\d{10,15}$/, t(ValidationConstants.contactNum))
      .required(t(ValidationConstants.reqContactNum)), // for contact num

    confirmPassword: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqConfirmPassword),
        (value) => value?.trim()?.length > 0
      )
      .matches(/^(\S+$)/, t(ValidationConstants.noSpace))
      .oneOf(
        [Yup.ref("password")],
        t(ValidationConstants.matchPassRegister)
      ) // for password matching with upper field
      .required(t(ValidationConstants.reqConfirmPassword)),

    countryCode: Yup.string()
      .test(
        "trim",
        "Please enter country code .",
        (value) => value?.trim()?.length > 0
      )
      .matches(
        /^[+][^0]\d{0,3}$/,
        t("Please enter a valid country code.")
      )
      .required(t("Please enter country code.")), // for contact num
  });
  const initialValues = {
    email: "",
    lastName: "",
    firstName: "",
    password: "",
    contactNum: "",
    confirmPassword: "",
    otp: "", /// for contact num
    countryCode: "+"
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    // validate
  });
  const formFields1 = [
    {
      type: "text",
      id: fieldIds.FIRSTNAME,
      value: customFunctions.capitalizeFirstLetter(formik.values.firstName.trimLeft()),
      error: formik.errors.firstName,
      placeholder: Constants.FirstName,
      fieldRef: firstnameRef,
      inputMaxLength: 20,
      onChange: formik.handleChange,
    },
    {
      type: "text",
      id: fieldIds.LASTNAME,
      value: customFunctions.capitalizeFirstLetter(formik.values.lastName.trimLeft()),
      error: formik.errors.lastName,
      placeholder: Constants.LastName,
      inputMaxLength: 20,
      // onKeyDownTrue: true,
      onChange: formik.handleChange,
    },
    {
      type: "email",
      id: fieldIds.EMAIL,
      value: formik.values.email.trimLeft(),
      error: formik.errors.email,
      placeholder: Constants.EmailAddress,
      inputMaxLength: 320,
      onChange: formik.handleChange,
    },
  ];
  const formFields2 = [
    {
      type: "password",
      id: fieldIds.PASSWORD,
      value: formik.values.password.trimLeft(),
      error: formik.errors.password,
      placeholder: Constants.Password,
      inputMaxLength: 1000,
      onKeyDownTrue: true,
      onChange: formik.handleChange,
    },
    {
      type: "password",
      id: fieldIds.CONFIRM_PASSWORD,
      value: formik.values.confirmPassword.trimLeft(),
      error: formik.errors.confirmPassword,
      placeholder: Constants.ConfirmPassword,
      inputMaxLength: 1000,
      onKeyDownTrue: true,
      onChange: formik.handleChange,
    },
  ];
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      setImage(img);
      const fileType = img.type.split("/")[0];
      const fileSize = img.size; //size is in bytes

      /*
       check here if FileType is other than IMAGE then show alert and 
       if FileSize is greater than 2MB then show alert.
      */
      fileType == "image"
        ? customFunctions.FileSizeConverter(fileSize, "MB") > 1.9
          ? toast.error(`${t('size_less_than_2Mb')}`, {
            className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
            toastId: "wrongSize",
          })
          : setBackground(URL.createObjectURL(img))
        : toast.error(t("upload_file_types"), {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
          toastId: "wrongFile",
        });
    }
  };
  const handleOnChange = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("otp", e.target.value);
      }
    } else {
      formik.setFieldValue("otp", "");
    }
  };
  const handleOnChangeCountryCode = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}+\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("countryCode", temp);
      }
    } else {
      formik.setFieldValue("countryCode", "");
    }
  };

  return (
    <div className="image-bg">
      <div className="page-header">
        <div
          className="line_true stopOverFlow"
          style={{
            position: "unset",
            transform: "unset",
            margin: "57px auto 0",
          }}
        >
          <div className="content-e ">

            <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <div className="text-center cost">
                <img src={AppImage.logo3} width={"35%"} />
                <h6 className="text-white text-capitalize ps-3 headg_d">
                  {Constants.Register}
                </h6>
                {/* <Tabs
                  defaultActiveKey="1"
                  size="large"
                  centered
                  //on change change setUserType
                  onChange={(key) => {
                    setUserType(key === "1" ? "performer" : "owner");
                  }}
                  items={[
                    {
                      label: `Performer`,
                      key: 1,
                    },
                    {
                      label: `Owner`,
                      key: 2,
                    }
                  ]}
                /> */}
                <Tooltip
                  title={
                    uploadedImage == null
                      ? Constants.ctayp
                      : "Click to change your photo. "
                  }
                  key={"#143C64"}
                  placement="right"
                >
                  <input
                    id={"register-upload"}
                    accept={".png,.jpg,.jpeg,.svg"}
                    style={{
                      display: "none",
                    }}
                    type="file"
                    name="uploadImage"
                    onChange={(event) => {

                      handleImageChange(event);
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <label
                    htmlFor="register-upload"
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      style={{
                        marginTop: "-15px",
                        marginBottom: "15px",
                        cursor: "pointer",
                        width: "130px",
                        height:
                          background != AppImage.registerProfile
                            ? "130px"
                            : null,
                        borderRadius:
                          background != AppImage.registerProfile
                            ? "130px"
                            : null,
                      }}
                      width="20%"
                      id="base-image"
                      src={background}
                    />
                    <div

                      style={{
                        position: "absolute",
                        right: "3px",
                        bottom: "-68px",
                        borderRadius: 38,
                        cursor: "pointer",
                      }}
                    >
                      <img src={AppImage.plusIcon} width={38} height={38} />
                    </div>
                  </label>
                </Tooltip>
              </div>
            </div>

            <form noValidate onSubmit={formik.handleSubmit}>
              <div className="wrapper_line login">
                {formFields1.map((items) => {
                  return (
                    <>
                      {/* <a href="#" alt={items.placeholder} class="tooltip"> */}
                      <TextField
                        formik={formik}
                        inputTrue={true}
                        {...items}
                      />
                      {/* </a> */}
                    </>
                  );
                })}

                <div className="form-group"
                  style={{
                    display: 'flex',

                  }}
                >
                  {/* <label>Password</label> */}
                  <input
                    id="countryCode"
                    type="text"
                    className="form-control"
                    placeholder={'+'}
                    name="countryCode"
                    maxLength={5}
                    style={{
                      width: '91px',
                      "borderEndEndRadius": "9px", "borderStartEndRadius": "9px"
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "." ||
                        e.key === "{" ||
                        e.key === "}" ||
                        e.key === "*"
                      ) {

                        e.preventDefault();
                      }
                    }}
                    autoComplete="off"
                    value={formik.values.countryCode}
                    onChange={(e) => handleOnChangeCountryCode(formik, e)}
                  />
                  <input
                    id="otp"
                    style={{
                      "width": "84%", "marginLeft": "16px", "borderStartStartRadius": "9px", "borderEndStartRadius": "9px"
                    }}
                    type="text"
                    className="form-control"
                    placeholder={Constants.ContactNum}
                    name="otp"
                    maxLength={15}
                    onKeyDown={(e) => {
                      if (
                        e.key === "." ||
                        e.key === "{" ||
                        e.key === "}" ||
                        e.key === "*"
                      ) {

                        e.preventDefault();
                      }
                    }}
                    autoComplete="off"
                    value={formik.values.otp}
                    onChange={(e) => handleOnChange(formik, e)}
                  />
                </div>
                <div>
                  {
                    formik.errors.countryCode
                      ? <div>

                        {formik.errors.countryCode ? (
                          <div className="error">{formik.errors.countryCode}</div>
                        ) : null}
                      </div>
                      :
                      <div>

                        {formik.errors.otp ? (
                          <div className="error">{formik.errors.otp}</div>
                        ) : null}
                      </div>
                  }
                </div>

                {formFields2.map((items) => {
                  return (
                    <>
                      {/* <a href="#" alt={items.placeholder} class="tooltip"> */}
                      <TextField
                        formik={formik}
                        inputTrue={true}
                        // onChange={formik.handleChange}
                        {...items}
                      />
                      {/* </a> */}
                    </>
                  );
                })}
                <div className="data_line">
                  <button type="submit" className="button-list focus">
                    {Constants.Register}
                  </button>
                </div>

                <div></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
