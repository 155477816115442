import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { ContainerAdmin } from "../../../hoc";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { CustomEmptyDiv } from "../../../molecules";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Pagination, Table, Tooltip } from "antd";
import { NavigateTo, SidebarMenus } from "../../../themes";
import { getOwnerListAdmin } from "../../../redux/actions/Dashboard";
import { blockUnblockOwner, deleteOwner } from "../../../redux/actions/Owner";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";

const OwnerManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [ordering, setOrdering] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [pageNum, setPageNumOwner] = useState(1); // owner
  const [totalOwners, setTotalOwners] = useState(0); // owner
  const [isModalVisibleBlock, setIsModalVisibleBlock] = useState(false); // block modal
  const [isModalVisible, setIsModalVisible] = useState(false); // delete modal
  const [blockUnblockLabel, setBlockUnblockLabel] = useState(""); // delete modal
  const [addedOwnerList, setAddedOwnerList] = useState([]); // delete modal

  const ownerList = useSelector((state) => state?.ownerManagement?.ownerList);

  const breadCrumbItems = [
    {
      link: NavigateTo.ADMIN_DASH_BOARD,
      label: t("dashboard"),
    },
    {
      link: "",
      label: t("owner_mngmnt"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("admin"),
    active: t("owner_mngmnt"),
  };

  // addedOwners; profile,name^,email^,Contact^
  const columns = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => pageNum * 10 - 10 + (index + 1),
    },
    {
      title: t("owner_name"),
      dataIndex: "name",
      key: "name",
      width: 200,
      sorter: true,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("email_address"),
      dataIndex: "email",
      key: "email",
      width: 200,
      sorter: true,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("contact_num"),
      dataIndex: "Contact",
      key: "Contact",
      width: 200,
      sorter: true,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "Posted",
      key: "Posted",
      render: (val, record) => {
        return (
          <div className="tex-center_">
            <button
              className="focus"
              type="button"
              onClick={() => {
                navigate(NavigateTo.ADMIN_OWNER_DETAILS);
                localStorage.setItem("ownerId", record?.id);
              }}
            >
              {t("view")}
            </button>
            <button
              className="focus"
              type="button"
              onClick={() => {
                navigate(NavigateTo.ADMIN_EDIT_DETAILS);
                localStorage.setItem("ownerId", record?.id);
              }}
            >
              {t("edit")}
            </button>

            <button
              className="focus"
              type="button"
              onClick={() => {
                if (record?.isBlocked == 0) {
                  setBlockUnblockLabel(t("block"));
                } else {
                  setBlockUnblockLabel(t("unblock"));
                }
                setUserId(record?.id);
                setIsModalVisibleBlock(true);
              }}
            >
              {record?.isBlocked == 0 ? t("block") : t("unblock")}
            </button>
            <button
              className="focus"
              type="button"
              onClick={() => {
                setUserId(record?.id);
                setIsModalVisible(true);
              }}
            >
              {t("delete")}
            </button>
          </div>
        );
      },
    },
  ];
  const payloadOwnerList = {
    body: {
      pageNum: searchValue ? 1 : pageNum,
      ordering,
      searchValue,
    },
  };
  useEffect(() => {
    setTotalOwners(ownerList[0]?.total);
    setAddedOwnerList(ownerList);
  }, [ownerList]);

  useEffect(() => {
    document.title = "WosoM";
    isInternetConnected() && dispatch(getOwnerListAdmin(payloadOwnerList));
  }, [pageNum, ordering, searchValue]);
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isModalVisible, isModalVisibleBlock]);

  return (
    <ContainerAdmin
      {...containerProps}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      {/* button and search bar */}
      <div className="top_button_item d-flex justify-content-between">
        <div
          className={
            // localStorage.getItem('lang')=='ar' ?
            // "button_left contain admin-search-container-ar" :
            "button_left contain "
          }
        >
          <Link to={NavigateTo.ADMIN_ADD_OWNER_DETAILS}>
            <button type="button" className="button-list top_new focus">
              {t("add_new_owner")}
            </button>
          </Link>
          <input
            type="text"
            className="search-bar"
            placeholder={t("search")}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            autoComplete={"off"}
          />
        </div>
      </div>
      {/* button and search bar */}

      {/* added owner table */}
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 300, paddingTop: "0px" }}
      >
        <div className="content-e owner_content">
          {/* table header*/}
          {addedOwnerList.length != 0 ? (
            <>
              <div
                className={
                  "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line"
                }
              >
                <h6 className="text-white text-capitalize ps-3">
                  {t("owner_mngmnt")}
                </h6>
              </div>

              <Table
                className={ 
                  "admin-owner-management-table"
                }
                dataSource={addedOwnerList}
                pagination={false}
                columns={columns}
                showSorterTooltip={false}
                onChange={(pagination, filters, sorter) => {
                  // profile,name^,email^,Contact^
                  if (sorter.order == undefined) {
                    setOrdering("");
                  }
                  if (sorter.order == "ascend" && sorter.columnKey == "name") {
                    setOrdering("first_name");
                  }
                  if (sorter.order == "descend" && sorter.columnKey == "name") {
                    setOrdering("-first_name");
                  }
                  if (sorter.order == "ascend" && sorter.columnKey == "email") {
                    setOrdering("email");
                  }
                  if (
                    sorter.order == "descend" &&
                    sorter.columnKey == "email"
                  ) {
                    setOrdering("-email");
                  }
                  if (
                    sorter.order == "ascend" &&
                    sorter.columnKey == "Contact"
                  ) {
                    setOrdering("phone_no");
                  }
                  if (
                    sorter.order == "descend" &&
                    sorter.columnKey == "Contact"
                  ) {
                    setOrdering("-phone_no");
                  }
                }}
              />
              <Pagination                
                  showTitle = {false}
                style={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  maxWidth: "fit-content",
                  right: "33px",
                  left: "auto",
                  position: "absolute",
                }}
                className="pagination-component"
                defaultCurrent={1}
                total={totalOwners} //
                size="medium"
                onChange={(val) => {
                  setPageNumOwner(val);
                }}
              />
            </>
          ) : (
            <CustomEmptyDiv
              containerHeight="280px"
              title={t("added_owners_appear_here")}
            />
          )}
        </div>
      </div>
      {/* table */}

      {/* delete modal */}
      <Modal
        title={t("delete")}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className="success_data delete"
        centered
      >
        {/* delete title */}
        <div className="text-center  model1">
          <p>{t("u_sure_to_dlt_this_owner")}</p>
        </div>
        <div
          className="text-center  model1 focus"
          style={{
            direction: localStorage.lang == "ar" ? "rtl" : "ltr",
          }}
        >
          {/* yes button */}
          <button
            type="button"
            className="button-list top_new focus"
            onClick={() => {
              const deletePayload = {
                id: userId,
              };
              isInternetConnected() && dispatch(deleteOwner(deletePayload));
              isInternetConnected() &&
                dispatch(getOwnerListAdmin(payloadOwnerList));
              setIsModalVisible(false);
            }}
          >
            {t("yes")}
          </button>
          {/* no button */}
          <button
            type="button"
            className="focus"
            onClick={() => setIsModalVisible(false)}
          >
            {t("no")}
          </button>
        </div>
      </Modal>

      {/* block modal */}
      <Modal
        title={blockUnblockLabel == t("block") ? t("block") : t("unblock")}
        open={isModalVisibleBlock}
        onOk={() => setIsModalVisibleBlock(false)}
        onCancel={() => setIsModalVisibleBlock(false)}
        footer={null}
        className="success_data delete"
        centered
      >
        <div className="text-center  model1">
          <p>
            {blockUnblockLabel == t("block")
              ? t("u_sure_to_block_this_owner")
              : t("u_sure_to_unblock_this_owner")}
          </p>
        </div>
        <div
          className="text-center  model1"
          style={{
            direction: localStorage.lang == "ar" ? "rtl" : "ltr",
          }}
        >
          <button
            type="button"
            className="button-list top_new focus"
            onClick={() => {
              console.log(blockUnblockLabel,'blockUnblockLabel');
              const blockUnblockPayload = JSON.stringify({
                user_id: userId,
                block_type: blockUnblockLabel == t("block") ? 1 : 0, // block 1 unblock  0
                user_type: 2, // 2 (owner) 3 (user)
              });
              isInternetConnected() &&
                dispatch(blockUnblockOwner(blockUnblockPayload));
              isInternetConnected() &&
                dispatch(getOwnerListAdmin(payloadOwnerList));
              setIsModalVisibleBlock(false);
            }}
          >
            {t("yes")}
          </button>
          <button
            type="button"
            className="focus"
            onClick={() => setIsModalVisibleBlock(false)}
          >
            {t("no")}
          </button>
        </div>
      </Modal>

      {/* do the unblock modal*/}
    </ContainerAdmin>
  );
};

export default OwnerManagement;
