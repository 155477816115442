import * as Yup from "yup";
import {
  Modal,
  Select,
  Table,
  Radio,
  Rate,
  Tooltip,
  Slider,
  InputNumber,
  Row,
  Col,
  Switch,
  Button,
  Steps,
  Upload,
  Popover,
} from "antd";
import TaskPreviewModal from "./taskPreviewModal";
import { useFormik } from "formik";
import { TagsOutlined, SafetyOutlined, UsergroupAddOutlined, CloudUploadOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { AppImage } from "../../../themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import  CKEDITOR from 'https://cdn.ckeditor.com/4.19.0/standard-all/ckeditor.js'
import { useTranslation } from "react-i18next";
import { CustomEmptyDiv, TextField, CustomHelp } from "../../../molecules";
import { Pagination } from "antd";

import { useDispatch } from "react-redux";
import { createTask, getTaskPreview } from "../../../redux/actions/Tasks";
// import { log } from "../../../redux/actions/Common";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { toast } from "react-toastify";
import {
  getWorkersValidatorsList,
  getLanguageList,
  stopLoading,
} from "../../../redux/actions/Common";

import { customFunctions } from "../../../themes/utils/CustomFunctions";
import TinyMCE from "../../../molecules/CustomEditor";
import Protptype from "prop-types";
import taskTypesExample from './taskExamples';
import { arInstructionExample, enInstructionExample } from "./instructionExample"
import { arSourceTypeExample, enSourceTypeExample } from "./sourceTypeExample"
import { enQuestionTypeExample, arQuestionTypeExample, enQuestionAnswerConditionalExample, arQuestionAnswerConditionalExample } from "./questionTypeExample"
import Checkbox from "antd/lib/checkbox/Checkbox";


const { Option } = Select;
const { Dragger } = Upload;

const AddTaskModal = ({
  show = false,
  hideTaskModal = () => { },
  dataToShow = {},
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const groupRef = useRef(null);
  const valTaskRef = useRef(null);
  const clsNameRef = useRef(null);
  const taskNameRef = useRef(null);
  const taskTypeRef = useRef(null);
  const fileNameRef = useRef(null);
  const textTypeRef = useRef(null);
  const taskDescRef = useRef(null);
  const sourceTypeRef = useRef(null);
  const annoTypeRef = useRef(null);
  const addQuestionRef = useRef(null);

  const [prevOpen, setPrevOpen] = useState("");
  const [prevData, setPrevData] = useState({});
  const [pageNum, setPageNum] = useState("");
  const [totalanswers, setTotalAnswers] = useState(0);
  const [languageVal, setLanguageVal] = useState(null);
  const [conQuestionIndex, setConQuestionIndex] = useState(null);
  const [conAnswerIndex, setConAnswerIndex] = useState(null);
  const [conInputType, setConInputType] = useState(null);
  const [conInputTitle, setConInputTitle] = useState(null);
  const [conInputReq, setConInputReq] = useState(null);
  const [conInputPara, setConInputPara] = useState(null);
  const [ratingVal, setRatingVal] = useState(null);
  const [languageChildren, setLanguageChildren] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showQuestionTable, setShowQuestionTable] = useState(false);
  const [backendError, setBackendError] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0); // total workers and validators
  // const [editorData, setEditorData] = useState("");
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [userskillDataSource, setUserskillDataSource] = useState([]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showupload, setShowupload] = useState(false);
  const [uploadList, setUploadList] = useState([]);


  const dragger = {
    name: 'file',
    multiple: false,
    fileList: uploadList,
    customRequest: ({ file, onSuccess }) => {
      formik.setFieldValue("fileName", file);
      onSuccess("ok");
      setShowupload(true);
      setUploadList([file]);
    },
    onChange(info) {
      setShowupload(true);
      const { status } = info.file;
      if (status === 'removed') {
        formik.setFieldValue("fileName", "");
        setShowupload(false);
        setUploadList([]);
      }
    },
  };

  const taskType = [
    { name: t("image"), value: "1" },
    { name: t("text"), value: "2" },
    { name: t("audio"), value: "3" },
  ];
  const skillCol = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 250,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      width: 250,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("experience"),
      dataIndex: "experience",
      key: "experience",
      width: 250,
      sorter: false,
    },
  ];
  const [textType, setTextType] = useState(null);
  const [showValidatorLists, setShowValidatorList] = useState([]);
  const [selectedIds, setselectedIds] = useState([]);

  const [quetsionLists, setQuetsionLists] = useState(
    []
  );
  const [showTaskModal, setShowTaskModal] = useState(false);
  // const [questionTableShow, setQuestionTableShow] = useState(false);
  const [removalId, setRemovalId] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [validationSystem, setValidationSystem] = useState("");
  const [fileName, setFileName] = useState(null);
  const [isModalRemoveuser, setIsModalRemoveuser] = useState(false);
  const [isQuestionModalVisible, setModalVisibleQuestions] = useState(false);
  const [isModalVisibleValidator, setIsModalVisibleValidator] = useState(false);
  const [isModalVisibleEmail, setIsModalVisibleEmail] = useState(false);
  const [taskTypeVal, setTaskTypeVal] = useState("");

  const [validatorInviteList, setValidatorInviteList] = useState([]);

  const [currentStep, setCurrentStep] = useState(0);

  const [currentlyActive, setCurrentlyActive] = useState([]);

  const validatorsDataSource = showValidatorLists; //validatorLists;

  const validatorsColumns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      },
      render: (value, item) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >

            <Tooltip
              title={value ? value : item?.email}
              placement={
                localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
              }
            >
              {value ? value : item?.email}</Tooltip>
          </div>
        );
      },
    },
    // {
    //   title: t("rating"),
    //   dataIndex: "rating",
    //   key: "rating",
    //   render: (value) => {
    //     return (
    //       <Rate
    //         style={{
    //           fontSize: "20px",
    //         }}
    //         className="edit-group-star2"
    //         allowHalf
    //         value={value}
    //         disabled={true}
    //       />
    //     );
    //   },
    //   // sorter: true,
    //   // defaultSortOrder: "descend",
    //   sorter: (a, b) => a.rating - b.rating,
    //   width: 180,
    // },
    {
      title: t("type"),
      dataIndex: "type",
      key: "type",
      render: (text, record) => {
        return (
          <div
            style={{
              textAlign: "center",
            }}
          >
            {record.type.includes("annotator") &&
              record.type.includes("validator") ? (
              <div>
                <TagsOutlined style={{ color: "#FFC000" }} />
                <SafetyOutlined style={{ color: "#FFC000" }} />
                <span style={{ marginLeft: "5px" }}>{t("ann_and_val")}</span>
              </div>
            ) : record.type.includes("annotator") ? (
              <div>
                <TagsOutlined style={{ color: "#FFC000" }} />
                <span style={{ marginLeft: "5px" }}>{t("annotator")}</span>
              </div>
            ) : record.type.includes("validator") ? (
              <div>
                <SafetyOutlined style={{ color: "#FFC000" }} />
                <span style={{ marginLeft: "5px" }}>{t("validator")}</span>
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "Posted",
      key: "Posted",
      render: (text, record) => {
        return (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <button
              type="button"
              className="focus"
              onClick={() => {
                setIsModalRemoveuser(true);
                setRemovalId(record.id);
              }}
            >
              {t("remove")}
            </button>
          </div>
        );
      },
    },
  ];

  const selectCustomStyle = {
    cursor: "pointer",
    caretColor: "transparent",
  };

  const columnsForQuestions = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => index + 1,
    },
    {
      title: t("qn"),
      dataIndex: "question",
      key: "question",
      width: "19%",
      render: (val) => {
        return (
          <Tooltip title={val} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
      // render: (value, item, index) => index + 1,
    },
    {
      title: t("ans_type"),
      dataIndex: "answerType",
      key: "answerType",
      render: (item, record) => {
        return record.answerType == "Radio-Buttons" ? t("radiolist") : record.answerType == "Radio-Button" ? t("radio") : record.answerType == "Check-Box" ? t("checkbox") : t("yes_no")
      },
    },
    {
      title: t("ans"),
      dataIndex: "answers",
      key: "answers",
      width: "30%",
      render: (value, record) => {
        // const ren = ["dwqdd", "uigieguf"];
        return (
          <div
            style={{
              boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
              borderRadius: "18px",
            }}
          >
            {value.map((items, indexx) => {
              let cons = record?.conInputs?.filter(x => x.answerIndex == indexx)
              return (
                <div
                  style={{
                    display: "flex",
                    borderBottom:
                      value.length - 1 != indexx ? "1px #8bbae8 solid" : null,
                    padding: "10px",
                    paddingLeft: "20px",
                  }}
                  key={indexx}
                >
                  <Tooltip title={items}>{items}</Tooltip>
                  {cons?.length > 0 && (
                    <CustomHelp title={""}
                      popover={<>
                        {cons?.map((item, index) => {
                          return (
                            <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
                              <b>{item.title}</b>
                              <i>{t(item.type)}</i> /
                              <i>{item.required ? t("req") : t("n_req")}</i>
                            </div>
                          );
                        })}
                      </>} />
                  )}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      render: (value, item, index) => {
        return (
          <div className="tex-center" style={{ textAlign: "center" }}>
            <button
              type="button"
              className="focus"
              onClick={() => {
                const temp = [];
                quetsionLists.filter((items, indexx) => {
                  if (index != indexx) temp.push(items);
                });
                setQuetsionLists(temp);
              }}
            >
              {t("delete")}
            </button>
          </div>
        );
      },
    },
  ];
  const validateRegex = (check, reg) => {
    return reg.test(check);
  };
  const updateWorker = (item, type) => {
    const tempSelectedIds = [...selectedIds];
    const tempAddedWorkerList = [...showValidatorLists];
    const worker = tempAddedWorkerList.find((items) => items.id == item.id);
    if (!worker) {
      tempSelectedIds.push(item.id);
      item["type"] = [type];
      tempAddedWorkerList.push(item);
    } else {
      if (worker.type.includes(type)) {
        worker.type.splice(worker.type.indexOf(type), 1);
      } else {
        switch (formik.values.validationSystem) {
          case "primary":
            // if the type is annotator remove validator and vice versa
            if (type == "annotator") {
              if (worker.type.includes("validator")) {
                worker.type.splice(worker.type.indexOf("validator"), 1);
              }
            } else {
              if (worker.type.includes("annotator")) {
                worker.type.splice(worker.type.indexOf("annotator"), 1);
              }
            }
            break;
          default:
            break;
          // TODO: add other conditions
        }
        worker.type.push(type);
      }

      if (worker.type.length == 0) {
        tempSelectedIds.splice(tempSelectedIds.indexOf(item.id), 1);
        tempAddedWorkerList.splice(tempAddedWorkerList.indexOf(item), 1);
      }
    }

    setShowValidatorList(tempAddedWorkerList);
    setselectedIds(tempSelectedIds);
  };
  const onSubmit = (values) => {
    const concatValidators = selectedIds.concat(values.inviteList);
    const inviteList = [];
    const questionList = [];
    showValidatorLists.map((items) => {
      for (let type in items.type) {
        inviteList.push({
          email: items.email,
          invite_type: items.type[type],
        });
      }
    });
    validatorInviteList.map((items) => {
      inviteList.push({
        email: items.email,
        invite_type: items.type,
      });
    });
    quetsionLists.map((items) => {
      questionList.push({
        question: items.question,
        value: items.question.replace(/ /g, "_").toLowerCase(),
        question_type: items.answerType == "Radio-Buttons" ? "radiolist" : items.answerType == "Radio-Button" ? "radio" : items.answerType == "Check-Box" ? "checkbox" : "yes-no",
        answers: items.answers.map((item, index) => {
          return {
            answer: item,
            value: item.replace(/ /g, "_").toLowerCase(),
            conditional: items?.conInputs?.filter(x => x.answerIndex == index).length > 0 ?
              items?.conInputs?.filter(x => x.answerIndex == index).map(item3 => {
                return {
                  title: item3.title,
                  type: item3.type,
                  required: item3.required,
                  use_paragraph: item3.use_paragraph,
                }
              }) : null
          };
        }),
      });
    });

    let err = "";
    if (concatValidators.length == 0) {
      err = "plz_add_min1_wrkr";
    }
    switch (values.validationSystem) {
      case "primary":
        if (inviteList.length < 2) {
          err = "plz_add_min1_ann_and_val";
        }
        //at least one annotator and one validator
        if (
          inviteList.filter((items) => items.invite_type == "annotator")
            .length == 0
        ) {
          err = "plz_add_min1_annot";
        }
        if (
          inviteList.filter((items) => items.invite_type == "validator")
            .length == 0
        ) {
          err = "plz_add_min1_vldtr";
        }
        break;
      default:
        break;
    }
    if (err) {
      toast.error(t(err), {
        className:
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,
        toastId: "selectOneValidator",
      });
      formik.setFieldError("inviteList", t(err));
    } else {
      const body = {
        name: values.taskName ? values.taskName : null,
        task_type: values.taskType
          ? values.taskType == 1
            ? values.annotType
            : values.taskType == 2
              ? values.textType
              : "transcription"
          : null,
        //source_type: values.sourceType ? values.sourceType : null,
        validation_system: values.validationSystem
          ? values.validationSystem
          : null,
        annotator_percentage: values.annotatorPercentage
          ? values.annotatorPercentage
          : null,
        sample_percentage: values.samplePercentage
          ? values.samplePercentage
          : null,
        sample_unique: values.sampleUnique ? values.sampleUnique : null,
        annotator_count: values.annotatorCount ? values.annotatorCount : null,
        validator_count: values.validatorCount ? values.validatorCount : null,
        file: values.fileName ? values.fileName : fileName,
        url: values.fileUrl ? values.fileUrl : null,

        classes: values.clsName.length > 0 ? values.clsName : null, // array

        questions: questionList.length > 0 ? questionList : null, // array

        description: values.taskDescription ? values.taskDescription : null, // optional
        instructions: values.taskInstruction ? values.taskInstruction : null, // optional
        invites: inviteList,

      };

      const payloadForAddTask = {
        body: body,
        success: () => {
          toast.success(t("task_created"), {
            className:
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
            position:
              localStorage.lang == "ar"
                ? toast.POSITION.TOP_LEFT
                : toast.POSITION.TOP_RIGHT,
            toastId: "taskAdded",
          });
          formik.resetForm();
          setFileName(null);
          setShowValidatorList([]);
          setCurrentStep(0);
          setValidatorInviteList([]);
          setselectedIds([]);
          setTotalAnswers(0);
          setQuetsionLists([]);
          setTaskTypeVal("");
          setTextType(null);
          setSourceType("");
          setValidationSystem("");
          setShowTaskModal(false);
          hideTaskModal(body);
        },
        fail: (resf) => {
          toast.error(resf?.length ? resf[0] : t(resf.message), {
            className:
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
            position:
              localStorage.lang == "ar"
                ? toast.POSITION.TOP_LEFT
                : toast.POSITION.TOP_RIGHT,
            toastId: "wrongFileFromBackend",
          });
          setBackendError(resf);
        },
      };
      isInternetConnected() && dispatch(createTask(payloadForAddTask));
    }
  };
  const previewTask = () => {
    const payloadForPreview = {
      body: {
        task_type:
          formik.values.taskType == 1
            ? formik.values.annotType
            : formik.values.taskType == 2
              ? formik.values.textType
              : "transcription",
        annotator_percentage: formik.values.annotatorPercentage,
        sample_percentage: formik.values.samplePercentage,
        sample_unique: formik.values.sampleUnique,
        validation_system: formik.values.validationSystem,
        annotator_count: formik.values.annotatorCount,
        validator_count: formik.values.validatorCount,
        file: formik.values.fileName,
        url: formik.values.fileUrl,
      },
      success: (res) => {
        setPrevData(res);
        setPrevOpen(true);
      },
      fail: (resf) => {
        console.log(resf);
        // toast

        toast.error(resf[0], {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
          toastId: "wrongFileFromBackend",
        });
      },
    };
    isInternetConnected() && dispatch(getTaskPreview(payloadForPreview));
  };
  const sliderFormatter = (value) => `${value}%`;
  const sliderMarks = {
    1: "1%",
    25: "25%",
    50: "50%",
    75: "75%",
    100: "100%",
  };
  const steps = [
    {
      title: t("basic_info"),
      content: "basic_info",
      icon: <img src={AppImage.one} width={30} />,
    },
    {
      title: t("task_details"),
      content: "task_details",
      icon: <img src={AppImage.two} width={30} />,
    },
    {
      title: t("data_workers"),
      content: "data_workers",
      icon: <img src={AppImage.three} width={30} />,
    },
  ];
  const steps_items = steps.map((item) => ({ key: item.title, title: item.title, icon: item.icon }));

  const initialValues = {
    clsName: [],
    taskDescription: "",
    taskInstruction: "",
    classfile: "",
    taskName: "",
    taskType: "",
    textType: "",
    fileName: "",
    sourceType: "",
    validationSystem: "",
    annotatorPercentage: 50,
    annotatorCount: 1,
    validatorCount: 1,
    samplePercentage: 0,
    sampleUnique: false,
    addQuestion: "",

    //groups: [], // multiSelect ?
    addAnswers: "",
    validatorList: [],
    inviteList: [], //['abc12@yopmail.com'],
    inviteEmail: "",
    inviteType: "annotator",
    clsFileName: "",
    fileUrl: "",
    questions: "",
    annotType: "",
    addedanswers: "",
  };
  const formikValidationSchemaAddTaskModal = () => {
    return Yup.object().shape({
      taskName: Yup.string()
        .min(3, t("name_3_char"))
        .required(t("pls_enter_task_name")),
      taskType: Yup.string().required(t("plz_select_task_typ")),
      annotType:
        taskTypeVal == 1
          ? Yup.string().required(t("plz_select_annot_type"))
          : Yup.string().resolve(),
      textType:
        taskTypeVal == 2
          ? Yup.string().required(t("plz_select_txt_type"))
          : Yup.string().resolve(),
      sourceType:
        taskTypeVal != 2
          ? Yup.string().required(t("plz_select_src_typ"))
          : Yup.string().resolve(),
      addQuestion:
        textType == "classification" && taskType == 2
          ? Yup.string().required(t("add_atlst_1qn"))
          : Yup.string().resolve(),
      fileName:
        sourceType == "local" || textType == "questions"
          ? Yup.string().required(t("plz_add_file"))
          : Yup.string().resolve(),
      fileUrl:
        sourceType == "url"
          ? Yup.string()
            .matches(
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/,
              t("plz_entr_valid_url")
            )
            .required(t("plz_entr_url"))
          : Yup.string().resolve(),
      clsName:
        taskTypeVal == 1 && taskTypeVal != ""
          ? Yup.array().min(1, t("plz_entr_cls_name"))
          : textType == "segmentation"
            ? Yup.array()
              .min(1, t("plz_entr_cls_name"))
              .max(20, t("max_20_cls"))
            : Yup.array().resolve(),
      taskDescription: Yup.string()
        .min(3, t("desc_3_char"))
        .required(t("plz_entr_task_desc")),
      taskInstruction: Yup.string().required(t("plz_entr_task_instrctn")),
      //  groups: Yup.array().min(1, t("plz_add_min1_grp")),
      questions:
        taskTypeVal == 2 && taskTypeVal != "" && textType == "questions"
          ? Yup.string().required(t("add_min_1qn"))
          : Yup.string().resolve(),
      validationSystem:
        validationSystem == ""
          ? Yup.string().required(t("plz_enter_validation_system"))
          : // TODO: add validation for all validation types
          Yup.string().resolve(),
      inviteEmail: Yup.string()
        .matches(
          /^[^\s!#%^*]+[\w\d\s@,.<>:;'"?/\\{}()\]\[\-_*&$]+$/,
          t("entr_vld_email")
        ),
      inviteType: Yup.string()
    });
  };
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: formikValidationSchemaAddTaskModal(),
  });

  const handleImageChange = (file, acceptType) => {
    if (file) {
      const file_name = file.name.split(".");
      const fileType = file_name[file_name.length - 1];

      //acccpeted file types is an array of strings
      if (!acceptType.includes(fileType)) {
        toast.error(`${t("plz_upload")} ${acceptType} ${t("file_only")}.`, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
          toastId: "wrongFile",
        });
        return false;
      }
      //limit is 500 mb
      if (file.size > 500000000) {
        toast.error(t("file_size_limit"), {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
          toastId: "wrongFile",
        });
        return false;
      }

      setFileName(file.name);

      return true;
    }
  };
  const taskNameProps = {
    type: "text",
    error: formik.errors.taskName,
    inputTrue: true,
    id: "taskName",
    value:
      customFunctions.capitalizeFirstLetter(
        formik.values.taskName.trimLeft()
      ) || "",
    selectOptions: dataToShow.skillsCategory,
    placeholder: t("tsk_name"),
    fieldLabel: t("tsk_name"),
    inputMaxLength: 50,
    refVal: taskNameRef,
  } // task name
  const taskTypeProps = {
    selectTrue: true,
    id: "taskType",
    selectOptions: dataToShow.taskType || taskType,
    fieldLabel: t("tsk_typ"),
    labelModal: taskTypesExample({
      t: t,
    }),
    selectOnChange: (val) => {
      formik.setValues({
        ...formik.values,
        taskType: val,
        textType: "",
        annotType: "",
        sourceType: "",
        clsName: [],
        fileUrl: "",
      });
      setTaskTypeVal(val);
      setSourceType("");
      setTextType(null);
      if (val == 1 || val == 3 || textType == "segmentation") {
        setQuetsionLists([]);
      }
      setFileName(null);
    },
    error: formik.errors.taskType,
    customInputStyle: selectCustomStyle,
    value: formik.values.taskType || t("select_task_type"), // configure later
    refVal: taskTypeRef,
  };

  useEffect(() => {
    const firstError = Object.keys(formik.errors)[0];
    const backendErrorCode = backendError?.code;
    console.log(formik.errors);

    setTimeout(() => {
      if (backendErrorCode)
        switch (backendErrorCode) {
          case "classes":
            formik.setFieldError("clsName", t(backendError.message));
            clsNameRef.current?.focus();
            clsNameRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            setCurrentStep(1);
            break;

          case "file":
            formik.setFieldError("fileName", t(backendError.message));
            fileNameRef.current?.focus();
            fileNameRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            setCurrentStep(2);
            break;
        }


      if (formik.isSubmitting && firstError === "taskName") {
        taskNameRef.current?.focus();
        taskNameRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setCurrentStep(0);
      }
      if (formik.isSubmitting && firstError === "taskDescription") {
        taskDescRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setCurrentStep(0);
      }
      if (formik.isSubmitting && firstError === "clsName") {
        clsNameRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setCurrentStep(0);
      }
      if (formik.isSubmitting && firstError === "taskType") {
        taskTypeRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setCurrentStep(1);
      }

      if (formik.isSubmitting && firstError === "textType") {
        textTypeRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setCurrentStep(1);
      }
      if (formik.isSubmitting && firstError === "sourceType") {
        sourceTypeRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setCurrentStep(2);
      }
      if (formik.isSubmitting && firstError === "validationSystem") {
        sourceTypeRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setCurrentStep(2);
      }

      if (formik.isSubmitting && firstError === "validatorList") {
        valTaskRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setCurrentStep(2);
      }
      if (formik.isSubmitting && firstError === "fileName") {
        fileNameRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setCurrentStep(2);
      }
    }, 300);
  }, [
    formik.isSubmitting,
    backendError
  ]);
  useEffect(() => {
    setShowTaskModal(show);

    const languagePayload = {
      body: {},
      success: (res) => {
        // add all the languages to first index
        res.unshift({
          code: "all",
          name: t("all"),
        });
        setLanguageChildren(res);
        setLanguageVal(res[0].code);
      },
      fail: () => {
        console.log("");
      }
    }
    isInternetConnected() && dispatch(getLanguageList(languagePayload));
  }, [show]);

  useEffect(() => {
    const payload = {
      body: {
        search: searchValue,
        pageNum: pageNum,
        language: languageVal == 'all' ? null : languageVal,
      },
      success: (res) => {
        setTotalUsers(res?.total);
        const modifiedRes = [];
        res?.data?.filter((item) => {
          modifiedRes.push({
            id: item.id,
            email: item.email,
            profile: item.image
              ? item.image
              : AppImage.defaultImage,
            name: item.first_name + " " + item.last_name,
            rating: item.rating || 0,
            languages: item.languages,
            skills: item.skills,
            tickTrue: false,
          });
        });
        localStorage.setItem("tempArr", JSON.stringify(modifiedRes));
        setCurrentlyActive(modifiedRes);
        dispatch(stopLoading());
      },
      fail: () => {
        console.log("");
      },
    };
    isInternetConnected() && dispatch(getWorkersValidatorsList(payload));
  }, [pageNum, languageVal, isModalVisibleValidator, searchValue]);


  return (
    <div>
      {/* add task modal start*/}
      <Modal
        maskClosable={false}
        title={t("add_task")}
        open={showTaskModal}
        onOk={() => {
          formik.resetForm();
          setFileName(null);
          setValidatorInviteList([]);
          setShowTaskModal(false);
          setCurrentStep(0);
        }}
        onCancel={() => {
          formik.resetForm();
          setFileName(null);
          setShowValidatorList([]);
          setValidatorInviteList([]);
          setselectedIds([]);
          setTotalAnswers(0);
          setQuetsionLists([]);
          setTaskTypeVal("");
          setTextType(null);
          setSourceType("");
          setValidationSystem("");
          setCurrentStep(0);
          setShowTaskModal(false);
          hideTaskModal();
        }}
        // wrapClassName="poolworkers"
        footer={null}
        width={923}
        centered
        className={localStorage.lang == "ar" ? "new_layout ar addTaskModal" : "en addTaskModal"}
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="text-center">
            <div className="wrapper_line1 models_data">
              <Steps current={currentStep} items={steps_items} className="card padding-m" />
              <div className={`steps-content ${isTransitioning ? "step-transition" : ""}`}>
                {currentStep == 0 && (
                  <div className="card">
                    {/* taskName */}
                    <div className="wrapper_line form_wrapper remove-horizontal-space">
                      <TextField
                        {...taskNameProps}
                        labelTitle={taskNameProps.fieldLabel}
                        labelTooltop={taskNameProps.labelTooltop}
                        onChange={formik.handleChange}
                        errorClassName="error-highlight"
                      />
                    </div>
                    {/*   description */}
                    <div className="wrapper_line form_wrapper remove-horizontal-space">
                      <div className="form-group">
                        <CustomHelp labelRef={taskDescRef} title={t("description")} />
                        <textarea
                          id="taskDescription"
                          value={customFunctions.capitalizeFirstLetter(
                            formik.values.taskDescription.trimLeft()
                          )}
                          onChange={formik.handleChange}
                          maxLength={100}
                          className={formik.errors.taskDescription ? "form-control error-highlight" : "form-control"}
                          placeholder={t("pls_entr_desc")}
                        ></textarea>

                        {
                          <div
                            className={
                              localStorage.lang == "en"
                                ? "text-count en"
                                : "text-count ar"
                            }
                          >
                            {/* error */}
                            <div>
                              {formik.errors.taskDescription ? (
                                <div
                                  className={
                                    localStorage.getItem("lang") == "ar"
                                      ? "error ar"
                                      : "error en"
                                  }
                                  style={{ paddingLeft: "17px" }}
                                >
                                  {formik.errors.taskDescription}
                                </div>
                              ) : null}
                            </div>
                            <div>
                              {/* count */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  color:
                                    formik.values.taskDescription.length == 100
                                      ? "#433d3d"
                                      : "grey",
                                }}
                              >
                                <div>{formik.values.taskDescription.length}/100</div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>

                    {/* instructions */}
                    <div className="wrapper_line1 form_wrapper remove-horizontal-space">
                      <div
                        className="form-group"
                      >
                        <CustomHelp title={t("instructions")} popover={
                          localStorage.getItem("lang") == "ar"
                            ? arInstructionExample
                            : enInstructionExample
                        } />

                        <TinyMCE
                          initValue={formik.values.taskInstruction}
                          placeholder={localStorage.lang == "en" ? "Instructions" : "تعليمات"}
                          lang={localStorage.lang}
                          onChange={(data) => {
                            formik.setValues({
                              ...formik.values,
                              taskInstruction: data,
                            });
                          }}
                        ></TinyMCE>
                      </div>
                      {formik.errors.taskInstruction ? (
                        <div
                          className={
                            localStorage.getItem("lang") == "ar"
                              ? "error ar"
                              : "error en"
                          }
                          style={{
                            paddingLeft: "17px",
                            marginTop: "-18px",
                            marginBottom: "14px",
                          }}
                        >
                          {formik.errors.taskInstruction}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                {currentStep == 1 && (
                  <div className="card">
                    {/* taskType */}
                    <div className="wrapper_line form_wrapper remove-horizontal-space">
                      <div className="form-group">
                        <TextField
                          {...taskTypeProps}
                          labelTitle={taskTypeProps.fieldLabel}
                          labelTooltop={taskTypeProps.labelTooltop}
                          onChange={formik.handleChange}
                          errorClassName="error-highlight"
                        />
                      </div>
                    </div>

                    {taskTypeVal == 1 ? (
                      <div className="wrapper_line form_wrapper remove-horizontal-space">
                        <div className="form-group">
                          <CustomHelp labelRef={annoTypeRef} title={t("annot_type")} />
                          <Select
                            id="annotType"
                            onClick={() => {
                              const modalDropdownItems = document.querySelectorAll(
                                ".ant-select-item.ant-select-item-option"
                              );
                              if (localStorage.getItem("lang") == "ar") {
                                for (let i = 0; i < modalDropdownItems.length; i++) {
                                  modalDropdownItems[i].style.textAlign = "right";
                                }
                              } else {
                                for (let i = 0; i < modalDropdownItems.length; i++) {
                                  modalDropdownItems[i].style.textAlign = "left";
                                }
                              }
                            }}
                            onChange={(val) => {
                              formik.setValues({
                                ...formik.values,
                                annotType: val,
                              });
                              // setannotTypeVal(val);
                            }}
                            value={formik.values.annotType || t("select_annot_type")}
                            className={formik.errors.annotType ? "default_text error-highlight" : "default_text"}
                            placeholder={t("select")}
                          >
                            <Option value="bbox">{t("box")}</Option>
                            <Option value="polygon">{t("polygon")}</Option>
                          </Select>
                          {formik.values.annotType == "" ? (
                            <div
                              className={
                                localStorage.getItem("lang") == "ar"
                                  ? "error ar"
                                  : "error en"
                              }
                              style={{ paddingLeft: "17px" }}
                            >
                              {formik.errors.annotType}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    {/* textType */}
                    {taskTypeVal == 2 && (
                      <div className="wrapper_line form_wrapper remove-horizontal-space">
                        <div className="form-group">
                          <CustomHelp labelRef={textTypeRef} title={t("text_type")} />
                          <Select
                            id="textType"
                            onClick={() => {
                              const modalDropdownItems = document.querySelectorAll(
                                ".ant-select-item.ant-select-item-option"
                              );
                              if (localStorage.getItem("lang") == "ar") {
                                for (let i = 0; i < modalDropdownItems.length; i++) {
                                  modalDropdownItems[i].style.textAlign = "right";
                                }
                              } else {
                                for (let i = 0; i < modalDropdownItems.length; i++) {
                                  modalDropdownItems[i].style.textAlign = "left";
                                }
                              }
                            }}
                            onChange={(val) => {
                              formik.setValues({
                                ...formik.values,
                                textType: val,
                                sourceType: "",
                              });
                              setTextType(val);
                              setFileName(null);
                              formik.setFieldValue("fileUrl", "");

                              formik.setFieldValue("clsName", []);

                              if (val == "segmentation") {
                                setTotalAnswers(0);
                                setQuetsionLists([]);
                              }
                            }}
                            value={formik.values.textType || t("select_text_type")}
                            className={formik.errors.textType ? "default_text" : "default_text"}
                          >
                            <Option value="classification">{t("qns")}</Option>
                            <Option value="segmentation">{t("segmentation")}</Option>
                            <Option value="text_transcription">{t("transcription")}</Option>
                          </Select>
                          {// formik.errors.textType
                            !formik.values.textType ? (
                              <div
                                className={
                                  localStorage.getItem("lang") == "ar"
                                    ? "error ar"
                                    : "error en"
                                }
                                style={{ paddingLeft: "17px" }}
                              >
                                {formik.errors.textType}
                              </div>
                            ) : null}
                        </div>
                      </div>
                    )}


                    {/* add questions  */}
                    {// textType == "questions"
                      formik.values.textType == "classification" && (
                        <div className="wrapper_line form_wrapper remove-horizontal-space">
                          <div className="form-group new_pool">
                            <CustomHelp labelRef={addQuestionRef} title={t("add_questions")} />
                            <input
                              type="button"
                              className="form-control"
                              value={t("add_qns_fr_tasks")}
                              onChange={formik.handleChange}
                              autoComplete="off"
                              onClick={() => {
                                setModalVisibleQuestions(true);
                                formik.setFieldValue("addQuestion", "");
                                // setQuestionTableShow(false);
                              }}
                              maxLength={50}
                            />
                            {formik.errors.questions ? (
                              <div className="error" style={{ paddingLeft: "17px" }}>
                                {formik.errors.questions}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}

                    {/* added question list */}
                    {quetsionLists.length > 0 &&
                      !isQuestionModalVisible &&
                      textType == "classification" ? (
                      <div className="wrapper_line form_wrapper remove-horizontal-space">
                        <div className="form-group new_pool">
                          <label ref={addQuestionRef}>{t("qn_lists")}</label>
                          <div className="inner_box_box">
                            <Table
                              className="question-table-owner"
                              dataSource={quetsionLists}
                              columns={columnsForQuestions}
                              pagination={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {/* className */}
                    {taskTypeVal == 1 || textType == "segmentation" ? (
                      <div className="wrapper_line form_wrapper remove-horizontal-space">
                        <div className="form-group">
                          <CustomHelp labelRef={clsNameRef} title={t("clss_name")} tooltip={t("comma_sep_enter")} />
                          <Select
                            mode="tags"
                            tokenSeparators={[","]}
                            notFoundContent={t("no_data")}
                            onClick={() => {
                              const noDataElementOfAddValidatorThrEmail = document.querySelectorAll(
                                "div.ant-empty.ant-empty-normal.ant-empty-small>div:last-child"
                              );
                              if (noDataElementOfAddValidatorThrEmail.length != 0) {
                                if (
                                  noDataElementOfAddValidatorThrEmail[0].innerHTML ==
                                  "No Data"
                                ) {
                                  noDataElementOfAddValidatorThrEmail[0].innerHTML =
                                    "Write To Add Data";
                                }
                              }
                              if (localStorage.getItem("lang") == "ar") {
                                setTimeout(() => {
                                  const ele = document.querySelectorAll(
                                    ".rc-virtual-list-holder-inner"
                                  );
                                  ele.forEach((element) => {
                                    element.classList.add("new_layout");
                                    element.classList.add("ar");
                                  });

                                  const ele2 = document.querySelector(
                                    "#clsName_list.ant-select-item-empty"
                                  );
                                  ele2.style.textAlign = "right";
                                }, 10);
                              } else {
                                const ele = document.querySelectorAll(
                                  ".rc-virtual-list-holder-inner"
                                );
                                ele.forEach((element) => {
                                  element.classList.remove("new_layout");
                                  element.classList.remove("ar");
                                });
                              }
                            }}
                            id="clsName"
                            onChange={(val) => {
                              const reg = /^[^\s!#%^*]+[\w\d\s@,.<>:;'"?/\\{}()\]\[\-_*&$]+([-a-zA-Z]+[^\s]+)*[\w\d\s@,.<>:;'"?/\\{}()\]\[\-_*&$]$/;
                              const check = val[val.length - 1];
                              if (
                                val.length != 0 &&
                                check.length < 51 &&
                                validateRegex(check, reg)
                              ) {
                                if (textType == "segmentation") {
                                  if (val.length < 21) {
                                    formik.setValues({
                                      ...formik.values,
                                      clsName: val,
                                    });
                                  } else {
                                    toast.error(t("max_20_cls"), {
                                      position:
                                        localStorage.lang == "en"
                                          ? toast.POSITION.TOP_RIGHT
                                          : toast.POSITION.TOP_LEFT,
                                      toastId: "max20clss",
                                    });
                                    // formik.setFieldError(
                                    //   "clsName",
                                    //   t("max_20_cls")
                                    // );
                                  }
                                }
                                if (taskTypeVal == 1) {
                                  if (val.length < 21) {
                                    formik.setValues({
                                      ...formik.values,
                                      clsName: val,
                                    });
                                  } else {
                                    toast.error(t("max_20_cls"), {
                                      position:
                                        localStorage.lang == "en"
                                          ? toast.POSITION.TOP_RIGHT
                                          : toast.POSITION.TOP_LEFT,
                                      toastId: "max20clss",
                                    });
                                  }
                                }
                              } else if (val.length === 0) {
                                formik.setValues({
                                  ...formik.values,
                                  clsName: val,
                                });
                              } else {
                                if (check.length > 50) {
                                  toast.error(t("max_50_chars"), {
                                    className:
                                      localStorage.lang == "ar"
                                        ? "Select_data ar"
                                        : "Select_data en",
                                    position:
                                      localStorage.lang == "ar"
                                        ? toast.POSITION.TOP_LEFT
                                        : toast.POSITION.TOP_RIGHT,
                                    toastId: "clsname",
                                  });
                                } else
                                  toast.error(t("class_name_min_3"), {
                                    className:
                                      localStorage.lang == "ar"
                                        ? "Select_data ar"
                                        : "Select_data en",
                                    position:
                                      localStorage.lang == "ar"
                                        ? toast.POSITION.TOP_LEFT
                                        : toast.POSITION.TOP_RIGHT,
                                    toastId: "clsname",
                                  });
                              }
                            }}
                            onKeyDown={() => {
                              if (localStorage.getItem("lang") == "ar") {
                                setTimeout(() => {
                                  const ele = document.querySelectorAll(
                                    ".rc-virtual-list-holder-inner"
                                  );
                                  ele.forEach((element) => {
                                    element.classList.add("new_layout");
                                    element.classList.add("ar");
                                  });
                                }, 10);
                              } else {
                                const ele = document.querySelectorAll(
                                  ".rc-virtual-list-holder-inner"
                                );
                                ele.forEach((element) => {
                                  element.classList.remove("new_layout");
                                  element.classList.remove("ar");
                                });
                              }
                            }}
                            value={formik.values.clsName}
                            style={{ width: "100%" }}
                            placeholder={t("clss_name")}
                            className={formik.errors.clsName ? "default_text error-highlight" : "default_text"}
                          ></Select>
                        </div>
                        {formik.errors.clsName ? (
                          <div
                            className={
                              localStorage.getItem("lang") == "ar"
                                ? "error ar"
                                : "error en"
                            }
                            style={{
                              paddingLeft: "17px",
                              marginTop: "-15px",
                              marginBottom: "17px",
                            }}
                          >
                            {formik.errors.clsName}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {formik.values.taskType != "" ? (
                      <>

                        {/* validation System */}
                        {
                          (
                            formik.values.taskType == 2 &&
                            (
                              formik.values.textType == "segmentation" ||
                              formik.values.textType == "classification" ||
                              formik.values.textType == "text_transcription"
                            )
                          ) ||
                            formik.values.taskType == 1 ||
                            formik.values.taskType == 3 ? (
                            <div className="wrapper_line form_wrapper remove-horizontal-space">
                              <div className="form-group">
                                <CustomHelp
                                  labelRef={sourceTypeRef}
                                  title={t("validation_sys")}
                                />
                                <Select
                                  id="validationSystem"
                                  disabled={formik.values.taskType == "" ? true : false}
                                  onClick={() => {
                                    const modalDropdownItems = document.querySelectorAll(
                                      ".ant-select-item.ant-select-item-option"
                                    );
                                    if (localStorage.getItem("lang") == "ar") {
                                      for (let i = 0; i < modalDropdownItems.length; i++) {
                                        modalDropdownItems[i].style.textAlign = "right";
                                      }
                                    } else {
                                      for (let i = 0; i < modalDropdownItems.length; i++) {
                                        modalDropdownItems[i].style.textAlign = "left";
                                      }
                                    }
                                  }}
                                  onChange={(val) => {
                                    formik.setValues({
                                      ...formik.values,
                                      validationSystem: val,
                                    });
                                  }}
                                  value={
                                    formik.values.validationSystem ||
                                    t("select_validation_sys")
                                  }
                                  className={formik.errors.validationSystem ? "default_text error-highlight" : "default_text"}
                                  placeholder={t("select_validation_sys")}
                                >
                                  <Option value="primary">{t("primary")}</Option>
                                  <Option value="grouping">{t("grouping")}</Option>
                                  <Option value="peer_review">{t("peer_review")}</Option>
                                  <Option value="sample_review">
                                    {t("sample_review")}
                                  </Option>
                                </Select>
                                {validationSystem == "" ? (
                                  <div
                                    className={
                                      localStorage.getItem("lang") == "ar"
                                        ? "error ar"
                                        : "error en"
                                    }
                                    style={{ paddingLeft: "17px" }}
                                  >
                                    {formik.errors.validationSystem}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                        {/* extra controls vallation system */}
                        {formik.values.validationSystem == "primary" ? (
                          <div className="wrapper_line form_wrapper remove-horizontal-space">
                            <div className="form-group">
                              <label ref={sourceTypeRef}>
                                {t("annotator_percentage")}
                              </label>
                              <Slider
                                className="slider-select"
                                defaultValue={50}
                                min={1}
                                max={100}
                                tooltip={sliderFormatter}
                                marks={sliderMarks}
                                onChange={(val) => {
                                  formik.setValues({
                                    ...formik.values,
                                    annotatorPercentage: val,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                        {formik.values.validationSystem == "sample_review" ? (
                          <div className="wrapper_line form_wrapper remove-horizontal-space">
                            <div className="form-group">
                              <Row>
                                <Col span={18}>
                                  <label ref={sourceTypeRef}>
                                    {t("sample_percentage")}
                                  </label>
                                  <Slider
                                    className="slider"
                                    defaultValue={50}
                                    min={1}
                                    max={100}
                                    tooltip={sliderFormatter}
                                    marks={sliderMarks}
                                    onChange={(val) => {
                                      formik.setValues({
                                        ...formik.values,
                                        samplePercentage: val,
                                      });
                                    }}
                                    style={{ marginRight: "15px" }}
                                  />
                                </Col>
                                <Col span={2}></Col>
                                <Col span={4}>
                                  <label ref={sourceTypeRef}>{t("sample_unique")}</label>
                                  <Switch
                                    onChange={(val) => {
                                      formik.setValues({
                                        ...formik.values,
                                        sampleUnique: val,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        ) : null}
                        {formik.values.validationSystem == "grouping" ? (
                          <div className="wrapper_line form_wrapper remove-horizontal-space">
                            <div className="form-group">
                              <Row>
                                <Col span={12}>
                                  <label ref={sourceTypeRef}>
                                    {t("annotator_count")}
                                  </label>
                                  <InputNumber
                                    className="default_text"
                                    size="large"
                                    defaultValue={1}
                                    min={1}
                                    max={100}
                                    onChange={(val) => {
                                      formik.setValues({
                                        ...formik.values,
                                        annotatorCount: val,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col span={12}>
                                  <label ref={sourceTypeRef}>
                                    {t("validator_count")}
                                  </label>
                                  <InputNumber
                                    className="default_text"
                                    defaultValue={1}
                                    size="large"
                                    min={1}
                                    max={100}
                                    onChange={(val) => {
                                      formik.setValues({
                                        ...formik.values,
                                        validatorCount: val,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        ) : null}
                      </>) : (
                      <CustomEmptyDiv
                        title={t("select_task_type")}
                      />
                    )}
                  </div>
                )}
                {currentStep == 2 && (
                  <div className="card">
                    {formik.values.taskType != "" ? (
                      <>
                        {/* sourceType */}
                        {// formik.values.taskType != 2 &&
                          (
                            formik.values.taskType == 2 &&
                            (
                              formik.values.textType == "segmentation" ||
                              formik.values.textType == "classification" ||
                              formik.values.textType == "text_transcription"
                            )
                          ) ||
                            formik.values.taskType == 1 ||
                            formik.values.taskType == 3 ? (
                            <div className="wrapper_line form_wrapper remove-horizontal-space">
                              <div className="form-group">
                                <CustomHelp labelRef={sourceTypeRef} title={t("src_type")} popover={
                                  localStorage.getItem("lang") == "ar"
                                    ? arSourceTypeExample
                                    : enSourceTypeExample
                                }
                                  popoverWidth="500px"
                                />
                                <Select
                                  id="sourceType"
                                  disabled={formik.values.taskType == "" ? true : false}
                                  onClick={() => {
                                    const modalDropdownItems = document.querySelectorAll(
                                      ".ant-select-item.ant-select-item-option"
                                    );
                                    if (localStorage.getItem("lang") == "ar") {
                                      for (let i = 0; i < modalDropdownItems.length; i++) {
                                        modalDropdownItems[i].style.textAlign = "right";
                                      }
                                    } else {
                                      for (let i = 0; i < modalDropdownItems.length; i++) {
                                        modalDropdownItems[i].style.textAlign = "left";
                                      }
                                    }
                                  }}
                                  onChange={(val) => {
                                    formik.setValues({
                                      ...formik.values,
                                      sourceType: val,
                                    });
                                    setFileName(null);
                                    //  formik.setFieldValue('clsName',[]);

                                    if (val == "url") {
                                      setFileName(null); // to erase the import file name
                                    } else {
                                      formik.setFieldValue("fileUrl", "");
                                    }

                                    setSourceType(val);
                                  }}
                                  value={
                                    formik.values.sourceType || t("select_source_type")
                                  }
                                  className={formik.errors.sourceType ? "default_text error-highlight" : "default_text"}
                                  placeholder={t("select_src_type")}
                                >
                                  <Option value="local">{t("local")}</Option>
                                  <Option value="url">{t("url")}</Option>
                                </Select>
                                {sourceType == "" ? (
                                  <div
                                    className={
                                      localStorage.getItem("lang") == "ar"
                                        ? "error ar"
                                        : "error en"
                                    }
                                    style={{ paddingLeft: "17px" }}
                                  >
                                    {formik.errors.sourceType}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                        {/* File and url  */}
                        {formik.values.taskType != "image" &&
                          formik.values.taskType != "" && (
                            <>
                              {formik.values.sourceType == "url" && (
                                <div className="wrapper_line form_wrapper remove-horizontal-space">
                                  <div ref={fileNameRef} className="form-group">
                                    <label>{t("enter_url")}</label>
                                    <input
                                      id="fileUrl"
                                      value={formik.values.fileUrl}
                                      onChange={formik.handleChange}
                                      type="text"
                                      className="form-control"
                                      placeholder={t("enter_url")}
                                      autoComplete="off"
                                    />
                                    {formik.errors.fileUrl &&
                                      formik.values.sourceType == "url" ? (
                                      <div
                                        className={
                                          localStorage.getItem("lang") == "ar"
                                            ? "error ar"
                                            : "error en"
                                        }
                                        style={{ paddingLeft: "17px" }}
                                      >
                                        {formik.errors.fileUrl}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              )}
                              {(formik.values.sourceType == "local" ||
                                textType == "questions") && (
                                  <div className=" form_wrapper remove-horizontal-space">
                                    <div
                                      ref={fileNameRef}
                                      className="form-group"
                                    >
                                      <label>
                                        {t("import_file")}
                                      </label>
                                      <Dragger
                                        {...dragger}
                                        id={"class-upload"}
                                        className="upload-file "
                                        showUploadList={showupload}
                                        accept={
                                          formik.values.taskType == 1 ||
                                            formik.values.taskType == 3
                                            ? ".zip"
                                            : ".csv,.xlsx,.json"
                                        }
                                        beforeUpload={(file) => {
                                          const fileAccept =
                                            formik.values.taskType == 1 ||
                                              formik.values.taskType == 3
                                              ? ["zip"]
                                              : ["csv", "xlsx", "json"];
                                          return handleImageChange(
                                            file,
                                            fileAccept
                                          );
                                        }}
                                      >
                                        <p className="ant-upload-drag-icon">
                                          <CloudUploadOutlined style={{
                                            color: "#194c7f",
                                          }} />
                                        </p>
                                        <p className="ant-upload-text">
                                          Click or drag file to this area to upload
                                        </p>
                                      </Dragger>
                                      <button
                                        type="button"
                                        style={{
                                          marginTop: "10px",
                                          position: "relative",
                                          left: "45%"
                                        }}
                                        className="button-list btn-sm"

                                        onClick={() => {
                                          previewTask();
                                        }}
                                      >
                                        <img src={AppImage.eye_w} />
                                      </button>
                                      {formik.errors.fileName &&
                                        (formik.values.sourceType == "local" ||
                                          textType == "questions") ? (
                                        <div
                                          className={
                                            localStorage.getItem("lang") == "ar"
                                              ? "error ar"
                                              : "error en"
                                          }
                                          style={{ paddingLeft: "17px" }}
                                        >
                                          {formik.errors.fileName}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                            </>
                          )}
                        {formik.values.validationSystem !== "" && (
                          <div className="card">
                            <div
                              className={formik.errors.inviteList ? "error-highlight" : "cad"}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "20px",
                              }}>
                              <div style={{ width: "40%" }}>
                                {/* select workrer for task */}
                                <div className="wrapper_line form_wrapper remove-horizontal-space">
                                  <div className="form-group new_pool">
                                    <label ref={valTaskRef}>
                                      {t("select_workers_for_task")}
                                    </label>
                                    <input
                                      type="button"
                                      className={
                                        localStorage.lang == "en"
                                          ? "form-control"
                                          : "form-control select_from_pool_of_validator ar"
                                      }
                                      // placeholder="Email Addresss"
                                      value={t("select_frm_pool")}
                                      autoComplete="off"
                                      onClick={() => {
                                        setLanguageVal(languageChildren?.[0]?.code);
                                        setRatingVal("0-1");
                                        setIsModalVisibleValidator(true);
                                        setPageNum(0);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div style={{
                                width: "20%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "18px",
                                fontWeight: "800",
                              }}>{t("or")}</div>
                              <div style={{ width: "40%" }}>
                                {/* add validator through email address */}
                                <div className="wrapper_line form_wrapper remove-horizontal-space">
                                  <div className="form-group new_pool">
                                    <label ref={valTaskRef}>
                                      {t("add_wrkr_thr_email_adrs")}
                                    </label>
                                    <input
                                      type="button"
                                      className={
                                        localStorage.lang == "en"
                                          ? "form-control"
                                          : "form-control select_from_pool_of_validator ar"
                                      }
                                      // placeholder="Email Addresss"
                                      value={t("add_wrkr_thr_email_adrs")}
                                      autoComplete="off"
                                      onClick={() => {
                                        setIsModalVisibleEmail(true);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* added validator list */}
                            {formik.values.validationSystem !== "" && (
                              <div className="wrapper_line  form_wrapper remove-horizontal-space">
                                {validatorsDataSource.length != 0 && (
                                  <div className="form-group">
                                    <div className="inner_box_box ">
                                      <Table
                                        dataSource={validatorsDataSource} //dataSourceRate
                                        columns={validatorsColumns} //columnsRate
                                        showSorterTooltip={false}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {formik.errors.inviteList && (
                              <div
                                className={
                                  localStorage.getItem("lang") == "ar"
                                    ? "error ar"
                                    : "error en"
                                }
                                style={{ paddingLeft: "17px" }}
                              >
                                {formik.errors.inviteList}
                              </div>
                            )}
                          </div>
                        )}

                      </>) : (
                      <CustomEmptyDiv
                        title={t("select_task_type")}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* add task button  it's function will appear at onSubmit method see*/}
          <div className="text-center  model1">
            {currentStep > 0 && (
              <button
                type="button"
                onClick={() => {
                  setIsTransitioning(true);
                  setTimeout(() => {
                    setCurrentStep(currentStep - 1);
                    setIsTransitioning(false);
                  }, 300);
                }}
                className="button-list top_new color_new pt-50 bottom_button sp focus"
              >
                {t("previous")}
              </button>
            )}

            {currentStep < steps.length - 1 && (
              <button
                type="button"
                onClick={() => {
                  setIsTransitioning(true);
                  setTimeout(() => {
                    setCurrentStep(currentStep + 1);
                    setIsTransitioning(false);
                  }, 300);
                }}
                className="button-list top_new color_new pt-50 bottom_button sp focus"
              >
                {t("next")}
              </button>
            )}
            {currentStep === steps.length - 1 && (
              <button
                type="submit"
                /*disabled={loader}*/
                className="button-list top_new color_new pt-50 bottom_button sp focus"
              >
                {t("add_tsk")}
              </button>
            )}

          </div>
        </form>

        {/* remove modal end*/}
      </Modal>
      {/* add task modal  end*/}

      {/* remove modal start*/}
      <Modal
        centered
        footer={null}
        maskClosable={false}
        title={t("remove")}
        open={isModalRemoveuser}
        onOk={() => setIsModalRemoveuser(false)}
        onCancel={() => setIsModalRemoveuser(false)}
        className={
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en"
        }
      >
        {/* remove title */}
        <div className="text-center  model1">
          <p>{t("u_sure_to_rmv_this_usr")}</p>
        </div>
        <div className="text-center  model1">
          {/* yes button */}
          <button
            type="button"
            className="button-list top_new focus"
            onClick={() => {
              const tempSelectedIds = [...selectedIds];
              const tempAddedWorkerList = [...showValidatorLists];
              tempSelectedIds.splice(tempSelectedIds.indexOf(removalId), 1);

              let indexx;
              for (
                let index = 0;
                index < tempAddedWorkerList.length;
                index++
              ) {
                const element = tempAddedWorkerList[index];
                if (element.id == removalId) {
                  indexx = index;
                  break;
                }
              }

              if (indexx > -1) {
                // only splice tempAddedWorkerList when item is found
                tempAddedWorkerList.splice(indexx, 1); // 2nd parameter means remove one item only
              }
              setShowValidatorList(tempAddedWorkerList);

              setselectedIds(tempSelectedIds);
              setIsModalRemoveuser(false);
            }}
          >
            {t("yes")}
          </button>
          {/* no  button */}
          <button
            type="button"
            className="focus"
            onClick={() => setIsModalRemoveuser(false)}
          >
            {t("no")}
          </button>
        </div>
      </Modal>
      {/* add questions modal start*/}
      <Modal
        maskClosable={false}
        title={t("add_qns")}
        open={isQuestionModalVisible}
        onOk={() => {
          setModalVisibleQuestions(false);
        }}
        onCancel={() => {
          setModalVisibleQuestions(false);
        }}
        wrapClassName="poolworkers"
        footer={null}
        width={646}
        className={
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en"
        }
        centered
      >
        <div className="text-center  model1">
          {/* add question input box */}
          {quetsionLists.length < 16 ? (
            <div
              className="form-group wrapper_line models"
              style={{
                padding: "0px 17px 2px",
              }}
            >
              <div className="form-group">
                <label>
                  {t("add_qns")} ({t("max_16qns")})
                </label>
                <input
                  ref={addQuestionRef}
                  id="addQuestion"
                  onChange={formik.handleChange}
                  value={customFunctions.capitalizeFirstLetter(
                    formik.values.addQuestion
                  )}
                  type="text"
                  className="form-control"
                  placeholder={t("write_qns")}
                  onKeyDown={(e) => {
                    const valll = formik.values.addQuestion.trimLeft();
                    // no space add as question check
                    if (e.key == "Enter" && valll != "") {
                      if (valll.length < 50 && valll.length > 2) {
                        setQuetsionLists((prevVal) => {
                          const tempQuestions = [...prevVal];
                          tempQuestions.push({
                            question: customFunctions.capitalizeFirstLetter(
                              formik.values.addQuestion
                            ),
                            answerType: "Check-Box",
                            answers: [],
                          });
                          return tempQuestions;
                        });
                        formik.setValues({
                          ...formik.values,
                          addQuestion: "",
                        });
                      } else {
                        valll.length < 3
                          ? formik.setFieldError(
                            "addQuestion",
                            t("add_qn_char_3_min")
                          )
                          : formik.setFieldError(
                            "addQuestion",
                            t("qn_min_50char")
                          );
                      }
                    }
                  }}
                  autoComplete="off"
                />

                {formik.errors.addQuestion ? (
                  <div
                    className={
                      localStorage.getItem("lang") == "ar"
                        ? "error ar"
                        : "error en"
                    }
                    style={{ paddingLeft: "17px" }}
                  >
                    {formik.errors.addQuestion}
                  </div>
                ) : null}

                {/* info text */}
                {formik.values.addQuestion.trimLeft() != "" ||
                  quetsionLists.length > 0 ? (
                  <div
                    style={{
                      paddingLeft: 10,
                      paddingTop: 10,
                      color: "gray",
                      textAlign: localStorage.lang == "ar" ? "right" : "left",
                      fontSize: "13.5px",
                    }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} onClick={() => { }} />
                    <span
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      {t("press")}
                      <b>&nbsp;{t("enter")} &nbsp;</b>
                      {t("to_add_questions")}
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      paddingLeft: 10,
                      paddingTop: 10,
                      color: "gray",
                      textAlign: localStorage.lang == "ar" ? "right" : "left",
                      fontSize: "13.5px",
                    }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} onClick={() => { }} />
                    <span
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      {t("add_min_1qn")}
                    </span>
                  </div>
                )}
                {/* info text */}
              </div>
            </div>
          ) : null}

          {/* questions lists title */}
          {quetsionLists.length > 0 && (
            <div
              style={{
                fontWeight: "500",
                fontSize: "16px",
                color: "rgb(31, 71, 119)",
                textAlign: "start",
                margin: "15px 18px",
              }}
            >
              {t("qn_lists")}
            </div>
          )}

          {/* question lists in */}
          {quetsionLists.length > 0 && (
            <div
              style={{
                display: "flex",
                padding: "20px",
                margin: "0px 20px",
                borderRadius: "15px",
                marginBottom: "50px",
                alignItems: "flex-start",
                flexDirection: "column",

                boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
              }}
            >
              {quetsionLists.map((item, index) => {
                const questionIndex = index;
                return (
                  <div
                    key={index}
                    style={{
                      padding: "10px",
                      borderRadius: "8px",
                      width: "100%",
                      marginBottom:
                        questionIndex != quetsionLists.length - 1
                          ? "18px"
                          : null,
                      boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
                    }}
                  >
                    {/* question */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          display: "flex",
                          borderRadius: "10px",
                          fontWeight: "600",
                          color: "#214c7d",
                        }}
                      >
                        {questionIndex + 1}.
                      </span>
                      {/* individual Question */}
                      <div
                        style={{
                          fontSize: "16px",
                          borderRadius: "10px",
                          fontWeight: "600",
                          color: "rgb(33, 76, 125)",
                          width: "92%",
                          textAlign: "left",
                          wordWrap: "break-word",
                          display: localStorage.lang == "ar" ? "flex" : "unset",
                        }}
                      >
                        {item.question}
                      </div>

                      {/* remove question icon */}
                      <Tooltip title={t("delete_this_question")}>
                        <img
                          alt=""
                          onClick={() => {
                            setQuetsionLists((prevVal) => {
                              const tempQuestions = [...prevVal];
                              const reducedQuestions = tempQuestions.filter(
                                (ele, index) => {
                                  if (index != questionIndex)
                                    return index != questionIndex;
                                }
                              );
                              return reducedQuestions;
                            });
                          }}
                          style={{
                            padding: "2px",
                            height: "20px",
                            // marginTop: '5px',
                            cursor: "pointer",
                            borderRadius: "20px",
                            background: "rgb(33 76 125)",
                            marginTop: "5px",
                          }}
                          src={AppImage.crossIcon}
                          width={20}
                        />
                      </Tooltip>
                    </div>

                    {/* answer type  */}
                    <div
                      style={{
                        fontSize: "13px",
                        textAlign: localStorage.lang == "ar" ? "right" : "left",
                        color: "#2b5483",
                        padding: "10px 21px",
                        fontWeight: "600",
                      }}
                    >
                      <div>
                        <CustomHelp
                          labelRef={sourceTypeRef}
                          title={t("ans_type")}
                          popover={
                            localStorage.getItem("lang") == "ar"
                              ? arQuestionTypeExample :
                              enQuestionTypeExample
                          }
                          popoverWidth="500px"
                        />
                      </div>
                      <Radio.Group
                        onChange={(e) => {
                          setQuetsionLists((prev) => {
                            var temp = [...prev];
                            temp[questionIndex].answerType = e.target.value;
                            return temp;
                          });
                          // if the selected answer type is yes-no then remove all the answers
                          // and add only yes and no
                          if (e.target.value == "Yes-No") {
                            setQuetsionLists((prev) => {
                              var temp = [...prev];
                              temp[questionIndex].answers = ["Yes", "No"];
                              temp[questionIndex].conInputs = [];
                              return temp;
                            });
                          } else {
                            setQuetsionLists((prev) => {
                              var temp = [...prev];
                              temp[questionIndex].answers = [];
                              return temp;
                            });
                          }

                        }}
                        value={item.answerType}
                        style={{
                          margin: "5px 0px",
                        }}
                      >
                        <Radio
                          value={"Check-Box"}
                          style={{
                            color: "#214c7d",
                            fontSize: "12px",
                          }}
                        >
                          {t("check_box")}
                        </Radio>
                        <Radio
                          value={"Radio-Button"}
                          style={{
                            color: "#214c7d",
                            fontSize: "12px",
                          }}
                        >
                          {t("radio_btn")}
                        </Radio>
                        <Radio
                          value={"Radio-Buttons"}
                          style={{
                            color: "#214c7d",
                            fontSize: "12px",
                          }}
                        >
                          {t("radio_btns")}
                        </Radio>
                        <Radio
                          value={"Yes-No"}
                          style={{
                            color: "#214c7d",
                            fontSize: "12px",
                          }}
                        >
                          {t("yes_no")}
                        </Radio>
                      </Radio.Group>
                    </div>

                    {/* add anwers */}
                    <div
                      className="form-group"
                      style={{
                        alignItems: "self-start",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px 20px",
                      }}
                    >
                      {quetsionLists[questionIndex].answers.length < 6 && quetsionLists[questionIndex].answerType !== "Yes-No" ? (
                        <>
                          <label
                            style={{
                              fontWeight: "600",
                              fontSize: "13px",
                              color: "#1f4777",
                            }}
                          >
                            <CustomHelp
                              labelRef={sourceTypeRef}
                              title={t("add_ans")}
                              popover={
                                localStorage.getItem("lang") == "ar"
                                  ? arQuestionAnswerConditionalExample :
                                  enQuestionAnswerConditionalExample
                              }
                              popoverWidth="500px"
                            />
                          </label>
                          <input
                            id={`addAnswers${index}`}
                            type="text"
                            style={{
                              fontSize: "15px",
                              height: "39px",
                              borderRadius: "10px",
                              padding: "0px 12px",
                              marginTop: "10px",
                            }}
                            maxLength={50}
                            className="form-control add-question"
                            placeholder={t("write_ans")}
                            onKeyDown={(e) => {
                              let val = document.getElementById(`addAnswers${index}`)
                              if (
                                e.key == "Enter" && val.value.trimLeft() != ""
                              ) {
                                if (val.value.trimLeft().length >= 1 &&
                                  val.value.trimLeft().length <= 50
                                ) {
                                  formik.setFieldError("addedanswers", "");
                                  const questionData = [...quetsionLists];
                                  const questionSet =
                                    questionData[questionIndex];
                                  const answerSet = [...questionSet.answers];

                                  if (
                                    answerSet.indexOf(
                                      customFunctions.capitalizeFirstLetter(
                                        val.value.trimLeft()
                                      )
                                    ) == -1
                                  ) {
                                    answerSet.push(
                                      customFunctions.capitalizeFirstLetter(
                                        val.value.trimLeft()
                                      )
                                    );

                                    questionData[
                                      questionIndex
                                    ].answers = answerSet;
                                    setQuetsionLists(questionData);

                                    // setAnswerVal("");
                                    document.getElementById(
                                      `addAnswers${index}`
                                    ).value = "";
                                  } else {
                                    toast.error(t("duplicate_not_allowed"), {
                                      className:
                                        localStorage.lang == "ar"
                                          ? "Select_data ar"
                                          : "Select_data en",
                                      position:
                                        localStorage.lang == "ar"
                                          ? toast.POSITION.TOP_LEFT
                                          : toast.POSITION.TOP_RIGHT,
                                      toastId: "duplicateAnswer",
                                    });
                                  }

                                  if (
                                    quetsionLists[questionIndex].answers
                                      .length == 2
                                  ) {
                                    setTotalAnswers(totalanswers + 2);
                                  }
                                } else {
                                  if (
                                    val.value.trimLeft().length < 1
                                  ) {
                                    formik.setFieldError(
                                      "addedanswers",
                                      t("answer_3_min")
                                    );
                                  }
                                  if (
                                    document
                                      .getElementById(`addAnswers${index}`)
                                      .value.trimLeft().length > 50
                                  )
                                    formik.setFieldError(
                                      "addedanswers",
                                      t("ans_min_50char")
                                    );
                                }
                              }
                            }}
                            autoComplete="off"
                          />

                          {formik.errors.addedanswers ? (
                            <div
                              className={
                                localStorage.getItem("lang") == "ar"
                                  ? "error ar"
                                  : "error en"
                              }
                              style={{ paddingLeft: "17px" }}
                            >
                              {formik.errors.addedanswers}
                            </div>
                          ) : null}
                        </>
                      ) : null}

                      {quetsionLists[questionIndex].answers.length < 2 &&
                        !formik.errors.addedanswers ? (
                        <div
                          className={
                            localStorage.getItem("lang") == "ar"
                              ? "error ar"
                              : "error en"
                          }
                          style={{ paddingLeft: 0 }}
                        >
                          {t("add_min_2ans")}
                        </div>
                      ) : null}

                      {/* info text */}
                      {formik.values.addAnswers != "" ? (
                        <div
                          style={{
                            paddingLeft: 10,
                            paddingTop: 10,
                            color: "gray",
                            textAlign: "left",
                            fontSize: "13.5px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            onClick={() => { }}
                          />
                          <span
                            style={{
                              marginLeft: 10,
                              fontSize: "13px",
                            }}
                          >
                            {t("press")}
                            <b>&nbsp;{t("enter")} &nbsp;</b>
                            {t("to_add_answers")}
                          </span>
                        </div>
                      ) : null}
                      {/* info text */}
                    </div>

                    {/* answerLists */}
                    {quetsionLists[questionIndex].answers.length > 0 && (
                      <div
                        style={{
                          margin: "-4px 22px 50px",
                        }}
                      >
                        {/* Answer lists Header */}
                        <div
                          style={{
                            fontWeight: "600",
                            fontSize: "13px",
                            color: "#1f4777",
                            textAlign: "start",
                            margin: "15px 0px",
                          }}
                        >
                          {t("ans_lists")}
                        </div>
                        {/* answer Lists */}
                        <div
                          style={{
                            display: "flex",
                            padding: "20px",
                            borderRadius: "15px",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
                          }}
                        >
                          {quetsionLists[questionIndex].answers.map(
                            (item, index) => {
                              const answerIndex = index;
                              return (
                                <div
                                  key={index}
                                  style={{
                                    // height: '30px',
                                    padding: "10px",
                                    borderRadius: "8px",
                                    width: "100%",
                                    marginBottom:
                                      answerIndex !=
                                        quetsionLists[questionIndex].answers
                                          .length -
                                        1
                                        ? "18px"
                                        : null,
                                    boxShadow:
                                      "0px 0px 14px rgb(20 60 100 / 12%)",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* individual anwers */}
                                    {
                                      quetsionLists[questionIndex].answerType == "Yes-No" ? (
                                        <input
                                          type="text"
                                          style={{
                                            fontSize: "15px",
                                            height: "39px",
                                            borderRadius: "10px",
                                            padding: "0px 12px",
                                            marginTop: "10px",
                                            width: "92%",
                                            textAlign: "left",
                                            wordWrap: "break-word",
                                            display:
                                              localStorage.lang == "ar"
                                                ? "flex"
                                                : "unset",
                                            boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
                                          }}
                                          maxLength={50}
                                          className="form-control add-question"
                                          placeholder={t("write_ans")}
                                          value={item}
                                          onChange={(e) => {
                                            const questionData = [
                                              ...quetsionLists,
                                            ];
                                            const questionSet =
                                              questionData[questionIndex];
                                            const answerSet = [
                                              ...questionSet.answers,
                                            ];
                                            answerSet[answerIndex] = customFunctions.capitalizeFirstLetter(
                                              e.target.value
                                            );
                                            questionData[
                                              questionIndex
                                            ].answers = answerSet;
                                            setQuetsionLists(questionData);
                                          }}
                                          autoComplete="off"
                                        />
                                      ) : (
                                        <>
                                          <span
                                            style={{
                                              fontSize: "16px",
                                              display: "flex",
                                              borderRadius: "10px",
                                              fontWeight: "500",
                                              color: "#214c7d",
                                            }}
                                          >
                                            {answerIndex + 1}.
                                          </span>
                                          <div
                                            style={{
                                              fontSize: "16px",
                                              borderRadius: "10px",
                                              fontWeight: "600",
                                              color: "rgb(33, 76, 125)",
                                              width: "92%",
                                              textAlign:
                                                localStorage.lang == "ar"
                                                  ? "right"
                                                  : "left",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {item}
                                          </div>
                                        </>
                                      )
                                    }
                                    {/* conditional add icon */}
                                    <Popover
                                      title={t("manage_cond_inpts")}
                                      placement="top"
                                      trigger={"click"}
                                      overlayClassName="custom-popover"
                                      content={<>
                                        <h5>{t("whn_ansr_slctd")}</h5>
                                        <Row
                                          className="card"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            padding: "10px",
                                          }}
                                        >
                                          <Col
                                            style={{
                                              position: "relative",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <label>{t("input_title")}</label>
                                              <div
                                                style={{
                                                  padding: "10px",
                                                }}>
                                                <input type="text" style={{
                                                  fontSize: "15px",
                                                  height: "39px",
                                                  borderRadius: "10px",
                                                  padding: "0px 12px",
                                                  marginTop: "10px",
                                                  textAlign: "left",
                                                  wordWrap: "break-word",
                                                  display:
                                                    localStorage.lang == "ar"
                                                      ? "flex"
                                                      : "unset",
                                                  boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
                                                }}
                                                  maxLength={50}
                                                  className="form-control add-question"
                                                  onChange={(e) => {
                                                    setConInputTitle(e.target.value);
                                                  }}
                                                  value={conInputTitle}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <label>{t("input_type")}</label>
                                              <div
                                                style={{
                                                  padding: "10px",
                                                }}>
                                                <Select
                                                  className="semi-wide"
                                                  placeholder={t("type")}
                                                  value={conInputType}
                                                  onChange={(val) => {
                                                    setConInputType(val);
                                                  }}
                                                >
                                                  <Option value="textarea">{t("textarea")}</Option>
                                                  <Option value="text">{t("text")}</Option>
                                                  <Option value="number">{t("number")}</Option>
                                                  <Option value="date">{t("date")}</Option>
                                                </Select>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <label>{t("input_req")}</label>
                                              <div
                                                style={{
                                                  padding: "10px",
                                                }}>
                                                <Checkbox
                                                  onChange={(e) => {
                                                    setConInputReq(e.target.checked);
                                                  }}
                                                  checked={conInputReq}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <label>{t("input_use_para")}</label>
                                              <div
                                                style={{
                                                  padding: "10px",
                                                }}>
                                                <Checkbox
                                                  onChange={(e) => {
                                                    setConInputPara(e.target.checked);
                                                  }}
                                                  checked={conInputPara}
                                                />
                                              </div>
                                            </div>
                                          </Col>

                                        </Row>
                                        <div className="text-center button_d_inline model1" style={{ marginTop: "60px" }}>
                                          <button
                                            type="button"
                                            className="button-list top_new active"
                                            onClick={() => {
                                              //check title and type
                                              if (!conInputTitle || !conInputType) {
                                                toast.error(t("fill_all_fields"), {
                                                  className:
                                                    localStorage.lang == "ar"
                                                      ? "Select_data ar"
                                                      : "Select_data en",
                                                  position:
                                                    localStorage.lang == "ar"
                                                      ? toast.POSITION.TOP_LEFT
                                                      : toast.POSITION.TOP_RIGHT,
                                                  toastId: "fillAllFields",
                                                });
                                              } else {
                                                let tempQuestions = [...quetsionLists];
                                                tempQuestions[conQuestionIndex].conInputs = [
                                                  ...tempQuestions[conQuestionIndex].conInputs ? tempQuestions[conQuestionIndex].conInputs : [],
                                                  {
                                                    title: conInputTitle,
                                                    type: conInputType,
                                                    required: conInputReq,
                                                    use_paragraph: conInputPara,
                                                    answerIndex: conAnswerIndex
                                                  }
                                                ]
                                                setConInputReq(false);
                                                setConInputTitle("")
                                                setConInputType("")
                                                setConInputPara(false)
                                                setQuetsionLists(tempQuestions);
                                              }
                                            }}
                                          >
                                            {t("add")}
                                          </button>
                                        </div>
                                      </>
                                      }>
                                      <Tooltip title={t("add_cond")}>
                                        <img
                                          alt=""
                                          style={{
                                            padding: "2px",
                                            height: "35px",
                                            width: "35px",
                                            marginRight: "15px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setConQuestionIndex(questionIndex);
                                            setConAnswerIndex(answerIndex);
                                          }}
                                          src={AppImage.plusIcon}
                                        />
                                      </Tooltip>
                                    </Popover>
                                    {/* remove question icon */}
                                    <Tooltip title={t("delete_this_answer")}>
                                      <img
                                        alt=""
                                        onClick={() => {
                                          const questionData = [
                                            ...quetsionLists,
                                          ];
                                          const questionSet =
                                            questionData[questionIndex];
                                          const answerSet = [
                                            ...questionSet.answers,
                                          ];
                                          const reducedAnswers = [];
                                          answerSet.filter((ele, index) => {
                                            if (index != answerIndex) {
                                              reducedAnswers.push(ele);
                                            }
                                          });
                                          questionData[
                                            questionIndex
                                          ].answers = reducedAnswers;
                                          setQuetsionLists(questionData);
                                        }}
                                        style={{
                                          padding: "2px",
                                          height: "15px",
                                          width: "15px",
                                          cursor: "pointer",
                                          borderRadius: "20px",
                                          background: "rgb(33 76 125)",
                                          marginTop: "10px",
                                        }}
                                        src={AppImage.crossIcon}
                                      />
                                    </Tooltip>
                                  </div>
                                  {quetsionLists[questionIndex].conInputs?.filter(x => x.answerIndex == answerIndex)?.length > 0 &&
                                    <div className="card" style={{
                                      marginTop: "20px",
                                    }}>
                                      <div
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "13px",
                                          color: "#1f4777",
                                          textAlign: "start",
                                          margin: "15px 0px",
                                        }}
                                      >
                                        {t("con_lists")}
                                      </div>
                                      <div style={{
                                        display: "flex",
                                        marginBottom: "20px",
                                        flexDirection: "column",
                                      }}>
                                        {quetsionLists[questionIndex].conInputs?.filter(x => x.answerIndex == answerIndex)
                                          .map(
                                            (item2, index2) => {
                                              return (
                                                <div key={index2} style={{
                                                  display: "flex",
                                                  marginBottom: "10px",
                                                  justifyContent: "space-between",
                                                  alignItems: "center",
                                                }}>
                                                  <span
                                                    style={{
                                                      fontSize: "16px",
                                                      display: "flex",
                                                      borderRadius: "10px",
                                                      fontWeight: "500",
                                                      color: "#214c7d",
                                                    }}
                                                  >
                                                    {index2 + 1}.
                                                  </span>
                                                  <div
                                                    style={{
                                                      fontSize: "16px",
                                                      borderRadius: "10px",
                                                      fontWeight: "600",
                                                      color: "rgb(33, 76, 125)",
                                                      marginRight: "10px",
                                                      textAlign:
                                                        localStorage.lang == "ar"
                                                          ? "right"
                                                          : "left",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    {item2?.title}
                                                  </div>
                                                  <span>{`( ${t(item2?.type)} / ${item2?.required ? t("req") : ""} / ${item2?.use_paragraph ? t("input_use_para") : ""} )`}</span>
                                                  {/* remove question icon */}

                                                  <Tooltip title={t("delete_this_con")}>
                                                    <img
                                                      alt=""
                                                      onClick={() => {
                                                        const questionData = [
                                                          ...quetsionLists,
                                                        ];
                                                        const questionSet =
                                                          questionData[questionIndex];
                                                        const conInputs = [
                                                          ...questionSet.conInputs,
                                                        ];
                                                        const reducedConInputs = [];
                                                        conInputs.filter((ele, index) => {
                                                          if (index != index2) {
                                                            reducedConInputs.push(ele);
                                                          }
                                                        });
                                                        questionData[
                                                          questionIndex
                                                        ].conInputs = reducedConInputs;
                                                        setQuetsionLists(questionData);
                                                      }}
                                                      style={{
                                                        padding: "2px",
                                                        height: "15px",
                                                        width: "15px",
                                                        cursor: "pointer",
                                                        borderRadius: "20px",
                                                        background: "rgb(33 76 125)",
                                                        marginTop: "10px",
                                                      }}
                                                      src={AppImage.crossIcon}
                                                    />
                                                  </Tooltip>
                                                </div>)
                                            })}
                                      </div>
                                    </div>
                                  }
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )
          }
        </div >
        {/* add button */}
        < div className="text-center  model1" >
          <button
            type="submit"
            className="button-list focus"
            onClick={() => {
              var isMinAns = false;
              for (let i of quetsionLists) {
                if (i.answers.length < 2) {
                  isMinAns = true;
                }
              }

              if (!isMinAns) {
                if (quetsionLists.length > 0) {
                  formik.setFieldValue("questions", "something");
                } else {
                  formik.setFieldError("questions", t("add_min_1qn"));
                }
                setModalVisibleQuestions(false);
                // setDataSourceForQuestions([
                //   {
                //     key: "1",
                //     qn: "Dummmy Name",
                //     qnType: "checkBox",
                //     ans: <span>hi</span>,
                //   },
                // ]);
                // setAnswerVal("");
                setShowQuestionTable(!showQuestionTable);
                // setQuestionTableShow(true);
              }
            }}
          >
            {t("add")}
          </button>
          {/* <button type="submit" onClick={() => handleCancel(false)}>Cancel</button> */}
        </div >
      </Modal >
      {/* add questions modal end*/}

      {/* pool of worker modal start*/}
      <Modal
        maskClosable={false}
        title={t("pool_of_wrkr")}
        open={isModalVisibleValidator}
        onOk={() => {
          setIsModalVisibleValidator(false);

          localStorage.removeItem("selectedFromPool");
        }}
        onCancel={() => {
          setIsModalVisibleValidator(false);
          setDefaultCurrent(1);
          setPageNum(1);
          if (showValidatorLists.length == 0) {
            setselectedIds([]);
            // setValidatorList([]);
          } else {
            let tempIds = [];
            showValidatorLists.map((item) => {
              tempIds.push(item.id);
            });
            setselectedIds(tempIds); //DO HERE
          }

          localStorage.removeItem("selectedFromPool");
        }}
        wrapClassName="poolworkers"
        footer={null}
        width={800}
        className={
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en"
        }
        centered
      >
        <div className="text-center  model1">
          <div className="form-group wrapper_line models">
            {/* Select From Currently Active */}
            <div className="new_new_data">
              <div
                className="top_data_1"
                style={{ direction: localStorage.lang == "en" ? "ltr" : "rtl" }}
              >
                {/* search input */}
                <div className="button_left contain">
                  {/* search bar */}
                  <input
                    type="text"
                    autoComplete="off"
                    className="search-bar"
                    placeholder={t("search")}
                    value={formik.values.searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setPageNum(1);
                    }}
                  />
                  {/* search bar */}
                </div>

                <Select
                  placeholder={t("rating")}
                  value={ratingVal}
                  onChange={(val) => {
                    setRatingVal(val);
                  }}
                >
                  <Option value="0-1">0-1</Option>
                  <Option value="1-2">1-2</Option>
                  <Option value="2-3">2-3</Option>
                  <Option value="3-4">3-4</Option>
                  <Option value="4-5">4-5</Option>
                </Select>
                <Select
                  placeholder={t("lang")}
                  onChange={(val) => {
                    setLanguageVal(val);
                  }}
                  value={languageVal}
                >
                  {languageChildren.map((item) => {
                    return <Option key={item.code} value={item.code}>{item.name}</Option>;
                  })}
                </Select>
              </div>

              {/* select from currently active  */}

              {
                currentlyActive.length > 0 ? (
                  <>
                    <div className="data_news poolworkers_ppop" style={{
                      marginTop: "20px",
                    }}>
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "15px",
                        fontWeight: "800",
                        borderBottom: "1px solid rgb(20 60 100 / 44%)",
                      }}>
                        <label className="bold">{t("worker_name")}</label>
                        <label className="bold">{t("rating")}</label>
                        <label className="bold">{t("languages")}</label>
                        <label className="bold">{t("actions")}</label>
                      </div>
                      {
                        currentlyActive.map((item, index) => {
                          return (
                            // row //
                            <div className="sperator_pool" key={index}>
                              {/* worker profile img */}
                              <div className="first_text">

                                <img
                                  src={item.profile}
                                  style={{
                                    width: "27px",
                                    height: "27px",
                                    borderRadius: "27px",
                                  }}
                                ></img>
                              </div>

                              {/* worker name */}
                              <div
                                className="first_text"
                                style={{
                                  width: "215px",
                                  paddingLeft: "5px",
                                  textAlign: "start",
                                  paddingRight: "5px",
                                }}
                              >
                                {item?.skills?.length > 0 ? (
                                  <CustomHelp title={item.name} popover={
                                    <>
                                      <h3 className="bold">{t("skills")}</h3>
                                      <Table
                                        pagination={true}
                                        columns={skillCol}
                                        showSorterTooltip={false}
                                        dataSource={userskillDataSource}
                                      />
                                    </>
                                  }
                                    popoverWidth={userskillDataSource?.length ? "600px" : "300px"}
                                    popoverIcon={AppImage.info}
                                    onClick={() => {
                                      setUserskillDataSource(item.skills);
                                    }}
                                  />) : <CustomHelp title={item.name} />}
                              </div>

                              {/* rating  */}
                              <div>
                                <Rate
                                  style={{
                                    fontSize: "20px",
                                  }}
                                  className="edit-group-star"
                                  allowHalf
                                  value={item.rating}
                                  disabled={true}
                                />
                              </div>
                              {/* Languaes */}
                              <div
                                className="first_text"
                                style={{
                                  width: "215px",
                                  paddingLeft: "5px",
                                  textAlign: "start",
                                  paddingRight: "5px",
                                }}>
                                {item.languages && item.languages.length > 0 ? (
                                  <Tooltip
                                    title={item.languages.map(item => { return item.name }).join(", ")}
                                  >
                                    {item.languages.slice(0, 2).map((lang, index) => {
                                      return (
                                        <span
                                          key={index}
                                          style={{
                                            fontSize: "12px",
                                            color: "#214c7d",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {lang.name} {" "}
                                        </span>
                                      );
                                    })}
                                  </Tooltip>
                                ) :
                                  <div className="first_text"
                                    style={{
                                      width: "215px",
                                      paddingLeft: "5px",
                                      textAlign: "start",
                                      paddingRight: "5px",
                                    }}>{t("not_specified")}</div>
                                }
                              </div>
                              {/* tick for selected workers */}
                              <div
                                className={
                                  localStorage.getItem("lang") == "en"
                                    ? "star_new"
                                    : "star_new ar"
                                }
                              >
                                <Tooltip
                                  title={t("select_as_worker")}
                                >
                                  <Button
                                    type="primary"
                                    shape="round"
                                    icon={<TagsOutlined />}
                                    size="large"
                                    className={
                                      showValidatorLists.filter(
                                        (i) =>
                                          i.id == item.id &&
                                          i.type.includes("annotator")
                                      ).length > 0
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      updateWorker(item, "annotator");
                                    }}
                                  />
                                </Tooltip>

                                <Tooltip
                                  title={t("select_as_validator")}
                                >
                                  <Button
                                    type="primary"
                                    shape="round"
                                    icon={<SafetyOutlined />}
                                    size="large"
                                    className={
                                      showValidatorLists.filter(
                                        (i) =>
                                          i.id == item.id &&
                                          i.type.includes("validator")
                                      ).length > 0
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      updateWorker(item, "validator");
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <Pagination
                      showTitle={false}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: "0",
                        right: "0",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        maxWidth: "fit-content",
                        position: "absolute",
                      }}
                      className={
                        localStorage.lang == "ar"
                          ? "pagination-component ar"
                          : "pagination-component"
                      }
                      defaultCurrent={defaultCurrent}
                      current={pageNum}
                      total={totalUsers}
                      size="medium"
                      onChange={(item) => {
                        setPageNum(item);
                      }}
                    />
                  </>
                ) : (
                  <CustomEmptyDiv marginTop={"30px"} title={t("no_user_found")} />
                )}
            </div>
          </div>
        </div>
        {/* add button */}
      </Modal>
      {/* pool of worker modal end*/}

      {/* pool of email modal start*/}
      <Modal
        maskClosable={false}
        title={t("add_wrkr_thr_email_adrs")}
        open={isModalVisibleEmail}
        onOk={() => {
          setIsModalVisibleEmail(false);
        }}
        onCancel={() => {
          setIsModalVisibleEmail(false);
        }}
        wrapClassName="poolworkers"
        footer={null}
        width={600}
        className={
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en"
        }
        centered
      >
        <div className="text-center  model1">
          <div className="wrapper_line models">
            <div
              style={{
                direction: localStorage.lang == "en" ? "ltr" : "rtl",
                display: "flex",
                justifyContent: "flex-start",
                // alignItems: "center",
              }}
            >
              {/* Email input */}
              <div className="form-group" style={{ marginRight: "10px", width: "30vw" }}>
                <label>{t("email")}</label>
                <input
                  type="email"
                  className={
                    localStorage.lang == "en"
                      ? "form-control"
                      : "form-control ar"
                  }
                  placeholder={t("email")}
                  value={formik.values.inviteEmail}
                  onChange={(e) => {
                    formik.setFieldValue("inviteEmail", e.target.value);
                  }}
                  autoComplete="off"
                />
                {formik.errors.inviteEmail ? (
                  <div
                    className={
                      localStorage.getItem("lang") == "ar"
                        ? "error ar"
                        : "error en"
                    }
                    style={{ paddingLeft: "17px" }}
                  >
                    {formik.errors.inviteEmail}
                  </div>
                ) : null}
              </div>
              {/* type select */}
              <div className="form-group ">
                <label>{t("invite_type")}</label>
                <div className="top_data_1">
                  <Select
                    placeholder={t("invite_type")}
                    onChange={(val) => {
                      formik.setFieldValue("inviteType", val);
                    }}
                    defaultValue={"annotator"}
                  >
                    <Option value="annotator"> <TagsOutlined /> {t("annotator")}</Option>
                    <Option value="validator"> <SafetyOutlined /> {t("validator")}</Option>
                    <Option disabled value="modirator"> <UsergroupAddOutlined /> {t("moderator")}</Option>
                  </Select>

                </div>
                {formik.errors.inviteType ? (
                  <div
                    className={
                      localStorage.getItem("lang") == "ar"
                        ? "error ar"
                        : "error en"
                    }
                    style={{ paddingLeft: "17px" }}
                  >
                    {formik.errors.inviteType}
                  </div>
                ) : null}
              </div>
            </div>
            {/* add button */}
            <div className="text-center  model1">
              <button
                type="button"
                className="button-list focus"
                onClick={() => {
                  if (formik.values.inviteEmail.trimLeft() != "") {
                    if (formik.values.inviteType.trimLeft() != "") {
                      // attempt to fond the worker in the list
                      let item = undefined;
                      currentlyActive.map((ele) => {
                        if (ele.email == formik.values.inviteEmail) {
                          item = ele;
                        }
                      });
                      // TODO: pool might be insufficient , use API to get the worker
                      if (!item) {
                        item = {
                          id: showValidatorLists.length + 1,
                          email: formik.values.inviteEmail,
                          type: formik.values.inviteType,
                        };
                      }


                      if (formik.values.inviteType == "annotator") {
                        updateWorker(item, "annotator");
                      } else {
                        updateWorker(item, "validator");
                      }

                    } else {
                      formik.setFieldError("inviteType", t("invite_type_required"));
                    }
                  } else {
                    formik.setFieldError("inviteEmail", t("email_required"));
                  }
                }}
              >
                {t("add")}
              </button>
              {/* <button type="submit" onClick={() => handleCancel(false)}>Cancel</button> */}
            </div>
          </div>
        </div>
      </Modal >
      {/* pool of email modal end*/}
      {/* preview modal start*/}
      <Modal
        centered
        open={prevOpen}
        onOk={() => setPrevOpen(false)}
        onCancel={() => setPrevOpen(false)}
        footer={null}
        width={923}
      >
        <TaskPreviewModal
          data={prevData}
          taskType={
            formik.values.taskType
              ? formik.values.taskType == 1
                ? formik.values.annotType
                : formik.values.taskType == 2
                  ? formik.values.textType
                  : "transcription"
              : ""
          }
          validationSystem={
            formik.values.validationSystem
          }
        ></TaskPreviewModal>
      </Modal>
      {/* preview modal end*/}
    </div >
  );
};


AddTaskModal.propTypes = {
  show: Protptype.bool,
  hideTaskModal: Protptype.func,
  dataToShow: Protptype.object,
};
export default AddTaskModal;
