import { toast } from "react-toastify";
// import i18n from '../../../i18n';
import i18n from "i18next";
export const customFunctions = {
  showToast: (msg, succORerr, id) => {
    if (succORerr) {
      toast.success(msg, {
        className:
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,
        toastId: id,
      });
    }
    if (!succORerr) {
      toast.error(msg, {
        className:
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,
        toastId: id,
      });
    }
  },
  FileSizeConverter: (sizeInByte, convertTo) => {
    var totalBytes = sizeInByte;
    var _size = 0;
    switch (convertTo) {
      case "KB": {
        _size = Math.floor(totalBytes / 1000);
        return _size;
      }
      case "MB": {
        _size = Math.floor(totalBytes / 1000000);
        return _size;
      }
      default:
        break;
    }
  },
  languageBasedDataReverse: (dataInArray) => {
    if (localStorage.getItem("lang") == "ar") return dataInArray.reverse();
    else return dataInArray;
  },
  capitalizeFirstLetter: (originalString) => {
    const capitalizeFirstLettter = originalString.charAt(0).toUpperCase();
    const modifiedString = capitalizeFirstLettter.concat(
      originalString.slice("1")
    );
    return modifiedString;
  },
  miliSecondsToTime: (mTime) => {
    var ms = mTime % 1000;
    mTime = (mTime - ms) / 1000;
    var secs = mTime % 60;
    mTime = (mTime - secs) / 60;
    var mins = mTime % 60;
    // var hrs = (mTime- mins) / 60;

    return mins + ":" + secs;
  },
  regexSet: (regOf, min, max) => {
    let validation;
    switch (regOf) {
      case "personFN":
        validation = {
          rule: /^[a-zA-Z.'\s]+$/,
          msg: i18n.t("valid_first_name"),
          req: i18n.t("please_enter_first_name"),
          max:
            i18n.t("max_chars", { max }) || i18n.t("max_chars", { max: "20" }),
          min:
            i18n.t("string_min", { min, name: i18n.t("first_name") }) ||
            i18n.t("string_min", { min: "2", name: i18n.t("first_name") }),
        };
        return validation;
      case "personLN":
        validation = {
          rule: /^[a-zA-Z.']+$/,
          msg: i18n.t("valid_last_name"),
          req: i18n.t("please_enter_last_name"),
          max:
            i18n.t("max_chars", { max }) || i18n.t("max_chars", { max: "20" }),
          min:
            i18n.t("string_min", { min, name: i18n.t("last_name") }) ||
            i18n.t("string_min", { min: "2", name: i18n.t("last_name") }),
        };
        return validation;
      case "email":
        validation = {
          rule: /^(([a-zA-Z0-9]+)||([a-zA-Z0-9_.-]+[a-zA-Z0-9]+))@([0-9a-zA-Z]+)([^0-9])([0-9a-zA-Z]+)||([a-zA-Z0-9])(\.[a-zA-z]{2,5})$ /,
          msg: i18n.t("entr_valid_email_adrs"),
          req: i18n.t("pls_entr_email"),
          max:
            i18n.t("max_chars", { max }) || i18n.t("max_chars", { max: "320" }),
          min: i18n.t("string_min", { min, name: i18n.t("email_address") }),
        };
        return validation;
      case "password":
        validation = {
          rule: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&()\\-`.+,/\"^*?%-=,\[<\]>{}])[A-Za-z\d!@#$&()\\-`.+,/\"^*?%-\[\]=,<>{}]{6,1000}$/,
          msg: i18n.t("paswrd_condtn"),
          req: i18n.t("plz_enter_password"),
          max: i18n.t("max_chars", { max }),
          min: i18n.t("string_min", { min, name: i18n.t("password") }),
        };
        return validation;
      case "confirmPassword":
        validation = {
          rule: /^(\S+$)/,
          msg: i18n.t("spc_not_alwd"),
          req: i18n.t("plz_entr_cnfrm_pass"),
          max: i18n.t("max_chars", { max }), //`Maximum ${max} characters are allowed.`,
          min: i18n.t("string_min", { min, name: i18n.t("confrm_password") }), //`Confirm password must be minimum ${min} characters long.`,
          match: i18n.t("pass_cnfrmpass_same"),
        };
        return validation;
      case "phoneNum":
        validation = {
          rule: /^\d{3}[-\s]?\d{3}[-\s]?\d{2,9}$/,
          msg: i18n.t("enter_valid_contact_num"),
          req: i18n.t("please_enter_contact_num"),
          max: i18n.t("max_chars", { max }), //`Maximum ${max} characters are allowed.`,
          min: i18n.t("string_min", { min, name: i18n.t("phone_num") }), //`Phone number must be minimum ${min} characters long.`
        };
        return validation;
      case "countryCode":
        validation = {
          rule: /^[+][^0]\d{0,3}$/,
          msg: i18n.t("please_enter_valid_cntry_code"),
          req: i18n.t("please_enter_cntry_code"),
          max: i18n.t("max_chars", { max }), //`Maximum ${max} characters are allowed.`,
          min: i18n.t("string_min", { min, name: i18n.t("cntry_code") }), //`Country code must be minimum ${min} characters long.`
        };
        return validation;

      default:
        break;
    }
  },
};
