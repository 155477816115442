import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../hoc";
import {
  CustomPageHeader,
  CustomTable,
  TextField,
} from "../../../molecules";
import { AppImage, Constants, NavigateTo, SidebarMenus } from "../../../themes";
import { Collapse, Modal } from "antd";

import { useNavigate } from "react-router";

const { Panel } = Collapse;
// const { Option } = Select;

const GroupDetails = () => {
  const navigate = useNavigate();
  const [isReport, setIsModalReport] = useState(false);
  // const [isModalRemove, setIsModalRemove] = useState(false);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isAnnotation, setIsModalAnnotation] = useState(false);
  // const [lang, setLang] = useState(localStorage.getItem('lang'));
  // const [isModalVisibleWorker, setIsModalVisibleWorker] = useState(false);

  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: Constants.home,
    },
    {
      link: NavigateTo.PROJECT,
      label: Constants.Projects,
    },
    {
      link: NavigateTo.PAST_PROJECT_DETAILS,
      label: Constants.pastDetails,
    },
    {
      link: '',
      label: Constants.GroupDetials,
    },
  ];

  /* page description */
  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: 'break-all'
  };
  const gn = [
    {
      labelTitle: `${Constants.Groupname}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: "Group",
      customLabelStyle: rightFontStyle,
    },
  ];
  const ctd = [
    {
      labelTitle: `${Constants.completedtasksd}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: 'Task, Task 2',
      customLabelStyle: rightFontStyle,
    },
  ];

  const textFieldlabels = [gn, ctd];

  /* page description */

  /* drop down */

  /* drop down */

  const taskListHeaders = [
    {
      label: Constants.name,
      headerWidth: "200px",
    },
    {
      label: Constants.type,
      headerWidth: "250px",
    },
    {
      label: Constants.status,
      headerWidth: "300px",
    }

  ];
  const taskListContents = [
    {
      0: 'Micheal',
      1: "Type",
      2: 'Pending'
    },
    {
      0: 'Micheal',
      1: "Type",
      2: 'Pending'
    },
  ];

  const workerListHeaders = [
    {
      label: Constants.ProfileImage,
      headerWidth: "250px",
    },
    {
      label: Constants.name,
      headerWidth: "150px",
    },
    {
      label: Constants.Skills,
      headerWidth: "500px",
    },
    {
      label: Constants.AllocatedTasks,
      headerWidth: "300px",
    },
    {
      label: Constants.ContactNum,
      headerWidth: '200px',
    },
    {
      label: Constants.Action,
      headerWidth: "100px",
      paddingLeft: "38px",
    },
  ];
  const workerListContents = [
    {
      0: <img src={AppImage.user_1} />,
      1: 'Micheal',
      2: 'Skill 1, Skill 2, Skill 3',
      3: 'Task',
      4: '+914534278905',
      5: {
        button: Constants.Report,
        link: "",
        id: 'wReport'
      }, //change later
    },
    {
      0: <img src={AppImage.user_2} />,
      1: 'Micheal',
      2: 'Skill 1, Skill 2, Skill 3',
      3: 'Task',
      4: '+914534278905',
      5: {
        button: Constants.Report,
        link: "",
        id: 'wReport'
      }, //change later
    },
  ];

  const dropDownGroup = [
    {
      table: {
        placeholder: Constants.TaskList,
        tableHeaders: taskListHeaders,
        tableContents: taskListContents,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        tableClassName: localStorage.getItem('lang') == 'ar' ? 'text_data owner-past-proj-details-grp-details-task-list-ar' : 'text_data owner-past-proj-details-grp-details-task-list-en'
      },
    },
    {
      table: {
        placeholder: Constants.workerlist,
        tableHeaders: workerListHeaders,
        tableContents: workerListContents,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        // tableClassName: localStorage.getItem('lang')=='ar' ? 'text_data owner-past-proj-details-grp-details-ar' : 'text_data owner-past-proj-details-task-list-en'
      },
    },

  ];

  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: Constants.Projects,
  };
  useEffect(() => {
    document.title = 'WosoM';
    window.scrollTo(0, 0)

  }, [])

  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isReport]);
  return (
    <Container {...containerProps}
      // languageChanged={() => {
      //   setLang(localStorage.getItem("lang"));
      //   // it is reuqired to re render this whole component
      // }}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        <CustomPageHeader
          label={Constants.GroupDetials}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px",
            marginTop: "30px",
          }}
        />

        <div className="wrapper_line owner-ongoingprojdetails">
          {/* pageDescription */}
          {textFieldlabels.map((item) => {
            return <TextField key={item}  labels={item} />;
          })}
          {/* pageDescription */}

          {/* dropdowns */}
          {dropDownGroup.map((item, index) => {
            return (
              <Collapse key={index} defaultActiveKey={["1"]} className="owner-group-details-taskList" onChange={() => { }}>
                <Panel id={index} header={item.table.placeholder} key="1">
                  <CustomTable
                    {...item.table}
                    handleClick={(label, id) => {
                      switch (id) {
                        case "tView": navigate(NavigateTo.PAST_TASK_DETAILS)
                          break;
                        case "gView": navigate(NavigateTo.GROUP_DETAILS)
                          break;
                        case "wReport": setIsModalReport(true)
                          break;
                        default:
                          break;
                      }
                    }
                    }

                  />
                </Panel>
              </Collapse>
            );
          })}
          {/* dropdowns */}

          {/* report modal */}
          <Modal
            title={Constants.Report}
            open={isReport}
            onOk={() => setIsModalReport(false)}
            onCancel={() => setIsModalReport(false)}
            footer={null}
            centered
          >
            <div className="text-center  model1">
              <p>{Constants.report}</p>
            </div>
            <div className="text-center  model1"
              style={{
                direction: localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr'
              }}
            >
              <button
                type="submit"
                className="button-list top_new focus"
                onClick={() => setIsModalReport(false)}
              >
                {Constants.Yes}
              </button>
              <button type="submit" className="focus" onClick={() => setIsModalReport(false)}>
                {Constants.No}
              </button>
            </div>
          </Modal>

        </div>
      </CardHoc>
    </Container>
  );
};

export default GroupDetails;
