import { fieldIds, NavigateTo, SidebarMenus } from "../../../themes";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { CardHoc, Container } from "../../../hoc";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerDetails } from "../../../redux/actions/Owner";
import { CustomPageHeader, TextField } from "../../../molecules";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";

const Profile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const userId = localStorage.getItem("loggedInUserId");
  const ownerDetails = useSelector(
    (state) => state?.ownerManagement?.ownerDetails
  );
  const [fName, setFname] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const breadCrumbItems = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.USER_PROFILE,
      label: t("profile"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t("profile"),
  };

  const ProfileImage = (props) => {
    const { image } = { ...props };
    return (
      <div
        style={{
          width: "100%",
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div className="image_title">
          <img
            src={image}
            style={{
              width: "101px",
              height: "101px",
              borderRadius: "101px",
            }}
          />
          {/* <div
            style={{
              width: "101px",
              height: "101px",
              borderRadius : '101px',
              backgroundImage: `url(${image})`,
              textAlign: "center",
              // cursor: "pointer",
            }}
          /> */}
        </div>
        {/* </Tooltip> */}
      </div>
    );
  };
  const personalFormFields = [
    {
      type: "text",
      error: null,
      inputTrue: true,
      id: fieldIds.FIRSTNAME,
      value: fName,
      placeholder: t("first_name"),
      fieldLabel: t("first_name"),
    },
    {
      type: "text",
      error: null,
      inputTrue: true,
      id: fieldIds.LASTNAME,
      value: lName,
      placeholder: t("last_name"),
      fieldLabel: t("last_name"),
    },
    {
      type: "text",
      error: null,
      inputTrue: true,
      id: fieldIds.CONTACTNUM,
      value: number,
      placeholder: t("contact_num"),
      fieldLabel: t("contact_num"),
    },
    {
      type: "text",
      error: null,
      inputTrue: true,
      id: fieldIds.EMAIL,
      value: email,
      placeholder: t("email_address"),
      fieldLabel: t("email_address"),
    },
  ];
  useEffect(() => {
    document.title = "WosoM";
    isInternetConnected() && dispatch(getOwnerDetails());
  }, []);
  useEffect(() => {
    setFname(ownerDetails?.first_name);
    setLName(ownerDetails?.last_name);
    setEmail(ownerDetails?.email);
    setNumber(ownerDetails?.country_code + ownerDetails?.phone);
    setProfileImage(ownerDetails?.image);
  }, [ownerDetails]);

  return (
    <Container {...containerProps}>
      <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        <CustomPageHeader
          label={t("profile")}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px",
          }}
        />

        <ProfileImage image={profileImage} />

        {personalFormFields.map((items) => {
          return (
            <div
              key={items.fieldLabel}
              className="wrapper_line form_wrapper"
              style={{ padding: "0px 15px 5px" }}
            >
              <TextField
                {...items}
                labelTitle={items.fieldLabel}
                isDisabled={true}
              />
            </div>
          );
        })}
        <div className="wrapper_line">
          <Link to={NavigateTo.CHANGE_PASSWORD}>
            <button type="submit" className="button-list pt-10 focus">
              {t("change_password")}
            </button>
          </Link>
          <button
            type="submit"
            className="button-list pt-10 focus"
            onClick={() => {
              navigate(NavigateTo.EDIT_PROFLE, { state: { name: "hi" } });
            }}
          >
            {t("edit_prfl")}
          </button>
          {/* </Link> */}
        </div>
      </CardHoc>
    </Container>
  );
};

export default Profile;
