import { toast } from "react-toastify"
import i18n from 'i18next'
export const isInternetConnected = (id = 'est25') => {
    if (!navigator.onLine) {
        // toast.dismiss();

        toast.error(i18n.t('chk_intrnet_connectn'), {
            className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
            toastId: 'failedInternet'
        });
    }
    localStorage.setItem('connection_id', id);
    return navigator.onLine;
}
