import React from "react"
import { Layout, Menu } from 'antd';
import { useLocation } from "react-router"

import {
  Link,
  useNavigate

} from "react-router-dom";
import images from "../themes/appImage";
import { Drawer } from 'antd';
import PropTypes from "prop-types";
const { Sider } = Layout;


const MobileSidebar = ({
  handlewClick,
  isVisible,
}) => {

  let navigate = useNavigate();
  let location = useLocation();


  const onMenuClick = (e) => {
    navigate(e.key)
    // history.push(e.key);
  };


  let activeKey = ""
  switch (location.pathname) {
    case "/AdminDashboard":
      activeKey = "/AdminDashboard"
      break;
    case "/ownermanagement":
      activeKey = "/ownermanagement"
      break;
    case "/adddetails":
      activeKey = "/ownermanagement"
      break;
    case "/OwnerDetails":
      activeKey = "/ownermanagement"
      break;
    case "/EditDetails":
      activeKey = "/ownermanagement"
      break;
    case "/usermanagement":
      activeKey = "/usermanagement"
      break;
    case "/UserDetails":
      activeKey = "/usermanagement"
      break;
    case "/projectadmin":
      activeKey = "/projectadmin"
      break;
    case "/adminprojectdetails":
      activeKey = "/projectadmin"
      break;
    case "/projecttaskdetails":
      activeKey = "/projectadmin"
      break;

    case "/changepassword":
      activeKey = "/changepassword"
      break;
  }


  return (

    <Drawer placement="right"
      onClose={handlewClick}
      open={isVisible}
      onClick={() => { }
        //  console.log("yes = = ")
      }>
      <Sider
        // breakpoint="lg"
        className="sider_left"
        collapsedWidth="0"
        width="250"
      >
        <div className="logo">
          <Link to="/dashboard">
            <img src={images.logo} />
          </Link>
        </div>



        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={[activeKey]}
          selectedKeys={[activeKey]}
          onClick={(e) => onMenuClick(e)}>


          <Menu.Item key="/AdminDashboard">
            <Link to="">
              <img src={images.Activity} />
              <span className="line_dash">Dashboard</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/ownermanagement">
            <Link to="">
              <img src={images.ownermanagement} />
              <span className="line_dash">Owner Management</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/usermanagement">
            <Link to="">
              <img src={images.usermanagement} />
              <span className="line_dash">User Management</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/projectadmin">
            <Link to="">
              <img src={images.text12} />
              <span className="line_dash">Project Management</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/changepassword">
            <Link to="">
              <img src={images.Unlock} />
              <span className="line_dash">Change Password</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/login">
            <img src={images.Logout} />
            <span className="line_dash">Logout</span>
          </Menu.Item>

        </Menu>
      </Sider>
    </Drawer>

  )
}

MobileSidebar.propTypes = {
  handlewClick: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default MobileSidebar;