import React from "react";
// import { Tabs } from "antd";
import TabScreen from "./tabScreen/index";
// import { useDispatch } from "react-redux";
import { Container, CardHoc } from "../../../hoc";
import { NavigateTo, SidebarMenus } from "../../../themes";


import { useTranslation } from "react-i18next";
// const { TabPane } = Tabs;
const UserNotifications = () => {
  // const dispatch = useDispatch();
  /* Container props */
  const { t } = useTranslation();

  // const [lang, setLang] = useState(localStorage.getItem("lang"));

  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.USER_NOTIFICATIONS,
      label: t("invites"),
    },
  ];
  const contentChild = () => {
    return <div></div>;
  };
  const containerProps = {
    handleClick: () => { }, // callback for clicking sidebar menu
    children: contentChild, // String
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("user"),
    active: t("notifications"),
  };
  /* Container props */

  return (
    <Container
      {...containerProps}

      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <CardHoc cardPadding={"30px"}>
        <TabScreen user="worker" />
        {/* <Tabs defaultActiveKey="1">
          <TabPane tab={t('worker')}   key={"1"}>
            <TabScreen user='worker'  />
          </TabPane>
          <TabPane tab={t('validator')}   key={"2"}>
            <TabScreen user='validator' />
          </TabPane>
        </Tabs> */}
      </CardHoc>
    </Container>
  );
};

export default UserNotifications;
