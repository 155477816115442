import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { saveAnnotations } from "../../../../../../../redux/actions/Annotations";
import { getDocument } from "../../../../../../../redux/actions/Tasks";
import {
  stopLoading,
  startLoading,
} from "../../../../../../../redux/actions/Common";
import { isInternetConnected } from "../../../../../../../themes/utils/internetConnectionn";
import { Select, Radio, Modal, Input, Form, Switch, Tooltip, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { CheckOutlined } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { SettingOutlined, InfoCircleOutlined, SaveOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import PropTypes from "prop-types";

const QuestionAnnotate = ({ unAnnotated }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let limit = 10;
  const arabicRegex = /[\u0600-\u06FF]/;
  const [tableData, setTableData] = useState([]);
  const [tableDataTotal, setTableDataTotal] = useState(0);
  const [offset, setOffset] = useState(-1);
  const [counter, setCounter] = useState(0);
  const [counterDisplay, setCounterDisplay] = useState(0);
  const [pageNumber, setPageNumber] = useState(counter + 1);
  const [settingsClicked, setSettingsClicked] = useState(false);
  const [infoClicked, setInfoClicked] = useState(false);
  const [docLoading, setDocLoading] = useState(false);
  const [autoTextNextPage, setAutoTextNextPage] = useState(
    localStorage.getItem("autoTextNextPage") == "true" ? true : false
  );
  const [saveError, setSaveError] = useState(false);
  const [timeArray, setTimeArray] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [conInput, setConInput] = useState("");
  const [conAnswer, setConAnswer] = useState("");

  const onChangeRadio = (e, index, item) => {
    let arr = { ...tableData?.[counter]?.data };
    let arr2 = [...tableData?.[counter]?.questionss];
    if (item?.type == "yes-no" || item?.type == "checkbox" || item?.type == "radiolist") {
      arr[`input${index}`] = e;
      arr2[index].selected = e;
    } else {
      arr[`input${index}`] = e.target.value;
      arr2[index].selected = e.target.value;
    }

    // localStorage.setItem("annotateObject", JSON.stringify(arr));
    let dt = [...tableData];
    let selected_index = item?.choices?.findIndex((i) => i == e);
    dt[counter].data = arr;
    dt[counter].questionss = arr2;
    let cond = item?.conditional?.[selected_index];
    if (cond?.[0]?.use_paragraph && conInput == "") { // expand to multiple conditions
      let extra = dt[counter]?.annotations?.[0]?.annotation?.[selected_index]?.extra;
      setConInput(extra ? extra.conditional : dt[counter].paragraph);
      setConAnswer(arr2[index].selected ? arr2[index].selected : "");
    }
    setTableData(dt);

    let timeArr = { ...timeArray };
    timeArr[counter].clicks.push({
      question: item?.title,
      answer: arr[`input${index}`],
      time: performance.now()
    });
    // add idle time calcuated from last click to this click , if no clicks then start of page to this click
    let idleTime = 0;
    if (timeArr[counter].clicks.length > 1) {
      idleTime = timeArr[counter].clicks[timeArr[counter].clicks.length - 1].time - timeArr[counter].clicks[timeArr[counter].clicks.length - 2].time;
    } else {
      idleTime = timeArr[counter].clicks[timeArr[counter].clicks.length - 1].time - timeArr[counter].start;
    }
    timeArr[counter].idle.push(idleTime);

    setTimeArray(timeArr);

    saveAnnotationApiCall("change");
    if (autoTextNextPage && !saveError) {
      setCounter(counter + 1);
      let timeArr = { ...timeArray };
      if (!timeArr[counter + 1]) {
        timeArr[counter + 1] = {
          start: performance.now(),
          clicks: [],
          idle: [],
          end: 0
        };
        setTimeArray(timeArr);
      }
    }
  };

  const saveAnnotationApiCall = (source) => {

    setSaveError(false);
    // return false
    let data = tableData?.[counter];
    let annotations = [];
    // let row = tableData?.[counter];
    data.questionss.map((item, index) => {
      let ann = data?.data[item?.name];
      let curr_answer = conAnswer;
      let curr_cond = conInput;
      annotations.push({
        question: item?.title,
        answer: ann ? ann : "",
        extra: curr_answer == ann ? {
          "title": item?.conditional?.[index]?.[0]?.title,
          "conditional": curr_cond !== tableData?.[counter].paragraph ? curr_cond : "unmodified",
        } : ""
      });
    });
    // if any annotation doesnt have answer
    let allAnswers = true;
    annotations.map((item) => {
      if (!item.answer) {
        allAnswers = false;
      }
    });

    if (!allAnswers) {
      setSaveError(true);
      if (source != "change") {
        toast.error(t("ansr_all_qns"), {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
          toastId: "answerAll",
        });
      }
      return true;
    }
    else if (source != "change" || (source == "change" && autoTextNextPage)) {

      let timeArr = { ...timeArray };
      // if counter > length then it is last page use 0

      let cnt = counter >= Object.keys(timeArr).length ? Object.keys(timeArr).length - 1 : counter;
      if (timeArr && timeArr[cnt] && timeArr[cnt].end == 0)
        timeArr[cnt].end = performance.now();
      setTimeArray(timeArr);

      let annotation = {
        document_id: data?.id,
        annotation: {
          document: data?.id,
          annotation: annotations,
          metrics: timeArr[cnt]
        },
      };

      const payloadToSaveAnnotation = {
        body: {
          task_id: localStorage.getItem("workerPendingTaskId"),
          annotation: annotation,
        },
        success: () => {
          if (source == "save") {
            toast.success(t("ann_svd"), {
              className:
                localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
              position:
                localStorage.lang == "ar"
                  ? toast.POSITION.TOP_LEFT
                  : toast.POSITION.TOP_RIGHT,

              toastId: "annotSaved",
            });
          }
          setSaveError(false);
          // update annotations of current page
          let dt = [...tableData];
          dt[counter].annotations = [annotation.annotation];
          setTableData(dt);

        },
        fail: (e) => {
          toast.error(t(e), {
            className:
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
            position:
              localStorage.lang == "ar"
                ? toast.POSITION.TOP_LEFT
                : toast.POSITION.TOP_RIGHT,

            toastId: "annotSaved",
          });
          setSaveError(true);
        },
      };

      isInternetConnected() && dispatch(saveAnnotations(payloadToSaveAnnotation))

      return false;
    }
  };
  function containsArabic(str) {
    return /[\u0600-\u06FF]/.test(str);
  }
  function fontSize(str) {
    if (!str) return "32px";
    if (str.length < 50) {
      return "32px";
    } else if (str.length < 100) {
      return "30px";
    } else if (str.length < 150) {
      return "28px";
    } else if (str.length < 200) {
      return "26px";
    } else {
      return "24px";
    }
  }
  function fillTable(res, cnt = undefined) {
    isInternetConnected() && dispatch(stopLoading())
    let questionss = JSON.parse(localStorage.questionType);
    let dt = res?.documents?.map((i) => {
      i["data"] = {};
      i["questionss"] = questionss.map((item, index) => {
        let ann = i?.annotations?.[0]?.annotation?.[index]?.answer;
        if (ann) {
          i["data"][`input${index}`] = ann ? ann : "";

        }
        return {
          ...item,
          selected: ann ? ann : "",
        };
      });

      return i;
    });
    res?.documents?.filter((x) => {
      if (x?.annotation?.length > 0) {
        dt = x?.annotation[0].jsonFile;
      }
    });
    setTableData(dt);
    setTableDataTotal(res?.total);
    let cntDisplay = cnt > -1 ? cnt : counter;
    setCounterDisplay(res?.offset + cntDisplay);
    setOffset(res?.offset);

    let timeArr = { ...timeArray };
    timeArr[counter] == undefined ?
      timeArr[counter] = {
        start: performance.now(),
        clicks: [],
        idle: [],
        end: 0
      } : timeArr[counter].start = performance.now();

    setTimeArray(timeArr);
    let i = dt?.[counter];
    if (i?.annotations?.length > 0) {
      i?.annotations?.[0]?.annotation?.map((item) => {
        let ann = item?.answer;
        let extra = item?.extra;
        if (ann) {
          setConAnswer(ann ? ann : "");
        }
        if (extra) {
          setConInput(extra?.conditional !== "unmodified" ? extra?.conditional : "");
        }
      });
    } else {
      let cond = i?.questionss?.[0]?.conditional?.[0];
      if (cond?.[0]?.use_paragraph) {
        setConInput(i?.paragraph);
        setConAnswer(i?.questionss?.[0]?.selected ? i?.questionss?.[0]?.selected : "");
      } else {
        setConInput("");
        setConAnswer("");
      }
    }

  }

  function changePage(next) {
    let call = false
    let offs = offset;
    let cnt = counter;
    if (next && cnt == 9) {
      setCounter(0);
      cnt = 0;
      offs = offset + limit > tableDataTotal ? offset : offset + limit;
      call = true
    }
    else if (next) {
      setCounter(cnt + 1);
      setCounterDisplay(counterDisplay + 1);
      cnt = cnt + 1;
      let timeArr = { ...timeArray };
      if (!timeArr[counter + 1]) {
        timeArr[counter + 1] = {
          start: performance.now(),
          clicks: [],
          idle: [],
          end: 0
        };
        setTimeArray(timeArr);
      }
    }
    if (!next && cnt == 0) {
      if (offset - limit >= 0) {
        offs = offset - limit;
        setCounter(9);
        cnt = 9;
      } else {
        offs = offset - 1 >= 0 ? offset - 1 : 0;
        setCounter(0);
        cnt = 0;
      }
      call = true
    } else if (!next) {
      setCounter(cnt - 1);
      setCounterDisplay(counterDisplay - 1);
      cnt = cnt - 1;
      let timeArr = { ...timeArray };
      if (!timeArr[counter - 1]) {
        timeArr[counter - 1] = {
          start: performance.now(),
          clicks: [],
          idle: [],
          end: 0
        };
        setTimeArray(timeArr);
      }
    }
    if (call) {
      setDocLoading(true);
      let payload = {
        body: {
          "offset": offs,
          "limit": limit,
          "task_id": localStorage.getItem("workerPendingTaskId"),
        },
        success: (res) => {
          setDocLoading(false);
          fillTable(res, cnt)
        }
      }
      isInternetConnected() && dispatch(startLoading())
      isInternetConnected() && dispatch(getDocument(payload))
    }
    let i = tableData?.[cnt];
    if (i?.annotations?.length > 0) {
      i?.annotations?.[0]?.annotation?.map((item) => {
        let ann = item?.answer;
        let extra = item?.extra;
        if (ann) {
          setConAnswer(ann ? ann : "");
        }
        if (extra) {
          setConInput(extra?.conditional !== "unmodified" ? extra?.conditional : "");
        }
      });
    } else {
      let cond = i?.questionss?.[0]?.conditional?.[0];
      if (cond?.[0]?.use_paragraph) {
        setConInput(i?.paragraph);
        setConAnswer(i?.questionss?.[0]?.selected ? i?.questionss?.[0]?.selected : "");
      } else {
        setConInput("");
        setConAnswer("");
      }
    }
  }
  function stringifyObj(obj, parse_html = false) {
    let str = "";
    // if its a dict or a list of dicts join as a string as is including the keys and
    if (typeof obj == "object") {
      if (Array.isArray(obj)) {
        obj.map((item) => {
          str += stringifyObj(item);
        });
      } else {
        Object.keys(obj).map((item) => {
          str += item + " : " + stringifyObj(obj[item]) + " ";
        });
      }
    } else {
      if (!obj) {
        return "";
      }
      // if obj has \n replace with <br>
      if (obj.includes("\n")) {
        obj = obj.replace(/\n/g, "<br>");
      } else if (obj.includes("\r")) {
        obj = obj.replace(/\r/g, "<br>");
      }
      str += obj;
    }
    if (parse_html) {
      str = parse(str);
    }
    return str;
  }

  useEffect(() => {
    let payload = {
      body: {
        "offset": offset,
        "limit": limit,
        "task_id": localStorage.getItem("workerPendingTaskId"),
        "unAnnotated": unAnnotated
      },
      success: (res) => {
        fillTable(res)
      }
    }
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    isInternetConnected() && dispatch(startLoading())
    isInternetConnected() && dispatch(getDocument(payload))
    setCounter(0);
    window.scrollTo(0, 0);
  }, []);
  const conditionalInput = (title, type, required, value = undefined) => {
    // setConInput(value);
    switch (type) {
      case "textarea":
        return (
          <div>
            <label
              style={{
                fontSize: "13px",
                textAlign:
                  localStorage.getItem("lang") == "ar" ? "right" : "left",
                color: required == true ? "#eb4143" : "#2b5483",
                // padding: "10px 21px",
                fontWeight: "600",
              }}
            >
              {title} {required && "*"}
            </label>
            <Input.TextArea
              required={required}
              cols={100}
              rows={width > 768 ? 8 : 10}
              style={{
                fontSize: fontSize(value),
                minHeight: width > 768 ? "20vh" : "40vh",
              }}
              className={`form-control paragraphs_udt textarea-large ${arabicRegex.test(value) ? "ar" : ""}`}
              value={conInput}
              onChange={(e) => { setConInput(e.target.value) }}
            />
          </div>
        );
      case "text":
        return (
          <div>
            <label
              style={{
                fontWeight: "600",
                color: required == true ? "#eb4143" : "#2b5483",
                fontSize: "13px",
                textAlign:
                  localStorage.getItem("lang") == "ar" ? "right" : "left",
              }}
            >
              {title} {required && "*"}
            </label>
            <Input
              type={type}
              className="form-control"
              required={required}
              style={{
                width: "100%",
              }}
            />
          </div>
        );
    }
  }
  return (
    (tableData?.length > 0 && (
      <div className="udt_container">
        {/*page number and settings and info */}
        <div
          className="header-sticky"
          style={{
            justifyContent: localStorage.lang == "ar" ? "left" : "right",
            flexDirection: localStorage.lang == "ar" ? "row-reverse" : "row",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              border: "0px",
              display: "flex",
              cursor: "pointer",
              borderRadius: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
              background: "rgb(31 71 118)",
            }}
            onClick={() => {
              setInfoClicked(true);
            }}
          >
            <InfoCircleOutlined style={{ color: "#fff", fontSize: "20px" }} />
          </div>
          <div
            style={{
              width: "40px",
              height: "40px",
              border: "0px",
              display: "flex",
              cursor: "pointer",
              borderRadius: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
              background: "rgb(31 71 118)",
            }}
            onClick={() => {
              setSettingsClicked(true);
            }}
          >
            <SettingOutlined style={{ color: "#fff", fontSize: "20px" }} />
          </div>
          <div
            style={{
              width: "40px",
              height: "40px",
              border: "0px",
              display: "flex",
              cursor: "pointer",
              borderRadius: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "10px",
              background: "rgb(31 71 118)",
            }}
            onClick={() => {
              saveAnnotationApiCall("save");
            }}
          >
            <SaveOutlined style={{ color: "#fff", fontSize: "20px" }} />
          </div>
          <div
            style={{
              background: "rgb(31 71 118 / 16%)",
              borderRadius: "10px",
              padding: " 8px",
              fontSize: "14px",
            }}
          >
            {localStorage.lang == "ar" ?
              <>
                <span
                  style={{
                    fontWeight: "800",
                    marginTop: "-2px",
                  }}
                >
                  {tableDataTotal}
                </span> / {counterDisplay + 1}
              </> :
              <>
                <span
                  style={{
                    fontWeight: "800",
                    marginTop: "-2px",
                  }}
                >
                  {counterDisplay + 1}
                </span> / {tableDataTotal}
              </>}

          </div>
          <Tooltip title={t("prev")}>
            <button
              style={{
                marginLeft: "10px",
                width: "60px",
                height: "40px",
                border: "0px",
                display: "flex",
                cursor: "pointer",
                borderRadius: "10px",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
                cursor: counterDisplay > 0 ? "pointer" : "no-drop",
                background: counterDisplay > 0 ? "rgb(31 71 118)" : "#a6b3c1",
              }}
              title={t("prev")}
              onClick={() => changePage(false)}
              disabled={counterDisplay > 0 ? false : true}
            >
              <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#fff", fontSize: "20px" }} />
            </button>
          </Tooltip>
          <Tooltip title={t("next")}>
            <button
              style={{
                marginLeft: "1px",
                width: "60px",
                height: "40px",
                border: "0px",
                display: "flex",
                cursor: "pointer",
                borderRadius: "10px",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
                background:
                  counterDisplay < tableDataTotal - 1 ? "rgb(31 71 118)" : "#a6b3c1",
                cursor: counterDisplay < tableDataTotal - 1 ? "pointer" : "no-drop",
              }}
              disabled={counterDisplay < tableDataTotal - 1 ? false : true}
              title={t("next")}
              onClick={() => {
                let err = saveAnnotationApiCall("next");
                if (!err && counterDisplay < tableDataTotal - 1) {
                  changePage(true);
                }
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} style={{ color: "#fff", fontSize: "20px" }} />
            </button>
          </Tooltip>
          <Modal
            title={t("settings")}
            open={settingsClicked}
            footer={[]}
            onCancel={() => {
              setSettingsClicked(false);
            }}
          >
            <label
              htmlFor="page"
              style={{
                fontWeight: "500",
                color: "rgb(20, 60, 100)",
              }}
            >
              {t("select_page")}
            </label>
            <Form layout="inline">
              <Input
                placeholder={`${t("select_page")} (${counterDisplay + 1} / ${tableDataTotal})`}
                type="number"
                onChange={(e) => {
                  setPageNumber(e.target.value);
                }}
                name="page"
                min={1}
                max={tableDataTotal}
                size="large"
                style={{
                  width: "80%",
                }}
              //value={currentIndex + 1}
              />
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  border: "0px",
                  display: "flex",
                  cursor: "pointer",
                  borderRadius: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(31 71 118)",
                }}
                onClick={() => {
                  if (pageNumber > 0 && pageNumber <= tableDataTotal) {
                    let payload = {
                      body: {
                        "offset": pageNumber - 1,
                        "limit": limit,
                        "task_id": localStorage.getItem("workerPendingTaskId"),
                      },
                      success: (res) => {
                        fillTable(res)
                        setSettingsClicked(false);
                      }
                    }
                    isInternetConnected() && dispatch(startLoading())
                    isInternetConnected() && dispatch(getDocument(payload))

                  } else {
                    toast.error(t("page_not_found"), {
                      className:
                        localStorage.lang == "ar"
                          ? "Select_data ar"
                          : "Select_data en",
                      position:
                        localStorage.lang == "ar"
                          ? toast.POSITION.TOP_LEFT
                          : toast.POSITION.TOP_RIGHT,

                      toastId: "pageNotFound",
                    });
                  }
                }}
              >
                <CheckOutlined style={{ color: "#fff", fontSize: "20px" }} />
              </div>
              <div
                className="mt-2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <label
                  htmlFor="page"
                  style={{
                    fontWeight: "500",
                    color: "rgb(20, 60, 100)",
                  }}
                >
                  {t("auto_next_page")}
                </label>
                <Switch
                  checked={autoTextNextPage}
                  onChange={(e) => {
                    setAutoTextNextPage(e);
                    localStorage.setItem("autoTextNextPage", e);
                  }}
                />
              </div>
            </Form>
          </Modal>
          <Modal
            title={t("instructions")}
            open={infoClicked}
            footer={[]}
            onCancel={() => {
              setInfoClicked(false);
            }}
            width={1000}
          >
            {/*  instructions  */}
            <div className="form-group">
              <label
                htmlFor="end"
                style={{
                  fontWeight: "500",
                  color: "rgb(20, 60, 100)",
                  fontFamily: 'font-family: "Montserrat", sans-serif',
                }}
              >
                {t("instructions")}
              </label>
              <div
                className="get-original-text-color"
                style={{
                  marginTop: "12px",
                  padding: "20px",
                  minHeight: "500px",
                  fontSize: "calc(12px + 0.5vw)"
                }}
              >
                <div className="ck-content">
                  {parse(localStorage.getItem("instruction"))}
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {/*page number and settings and info end */}

        <div className="flex_udt mt-2">

          <div className="paragraphs_udt">
            <p
              style={{
                fontSize: fontSize(tableData?.[counter]?.paragraph),
                textAlign:
                  containsArabic(tableData?.[counter]?.paragraph) ? "right" : "left",
                direction: containsArabic(tableData?.[counter]?.paragraph) ? "rtl" : "ltr",
                color: "#2b5483",
                padding: "10px 21px",
                // fontWeight: "600",
                overflowWrap: "anywhere"
              }}
            >
              {
                docLoading ? t("loading") + "..." :
                  stringifyObj(tableData?.[counter]?.paragraph, true)
              }
            </p>
          </div>

          <div className="card">
            {tableData?.[counter]?.questionss?.map((i, j) => {
              return i?.type == "radio" ? (
                <div
                  style={{
                    fontSize: "13px",
                    textAlign:
                      localStorage.getItem("lang") == "ar" ? "right" : "left",
                    color: "#2b5483",
                    padding: "10px 21px",
                    fontWeight: "600",
                  }}
                >
                  <div className="title_udt">{i?.title}</div>
                  <div className="udt_heading">
                    <Radio.Group
                      size="large"
                      value={tableData?.[counter]?.data[`input${j}`]}
                      style={{
                        margin: "5px 0px",
                        width: "20%",
                      }}
                      onChange={(value) => onChangeRadio(value, j, i)}
                      className="udt_heading"
                    >
                      {i?.choices?.map((item, index) => {
                        return (
                          <Radio
                            key={index}
                            value={item}
                            style={{
                              color: "#214c7d",
                              fontSize: "14px",
                            }}
                          >
                            {item}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </div>
                </div>
              ) : i?.type == "radiolist" ? (
                <div
                  style={{
                    fontSize: "13px",
                    textAlign:
                      localStorage.getItem("lang") == "ar" ? "right" : "left",
                    color: "#2b5483",
                    padding: "10px 21px",
                    fontWeight: "600",
                  }}
                >
                  <div className="title_udt">{i?.title}</div>
                  <div className="udt_heading">
                    <Row
                      align={"middle"} type="flex"
                    >
                      {
                        i?.choices?.map((item, index) => {
                          return (
                            <>
                              <Col span={24}>
                                <button
                                  key={index}
                                  className="udt_btn"
                                  style={{
                                    marginLeft: "10px",
                                    padding: "10px",
                                    border: "0px",
                                    display: "flex",
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "10px",
                                    cursor: "pointer",
                                    color: "#fff",
                                    background: i?.selected == item ? "green" : "rgb(31 71 118)",
                                    fontSize: item.length < 3 ? "25px" : item.length < 5 ? "20px" : item.length < 8 ? "18px" : "15px",
                                  }}
                                  onClick={() => {
                                    onChangeRadio(item, j, i);
                                  }}
                                >
                                  {item}
                                </button>
                              </Col>
                              <Col span={24}>
                                <div style={{
                                  padding: "10px",
                                }}>
                                  {i?.selected == item &&
                                    i?.conditional?.[index]?.map((item) => {
                                      return conditionalInput(
                                        item?.title,
                                        item?.type,
                                        item?.required,
                                        docLoading ? t("loading") + "..." : stringifyObj(tableData?.[counter].paragraph)
                                      )
                                    })
                                  }
                                </div>
                              </Col>
                            </>
                          );
                        })
                      }
                    </Row>
                  </div>
                </div>
              ) : i?.type == "checkbox" ? (
                <div
                  style={{
                    fontSize: "13px",
                    textAlign:
                      localStorage.getItem("lang") == "ar" ? "right" : "left",
                    color: "#2b5483",
                    padding: "10px 21px",
                    fontWeight: "600",
                  }}
                  className="email_selecter"
                >
                  <div className="title_udt">{i?.title}</div>
                  <div className="udt_heading">
                    {
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Select"
                        className="udt_selector"
                        value={tableData?.[counter]?.data[`input${j}`]}
                        onChange={(value) => onChangeRadio(value, j, i)}
                      >
                        {i?.choices?.map((item, index) => {
                          return <Option key={index} value={item}>{item}</Option>;
                        })}
                      </Select>
                    }
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "13px",
                    textAlign:
                      localStorage.getItem("lang") == "ar" ? "right" : "left",
                    color: "#2b5483",
                    padding: "10px 21px",
                    fontWeight: "600",
                  }}
                >
                  <div className="title_udt">{i?.title}</div>
                  <div className="udt_heading">
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {
                        i?.choices?.map((item, index) => {
                          return (
                            <>
                              <button
                                key={index}
                                className="udt_btn"
                                style={{
                                  marginLeft: "10px",
                                  width: "60px",
                                  height: "40px",
                                  border: "0px",
                                  display: "flex",
                                  cursor: "pointer",
                                  borderRadius: "10px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  color: "#fff",
                                  background: i?.selected == item ? "green" : "rgb(31 71 118)",
                                  fontSize: item.length < 3 ? "18px" : item.length < 5 ? "14px" : item.length < 8 ? "12px" : "10px",
                                }}
                                onClick={() => {
                                  onChangeRadio(item, j, i);
                                }}
                              >
                                {item}
                              </button>
                              {i?.selected == item &&
                                i?.conditional?.[index]?.map((item) => {
                                  return conditionalInput(item?.title, item?.type, item?.required)
                                })
                              }
                            </>);
                        })
                      }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div >
    ))
  );
};
QuestionAnnotate.propTypes = {
  unAnnotated: PropTypes.bool.isRequired,
};
export default QuestionAnnotate;
