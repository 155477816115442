// Common---->
export const START_LOADING = "START_LOADING";
export const DISPUTE_LIST_START_LOADING = "DISPUTE_LIST_START_LOADING";
export const DISPUTE_LIST_STOP_LOADING = "DISPUTE_LIST_STOP_LOADING";
export const SEARCH_VALUE = "SEARCH_VALUE";
export const STOP_LOADING = "STOP_LOADING";
export const GET_LANGUAGE_LIST = "GET_LANGUAGE_LIST";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_COUNTRY = "GET_COUNTRY";
export const DISABLE_PROFILE_COMPLETION = "DISABLE_PROFILE_COMPLETION";
export const WORKERS_VALIDATORS_LIST = "WORKERS_VALIDATORS_LIST";
export const SAVE_WORKER_PENDING_TASKS = "SAVE_WORKER_PENDING_TASKS";
export const SAVE_WORKER_COMPLETED_TASKS = "SAVE_WORKER_COMPLETED_TASKS";
export const SAVE_VALIDATOR_COMPLETED_TASKS = "SAVE_VALIDATOR_COMPLETED_TASKS";
export const SEARCH_DATE = "SEARCH_DATE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const PASS_SUCESS = "PASS_SUCESS";
export const LOGOUT = "LOGOUT";
export const LANGUAGE_CHANGED = "LANGUAGE_CHANGED";
export const CONTACT_US = "CONTACT_US";
export const LOG = "LOG";

// otp verification
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const VERIFY_REGISTERED_EMAIL = "VERIFY_REGISTERED_EMAIL"; // VERIFIY TOKEN
export const VERIFY_FORGOT_TOKEN = "VERIFY_FORGOT_TOKEN"; // VERIFIY TOKEN

// LOGIN --->
export const USER_LOGIN_LOAD = "USER_LOGIN_LOAD";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";

//forget password
export const USER_FORGET_LOAD = "USER_FORGET_LOAD";
export const USER_FORGET_SUCCESS = "USER_FORGET_SUCCESS";
export const USER_FORGET_ERROR = "USER_FORGET_ERROR";

// change password
export const USER_CHANGE_PASSWORD_LOAD = "USER_CHANGE_PASSWORD_LOAD";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_ERROR = "USER_CHANGE_PASSWORD_ERROR ";

// dashboard ---->

export const USER_DASHBOARD_LOAD = "USER_DASHBOARD_LOAD";
export const USER_DASHBOARD_SUCCESS = "USER_DASHBOARD_SUCCESS";
export const USER_DASHBOARD_ERROR = "USER_DASHBOARD_ERROR";

//User management table --->

export const USER_TABLE_LOAD = "USER_TABLE_LOAD";
export const USER_TABLE_SUCCESS = "USER_TABLE_SUCCESS";
export const USER_TABLE_ERROR = "USER_TABLE_ERROR";

//View User Data ------>

export const USER_VIEW_LOAD = "USER_VIEW_LOAD";
export const USER_VIEW_SUCCESS = "USER_VIEW_SUCCESS";
export const USER_VIEW_ERROR = "USER_VIEW_ERROR";

//Edit User Data --->
export const USER_EDIT_LOAD = "USER_EDIT_LOAD";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_ERROR = "USER_EDIT_ERROR";

//Enable disable User ---------->
export const USER_ENABLE_DISABLE_LOAD = "USER_ENABLE_DISABLE_LOAD";
export const USER_ENABLE_DISABLE_SUCCESS = "USER_ENABLE_DISABLE_SUCCESS";
export const USER_ENABLE_DISABLE_ERROR = "USER_ENABLE_DISABLE_ERROR";

// update User Details ---->

export const USER_UPDATE_LOAD = "USER_UPDATE_LOAD";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";

// reset password --->
export const USER_RESET_PASSWORD_LOAD = "USER_RESET_PASSWORD_LOAD";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_ERROR = "USER_RESET_PASSWORD_ERROR";

// set password ---->

export const USER_SET_PASSWORD_LOAD = "USER_SET_PASSWORD_LOAD";
export const USER_SET_PASSWORD_SUCCESS = "USER_SET_PASSWORD_SUCCESS";
export const USER_SET_PASSWORD_ERROR = "USER_SET_PASSWORD_ERROR";

// update User Register ---->

export const USER_REGISTER_LOAD = "USER_REGISTER_LOAD";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

// user profile -------->

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const PROFILE_COMPLETION = "PROFILE_COMPLETION";

// Dashboard ------->
export const GET_USER_DASHBOARD = "GET_USER_DASHBOARD";
export const GET_OWNER_DASHBOARD = "GET_OWNER_DASHBOARD";
export const GET_ADMIN_DASHBOARD = "GET_ADMIN_DASHBOARD";
export const GET_OWNER_LIST_ADMIN = "GET_OWNER_LIST_ADMIN";
export const GET_USER_LIST_ADMIN = "GET_USER_LIST_ADMIN";
export const IMERSONATE_USER = "IMERSONATE_USER";
export const GET_PROJ_CREATED_ADMIN = "GET_PROJ_CREATED_ADMIN";
export const GET_PROJ_COMPLETED_ADMIN = "GET_PROJ_COMPLETED_ADMIN";
export const GET_IMAGES_ADMIN = "GET_IMAGES_ADMIN";
export const GET_ANNOTATION_ADMIN = "GET_ANNOTATION_ADMIN";

// Notification ------>
export const GET_USER_NOTIFICATION = "GET_USER_NOTIFICATION";
export const GET_WORKER_NOTIFICATION = "GET_WORKER_NOTIFICATION"; // below 2 are optional  in case the api comes individually
export const GET_VALIDATOR_NOTIFICATION = "GET_VALIDATOR_NOTIFICATION"; // below 2 are optional  in case the api comes individually
export const ACCEPT_REJECT_NOTIFICATION = "ACCEPT_REJECT_NOTIFICATION"; // below 2 are optional  in case the api comes individually

// Project ------>
// Owner
export const POST_NEW_PROJECT = "POST_NEW_PROJECT";
export const ADD_NEW_TASK_TO_PROJ = "ADD_NEW_TASK_TO_PROJ";
export const OWNER_DASH_PROJ_LISTS = "OWNER_DASH_PROJ_LISTS";
export const OWNER_DASH_TASK_LISTS = "OWNER_DASH_TASK_LISTS";

// ADMIN
export const GET_ADMIN_PROJ_LISTS = "GET_ADMIN_PROJ_LISTS";
export const SET_ADMIN_PROJ_LISTS = "SET_ADMIN_PROJ_LISTS";

export const GET_ONGOING_PROJ_LISTS = "GET_ONGOING_PROJ_LISTS";
export const SAVE_PROJ_DETAILS = "SAVE_PROJ_DETAILS";
export const GET_PROJ_DETAILS = "GET_PROJ_DETAILS";
export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";

export const GET_PAST_PROJ_LISTS = "GET_PAST_PROJ_LISTS";

// Task ------>
// Owner
export const SAVE_TASK_LIST = "SAVE_TASK_LIST"; // below 2 are optional  in case the api comes individually
export const SAVE_TASK_LIST_FOR_API = "SAVE_TASK_LIST_FOR_API"; // below 2 are optional  in case the api comes individually
export const SAVE_TASK_LIST_FOR_TABLE = "SAVE_TASK_LIST_FOR_TABLE"; // below 2 are optional  in case the api comes individually
export const CREATE_TASK = "CREATE_TASK";
export const INVITE_TO_TASK = "INVITE_TO_TASK";
export const GET_INVITED_USERS = "GET_INVITED_USERS";
export const GET_ADDED_TASK_LIST = "GET_ADDED_TASK_LIST";
export const DELETE_ADDED_TASK = "DELETE_ADDED_TASK";
export const GET_TASK_DETAILS = "GET_TASK_DETAILS";
export const GET_TASK_PREVIEW = "GET_TASK_PREVIEW";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const GET_DOCUMENT_DETAILS = "GET_DOCUMENT_DETAILS";
export const EXPORT_TASK_LISTING = "EXPORT_TASK_LISTING";
export const SAVE_IMAGE_ARRAY = "SAVE_IMAGE_ARRAY";
export const SAVE_CLASS_NAMES = "SAVE_CLASS_NAMES";
export const SAVE_ITR = "SAVE_ITR";
export const SET_IMAGE_LOADING = "SET_IMAGE_LOADING";

// Owner Dashborad/Projects/Ongoing Project Details/Task Details   start
export const GET_VALIDATORS_LIST_OWNER_ONGOING_PROJ =
  "GET_VALIDATORS_LIST_OWNER_ONGOING_PROJ";
export const GET_WORKERS_LIST_OWNER_ONGOING_PROJ =
  "GET_WORKERS_LIST_OWNER_ONGOING_PROJ";
export const GET_USER_INVITATION_OWNER_ONGOING_PROJ =
  "GET_USER_INVITATION_OWNER_ONGOING_PROJ"; // GET_USER_INVITATION_OWNER_ONGOING_PROJ
export const REMOVE_USER_FROM_OWNER_ONGOING_PROJ_TASK =
  "REMOVE_USER_FROM_OWNER_ONGOING_PROJ_TASK";
export const UPDATE_USER_QUOTA_FROM_OWNER_ONGOING_PROJ_TASK =
  "UPDATE_USER_QUOTA_FROM_OWNER_ONGOING_PROJ_TASK";
export const REPLACE_USER_FROM_OWNER_ONGOING_PROJ_TASK =
  "REPLACE_USER_FROM_OWNER_ONGOING_PROJ_TASK";
export const REMOVE_USER_INVITE_FROM_OWNER_ONGOING_PROJ_TASK =
  "REMOVE_USER_INVITE_FROM_OWNER_ONGOING_PROJ_TASK";
export const ADD_VALIDATOR_FOR_TASK = "ADD_VALIDATOR_FOR_TASK";
export const ADD_WORKER_FOR_TASK = "ADD_WORKER_FOR_TASK";
// Owner Dashborad/Projects/Ongoing Project Details/Task Details   start

// Owner Dashborad/Projects/Ongoing Project Details  start
export const GET_TASK_LISTS = "GET_TASK_LISTS";
// Owner Dashborad/Projects/Ongoing Project Details  end

//user
export const GET_WORKER_PENDING_TASKS = "GET_WORKER_PENDING_TASKS";
export const GET_WORKER_COMPLETED_TASKS = "GET_WORKER_COMPLETED_TASKS";
export const GET_VALIDATOR_PENDING_TASKS = "GET_VALIDATOR_PENDING_TASKS";
export const GET_VALIDATOR_COMPLETED_TASKS = "GET_VALIDATOR_COMPLETED_TASKS";
export const SAVE_VALIDATOR_PENDING_TASKS = "SAVE_VALIDATOR_PENDING_TASKS";

// Group Management
// Owner
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const SHOULD_MEMBER_REMOVED = "SHOULD_MEMBER_REMOVED";
export const CREATE_GROUP = "CREATE_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const GROUP_INVITATIONS = "GROUP_INVITATIONS";

// skills
export const GET_SKILL_CATEGORIES = "GET_SKILL_CATEGORIES";
export const GET_DEVICE_LIST = "GET_DEVICE_LIST";

// TASKS
export const GET_TASK_TYPES = "GET_TASK_TYPES";
export const SAVE_TASKID = "SAVE_TASKID";

// annotations
export const SAVE_ANNOTATIONS_USER_PENDING = "SAVE_ANNOTATIONS_USER_PENDING";
export const SAVE_VALIDATIONS_USER_PENDING = "SAVE_VALIDATIONS_USER_PENDING";
export const ACCEPT_REJECT_ANNOTATIONS = "ACCEPT_REJECT_ANNOTATIONS"; // VALIDATOR PENDING TASK DETAILS
export const GET_ANNOTATIONS_LISTS = "GET_ANNOTATIONS_LISTS";

// RATE
export const RATE_WORKER_BY_VALIDATOR = "RATE_WORKER_BY_VALIDATOR"; //  VALIDATOR COMPLETED TASK DETAILS

// REPORT
export const REPORT_WORKER_BY_VALIDATOR = "REPORT_WORKER_BY_VALIDATOR"; //  VALIDATOR PENDING TASK DETAILS

// DISPUTE ANNOTS
export const RAISE_DISPUTE = "RAISE_DISPUTE";
export const DISPUTE_LISTING = "DISPUTE_LISTING";
export const DISPUTE_DETAILS = "DISPUTE_DETAILS";
export const ACCEPT_REJECT_DISPUTED_ANNOTATIONS =
  "ACCEPT_REJECT_DISPUTED_ANNOTATIONS";

// ADMIN
// OWNER MANAGEMENT
export const CREATE_OWNER = "CREATE_OWNER";
export const UPDATE_OWNER = "UPDATE_OWNER";
export const OWNER_SUCCESS = "OWNER_SUCCESS";
export const SET_OWNER_LIST = "SET_OWNER_LIST";
export const DELETE_OWNER = "DELETE_OWNER";
export const SET_OWNER_DETAILS = "SET_OWNER_DETAILS";
export const GET_OWNER_DETAILS = "GET_OWNER_DETAILS";
export const BLOCK_UNBLOCK_OWNER = "BLOCK_UNBLOCK_OWNER";

// USER MANAGEMENT
export const SET_USER_LIST = "SET_USER_LIST";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const USER_DELETE = "USER_DELETE";

// PROJ MANAGEMENT
export const DELETE_PROJECT_ADMIN = "DELETE_PROJECT_ADMIN";
export const DELETE_TASK_ADMIN = "DELETE_TASK_ADMIN";

// profile
// user
export const UPDATE_PROFILE = "UPDATE_PROFILE";

//export data
export const EXPORT_DATA = "EXPORT_DATA";
