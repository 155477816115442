import React, { useEffect, useState } from "react";
import { CardAdmin, CardHoc, ContainerAdmin } from "../../../../hoc";
import { Collapse, Rate, Table } from "antd";
import { CustomPageHeader, TextField } from "../../../../molecules/Admin";
import { CustomTable } from "../../../../molecules";
import {
  AppImage,
  NavigateTo,
  SidebarMenus,
} from "../../../../themes";
import { useTranslation } from "react-i18next";
import { CustomEmptyDiv } from "../../../../molecules";
import { getTaskDetails, saveTaskId } from "../../../../redux/actions/Tasks";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn";
import { useDispatch } from "react-redux";
import appconstant from "../../../../themes/appconstant";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { AnnotationModal } from "../../../OwnerModule/projectmanagement/ongoingProjectTaskDetailsModals";

const { Panel } = Collapse;

const leftFontstyle = {
  display: "inline",
  color: "#143C64",
  fontWeight: 500,
};
const rightFontStyle = {  
    marginRight: 10,
  display: "inline",
  marginLeft: 10,
  color: "#316293",
  fontWeight: 500,
  wordBreak: "break-all",
};
const TaskDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {taskId} = useParams();  //localStorage.getItem("projectTaskId");

  const [taskInfo, setTaskInfo] = useState({
    taskName: "",
    taskType: "",
    textType: "",
    description: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [dataSourceValidator, setDataSourceValidator] = useState([]); // validators
  const [dataSourceWorker, setDataSourceWorker] = useState([]); // workers table

  const [taskStatus, setTaskStatus] = useState("completed"); //location.state?.status

  // audio states start
  
  const [audioLists, setAudioLists] = useState([]);

  const [annotationAudioDS, setAnnotationAudioDS] = useState([]);
  // audio states end

  // text states start
  const [textLists, setTextLists] = useState([]);
  const [annotationTextDS, setAnnotationTextDS] = useState([]);
  // text states end
  const [questions, setQuestions] = useState(null);
  const [classNames, setClassNames] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [imageIndex, setImageIndex] = useState("");
  
  const [annotDetails, setAnnotDetails] = useState([]);
  const [annotationDS, setAnnotationDS] = useState([]); // annotationImageDS
  const [classLists, setClassLists] = useState([]);
  

  const breadCrumbItems = [
    {
      link: NavigateTo.ADMIN_DASH_BOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.ADMIN_PROJECT_MANAGEMENT,
      label: t("prj_mngmnt"),
    },
    {
      link: NavigateTo.ADMIN_PORJ_DETAILS,
      label: t("prj_details"),
    },
    {
      link: "",
      label: t("task_details"),
    },
  ];

  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("admin"),
    active: t('prj_mngmnt'),
  };
  const tn = [
    {
      labelTitle: `${t("tsk_name")}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo?.taskName,
      customLabelStyle: rightFontStyle,
    },
  ];
  const tt = [
    {
      labelTitle: `${t("tsk_typ")}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: t(taskInfo?.taskTypeToShow),
      customLabelStyle: rightFontStyle,
    },
  ];
  const td = [
    {
      labelTitle: `${t("tsk_desc")}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo?.description,
      customLabelStyle: rightFontStyle,
    },
  ];
  const taskdetailsLabels = [tn, tt, td];

  const columnsValidators = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => index + 1,
    },
    {
      title: t("profile_img"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (value) => {
        return (
          <img
            width={45}
            height={45}
            style={{ borderRadius: 10 }}
            src={value ? value : AppImage.defaultImage}
          />
        );
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 300,
      // sorter: true,
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      // sorter: true,
      width: 280,
    },
    {
      title: t("contact_num"),
      dataIndex: "phNumber",
      key: "phNumber",
      // sorter: true,
      width: 280,
    },
    {
      title: t("rating"),
      dataIndex: "rating",
      key: "rating",
      width: "300px",
      render: (value) => {
        return (
          <Rate
            style={{
              fontSize: "20px",
              position: "absolute",
              top: "24px",
              right: "6px",
              maxWidth: "fit-content",
            }}
            className={ localStorage.lang == 'ar' ? 'edit-group-star ar' : "edit-group-star en" }
            allowHalf
            value={value}
            disabled={true}
          />
        );
      },
    },
  ];

  const columnsWorkers = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => index + 1,
    },
    {
      title: t("profile_img"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (value) => {
        return (
          <img
            width={45}
            height={45}
            style={{ borderRadius: 10 }}
            src={value ? value : AppImage.defaultImage}
          />
        );
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 300,
      // sorter: true,
    },
    {
      title: t("contact_num"),
      dataIndex: "phNumber",
      key: "phNumber",
      // sorter: true,
      width: 280,
    },
  ];
  const AnnotationModalProps = {
    title: t("annot_list"),
    handleOk: () => {
      setIsModalVisible(false);
    },
    onSubmit: () => {
      setIsModalVisible(false);
    },
    modalWidth: 923,
    handleCancel: () => {
      setIsModalVisible(false);
    },
    isModalVisible: isModalVisible,
  };
  const udtBreadCrumbsAdminEn  = [
    {
      link: NavigateTo.ADMIN_DASH_BOARD,
      label:  "Dashboard",
    }, 
    {
      link: NavigateTo.ADMIN_PROJECT_MANAGEMENT,
      label: "Project Management",
    },
    {
      link: NavigateTo.ADMIN_PORJ_DETAILS,
      label:  "Project Details",
    },
    {
      link: NavigateTo.ADMIN_PROJ_TASK_DETAILS+`/${taskId}`,  
      label: "Task Details",
    },  
    {
      link: "",
      label:"Annotate",
    },
  ];
  const udtBreadCrumbsAdminAr  = [
    {
      link: NavigateTo.ADMIN_DASH_BOARD,
      label:  "لوحة القيادة",
    }, 
    {
      link: NavigateTo.ADMIN_PROJECT_MANAGEMENT,
      label: "ادارة مشروع",
    },
    {
      link: NavigateTo.ADMIN_PORJ_DETAILS,
      label:  "تفاصيل المشروع",
    },
    {
      link: NavigateTo.ADMIN_PROJ_TASK_DETAILS+`/${taskId}`,  
      label: "تفاصيل المهمة",
    },  
    {
      link: "",
      label: "وسم",
    },
  ]; 
  const udtContainerPropsAdmin = {
    breadCrumbItems: {
      en: udtBreadCrumbsAdminEn,
      ar: udtBreadCrumbsAdminAr
    },
    menuProps: SidebarMenus("admin"),
    active:t("prj_mngmnt"),
  };
  const classListHeaders = [
    {
      label: "",
      headerWidth: "150px",
    },
    {
      label: t("clss_name"),
      headerWidth: "150px",
    },
    {
      label: t("annotations"),
      headerWidth: "210px",
    },
    {
      label: t("action"),
      paddingLeft: "70px",
      headerWidth: "80px",
    },
  ];
  const audio_and_tex_list_headers = [
    {
      label: t("file_img"),
      headerWidth: "150px",
    },
    {
      label: t("file_name"),
      headerWidth: taskInfo?.taskType == "Audio" ? "150px" : "200px",
    },
    {
      label: t("annotations"),
      headerWidth: "250px",
    }, 
    {
      label: t("action"),
      paddingLeft: taskInfo?.taskType == "Audio" ? 0 : 0,
      paddingRight:
        taskInfo?.taskType == "Audio" || taskInfo?.taskType == "Text"
          ? taskStatus == "Completed"
            ? "50px"
            : null
          : 0,
      headerWidth:
        taskInfo?.taskType == "Text" &&
        localStorage.getItem("textType") == "questions"
          ? "100px"
          : taskInfo?.taskType == "Audio"
          ? taskStatus == "Completed"
            ? "100px"
            : "140px"
          : taskStatus == "Completed"
          ? "100px"
          : "337px",
      textAlign: "center",
    },
  ];
  const dropDownGroup = [
    {
      table: {
        placeholder: t("cls_list"),
        dropDownName: "Class",
        tableHeaders: classListHeaders,
        tableContents: classLists, //state,
        listLength: classLists.length,
        ModalProps: AnnotationModalProps,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data user-task-management-worker-pending-task-details-class-list-ar"
            : "text_data user-task-management-worker-pending-task-details-class-list-en",
      },
    }, // class lists
    {
      table: {
        placeholder:
          taskInfo?.taskType == "Audio" ? t('audio_list') : t('txt_list'),
        tableHeaders: 
          audio_and_tex_list_headers,
        tableContents: 
          taskInfo?.taskType == "Audio" ? audioLists : textLists,
        tdClassName:
          taskInfo?.taskType == "Audio" ||
          (taskInfo?.taskType == "Text" &&
            localStorage.getItem("textType") == "segmentation")
            ? "workerpendingaudioannot"
            : "workerpendingqnannot ",
        tdImageWidth: taskInfo?.taskType == "Text" ? "unset" : null,
        tdImageHeight: taskInfo?.taskType == "Text" ? "unset" : null,
        tdImageBorderRadius: taskInfo?.taskType == "Text" ? "unset" : null,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data admin-task-deatils-class-list-ar"
            : "text_data admin-task-deatils-class-list-en",
      },
    }, // audio Lists
  ];

  const payload = {
    body: {
      taskId,
      type: "owner",
    },
    success: (res) => {
      setTaskInfo({
        taskName: res?.data?.name,
        taskType:
          res?.data?.task_type == 1
            ? "Image"
            : res?.data?.task_type == 2
            ? "Text"
            : "Audio",
        taskTypeToShow:
          res?.data?.task_type == 1
            ? "image"
            : res?.data?.task_type == 2
            ? "text"
            : "audio",
        instruction: res?.data?.instruction,
        description: res?.data?.description,
        taskId: res?.data?.id,
      });
      let tempValList = [];
      res?.data?.validators.map((item) => {
        tempValList.push({
          image: item?.user_id?.image
            ? appconstant.imageBaseUrl + item?.user_id?.image
            : AppImage.defaultImage,
          name: item?.user_id?.first_name + " " + item?.user_id?.last_name,
          email: item?.user_id?.email,
          phNumber: item?.user_id?.phone_no,
          rating: item?.user_id?.average_rating,
        });
      });
      console.log(tempValList,'tempValList');
      setDataSourceValidator(tempValList);

      let tempWorkerList = [];
      res?.data?.workers.map((item) => {
        tempWorkerList.push({
          image: item?.user_id?.image
            ? appconstant.imageBaseUrl + item?.user_id?.image
            : AppImage.defaultImage,
          name: item?.user_id?.first_name + " " + item?.user_id?.last_name,
          phNumber: item?.user_id?.phone_no,
        });
      });
      setDataSourceWorker(tempWorkerList);

      localStorage.setItem("instruction", res?.data?.instruction);
       let statusTemp =
        res?.data?.status == 0
          ? "pending"
          : res?.data?.status == 1
          ? "pending"
          : res?.data?.status == 2
          ? "completed"
          : "pending";
      setTaskStatus(statusTemp);
      localStorage.setItem("annotType", res?.data?.ann_type);
      if (res?.data?.task_type == 1) {
        localStorage.setItem("taskType", "Image");

        let classNames = [];
        res?.data?.classes.map((item) => {
          classNames.push(item?.name);
        });

        setClassNames(classNames);

        let tempClassLists = [];
        let tempImageArray = [];
        let tempApiJsonImage = [];
        res?.data?.media.map((item, index) => {
          let imageDetails = {};
          imageDetails["annotationId"] = item?.id;
          imageDetails["jsonFile"] = item?.annotation; //jsonFile?.regions;
          imageDetails["annotStatus"] = item?.status;
          imageDetails["vComments"] = item?.comment;
          // imageDetails["mediaId"] = item?.media_id?.id;
          console.log(item?.annotation?.length,'item?.annotation?.length');
          tempClassLists.push({
            0: { image: appconstant.imageBaseUrl + item?.path },
            1: classNames.join(","),
            2: item?.annotation ? item?.annotation?.length : 0,
            3: {
              button:  "View Annotations" ,
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: imageDetails, // send anything like image id item?.id or annot details
            },
          }); // tempClassLists
          tempImageArray.push({
            name: `Image-${index + 1}`,
            src: appconstant.imageBaseUrl + item?.path,
            regions:
              item?.annotation.length != 0
                ? item?.annotation[item?.annotation.length - 1].jsonFile
                    ?.regions
                : [],
            id: item?.id,
          }); // tempImageArray
          tempApiJsonImage.push({
            // jsonFile: item?.jsonFile != null ? item?.jsonFile : [], // jsonFile
            task_id: item?.task_id,
            anotation_id: item?.id,
            worker_id: item?.worker_id, //item?.worker_id?.id,
            media_id: item?.id,
          }); // tempApiJsonImage
        });
        setClassLists(tempClassLists);
        setImageArray(tempImageArray);
      } // image

      if (res?.data?.task_type == 2) {
        localStorage.setItem("taskType", "Text");
        if (res?.data?.text_type == "segmentation") {
          localStorage.setItem("textType", "segmentation");

          let tempTextLists = [];
          let tempClassLists = [];
          res?.data?.media.map((item) => {
            let textFileName = item?.path; //.substring(0, 15) + "..."; //.split("/");
            let textDetails = {
              jsonFileForStartTask:
                item?.annotation[item?.annotation.length - 1]?.Anotation_id
                  ?.jsonFile,
              jsonFile: item?.annotation,
              paragraph: item?.path,
              totalTasks: res?.data?.media?.length,

              task_id: item?.task_id,
              anotation_id: item?.id,
              worker_id: item?.worker_id,
              media_id: item?.id,
            };
            tempTextLists.push({
              0: { image: AppImage.file_n },
              1: textFileName, //textFileName[textFileName.length - 1],
              2: item?.annotation ? item?.annotation?.length : 0,
              3: {
                button: "View Annotations" ,
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: textDetails, // send anything like image id item?.id or annot details
              },
            }); // tempTextLists
          });
          setTextLists(tempTextLists);

          res?.data?.classes.map((item) => {
            tempClassLists.push({
              id: item?.id,
              displayName: item?.name,
              task_id: item?.task_id,
            });
          });
          setClassLists(tempClassLists);
        }
        if (res?.data?.text_type == "questions") {
          localStorage.setItem("textType", "questions");

          localStorage.setItem("textType", "questions");
          let tempTextLists = [];
          const tempQuestions = [];

          JSON.parse(res?.data?.question?.questionsData)?.map((item, index) => {
            tempQuestions.push({
              name: `input${index}`,
              type:
                item?.answerType == "Check-Box"
                  ? "checkbox"
                  : "radio",
              title: item?.question,
              choices: item?.answers,
            });
          });

          res?.data?.media.map((item) => {
            let textFileName = item?.path.split("/");
            let textDetails = {
              jsonFileForStartTask:
                item?.annotation[item?.annotation.length - 1]?.jsonFile,
              jsonFile: item?.annotation,
              pdfUrl: appconstant?.pdfBaseUrl + item?.path,
              task_id: item?.task_id,
              anotation_id: item?.id,
              worker_id: item?.worker_id,
              media_id: item?.id,
              totalTasks: res?.data?.media?.length,
            };
            tempTextLists.push({
              0: { image: AppImage.file_n },
              1: textFileName[textFileName.length - 1],
              2: item?.annotation ? item?.annotation?.length : 0,
              3: {
                button: "View Annotations" ,
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: textDetails, // send anything like image id item?.id or annot details
              },
            }); // tempTextLists
          });
          // setPdfUrl(tempPdfUrls);//res?.data?.pdfUrls
          setQuestions(tempQuestions);
          setTextLists(tempTextLists);
        }
      } // text

      // audio
      if (res?.data?.task_type == 3) {
        localStorage.setItem("taskType", "Audio");

        let tempAudioArray = [];
        let tempAudioLists = [];

        res?.data?.media.map((item) => {
          let audioDetails = {};

          let audioFileName = item?.path.split("/");
          audioDetails["jsonFile"] = item.annotation;
          audioDetails["audioUrl"] = appconstant.imageBaseUrl + item?.path;

          audioDetails["annotStatus"] = item?.status;
          audioDetails["vComments"] = item?.comment;
          audioDetails["annotationId"] = item?.id;
          tempAudioLists.push({
            0: { image: AppImage.volumeFileImg },
            1: audioFileName[audioFileName.length - 1],
            2: item?.annotation ? item?.annotation?.length : 0,
            3: {
              button:"View Annotations" ,
              tdClassName: "workerpendingaudioannot",

              tdCustomstyle: {
                paddingLeft: 0,
                paddingRight: 0,
              },
              link: "",
              id: audioDetails, // send anything like image id item?.id or annot details
            },
          }); // tempAudioLists

          tempAudioArray.push({
            url: appconstant.imageBaseUrl + item?.path,
            jsonFile:
              item?.annotation != null
                ? item?.annotation[item?.annotation.length - 1]?.jsonFile
                : [], // jsonFile
            task_id: item?.task_id,
            anotation_id: item?.id,
            worker_id: item?.worker_id,
            media_id: item?.id,
          });
        });
        setAudioLists(tempAudioLists);
      } // audio
    },
    fail: (resf) => { 
      resf?.data &&
        toast.error(resf?.data, {
          className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      resf?.message &&
        toast.error(resf?.message, {
          className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
    },
  }; //  task details info payload

  useEffect(() => {
    window.scrollTo(0,0);
    dispatch(saveTaskId(taskId)); 
    isInternetConnected() && dispatch(getTaskDetails(payload)); // task deatils
  }, []); 
  

  return (
    <ContainerAdmin
      {...containerProps}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <CardAdmin cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        <CustomPageHeader
          label={t("task_details")}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px", 
          }}
        >
          <CustomPageHeader
            label={`${t("status")}: ${t(taskStatus)}`}
            wrapperClassName={
              "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
            }
            customWrapperStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginLeft: 10, marginTop: -5 }}>
              <img
                src={
                  taskStatus == "pending"
                    ? AppImage.pendingTask
                    : AppImage.completedTask
                }
              />
            </div>
          </CustomPageHeader>
        </CustomPageHeader>
        <div className="wrapper_line">
          {/* pageDescription */}
          {taskdetailsLabels.map((item) => {
            return (
              <TextField wrapperClassName={"admin-projdetails"} labels={item} key={item} />
            );
          })}
          {/* pageDescription */}

          <Collapse
            defaultActiveKey={["1"]}
            onChange={() => {
              const isDropdownOpened = document
                .querySelector(".ant-collapse-header")
                .getAttribute("aria-expanded");
              const dropdownArrowsOnClickStyle = document.querySelectorAll(
                ".ant-collapse-arrow svg"
              );
              if (isDropdownOpened == "true") {
                // .setAttribute('style','transform: rotate(-90deg);');
              } else {
                dropdownArrowsOnClickStyle[0].setAttribute(
                  "style",
                  "transform: rotate(-90deg) !important;"
                );
                // dropdownArrowsOnClickStyle[i].setAttribute('style','transform: rotate(90deg);');
              }
            }}
            className={localStorage.lang == 'ar' ? 'admin-taskDetails ar' :'admin-taskDetails en'}
          >
            <Panel
              header={
                taskInfo.taskType == "Image" ||
                taskInfo.taskType == "Segmentation"
                  ? dropDownGroup[0].table.placeholder
                  : dropDownGroup[1].table.placeholder
              }
              key="1"
            >
              {classLists.length > 0 ||
              audioLists.length > 0 ||
              textLists.length > 0 ? (
                <CustomTable
                  {...(taskInfo.taskType == "Image" ||
                  taskInfo.taskType == "Segmentation"
                    ? dropDownGroup[0].table
                    : dropDownGroup[1].table)}
                  handleClick={(label, id, link, row2, trIndex) => {
                    switch (label) {
                      case  "View Annotations" : {
                        if (taskInfo?.taskType == "Image") {
                          if (id != null) {
                           
                              let tempAnnotDetails = [];
                              const annots = id; 
                              annots?.jsonFile?.map((item) => { 

                                tempAnnotDetails.push({
                                  annotation_name: item?.Anotation_id?.regions.type,
                                  class: item?.Anotation_id?.regions.cls,
                                  file_id: item?.Anotation_id?.regions.id,
                                  status:
                                    item?.status == 3
                                      ? "Rejected"
                                      : item?.status == 2
                                      ? "Approved"
                                       : item?.status == 4
                                      ? "Disputed"
                                      : "Pending",
                                  statusToShow:
                                    item?.status == 3
                                      ? "rejected"
                                      : item?.status == 2
                                      ? "approved"
                                       : item?.status == 4
                                      ? "disputed"
                                      : "pending",

                                  // for raise dispute
                                  annotationId: item?.Anotation_id?.id,
                                  // for buttons to show if raise dispute or view comment or disable raise dispute
                                  annotStatus: item?.Anotation_id?.status,
                                  // to show vComments
                                  vComments: item?.Anotation_id?.comment, //annots?.vComments,
                                  annotDetails: item?.Anotation_id?.jsonFile,
                                  workerId: item?.Anotation_id?.worker_id?.id, 

                                  worker:
                                  item?.worker_id?.user_id?.first_name +
                                  " " +
                                  item?.worker_id?.user_id?.last_name,
                                validator:
                                  item?.validator_id?.user_id?.first_name +
                                  " " +
                                  item?.validator_id?.user_id?.last_name,
                                });
                              });
                              setAnnotationDS(tempAnnotDetails);
                              setAnnotDetails(id);
                            
                           
                          }
                          localStorage.setItem('imageIndex',trIndex);
                          setImageIndex(trIndex);
                          setIsModalVisible(true);
                        }
                        if (taskInfo?.taskType == "Audio") {
                          if (id != null) {
                               const audioDetails = id;
                              let tempAnnotDetails = [];
                              audioDetails?.jsonFile.map((item) => {
                                tempAnnotDetails.push({
                                  annotation_name: 
                                    item?.Anotation_id?.regions?.data?.note,
                                        annotationId: item?.id,
                                  status:
                                    item?.Anotation_id?.status == 3
                                      ? "Rejected"
                                      : item?.status == 2
                                      ? "Approved"
                                      : item?.status == 4
                                      ? "Disputed"
                                      : "Pending",
                                  url: item?.Anotation_id?.url,
                                  jsonFile: [item?.Anotation_id?.regions],

                                  worker:
                                    item?.worker_id?.user_id?.first_name +
                                    " " +
                                    item?.worker_id?.user_id?.last_name,
                                  validator:
                                    item?.validator_id?.user_id?.first_name +
                                    " " +
                                    item?.validator_id?.user_id?.last_name,
                                });
                              });
                              setAnnotationAudioDS(tempAnnotDetails); // data to show for Audio annotation list table of modal
                              setAnnotDetails(id); // all annotations for single audio
                                  }
                          setIsModalVisible(true);
                        }
                        if (taskInfo?.taskType == "Text") {
                          if (id != null) {
                            if (
                              localStorage.getItem("textType") == "questions"
                            ) { 
                                const textDetails = id;
                                let tempAnnotDetails = [];
                                textDetails?.jsonFile.map((item) => {
                                  const questionIndex =
                                    item?.Anotation_id?.region_id;
                                  const annotName =
                                    questions[
                                      questionIndex.split("")[
                                        questionIndex.length - 1
                                      ]
                                    ]?.title;
                                  tempAnnotDetails.push({
                                    annotation_name: 
                                      annotName,
                                    status:
                                      item?.status == 3
                                        ? "Rejected"
                                        : item?.status == 2
                                        ? "Approved"
                                        : item?.status == 4
                                        ? "Disputed"
                                        : "Pending",
                                    // for raise dispute
                                    annotationId: item?.id,
                                    // for buttons to show if raise dispute or view comment or disable raise dispute
                                    annotStatus: item?.status,
                                    // to show vComments
                                    vComments: item?.comment,
                                    questions: [
                                      questions[
                                        questionIndex.split("")[
                                          questionIndex.length - 1
                                        ]
                                      ],
                                    ],
                                    pdfUrl: item?.Anotation_id?.media_id?.path,
                                    savedAnnotation: {
                                      [item?.Anotation_id?.region_id]: item
                                        ?.Anotation_id?.regions,
                                    },

                                    workerId: item?.worker_id?.id,
                                    validator_id: item?.validator_id?.id,
                                    annotation_id: item?.Anotation_id?.id,

                                    worker:
                                      item?.worker_id?.user_id?.first_name +
                                      " " +
                                      item?.worker_id?.user_id?.last_name,
                                    validator:
                                      item?.validator_id?.user_id?.first_name +
                                      " " +
                                      item?.validator_id?.user_id?.last_name,
                                  });
                                });
                                setAnnotationTextDS(tempAnnotDetails);
                                setAnnotDetails(id);
                              
                            }
                            if (
                              localStorage.getItem("textType") == "segmentation"
                            ) {
                                 const textDetails = id;

                                let tempAnnotDetails = [];
                                textDetails?.jsonFile.map((item) => {
                                  const annotName =
                                    item?.Anotation_id?.regions?.text;

                                  tempAnnotDetails.push({
                                    annotation_name:
                                          annotName,
                                    status:
                                      item?.status == 3
                                        ? "Rejected"
                                        : item?.status == 2
                                        ? "Approved"
                                        : item?.status == 4
                                        ? "Disputed"
                                        : "Pending",
                                    // for raise dispute
                                    annotationId: item?.id,
                                    // for buttons to show if raise dispute or view comment or disable raise dispute
                                    annotStatus: item?.status,
                                    // to show vComments
                                    vComments: item?.comment,
                                    worker:
                                      item?.worker_id?.user_id?.first_name +
                                      " " +
                                      item?.worker_id?.user_id?.last_name,
                                    validator:
                                      item?.validator_id?.user_id?.first_name +
                                      " " +
                                      item?.validator_id?.user_id?.last_name,
                                    paragraph: item?.paragraph,
                                    particularAnnot: [
                                      item?.Anotation_id?.regions,
                                    ],
                                    segmentAllAnnots:
                                      textDetails?.jsonFileForStartTask,
                                    totalAnnots: item?.jsonFile,
                                  });
                                });
                                setAnnotationTextDS(tempAnnotDetails); 
                              
                            }
                          }
                          setIsModalVisible(true);
                        }
                        break;
                       }
                     
                      default:
                        break;
                    }
                  }}
                />
              ) : (
                <CustomEmptyDiv
                  title={
                    classLists.length == 0
                      ? t("clss_appear_here")
                      : audioLists.length == 0
                      ? t("audio_appear_here")
                      : textLists.length == 0
                      ? t('text_appear_here')
                      : null
                  }
                />
              )}
            </Panel>
          </Collapse>

          {/* class / audio / text annot modal */}
          <AnnotationModal
            // image class details
            classNames={classNames}
            udtContainerPropsAdmin = {udtContainerPropsAdmin}
            // udtBreadCrumbsAdmin = {udtBreadCrumbsAdmin}
            imageArray={imageArray}
            imageIndex={imageIndex}
            annotDetails={annotDetails}
            annotationDS={annotationDS}
            // audio details
            annotationAudioDS={annotationAudioDS}
            // text
            annotationTextDS={annotationTextDS}
            classLists={classLists}
            // projId={projId}
            isModalVisible={isModalVisible}
            hideModal={(hide) => {
              setIsModalVisible(hide);
            }}
          />

          {/* workers list start*/}
          <div className="form-group">
            <label
              style={{
                marginBottom: "-13px",
                fontWeight: "600",
                fontSize: "16px",
                // marginTop: "45px",
              }}
            >
              {t("wrkr_list")}
            </label>
            <CardHoc
              cardInnerPadding={"content-e"}
              cardPadding={"0px"}
              minHeight={0}
            >
              {dataSourceWorker.length != 0 ? (
                <div id="paginationOfgroup">
                  <Table
                    dataSource={dataSourceWorker}
                    columns={columnsWorkers}
                    pagination={false}
                    showSorterTooltip={false}
                  />
                </div>
              ) : (
                <CustomEmptyDiv title={t('wrkr_appear_here')} />
              )}
            </CardHoc>
          </div>
          {/* workers list end*/}

          {/* validators list start*/}
          <div className="form-group">
            <label
              style={{
                marginBottom: "-13px",
                fontWeight: "600",
                fontSize: "16px",
                marginTop: "45px",
              }}
            >
              {t("validators_list")}
            </label>
            <CardHoc
              cardInnerPadding={"content-e"}
              cardPadding={"0px"}
              minHeight={0}
            >
              {dataSourceValidator.length != 0 ? (
                <div
                  id="paginationOfgroup"
                  className="vlist-ongoingproj-task-details"
                >
                  <Table
                    dataSource={dataSourceValidator}
                    columns={columnsValidators}
                    pagination={false}
                    showSorterTooltip={false}
                  />
                </div>
              ) : (
                <CustomEmptyDiv title={t('val_appear_here')}/>
              )}
            </CardHoc>
          </div>
          {/* validators list end*/}
        </div>
      </CardAdmin>
    </ContainerAdmin>
  );
};

export default TaskDetails;
