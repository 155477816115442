import React from 'react'

const CardAdmin = (props) => {
  const {
    children,
    cardPadding,
    cardInnerPadding  
  }={...props}
 
                    
  return (
    // <Content style={{ margin: '10px 16px 0' }}>
        <div className="site-layout-background" style={{ padding: cardPadding || '0px 24px',paddingTop:'-35px', minHeight: 360 }}>
            <div className={cardInnerPadding  || 'content-e'}>
            {/* "content-e tabs_button removeTopMagin" */}
                {children}
            </div>
        </div>
    // </Content>
  )
}

export default CardAdmin