import React, { /* useState, useEffect, */ useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from "prop-types";
import { tinyMCEApiKey } from '../../Env';
const TinyMCE = ({
  onChange,
  id,
  initValue,
}) => {

  const editorRef = useRef(null);

  return (
    <div>
      <Editor
        id={id}
        apiKey={tinyMCEApiKey}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        value={initValue}
        init={{
          height: 300,
          menubar: "edit view insert format table",
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent ',
          content_style: `
          @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');
        body {
            font-family: "Montserrat", "Cairo", sans-serif !important;
            font-size:14px;
           }
          `,
        }}
        onEditorChange={onChange}

      />
    </div>
  );
};

TinyMCE.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
  initValue: PropTypes.string,
};

export default TinyMCE;
