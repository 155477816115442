/*

1. For Container :
    * handleClick,children,menuProps,breadCrumbItems,menuProps  
      are Mandatory props
    * Prop types
        {

            menuProps: SidebarMenus('user'),  // for side bar
            handleClick : ()=>{

            }, // callback for clicking sidebar menu
            children : contentChild = ()=>{
                return(
                    <></>
                )
            }, // jsx component child of Content
            breadCrumbItems: breadCrumbItems = [
                { 
                    link : NavigateTo.LINK_NAME,
                    label : Constants.labelname
                }
            ]
        }

*/

import React, { useEffect, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Tooltip,
  Popover,
  Timeline,
  Badge
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCheckCircle
} from "@fortawesome/free-regular-svg-icons";
import { BellOutlined, RedoOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Header as HeaderUser } from "../../layout/UserModule/";
import Sidebar from "../../layout/sidebar";
import { AppImage, NavigateTo } from "../../themes";
import { isInternetConnected } from "../../themes/utils/internetConnectionn";
import {
  getUserNotification,
} from "../../redux/actions/Notification";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Container = (props) => {
  let {
    children,
    menuProps,
    active,
    languageChanged = () => { },
    breadCrumbItems = [
      {
        link: "/dummy",
        label: "Dummy",
      },
    ],
  } = { ...props };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Content } = Layout;
  const navigate = useNavigate();
  const [hamClicked, setHamClicked] = useState(true);
  const [isMobile, setIsMobile] = useState(false); // for mobile view [not used]
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [data_source_worker, setData_source_worker] = useState([]);
  const [pendingTotal, setPendingTotal] = useState(0);
  const name = localStorage.getItem("fullName");
  function fillNotifications(res) {
    const tempForWorker = [];
    let pending = 0;
    res.sort((a, b) => {
      return new Date(b?.created_at) - new Date(a?.created_at);
    })
      .sort((a) => {
        return a?.invite?.is_accepted == "pending" ? -1 : 1;
      })
      .map((item) => {
        let inv_pending = item?.invite?.is_accepted == "pending";
        if (inv_pending) {
          pending = pending + 1;
        }

        tempForWorker.push({
          dot: inv_pending ? <BellOutlined style={{ fontSize: "2vh", color: "#f00" }} /> : <FontAwesomeIcon icon={faCheckCircle} size="lg" color="#316293" />,
          children: (
            item?.invite ? (
              <p>
                {t("u_invited_to_proj")} <b>{item?.invite?.task_name}</b> {t("as_a")} <b>{item?.invite?.invite_type}</b>
              </p>
            ) : (
              item?.description && item?.description?.split(" ").map((item2, index) => {
                return (
                  <span key={index} style={{ fontWeight: index == item?.description?.split(" ").length - 1 ? "bold" : "normal" }}>
                    {item2}&nbsp;
                  </span>
                );
              }) || ""
            )
          ),
        });
      });
    setData_source_worker(tempForWorker);
    setPendingTotal(pending);
  }
  const payloadNotification = {
    body: {
      pageNum: 1,
      type: 1,
    },
    success: (res) => {
      fillNotifications(res);
      localStorage.setItem("notifications", JSON.stringify(res));
      localStorage.setItem("notificationsTimeDelta", new Date().getTime());
    },
  };
  useEffect(() => {
    const contentsComponent = document.querySelector(
      "section.ant-layout.new_layout"
    );
    if (localStorage.getItem("lang") == "ar") {
      contentsComponent.setAttribute("style", "margin-left:unset;");
    }
    // one minute
    let res = localStorage.getItem("notificationsTimeDelta") && new Date().getTime() - localStorage.getItem("notificationsTimeDelta") < 60000
      ? JSON.parse(localStorage.getItem("notifications"))
      : [];
    if (res.length) {
      fillNotifications(res);
    } else {
      isInternetConnected() && dispatch(getUserNotification(payloadNotification));
    }

  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Layout>
      <Sidebar
        isHamClicked={hamClicked}
        menuProps={menuProps}
        activeItem={active}
        hamUnclicked={() => setHamClicked(true)}
      />
      <Layout
        className={
          localStorage.getItem("lang") == "ar"
            ? "new_layout ar"
            : "new_layout en"
        }
      >
        <div
          style={{
            backgoundColor: "red",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <HeaderUser
            headerClassName={
              menuProps[0].link == NavigateTo.OWNER_DASHBOARD
                ? "owner-header"
                : null
            }
            handleHamburgerClick={() => setHamClicked(!hamClicked)}
            handleLanguageChange={(val) => {
              setLang(val);
              languageChanged(true);
            }}
          />
        </div>
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            className="breadcrumb_header"
            style={{
              // flexDirection: lang == "ar" ? "row-reverse" : 'row',
              paddingRight: lang == "ar" ? "0px" : null,
              paddingLeft: lang == "ar" ? "25px" : null,
            }}
          >
            <Breadcrumb
            // style={{ flexDirection: lang=='ar' ? 'row-reverse' : 'row'}}
            >
              {breadCrumbItems.map((item, index) => {
                return index == breadCrumbItems.length - 1 ? (
                  <Breadcrumb.Item key={index}>{item.label}</Breadcrumb.Item>
                ) : !isMobile ? (
                  <Breadcrumb.Item key={index} className="slash-sibling">
                    <Link
                      className="focus"
                      to={item.link}
                      style={{
                        color: "rgba(0, 0, 0, 0.45)",
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      {item.label}
                    </Link>
                  </Breadcrumb.Item>
                ) : null;
              })}
            </Breadcrumb>

            {/* user profile on header along breadcrumb */}

            <div
              style={{
                display: "flex",
                flexDirection: lang == "ar" ? "row-reverse" : "row",
                alignItems: "center",
              }}
            >
              {localStorage.getItem("user_type") != "owner" ? (
                <div
                  style={{
                    cursor: "pointer",
                    marginRight: lang == "ar" ? "0px" : "20px",
                    marginLeft: lang == "ar" ? "20px" : "0px",
                  }}
                >
                  <Popover
                    title={
                      <div style={{
                        direction: lang === "ar" ? "rtl" : "ltr",
                        textAlign: lang === "ar" ? "right" : "left",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}>
                        <b>{t("notifications")}</b>
                        <button
                          onClick={() => {
                            isInternetConnected() && dispatch(getUserNotification(payloadNotification));
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            color: "#316293",
                            cursor: "pointer",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                          }}>
                          <RedoOutlined />
                        </button>

                      </div>
                    }
                    placement="topLeft"
                    overlayInnerStyle={{
                      direction: lang === "ar" ? "rtl" : "ltr",
                      textAlign: lang === "ar" ? "right" : "left",
                      borderRadius: "10px",
                      backgroundColor: "rgb(239 243 254)",
                    }}
                    content={
                      <Timeline
                        style={{
                          margin: "3% 4% 0% 4%",
                          padding: "2%",
                          minHeight: "200px",
                          maxHeight: "600px",
                          overflowY: "scroll",
                        }}
                      >
                        {data_source_worker.map((item, index) => {
                          return (
                            <Timeline.Item
                              key={index}
                              dot={item?.dot ? item?.dot : null}
                              color={item?.color ? item?.color : "blue"}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate(NavigateTo.USER_NOTIFICATIONS);
                              }}
                            >
                              {item?.children}
                            </Timeline.Item>
                          );
                        })}
                      </Timeline>
                    }>
                    <Badge count={pendingTotal} showZero>
                      <FontAwesomeIcon icon={faBell} size="lg" color="#193d64" />
                    </Badge>
                  </Popover>
                </div>
              ) : null}
              <label
                style={{
                  fontWeight: "500",
                  fontSize: "15px",
                  color: "#316293",
                  paddingRight: "8px",
                  paddingLeft: lang == "ar" ? "8px" : null,
                  cursor: "pointer",
                  textTransform: "capitalize",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  // width: name.length > 8 ? 103 : 82,
                }}
              >
                <Tooltip title={name} className="userName">
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                  // onClick={() => setShowSkillModal(true)}
                  >
                    {name?.length > 18 ? name?.substring(0, 8) + "..." : name || ""}
                  </span>
                </Tooltip>
                {/* {localStorage.getItem('fullName')} */}
              </label>
              <div
                style={{
                  border: "2px solid #193d64",
                  borderRadius: "50px",
                  width: " 40px",
                  height: " 40px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(localStorage.getItem("user_type") == "owner" ? NavigateTo.OWNER_PROFILE : NavigateTo.USER_PROFILE);
                }}
              >
                <img
                  style={{
                    borderRadius: "35px",
                    width: " 36px",
                    height: " 36px",
                  }}
                  alt=""
                  src={localStorage.getItem("userImage") && localStorage.getItem("userImage") != "" ? localStorage.getItem("userImage") : AppImage.defaultImage}
                  width={35}
                />
              </div>
            </div>

          </div>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Container;
