import { GET_ANNOTATION_ADMIN, GET_IMAGES_ADMIN, GET_PROJ_COMPLETED_ADMIN, GET_USER_LIST_ADMIN, IMERSONATE_USER, GET_OWNER_LIST_ADMIN, GET_USER_DASHBOARD, GET_OWNER_DASHBOARD, GET_ADMIN_DASHBOARD, GET_PROJ_CREATED_ADMIN } from '../actionTypes/actionTypes'

export const getUserDashboardData = (payload) => {
    return {
        type: GET_USER_DASHBOARD,
        payload
    }
}
// done
export const getOwnerDashboardData = (payload) => {
    return {
        type: GET_OWNER_DASHBOARD,
        payload
    }
}
// done
export const getAdminDashboardData = (payload) => {
    return {
        type: GET_ADMIN_DASHBOARD,
        payload
    }
}
export const getOwnerListAdmin = (payload) => {
    return {
        type: GET_OWNER_LIST_ADMIN,
        payload: payload
    }

}
export const getUserListAdmin = (payload) => {
    return {
        type: GET_USER_LIST_ADMIN,
        payload: payload
    }

}
export const impersonateUser = (payload) => {
    return {
        type: IMERSONATE_USER,
        payload: payload
    }

}
export const getProjCreatedAdmin = (payload) => {
    return {
        type: GET_PROJ_CREATED_ADMIN,
        payload: payload
    }

}
export const getProjCompletedAdmin = (payload) => {
    return {
        type: GET_PROJ_COMPLETED_ADMIN,
        payload: payload
    }

}
export const getImagesAdmin = (payload) => {
    return {
        type: GET_IMAGES_ADMIN,
        payload: payload
    }

}
export const getAnnotationAdmin = (payload) => {
    return {
        type: GET_ANNOTATION_ADMIN,
        payload: payload
    }

}