import React from 'react';
import './App.css';
import '../src/css/style.css';
import '../src/css/responsive.css';
import 'antd/dist/antd.min.css';
import 'react-datasheet/lib/react-datasheet.css';
import RoutesNew from '../src/Routes/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoaderHoc } from './hoc';
import { useEffect } from 'react';
import i18n from "i18next";
// import "../src/css/ckEditorStyle.css";
import { useNavigate } from 'react-router';
import { setNavigate } from './Routes/navigationServices';
import { useDispatch } from 'react-redux';
function App() {

  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    // if(location.pathname)
    localStorage.setItem('__REACT_WORKSPACE_LAYOUT_EXPANDED', false);

    document.title = 'WosoM';
    setNavigate(navigate, dispatch);
    i18n.changeLanguage(localStorage.getItem('lang')); // this code is used to persist the translations in case of reloading the page
  }, []);
  return (

    <LoaderHoc>
      <RoutesNew />
      <ToastContainer limit={1} autoClose={1500} />
    </LoaderHoc>

  );

}

export default App;


