import { LOGOUT, SAVE_TASK_LIST_FOR_API,SAVE_TASK_LIST_FOR_TABLE } from "./../../actionTypes/actionTypes";

const initialState = {
     savedTaskListForApi : [],
     savedTaskListForTable : [],
}

const taskListReducer  = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
                savedTaskListForApi : [],
                savedTaskListForTable : [],
            };
        case SAVE_TASK_LIST_FOR_API : {
            return {
                ...state, 
                savedTaskListForApi : action.payload
            };
        }
        case SAVE_TASK_LIST_FOR_TABLE : {
            return {
                ...state, 
                savedTaskListForTable : action.payload
            };
        }
        default:
            return {
                ...state
            };

    }

}

export default taskListReducer ;