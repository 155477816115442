import { Post } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl;

const Fetch = {
  getAnnotationListsApi(payload) {
    let url = baseUrl + `anotation_listing`;
    return Post(payload, url);
  },
  saveAnnotationsSagaApi(payload) {
    let url = baseUrl + `performer/${payload.task_id}/annotate/`;
    return Post(payload.annotation, url);
  },
  saveValidationsSagaApi(payload) {
    let url = baseUrl + `performer/${payload.task_id}/validate/`;
    return Post(payload.validation, url);
  },
  acceptRejectAnnotationsApi(payload) {
    let url = baseUrl + `ValidatorApproveOrReject/`;
    return Post(payload, url);
  }, // VALIDATOR PENDING TASK DETAILS
};

export default Fetch;
