import React, { useEffect, useState, useRef } from "react";
import { CardHoc, Container, AudioPlayer } from "../../../hoc";
import {
  CustomEmptyDiv,
  // CustomPageHeader,
  CustomTable,
  // TextField,
  CustomHelp
} from "../../../molecules";
import { AppImage, NavigateTo, SidebarMenus } from "../../../themes";
import {
  Collapse,
  Table,
  Modal,
  Select,
  Tooltip,
  Button,
  Rate,
  Progress,
  Pagination,
  Card,
  Row,
  Col,
  Skeleton,
  Timeline,
  Dropdown,
  Popover
} from "antd";
// import FileManager from "../dashboard/file";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { useDispatch } from "react-redux";
import {
  getTaskDetails,
  saveTaskId,
  inviteToTask,
  getInvitedUsersList,
  removeUser,
  updateQuota,
  replaceTaskUser,
  removeUserInvite,
  getDocumentDetails,
} from "../../../redux/actions/Tasks";
// import appconstant from "../../../themes/appconstant";
import { toast } from "react-toastify";
import {
  startLoading,
  stopLoading,
  getLanguageList,
  getWorkersValidatorsList
} from "../../../redux/actions/Common";
import parse from "html-react-parser";
// import { disputeListing } from "../../../redux/actions/DisputedAnnots";
import {
  AnnotationModal,
  ReportModal,
} from "./ongoingProjectTaskDetailsModals";
import WorkersBar from "./charts/workersBar";
import TimelineBar from "./charts/timelineBar";
import {
  TagsOutlined,
  SafetyOutlined,
  UsergroupAddOutlined,
  InfoCircleOutlined,
  EyeOutlined,
  CloudDownloadOutlined,
  RetweetOutlined,
  MoreOutlined,
  RightOutlined,
  PlusOutlined,
  MinusOutlined
} from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
const { Panel } = Collapse;
const { Option } = Select;

const OngoingProjectTaskDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const location = useLocation();
  const dispatch = useDispatch();
  // const imageForFileType = AppImage.file_n;
  const currentAudioRef = useRef(null);
  // const projectId = localStorage.getItem("ongoingProjectId");
  const { taskId } = useParams(); //localStorage.getItem("ongoingProjectTaskId");

  localStorage.setItem("workStatus", "InReviewPending");
  const initialValues = {
    inviteList: [],
    inviteEmail: "",
    inviteType: "annotator",
  };
  const userMenuItems = [
    // edit button
    {
      key: '1',
      label: (
        <button
          type="button"
          className="btn focus"
          onClick={() => {
            setReplaceUser({
              name: currentUser.name,
              email: currentUser.email,
            });
            setIsModalVisiblePool(true);
          }}
        >
          {t("replace")}
        </button>
      ),
    },
    // delete button
    {
      key: '2',
      label: (

        <button
          type="button"
          className="btn focus"
          onClick={() => {
            setUserTypeRemove("worker");
            setUserRemovalId(currentUser?.email);
            setIsModalRemove(true);
          }}
        >
          {t("remove")}
        </button>
      ),
    },
  ];
  const onSubmit = (values) => {
    if (workerInvitaionList.find(item => item[0] == values.inviteEmail)) {
      toast.error(t("email_already_exists"), {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const invitePayload = {
      body: {
        taskId: taskId,
        email: values.inviteEmail,
        invite_type: values.inviteType,
      },
      success: () => {
        toast.success(t("invite_success"), {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
        setIsModalVisibleEmail(false);
        formik.resetForm();

        isInternetConnected() && dispatch(getInvitedUsersList(inviteListPayload));
      },
      fail: (res) => {
        toast.error(res?.data, {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      }
    }
    isInternetConnected() && dispatch(inviteToTask(invitePayload));

  };
  const formikValidationSchemaAddTaskModal = () => {
    return Yup.object().shape({
      inviteEmail: Yup.string()
        .matches(
          /^[^\s!#%^*]+[\w\d\s@,.<>:;'"?/\\{}()\]\[\-_*&$]+$/,
          t("entr_vld_email")
        ),
      inviteType: Yup.string()
    });
  };
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: formikValidationSchemaAddTaskModal(),
  });
  const columnsForQuestions = [
    {
      title: t("qn"),
      dataIndex: "title",
      key: "question",
      width: "19%",
      render: (val) => {
        return (
          <Tooltip title={val} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
      // render: (value, item, index) => index + 1,
    },
    {
      title: t("ans_type"),
      dataIndex: "type",
      key: "answerType",
      render: (item, record) => {
        return record.type == "radiolist" ? t("radio_btns") : record.type == "radio" ? t("radio_btn") : record.type == "checkbox" ? t("check_box") : t("yes_no")
      },
    },
    {
      title: t("ans"),
      dataIndex: "choices",
      key: "choices",
      width: "30%",
      render: (value) => {
        // const ren = ["dwqdd", "uigieguf"];
        return (
          <div
            style={{
              boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
              borderRadius: "18px",
            }}
          >
            {value.map((items, indexx) => {
              return (
                <div
                  style={{
                    borderBottom:
                      value.length - 1 != indexx ? "1px #8bbae8 solid" : null,
                    padding: "10px",
                    paddingLeft: "20px",
                  }}
                  key={indexx}
                >
                  <Tooltip title={items}>{items}</Tooltip>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];
  // image class start
  const [classLists, setClassLists] = useState([]);
  const [annotationDS, setAnnotationDS] = useState([]);
  const [questions, setQuestions] = useState(null);

  const [classNames, setClassNames] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [imageListTotal, setImageListTotal] = useState(0);
  // const [annotDetails, setAnnotDetails] = useState([]);
  // const [imageIndex, setImageIndex] = useState("");
  // const [documents, setDocuments] = useState([]);
  // image class start
  const [width, setWidth] = useState(window.innerWidth);
  //audio start
  const [audioLists, setAudioLists] = useState([]);
  const [audioListTotal, setAudioListTotal] = useState(0);
  const [totalAnnotated, setTotalAnnotated] = useState(0);
  const [totalValidated, setTotalValidated] = useState(0);
  // const [audioIndex, setAudioIndex] = useState("");
  // const [annotationAudioDS, setAnnotationAudioDS] = useState([]);
  //audio end
  //text
  const [currentUser, setCurrentUser] = useState(null);
  const [textLists, setTextLists] = useState([]);
  const [textListTotal, setTextListTotal] = useState(0);
  // const [annotationTextDS, setAnnotationTextDS] = useState([]);
  //text
  const [annotationDate, setAnnotationDate] = useState({});
  // const [modalTitle, setModalTitle] = useState("");

  const [selectedIds, setselectedIds] = useState([]);
  const [showValidatorLists, setShowValidatorList] = useState([]);
  // const [showWrokerLists, setShowWrokerList] = useState([]);

  const [_ordering, setOrdering] = useState("");
  const [userTypeRemove, setUserTypeRemove] = useState(null);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [userRemovalId, setUserRemovalId] = useState(false);
  const [userReplaceId, setUserReplaceId] = useState(false);
  const [isModalReplace, setIsModalReplace] = useState(false);
  const [isReport, setIsModalReport] = useState(false);
  const [isModalRemove, setIsModalRemove] = useState(false); // remove modal
  // const [isModalQuota, setIsModalQuota] = useState(false); // Quota modal
  const [isModalRemoveInvite, setIsModalRemoveInvite] = useState(false); // remove modal
  // const [isModalRemoveUser, setIsModalRemoveUser] = useState(false); // remove modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEmail, setIsModalVisibleEmail] = useState(false);
  const [isModalVisibleQuestions, setIsModalVisibleQuestions] = useState(false);
  const [ratingVal, setRatingVal] = useState(0);
  const [languageVal, setLanguageVal] = useState("en");
  const [languageChildren, setLanguageChildren] = useState([]);
  const [currentlyActive, setCurrentlyActive] = useState([]);
  const [isModalVisiblePool, setIsModalVisiblePool] = useState(false);
  // const [totalValidators, setTotalValidators] = useState(1);
  // const [pageNumValidators, setPageNumValidators] = useState(1);
  // const [dataSourceValidator, setDataSourceValidator] = useState([]); // validators
  const [searchValue, setSearchValue] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [_totalWorkers, setTotalWorkers] = useState(1);
  // const [pageNumWorker, _setPageNumWorker] = useState(1);
  const [dataSourceWorker, setDataSourceWorker] = useState([]); // workers table
  const [pageNum, setPageNum] = useState("");
  const [workerInvitaionList, setWorkerInvitaionList] = useState([]); // worker invited
  // const [validatorInvitationList, setValidatorInvitationList] = useState([]);
  const [userFilter, setUserFilter] = useState([]);
  const [answerFilter, setAnswerFilter] = useState([]);
  const [limitFilter, setLimitFilter] = useState(10);
  const [replaceUser, setReplaceUser] = useState(false);
  const [disputeAnnotationContents, _setDisputeAnnotationContents] = useState([]);
  const [quotaData, setQuotaData] = useState({
    current: 1,
    free: 0,
    adding: 0,
  });
  // const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [taskStatus, setTaskStatus] = useState("");
  /*   const [dataSourceAnnotation, setDataSourceAnnotation] = useState([]); */
  const [taskInfo, setTaskInfo] = useState({
    taskName: "",
    taskType: "",
    taskTypeVal: "",
    textType: "",
    instruction: "<p></p>",
    description: "",
    validation_system: "",
    quotas: [],
    latest_documents: [],
  });

  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.PROJECT,
      label: t("projects"),
    },
    {
      link: NavigateTo.ONGOING_PROJECT_DETAILS,
      label: t("ongoing_prj_details"),
    },
    {
      link: "",
      label: t("task_details"),
    },
  ];
  function update_quotas(add) {
    let { current, free, adding } = quotaData;
    if (add) {
      if (free > 0) {
        free--;
        adding++;
      } else {
        toast.error(t("no_more_quota"), {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      if (adding + current > 1) { // atleast 1 quota should be assigned
        free++;
        adding--;
      } else if (adding == 0 && current > 1) {
        free++;
        adding--;
      } else {
        toast.error(t("no_more_quota"), {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      }
    }

    setQuotaData({
      free: free,
      adding: adding,
      current: current,
    });
  }
  function thousands_separators(num) {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }
  function download_csv(type) {
    switch (type) {
      case "workers":
        let csvString = "data:text/csv;charset=utf-8,";
        csvString += "Name,Email,Assigned,Annotated\n";
        dataSourceWorker
          .sort((a, b) => {
            return b.annotated - a.annotated;
          })
          .forEach(function (row) {
            csvString += row.name + "," + row.email + "," + row.assigned + "," + row.annotated + "\n";
          });
        var encodedUri = encodeURI(csvString);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Workers.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        break;
    }
  }
  function generateSample() {
    dispatch(startLoading());
    let payload = {
      body: {
        task_id: taskId,
        by_user: userFilter,
        by_answer: answerFilter,
        limit: limitFilter,
      },
      success: (res) => {
        dispatch(stopLoading());
        // TODO: fix for other types
        let tempTextLists = [];

        res?.map((item) => {
          tempTextLists.push({
            0: {
              image: AppImage.file_n,
              text: item?.paragraph?.length > 50 ? item?.paragraph?.substring(0, 50) + "..." : item?.paragraph,
              tooltip: item?.paragraph,
              annotations: item?.annotations,
            },
            1:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.annotation.length > 0
                  ? item?.annotations[0].annotation.map((annot) => {
                    return `${annot?.question} : ${annot?.answer}`;
                  }).join(" | ")
                  : t("no_annot")
                : t("no_annot"),
            2:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.validation?.validated == true
                  ? item?.annotations[0]?.validation?.is_valid == true
                    ? t("valid")
                    : `${t("invalid")} (${item?.annotations[0]?.validation
                      ?.comment || t("no_cmnts")})`
                  : t("no_val")
                : t("no_val"),
            3: {
              button: "View Annotations",
            },
          }); // tempTextLists
        }
        );
        setTextLists(tempTextLists);
      },
      fail: () => {
        dispatch(stopLoading());
      }
    }
    isInternetConnected() && dispatch(getDocumentDetails(payload));
  }
  const statItems = [
    {
      "title": t("tsk_quota"),
      "items": [
        {
          label: t("total"),
          value: taskInfo?.quotas?.length,
        },
        {
          label: t("assigned"),
          value: taskInfo?.quotas?.filter(item => item?.assigned == true).length,
        },
        {
          label: t("free"),
          value: taskInfo?.quotas?.filter(item => item?.assigned == false).length,
        }
      ]
    },
    {
      "title": t("workers"),
      "items": [
        {
          label: t("users"),
          value: dataSourceWorker?.length,
        },
        {
          label: t("invited"),
          value: workerInvitaionList?.length,
        },
        {
          label: t("accepted"),
          value: workerInvitaionList?.filter(item => item[3] == "accepted").length,
        }
      ]
    },
    {
      "title": t("documents"),
      "items": [
        {
          label: t("total"),
          value: thousands_separators(taskInfo?.taskTypeVal == "transcription" ? audioListTotal : taskInfo?.taskTypeVal == "segmentation" || taskInfo?.taskTypeVal == "bbox" ? imageListTotal : textListTotal),
        },
        {
          label: t("annotated"),
          value: thousands_separators(totalAnnotated),
          sub: `${Math.round((totalAnnotated / (taskInfo?.taskTypeVal == "transcription" ? audioListTotal : taskInfo?.taskTypeVal == "segmentation" || taskInfo?.taskTypeVal == "bbox" ? imageListTotal : textListTotal)) * 100)}%`
        },
        {
          label: t("validated"),
          value: thousands_separators(totalValidated),
        }
      ]
    }

  ];
  const filterItems = [
    {
      "title": t("by_user"),
      "value": <Select
        mode="multiple"
        allowClear
        className="wide"
        placeholder={t("all")}
        value={userFilter}
        onChange={(val) => {
          setUserFilter(val);
        }}
      >
        {dataSourceWorker?.map((item, index) => {
          return (
            <Option value={item.email} key={index}>
              {item.name} ({item.email})
            </Option>
          );
        })}
      </Select>,
    },
    {
      "title": t("by_answer"),
      "value": <Select
        mode="multiple"
        allowClear
        className="wide"
        placeholder={t("all")}
        value={answerFilter}
        onChange={(val) => {
          setAnswerFilter(val);
        }}
      >
        {questions?.map((item, index) => {
          return item?.choices?.map((item2) => {
            return (
              <Option value={item2} key={index}>
                {item2}
              </Option>
            );
          })
        })}
      </Select>
    },
    {
      "title": t("limit"),
      "value": <Select
        className="wide"
        placeholder={t("limit")}
        value={limitFilter}
        onChange={(val) => {
          setLimitFilter(val);
        }}
      >
        <Option value="10">10</Option>
        <Option value="20">20</Option>
        <Option value="50">50</Option>
        <Option value="100">100</Option>
      </Select>
    }
  ]

  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t('projects'),
  };
  // others

  /* class view annotation modal table end */

  /* add worker modal table start */

  // (image, name^,email^, phNumber^ , action => remove,change,report)


  // (image, name^, phNumber^ , action => report,remove )
  const ActionColumnWorker =
    taskStatus == "completed"
      ? {}
      : {
        title: t("action"),
        dataIndex: "action",
        key: "action",
        width: 200,
        render: (value, record) => {
          return (
            <div>
              <Popover
                title={null}
                overlayClassName="custom-popover"
                content={
                  <>
                    <p>{t("manage_quotas")}</p>
                    <div className="card">
                      <p><b> {currentUser?.email} </b></p>
                      <span>{t("assigned")}: <b>{quotaData?.current + quotaData?.adding}</b> - {t("free")}: <b>{quotaData?.free}</b></span>
                      <hr />
                      {quotaData?.adding != 0 && (
                        <span>{quotaData?.adding > 0 ? t("adding") : t("removing")}:  <b>{Math.abs(quotaData?.adding)}</b> {t("quota")}</span>
                      )}
                      <div className="text-center button_d_inline model1 ">
                        <button
                          type="button"
                          className="button-list top_new active"
                          style={{ marginTop: "10px" }}
                          onClick={() => {
                            update_quotas(true);
                          }}
                        >
                          <PlusOutlined style={{ fontSize: "2vh" }} />
                        </button>
                        <button
                          type="button"
                          className="button-list top_new"
                          onClick={() => {
                            update_quotas(false);
                          }}
                        >
                          <MinusOutlined style={{ fontSize: "2vh" }} />
                        </button>
                      </div>
                    </div>
                    <div className="text-center button_d_inline model1" style={{ marginTop: "60px" }}>
                      <button
                        type="button"
                        className="button-list top_new active"
                        onClick={() => {
                          let quota_payload = {
                            body: {
                              email: currentUser?.email,
                              task_id: taskId,
                              adding: quotaData?.adding,
                            },
                            success: () => {
                              toast.success(t("quota_updated_successfully"), {
                                className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
                                position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
                              });
                              isInternetConnected() && dispatch(getInvitedUsersList(inviteListPayload));
                              isInternetConnected() && dispatch(getWorkersValidatorsList(userPayload));
                              isInternetConnected() && dispatch(getTaskDetails(payload));
                              // setIsModalQuota(false);

                            }
                          }
                          isInternetConnected() && dispatch(updateQuota(quota_payload));
                        }}
                      >
                        {t("save")}
                      </button>
                    </div>
                  </>
                }
                trigger="click"
              >
                <button
                  type="button"
                  className="focus"
                  onClick={() => {
                    setCurrentUser(record);
                    setQuotaData({
                      current: record?.quotas?.length,
                      free: taskInfo?.quotas?.filter(item => item?.assigned == false).length,
                      adding: 0
                    });
                    // setIsModalQuota(true);
                  }}
                >
                  {t("quotas")}
                </button>
              </Popover>
              <Dropdown
                trigger={"click"} menu={{ items: userMenuItems }} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
                <button
                  className="overflow-menu"
                  onClick={() => {
                    setCurrentUser(record);
                  }}
                >
                  <MoreOutlined style={{
                    color: "#193d64",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  /></button>
              </Dropdown>

            </div >
          );
        },
      };
  const columnsWorkers = width > 1000 ? [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (value, record) => {
        return (
          <>
            <img
              width={45}
              height={45}
              style={{ borderRadius: 10, marginLeft: 10, marginRight: 10 }}
              src={record?.image ? record?.image : AppImage.defaultImage}
            /> {value}
          </>
        );
      },
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      width: 300,
      render: (value, record) => {
        return (
          <Tooltip title={record?.phNumber}>
            <span style={{ color: "#316293" }}>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t("quotas"),
      dataIndex: "quotas",
      key: "quotas",
      width: 200,
      render: (value) => {
        return (
          <Tooltip title={value.map(item => { return "#" + item }).join(', ')}>
            <span style={{ color: "#316293" }}>{value.length}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t("progress"),
      dataIndex: "assigned",
      key: "assigned",
      sorter: (a, b) => a.assigned - b.assigned,
      width: 400,
      render: (value, record) => {
        return (
          <Tooltip title={`${record?.assigned} ${t("assigned")} | ${record?.annotated} ${t("annotated")}`}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Progress
                style={{
                  borderRadius: "18px",
                }}
                percent={Math.round((record?.annotated / record?.assigned) * 100)} size={[300, 20]}
                showInfo={false}
              /> <span style={{
                positionr: "absolute",
                right: "0",
              }}> {Math.round((record?.annotated / record?.assigned) * 100)}%</span>
            </div>
          </Tooltip>
        );
      }
    },
    ActionColumnWorker,
  ] : [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (value, record) => {
        return (
          <>
            <img
              width={45}
              height={45}
              style={{ borderRadius: 10, marginLeft: 10, marginRight: 10 }}
              src={record?.image ? record?.image : AppImage.defaultImage}
            /> {value}
          </>
        );
      },
    },
    {
      title: t("progress"),
      dataIndex: "assigned",
      key: "assigned",
      sorter: (a, b) => a.assigned - b.assigned,
      width: 400,
      render: (value, record) => {
        return (<p>
          {`${record?.assigned} /  ${record?.annotated}`}
        </p>);
      }
    },
    ActionColumnWorker,
  ];
  /* add worker modal table end */

  /* page description start */

  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
  };

  /* page description end*/
  const updateWorker = (item, type) => {
    const tempSelectedIds = [...selectedIds];
    const tempAddedWorkerList = [...showValidatorLists];
    const worker = tempAddedWorkerList.find((items) => items.id == item.id);
    if (!worker) {
      tempSelectedIds.push(item.id);
      item["type"] = [type];
      tempAddedWorkerList.push(item);
    } else {
      if (worker.type.includes(type)) {
        worker.type.splice(worker.type.indexOf(type), 1);
      } else {
        switch (taskInfo.validation_system) {
          case "primary":
            // if the type is annotator remove validator and vice versa
            if (type == "annotator") {
              if (worker.type.includes("validator")) {
                worker.type.splice(worker.type.indexOf("validator"), 1);
              }
            } else {
              if (worker.type.includes("annotator")) {
                worker.type.splice(worker.type.indexOf("annotator"), 1);
              }
            }
            break;
          default:
            break;
          // TODO: add other conditions
        }
        worker.type.push(type);
      }

      if (worker.type.length == 0) {
        tempSelectedIds.splice(tempSelectedIds.indexOf(item.id), 1);
        tempAddedWorkerList.splice(tempAddedWorkerList.indexOf(item), 1);
      }
    }

    setShowValidatorList(tempAddedWorkerList);
    setselectedIds(tempSelectedIds);
  };

  // dispute annotation list info ( annotations name, class name, validator name, worker name, action => view ) taskInfo?.validatorsListContents

  const disputeAnnotaionsListHeaders =
    taskInfo.taskType == "image"
      ? [

        {
          label: t("clss_name"),
          headerWidth: "200px",
        },
        {
          label: t("val_name"),
          headerWidth: "200px",
        },
        {
          label: t("worker_name"),
          headerWidth: "200px",
        },
        {
          label: t("action"),
          headerWidth: "200px",
          paddingLeft: "30px",
        },
      ]
      : [
        {
          label: t("annot_name"),
          headerWidth: "200px",
        },
        {
          label: t("val_name"),
          headerWidth: "200px",
        },
        {
          label: t("worker_name"),
          headerWidth: "200px",
        },
        {
          label: t("action"),
          headerWidth: "200px",
          paddingLeft: "30px",
        },
      ];
  // invitation list info ( name, user type, action => invite )
  const invitationListHeaders = width > 1000 ? [
    {
      label: t("name"),
      headerWidth: "188px",
    },
    {
      label: t("email"),
      headerWidth: "188px",
    },
    {
      label: t("user_type"),
      headerWidth: "188px",
    },
    {
      label: t("status"),
      headerWidth: "188px",
    },
    {
      label: t("date"),
      headerWidth: "188px",
    },
    {
      label: t("action"),
      headerWidth: "188px",
    }
  ] : [
    {
      label: t("name"),
      headerWidth: "188px",
    },
    {
      label: t("status"),
      headerWidth: "188px",
    },
    {
      label: t("action"),
      headerWidth: "188px",
    }
  ];
  const classListHeaders = [
    {
      label: "",
      headerWidth: "200px",
    },
    {
      label: t('clss_name'),
      headerWidth: "400px",
    },
    {
      label: t('annotations'),
      headerWidth: "300px",
    },
    {
      label: t('action'),
      headerWidth: "300px",
      paddingLeft: "81px",
    },
  ];
  const audioLisHeaders = [
    {
      label: t("file_name"),
      headerWidth: "350px",
    },
    {
      label: t("annotations"),
      headerWidth: "150px",
    },
    {
      label: t("validation"),
      paddingLeft: "70px",
      headerWidth: "80px",
    },
    {
      label: t("action"),
      headerWidth: "100px",
      paddingLeft: "30px",
    }
  ]; // outer list table
  const dropDownGroup = [
    {
      table: {
        placeholder: t('image_list'),
        listLength: classLists.length,
        tableName: "Classes",
        tableHeaders: classListHeaders,
        tableContents: classLists,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data owner-past-proj-details-task-details-class-list-ar"
            : "text_data owner-past-proj-details-task-details-class-list-en",
        paginationTotal: imageListTotal,
      },
    }, //class lists
    {
      table: {
        placeholder:
          taskInfo?.taskTypeVal == "transcription" ? t("audio_list") : t("txt_list"),
        tableHeaders:
          audioLisHeaders,
        tableContents:
          taskInfo?.taskTypeVal == "transcription" ? audioLists : textLists,
        tdImageWidth: taskInfo?.taskTypeVal == "segmentation" || taskInfo?.taskTypeVal == "classification" ? "35px" : null,
        tdImageHeight: taskInfo?.taskTypeVal == "segmentation" || taskInfo?.taskTypeVal == "classification" ? "40px" : null,
        tdImageBorderRadius: taskInfo?.taskType == "text" ? "unset" : null,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        AnnotClassName: "wptd",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data user-task-manage-worker-completed-task-details-class-list-ar"
            : "text_data user-task-manage-worker-completed-task-details-class-list-en",
        paginationTotal: taskInfo?.taskTypeVal == "transcription" ? audioListTotal : textListTotal,
      },
    }, // audio Lists
  ];
  const dropDownGroup2 = [
    // invitation list
    {
      table: {
        hasNestedDropDown: false,
        placeholder: t("invitation_list"),
        tableHeaders: invitationListHeaders, //workerInvitationHeaders,
        tableContents: width > 1000 ? workerInvitaionList : workerInvitaionList.map(item => {
          return [item[0], item[3], item[5]]
        }),
        listLength: workerInvitaionList.length,
        tableName: 'workers_smal_cap',
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child invited-list",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "table-sm text_data validator-completed-group-name-ar-language validator-pending-group-name-ar-language"
            : "table-sm text_data validator-completed-group-name-en-language validator-pending-group-name-en-language",
      },
    },
    //dispute annotation list
    {
      table: {
        hasNestedDropDown: false,
        placeholder: t("dispute_annots"),
        tableHeaders: disputeAnnotaionsListHeaders,
        tableContents: disputeAnnotationContents,
        listLength: disputeAnnotationContents.length,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        tableName: "disp_annots",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data "
            : "text_data ",
      },
    },
  ];
  const RemoveModal = () => {
    return (
      <Modal
        // title={"Alert"}
        open={isModalRemove}
        closable={false}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
        footer={null}
        centered
      >
        <div className="text-center  model1"
          style={{
            padding: '20px'
          }}>
          <p>{t("u_sure_to_rmv_this_usr")}</p>
          <b> {currentUser?.email} </b>
        </div>
        <div className="text-center button_d_inline model1">
          <button
            type="button"
            className="button-list top_new active"
            onClick={() => {
              setIsModalRemove(false);
              const userRemovePayload = {
                body: {
                  task_id: taskId,
                  email: userRemovalId
                },
                success: () => {
                  toast.success(t("user_removed_successfully"), {
                    className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
                    position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
                  });

                  isInternetConnected() && dispatch(getInvitedUsersList(inviteListPayload));
                  isInternetConnected() && dispatch(getWorkersValidatorsList(userPayload));
                  isInternetConnected() && dispatch(getTaskDetails(payload));
                },
              }
              isInternetConnected() && dispatch(removeUser(userRemovePayload));
            }}
          >
            {t("yes")}
          </button>
          <button
            type="button"
            className="button-list top_new"
            onClick={() => {
              setIsModalRemove(false);
              isInternetConnected() && dispatch(stopLoading());
            }}
          >
            {t("no")}
          </button>
        </div>
      </Modal>
    );
  };

  const ReplaceModal = () => {
    return (
      <Modal
        // title={"Alert"}
        open={isModalReplace}
        closable={false}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
        footer={null}
        centered
      >
        <div className="text-center  model1"
          style={{
            padding: '20px'
          }}>
          <p>{t("u_sure_to_rplc_this_usr")}</p> <b>{replaceUser?.name}</b> <RightOutlined /> <b>{userReplaceId?.name}</b>
        </div>
        <div className="text-center button_d_inline model1">
          <button
            type="button"
            className="button-list top_new active"
            onClick={() => {
              setIsModalReplace(false);

              const userReplacePayload = {
                body: {
                  task_id: taskId,
                  replace_email: userReplaceId?.email,
                  email: replaceUser?.email,
                },
                success: () => {

                  toast.success(t("user_replaced_successfully"), {
                    className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
                    position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
                  });
                  setIsModalReplace(false);
                  isInternetConnected() && dispatch(getInvitedUsersList(inviteListPayload));
                  isInternetConnected() && dispatch(getWorkersValidatorsList(userPayload));
                  isInternetConnected() && dispatch(getTaskDetails(payload));
                }
              }
              isInternetConnected() && dispatch(replaceTaskUser(userReplacePayload));
            }}
          >
            {t("yes")}
          </button>
          <button
            type="button"
            className="button-list top_new"
            onClick={() => {
              setIsModalReplace(false);
              isInternetConnected() && dispatch(stopLoading());
            }}
          >
            {t("no")}
          </button>
        </div>
      </Modal >
    );
  };

  const RemoveInviteModal = () => {
    return (
      <Modal
        // title={"Alert"}
        open={isModalRemoveInvite}
        closable={false}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
        footer={null}
        centered
      >
        <div className="text-center  model1"
          style={{
            padding: '20px'
          }}>
          <p>{t("u_sure_to_rmv_this_inv")}</p>
        </div>
        <div className="text-center button_d_inline model1">
          <button
            type="button"
            className="button-list top_new active"
            onClick={() => {
              setIsModalRemoveInvite(false);
              const userRemoveInvitePayload = {
                body: {
                  task_id: taskId,
                  email: userRemovalId,
                  invite_type: userTypeRemove
                },
                success: () => {
                  toast.success(t("user_removed_successfully"), {
                    className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
                    position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
                  });

                  isInternetConnected() && dispatch(getInvitedUsersList(inviteListPayload));
                  isInternetConnected() && dispatch(getWorkersValidatorsList(userPayload));
                  isInternetConnected() && dispatch(getTaskDetails(payload));
                },
              }
              isInternetConnected() && dispatch(removeUserInvite(userRemoveInvitePayload));
            }}
          >
            {t("yes")}
          </button>
          <button
            type="button"
            className="button-list top_new"
            onClick={() => {
              setIsModalRemoveInvite(false);
              isInternetConnected() && dispatch(stopLoading());
            }}
          >
            {t("no")}
          </button>
        </div>
      </Modal>
    );
  };
  /* drop down */
  function containsArabic(str) {
    return /[\u0600-\u06FF]/.test(str);
  }
  const fillTaskDetails = (res) => {
    setTaskInfo({
      taskName: res?.name,
      taskType: t(res?.task_type),
      taskTypeVal: res?.task_type,
      instruction: res?.instructions,
      description: res?.description,
      validation_system: res?.validation_system,
      quotas: res?.quotas,
      //revese after map
      latest_documents: res?.documents.filter(item => {
        return item?.annotations.length > 0
      }).map((item, index) => {
        let ann = item?.annotations[0];
        switch (res?.task_type) {
          case "segmentation":
          case "text_transcription":
          case "classification":
            return {
              dot: <InfoCircleOutlined style={{ fontSize: "16px", color: "#316293" }} />,
              children: (
                <p>
                  <b>{ann?.user?.name}</b> {t("annotated")} <b>{ann?.annotation[0]?.answer}</b>
                  {t("at")}
                  <b>{new Date(item?.created_at).toLocaleString()}</b> {t("on_object")} •
                  <CustomHelp
                    modal={
                      <>
                        <p>
                          {item?.paragraph}
                        </p>
                        <p>
                          <b>{ann?.user?.name}</b> | <b>{ann?.annotation[0]?.answer}</b>
                        </p>
                      </>
                    } title={item?.paragraph?.length > 15 ? item?.paragraph?.substring(0, 15) + "..." : item?.paragraph}></CustomHelp>
                </p>
              ),
            };
          case "transcription":
            return {
              dot: <InfoCircleOutlined style={{ fontSize: "16px", color: "#316293" }} />,
              children: (
                <Card>
                  <b>{ann?.user?.name}</b>  {t("annotated")} <b>{ann?.annotation[0]?.text?.length > 15 ? ann?.annotation[0]?.text?.substring(0, 15) + "..." : ann?.annotation[0]?.text}</b>
                  <br />
                  {t("at")} &nbsp;
                  <b>{new Date(item?.created_at).toLocaleString()}</b> {t("on_object")} # {index + 1} • <b>{item?.url.split("/").pop()}</b>
                </Card>
              ),
            };
          default:
            return t("no_data");
        }
      }).reverse() || [],
    }),



      setTaskStatus(res?.status);

    if (res?.task_type == 'bbox' || res?.task_type == 'polygon') {
      localStorage.setItem("taskType", "Image");
      // isInternetConnected() &&
      //   dispatch(disputeListing(payloadForDisputedAnnotations)); // dispute annot listing
      let classNames = [];
      res?.class_list.map((item) => {
        classNames.push(item?.class_name);
      });

      setClassNames(classNames);

      let tempClassLists = [];
      let tempImageArray = [];
      res?.documents.map((item, index) => {
        let imageDetails = {};
        imageDetails["annotationId"] = item?.id;
        imageDetails["jsonFile"] = item?.annotations; //item?.jsonFile?.regions;

        imageDetails["task_id"] = res?.id;
        imageDetails["workerId"] = item?.worker_id; //res?.data?.workers[0].id;
        imageDetails["validator_id"] = res?.data?.validators?.length > 0 ? res?.data?.validators?.[0].id : '';
        var classNames_list = [];
        item?.annotation?.forEach((ann) => {
          if (ann?.Anotation_id?.regions?.cls)
            classNames_list.push(ann?.Anotation_id?.regions?.cls);
        })
        tempClassLists.push({
          0: { image: item?.url },
          1: classNames_list.length > 0 ? classNames_list.join(",") : t("no_class"),
          2: item?.annotation ? item?.annotation?.length : 0,
          3: {
            button: "View Annotations",
            link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
            id: imageDetails, // send anything like image id item?.id or annot details
          },
        }); // tempClassLists
        tempImageArray.push({
          name: `Image - ${index + 1} `,
          src: item?.url,
          regions:
            item?.annotation?.length > 0
              ? item?.annotation[item?.annotation?.length - 1].jsonFile
                ?.regions
              : [],
          id: item?.id,
        }); // tempImageArray
      });
      setClassLists(tempClassLists);
      setImageArray(tempImageArray);
      setImageListTotal(res?.document_count);
    } // image

    if (
      res?.task_type == "segmentation" ||
      res?.task_type == "classification" ||
      res?.task_type == "text_transcription"
    ) {
      localStorage.setItem("taskType", "Text");
      // isInternetConnected() &&
      //   dispatch(disputeListing(payloadForDisputedAnnotations)); // dispute annot listing
      if (res?.task_type == "segmentation") {
        localStorage.setItem("textType", "segmentation");

        let tempTextLists = [];
        let tempClassLists = [];
        res?.documents.map((item) => {
          if (item?.annotations?.length == tempQuestions.length) {
            annotCount++;
          }

          tempTextLists.push({
            0: {
              image: AppImage.file_n,
              text: item?.paragraph?.length > 50 ? item?.paragraph?.substring(0, 50) + "..." : item?.paragraph,
              tooltip: item?.paragraph,
              annotations: item?.annotations,
            },
            1:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.annotation.length > 0
                  ? item?.annotations[0].annotation.map((annot) => {
                    return `${annot?.question} : ${annot?.answer} `;
                  }).join(" | ")
                  : t("no_annot")
                : t("no_annot"),
            2:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.validation?.validated == true
                  ? item?.annotations[0]?.validation?.is_valid == true
                    ? t("valid")
                    : `${t("invalid")} (${item?.annotations[0]?.validation
                      ?.comment || t("no_cmnts")
                    })`
                  : t("no_val")
                : t("no_val"),
            3: { button: "View Annotations" }

          }); // tempTextLists
        });


        setTextLists(tempTextLists);
        setTextListTotal(res?.document_count);
        res?.class_list?.map((item) => {
          tempClassLists.push({
            id: item?.class_code,
            displayName: item?.class_name,
            task_id: item?.task_id,
          });
        });
        setClassLists(tempClassLists);
      }
      if (res?.task_type == "classification") {
        localStorage.setItem("textType", "classification");

        // localStorage.setItem("textType", "questions");
        let tempTextLists = [];
        const tempQuestions = [];

        res?.question_list?.map((item, index) => {
          tempQuestions.push({
            name: `input${index} `,
            type:
              item?.question_type,
            title: item?.question,
            choices: item?.answers.map((ans) => ans?.answer) || [],
          });
        });

        res?.documents.map((item) => {
          tempTextLists.push({
            0: {
              image: AppImage.file_n,
              text: item?.paragraph?.length > 50 ? item?.paragraph?.substring(0, 50) + "..." : item?.paragraph,
              tooltip: item?.paragraph,
              ar: containsArabic(item?.paragraph),
              annotations: item?.annotations,
            },
            1:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.annotation.length > 0
                  ? item?.annotations[0].annotation.map((annot) => {
                    return `${annot?.question} : ${annot?.answer} `;
                  }).join(" | ")
                  : t("no_annot")
                : t("no_annot"),
            2:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.validation?.validated == true
                  ? item?.annotations[0]?.validation?.is_valid == true
                    ? t("valid")
                    : `${t("invalid")} (${item?.annotations[0]?.validation
                      ?.comment || t("no_cmnts")
                    })`
                  : t("no_val")
                : t("no_val"),
            3: { button: "View Annotations" }
          }); // tempTextLists
        });
        setQuestions(tempQuestions);
        setTextLists(tempTextLists);
        setTextListTotal(res?.document_count);
        setTotalAnnotated(res?.total_annotated)
        setTotalValidated(res?.total_validated)
      }
      if (res?.task_type == "text_transcription") {
        localStorage.setItem("textType", "text_transcription");
        let tempTextLists = [];

        res?.documents.map((item) => {

          tempTextLists.push({
            0: {
              image: AppImage.file_n,
              text: item?.paragraph?.length > 30 ? item?.paragraph?.substring(0, 30) + "..." : item?.paragraph,
              tooltip: item?.paragraph,
              ar: containsArabic(item?.paragraph),
              id: item?.id,
              annotations: item?.annotations,
            },
            1:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.text
                : t("no_annot"),
            2:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.validation?.validated == true
                  ? item?.annotations[0]?.validation?.is_valid == true
                    ? t("valid")
                    : `${t("invalid")} (${item?.annotations[0]?.validation
                      ?.comment || t("no_cmnts")
                    })`
                  : t("no_val")
                : t("no_val"),
            3: { button: "View Annotations" }
          }); // tempTextLists
        });
        setTextLists(tempTextLists);
        setTextListTotal(res?.document_count);
      }
    } // text
    // audio
    if (res?.task_type == 'transcription') {
      localStorage.setItem("taskType", "Audio");
      // isInternetConnected() &&
      //   dispatch(disputeListing(payloadForDisputedAnnotations)); // dispute annot listing
      // let tempAudioArray = [];
      let tempAudioLists = [];
      // let docs = [];

      res?.documents.map((item) => {
        let audioFileName = item?.path.includes("/") ? item?.path.split("/") : item?.path.split("\\");
        let audioDetails = {};

        audioDetails["jsonFile"] = item?.annotations || [];
        audioDetails["audioUrl"] = item?.url;

        audioDetails["task_id"] = res?.id;
        audioDetails["workerId"] = res?.worker_id; //res?.data?.workers[0].id;
        audioDetails["validator_id"] = res?.data?.validators[0].id;
        audioDetails["annotationId"] = item?.id;
        tempAudioLists.push({
          0: {
            image: AppImage.volumeFileImg,
            text: audioFileName[audioFileName.length - 1],
            tooltip: item?.url,
            ar: false,
            annotations: item?.annotations,
          },
          1:
            item?.annotations?.length > 0
              ? item?.annotations[0]?.annotation.length > 0
                ? (item?.annotations[0].annotation.map((annot) => {
                  return `${item?.annotations[0].user?.name}: ${annot?.text}`;
                }) || []).join(" | ")
                : t("no_annot")
              : t("no_annot"),
          2: t("no_val"),
          3: { button: "View Annotations" }
        });
      });
      setAudioLists(tempAudioLists);
      // setDocuments(docs);
      setAudioListTotal(res?.document_count);
      setTotalAnnotated(res?.total_annotated)
      setTotalValidated(res?.total_validated)
    } // audio
    // workers
    setTotalWorkers(res?.annotators?.length);
    let tempWorker = [];
    res?.annotators?.
      sort((a, b) => {
        return b.annotated - a.annotated;
      }).
      map((item) => {
        tempWorker.push({
          image: item?.image,
          name: item?.full_name,
          email: item?.email,
          phNumber: item?.phone_number, // report, remove
          id: item?.id,
          assigned: item?.assigned,
          annotated: item?.annotated,
          quotas: item?.quotas,
        });
      });
    setDataSourceWorker(tempWorker);
    let total_today = 0;
    let annotationDates = [];
    let hourCounts = {};
    let dayCounts = {};

    let today = new Date().getDate();
    res?.annotations_date?.map((item) => {
      // convery iso time to day/month
      let date = new Date(item?.date);
      let day = date.getDate();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let hour = date.getHours();

      if (day == today) {
        total_today += item?.count;
      }

      if (hourCounts[hour]) {
        hourCounts[hour] += item?.count;
      } else {
        hourCounts[hour] = item?.count;
      }

      if (dayCounts[day]) {
        dayCounts[day] += item?.count;
      } else {
        dayCounts[day] = item?.count;
      }

      annotationDates.push({
        date: `${hour} ${day} /${month}/${year} `,
        day: day,
        year: year,
        month: month,
        hour: hour,
        count: item?.count,
      });
    });


    setAnnotationDate({
      avg_hour: Object.values(hourCounts).reduce((a, b) => a + b, 0) / Object.values(hourCounts).length,
      avg_day: Object.values(dayCounts).reduce((a, b) => a + b, 0) / Object.values(dayCounts).length,
      total_today: total_today,
      dates: annotationDates,
    });
    // invited workers and validators
    let tempWorkerInvitaionList = [];

    res?.invite_list?.map((item) => {

      tempWorkerInvitaionList.push({
        0: item?.full_name,
        1: item?.email,
        2: item?.invite_type,
        3: item?.is_accepted,
        4: new Date(item?.invite_date).toLocaleDateString() + " " + new Date(item?.invite_date).toLocaleTimeString(),
        5: item?.is_accepted == 'pending' ? {
          button: "Remove Invite",
          id: item?.email,
        } : t("already_invited"),
      });
    });
    setWorkerInvitaionList(tempWorkerInvitaionList);
    dispatch(stopLoading());
  };
  const bulkInvite = () => {
    const invitesListPayload = {
      body: {
        taskId: taskId,
        invites: showValidatorLists.map((item) => {
          return {
            email: item.email,
            invite_type: item.type[0],
          };
        }
        ),
      },
      success: (res) => {
        let tempWorkerInvitaionList = [];
        res?.map((item) => {
          tempWorkerInvitaionList.push({
            0: item?.email,
            1: item?.invite_type,
            2: item?.is_accepted,
            3: new Date(item?.invite_date).toLocaleDateString() + " " + new Date(item?.invite_date).toLocaleTimeString(),
          });
        });
        setWorkerInvitaionList(tempWorkerInvitaionList);
        setIsModalVisiblePool(false);
        toast.success(t("invite_success"), {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      },
    }
    isInternetConnected() && dispatch(inviteToTask(invitesListPayload));
  }
  /* drop down */
  const payload = {
    body: {
      taskId,
      type: "owner",
      pageNumber: 1,
      pageSize: 10,
    },
    success: (res) => {
      fillTaskDetails(res);
    },
    fail: (resf) => {
      resf?.data && toast.error(resf?.data, {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
      resf?.message && toast.error(resf?.message, {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
    },
  }; //  task details info payload

  useEffect(() => {
    document.title = "WosoM";
    window.addEventListener("resize", () => { setWidth(window.innerWidth); });
    dispatch(saveTaskId(taskId));
    isInternetConnected() && dispatch(getTaskDetails(payload)); // task deatils
  }, []);//lang
  const userPayload = {
    body: {
      task_id: taskId,
      search: searchValue,
      pageNum: pageNum,
      language: languageVal == 'all' ? null : languageVal,
    },
    success: (res) => {
      setTotalUsers(res?.total);
      const modifiedRes = [];
      res?.data?.filter((item) => {
        modifiedRes.push({
          id: item.id,
          email: item.email,
          profile: item.image
            ? item.image
            : AppImage.defaultImage,
          name: item.first_name + " " + item.last_name,
          rating: item.rating || 0,
          languages: item.languages,
          skills: item.skills,
          tickTrue: false,
        });
      });
      localStorage.setItem("tempArr", JSON.stringify(modifiedRes));
      setCurrentlyActive(modifiedRes);
    },
  };
  const inviteListPayload = {
    body: {
      taskId: taskId,
    },
    success: (res) => {
      let tempWorkerInvitaionList = [];
      res?.map((item) => {

        tempWorkerInvitaionList.push({
          0: item?.email,
          1: item?.invite_type,
          2: item?.is_accepted,
          3: new Date(item?.invite_date).toLocaleDateString() + " " + new Date(item?.invite_date).toLocaleTimeString(),
        });
      });

      setWorkerInvitaionList(tempWorkerInvitaionList);
    },
  }
  useEffect(() => {
    const languagePayload = {
      body: {},
      success: (res) => {
        // add all the languages to first index
        res.unshift({
          code: "all",
          name: t("all"),
        });
        setLanguageChildren(res);
        setLanguageVal(res[0].code);
        setRatingVal("0-1");
      },
    }
    isInternetConnected() && dispatch(getLanguageList(languagePayload));

    isInternetConnected() && dispatch(getWorkersValidatorsList(userPayload));
  }, [isModalVisiblePool, languageVal, pageNum, searchValue]);
  // USE-EFFECT
  return (
    <Container
      {...containerProps}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >

      <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
        {taskInfo?.taskName !== "" ? (
          <Card
            className="border-radius-lg"
            bodyStyle={{
              padding: "10px",
            }}
            style={{
              marginBottom: "20px",
              marginTop: "30px",
            }}>

            <div style={{
              display: "flex",
              flexDirection: width > 768 ? "row" : "column",
              justifyContent: "space-between",
            }} >
              {/* name */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}>

                <div style={{
                  fontSize: "1.2rem",
                  color: "#214c7d",
                  // fontWeight: "600",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}>
                  <b>{taskInfo?.taskName}</b>
                </div>
                <div style={{
                  fontSize: "1rem",
                  color: "#3a3a3a",
                  // fontWeight: "600",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}>
                  <b>{t("description")}</b>: {taskInfo?.description}
                </div>
                <div style={{
                  fontSize: "1rem",
                  color: "#3a3a3a",
                  // fontWeight: "600",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}>
                  <b>{t("task_type")}</b>: {taskInfo?.taskType}
                </div>
                {taskInfo?.taskTypeVal == "classification" && (
                  <div style={{
                    fontSize: "1rem",
                    color: "#3a3a3a",
                    // fontWeight: "600",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}>
                    <b>{t("questions")}</b>:  {questions?.length} <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        setIsModalVisibleQuestions(true);
                      }}
                    >
                      <EyeOutlined />
                    </button>
                  </div>
                )}
              </div>
              {/* stat cards */}
              <div
                className={width > 1000 ? "" : "card"}
                style={{
                  display: "flex",
                  marginTop: "10px",
                  flexDirection: width > 1000 ? "row" : "column",
                }}>
                {statItems.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={width > 1000 ? " card" : ""}
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <h1 style={{
                        fontSize: "1rem",
                        color: "#214c7d",
                        fontWeight: "600",
                      }}>{item.title}</h1>
                      <div className="stats" style={{ display: "flex" }}>
                        {item.items.map((item, index2) => {
                          return (
                            <div
                              key={index2}
                              style={{
                                position: "relative",
                              }}
                              className={width > 1000 ? " stat-card" : "stat-card-mb"}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <h3
                                  style={{
                                    fontSize: "1.5rem",
                                    color: "#214c7d",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item.value}
                                  {item.sub && (
                                    <p
                                      style={{
                                        position: "absolute",
                                        bottom: "-10px",
                                        left: "40%",
                                        fontSize: "0.85rem",
                                        color: "#838383",
                                      }}
                                    >
                                      {item.sub}
                                    </p>
                                  )}
                                </h3>

                                <p
                                  style={{
                                    fontSize: width > 768 ? "1rem" : "0.85rem",
                                    color: "#3a3a3a",
                                    padding: "0px 10px",
                                  }}
                                >
                                  {item.label}
                                </p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Card>) :
          <Skeleton style={{
            padding: "10px",
          }} active />
        }
      </CardHoc>

      <Collapse
        defaultActiveKey={["1"]}
        style={{
          padding: "10px",
        }}
      >
        {/* task data */}
        <Panel header={t("task_data")} key="1">
          {taskInfo?.taskName !== "" ? (
            <div className="collapse-details">
              <Row>
                <Col xs={24} md={12} lg={16}>
                  <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                    <Card
                      className="border-radius-lg "
                      style={{
                        marginBottom: "20px",
                      }}>
                      <div style={{
                        fontSize: "1.2rem",
                        color: "#214c7d",
                        // fontWeight: "600",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}>
                        {t("instructions")}
                      </div>
                      <div
                        className="get-original-text-color"
                        style={{
                          marginTop: "12px",
                          padding: "1%",
                          minHeight: "350px",
                        }}
                      >
                        <div className="ck-content">

                          {taskInfo?.instruction ? (
                            parse(taskInfo?.instruction)
                          ) : (
                            <p style={{ color: "#acb2ba" }}>{t('inst_wil_appear_here')}</p>
                          )}
                        </div>
                      </div>
                    </Card>
                  </CardHoc>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                    <Card className="border-radius-lg "
                      style={{
                        marginBottom: "20px",
                      }}>
                      <div style={{
                        fontSize: "1.2rem",
                        color: "#214c7d",
                        // fontWeight: "600",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}>
                        {t("annotations")}
                      </div>
                      <div
                        style={{
                          marginTop: "12px",
                          padding: "1%",
                          minHeight: "300px",
                        }}
                      >
                        {taskInfo?.latest_documents && taskInfo?.latest_documents.length > 0 ? (
                          <Timeline
                            style={{
                              margin: "3% 4% 0% 4%",
                              padding: "2%",
                              maxHeight: "350px",
                              overflowY: "scroll",
                            }}
                          >
                            {taskInfo?.latest_documents.map((item, index) => {
                              return (
                                <Timeline.Item
                                  key={index}
                                  dot={item?.dot ? item?.dot : null}
                                  color={item?.color ? item?.color : "blue"}
                                >
                                  {item?.children}
                                </Timeline.Item>
                              );
                            })}
                          </Timeline>
                        ) : (
                          <p style={{ color: "#acb2ba" }}>{t('ann_wil_appear_here')}</p>
                        )}
                      </div>
                    </Card>
                  </CardHoc>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={8} lg={10}>
                  <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                    <Card className="border-radius-lg "
                      style={{
                        marginBottom: "20px",
                      }}>
                      <div style={{
                        fontSize: "1.2rem",
                        color: "#214c7d",
                        // fontWeight: "600",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}>
                        {t("workers")}
                      </div>
                      <div
                        style={{
                          marginTop: "12px",
                          padding: "1%",
                          minHeight: "300px",
                        }}
                      >
                        {dataSourceWorker?.length > 0 ? (
                          <WorkersBar workers={dataSourceWorker} />
                        ) : (
                          <p style={{ color: "#acb2ba" }}>{t('ann_wil_appear_here')}</p>
                        )}

                      </div>
                    </Card>
                  </CardHoc>
                </Col>
                <Col xs={24} md={8} lg={14}>
                  <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                    <Card className="border-radius-lg "
                      style={{
                        marginBottom: "20px",
                      }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: width > 768 ? "row" : "column",
                        }}>
                        <div style={{
                          fontSize: "1.2rem",
                          color: "#214c7d",
                          // fontWeight: "600",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}>
                          {t("timeline")}
                        </div>
                        <div
                          className="card card-sm"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "10px",
                            marginTop: "10px",
                          }}>
                          <div
                            style={{
                              position: "relative",
                              padding: "0 12px",
                            }}
                            className="stat-card"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <h3
                                style={{
                                  fontSize: "1rem",
                                  color: "#214c7d",
                                  fontWeight: "600",
                                }}
                              >
                                {annotationDate?.avg_day?.toFixed(2)}
                              </h3>
                              <p
                                style={{
                                  fontSize: "0.85rem",
                                  color: "#3a3a3a",
                                }}
                              >
                                {t("avg_day")}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "relative",
                              padding: "0 12px",
                            }}
                            className="stat-card"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <h3
                                style={{
                                  fontSize: "1rem",
                                  color: "#214c7d",
                                  fontWeight: "600",
                                }}
                              >
                                {annotationDate?.avg_hour?.toFixed(2)}
                              </h3>
                              <p
                                style={{
                                  fontSize: "0.85rem",
                                  color: "#3a3a3a",
                                }}
                              >
                                {t("avg_hour")}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "relative",
                              padding: "0 12px",
                            }}
                            className="stat-card"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <h3
                                style={{
                                  fontSize: "1rem",
                                  color: "#214c7d",
                                  fontWeight: "600",
                                }}
                              >
                                {annotationDate?.total_today}
                              </h3>
                              <p
                                style={{
                                  fontSize: "0.85rem",
                                  color: "#3a3a3a",
                                }}
                              >
                                {t("total_today")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "12px",
                          padding: "1%",
                          minHeight: "500px",
                        }}
                      >
                        {annotationDate?.dates?.length > 0 ? (
                          <TimelineBar dates={annotationDate?.dates} />
                        ) : (
                          <p style={{ color: "#acb2ba" }}>{t('ann_wil_appear_here')}</p>
                        )}

                      </div>
                    </Card>
                  </CardHoc>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24} lg={24}>
                  <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                    <Card className="border-radius-lg "
                      style={{
                        marginBottom: "20px",
                      }}>
                      <div style={{
                        fontSize: "1.2rem",
                        color: "#214c7d",
                        // fontWeight: "600",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}>
                        {t("data_browser")}
                      </div>
                      <div
                        style={{
                          marginTop: "12px",
                          padding: "1%",
                          minHeight: "700px",
                        }}
                      >
                        <Row
                          className="card"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginLeft: "10px",
                            marginRight: "10px",
                            padding: "10px",
                          }}
                        >
                          {filterItems.map((item, index) => {
                            return (
                              <Col
                                key={index}
                                style={{
                                  position: "relative",
                                }}
                                className="stat-card"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <CustomHelp
                                    title={item.title}
                                    popover={item.popover} />
                                  <div
                                    style={{
                                      padding: "10px",
                                    }}>
                                    {item.value}
                                  </div>
                                </div>
                              </Col>
                            )
                          })}
                          <div
                            style={{
                              padding: "0 24px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="btn btn-lg btn-primary"
                            onClick={() => {
                              generateSample();
                            }}
                          >
                            <div
                              style={{
                                padding: "10px",
                              }}>
                              <EyeOutlined style={{
                                fontSize: "2rem",
                              }} />
                            </div>
                          </div>
                        </Row>
                        <div
                          style={{
                            marginTop: "12px",
                            padding: "1%",
                            minHeight: "700px",
                            borderRadius: "10px",
                          }}
                        >
                          {/* class lists and audio lists */}
                          {taskInfo.taskTypeVal == "bbox" ||
                            taskInfo.taskTypeVal == "polygon" ? (
                            classLists.length > 0 ? (
                              <CustomTable
                                {...dropDownGroup[0].table}
                                handleClick={(label) => {
                                  switch (label) {
                                    case "View Annotations": {
                                      setAnnotationDS(<>
                                        <p>
                                          {item?.paragraph}
                                        </p>
                                        <p>
                                          <b>{ann?.user?.name}</b> | <b>{ann?.annotation[0]?.answer}</b>
                                        </p>
                                      </>)
                                      setIsModalVisible(true);
                                      break;
                                    }

                                    default:
                                      break;
                                  }
                                }}
                              />
                            ) : (
                              <CustomEmptyDiv title={t("clss_appear_here")} />
                            )
                          ) : (
                            audioLists.length > 0 || textLists.length > 0
                              ? (
                                <CustomTable
                                  {...dropDownGroup[1].table}
                                  pagination={true}
                                  paginationVisible={false}
                                  handleClick={(label, id, link, row2) => {
                                    switch (label) {
                                      case "View Annotations": {
                                        let dt = row2?.[0];

                                        switch (taskInfo?.taskTypeVal) {
                                          case "text_transcription":
                                          case "classification":
                                          case "segmentation":
                                            setAnnotationDS(
                                              <>
                                                <p>
                                                  {dt?.tooltip}
                                                </p>
                                                {dt?.annotations?.length > 0 && dt?.annotations?.map((item, index) => {
                                                  return (<p key={index}>
                                                    <b>{item?.user?.name}</b> | <b>{item?.annotation?.[0]?.answer}</b>
                                                  </p>)
                                                })}
                                              </>
                                            )
                                            break;
                                          case "transcription":

                                            setAnnotationDS(
                                              <>
                                                <p>
                                                  <AudioPlayer url={dt?.tooltip} ref={currentAudioRef} loaded={Math.floor(Math.random() * 1000)} />
                                                </p>
                                                {dt?.annotations?.length > 0 && dt?.annotations?.map((item, index) => {
                                                  return (<p key={index}>
                                                    <b>{item?.user?.name}</b> | {item?.annotation?.[0]?.text}
                                                  </p>)
                                                })}
                                              </>
                                            )
                                            break;
                                        }
                                        setIsModalVisible(true);
                                        break;
                                      }

                                      default:
                                        break;
                                    }
                                  }}
                                />
                              ) : (
                                <CustomEmptyDiv title={`${taskInfo?.taskType == 'audio' ? t('audio_list') : t('txt_list')}  ${t('wil_appear_here')}.`} />
                              )
                          ) // Audio
                            // ) : null
                          }
                        </div>
                      </div>
                    </Card>
                  </CardHoc>
                </Col>
              </Row>
            </div>
          ) : (
            <Skeleton style={{
              padding: "10px",
            }} active />
          )}
        </Panel>
        {/* task managment */}

      </Collapse>
      <Collapse defaultActiveKey={["1"]}
        style={{
          padding: "10px",
        }}
      >
        <Panel header={t("task_managment")} key="1" style={{ marginBottom: "10px" }}>
          {taskInfo?.taskName !== "" ? (
            <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
              <Card
                className="border-radius-lg "
                style={{
                  marginBottom: "20px",
                }}>
                {/* workers list start*/}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <div style={{
                    fontSize: "1.2rem",
                    color: "#214c7d",
                    // fontWeight: "600",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}>
                    {t("wrkr_list")}
                  </div>
                  <button
                    style={{
                      marginLeft: 15,
                      padding: "5px 15px",
                      minWidth: "max-content",
                    }}
                    type="button" onClick={() => download_csv("workers")}
                    className="button-list bottom_button pt-10 focus"
                  >
                    <CloudDownloadOutlined style={{
                      fontSize: "1.2rem",
                      marginRight: "5px",
                    }}

                    />   {t("export_wrkr_list")}
                  </button>
                </div>
                <CardHoc
                  cardInnerPadding={"content-e"}
                  cardPadding={"0px"}
                  minHeight={0}
                >
                  {dataSourceWorker.length != 0 ? (
                    <div id="paginationOfgroup">
                      <Table
                        className="table table-sm"
                        dataSource={dataSourceWorker}
                        columns={columnsWorkers}
                        pagination={false}
                        showSorterTooltip={false}
                        onChange={(pagination, filters, sorter) => {

                          if (sorter.order == undefined) {
                            setOrdering("");
                          }

                          if (
                            sorter.order == "ascend" &&
                            sorter.order != undefined &&
                            sorter.columnKey == "name"
                          ) {
                            setOrdering("first_name");
                          }
                          if (
                            sorter.order == "descend" &&
                            sorter.order != undefined &&
                            sorter.columnKey == "name"
                          ) {
                            setOrdering("-first_name");
                          }

                          if (
                            sorter.order == "ascend" &&
                            sorter.order != undefined &&
                            sorter.columnKey == "phNumber"
                          ) {
                            setOrdering("phone_no");
                          }
                          if (
                            sorter.order == "descend" &&
                            sorter.order != undefined &&
                            sorter.columnKey == "phNumber"
                          ) {
                            setOrdering("-phone_no");
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <CustomEmptyDiv title={t('wrkr_appear_here')} />
                  )}
                </CardHoc>
                {taskStatus != "Completed" && taskStatus != "" ? (
                  <div style={{
                    display: "flex",
                    flexDirection: width > 1000 ? "row" : "column",
                  }}>
                    {/* Add worker button */}
                    <button
                      style={{

                        minWidth: "max-content",
                        cursor: taskInfo.quotas?.filter(item => item?.assigned == false).length == 0 ? "not-allowed" : "pointer"
                      }}
                      type="button"
                      disabled={taskInfo.quotas?.filter(item => item?.assigned == false).length == 0}
                      className={"button-list bottom_button pt-10 focus " + (width > 1000 ? "" : "btn-block")}
                      onClick={() => {
                        setIsModalVisiblePool(true);
                      }}
                    >
                      {t("select_frm_pool")}
                    </button>
                    {/* Add add_wrkr_thr_email button */}
                    <button
                      style={{
                        minWidth: "max-content",
                        cursor: taskInfo.quotas?.filter(item => item?.assigned == false).length == 0 ? "not-allowed" : "pointer"
                      }}
                      type="button"
                      disabled={taskInfo.quotas?.filter(item => item?.assigned == false).length == 0}
                      className={"button-list bottom_button pt-10 focus " + (width > 1000 ? "" : "btn-block")}
                      onClick={() => {
                        setIsModalVisibleEmail(true);
                      }}
                    >
                      {t("add_wrkr_thr_email")}
                    </button>
                  </div>
                ) : null}


                {/* invited workers and disputed annotations start */}
                {dropDownGroup2.map((item, index) => {
                  return (
                    <Collapse defaultActiveKey={["1"]} key={index}>
                      <Panel id={index} header={item.table.placeholder} key="1">
                        {item?.table?.listLength ? (
                          <CustomTable
                            tableClassName="table table-sm"
                            defaultActiveKey={["1"]}
                            {...item.table}
                            handleClick={(label, id) => {
                              switch (label) {
                                case "View": {
                                  localStorage.setItem("disputeId", id?.id);
                                  localStorage.setItem(
                                    "others",
                                    JSON.stringify(id?.others)
                                  );
                                  navigate(NavigateTo.DISPUTE_ANNOTATION + `/ ${id?.id}`);
                                  break;
                                }
                                case "Report":
                                  setIsModalReport(true);
                                  break;
                                case "Remove":
                                  setIsModalRemove(true);
                                  break;
                                case "Remove Invite":
                                  setUserRemovalId(id);
                                  setUserTypeRemove("annotator");
                                  setIsModalRemoveInvite(true);
                                  break;
                                default:
                                  break;
                              }
                            }}
                          />
                        ) : (
                          <CustomEmptyDiv
                            title={`${t(item?.table?.tableName)} ${t('wil_appear_here')}.`}
                            marginBottom={0}
                            margin={"25px"}
                          />
                        )}
                      </Panel>
                    </Collapse>
                  );
                })}
                {/* invited workers and disputed annotations end */}
              </Card>
              {/* workers list end*/}
            </CardHoc>) :
            <Skeleton style={{
              padding: "10px",
            }} active />
          }
          {/* pool of worker modal start*/}
          <Modal
            maskClosable={false}
            title={t("pool_of_wrkr")}
            open={isModalVisiblePool}
            onOk={() => {
              setIsModalVisiblePool(false);

              localStorage.removeItem("selectedFromPool");
            }}
            onCancel={() => {
              setIsModalVisiblePool(false);
              setDefaultCurrent(1);
              localStorage.removeItem("selectedFromPool");
            }}
            wrapClassName="poolworkers"
            footer={null}
            width={800}
            className={
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en"
            }
            centered
          >
            <div className="text-center  model1">
              <div className="form-group wrapper_line models">
                {/* Select From Currently Active */}
                <div className="new_new_data">
                  <div
                    className="top_data_1"
                    style={{ direction: localStorage.lang == "en" ? "ltr" : "rtl" }}
                  >
                    {/* search input */}
                    <div className="button_left contain">
                      {/* search bar */}
                      <input
                        type="text"
                        autoComplete="off"
                        className="search-bar"
                        placeholder={t("search")}
                        value={formik.values.searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setPageNum(1);
                        }}
                      />
                      {/* search bar */}
                    </div>

                    <Select
                      placeholder={t("rating")}
                      value={ratingVal}
                      onChange={(val) => {
                        setRatingVal(val);
                      }}
                    >
                      <Option value="0-1">0-1</Option>
                      <Option value="1-2">1-2</Option>
                      <Option value="2-3">2-3</Option>
                      <Option value="3-4">3-4</Option>
                      <Option value="4-5">4-5</Option>
                    </Select>
                    <Select
                      placeholder={t("lang")}
                      onChange={(val) => {
                        setLanguageVal(val);
                      }}
                      value={languageVal}
                    >
                      {languageChildren.map((item) => {
                        return <Option key={item.code} value={item.code}>{item.name}</Option>;
                      })}
                    </Select>
                  </div>

                  {/* select from currently active  */}

                  {
                    currentlyActive.length > 0 ? (
                      <>
                        <div>
                          <div className="data_news poolworkers_ppop" style={{
                            marginTop: "20px",
                          }}>
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "15px",
                              fontWeight: "800",
                              borderBottom: "1px solid rgb(20 60 100 / 44%)",
                            }}>
                              <label className="bold">{t("worker_name")}</label>
                              <label className="bold">{t("email")}</label>
                              <label className="bold">{t("rating")}</label>
                              <label className="bold">{t("languages")}</label>
                              <label className="bold">{t("actions")}</label>
                            </div>
                            {
                              currentlyActive.map((item, index) => {
                                return (
                                  // row //
                                  <div className="sperator_pool" key={index}>
                                    {/* worker profile img */}
                                    <div className="first_text">

                                      <img
                                        src={item.profile}
                                        style={{
                                          width: "27px",
                                          height: "27px",
                                          borderRadius: "27px",
                                        }}
                                      ></img>
                                    </div>

                                    {/* worker name */}
                                    <div
                                      className="first_text"
                                      style={{
                                        width: "215px",
                                        paddingLeft: "5px",
                                        textAlign: "start",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      {item?.skills?.length > 0 ? (
                                        <CustomHelp title={item.email} popover={
                                          <>
                                            <h3 className="bold">{t("skills")}</h3>
                                            <Table
                                              pagination={true}
                                              columns={skillCol}
                                              showSorterTooltip={false}
                                              dataSource={userskillDataSource}
                                            />
                                          </>
                                        }
                                          popoverWidth={userskillDataSource?.length ? "600px" : "300px"}
                                          popoverIcon={AppImage.info}
                                          onClick={() => {
                                            setUserskillDataSource(item.skills);
                                          }}
                                        />) : <Tooltip title={item.email}>
                                        {item.name.length > 15 ? item.name.substring(0, 15) + "..." : item.name}
                                      </Tooltip>
                                      }
                                    </div>
                                    {/* rating  */}
                                    <div>
                                      <Rate
                                        style={{
                                          fontSize: "20px",
                                        }}
                                        className="edit-group-star"
                                        allowHalf
                                        value={item.rating}
                                        disabled={true}
                                      />
                                    </div>
                                    {/* Languaes */}
                                    <div
                                      className="first_text"
                                      style={{
                                        width: "215px",
                                        paddingLeft: "5px",
                                        textAlign: "start",
                                        paddingRight: "5px",
                                      }}>
                                      {item.languages && item.languages.length > 0 ? (
                                        <Tooltip
                                          title={item.languages.map(item => { return item.name }).join(", ")}
                                        >
                                          {item.languages.slice(0, 2).map((lang, index) => {
                                            return (
                                              <span
                                                key={index}
                                                style={{
                                                  fontSize: "12px",
                                                  color: "#214c7d",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {lang.name} {" "}
                                              </span>
                                            );
                                          })}
                                        </Tooltip>
                                      ) :
                                        <div className="first_text"
                                          style={{
                                            width: "215px",
                                            paddingLeft: "5px",
                                            textAlign: "start",
                                            paddingRight: "5px",
                                          }}>{t("not_specified")}</div>
                                      }
                                    </div>
                                    {/* tick for selected workers */}
                                    {!replaceUser ? (
                                      <div
                                        className={
                                          localStorage.getItem("lang") == "en"
                                            ? "star_new"
                                            : "star_new ar"
                                        }
                                      >
                                        <Tooltip
                                          title={t("select_as_worker")}
                                        >
                                          <Button
                                            type="primary"
                                            shape="round"
                                            icon={<TagsOutlined />}
                                            size="large"
                                            className={
                                              showValidatorLists.filter(
                                                (i) =>
                                                  i.id == item.id &&
                                                  i.type.includes("annotator")
                                              ).length > 0
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              updateWorker(item, "annotator");
                                            }}
                                          />
                                        </Tooltip>
                                        {/* 
                                        <Tooltip
                                          title={t("select_as_validator")}
                                        >
                                          <Button
                                            type="primary"
                                            shape="round"
                                            icon={<SafetyOutlined />}
                                            size="large"
                                            className={
                                              showValidatorLists.filter(
                                                (i) =>
                                                  i.id == item.id &&
                                                  i.type.includes("validator")
                                              ).length > 0
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() => {
                                              updateWorker(item, "validator");
                                            }}
                                          />
                                        </Tooltip> */
                                        }
                                      </div>
                                    ) : (
                                      <div
                                        className={
                                          localStorage.getItem("lang") == "en"
                                            ? "star_new"
                                            : "star_new ar"
                                        }
                                      >
                                        <Tooltip
                                          title={t("replace")}
                                        >
                                          <Button
                                            type="primary"
                                            shape="round"
                                            icon={<RetweetOutlined />}
                                            size="large"
                                            className={"active"}
                                            onClick={() => {
                                              setUserReplaceId({
                                                name: item.name,
                                                email: item.email,
                                              });
                                              setIsModalReplace(true);
                                            }}
                                          />
                                        </Tooltip>
                                      </div>
                                    )}

                                  </div>
                                );
                              })}
                          </div>
                          <Pagination
                            showTitle={false}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              left: "0",
                              right: "0",
                              paddingTop: "30px",
                              paddingBottom: "30px",
                              maxWidth: "fit-content",
                              position: "absolute",
                            }}
                            className={
                              localStorage.lang == "ar"
                                ? "pagination-component ar"
                                : "pagination-component"
                            }
                            defaultCurrent={defaultCurrent}
                            current={pageNum}
                            total={totalUsers}
                            size="medium"
                            onChange={(item) => {
                              setPageNum(item);
                            }}
                          />
                        </div>
                        {!replaceUser && (
                          <Button
                            type="primary"
                            shape="round"
                            size="large"
                            className="button-list btn active"
                            style={{
                              marginTop: "30px",
                              position: "relative",
                              // left: "50%",
                              bottom: "-50px",
                            }}
                            onClick={() => {
                              bulkInvite();
                            }}
                          >
                            {t("invite")}
                          </Button>
                        )}

                      </>
                    ) : (
                      <CustomEmptyDiv marginTop={"30px"} title={t("no_user_found")} />
                    )}
                </div>
              </div>
            </div>
            {/* add button */}
          </Modal>
          {/* pool of worker modal end*/}
          {/* pool of email modal start*/}
          <Modal
            maskClosable={false}
            title={t("add_wrkr_thr_email_adrs")}
            open={isModalVisibleEmail}
            onOk={() => {
              setIsModalVisibleEmail(false);
            }}
            onCancel={() => {
              setIsModalVisibleEmail(false);
            }}
            wrapClassName="poolworkers"
            footer={null}
            width={600}
            className={
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en"
            }
            centered
          >
            <div className="text-center  model1">
              <div className="wrapper_line models">
                <div
                  style={{
                    direction: localStorage.lang == "en" ? "ltr" : "rtl",
                    display: "flex",
                    justifyContent: "flex-start",
                    // alignItems: "center",
                  }}
                >
                  {/* Email input */}
                  <div className="form-group" style={{ marginRight: "10px", width: "30vw" }}>
                    <label>{t("email")}</label>
                    <input
                      type="email"
                      className={
                        localStorage.lang == "en"
                          ? "form-control"
                          : "form-control ar"
                      }
                      placeholder={t("email")}
                      value={formik.values.inviteEmail}
                      onChange={(e) => {
                        formik.setFieldValue("inviteEmail", e.target.value);
                      }}
                      autoComplete="off"
                    />
                    {formik.errors.inviteEmail ? (
                      <div
                        className={
                          localStorage.getItem("lang") == "ar"
                            ? "error ar"
                            : "error en"
                        }
                        style={{ paddingLeft: "17px" }}
                      >
                        {formik.errors.inviteEmail}
                      </div>
                    ) : null}
                  </div>
                  {/* type select */}
                  <div className="form-group ">
                    <label>{t("invite_type")}</label>
                    <div className="top_data_1">
                      <Select
                        placeholder={t("invite_type")}
                        onChange={(val) => {
                          formik.setFieldValue("inviteType", val);
                        }}
                        defaultValue={"annotator"}
                      >
                        <Option value="annotator"> <TagsOutlined /> {t("annotator")}</Option>
                        <Option value="validator"> <SafetyOutlined /> {t("validator")}</Option>
                        <Option disabled value="modirator"> <UsergroupAddOutlined /> {t("moderator")}</Option>
                      </Select>

                    </div>
                    {formik.errors.inviteType ? (
                      <div
                        className={
                          localStorage.getItem("lang") == "ar"
                            ? "error ar"
                            : "error en"
                        }
                        style={{ paddingLeft: "17px" }}
                      >
                        {formik.errors.inviteType}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* add button */}
                <div className="text-center  model1">
                  <button
                    type="submit"
                    className="button-list focus"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    {t("add")}
                  </button>
                  {/* <button type="submit" onClick={() => handleCancel(false)}>Cancel</button> */}
                </div>
              </div>
            </div>
          </Modal >
          {/* pool of email modal end*/}

          {/* class / audio annot modal */}
          <AnnotationModal
            // image class details
            classNames={classNames}
            imageArray={imageArray}
            // imageIndex={imageIndex}
            // annotDetails={annotDetails}
            // annotationDS={annotationDS}
            // audio details
            // annotationAudioDS={annotationAudioDS}
            // text
            // annotationTextDS={annotationTextDS}
            classLists={classLists}
            // projId={projId}
            isModalVisible={false}
            hideModal={(hide) => {
              setIsModalVisible(hide);
            }}
          />

          {/* view modal */}
          <Modal
            title={t("view")}
            width={800}
            open={isModalVisible}
            footer={[]}
            onCancel={() => {
              setIsModalVisible(false);
              currentAudioRef.current.onUnload();
            }}
            style={{
              direction: localStorage.lang === "ar" ? "rtl" : "ltr",
              textAlign: localStorage.lang === "ar" ? "right" : "left",
            }}
          >
            {annotationDS}
          </Modal>
          {/* report modal */}
          <ReportModal
            isReport={isReport}
            hideModal={(hide) => {
              setIsModalReport(hide);
            }}
          />
          {/* remove modal */}
          <RemoveModal />
          <ReplaceModal />
          <RemoveInviteModal />

          {/* questions modal */}
          <Modal
            maskClosable={false}
            title={taskInfo?.taskTypeVal == 'classification' ? t("questions") : t("classes")}
            open={isModalVisibleQuestions}
            onOk={() => {
              setIsModalVisibleQuestions(false);
            }}
            onCancel={() => {
              setIsModalVisibleQuestions(false);
            }}
            wrapClassName="poolworkers"
            footer={null}
            width={1200}
            className={
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en"
            }
            centered
          >
            {taskInfo?.taskTypeVal == 'classification' ? <> {questions?.length > 0 && (
              <div className="wrapper_line form_wrapper remove-horizontal-space">
                <div style={{
                  marginTop: "12px",
                }}>
                  <label style={leftFontstyle}>{t("qn_lists")}</label>
                  <div className="inner_box_box">
                    <Table
                      dataSource={questions}
                      columns={columnsForQuestions}
                      showSorterTooltip={false}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            )}</> : <>
              {classLists.length > 0 ? (
                <CustomTable
                  {...dropDownGroup[0].table}
                  handleClick={(label) => {
                    switch (label) {
                      case "View Annotations": {
                        setAnnotationDS(<>
                          <p>
                            {item?.paragraph}
                          </p>
                          <p>
                            <b>{ann?.user?.name}</b> | <b>{ann?.annotation[0]?.answer}</b>
                          </p>
                        </>)
                        setIsModalVisible(true);
                        break;
                      }

                      default:
                        break;
                    }
                  }}
                />
              ) : (
                <CustomEmptyDiv title={t("clss_appear_here")} />
              )}
            </>}


          </Modal>

        </Panel>
      </Collapse>

    </Container >
  );
};

export default OngoingProjectTaskDetails;
