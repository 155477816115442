import {
  AppImage,
  fieldIds,
  NavigateTo,
  SidebarMenus,
} from "../../../../themes";
import { Collapse, Modal } from "antd";
import { useFormik } from "formik";
import {
  CustomPageHeader,
} from "../../../../molecules/Admin";
import { CustomEmptyDiv, CustomTable, TextField } from '../../../../molecules';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CardAdmin, ContainerAdmin } from "../../../../hoc";
import { useTranslation } from "react-i18next";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn";
import { getProjectDetails } from "../../../../redux/actions/Project";
import { useDispatch } from "react-redux";
import { deleteAdminTask } from "../../../../redux/actions/Tasks";

const { Panel } = Collapse;
const ProjectDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [taskId, setTaskId] = useState(null); // delete modal
  const [isModalVisible, setIsModalVisible] = useState(false); // delete modal
  const [projStatus, setprojStatus] = useState(''); // delete modal
  const [lang, setLang] = useState(localStorage.getItem('lang'));
  const [taskListContents, setTaskListContents] = useState([]);
  const [projName, setprojName] = useState('');
  const [completionDate, setcompletionDate] = useState('');
  const [totalTasks, settotalTasks] = useState('');
  const [taskPending, settaskPending] = useState('');

  const [ownerName, setownerName] = useState('');
  const [ownerEmail, setownerEmail] = useState('');
  const [ownerContact, setownerContact] = useState('');

  let projectId = localStorage.getItem("projId"); // implement it if api will be hit for this page

  const breadCrumbItems = [
    {
      link: NavigateTo.ADMIN_DASH_BOARD,
      label: t('dashboard'),
    },
    {
      link: NavigateTo.ADMIN_PROJECT_MANAGEMENT,
      label: t('prj_mngmnt'),
    },
    {
      link: "",
      label: t('prj_details'),
    },
  ];

  /* page description */

  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: 'break-all'
  };
  const tnl = [
    {
      labelTitle: `${t('proj_name')}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: projName,
      customLabelStyle: rightFontStyle,
    },
  ];
  const ttl = [
    {
      labelTitle: `${t('prj_cmpltn_date')}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: completionDate,
      customLabelStyle: rightFontStyle,
    },
  ];
  const il = [
    { labelTitle: `${t('no_of_task')}:`, customLabelStyle: leftFontstyle },
    {
      labelTitle: totalTasks,
      customLabelStyle: rightFontStyle,
    },
  ];
  const tdl = [
    {
      labelTitle: `${t('pending')}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskPending,
      customLabelStyle: rightFontStyle,
    },
  ];
  let textFieldlabels;

  completionDate ?

    textFieldlabels = [tnl, ttl, il, tdl]

    :
    textFieldlabels = [tnl, il, tdl];

  /* page description */

  const taskListHeaders = [
    {
      label: t("name"),
      headerWidth: "300px",
    },
    {
      label: t("type"),
      headerWidth: "300px",
    },
    {
      label: t("name_of_grps"),
      headerWidth: "400px",
    },
    {
      label: t("action"),
      headerWidth: "225px",
      textAlign: "center",
    },
  ];

  const dropDownGroup = [
    {
      table: {
        placeholder: t('task_list'),
        tableHeaders: taskListHeaders,
        tableContents: taskListContents,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child admin-projdetails",
      },
    },
  ];

  /* drop down */

  /* View Annotaion modal */
  const initialValues = {
    ownerName: "Micheal",
    email: "abc@gmail.com",
    contactNum: "+91 5463789320",
  };
  const formik = useFormik({
    initialValues,
    // validationSchema,
    // onSubmit,
    // validate,
  });

  const ownerDetailsFormFields = [
    {
      type: "text",
      error: null,
      inputTrue: true,
      id: fieldIds.OWNER_NAME,
      value: ownerName, // configure later
      placeholder: t('owner_name'),
      fieldLabel: t('owner_name'),
    },
    {
      type: "text",
      error: null,
      inputTrue: true,
      id: fieldIds.EMAIL,
      value: ownerEmail, // configure later
      placeholder: t('email_address'),
      fieldLabel: t('email_address'),
    },
    {
      type: "text",
      error: null,
      inputTrue: true,
      id: fieldIds.CONTACTNUM,
      value: ownerContact, // configure later
      placeholder: t('contact_num'),
      fieldLabel: t('contact_num'),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("admin"),
    active: t('prj_mngmnt'),
  };
  const payload = {
    body: {
      projectId,
    },
    success: (res) => {

      let tempTaskList = []; // (task name,task type,task description,completion date,view)
      res?.data?.tasks.map((item) => {
        let tempGroups = [];
        item?.task_group.map(item => {
          tempGroups.push(item?.name);
        })

        tempTaskList.push({
          0: item?.name,
          1:
            item?.task_type == "1"
              ? "image"
              : item?.task_type == "2"
                ? "text"
                : "audio", //item?.task_type, 
          2: tempGroups.join(','),
          4: {
            button: [
              {
                label: 'View',
                tdCustomstyle: {
                  display: "table-cell",
                },
                link: NavigateTo.ADMIN_PROJ_TASK_DETAILS,
                id: item?.id,
                customBtnStyle: {
                  padding: "5px 24px",
                  borderRadius: "7px",
                },
              },
              {
                label: "Delete",
                tdCustomstyle: {
                  display: "table-cell",
                },
                link: "",
                id: item?.id,
                customBtnStyle: {
                  padding: "5px 24px",
                  borderRadius: "7px",
                },
              },
            ],
          }
        });
      });
      setTaskListContents(tempTaskList);

      setprojName(res?.data?.name);
      const tempstatus = res?.data?.end_date ? 'completed' : 'pending';
      setprojStatus(tempstatus);
      res?.data?.end_date && setcompletionDate(res?.data?.end_date.split('T')[0].split('-').reverse().join('-'));
      settotalTasks(res?.data?.tasks.length);
      settaskPending(res?.data?.total_task_pending);
      setownerName(res?.data?.owner_id?.first_name + ' ' + res?.data?.owner_id?.last_name);
      setownerEmail(res?.data?.owner_id?.email);
      setownerContact('+' + ' ' + res?.data?.owner_id?.phone_no);
      // setProjectInfo(tempProjInfo); 
    },
    fail: (resf) => {
      console.log(resf, "resf of ongoing proj details");
    },
  };
  useEffect(() => {
    window.document.title = 'WosoM';

    isInternetConnected() && dispatch(getProjectDetails(payload)); // implement it if api will be hit


  }, [lang])
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll('button[aria-label="Close"]');
    if (modalPopUpCrossButton && localStorage.getItem('lang') == 'ar') {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = 'unset';
        modalPopUpCrossButton[i].style.left = '0';
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem('lang') == 'en') {

      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = '0';
        modalPopUpCrossButton[i].style.left = 'unset';
      }
    }
  }, [isModalVisible])

  return (
    <ContainerAdmin {...containerProps}
      languageChanged={
        () => {
          setLang(localStorage.getItem('lang'));

        }
      }
      layoutClassName={localStorage.getItem('lang') == 'ar' ? 'new_layout ar' : 'new_layout en'}
    >
      <CardAdmin cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        <CustomPageHeader
          label={t('prj_details')}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px",
          }}
        >
          <CustomPageHeader
            label={`${t('status')}: ${t(projStatus)}`}
            wrapperClassName={
              "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
            }
            customWrapperStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginLeft: 10, marginTop: -5 }}>
              <img
                src={
                  projStatus == "pending"
                    ? AppImage.pendingTask
                    : AppImage.completedTask
                }
              />
            </div>
          </CustomPageHeader>
        </CustomPageHeader>

        <div className="wrapper_line">
          {/* pageDescription */}
          {textFieldlabels.map((item) => {
            return (
              <TextField wrapperClassName={"admin-projdetails"} labels={item} key={item} />
            );
          })}
          {/* pageDescription */}

          <h6
            className="text-white text-capitalize ps-3"
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            {t('owner_details')}
          </h6>

          {ownerDetailsFormFields.map((items) => {
            return (
              <div className="" key={items}>
                <TextField
                  {...items}
                  onChange={formik.handleChange}
                  labelTitle={items.fieldLabel}
                  isDisabled={true}
                />
              </div>
            );
          })}
          {/* dropdowns */}
          {dropDownGroup.map((item, index) => {
            return (
              <Collapse key={index} defaultActiveKey={["1"]} className="admin-taskDetails" onChange={() => { }}>
                <Panel id={index} header={item.table.placeholder} key="1">
                  {
                    taskListContents.length > 0 ?

                      <CustomTable
                        {...item.table}
                        customTableClassName={localStorage.getItem('lang') == 'ar' ? 'text_data admin-project-details-task-list-table-ar' : 'text_data admin-project-details-task-list-table-en'}
                        buttonCallbackTrue={true}
                        lang={lang}
                        handleClick={(label, id) => {
                          switch (label) {
                            case 'View':
                              {
                                localStorage.setItem("projectTaskId", id);
                                navigate(NavigateTo.ADMIN_PROJ_TASK_DETAILS + `/${id}`)
                                break;
                              }
                            case 'Delete':
                              {
                                setTaskId(id);
                                setIsModalVisible(true)
                                break;
                              }

                            default:
                              break;
                          }
                        }}
                      />
                      :
                      <CustomEmptyDiv title={t("tasks_appear_here")} />
                  }
                </Panel>
              </Collapse>
            );
          })}

          {/* delete project modal */}
          <Modal
            title={t('delete')}
            open={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            // className="success_data delete"
            className={localStorage.lang == "ar" ? "new_layout ar success_data delete" : "en success_data delete"}
            centered
          >
            <div className="text-center  model1">
              <p>{t('u_sure_to_dlt_this_task')}</p>
            </div>
            <div className="text-center  model1">
              <button
                type="submit"
                className="button-list top_new focus"
                onClick={() => {
                  const payloadDelete = {
                    id: taskId
                  }
                  isInternetConnected() && dispatch(deleteAdminTask(payloadDelete));
                  isInternetConnected() && dispatch(getProjectDetails(payload)); // implement it if api will be hit

                  setIsModalVisible(false);
                }}
              >
                {t('yes')}
              </button>
              <button type="submit" className="focus" onClick={() => setIsModalVisible(false)}>
                {t('no')}
              </button>
            </div>
          </Modal>
        </div>
      </CardAdmin>
    </ContainerAdmin>
  );
};

export default ProjectDetails;
