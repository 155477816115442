import createSagaMiddleware from "@redux-saga/core";
import { createStore, applyMiddleware } from "redux";
import rootReducers from "../reducers/rootReducers";
import { mySaga } from "../saga";

// import {persistStore, persistReducer} from "redux-persist";
// import storage from "redux-persist/lib/storage";

//add redux-persist

const sagaMiddleware = createSagaMiddleware();
// const middleware = [sagaMiddleware];

/**
 * Persist config to persist(save/store) reducer
 */
//  const persistConfig = {
//     key: "root",
//     storage,
//     whiteList:['login']
// };
 
/**
 * Persist reducer
 */
//  const persistedReducer = persistReducer(persistConfig, rootReducers);

 /**
 * Create store with persisted reducer
 */
export const store = createStore(
    // persistedReducer,
    rootReducers,
   applyMiddleware(sagaMiddleware)
);
  

sagaMiddleware.run(mySaga);

// export const persistedStore = persistStore(store);
 

  
 


