import { GET_USER_DETAILS, SET_USER_DETAILS, SET_USER_LIST, USER_DELETE } from "../actionTypes/actionTypes"

// done
  
export const setUserList = (payload) => {
    return {
        type: SET_USER_LIST, 
        payload: payload 
    }
 
} 
export const userDelete = (payload) => {
    return {
        type: USER_DELETE, 
        payload: payload 
    }
 
} 

export const getUserDetails = (payload) => {
    return {
        type: GET_USER_DETAILS, 
        payload: payload 
    }

}  
export const setUserDetails = (payload) => {
    return {
        type: SET_USER_DETAILS, 
        payload: payload 
    }

} 