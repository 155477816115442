import React, { useState, useEffect } from "react"
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  AppImage,
  Constants,
  fieldIds,
  NavigateTo,
} from "../../../themes/index";
import TextField from "../../../molecules/Textfield";
import "./style.css";
import CustomModal from "../../../molecules/CustomModal";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { customFunctions } from "../../../themes/utils/CustomFunctions";
import { useDispatch } from "react-redux";
import { getSkillCategories } from "../../../redux/actions/Skills";
import { toast } from "react-toastify";
import { profileCompletion } from "../../../redux/actions/Profile";
import {
  disableProfileCompletion,
  getCountries,
  getCountry,
  getLanguageList,
} from "../../../redux/actions/Common";
import { useTranslation } from "react-i18next";
import { Modal, Tooltip } from "antd";

import { useNavigate } from "react-router";



const ProfileCompletion = () => {
  const uid = localStorage.getItem("profile_uid");
  const token = localStorage.getItem("profile_token");

  const fieldIcons = [AppImage.upArrow, AppImage.downArrow];

  const [disableDownArrow, setDisableDownArrow] = useState(false);
  const [disableUpperArrow, setDisableUpperArrow] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [avail, setavail] = useState(`20`);
  const [visible, setVisible] = useState(false);
  const [genderVal, setGender] = useState(null);
  // const [educationVal, setEducationVal] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [languageChildren, setLanguageChildren] = useState([]);
  const [addedSkillsToShow, setAddedSkillsToShow] = useState([]);
  const [citizenshipOptions, setCitizenshipOptions] = useState(null);
  // const [categoryValue, setCategoryValue] = useState(
  //   Constants.Skill_Category_Ph
  // ); // this will have CommonCategory as keys.
  // const [nameValue, setNameValue] = useState(Constants.Skill_Name_Ph); // this will have CommonCategory as keys.
  // const [experienceValue, setExperienceValue] = useState(
  //   Constants.Skill_Experience_Ph
  // ); // this will have CommonCategory as keys.
  const [skillOptions, setSkillOptions] = useState([
    {
      name: "Abc",
      value: "abc",
    },
  ]);
  const [citizenshipVal, setCitizenshipVal] = useState([]);
  const [countryVal, setCountryVal] = useState([]);
  const [countryOptions, setCountryOptions] = useState(null);

  const selectPlaceholders = [
    Constants.Skill_Category_Ph,
    Constants.Skill_Name_Ph,
    Constants.Skill_Experience_Ph,
  ];

  const callProfileCompletionApi = (bodyTobeSend) => {
    const values = formik.values;

    const payload = {
      success: () => {
        setShowSuccessModal(true);
        localStorage.setItem("userSkills", JSON.stringify([]));
        localStorage.setItem("addedSkillsToShow", JSON.stringify([]));
        // reset uid and token
        localStorage.setItem("uid", undefined);
        localStorage.setItem("token", undefined);
      },
      fail: () => { },
      body: bodyTobeSend
        ? bodyTobeSend
        : {
          uid: uid,
          token: token,
          languages:
            values?.languages.length > 0 ? values?.languages.map(item => {
              return {
                code: item
              }
            }) : [],
          device: values?.devices.length > 0 ? values?.devices.join(",") : [1],
          education_level: values.education ? values.education.key : null,
          citizenships:
            citizenshipVal.length > 0 ? citizenshipVal.map(item => {
              return {
                code: item.key
              }
            }) : null,
          country: countryVal.length > 0 ? {
            code: countryVal[0].key
          } : null,
          gender: genderVal ? genderVal.toLowerCase() : null,
          address: values.address ? values.address : null,
          availability: document.getElementsByClassName("id-control")[0]
            .value,
          skills:
            JSON.parse(localStorage.getItem("userSkills")).length > 0
              ? JSON.parse(localStorage.getItem("userSkills"))
              : null,
        },
    };
    isInternetConnected() && dispatch(profileCompletion(payload));
  };

  const onSubmit = () => {
    callProfileCompletionApi();
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string()
      // .test('trim','Please enter address.',value => value?.trim()?.length > 0)
      .matches(
        /^[-a-zA-Z0-9-()]+([-a-zA-Z0-9-(),'"?/!@.\s]+)*$/,
        "Please enter valid address."
      )
      .max(500, "Maximum 500 characters are allowed.")
      .min(5, "Address must be 5 characters long."),
    primaryEd: Yup.string()
      // .test('trim','Please enter primary education.',value => value?.trim()?.length > 0)
      .max(50, "Maximum 50 characters are allowed.")
      .min(5, "Primary education should be at least 5 characters long.")
      .matches(
        /^[-a-zA-Z0-9-()]+([-a-zA-Z0-9-(),'"?/!@.\s]+)*$/,
        "Please enter valid text without any space at the beginning."
      ),
    secondaryEd: Yup.string()
      // .test('trim','Please enter secondary education.',value => value?.trim()?.length > 0)
      .max(50, "Maximum 50 characters are allowed.")
      .min(5, "Secondary education should be at least 5 characters long.")
      .matches(
        /^[-a-zA-Z0-9-()]+([-a-zA-Z0-9-(),'"?/!@.\s]+)*$/,
        "Please enter valid text without any space at the beginning."
      ),
    bachelorEd: Yup.string()
      // .test('trim','Please enter bachelor degree.',value => value?.trim()?.length > 0)
      .max(50, "Maximum 50 characters are allowed.")
      .min(5, "Bachelor degree must be minimum 5 characters long.")
      .matches(
        /^[-a-zA-Z0-9-()]+([-a-zA-Z0-9-(),'"?/!@.\s]+)*$/,
        "Please enter valid text without any space at the beginning."
      ),
    masterEd: Yup.string()
      // .test('trim','Please enter master degree.',value => value?.trim()?.length > 0)
      .max(50, "Maximum 50 characters are allowed.")
      .min(5, "Master degree must be minimum 5 characters long.")
      .matches(
        /^[-a-zA-Z0-9-()]+([-a-zA-Z0-9-(),'"?/!@.\s]+)*$/,
        "Please enter valid text without any space at the beginning."
      ),
    phdEd: Yup.string()
      // .test('trim','Please enter PHD degree.',value => value?.trim()?.length > 0)
      .max(50, "Maximum 50 characters are allowed.")
      .min(5, "PHD degree must be minimum 5 characters long.")
      .matches(
        /^[-a-zA-Z0-9-()]+([-a-zA-Z0-9-(),'"?/!@.\s]+)*$/,
        "Please enter valid text without any space at the beginning."
      ),
  });

  const initialValues = {
    address: "",
    skills: [],
    languages: languageChildren,
    citizenships: "", //citizenshipVal,
    gender: "",
    education: "",
    primaryEd: "",
    secondaryEd: "",
    bachelorEd: "",
    masterEd: "",
    phdEd: "",
    devices: [],
    availability: `20`,
    country: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    // validate,
  });
  const skillSection = [
    {
      label: Constants.Skill_Category_Label,
      value: "Category 1",
    },
    {
      label: Constants.Skill_Name_Label,
      value: "Name 1",
    },
    {
      label: Constants.Skill_Description_Label,
      value: "Lorem ipsum is a text.",
    },
    {
      label: Constants.Skill_Experience_Label,
      value: "1 year",
    },
  ];

  const skillsCard = skillSection.length ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "150%",
        paddingLeft: '9px'

        // flexWrap: "wrap",
        // justifyContent:'space-between'
      }}
    >
      {localStorage.getItem("addedSkillsToShow") != null &&
        JSON.parse(localStorage.getItem("addedSkillsToShow"))
          // addedSkillsToShow.
          .map((localStorageItem, index) => {
            return (
              <div
                key={index}
                className="skillCard"
                style={{
                  width: "180px",
                  position: "relative",
                  marginRight: "15px",
                  marginBottom: "20px",
                }}
              >
                {localStorageItem.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        padding: 10,
                      }}
                    >
                      <div className="skillHeading">{item.label}</div>
                      <div
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          whiteSpace: 'break-spaces'

                        }}
                        className="skillSubHeading"
                      >
                        {item.value}
                      </div>
                      {index != skillSection.length - 1 && (
                        <div
                          style={{
                            border: "1px solid #8BBAE8",
                          }}
                        />
                      )}
                    </div>
                  );
                })}
                <div
                  onClick={() => {
                    let localStorageAddedSkillsToShow = JSON.parse(
                      localStorage.getItem("addedSkillsToShow")
                    );
                    let modifiedSkillsToShow = [];

                    let localStorageAddedSkillsForApi = JSON.parse(
                      localStorage.getItem("userSkills")
                    );
                    let modifiedSkillsForApi = [];

                    localStorageAddedSkillsToShow.filter(
                      (item, searchedIndex) => {
                        if (index != searchedIndex) {
                          modifiedSkillsToShow.push(item);
                        }
                      }
                    );

                    localStorageAddedSkillsForApi.filter(
                      (item, searchedIndex) => {
                        if (index != searchedIndex) {
                          modifiedSkillsForApi.push(item);
                        }
                      }
                    );

                    localStorage.setItem(
                      "addedSkillsToShow",
                      JSON.stringify(modifiedSkillsToShow)
                    );
                    localStorage.setItem(
                      "userSkills",
                      JSON.stringify(modifiedSkillsForApi)
                    );

                    setAddedSkillsToShow(modifiedSkillsToShow);
                  }}
                  style={{
                    position: "absolute",
                    right: "-13px",
                    top: "-14px",
                    cursor: "pointer",
                  }}
                >
                  <img src={AppImage.crossButton} width={40} height={40} />
                </div>
              </div>
            );
          })}
    </div>
  ) : null;

  // const increaseDecrease = (
  //   <div className="form-group" style={{ position: "relative" }}>
  //     <input
  //       id={"id"}
  //       value={formik.values.availability}
  //       className="id-control form-control"
  //       readOnly={true}
  //       autoComplete="off"
  //     />
  //   </div>
  // );
  const personalFormFields = [
    {
      error: formik.errors.address,
      inputTrue: true,
      type: "text",
      id: fieldIds.ADDRESS,
      fieldLabel: Constants.AddressLabel,
      value: customFunctions.capitalizeFirstLetter(
        formik.values.address.trimLeft()
      ),
      placeholder: "Address", //Constants.Address,
      inputMaxLength: 500,
    },
    {
      id: "languages",
      selectTrue: true,
      selectMode: "multiple",
      selectOptions: languageChildren,
      placeholder: "Select Languages", //Constants.Languages,
      value: formik.values.languages || "",
      error: formik.errors.languages,
      fieldLabel: t("languages_label"),
      selectOnChange: (val) => {
        formik.setValues({
          ...formik.values,
          languages: val,
        });
      },
      showSearch: true,
    },
    {
      id: fieldIds.CITIZENSHIP,
      selectTrue: true,
      placeholder: t("select_citizenship"),
      value: formik.values.citizenships[0]?.key || null,
      error: formik.errors.citizenships,
      fieldLabel: t("citizenship"),
      selectOptions: citizenshipOptions,
      selectOnChange: (val, option) => {
        setCitizenshipVal([{ key: val, val: option.children }]);
        formik.setValues({
          ...formik.values,
          citizenships: [{ key: val, val: option.children }],
        });
      },
    },
    {
      id: "country",
      selectTrue: true,
      placeholder: "Select Country",
      value: formik.values.country[0]?.key || null,
      error: formik.errors.country,
      fieldLabel: "Country",
      selectOptions: countryOptions,
      selectOnChange: (val, option) => {
        setCountryVal([{ key: val, val: option.children }]);
        formik.setValues({
          ...formik.values,
          country: [{ key: val, val: option.children }],
        });
      },
    },
    {
      type: "text",
      // inputTrue: true,
      selectTrue: true,
      fieldLabel: Constants.Gender,
      // labelIcon: AppImage.plus,
      id: fieldIds.GENDER,
      // value: genderVal || 'Select Gender',
      value: formik.values.gender[0]?.key || null,
      error: formik.errors.gender,
      selectOptions: [
        { name: t("male"), value: "Male" },
        { name: t("female"), value: "Female" },
        { name: t("other"), value: "Other" },
      ],
      placeholder: "Select Gender", //Constants.Gender,
      selectOnChange: (val, options) => {
        setGender(val);

        formik.setValues({
          ...formik.values,
          gender: [{ key: val, val: options.children }],
        });
      },

      // inputMaxLength : 50
    },
  ];
  const educationFormFields = [
    {
      type: "text",
      // inputTrue: true,
      selectTrue: true,
      fieldLabel: Constants.Education,
      // labelIcon: AppImage.plus,
      id: fieldIds.EDUCATION,
      // value: genderVal || 'Select Gender',
      value: formik.values.education[0]?.key || null,
      error: formik.errors.education,
      selectOptions: [
        { name: t("prim_ed"), value: "primary" },
        { name: t("sec_ed"), value: "secondary" },
        { name: t("bach_ed"), value: "bachelors" },
        { name: t("mastrs_ed"), value: "masters" },
        { name: t("phd_ed"), value: "phd" },
      ],
      placeholder: "Select education level", //Constants.Gender,
      selectOnChange: (val, options) => {
        // setEducationVal(val);

        formik.setValues({
          ...formik.values,
          education: [{ key: val, val: options.children }],
        });
      },
    },
    {
      selectTrue: true,
      selectMode: "multiple",
      fieldLabel: Constants.Device_Available,
      value: formik.values.devices || "",
      error: null,
      placeholder: "Select Devices",
      selectOptions: [
        { name: t("desktop"), value: "desktop" },
        { name: t("tablet"), value: "tablet" },
        { name: t("smartphone"), value: "smartphone" },
        { name: t("other"), value: "other" },
      ],
      selectOnChange: (val) => {
        formik.setValues({
          ...formik.values,
          devices: val,
        });
      },
    },
  ];
  // const selectCustomStyle = {
  //   cursor: "pointer",
  //   caretColor: "transparent",
  // };

  const AddSkillModalProps = {
    title: t("add_skills"),
    handleOk: (data) => {
      setVisible(false);

      setAddedSkillsToShow(data);
    },
    onSubmit: (data) => {
      setVisible(false);
      setAddedSkillsToShow(data);
    },
    modalWidth: 550,
    handleCancel: () => {
      setVisible(false);
    },
    submitTitle: Constants.add,
    isModalVisible: visible,
  };

  const SuccessModal = () => {
    return (
      <Modal
        title={Constants.Success}
        open={showSuccessModal}
        closable={false}
        footer={null}
        centered
      >
        <div className="text-center  model1">
          <p>{Constants.Successfully_Registered}</p>
        </div>
        <div className="text-center button_d_inline model1">
          <button
            type="submit"
            className="button-list top_new focus"
            onClick={() => {
              setShowSuccessModal(false);
              localStorage.setItem("showProfileCompletion", "false");
              dispatch(disableProfileCompletion(false));
              navigate(NavigateTo.LOGIN);
            }}
          >
            {Constants.done}
          </button>
        </div>
      </Modal>
    );
  };

  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  const isMobileDevice = () => {
    const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
    return regexs.some((b) => userAgent.match(b))
  }

  const isTabletDevice = () => {
    const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
    return regex.test(userAgent.toLowerCase())
  }

  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);


    if (!token || !uid) {
      navigate(NavigateTo.REGISTER);
    } else {
      dispatch(disableProfileCompletion(true));
    }

    const device = isMobileDevice() ? "mobile" : isTabletDevice() ? "tablet" : "desktop";

    initialValues["devices"] = [device];


    const payloadForSkills = {
      success: (res) => {
        var modifiedResult = [];
        res.filter((item) => {
          modifiedResult.push({ name: item.description, value: item.id });
        });
        setSkillOptions(modifiedResult);
      },
      fail: (err) => {
        toast(err);
      },
    };
    isInternetConnected() && dispatch(getSkillCategories(payloadForSkills));

    const payloadForLanguages = {
      success: (res) => {
        var modifiedResult = [];
        res.filter((item) => {
          modifiedResult.push({ name: `${item.name} (${item.native})`, value: item.code });
        });
        setLanguageChildren(modifiedResult);
      },
      fail: (err) => {
        toast(err);
      },
    };
    isInternetConnected() && dispatch(getLanguageList(payloadForLanguages));

    const payloadForCitizenship = {
      success: (res) => {
        var modifiedResult = [];
        res.filter((item) => {
          modifiedResult.push({ name: `${item.name} (${item.native})`, value: item.code });
        });
        setCitizenshipOptions(modifiedResult);
      },
      fail: (err) => {
        toast(err);
      },
    };
    isInternetConnected() && dispatch(getCountries(payloadForCitizenship));


    const payloadForCountry = {
      success: (res) => {
        var modifiedResult = [];
        var modifiedResultCtznshp = [];
        res.filter((item) => {
          modifiedResult.push({ name: `${item.name} (${item.native})`, value: item.code });
          modifiedResultCtznshp.push({ name: `${item.name} (${item.native})`, value: item.code });
        });
        setCountryOptions(modifiedResult);
        setCitizenshipOptions(modifiedResultCtznshp);
      },
      fail: (err) => {
        toast(err);
      },
    };
    isInternetConnected() && dispatch(getCountry(payloadForCountry)); // country
  }, [addedSkillsToShow]);

  useEffect(() => {
    localStorage.setItem("userSkills", JSON.stringify([]));
    localStorage.setItem("addedSkillsToShow", JSON.stringify([]));
    if (
      localStorage.getItem("showProfileCompletion") == "false" ||
      localStorage.getItem("showProfileCompletion") == null
    ) {
      navigate(NavigateTo.REGISTER);
    }
    /* if (!showProfile) {
      navigate(NavigateTo.REGISTER);
    } */
  }, []);
  return (
    <div className="image-bg">
      <div className="page-header heightAdjust">
        <div className="line_true stopOverFlow">
          <div className="content-e">
            <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <div className="text-center cost pad">
                {/* <img src={AppImage.logo3} /> */}
                <h6 className="text-white text-capitalize ps-3 headg_d text-left">
                  {Constants.ProfileCompletion}
                </h6>
              </div>
            </div>
            <form noValidate onSubmit={formik.handleSubmit}>
              {/* skills */}
              <div className="wrapper_line form_wrapper">
                <div
                  className="form-group "
                  style={{
                    position: "relative",
                    overflowX:
                      localStorage.getItem("addedSkillsToShow") != null &&
                        JSON.parse(localStorage.getItem("addedSkillsToShow"))
                          .length > 2
                        ? "scroll"
                        : "unset",
                  }}
                >
                  <label>
                    Skills
                    <Tooltip title={t("add_skills")}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        <img src={AppImage.plus} className="labelIcon" />
                      </span>
                    </Tooltip>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "150%",
                      paddingLeft: '9px'
                    }}
                  ></div>
                  {skillsCard}
                </div>
                {formik.errors.skills ? (
                  <div className={"error"}>{formik.errors.skills}</div>
                ) : null}
              </div>

              {/* availability */}
              <div className="wrapper_line form_wrapper">
                <div className="form-group" style={{ position: "relative" }}>
                  <label> Availability (hours)</label>
                  <div className="form-group" style={{ position: "relative" }}>
                    <input
                      id="id"
                      className="id-control form-control"
                      readOnly=""
                      autoComplete="off"
                      // type={'number'}
                      value={avail}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "row",
                      top: "42px",
                      right: "0px",
                    }}
                  >
                    {/* Availability upper Arrow */}
                    <div
                      className="arrow-icon"
                      style={{
                        position: "absolute",
                        right: "25px",
                        display: "inlineFlex",
                        cursor: disableUpperArrow ? "unset" : "pointer",
                      }}
                      onClick={() => {
                        const el = document.getElementsByClassName(
                          "id-control"
                        );
                        if (el.length > 0) {
                          setDisableDownArrow(false);
                          const val = el[0].value;
                          const num = Number(val.split(" ")[0]);
                          if (num >= 59) {
                            setDisableUpperArrow(true);
                            document.getElementsByClassName(
                              "id-control"
                            )[0].value = `60`;
                            setavail(`60`);
                          } else {
                            const timeIncreased = Number(num + 1);
                            document.getElementsByClassName(
                              "id-control"
                            )[0].value = timeIncreased;
                            setavail(timeIncreased);
                          }
                        }
                      }}
                    >
                      <img
                        style={{ opacity: disableUpperArrow ? 0.3 : null }}
                        src={fieldIcons[0]}
                      />
                    </div>
                    {/* Availability upper Arrow */}

                    {/* Availability down Arrow */}
                    <div
                      className="arrow-icon"
                      style={{
                        position: "absolute",
                        display: "inlineFlex",
                        top: "12px",
                        right: "25px",
                        cursor: disableDownArrow ? "unset" : "pointer",
                      }}
                      onClick={() => {
                        const el = document.getElementsByClassName(
                          "id-control"
                        );
                        if (el.length > 0) {
                          setDisableUpperArrow(false);
                          const val = el[0].value;
                          const num = val.split(" ")[0];
                          if (num == 20) {
                            setDisableDownArrow(true);
                            // setErrorMessage("Minimum availability is 20 hours.");
                            document.getElementsByClassName(
                              "id-control"
                            )[0].value = `20`;
                            setavail(`20`);
                          } else {
                            const decerasedNumber = Number(num - 1);
                            document.getElementsByClassName(
                              "id-control"
                            )[0].value = decerasedNumber;
                            setavail(decerasedNumber);
                          }
                        }
                      }}
                    >
                      <img
                        style={{ opacity: disableDownArrow ? 0.3 : null }}
                        src={fieldIcons[1]}
                      />
                    </div>
                    {/* Availability down Arrow */}
                  </div>
                </div>
              </div>

              {/* PersonalInfo section */}
              {personalFormFields.map((items) => {
                return (
                  <div className="wrapper_line form_wrapper" key={items}>
                    <TextField
                      {...items}
                      formik={formik}
                      labelTitle={items.fieldLabel}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          [e.target.id]: e.target.value.trimLeft()
                        })

                      }}
                    />
                  </div>
                );
              })}
              {/* Education section */}
              <div className="subheader">
                <h2 className="text-white text-capitalize ps-3 headg_d text-left">
                  {Constants.Education}
                </h2>
              </div>
              {educationFormFields.map((items) => {
                return (
                  <div className="wrapper_line form_wrapper" key={items}>
                    <TextField
                      {...items}
                      formik={formik}
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          [e.target.id]: e.target.value.trimLeft()
                        })

                      }}
                      // onChange={formik.handleChange}
                      // inputTrue={true}
                      labelTitle={items.fieldLabel}
                    />
                  </div>
                );
              })}

              {/* <AddSkillModal /> */}
              <CustomModal
                formik={formik}
                {...AddSkillModalProps}
                // formFields={AddSkillModalFormFields}
                dataToShow={skillOptions}
                selectPlaceholders={selectPlaceholders}
              // onTextChange={() => {
              //   console.log('');
              // }}
              // selectOnChange={(val, label) => {
              //   // if (label == Constants.Skill_Category_Label) {
              //   //   setCategoryValue(val);
              //   // }
              //   if (label == Constants.Skill_Name_Label) {
              //     setNameValue(val);
              //   }
              //   if (label == Constants.Skill_Experience_Label) {
              //     setExperienceValue(val);
              //   }
              // }}
              />

              <SuccessModal />
              {/* submit button */}
              <div
                className="data_line"
                style={{ paddingBottom: "10px", paddingTop: 0, marginTop: 30 }}
              >
                <button
                  type="submit"
                  className="button-list focus"
                // onClick={() => {
                //   setShowSuccessModal(true);
                // }}
                >
                  {Constants.submit}
                </button>
              </div>
            </form>
            {/* skip link */}
            <div className="subheader"></div>
            <div
              // className="forrgot"
              style={{
                textDecoration: "underline",
                textAlign: "center",
                paddingBottom: "40px",
              }}
            >
              {/* link to dashboard page */}
              <p
                className="skipLink"
                style={{
                  color: "#194C7F",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  const bodyTobeSend = {
                    uid: uid,
                    token: token,
                    languages: [],
                    device: "",
                    education_level: "",
                    gender: "",
                    address: "",
                    availability: 0,
                    skills_array: [],
                    country: { code: "au" },
                    citizenships: [],
                  };
                  callProfileCompletionApi(bodyTobeSend);
                }}
              >
                {Constants.Skip}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileCompletion;
