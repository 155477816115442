import { Get, Post } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl;

const Fetch = {
    getUserProfile() {
        let url = baseUrl + "user/profile/";
        return Get(url, true);
    },
    profileCompletion(payload) {
        let url = baseUrl + "user/profile/";
        let headers = {
            "Content-Type": "application/json"
        }
        return Post(payload, url, headers);
    }
}

export default Fetch;