import React from 'react';

const enSourceTypeExample = <>
    <h3>Please upload a file containing your dataset as follows</h3>

    <h4>Image:</h4>
    <p>Upload a zip file containing images. No more than <b>50MB</b> in size, the images must be directly in the zip with no subfolders</p>

    <h4>Audio:</h4>
    <p>Upload a zip file containing audio files. No more than <b>50MB</b> in size, the audio files must be directly in the zip with no subfolders</p>

    <h4>Text:</h4>
    <p>Upload a xlsx or a csv file. No more than <b>50MB</b> in size, the file must have one column with the name <b>paragraph</b> and all the data to be annotated must be under this column, other columns will be ignored</p>


    <p><i>Note for remote upload: please provide the a link directly to the file, for example</i></p>
    <p><code>https://www.example.com/files/file.zip</code></p>
</>;

const arSourceTypeExample = <>
    <h3>يرجى تحميل ملف يحتوي على مجموعة البيانات الخاصة بك على النحو التالي</h3>

    <h4>صورة:</h4>
    <p>قم بتحميل ملف zip يحتوي على الصور. لا يزيد حجمها عن <b>50 ميغابايت</b> ، يجب أن تكون الصور مباشرة في ملف zip دون أي مجلدات فرعية</p>

    <h4>صوت:</h4>
    <p>قم بتحميل ملف zip يحتوي على ملفات صوتية. لا يزيد حجمها عن <b>50 ميغابايت</b> ، يجب أن تكون ملفات الصوت مباشرة في ملف zip دون أي مجلدات فرعية</p>

    <h4>نص:</h4>
    <p>قم بتحميل ملف xlsx أو csv. لا يزيد حجمها عن <b>50 ميغابايت</b> ، يجب أن يحتوي الملف على عمود واحد بالاسم <b>paragraph</b> ويجب أن تكون جميع البيانات التي سيتم تعليقها تحت هذا العمود ، وسيتم تجاهل الأعمدة الأخرى</p>

    <p><i>ملاحظة للتحميل عن بعد: يرجى تقديم رابط مباشر للملف، على سبيل المثال</i></p>
    <p><code>https://www.example.com/files/file.zip</code></p>
</>;

export { enSourceTypeExample, arSourceTypeExample };