import { LOGOUT, SET_USER_PROFILE } from "./../../actionTypes/actionTypes";

const initialState = {
     data:null
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT :
            return {
                ...state,
                data:null
            };
        case SET_USER_PROFILE:{
            return {
                ...state, 
                data : action.payload
            };
        }
        default:
            return {
                ...state
            };

    }

}

export default profileReducer;