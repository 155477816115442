import Fetch from "../Fetch/CommonApi";
import { toast } from "react-toastify";
import { put, call } from "redux-saga/effects";
import {
  passSuccess,
  profilestartLoading,
  profilestopLoading,
  startLoading,
  stopLoading,
} from "../actions/Common";
import { customFunctions } from "../../themes/utils/CustomFunctions";
import { NavigateTo } from "../../themes";
import i18n from "i18next";
export function* langugaeChangedSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.languageChangedApi, body);
    if (result.status === 1) {
      success(result?.result);
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getWorkersValidatorsListSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.workersValidatorsList, body);
    if (result.status === 1) {
      success(result?.result);
      // yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getLanguageListSaga({
  payload: { success = () => { }, fail = () => { } } = {},
}) {
  try {
    const result = yield call(Fetch.languageListApi);
    yield put(startLoading());
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getDeviceListSaga({
  payload: { success = () => { }, fail = () => { } } = {},
}) {
  try {
    const result = yield call(Fetch.deviceListApi);
    yield put(startLoading());
    if (result.status === 1) {
      success(result?.result?.data);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
// country
export function* getCountryListSaga({
  payload: { success = () => { }, fail = () => { } } = {},
}) {
  try {
    const result = yield call(Fetch.countryListApi);
    yield put(startLoading());
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* changePasswordSaga({ payload, navigate }) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.changePasswordApi, payload);

    if (result.status === 1) {
      localStorage.clear();

      customFunctions.showToast(
        result?.result?.message,
        true,
        "passupdatesuccess"
      );
      yield put(passSuccess(true));
      yield put(stopLoading());
      // yield put(logout("i"));
      i18n.changeLanguage("en");
      //   setTimeout(() => {
      navigate(NavigateTo.LOGIN);
      //   }, 5000);
      // } else {
      customFunctions.showToast(
        result?.result?.old_password[0],
        false,
        "passupdatefailed"
      );
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      toast.error(result?.result?.old_password[0], {
        position:
          localStorage?.lang == "en"
            ? toast.POSITION.TOP_RIGHT
            : toast.POSITION.TOP_LEFT,
      });
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
// country
export function* getCountrySaga({
  payload: { success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.countryApi);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* verifyRegisteredEmailSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    const result = yield call(Fetch.verifyRegisteredEmailApi, body);

    yield put(startLoading());
    if (result.status === 1) {
      success(result?.result?.message);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result?.message);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* verifyForgotPassTokenSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    const result = yield call(Fetch.verifyForgotPassTokenApi, body);
    yield put(startLoading());
    if (result.status === 1) {
      success(result?.result?.message);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
// user profile update
export function* updateProfileSaga({ payload, navigate }) {
  try {
    yield put(profilestartLoading());
    let result;
    if (payload?.image) {
      result = yield call(Fetch.userProfileUpdateImageApi, payload);
    } else {
      result = yield call(Fetch.userProfileUpdateApi, payload);
    }

    yield put(profilestartLoading());
    if (result.status === 1) {
      if (navigate) {
        navigate(NavigateTo.ADMIN_USER_MANAGEMENT);
      }

      if (payload.first_name) {
        localStorage.setItem(
          "fullName",
          payload?.first_name + " " + payload?.last_name
        );
      }
      yield put(profilestopLoading());
      toast.success(i18n.t("profile_updtd"), {
        className:
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,

        id: "psuccess",
      });
    } else if (result.status === 2) {
      yield put(profilestopLoading());
      result?.result?.error &&
        toast.error(result?.result?.error, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,

          toastId: "erremail",
        });

      result?.result?.errors?.phone_no &&
        toast.error(result?.result?.errors?.phone_no[0], {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,

          toastId: "errNum",
        });
      result?.result?.errors?.email &&
        toast.error(result?.result?.errors?.email[0], {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,

          toastId: "errNum",
        });
    }
  } catch (error) {
    yield put(profilestopLoading());
  }
}
export function* contactUsSaga({ payload }) {
  try {
    yield put(startLoading());

    let result = yield call(Fetch.contactUsApi, payload);
    if (result.status === 1) {
      yield put(stopLoading());
      window.scrollTo(0, 0);
      toast.success('Thank you, we will reach you soon.', {
        position: toast.POSITION.TOP_RIGHT,
        id: "msuccess",
      });
    } else if (result.status === 2) {
      yield put(stopLoading());
      toast.error("Error", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "errm",
      });
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
export function* logSaga({ payload }) {
  try {
    yield put(startLoading());
    let result = yield call(Fetch.logApi, payload);
    if (result.status === 1) {
      yield put(stopLoading());
    } else if (result.status === 2) {
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
  }
}