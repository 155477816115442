import { takeEvery, takeLatest } from "redux-saga/effects";
import * as types from "../actionTypes/actionTypes";
import {
  getAdminDashboardSaga,
  getAnnotationAdminSaga,
  getImagesAdminSaga,
  getOwnerDashboardSaga,
  getOwnerListAdminSaga,
  getProjCompletedAdminSaga,
  getProjCreatedAdminSaga,
  getUserDashboardSaga,
  getUserListAdminSaga,
  impersonateUserSaga,
} from "./DashboardSaga";
import postForgotApi, {
  logoutSaga,
  otpVerificationApi,
  postChangePassApi,
  PostLoginApi,
  postResetPassApi,
  postSetPassApi,
  registerUserApi,
} from "./AuthenticateSaga";
import { getUserProfileApi, profileCompletionApi } from "./UserSaga";
import getUserDetailsApi from "./ViewSaga";
import userEditDetailsApi from "./EditUserSaga";
import updateUserDetailsApi from "./UpdateUserDetailsSaga";
import enableDisableApi from "./EnableSaga";
import { setUserProfileInStore } from "../actions/Profile";
import {
  acceptRejectNotificationSaga,
  getUserNotificationSaga,
} from "./NotificationSaga";
import {
  addNewTaskToProjectSaga,
  deleteProjectSaga,
  exportDataSaga,
  getAdminProjectListsSaga,
  getOwnerDashQuickLinkProjSaga,
  getOwnerDashQuickLinkTotalTaskSaga,
  getOwnerOngoingProjectListsSaga,
  getOwnerPastProjectListsSaga,
  getProjectDetailsSaga,
  postOwnerCreateNewProjectSaga,
} from "./ProjectSaga";
import {
  createTaskSaga,
  deleteAddedTaskSaga,
  getAddedTaskListSaga,
  getValidatorCompletedTaskSaga,
  getValidatorPendingTaskSaga,
  getValidtaorsListOwnerOngoingProjSaga,
  getWorkerListOwnerOngoingProjSaga,
  getWorkerCompletedTaskSaga,
  getWorkerPendingTaskSaga,
  taskDetailsSaga,
  taskPreviewSaga,
  addValidatorForTaskSaga,
  removeUserSaga,
  taskQuotaSaga,
  replaceUserSaga,
  removeTaskUserInviteSaga,
  addWorkerForTaskSaga,
  getUserInvitaionListSaga,
  deleteTaskAdminSaga,
  taskListingExportSaga,
  inviteToTaskSaga,
  taskInvitesSaga,
} from "./TasksSaga";
import {
  changePasswordSaga,
  contactUsSaga,
  logSaga,
  getCountryListSaga,
  getCountrySaga,
  getDeviceListSaga,
  getLanguageListSaga,
  getWorkersValidatorsListSaga,
  langugaeChangedSaga,
  updateProfileSaga,
  verifyForgotPassTokenSaga,
  verifyRegisteredEmailSaga,
} from "./CommonSaga";
import {
  createGroupApi,
  deleteGroupApi,
  getGroupInvitationsApi,
  getGroupListsApi,
  shouldMemberBeRemovedSaga,
  updateGroupApi,
} from "./GroupSaga";
import { getSkillCategoriesApi } from "./SkillsSaga";
import { getTasksTypeApi, getDocument, getDocumentDetails } from "./TasksSaga";
import {
  acceptRejectAnnotationsSaga,
  getAnnotationListsSaga,
  saveAnnotationsSaga,
  saveValidationsSaga,
} from "./AnnotationSaga";
import { rateWorkerByValidatorSaga } from "./Rating";
import { reportWorkerByValidatorSaga } from "./Report";
import {
  accpetRejectDisputedAnnotsSaga,
  disputeDetailsSaga,
  disputeListsSaga,
  raiseDisputesSaga,
} from "./DisputeAnnotsSaga";
import {
  blockUnblockOwnerSaga,
  createOwnerSaga,
  deleteOwnerSaga,
  getOwnerDetailsSaga,
  updateOwnerSaga,
} from "./Owner";
import { getUserDetailsSaga, userDeleteSaga } from "./User";
export function* mySaga() {
  // common

  yield takeLatest(types.GET_LANGUAGE_LIST, getLanguageListSaga);
  yield takeLatest(types.GET_DEVICE_LIST, getDeviceListSaga);
  yield takeLatest(types.GET_COUNTRY_LIST, getCountryListSaga);
  yield takeLatest(types.GET_COUNTRY, getCountrySaga);
  yield takeLatest(types.VERIFY_REGISTERED_EMAIL, verifyRegisteredEmailSaga);
  yield takeLatest(types.VERIFY_FORGOT_TOKEN, verifyForgotPassTokenSaga);
  yield takeLatest(types.WORKERS_VALIDATORS_LIST, getWorkersValidatorsListSaga);
  yield takeLatest(types.CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(types.LANGUAGE_CHANGED, langugaeChangedSaga);
  yield takeLatest(types.CONTACT_US, contactUsSaga);
  yield takeLatest(types.LOG, logSaga);

  // common

  // profile =========

  // user
  yield takeLatest(types.GET_USER_PROFILE, getUserProfileApi);
  yield takeLatest(types.SET_USER_PROFILE, setUserProfileInStore);
  // owner

  // admin

  // profile =========

  // dashboard  ==========

  // user
  yield takeEvery(types.GET_USER_DASHBOARD, getUserDashboardSaga);
  // owner
  yield takeEvery(types.GET_OWNER_DASHBOARD, getOwnerDashboardSaga);
  // admin
  yield takeEvery(types.GET_ADMIN_DASHBOARD, getAdminDashboardSaga);
  yield takeLatest(types.GET_OWNER_LIST_ADMIN, getOwnerListAdminSaga);
  yield takeLatest(types.GET_USER_LIST_ADMIN, getUserListAdminSaga);
  yield takeLatest(types.IMERSONATE_USER, impersonateUserSaga);
  yield takeLatest(types.GET_PROJ_CREATED_ADMIN, getProjCreatedAdminSaga);
  yield takeLatest(types.GET_PROJ_COMPLETED_ADMIN, getProjCompletedAdminSaga);
  yield takeLatest(types.GET_IMAGES_ADMIN, getImagesAdminSaga);
  yield takeLatest(types.GET_ANNOTATION_ADMIN, getAnnotationAdminSaga);
  // dashboard ===========

  // notification  ==========

  // user
  yield takeLatest(types.GET_USER_NOTIFICATION, getUserNotificationSaga);
  yield takeLatest(
    types.ACCEPT_REJECT_NOTIFICATION,
    acceptRejectNotificationSaga
  );
  // worker
  // yield takeLatest(types.GET_OWNER_DASHBOARD, getWorkerNotificationSaga)
  // validator
  // yield takeLatest(types.GET_ADMIN_DASHBOARD, getValidatorNotificationSaga)

  // notification ===========

  // project ===========

  //owner
  //create new project
  yield takeLatest(types.POST_NEW_PROJECT, postOwnerCreateNewProjectSaga);
  yield takeLatest(types.ADD_NEW_TASK_TO_PROJ, addNewTaskToProjectSaga);
  yield takeLatest(types.CREATE_TASK, createTaskSaga);
  yield takeLatest(types.INVITE_TO_TASK, inviteToTaskSaga);
  yield takeLatest(types.GET_INVITED_USERS, taskInvitesSaga);

  yield takeLatest(types.GET_ADDED_TASK_LIST, getAddedTaskListSaga);
  yield takeLatest(types.DELETE_ADDED_TASK, deleteAddedTaskSaga);

  // project ===========
  yield takeLatest(
    types.GET_ONGOING_PROJ_LISTS,
    getOwnerOngoingProjectListsSaga
  );
  yield takeLatest(types.GET_PAST_PROJ_LISTS, getOwnerPastProjectListsSaga);
  yield takeLatest(types.GET_PROJECT_DETAILS, getProjectDetailsSaga);
  yield takeLatest(types.GET_TASK_DETAILS, taskDetailsSaga);
  yield takeLatest(types.GET_TASK_PREVIEW, taskPreviewSaga);
  yield takeLatest(types.OWNER_DASH_PROJ_LISTS, getOwnerDashQuickLinkProjSaga);
  yield takeLatest(
    types.OWNER_DASH_TASK_LISTS,
    getOwnerDashQuickLinkTotalTaskSaga
  );
  yield takeLatest(types.GET_ADMIN_PROJ_LISTS, getAdminProjectListsSaga);

  // Authentication ========
  yield takeLatest(types.LOGOUT, logoutSaga);
  yield takeLatest(types.USER_LOGIN_LOAD, PostLoginApi);
  yield takeLatest(types.USER_FORGET_LOAD, postForgotApi);
  yield takeLatest(types.USER_CHANGE_PASSWORD_LOAD, postChangePassApi);
  yield takeLatest(types.USER_VIEW_LOAD, getUserDetailsApi);
  yield takeLatest(types.USER_EDIT_LOAD, userEditDetailsApi);
  yield takeLatest(types.USER_UPDATE_LOAD, updateUserDetailsApi);
  yield takeLatest(types.USER_SET_PASSWORD_LOAD, postSetPassApi);
  yield takeLatest(types.USER_RESET_PASSWORD_LOAD, postResetPassApi);
  yield takeLatest(types.USER_ENABLE_DISABLE_LOAD, enableDisableApi);
  yield takeLatest(types.USER_REGISTER_LOAD, registerUserApi);
  yield takeLatest(types.OTP_VERIFICATION, otpVerificationApi);
  yield takeLatest(types.PROFILE_COMPLETION, profileCompletionApi);

  // Groups ============
  yield takeLatest(types.GET_ALL_GROUPS, getGroupListsApi);
  yield takeLatest(types.SHOULD_MEMBER_REMOVED, shouldMemberBeRemovedSaga);

  yield takeLatest(types.UPDATE_GROUP, updateGroupApi);
  yield takeLatest(types.CREATE_GROUP, createGroupApi);
  yield takeLatest(types.DELETE_GROUP, deleteGroupApi);
  yield takeLatest(types.GROUP_INVITATIONS, getGroupInvitationsApi);

  // skills ============
  yield takeLatest(types.GET_SKILL_CATEGORIES, getSkillCategoriesApi);

  // tasks ==========
  yield takeLatest(types.GET_TASK_TYPES, getTasksTypeApi);
  yield takeLatest(types.GET_DOCUMENT, getDocument);
  yield takeLatest(types.GET_DOCUMENT_DETAILS, getDocumentDetails);
  // Owner Dashborad/Projects/Ongoing Project Details/Task Details   start
  yield takeLatest(
    types.GET_VALIDATORS_LIST_OWNER_ONGOING_PROJ,
    getValidtaorsListOwnerOngoingProjSaga
  );
  yield takeLatest(
    types.GET_WORKERS_LIST_OWNER_ONGOING_PROJ,
    getWorkerListOwnerOngoingProjSaga
  );
  yield takeLatest(
    types.GET_USER_INVITATION_OWNER_ONGOING_PROJ,
    getUserInvitaionListSaga
  );
  yield takeLatest(
    types.REMOVE_USER_FROM_OWNER_ONGOING_PROJ_TASK,
    removeUserSaga
  );
  yield takeLatest(
    types.UPDATE_USER_QUOTA_FROM_OWNER_ONGOING_PROJ_TASK,
    taskQuotaSaga
  );
  yield takeLatest(
    types.REPLACE_USER_FROM_OWNER_ONGOING_PROJ_TASK,
    replaceUserSaga
  );
  yield takeLatest(
    types.REMOVE_USER_INVITE_FROM_OWNER_ONGOING_PROJ_TASK,
    removeTaskUserInviteSaga
  );
  yield takeLatest(types.ADD_VALIDATOR_FOR_TASK, addValidatorForTaskSaga);
  yield takeLatest(types.ADD_WORKER_FOR_TASK, addWorkerForTaskSaga);
  yield takeLatest(types.EXPORT_TASK_LISTING, taskListingExportSaga);
  // Owner Dashborad/Projects/Ongoing Project Details/Task Details   end

  //user
  yield takeLatest(types.GET_WORKER_PENDING_TASKS, getWorkerPendingTaskSaga);
  yield takeLatest(
    types.GET_WORKER_COMPLETED_TASKS,
    getWorkerCompletedTaskSaga
  );
  yield takeLatest(
    types.GET_VALIDATOR_PENDING_TASKS,
    getValidatorPendingTaskSaga
  );
  yield takeLatest(
    types.GET_VALIDATOR_COMPLETED_TASKS,
    getValidatorCompletedTaskSaga
  );

  // annotations
  yield takeLatest(types.SAVE_ANNOTATIONS_USER_PENDING, saveAnnotationsSaga);
  yield takeLatest(types.SAVE_VALIDATIONS_USER_PENDING, saveValidationsSaga);
  yield takeLatest(
    types.ACCEPT_REJECT_ANNOTATIONS,
    acceptRejectAnnotationsSaga
  );
  yield takeLatest(types.GET_ANNOTATIONS_LISTS, getAnnotationListsSaga);

  // disputed annotations
  yield takeLatest(types.RAISE_DISPUTE, raiseDisputesSaga);
  yield takeLatest(types.DISPUTE_LISTING, disputeListsSaga);
  yield takeLatest(types.DISPUTE_DETAILS, disputeDetailsSaga);
  yield takeLatest(
    types.ACCEPT_REJECT_DISPUTED_ANNOTATIONS,
    accpetRejectDisputedAnnotsSaga
  );

  // rating
  yield takeLatest(types.RATE_WORKER_BY_VALIDATOR, rateWorkerByValidatorSaga);

  // report
  yield takeLatest(
    types.REPORT_WORKER_BY_VALIDATOR,
    reportWorkerByValidatorSaga
  );

  // Admin
  // owner
  yield takeLatest(types.CREATE_OWNER, createOwnerSaga);
  yield takeLatest(types.UPDATE_OWNER, updateOwnerSaga);
  yield takeLatest(types.DELETE_OWNER, deleteOwnerSaga);
  yield takeLatest(types.BLOCK_UNBLOCK_OWNER, blockUnblockOwnerSaga);
  yield takeLatest(types.GET_OWNER_DETAILS, getOwnerDetailsSaga);

  //user
  yield takeLatest(types.GET_USER_DETAILS, getUserDetailsSaga);
  yield takeLatest(types.USER_DELETE, userDeleteSaga);

  // proj
  yield takeLatest(types.DELETE_PROJECT_ADMIN, deleteProjectSaga);
  yield takeLatest(types.DELETE_TASK_ADMIN, deleteTaskAdminSaga);

  //user profile
  yield takeLatest(types.UPDATE_PROFILE, updateProfileSaga);

  // export data
  yield takeLatest(types.EXPORT_DATA, exportDataSaga);
}
