import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import Wavesurfer from "wavesurfer.js";
import {
    faBackward,
    faForward,
    faPause,
    faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const AudioPlayer = forwardRef((props, ref) => {
    const waveform = useRef(null);
    let waveformRef = useRef(null);
    const { url, loaded } = { ...props }
    const [playing, setPlaying] = useState(false);
    const load = (url) => {
        if (!waveform.current) {
            waveform.current = Wavesurfer.create({
                barGap: 1,
                height: 50,
                barWidth: 3,
                barRadius: 3,
                cursorWidth: 3,
                barMinHeight: 1,
                responsive: true,
                waveColor: "rgb(52 124 210)",
                container: "#waveform",
                cursorColor: "rgb(31, 71, 118)",
                backend: 'MediaElement',
            });
            waveformRef = waveform.current;
        }
        waveform.current && waveform.current.load(url);
    };
    useImperativeHandle(ref, () => ({
        playPause() {
            playAudio()
        },
        forward() {
            frowardAudio()
        },
        backward() {
            backwardAudio()
        },
        onUnload() {
            waveform.current.destroy()
            waveform.current = null
        },
        onLoad(url) {
            load(url)
        }
    }));


    const playAudio = () => {
        waveform.current.playPause();
        setPlaying(waveform.current.isPlaying());
    };
    const frowardAudio = () => {
        waveform.current.skip(0.5)
    };
    const backwardAudio = () => {
        waveform.current.skip(-0.5)
    };

    useEffect(() => {
        load(url)
    }, [loaded])

    return (
        <div>
            <div
                id={"waveform"}
                style={{
                    marginTop: 10,
                    position: "relative",
                    background: "rgb(206 215 232)",
                    boxSizing: "border-box",
                    borderRadius: "10px 10px 0px 0px",
                }}
                ref={waveformRef}
            />
            {/* backward,play,forward */}
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "right",
                    background: "rgb(206 215 232)",
                    boxSizing: "border-box",
                    // bottom corner radius
                    borderRadius: "0px 0px 10px 0",
                    padding: "10px",
                }}
            >

                {/* forward button */}
                <div
                    style={{
                        width: "50px",
                        height: "50px",
                        border: "0px",
                        display: "flex",
                        cursor: "pointer",
                        borderRadius: "10px",
                        alignItems: "center",
                        margin: "1px",
                        justifyContent: "center",
                        background: "rgb(31 71 118)",
                    }}
                    onClick={backwardAudio}
                >
                    <FontAwesomeIcon icon={faBackward} style={{ color: "#fff" }} />
                </div>
                {/* play pause button */}
                <div
                    style={{
                        width: "50px",
                        height: "50px",
                        border: "0px",
                        display: "flex",
                        cursor: "pointer",
                        borderRadius: "10px",
                        alignItems: "center",
                        margin: "1px",
                        justifyContent: "center",
                        background: "rgb(31 71 118)",
                    }}
                    onClick={playAudio}
                >
                    <FontAwesomeIcon
                        icon={playing ? faPause : faPlay}
                        style={{ color: "#fff" }}
                    />
                </div>
                {/* backward button */}
                <div
                    style={{
                        width: "50px",
                        border: "0px",
                        height: "50px",
                        display: "flex",
                        cursor: "pointer",
                        borderRadius: "10px",
                        margin: "1px",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgb(31 71 118)",
                    }}
                    onClick={frowardAudio}
                >
                    <FontAwesomeIcon icon={faForward} style={{ color: "#fff" }} />
                </div>
            </div>
            {/* backward,play,forward */}

        </div>
    )
})

AudioPlayer.displayName = 'AudioPlayer'

export default AudioPlayer