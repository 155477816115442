import Fetch from "../Fetch/DisputeAnnotsApi";
import { put, call } from "redux-saga/effects";
import { disputeListStartLoading, disputeListStopLoading, startLoading, stopLoading } from "../actions/Common";  

 
export function* raiseDisputesSaga({
    payload: { success = () => {}, fail = () => {},body } = {},
  }) {
    try { 
  
      yield put(disputeListStartLoading());
      const result = yield call(Fetch.raiseDisputeApi ,body);
      if (result.status === 1) {  
        success(result?.result);
        // yield put(disputeListStopLoading());
      } else if (result.status === 4) { 
        fail(result);
        yield put(disputeListStopLoading());
      } else {
        fail(result);
        yield put(disputeListStopLoading());
      }
    } catch (error) {
      fail(error);
      yield put(disputeListStopLoading());
    }
  } 
export function* disputeListsSaga({
    payload: { success = () => {}, fail = () => {},body } = {},
  }) {
    try { 
  
      yield put(startLoading());
      const result = yield call(Fetch.disputeListsApi ,body);
      if (result.status === 1) { 
        success(result?.result);
        yield put(stopLoading());
      } else if (result.status === 4) { 
        fail(result);
        yield put(stopLoading());
      } else {
        fail(result);
        yield put(stopLoading());
      }
    } catch (error) {
      fail(error);
      yield put(stopLoading());
    }
  }
export function* disputeDetailsSaga({
    payload: { success = () => {}, fail = () => {},body } = {},
  }) {
    try { 
  
      yield put(startLoading());
      const result = yield call(Fetch.disputeDetailsApi ,body);
      if (result.status === 1) { 
        success(result?.result);
        yield put(stopLoading());
      } else if (result.status === 4) { 
        fail(result);
        yield put(stopLoading());
      } else {
        fail(result);
        yield put(stopLoading());
      }
    } catch (error) {
      fail(error);
      yield put(stopLoading());
    }
  }
export function* accpetRejectDisputedAnnotsSaga({
    payload: { success = () => {}, fail = () => {},body } = {},
  }) {
    try { 
  
      yield put(startLoading());
      const result = yield call(Fetch.accpetRejectDisputedAnnotsApi ,body);
      if (result.status === 1) { 
        success(result?.result);
        yield put(stopLoading());
      } else if (result.status === 4) { 
        fail(result);
        yield put(stopLoading());
      } else {
        fail(result);
        yield put(stopLoading());
      }
    } catch (error) {
      fail(error);
      yield put(stopLoading());
    }
  }
  