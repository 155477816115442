var navigationRef = null;
var dispatchRef = null;

export const setNavigate = (data,dispatch) => {
  navigationRef = data;
  dispatchRef = dispatch;
}; 

export const customNavigate = (path, params = null) => {
  if (params) {
    return navigationRef(path, { state: params })
  }
  return navigationRef(path);
};
export const customDispatch = (data) => {
  dispatchRef(data);
};

export const goBack = () => {
  navigationRef(-1)
};
