import {CREATE_OWNER,UPDATE_OWNER, OWNER_SUCCESS ,SET_OWNER_LIST,DELETE_OWNER,BLOCK_UNBLOCK_OWNER,SET_OWNER_DETAILS, GET_OWNER_DETAILS} from "../actionTypes/actionTypes"

// done
export const createOwner = (payload,navigate) => {
    return {
        type: CREATE_OWNER, 
        payload: payload  ,
        navigate
    }
 
}  
export const updateOwner = (payload,navigate) => {
    return {
        type: UPDATE_OWNER, 
        payload: payload  ,
        navigate
    }
 
}  
export const setOwnerList = (payload) => {
    return {
        type: SET_OWNER_LIST, 
        payload: payload 
    }

}  
export const deleteOwner = (payload) => {
    return {
        type: DELETE_OWNER, 
        payload: payload 
    }

} 
export const setOwnerDetails = (payload) => {
    return {
        type: SET_OWNER_DETAILS, 
        payload: payload 
    }

} 
// export const getOwnerDetails = (payload) => {
//     return {
//         type: GET_USER_DETAILS, 
//         payload: payload 
//     }

// } 
export const getOwnerDetails = () => {
    return {
        type: GET_OWNER_DETAILS, 
    }

} 
export const blockUnblockOwner = (payload) => {
    return {
        type: BLOCK_UNBLOCK_OWNER, 
        payload: payload 
    }

} 
export const createOwnerSuccess = () => {
    return {
        type: OWNER_SUCCESS  
    }

} 