import React from 'react'
import PropTypes from 'prop-types'
const CustomPageHeader = ({ label, wrapperClassName, children, customWrapperStyle }) => {
  return (
    <div className={wrapperClassName} style={customWrapperStyle}>
      <h6 className="text-white text-capitalize ps-3" style={{ color: '#193d64' }}>
        {label}
      </h6>
      {children}
    </div>
  )
}
CustomPageHeader.propTypes = {
  label: PropTypes.string,
  wrapperClassName: PropTypes.string,
  customWrapperStyle: PropTypes.object,
  children: PropTypes.node,
};
export default CustomPageHeader

