import * as Yup from "yup";
// import i18n from "../../../../i18n";
import i18n from "i18next";
import { customFunctions } from "../../../../themes/utils/CustomFunctions";
 
export const constants = {
  formikValidationSchema : (lang) => {
    switch (lang) {
      case 'en':
        return Yup.object().shape({
          ownerFirstName: Yup.string()
            .test( 
              "trim",
              i18n.t("please_enter_first_name"),
              (value) => value?.trim()?.length > 0
            )
            .matches(
              customFunctions.regexSet("personFN").rule,
              customFunctions.regexSet("personFN").msg
            )
            .required(customFunctions.regexSet("personFN").req)
            .min(3, customFunctions.regexSet("personFN", 3, null).min)
            .max(20, customFunctions.regexSet("personFN", null, 20).max),
          ownerLastName: Yup.string()
            .test(
              "trim",
              i18n.t("please_enter_last_name"),
              (value) => value?.trim()?.length > 0
            )
            .matches(
              customFunctions.regexSet("personLN").rule,
              customFunctions.regexSet("personLN").msg
            )
            .required(customFunctions.regexSet("personLN").req)
            .min(3, customFunctions.regexSet("personLN", 3, null).min)
            .max(20, customFunctions.regexSet("personLN", null, 20).max),
          email: Yup.string()
            .test(
              "trim",
              i18n.t("pls_entr_email"),
              (value) => value?.trim()?.length > 0
            )
            .matches(
              customFunctions.regexSet("email").rule,
              customFunctions.regexSet("email").msg
            )
            .email(customFunctions.regexSet("email").msg)
            .required(customFunctions.regexSet("email").req)
            .max(320, customFunctions.regexSet("email", null, 320).max),
          contactNum: Yup.string()
            .test(
              "trim",
              i18n.t("please_enter_contact_num"),
              (value) => value?.trim()?.length > 0
            )
            .matches(
              customFunctions.regexSet("phoneNum").rule,
              customFunctions.regexSet("phoneNum").msg
            )
            .required(customFunctions.regexSet("phoneNum").req),
          countryCode: Yup.string()
            .test(
              "trim",
              i18n.t("please_enter_cntry_code"),
              (value) => value?.trim()?.length > 0
            )
            .matches(
              customFunctions.regexSet("countryCode").rule,
              customFunctions.regexSet("countryCode").msg
            )
            .required(customFunctions.regexSet("countryCode").req),
        });
      case 'ar':
        return Yup.object().shape({
          ownerFirstName: Yup.string()
            .test( 
              "trim",
              i18n.t("please_enter_first_name"),
              (value) => value?.trim()?.length > 0
            ) 
            .required(customFunctions.regexSet("personFN").req)
            .min(3, customFunctions.regexSet("personFN", 3, null).min)
            .max(20, customFunctions.regexSet("personFN", null, 20).max),
          ownerLastName: Yup.string()
            .test(
              "trim",
              i18n.t("please_enter_last_name"),
              (value) => value?.trim()?.length > 0
            ) 
            .required(customFunctions.regexSet("personLN").req)
            .min(3, customFunctions.regexSet("personLN", 3, null).min)
            .max(20, customFunctions.regexSet("personLN", null, 20).max),
          email: Yup.string()
            .test(
              "trim",
              i18n.t("pls_entr_email"),
              (value) => value?.trim()?.length > 0
            )  
            .required(customFunctions.regexSet("email").req)
            .max(320, customFunctions.regexSet("email", null, 320).max),
          contactNum: Yup.string()
            .test(
              "trim",
              i18n.t("please_enter_contact_num"),
              (value) => value?.trim()?.length > 0 
            ) 
            .matches(
              customFunctions.regexSet("phoneNum").rule,
              customFunctions.regexSet("phoneNum").msg
            )
            .required(customFunctions.regexSet("phoneNum").req),
          countryCode: Yup.string()
            .test(
              "trim",
              i18n.t("please_enter_cntry_code"),
              (value) => value?.trim()?.length > 0
            ) 
            .matches(
              customFunctions.regexSet("countryCode").rule,
              customFunctions.regexSet("countryCode").msg
            )
            .required(customFunctions.regexSet("countryCode").req),
        });
        break;
    
      default:
        break;
    }
    
  }, 
};
