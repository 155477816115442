import React from "react";
import { Select } from "antd";
import { Layout } from "antd";

const { Header } = Layout;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const HeaderAdmin = ({
  handlewClick,
}) => {

  return (
    <div>
      <Header
        className="site-layout-sub-header-background"
        style={{ padding: 0 }}
      >
        <Select
          defaultValue="en"

          style={{ width: 222 }}
          name="language"
          className="select_new user_new_select"
        >
          <option title="" color="#fff" className="language-dropdown" value="en">
            English
          </option>
          <option title="" value="fr">Arabic</option>
        </Select>
        <div className="iocns">
          <FontAwesomeIcon
            icon={faBars}
            className="lite-text"
            onClick={handlewClick}
          />
        </div>
      </Header>
    </div>
  );
};

HeaderAdmin.propTypes = {
  handlewClick: PropTypes.func,
};
export default HeaderAdmin;
