import { GET_SKILL_CATEGORIES } from "../actionTypes/actionTypes"

// done
export const getSkillCategories = (payload) => { 
    return {
        type: GET_SKILL_CATEGORIES,
        payload : payload
    }

}
 