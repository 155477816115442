import moment from "moment";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import WorkerTab from "./Tabs/workerTasks";
import { DatePicker, Space } from "antd";
import { useTranslation } from "react-i18next";
import { Container, CardHoc } from "../../../hoc";
import React, { useEffect, useState } from "react";
import { NavigateTo, SidebarMenus } from "../../../themes";
import { saveSearchDate, saveSearchValue } from "../../../redux/actions/Common";

// const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const TaskManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  /* Container props */
  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: t("task_management"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("user"),
    active: t("task_management"),
  };
  const [activeTabIndex, setActiveTabIndex] = useState(false);
  const active = 1;
  // const [searchValue, setSearchValue] = useState("");
  // const [date, setDate] = useState("");
  // const [lang, setLang] = useState(localStorage.getItem("lang"));

  const iStyle = {
    width: "70%", //not default
    height: "35px",
    borderRadius: " 10px",
  };
  const initialValues = {
    searchValue: "",
  };
  const formik = useFormik({
    initialValues,
  });
  /* Container props */

  useEffect(() => {
    window.document.title = "WosoM";
  }, []);

  return (
    <Container
      {...containerProps}
    >
      <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        <div
          style={{
            position: "relative",
          }}
        >
          {/* <Tabs
            defaultActiveKey="1"
            onChange={(val) => {
              setActive(val);
            }}
          >
            <TabPane tab={t("wrker_task")} key={"1"}>
              <WorkerTab
                onTabActive={(isActive) => {
                  setActiveTabIndex(isActive);
                }}
                render={active}
                searchValue={formik.values.searchValue}
              />
            </TabPane>
            <TabPane tab={t("val_task")} key={"2"}>
              <ValidatorTab
                onTabActive={(isActive) => {
                  setActiveTabIndex(isActive);
                }}
                searchValue={formik.values.searchValue}
                render={active}
                date={date}
              />
            </TabPane>
          </Tabs> */}
          {/* <DateRangePicker_SearchBar /> */}
          <div
            style={{
              // position: "relative",
              // width: activeTabIndex ? "50%" : "35%", // default,
              // top: "2%",
              // right: localStorage.getItem("lang") == "ar" ? null : "30px",
              // left: localStorage.getItem("lang") == "ar" ? "30px" : null,
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: "1%",
            }}
          >
            {active == 2 && activeTabIndex && (
              <Space direction="vertical" size={12}>
                <RangePicker
                  placeholder={
                    localStorage.lang == "en"
                      ? ["Start date", "End date"]
                      : ["تاريخ البدء", "تاريخ الانتهاء"]
                  }
                  inputReadOnly
                  style={{ marginRight: "10px" }}
                  onChange={(val) => {
                    // setDate(moment(val[0]).toDate());
                    if (val) {
                      let searchDate = [
                        moment(val[0]).toDate(),
                        moment(val[1]).toDate(),
                      ];
                      localStorage.setItem(
                        "searchDate",
                        JSON.stringify(searchDate)
                      );
                      dispatch(saveSearchDate(searchDate));
                    } else {
                      localStorage.setItem("searchDate", JSON.stringify(null));
                      dispatch(saveSearchDate(null));
                    }
                  }}
                />
              </Space>
            )}
            <input
              style={
                activeTabIndex
                  ? iStyle
                  : { height: "35px", borderRadius: " 10px", marginBottom: "10px" }
              }
              type="text"
              className="search-bar"
              placeholder={t("search")}
              value={formik.values.searchValue}
              onChange={(e) => {
                formik.setValues({
                  searchValue: e.target.value,
                });

                dispatch(saveSearchValue(e.target.value));
              }}
            />
          </div>
          <WorkerTab
            onTabActive={(isActive) => {
              setActiveTabIndex(isActive);
            }}
            render={active}
            searchValue={formik.values.searchValue}
          />

        </div>
      </CardHoc>
    </Container>
  );
};

export default TaskManagement;
