import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function WorkerBar({ total, annotated }) {
    let chart;
    const { t } = useTranslation();
    useEffect(() => {
        chart = am4core.create("chartdiv", am4charts.PieChart);
        chart.startAngle = 160;
        chart.endAngle = 380;

        let colors = [
            am4core.color("#123a65"),
            am4core.color("#184f8b"),
            am4core.color("#1e64b1"),
            am4core.color("#2479d7"),
            am4core.color("#2a8efd"),
            am4core.color("#2a80ff")
        ];
        // Let's cut a hole in our Pie chart the size of 40% the radius
        chart.innerRadius = am4core.percent(40);
        // Add data
        chart.data = [{
            "country": t("annotated"),
            "first": annotated,
            "second": annotated
        }, {
            "country": t("remaining"),
            "first": total - annotated,
            "second": total - annotated
        }];

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "first";
        pieSeries.dataFields.category = "country";
        pieSeries.colors.list = colors;
        // color 194c7f 
        pieSeries.slices.template.stroke = new am4core.InterfaceColorSet().getFor("background");
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 1;

        // Disabling labels and ticks on inner circle
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        // Disable sliding out of slices
        pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        pieSeries.slices.template.states.getKey("hover").properties.scale = 1;
        pieSeries.radius = am4core.percent(40);
        pieSeries.innerRadius = am4core.percent(30);

        // Add second series
        var pieSeries2 = chart.series.push(new am4charts.PieSeries());
        pieSeries2.dataFields.value = "second";
        pieSeries2.dataFields.category = "country";
        pieSeries2.colors.list = colors
        pieSeries2.slices.template.stroke = new am4core.InterfaceColorSet().getFor("background");
        pieSeries2.slices.template.strokeWidth = 1;
        pieSeries2.slices.template.strokeOpacity = 1;
        pieSeries2.slices.template.states.getKey("hover").properties.shiftRadius = 0.05;
        pieSeries2.slices.template.states.getKey("hover").properties.scale = 1;

        pieSeries2.labels.template.disabled = true;
        pieSeries2.ticks.template.disabled = true;


        var label = chart.seriesContainer.createChild(am4core.Label);
        label.textAlign = "middle";
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.adapter.add("text", function () {
            return `[font-size:18px]${t("total")}[/]:\n[bold #214c7d font-size:30px] ${total} [/]`;
        })

        return () => {
            if (chart) {
                chart.dispose();
            }
        };

    }, [total, annotated]);
    return (
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    );
}

WorkerBar.propTypes = {
    total: PropTypes.number,
    annotated: PropTypes.number
};


export default WorkerBar;
