import { LOGOUT, USER_VIEW_ERROR, USER_VIEW_LOAD, USER_VIEW_SUCCESS } from "./../../actionTypes/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    status: 0,
    data: [],
    sportsData: [],
    enableStatus: false,
}

const viewReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
                isLoading: false,
                error: null,
                status: 0,
                data: [],
                sportsData: [],
                enableStatus: false,
            };
        case USER_VIEW_LOAD:
            return {
                ...state,
                isLoading: true
            }
        case USER_VIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                status: action.status,
                data: action.result.data,
            }
        case USER_VIEW_ERROR:
            return {
                ...state,
                isLoading: false
            }

        default:
            return {
                ...state
            }
    }
}

export default viewReducer;