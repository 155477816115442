import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../../hoc";

import {
  PlayCircleOutlined,
  InfoCircleOutlined,
  DoubleRightOutlined
} from "@ant-design/icons";
import {
  AppImage,
  NavigateTo,
  SidebarMenus,
} from "../../../../themes";
import { Modal, Tooltip, Card, Skeleton, Row, Col, Timeline } from "antd";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn";
import { useDispatch } from "react-redux";
import { getTaskDetails } from "../../../../redux/actions/Tasks";
import appconstant from "../../../../themes/appconstant";
import parse from "html-react-parser";
// import { raiseDispute } from "../../../../redux/actions/DisputedAnnots";
import { toast } from "react-toastify";
import WorkerBar from "./workerBar"
import {
  CustomHelp
} from "../../../../molecules";

// const { Panel } = Collapse;

const WorkerPendingTaskDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // local storage get item start
  const { taskId } = useParams(); //localStorage.getItem("workerPendingTaskId");

  // local storage get item end

  //common start
  const textData = []
  // const [vComments, setVComments] = useState([]);
  const [taskIsCompleted, setTaskIsCompleted] = useState(0);
  // const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [taskStatus, setTaskStatus] = useState(null); //location.state?.status
  // const [showCommentModal, setShowCommentModal] = useState(false); // comment MODAL
  // const [showRaiseDispute, setShowRaiseDispute] = useState(null); // raise dispute MODAL
  // const [raiseDisputeApiBody, setRaiseDisputeApiBody] = useState({}); // acceptRejectApiBody
  // const showRaiseDisputeButton = true;
  // const [loading, setLoading] = useState(false);
  // const [isModalVisible, setIsModalVisible] = useState(false); // class list annotation MODAL
  const [tableData, setTableData] = useState([]); // class list annotation MODAL
  const [totalAnnotated, setTotalAnnotated] = useState(0);

  const [taskInfo, setTaskInfo] = useState({
    taskName: "",
    taskType: "",
    instruction: "",
    description: "",
  });
  //common end

  // audio states start
  const [_audioLists, setAudioLists] = useState([]);
  // const [audioListTotal, setAudioListTotal] = useState(0);
  // const [audioIndex, setAudioIndex] = useState("");
  // const [annotationAudioDS, setAnnotationAudioDS] = useState([]);
  // audio states end

  // image states start

  // text states start
  const [textLists, setTextLists] = useState([]);
  const [textListTotal, setTextListTotal] = useState(0);
  // const [annotationTextDS, setAnnotationTextDS] = useState([]);
  // text states end

  const [audio, setAudio] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [classNames, setClassNames] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  // const [imageIndex, setImageIndex] = useState("");
  const [apiJsonImage, setApiJsonImage] = useState(null);
  const [segmentation, setSegmentation] = useState(null);
  // const [annotDetails, setAnnotDetails] = useState([]);
  // const [annotationDS, setAnnotationDS] = useState([]); // annotationImageDS
  const [textDetails, setTextDetails] = useState([]);
  const [classLists, setClassLists] = useState([]);
  const [latestDocuments, setLatestDocuments] = useState([]);
  // const [classListsTotal, setClassListsTotal] = useState(0);
  // const [isModalVisibleQuestions, setIsModalVisibleQuestions] = useState(false);
  const [isModalVisibleInstructions, setIsModalVisibleInstructions] = useState(false);
  // image states start

  // breadcrumb start
  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: t("task_management"),
    },
    {
      link: "",
      label:
        taskStatus == "Completed"
          ? t("wrkr_cmpltd_task_details")
          : t("wrker_pend_task_details"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("user"),
    active: t("task_management"),
  };
  // breadcrumb end

  /* page description start*/

  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: "break-all",
  };
  const pillFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    fontSize: 16,
    wordBreak: "break-all",
    backgroundColor: "#E6F7FF",
    borderRadius: 5,
    padding: "2px 10px",
  };
  const tnl = [
    { labelTitle: t("tsk_name") + " : ", customLabelStyle: leftFontstyle },
    {
      labelTitle: taskInfo.taskName,
      customLabelStyle: rightFontStyle,
    },
  ]; // task name

  const ttl = [
    { labelTitle: t("tsk_typ") + " : ", customLabelStyle: leftFontstyle },
    {
      labelTitle: t(taskInfo.taskType),
      customLabelStyle: rightFontStyle,
    },
  ]; // task type
  const tdl = [
    {
      labelTitle: t("tsk_desc") + " : ",
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo.description,
      customLabelStyle: rightFontStyle,
    },
  ]; // task description

  const tcl = [
    {
      labelTitle: t("tsk_cls") + " : ",
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: classLists
        .map((item) => {
          return item.displayName;
        })
        .join(", "),
      customLabelStyle: pillFontStyle,
    },
  ]; // task classes
  const textFieldlabels = [tnl, ttl, tdl];

  /* page description end*/

  /* dropdown start*/
  // const AnnotationModalProps = {
  //   title: t("annot_list"),
  //   handleOk: () => {
  //     setIsModalVisible(false);
  //   },
  //   onSubmit: () => {
  //     setIsModalVisible(false);
  //   },
  //   modalWidth: 923,
  //   handleCancel: () => {
  //     setIsModalVisible(false);
  //   },
  //   isModalVisible: isModalVisible,
  // };
  const udtBreadCrumbsAr = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: "لوحة القيادة",
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: "ادارة المهام",
    },
    {
      link: NavigateTo.WORKER_PENDING_TASK_DETAILS + `/${taskId}`,
      label:
        taskStatus == "Completed"
          ? "تفاصيل المهمة المنجزة للعامل"
          : "العامل تفاصيل المهمة المعلقة",
    },
    {
      link: "",
      label: "وسم",
    },
  ];
  const udtBreadCrumbsEn = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: "Dashboard",
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: "Task Management",
    },
    {
      link: NavigateTo.WORKER_PENDING_TASK_DETAILS + `/${taskId}`,
      label:
        taskStatus == "Completed"
          ? "Worker Completed Task Details"
          : "Worker Pending Task Details",
    },
    {
      link: "",
      label: "Annotate",
    },
  ];
  const udtContainerProps = {
    breadCrumbItems: {
      en: udtBreadCrumbsEn,
      ar: udtBreadCrumbsAr,
    },
    menuProps: SidebarMenus("user"),
    active: t("task_management"),
  };

  // const ActionColumnImage =
  //   taskStatus == "In Review"
  //     ? {
  //       title: t("action"),
  //       dataIndex: "action",
  //       key: "action",
  //       width: 50,
  //       render: (value, record) => {
  //         return (
  //           <div
  //             style={{
  //               justifyContent: "center",
  //               display: "flex",
  //             }}
  //           >
  //             {record?.annotStatus == 3 ? (
  //               <div
  //                 style={{
  //                   textAlign: "center",
  //                   width: "max-content",
  //                 }}
  //               >
  //                 {showRaiseDisputeButton ? (
  //                   <>
  //                     <button
  //                       type="button"
  //                       className="button-list accordin focus raise-dispute"
  //                       onClick={() => {
  //                         setRaiseDisputeApiBody({
  //                           validator_id: record?.valId,
  //                           anotation_id: record?.annotationId, //anotation id
  //                           status: 4, //2 approve, 3 reject
  //                         }); // hereee
  //                         setShowRaiseDispute(true);
  //                       }}
  //                     >
  //                       {t("raise_dispute")}
  //                     </button>
  //                     <button
  //                       type="button"
  //                       className="button-list accordin focus raise-dispute"
  //                       onClick={() => {
  //                         setVComments(record?.vComments);
  //                         setShowCommentModal(true);
  //                       }}
  //                     >
  //                       {t("view_cmnts")}
  //                     </button>
  //                     <button
  //                       type="submit"
  //                       style={{
  //                         padding: "6px 20px 5px 20px",
  //                       }}
  //                       className="button-list accordin focus raise-dispute"
  //                       onClick={() => {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             udtContainerProps,
  //                             taskType: localStorage.getItem("taskType"),
  //                             removeIcos: true,
  //                             classLists: classNames,
  //                             images: imageArray,
  //                             imageIndex: imageIndex,
  //                             particularAnnotation: record?.annotDetails,
  //                           },
  //                         });
  //                       }}
  //                     >
  //                       {t("view")}
  //                     </button>
  //                   </>
  //                 ) : null}
  //               </div>
  //             ) : record?.annotStatus == 2 ? (
  //               <>
  //                 <button
  //                   type="button"
  //                   style={{
  //                     padding: "6px 20px 5px 20px",
  //                   }}
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                       state: {
  //                         udtContainerProps,
  //                         taskType: localStorage.getItem("taskType"),
  //                         removeIcos: true,
  //                         classLists: classNames,
  //                         images: imageArray,
  //                         imageIndex: imageIndex,
  //                         particularAnnotation: record?.annotDetails,
  //                       },
  //                     });
  //                   }}
  //                 >
  //                   {t("view")}
  //                 </button>
  //               </>
  //             ) : (
  //               <button
  //                 type="button"
  //                 style={{
  //                   padding: "6px 20px 5px 20px",
  //                 }}
  //                 className="button-list accordin focus raise-dispute"
  //                 onClick={() => {
  //                   navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                     state: {
  //                       udtContainerProps,
  //                       taskType: localStorage.getItem("taskType"),
  //                       removeIcos: true,
  //                       classLists: classNames,
  //                       images: imageArray,
  //                       imageIndex: imageIndex,
  //                       particularAnnotation: record?.annotDetails,
  //                     },
  //                   });
  //                 }}
  //               >
  //                 {t("view")}
  //               </button>
  //             )}

  //             {/* </Link> */}
  //           </div>
  //         );
  //       },
  //     }
  //     : {
  //       title: t("action"),
  //       dataIndex: "action",
  //       key: "action",
  //       width: 50,
  //       render: (value, record) => {
  //         return (
  //           <div
  //             style={{
  //               textAlign: "center",
  //               width: "max-content",
  //             }}
  //           >
  //             {taskStatus == "Completed" ? (
  //               <div
  //                 style={{
  //                   textAlign: "center",
  //                   width: "max-content",
  //                 }}
  //               >
  //                 <button
  //                   type="submit"
  //                   style={{
  //                     padding: "6px 20px 5px 20px",
  //                   }}
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                       state: {
  //                         udtContainerProps,
  //                         taskType: localStorage.getItem("taskType"),
  //                         removeIcos: true,
  //                         classLists: classNames,
  //                         images: imageArray,
  //                         imageIndex: imageIndex,
  //                         particularAnnotation: record?.annotDetails,
  //                       },
  //                     });
  //                   }}
  //                 >
  //                   {t("view")}
  //                 </button>
  //               </div>
  //             ) : record?.annotStatus == 3 ? (
  //               <div>
  //                 {showRaiseDisputeButton ? (
  //                   <>
  //                     <button
  //                       type="button"
  //                       className="button-list accordin focus raise-dispute"
  //                       onClick={() => {
  //                         setRaiseDisputeApiBody({
  //                           validator_id: record?.valId,
  //                           anotation_id: record?.annotationId, //anotation id
  //                           status: 4, //2 approve, 3 reject,
  //                         });
  //                         setShowRaiseDispute(true);
  //                       }}
  //                     >
  //                       {t("raise_dispute")}
  //                     </button>
  //                     <button
  //                       type="submit"
  //                       className="button-list accordin focus raise-dispute"
  //                       onClick={() => {
  //                         setVComments(record?.vComments);
  //                         setShowCommentModal(true);
  //                       }}
  //                     >
  //                       {t("view_cmnts")}
  //                     </button>
  //                     <button
  //                       type="submit"
  //                       style={{
  //                         padding: "6px 20px 5px 20px",
  //                       }}
  //                       className="button-list accordin focus raise-dispute"
  //                       onClick={() => {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             udtContainerProps,
  //                             taskType: localStorage.getItem("taskType"),
  //                             removeIcos: true,
  //                             classLists: classNames,
  //                             images: imageArray,
  //                             imageIndex: imageIndex,
  //                             particularAnnotation: record?.annotDetails,
  //                           },
  //                         });
  //                       }}
  //                     >
  //                       {t("view")}
  //                     </button>
  //                   </>
  //                 ) : null}
  //               </div>
  //             ) : (
  //               <button
  //                 type="submit"
  //                 style={{
  //                   padding: "6px 20px 5px 20px",
  //                 }}
  //                 className="button-list accordin focus raise-dispute"
  //                 onClick={() => {
  //                   navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                     state: {
  //                       udtContainerProps,
  //                       taskType: localStorage.getItem("taskType"),
  //                       removeIcos: true,
  //                       classLists: classNames,
  //                       images: imageArray,
  //                       imageIndex: imageIndex,
  //                       particularAnnotation: record?.annotDetails,
  //                     },
  //                   });
  //                 }}
  //               >
  //                 {t("view")}
  //               </button>
  //             )}
  //           </div>
  //         );
  //       },
  //     };
  // const ActionColumnAudio =
  //   taskStatus == "In Review"
  //     ? {
  //       title: t("action"),
  //       dataIndex: "action",
  //       key: "action",
  //       width: 50,

  //       render: (value, record, index) => {
  //         return (
  //           <div
  //             style={{
  //               justifyContent: "center",
  //               display: "flex",
  //             }}
  //           >
  //             {record?.annotStatus == 3 ? (
  //               <div
  //                 style={{
  //                   textAlign: "center",
  //                   width: "max-content",
  //                 }}
  //               >
  //                 <button
  //                   type="submit"
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     setRaiseDisputeApiBody({
  //                       validator_id: record?.valId,
  //                       anotation_id: record?.annotationId, //anotation id
  //                       status: 4, //2 approve, 3 reject
  //                     });
  //                     setShowRaiseDispute(true);
  //                   }}
  //                 >
  //                   {t("raise_dispute")}
  //                 </button>
  //                 <button
  //                   type="submit"
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     setVComments(record?.vComments);
  //                     setShowCommentModal(true);
  //                   }}
  //                 >
  //                   {t("view_cmnts")}
  //                 </button>
  //                 <button
  //                   type="submit"
  //                   style={{
  //                     padding: "6px 20px 5px 20px",
  //                   }}
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     if (taskInfo?.taskType == "audio") {
  //                       let singleAudio = [
  //                         {
  //                           url: annotDetails?.audioUrl,
  //                           jsonFile: record?.individualAnnots, //annotDetails?.jsonFile[index]?.regions, // jsonFile
  //                           task_id: 1,
  //                           anotation_id: 1,
  //                           worker_id: 1,
  //                         },
  //                       ];

  //                       navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                         state: {
  //                           taskType: localStorage.getItem("taskType"),
  //                           udtContainerProps,
  //                           audio: singleAudio,
  //                         },
  //                       });
  //                     }
  //                     if (taskInfo?.taskType == "text") {
  //                       if (localStorage.getItem("textType") == "questions") {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             taskType: localStorage.getItem("taskType"),
  //                             udtContainerProps,
  //                             pdfUrl: record?.pdfUrl,
  //                             questions: record?.questions,
  //                             particularAnnotation: record?.savedAnnotation,
  //                             qnindex: index,
  //                             tableData: record?.pdfUrl,
  //                           },
  //                         });
  //                       }
  //                       if (
  //                         localStorage.getItem("textType") == "segmentation"
  //                       ) {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             taskType: localStorage.getItem("taskType"),
  //                             udtContainerProps,
  //                             particularAnnotation: record?.particularAnnot,
  //                             segmentAllAnnots: record?.segmentAllAnnots,
  //                             classes: classLists,
  //                             totalAnnots: record?.totalAnnots,
  //                             paragraph: record?.paragraph,
  //                           },
  //                         });
  //                       }
  //                     }
  //                   }}
  //                 >
  //                   {t("view")}
  //                 </button>
  //               </div>
  //             ) : record?.annotStatus == 2 ? (
  //               <>
  //                 <button
  //                   type="submit"
  //                   className="button-list accordin focus raise-dispute"
  //                   disabled
  //                   style={{
  //                     background:
  //                       "linear-gradient(180deg, #a0aab5 0%, #a2a2a2e0 100%)",
  //                     boxShadow: "0px 0px 4px rgb(101 101 101 / 61%)",
  //                     cursor: "no-drop",
  //                   }}
  //                 >
  //                   {t("raise_dispute")}
  //                 </button>
  //                 <button
  //                   type="submit"
  //                   style={{
  //                     padding: "6px 20px 5px 20px",
  //                   }}
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     if (taskInfo?.taskType == "audio") {
  //                       let singleAudio = [
  //                         {
  //                           url: annotDetails?.audioUrl,
  //                           jsonFile: record?.individualAnnots, //annotDetails?.jsonFile[index]?.regions, // jsonFile
  //                           task_id: 1,
  //                           anotation_id: 1,
  //                           worker_id: 1,
  //                         },
  //                       ];

  //                       navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                         state: {
  //                           taskType: localStorage.getItem("taskType"),
  //                           udtContainerProps,
  //                           audio: singleAudio,
  //                         },
  //                       });
  //                     }
  //                     if (taskInfo?.taskType == "text") {
  //                       if (localStorage.getItem("textType") == "questions") {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             taskType: localStorage.getItem("taskType"),
  //                             udtContainerProps,
  //                             pdfUrl: record?.pdfUrl,
  //                             questions: record?.questions,
  //                             particularAnnotation: record?.savedAnnotation,
  //                             qnindex: index,
  //                             tableData: record?.pdfUrl,
  //                           },
  //                         });
  //                       }
  //                       if (
  //                         localStorage.getItem("textType") == "segmentation"
  //                       ) {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             taskType: localStorage.getItem("taskType"),
  //                             udtContainerProps,
  //                             particularAnnotation: record?.particularAnnot,
  //                             segmentAllAnnots: record?.segmentAllAnnots,
  //                             classes: classLists,
  //                             totalAnnots: record?.totalAnnots,
  //                             paragraph: record?.paragraph,
  //                           },
  //                         });
  //                       }
  //                     }
  //                   }}
  //                 >
  //                   {t("view")}
  //                 </button>
  //               </>
  //             ) : (
  //               <button
  //                 type="submit"
  //                 style={{
  //                   padding: "6px 20px 5px 20px",
  //                 }}
  //                 className="button-list accordin focus raise-dispute"
  //                 onClick={() => {
  //                   if (taskInfo?.taskType == "audio") {
  //                     let singleAudio = [
  //                       {
  //                         url: annotDetails?.audioUrl,
  //                         jsonFile: record?.individualAnnots, //annotDetails?.jsonFile[index]?.regions, // jsonFile
  //                         task_id: 1,
  //                         anotation_id: 1,
  //                         worker_id: 1,
  //                       },
  //                     ];

  //                     navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                       state: {
  //                         taskType: localStorage.getItem("taskType"),
  //                         udtContainerProps,
  //                         audio: singleAudio,
  //                       },
  //                     });
  //                   }
  //                   if (taskInfo?.taskType == "text") {
  //                     if (localStorage.getItem("textType") == "questions") {
  //                       navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                         state: {
  //                           taskType: localStorage.getItem("taskType"),
  //                           udtContainerProps,
  //                           pdfUrl: record?.pdfUrl,
  //                           questions: record?.questions,
  //                           particularAnnotation: record?.savedAnnotation,
  //                           qnindex: index,
  //                           tableData: record?.pdfUrl,
  //                         },
  //                       });
  //                     }
  //                     if (
  //                       localStorage.getItem("textType") == "segmentation"
  //                     ) {
  //                       navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                         state: {
  //                           taskType: localStorage.getItem("taskType"),
  //                           udtContainerProps,
  //                           particularAnnotation: record?.particularAnnot,
  //                           segmentAllAnnots: record?.segmentAllAnnots,
  //                           classes: classLists,
  //                           totalAnnots: record?.totalAnnots,
  //                           paragraph: record?.paragraph,
  //                         },
  //                       });
  //                     }
  //                   }
  //                 }}
  //               >
  //                 {t("view")}
  //               </button>
  //             )}

  //             {/* </Link> */}
  //           </div>
  //         );
  //       },
  //     }
  //     : {
  //       title: t("action"),
  //       dataIndex: "action",
  //       key: "action",
  //       width: 50,

  //       render: (value, record, index) => {
  //         return (
  //           <div
  //             style={{
  //               textAlign: "center",
  //               width: "max-content",
  //             }}
  //           >
  //             {taskStatus == "Completed" ? (
  //               <div
  //                 style={{
  //                   textAlign: "center",
  //                   width: "max-content",
  //                 }}
  //               >
  //                 <button
  //                   type="submit"
  //                   style={{
  //                     padding: "6px 20px 5px 20px",
  //                   }}
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     if (taskInfo?.taskType == "audio") {
  //                       let singleAudio = [
  //                         {
  //                           url: annotDetails?.audioUrl,
  //                           jsonFile: record?.individualAnnots, //annotDetails?.jsonFile[index]?.regions, // jsonFile
  //                           task_id: 1,
  //                           anotation_id: 1,
  //                           worker_id: 1,
  //                         },
  //                       ];

  //                       navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                         state: {
  //                           taskType: localStorage.getItem("taskType"),
  //                           udtContainerProps,
  //                           audio: singleAudio,
  //                         },
  //                       });
  //                     }
  //                     if (taskInfo?.taskType == "text") {
  //                       if (localStorage.getItem("textType") == "questions") {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             taskType: localStorage.getItem("taskType"),
  //                             udtContainerProps,
  //                             pdfUrl: record?.pdfUrl,
  //                             questions: record?.questions,
  //                             particularAnnotation: record?.savedAnnotation,
  //                             qnindex: index,
  //                             tableData: record?.pdfUrl,
  //                           },
  //                         });
  //                       }
  //                       if (
  //                         localStorage.getItem("textType") == "segmentation"
  //                       ) {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             taskType: localStorage.getItem("taskType"),
  //                             udtContainerProps,
  //                             particularAnnotation: record?.particularAnnot,
  //                             segmentAllAnnots: record?.segmentAllAnnots,
  //                             classes: classLists,
  //                             totalAnnots: record?.totalAnnots,
  //                             paragraph: record?.paragraph,
  //                           },
  //                         });
  //                       }
  //                     }
  //                   }}
  //                 >
  //                   {t("view")}
  //                 </button>
  //               </div>
  //             ) : record?.annotStatus == 3 ? (
  //               <div>
  //                 <button
  //                   type="submit"
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     setRaiseDisputeApiBody({
  //                       validator_id: record?.valId,
  //                       anotation_id: record?.annotationId, //anotation id
  //                       status: 4, //2 approve, 3 reject
  //                     });
  //                     setShowRaiseDispute(true);
  //                   }}
  //                 >
  //                   {t("raise_dispute")}
  //                 </button>
  //                 <button
  //                   type="submit"
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     setVComments(record?.vComments);
  //                     setShowCommentModal(true);
  //                   }}
  //                 >
  //                   {t("view_cmnts")}
  //                 </button>
  //                 <button
  //                   type="submit"
  //                   style={{
  //                     padding: "6px 20px 5px 20px",
  //                   }}
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     if (taskInfo?.taskType == "audio") {
  //                       let singleAudio = [
  //                         {
  //                           url: annotDetails?.audioUrl,
  //                           jsonFile: record?.individualAnnots,
  //                           task_id: 1,
  //                           anotation_id: 1,
  //                           worker_id: 1,
  //                         },
  //                       ];

  //                       navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                         state: {
  //                           taskType: localStorage.getItem("taskType"),
  //                           udtContainerProps,
  //                           audio: singleAudio,
  //                         },
  //                       });
  //                     }
  //                     if (taskInfo?.taskType == "text") {
  //                       if (localStorage.getItem("textType") == "questions") {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             taskType: localStorage.getItem("taskType"),
  //                             udtContainerProps,
  //                             pdfUrl: record?.pdfUrl,
  //                             questions: record?.questions,
  //                             particularAnnotation: record?.savedAnnotation,
  //                             qnindex: index,
  //                             tableData: record?.pdfUrl,
  //                           },
  //                         });
  //                       }
  //                       if (
  //                         localStorage.getItem("textType") == "segmentation"
  //                       ) {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             taskType: localStorage.getItem("taskType"),
  //                             udtContainerProps,
  //                             particularAnnotation: record?.particularAnnot,
  //                             segmentAllAnnots: record?.segmentAllAnnots,
  //                             classes: classLists,
  //                             totalAnnots: record?.totalAnnots,
  //                             paragraph: record?.paragraph,
  //                           },
  //                         });
  //                       }
  //                     }
  //                   }}
  //                 >
  //                   {t("view")}
  //                 </button>
  //               </div>
  //             ) : record?.annotStatus == 2 ? (
  //               <>
  //                 <button
  //                   type="submit"
  //                   className="button-list accordin focus raise-dispute"
  //                   disabled
  //                   style={{
  //                     background:
  //                       "linear-gradient(180deg, #a0aab5 0%, #a2a2a2e0 100%)",
  //                     boxShadow: "0px 0px 4px rgb(101 101 101 / 61%)",
  //                     cursor: "no-drop",
  //                   }}
  //                 >
  //                   {t("raise_dispute")}
  //                 </button>
  //                 <button
  //                   type="submit"
  //                   style={{
  //                     padding: "6px 20px 5px 20px",
  //                   }}
  //                   className="button-list accordin focus raise-dispute"
  //                   onClick={() => {
  //                     if (taskInfo?.taskType == "audio") {
  //                       let singleAudio = [
  //                         {
  //                           url: annotDetails?.audioUrl,
  //                           jsonFile: record?.individualAnnots, //annotDetails?.jsonFile[index]?.regions, // jsonFile
  //                           task_id: 1,
  //                           anotation_id: 1,
  //                           worker_id: 1,
  //                         },
  //                       ];

  //                       navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                         state: {
  //                           taskType: localStorage.getItem("taskType"),
  //                           udtContainerProps,
  //                           audio: singleAudio,
  //                         },
  //                       });
  //                     }
  //                     if (taskInfo?.taskType == "text") {
  //                       if (localStorage.getItem("textType") == "questions") {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             taskType: localStorage.getItem("taskType"),
  //                             udtContainerProps,
  //                             pdfUrl: record?.pdfUrl,
  //                             questions: record?.questions,
  //                             particularAnnotation: record?.savedAnnotation,
  //                             qnindex: index,
  //                             tableData: record?.pdfUrl,
  //                           },
  //                         });
  //                       }
  //                       if (
  //                         localStorage.getItem("textType") == "segmentation"
  //                       ) {
  //                         navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                           state: {
  //                             taskType: localStorage.getItem("taskType"),
  //                             udtContainerProps,
  //                             particularAnnotation: record?.particularAnnot,
  //                             segmentAllAnnots: record?.segmentAllAnnots,
  //                             classes: classLists,
  //                             totalAnnots: record?.totalAnnots,
  //                             paragraph: record?.paragraph,
  //                           },
  //                         });
  //                       }
  //                     }
  //                   }}
  //                 >
  //                   {t("view")}
  //                 </button>
  //               </>
  //             ) : (
  //               <button
  //                 type="submit"
  //                 style={{
  //                   padding: "6px 20px 5px 20px",
  //                 }}
  //                 className="button-list accordin focus raise-dispute "
  //                 onClick={() => {
  //                   if (taskInfo?.taskType == "audio") {
  //                     let singleAudio = [
  //                       {
  //                         url: annotDetails?.audioUrl,
  //                         jsonFile: record?.individualAnnots, //annotDetails?.jsonFile[index]?.regions, // jsonFile
  //                         task_id: 1,
  //                         anotation_id: 1,
  //                         worker_id: 1,
  //                       },
  //                     ];

  //                     navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                       state: {
  //                         taskType: localStorage.getItem("taskType"),
  //                         udtContainerProps,
  //                         audio: singleAudio,
  //                       },
  //                     });
  //                   }

  //                   if (taskInfo?.taskType == "text") {
  //                     if (localStorage.getItem("textType") == "questions") {
  //                       navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                         state: {
  //                           taskType: localStorage.getItem("taskType"),
  //                           udtContainerProps,
  //                           pdfUrl: record?.pdfUrl,
  //                           questions: record?.questions,
  //                           particularAnnotation: record?.savedAnnotation,
  //                           classes: classLists,
  //                           qnindex: index,
  //                         },
  //                       });
  //                     }

  //                     if (
  //                       localStorage.getItem("textType") == "segmentation"
  //                     ) {
  //                       navigate(NavigateTo.USER_UDT + `/${taskId}`, {
  //                         state: {
  //                           taskType: localStorage.getItem("taskType"),
  //                           udtContainerProps,
  //                           particularAnnotation: record?.particularAnnot,
  //                           segmentAllAnnots: record?.segmentAllAnnots,
  //                           classes: classLists,
  //                           totalAnnots: record?.totalAnnots,
  //                           paragraph: record?.paragraph,
  //                         },
  //                       });
  //                     }
  //                   }
  //                 }}
  //               >
  //                 {t("view")}
  //               </button>
  //             )}
  //           </div>
  //         );
  //       },
  //     };
  function containsArabic(str) {
    return /[\u0600-\u06FF]/.test(str);
  }
  // image class start
  // const classListHeaders = [
  //   {
  //     label: "",
  //     headerWidth: "150px",
  //   },
  //   {
  //     label: t("clss_name"),
  //     headerWidth: "150px",
  //   },
  //   {
  //     label: t("annotations"),
  //     headerWidth: "210px",
  //   },
  //   {
  //     label: t("action"),
  //     paddingLeft: "70px",
  //     headerWidth: "80px",
  //   },
  // ]; // outer list table
  // const annotationC = [
  //   {
  //     title: t("sr_no"),
  //     dataIndex: "srno",
  //     key: "srno",
  //     width: 100,
  //     render: (value, item, index) => index + 1,
  //   },
  //   {
  //     title: t("annot_name"),
  //     dataIndex: "annotation_name",
  //     key: "annotation_name",
  //     width: 50,
  //     sorter: false,

  //     render: (value) => {
  //       return (
  //         <Tooltip placement="topLeft" title={value}>
  //           {value}
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   {
  //     title: t("file_id"),
  //     dataIndex: "file_id",
  //     key: "file_id",
  //     width: 100,
  //     sorter: false,
  //     render: (value) => {
  //       const tempVal =
  //         localStorage.lang == "ar"
  //           ? value
  //             .split("")
  //             .reverse()
  //             .concat("")
  //           : value;

  //       return (
  //         <Tooltip placement="topLeft" title={tempVal}>
  //           {tempVal}
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   {
  //     title: t("status"),
  //     dataIndex: "status",
  //     key: "status",
  //     width: 200,
  //     sorter: false,
  //     render: (i, rec) => t(rec?.statusToshow),
  //   },
  //   {
  //     title: t("class"),
  //     dataIndex: "class",
  //     key: "class",
  //     width: 250,
  //     sorter: false,
  //     render: (value) => {
  //       return (
  //         <Tooltip placement="topLeft" title={value}>
  //           {value}
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   ActionColumnImage,
  // ]; // modal table
  // image class end

  // // audio start
  // const audio_and_tex_list_headers = [
  //   {
  //     label: t("file_name"),
  //     dataIndex: "0",
  //     key: "0",
  //     width: 150,
  //   },
  //   {
  //     label: t("annotations"),
  //     dataIndex: "1",
  //     key: "1",
  //     width: 150,
  //   },
  //   {
  //     label: t("validations"),
  //     dataIndex: "2",
  //     key: "2",
  //     width: 150,
  //   },
  //   // {
  //   //   label: t("action"),
  //   //   paddingLeft: taskInfo?.taskType == "audio" ? 0 : 0,
  //   //   paddingRight:
  //   //     taskInfo?.taskType == "audio" || taskInfo?.taskType == "text"
  //   //       ? taskStatus == "Completed"
  //   //         ? "50px"
  //   //         : null
  //   //       : 0,
  //   //   headerWidth:
  //   //     taskInfo?.taskType == "text" &&
  //   //       localStorage.getItem("textType") == "questions"
  //   //       ? "100px"
  //   //       : taskInfo?.taskType == "audio"
  //   //         ? taskStatus == "Completed"
  //   //           ? "100px"
  //   //           : "140px"
  //   //         : taskStatus == "Completed"
  //   //           ? "100px"
  //   //           : "337px",
  //   //   textAlign: "center",
  //   // },
  // ]; // outer list table
  // const annotationAudioC = [
  //   {
  //     title: t("sr_no"),
  //     dataIndex: "srno",
  //     key: "srno",
  //     width: 150,
  //     render: (value, item, index) => index + 1,
  //   },
  //   {
  //     title: t("annot_name"),
  //     dataIndex: "annotation_name",
  //     key: "annotation_name",
  //     width: 200,
  //     sorter: false,
  //     render: (value) => {
  //       return (
  //         <Tooltip placement="topLeft" title={value}>
  //           {value}
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   {
  //     title: t("status"),
  //     dataIndex: "status",
  //     key: "status",
  //     width: 200,
  //     sorter: false,
  //     render: (i, rec) => t(rec?.statusToshow),
  //   },
  //   ActionColumnAudio,
  // ]; // modal table

  // const dropDownGroup = [
  //   {
  //     table: {
  //       placeholder: t("doc_list"),
  //       dropDownName: "Class",
  //       tableHeaders: classListHeaders,
  //       tableContents: classLists, //state,
  //       tableTotal: classListsTotal, //state,
  //       ModalH: annotationC,
  //       ModalC: annotationDS, // state
  //       listLength: classLists.length,
  //       ModalProps: AnnotationModalProps,
  //       tableContentsHasArrayOFObj: true,
  //       tBodyClassName: "last-child",
  //       tableClassName:
  //         localStorage.getItem("lang") == "ar"
  //           ? "text_data user-task-management-worker-pending-task-details-class-list-ar"
  //           : "text_data user-task-management-worker-pending-task-details-class-list-en",
  //     },
  //   }, // class lists
  //   {
  //     table: {
  //       placeholder:
  //         taskInfo?.taskType == "audio" ? t("audio_list") : t("text_lists"),
  //       tableHeaders: audio_and_tex_list_headers,
  //       tableContents: taskInfo?.taskType == "audio" ? audioLists : textLists,
  //       tableTotal: classListsTotal, //state,
  //       tdClassName:
  //         taskInfo?.taskType == "audio" ||
  //           (taskInfo?.taskType == "text" &&
  //             localStorage.getItem("textType") == "segmentation")
  //           ? "workerpendingaudioannot"
  //           : "workerpendingqnannot ",
  //       tdImageWidth: taskInfo?.taskType == "text" ? "unset" : null,
  //       tdImageHeight: taskInfo?.taskType == "text" ? "unset" : null,
  //       tdImageBorderRadius: taskInfo?.taskType == "text" ? "unset" : null,
  //       tableContentsHasArrayOFObj: true,
  //       tBodyClassName: "last-child",
  //       // AnnotClassName: "wptd",
  //       // ModalProps: AnnotationModalProps,
  //       tableClassName:
  //         localStorage.getItem("lang") == "ar"
  //           ? "text_data user-task-manage-worker-pending-task-details-class-list-ar"
  //           : "text_data user-task-manage-worker-pending-task-details-class-list-en",
  //       paginationTotal: taskInfo?.taskType == "audio" ? audioListTotal : textListTotal,
  //     },
  //   }, // audio Lists
  // ];
  /* dropdown end*/
  // fill function start
  const fillTaskDetails = (res) => {
    setTaskInfo({
      taskName: res?.name,
      taskType:
        res?.task_type === "transcription"
          ? "audio"
          : res?.task_type == "segmentation" ||
            res?.task_type == "classification" ||
            res?.task_type == "text_transcription"
            ? "text"
            : "image",
      taskTypeVal: res?.task_type,
      taskTypeDisplay: t(res?.task_type),
      taskStatus:
        res?.data?.status == 0
          ? "pending"
          : res?.data?.status == 1
            ? "in_review"
            : res?.data?.status == 2
              ? "completed"
              : "pending",
      instruction: res?.instructions,
      description: res?.description,
      taskId: res?.id,
      workerType: res?.type,
    });
    // setClassListsTotal(res?.documents?.length);
    localStorage.setItem("instruction", res?.instructions);

    let statusTemp =
      res?.data?.status == 0
        ? "Pending"
        : res?.data?.status == 1
          ? "In Review"
          : res?.data?.status == 2
            ? "Completed"
            : "Pending";
    setTaskStatus(statusTemp);
    localStorage.setItem("annotType", res?.task_type);
    if (res?.task_type == "bbox" || res?.task_type == "polygon") {
      localStorage.setItem("taskType", "Image");

      let classNames = [];
      res?.class_list.map((item) => {
        classNames.push(item?.class_code);
      });

      setClassNames(classNames);

      let tempClassLists = [];
      let tempImageArray = [];
      let tempApiJsonImage = [];


      res?.documents.map((item, index) => {
        let imageDetails = {};
        imageDetails["annotationId"] = item?.id;
        imageDetails["jsonFile"] = item?.annotations; //jsonFile?.regions;
        imageDetails["annotStatus"] = item?.status;
        imageDetails["vComments"] = item?.comment;
        let tempRegion = [];

        if (item?.annotations?.length != 0) {
          tempRegion =
            item?.annotations[item?.annotations.length - 1]?.annotations;
        }
        var classNames_list = [];
        tempRegion.forEach((ann) => {
          if (ann?.cls) classNames_list.push(ann?.cls);
        });
        tempClassLists.push({
          0: { image: appconstant.imageBaseUrl + item?.path },
          1:
            classNames_list.length > 0
              ? classNames_list.join(",")
              : t("no_class"),
          2: item?.annotations?.length > 0 ? item?.annotations[0]?.annotations?.length : 0,
          3: {
            button: "View Annotations",
            link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
            id: imageDetails, // send anything like image id item?.id or annot details
          },
        }); // tempClassLists
        tempImageArray.push({
          name: `Image-${index + 1}`,
          src: appconstant.imageBaseUrl + item?.path,
          regions: item?.annotations.length != 0 ? tempRegion : [],
          id: item?.id,
        }); // tempImageArray
        tempApiJsonImage.push({
          // jsonFile: item?.jsonFile != null ? item?.jsonFile : [], // jsonFile
          task_id: item?.task_id,
          anotation_id: item?.id,
          worker_id: item?.worker_id, //item?.worker_id?.id,
          media_id: item?.id,
        }); // tempApiJsonImage
      });
      setClassLists(tempClassLists);
      setImageArray(tempImageArray);


      setApiJsonImage(tempApiJsonImage);
    } // image

    if (
      res?.task_type == "segmentation" ||
      res?.task_type == "classification" ||
      res?.task_type == "text_transcription"
    ) {
      localStorage.setItem("taskType", "Text");
      if (res?.task_type == "segmentation") {
        localStorage.setItem("textType", "segmentation");
        textFieldlabels.push(tcl);
        let tempTextLists = [];
        let tempClassLists = [];
        let annotCount = 0;
        res?.documents.map((item) => {
          //.substring(0, 15) + "..."; //.split("/");
          let textDetails = {
            jsonFileForStartTask: item?.annotation
              ? item?.annotation[item?.annotation.length - 1]?.jsonFile
              : null,
            jsonFile: item?.annotation,
            paragraph: item?.paragraph,
            totalTasks: res?.documents,

            task_id: item?.task_id,
            anotation_id: item?.id,
            worker_id: item?.worker_id,
            media_id: item?.id,
          };
          let tempButton =
            statusTemp != "Completed"
              ? [
                {
                  label: "View Annotations",
                  tdCustomstyle: {
                    display: "table-cell",
                  },
                  link: "", // NavigateTo.ADMIN_PROJ_TASK_DETAILS,
                  customBtnStyle: {
                    padding: "5px 24px",
                    borderRadius: "7px",
                    width: "185px",
                  },
                  id: textDetails,
                },
                {
                  label: "Start Task", //t("start_task")
                  tdCustomstyle: {
                    display: "table-cell",
                  },
                  link: "",
                  customBtnStyle: {
                    padding: "5px 24px",
                    borderRadius: "7px",
                  },
                  id: textDetails,
                },
              ]
              : [
                {
                  label: "View Annotations", //t("view_annotations"),
                  tdCustomstyle: {
                    display: "table-cell",
                  },
                  link: "", // NavigateTo.ADMIN_PROJ_TASK_DETAILS,
                  customBtnStyle: {
                    padding: "5px 24px",
                    borderRadius: "7px",
                    width: "185px",
                  },
                  id: textDetails,
                },
              ];
          tempTextLists.push({
            0: { image: AppImage.file_n },
            1: item?.paragraph,
            2: item?.annotation ? item?.annotation?.length : 0,
            3: {
              button: tempButton,
            },
          }); // tempTextLists
        });
        setTextLists(tempTextLists);
        setSegmentation(tempTextLists);
        setTaskIsCompleted(annotCount);
        setTextListTotal(res?.document_count);
        setTotalAnnotated(res?.total_annotated);

        res?.class_list?.map((item) => {
          tempClassLists.push({
            id: item?.class_code,
            displayName: item?.class_name,
            task_id: item?.task_id,
          });
        });
        setClassLists(tempClassLists);
      }
      if (res?.task_type == "text_transcription") {
        localStorage.setItem("textType", "text_transcription");
        textFieldlabels.push(tcl);
        let tempTextLists = [];
        let annotCount = 0;
        let tempTextDetails = [];

        res?.documents.map((item) => {
          tempTextDetails.push({
            jsonFile: item?.annotations,
            paragraph: item?.paragraph,
            task_id: item?.task_id,
            anotation_id: item?.id,
            worker_id: item?.worker_id,
            media_id: item?.id,
          });
          tempTextLists.push({
            0: {
              image: AppImage.file_n,
              text: item?.paragraph?.length > 30 ? item?.paragraph?.substring(0, 30) + "..." : item?.paragraph,
              tooltip: item?.paragraph,
              ar: containsArabic(item?.paragraph),
              id: item?.id,
            },
            1:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.text
                : t("no_annot"),
            2:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.validation?.validated == true
                  ? item?.annotations[0]?.validation?.is_valid == true
                    ? t("valid")
                    : `${t("invalid")} (${item?.annotations[0]?.validation
                      ?.comment || t("no_cmnts")})`
                  : t("no_val")
                : t("no_val"),
          }); // tempTextLists
        });
        setTextLists(tempTextLists);
        setTextDetails(tempTextDetails);
        setTaskIsCompleted(annotCount);
        setTextListTotal(res?.document_count);
        setTotalAnnotated(res?.total_annotated);
      }
      if (res?.task_type == "classification") {
        localStorage.setItem("textType", "classification");
        let tempTextLists = [];
        const tempQuestions = [];
        let annotCount = 0;

        res?.question_list?.map((item, index) => {
          tempQuestions.push({
            name: `input${index}`,
            type: item?.question_type,
            title: item?.question,
            choices: item?.answers.map((ans) => ans?.answer) || [],
            conditional: item?.answers.map((ans) => ans?.conditional) || [],
          });
        });

        res?.documents.map((item) => {
          if (item?.annotations?.length == tempQuestions.length) {
            annotCount++;
          }

          tempTextLists.push({
            0: {
              image: AppImage.file_n,
              text: item?.paragraph,
            },
            1:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.annotation.length > 0
                  ? item?.annotations[0].annotation.map((annot) => {
                    return `${annot?.question} : ${annot?.answer}`;
                  }).join(" | ")
                  : t("no_annot")
                : t("no_annot"),
            2:
              item?.annotations?.length > 0
                ? item?.annotations[0]?.validation?.validated == true
                  ? item?.annotations[0]?.validation?.is_valid == true
                    ? t("valid")
                    : `${t("invalid")} (${item?.annotations[0]?.validation
                      ?.comment || t("no_cmnts")})`
                  : t("no_val")
                : t("no_val"),
          }); // tempTextLists
        });
        let docs = res?.latest_documents.map((item) => {
          let ann = item?.annotations[0];
          return {
            dot: <InfoCircleOutlined style={{ fontSize: "16px", color: "#316293" }} />,
            children: (
              <Card>
                {t("annotated")} <b>{ann?.annotation[0]?.answer}</b> &nbsp;
                {t("at")}&nbsp;
                <b>{new Date(item?.created_at).toLocaleString()}</b> {t("on_object")} •
                <CustomHelp
                  modal={
                    <>
                      <p>
                        {item?.paragraph}
                      </p>
                      <p>
                        <b>{ann?.annotation[0]?.answer}</b>
                      </p>
                    </>
                  } title={item?.paragraph?.length > 15 ? item?.paragraph?.substring(0, 15) + "..." : item?.paragraph}></CustomHelp>
              </Card>
            ),
          }
        });
        docs = docs.reverse();
        setLatestDocuments(docs);
        setQuestions(tempQuestions);
        setTextLists(tempTextLists);
        setTaskIsCompleted(annotCount);
        setTableData(res?.documents);
        setTextListTotal(res?.document_count);
        setTotalAnnotated(res?.total_annotated);
      }
    } // text

    // audio
    if (res?.task_type == "transcription") {
      localStorage.setItem("taskType", "Audio");

      let tempAudioArray = [];
      let tempAudioLists = [];

      res?.documents.map((item) => {
        let audioDetails = {};

        let audioFileName = item?.path.includes("/")
          ? item?.path.split("/")
          : item?.path.split("\\");
        audioDetails["jsonFile"] = item?.annotations;
        audioDetails["audioUrl"] = item?.url;

        audioDetails["annotStatus"] = item?.status;
        audioDetails["vComments"] = item?.comment;
        audioDetails["annotationId"] = item?.id;
        tempAudioLists.push({
          0: {
            image: AppImage.volumeFileImg,
            text: audioFileName[audioFileName.length - 1],
          },
          1:
            item?.annotations?.length > 0
              ? item?.annotations[0].text !== ""
                ? item?.annotations[0].text
                : t("no_annot")
              : t("no_annot"),
          2:
            item?.annotations?.length > 0
              ? item?.annotations[0]?.validation?.validated == true
                ? item?.annotations[0]?.validation?.is_valid == true
                  ? t("valid")
                  : `${t("invalid")} (${item?.annotations[0]?.validation
                    ?.comment || t("no_cmnts")})`
                : t("no_val")
              : t("no_val"),
        }); // tempAudioLists

        tempAudioArray.push({
          // name: `Audio-${index + 1}`,
          url: item?.url,
          jsonFile:
            item?.annotations != null
              ? item?.annotations[item?.annotations.length - 1]
              : [], // jsonFile
          task_id: item?.task_id,
          anotation_id: item?.id,
          worker_id: item?.worker_id,
          media_id: item?.id,
        });
      });
      let docs = res?.latest_documents.map((item, index) => {
        let ann = item?.annotations[0];
        return {
          dot: <InfoCircleOutlined style={{ fontSize: "16px", color: "#316293" }} />,
          children: (
            <Card>
              {t("annotated")} <b>{ann?.text?.length > 15 ? ann?.text?.substring(0, 15) + "..." : ann?.text}</b>
              <br />
              {t("at")} &nbsp;
              <b>{new Date(item?.created_at).toLocaleString()}</b> {t("on_object")} • # {index + 1}

            </Card>
          ),
        }
      });
      docs = docs.reverse();
      setLatestDocuments(docs);
      setAudioLists(tempAudioLists);
      setAudio(tempAudioArray);
      setTextListTotal(res?.document_count);
      setTotalAnnotated(res?.total_annotated);
    } // audio
  };

  // payload start

  const payload = {
    body: {
      taskId,
      user_id: Number(localStorage.getItem("loggedInUserId")),
      pageNumber: 1,
      pageSize: 10,
    },
    success: (res) => {
      fillTaskDetails(res);
    },
    fail: (resf) => {
      resf?.data &&
        toast.error(resf?.data, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
        });
      resf?.message &&
        toast.error(resf?.message, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
        });
    },
  }; //  task details info payload
  // const payloadRaiseDispute = {
  //   body: JSON.stringify(raiseDisputeApiBody),
  //   success: (res) => {
  //     toast.success(res?.data?.message, {
  //       className:
  //         localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
  //       position:
  //         localStorage.lang == "ar"
  //           ? toast.POSITION.TOP_LEFT
  //           : toast.POSITION.TOP_RIGHT,

  //       toastId: "raisedDispute",
  //     });
  //     setShowRaiseDispute(false);
  //     isInternetConnected("task") && dispatch(getTaskDetails(payload));
  //   },
  //   fail: () => {
  //     console.log("");
  //   },
  // }; //  task details info payload

  // payload end

  // use effect start
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("textType");
    localStorage.removeItem("instruction");
    localStorage.removeItem("annotType");
    localStorage.removeItem("taskType");
    localStorage.removeItem("questionType");
    localStorage.removeItem("tableData");
    localStorage.removeItem("indexx");
    isInternetConnected("task") && dispatch(getTaskDetails(payload));
  }, []);
  // useEffect(() => {
  //   window.document.title = "WosoM";
  //   const modalPopUpCrossButton = document.querySelectorAll(
  //     'button[aria-label="Close"]'
  //   );
  //   const annotModalTabelCells = document.querySelectorAll(
  //     ".worker-pending-annot-modal th, .worker-pending-annot-modal td"
  //   );
  //   const tableHeaderOneSideMargins = document.querySelectorAll(
  //     ".ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before"
  //   );

  //   // const modalContent = document.querySelector('.ant-modal-content') ;
  //   if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
  //     for (let i = 0; i < modalPopUpCrossButton.length; i++) {
  //       modalPopUpCrossButton[i].style.right = "unset";
  //       modalPopUpCrossButton[i].style.left = "0";
  //     }
  //     if (isModalVisible) {
  //       document.querySelector(".ant-modal-content").style.direction = "rtl";
  //       for (let i = 0; i < annotModalTabelCells.length; i++) {
  //         const element = annotModalTabelCells[i];
  //         element.style.textAlign = "right";
  //       }
  //       for (let i = 0; i < tableHeaderOneSideMargins.length; i++) {
  //         const element = tableHeaderOneSideMargins[i];
  //         element.style.right = "unset";
  //         element.style.left = "0px";
  //       }
  //     }
  //   }
  //   if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
  //     for (let i = 0; i < modalPopUpCrossButton.length; i++) {
  //       modalPopUpCrossButton[i].style.right = "0";
  //       modalPopUpCrossButton[i].style.left = "unset";
  //     }
  //     if (isModalVisible) {
  //       document.querySelector(".ant-modal-content").style.direction = "ltr";

  //       for (let i = 0; i < annotModalTabelCells.length; i++) {
  //         const element = annotModalTabelCells[i];
  //         element.style.textAlign = "left";
  //       }
  //     }
  //   }
  // }, [isModalVisible]);
  // use effect end

  return (<Container
    {...containerProps}
    layoutClassName={
      localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
    }
  >
    <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
      {taskInfo?.taskName !== "" ? (
        <>
          <Card
            className="border-radius-lg"
            bodyStyle={{
              padding: "10px",
            }}
            style={{
              marginBottom: "20px",
              marginTop: "30px",
            }}>

            <div style={{
              display: "flex",
            }}>
              {/* name */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}>

                <div style={{
                  fontSize: "1.2rem",
                  color: "#214c7d",
                  // fontWeight: "600",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}>
                  <Tooltip title={t(taskInfo?.taskStatus)} placement="top">
                    <img
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      src={
                        taskStatus == "Pending"
                          ? AppImage.pendingTask
                          : taskStatus == "Completed"
                            ? AppImage.completedTask
                            : taskStatus == "In Review"
                              ? AppImage.inReviewTask
                              : AppImage.pendingTask
                      }
                    />
                  </Tooltip>
                  <b>{taskInfo?.taskName}</b>
                  {taskStatus != "Completed" && taskInfo?.taskType != "" ? (
                    <button
                      type="button"
                      style={{
                        marginLeft: "10px",
                        padding: "6px",
                      }}
                      className={"pt-10 btn-trans"}
                      onClick={() => {
                        setIsModalVisibleInstructions(true);
                      }}
                    >
                      <InfoCircleOutlined style={{
                        fontSize: "1.2rem",
                      }} />
                    </button>
                  ) : null}
                </div>
                <div style={{
                  fontSize: "1rem",
                  color: "#3a3a3a",
                  // fontWeight: "600",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}>
                  <b>{t("description")}</b>: {taskInfo?.description}
                </div>
              </div>

              <div style={{
                display: "flex",
                marginLeft: "auto",
                marginRight: "10px",
              }}>
                {taskStatus != "Completed" && taskInfo?.taskType != "" ? (
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                  }} >
                    <Tooltip title={t("start_task")} placement="top">
                      <button
                        type="submit"
                        className={"button-list bottom_button pt-10 focus"}
                        onClick={() => {
                          if (taskInfo.taskType == "image") {
                            if (imageArray.length) {
                              //  for image
                              navigate(NavigateTo.WORKER_PENDING_UDT, {
                                state: {
                                  udtContainerProps,
                                  apiJsonImage: apiJsonImage,
                                  classLists: classNames,
                                  images: imageArray,
                                  imageIndex: 0,
                                },
                              }); // UDT SCREEN
                            }
                          }
                          if (taskInfo.taskType == "audio") {
                            navigate(NavigateTo.WORKER_PENDING_UDT, {
                              state: {
                                udtContainerProps,
                                audio: audio,
                                workerType: taskInfo?.workerType,
                              },
                            }); // UDT SCREEN
                          }
                          if (taskInfo.taskType == "text") {
                            localStorage.setItem(
                              "questionType",
                              JSON.stringify(questions)
                            );
                            localStorage.setItem("indexx", JSON.stringify(0));
                            // localStorage.setItem("tableData", JSON.stringify(tableData));
                            localStorage.setItem("tableDataTotal", textListTotal);
                            //  if (localStorage.getItem("textType") == "segmentation") {
                            let paragraph = textLists[0]?.[1];

                            navigate(NavigateTo.WORKER_PENDING_UDT, {
                              state: {
                                udtContainerProps,
                                textData: textData,
                                questions: questions, // questions
                                segmentation: segmentation, // segmentation
                                paragraph: paragraph, // paragraph
                                paragraphs: textDetails, // text_transcription
                                classes: classLists, // segmentation
                                tableData: tableData, // classification
                                isCompleted: taskIsCompleted,
                                task_id: taskInfo?.taskId,
                                workerType: taskInfo?.workerType,
                                unAnnotated: false,
                              },
                            });
                            // } // UDT SCREEN only in text case
                          }
                        }}
                      >
                        <PlayCircleOutlined style={{
                          fontSize: "2.2rem",
                        }} />
                      </button>
                    </Tooltip>
                    <Tooltip title={t("start_task_con")} placement="top">
                      <button
                        type="submit"
                        className={"button-list bottom_button2 pt-10 active"}
                        onClick={() => {
                          if (taskInfo.taskType == "image") {
                            if (imageArray.length) {
                              //  for image
                              navigate(NavigateTo.WORKER_PENDING_UDT, {
                                state: {
                                  udtContainerProps,
                                  apiJsonImage: apiJsonImage,
                                  classLists: classNames,
                                  images: imageArray,
                                  imageIndex: 0,
                                },
                              }); // UDT SCREEN
                            }
                          }
                          if (taskInfo.taskType == "audio") {
                            navigate(NavigateTo.WORKER_PENDING_UDT, {
                              state: {
                                udtContainerProps,
                                audio: audio,
                                workerType: taskInfo?.workerType,
                              },
                            }); // UDT SCREEN
                          }
                          if (taskInfo.taskType == "text") {
                            localStorage.setItem(
                              "questionType",
                              JSON.stringify(questions)
                            );
                            localStorage.setItem("indexx", JSON.stringify(0));
                            // localStorage.setItem("tableData", JSON.stringify(tableData));
                            localStorage.setItem("tableDataTotal", textListTotal);
                            //  if (localStorage.getItem("textType") == "segmentation") {
                            let paragraph = textLists[0]?.[1];

                            navigate(NavigateTo.WORKER_PENDING_UDT, {
                              state: {
                                udtContainerProps,
                                textData: textData,
                                questions: questions, // questions
                                segmentation: segmentation, // segmentation
                                paragraph: paragraph, // paragraph
                                paragraphs: textDetails, // text_transcription
                                classes: classLists, // segmentation
                                tableData: tableData, // classification
                                isCompleted: taskIsCompleted,
                                task_id: taskInfo?.taskId,
                                workerType: taskInfo?.workerType,
                                unAnnotated: true,
                              },
                            });
                            // } // UDT SCREEN only in text case
                          }
                        }}
                      >
                        <DoubleRightOutlined style={{
                          fontSize: "2.2rem",
                        }} />
                      </button>
                    </Tooltip>
                  </div>
                ) : null}
              </div>
            </div>
          </Card>
        </>) :
        <Skeleton style={{
          padding: "10px",
        }} active />
      }
    </CardHoc>

    {taskInfo?.taskName !== "" ? (
      <div className="collapse-details">
        <Row>
          <Col xs={24} md={8} lg={10}>
            <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
              <Card className="border-radius-lg "
                style={{
                  marginBottom: "20px",
                }}>
                <div style={{
                  fontSize: "1.2rem",
                  color: "#214c7d",
                  // fontWeight: "600",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}>
                  {t("progress")}
                </div>
                <div
                  style={{
                    marginTop: "12px",
                    padding: "1%",
                    minHeight: "300px",
                  }}
                >
                  {textListTotal > 0 ? (
                    <WorkerBar total={textListTotal} annotated={totalAnnotated} />
                  ) : (
                    <p style={{ color: "#acb2ba" }}>{t('ann_wil_appear_here')}</p>
                  )}

                </div>
              </Card>
            </CardHoc>
          </Col>
          <Col xs={24} md={8} lg={14}>
            <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
              <Card className="border-radius-lg "
                style={{
                  marginBottom: "20px",
                }}>
                <div style={{
                  fontSize: "1.2rem",
                  color: "#214c7d",
                  // fontWeight: "600",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}>
                  {t("annotations")}
                </div>
                <div
                  style={{
                    padding: "1%",
                    height: "500px",
                    overflowY: "scroll",
                  }}
                >
                  {totalAnnotated > 0 ? (
                    <Timeline
                      style={{
                        margin: "3% 4% 0% 4%",
                        padding: "2%",
                        maxHeight: "350px",
                        overflowY: "scroll",
                      }}
                    >
                      {latestDocuments.map((item, index) => {
                        return (
                          <Timeline.Item
                            key={index}
                            dot={item?.dot ? item?.dot : null}
                            color={item?.color ? item?.color : "blue"}
                          >
                            {item?.children}
                          </Timeline.Item>
                        );
                      })}
                    </Timeline>
                  ) : (
                    <p style={{ color: "#acb2ba" }}>{t('ann_wil_appear_here')}</p>
                  )}
                </div>
              </Card>
            </CardHoc>
          </Col>
        </Row>
      </div>
    ) : (
      <Skeleton style={{
        padding: "10px",
      }} active />
    )}

    {/* instructions modal */}
    <Modal
      title={t("instructions")}
      open={isModalVisibleInstructions}
      onCancel={() => {
        setIsModalVisibleInstructions(false);
      }}
      footer={null}
      width={1000}
    >
      <div className="form-group">
        <div
          className="get-original-text-color"
          style={{
            marginTop: "12px",
            padding: "20px",
            minHeight: "500px",
            fontSize: "calc(12px + 0.5vw)"
          }}
        >
          <div className="ck-content">
            {parse(taskInfo?.instruction ? taskInfo?.instruction : `<p style={{ color: "#acb2ba" }}>{t("inst_wil_appear_here")}</p>`)}
          </div>
        </div>
      </div>
    </Modal>
  </Container>
  );
};

export default WorkerPendingTaskDetails;
