import React, { useEffect, useState } from "react";
import { NavigateTo, SidebarMenus, AppImage } from "../../../themes";
import { ContainerAdmin, CardHoc } from "../../../hoc";
import { Row, Col, Card, Timeline } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getAdminDashboardData } from "../../../redux/actions/Dashboard";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { useDispatch } from "react-redux";

import {
  faUser,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faTag
} from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComplexLine from "./main_chart";
import StoragePie from "./sotrage_pie";

const AdminDashboard = () => {
  function thousands_separators(num) {
    if (num === 0 || num == NaN) return 0;
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return num_parts.join(".");
  }

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [adminData, setAdminData] = useState({
    total_users: 0,
    total_invites: 0,
    total_tasks: 0,
    total_annotations: 0,
    total_exports: 0,
    server_status: "",
    cache_status: null,
    db_status: null,
    file_status: null,
    latest_activity: null,
    chart_data: null,
  });
  const name = localStorage.getItem("fullName");
  const breadCrumbItems = [
    {
      link: NavigateTo.ADMIN_DASH_BOARD,
      label: t('dashboard'),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("admin"),
    active: t('dashboard'),
  };
  const iconStyle = {
    fontSize: "5vh",
    color: "#143c64",
    marginBottom: "10px",
  }
  const infoItems = [
    {
      "title": t("server_status"),
      "label": adminData?.server_status !== "" ? (<span style={{
        color: "#54bf1f",
      }}>{adminData?.server_status}</span>) : (<span style={{
        color: "#ff0000",
      }}>offline</span>)
    },
    {
      "title": t("cache_status"),
      "label": adminData?.cache_status !== null ? <span style={{
        color: "#54bf1f",
      }}>{adminData?.cache_status?.value}</span> :
        <span style={{
          color: "#ff0000",
        }}>offline</span>
    },
    {
      "title": t("database_status"),
      "label": adminData?.db_status ? <span style={{
        color: "#54bf1f",
      }}>{adminData?.db_status?.readtime}s</span> : <span style={{
        color: "#ff0000",
      }}>offline</span>
    },
  ];
  const infoItems2 = [
    {
      "icon": <FontAwesomeIcon icon={faUser} style={iconStyle} />,
      "title": t("users"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: adminData?.total_users
        }
      ]
    },
    {
      "icon": <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />,
      "title": t("invites"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: adminData?.total_invites
        }
      ]
    },
    {
      "icon": <FontAwesomeIcon icon={faBars} style={iconStyle} />,
      "title": t("tasks"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: adminData?.total_tasks
        }
      ]
    },
    {
      "icon": <FontAwesomeIcon icon={faTag} style={iconStyle} />,
      "title": t("annotations"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: thousands_separators(adminData?.total_annotations)
        }
      ]
    },
    {
      "icon": <FontAwesomeIcon icon={faUser} style={iconStyle} />,
      "title": t("exports"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: adminData?.total_exports
        }
      ]
    },
  ];
  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
    setAdminData({
      ...adminData,
      server_status: t("down"),
      cache_status: null,
    })
    let payload = {
      body: {
        dashType: "quicklinks"
      },
      success: (res) => {
        let latest_activity = Object.keys(res?.latest_activity).map((item) => {
          let data = res?.latest_activity[item];
          if (!data?.by) return null;
          return {
            dot: <InfoCircleOutlined style={{ fontSize: "16px", color: "#316293" }} />,
            children: (
              <p>
                <b>{t(item)}</b> {t("created")} <b>{new Date(data?.at).toLocaleString()}</b> &nbsp; {t("by")} {data?.by}
              </p >
            ),
          }
        }) || [];
        setAdminData({
          ...adminData,
          total_users: res?.total_users,
          total_invites: res?.total_invites,
          total_tasks: res?.total_tasks,
          total_annotations: res?.total_annotations,
          total_exports: res?.total_exports,
          server_status: t("up"),
          cache_status: res?.cache_status,
          db_status: res?.db_status,
          file_status: res?.file_status,
          latest_activity: latest_activity,
          chart_data: res?.chart_data,
        })
      }
    }
    isInternetConnected() && dispatch(getAdminDashboardData(payload))
  }, []);

  return (
    <ContainerAdmin
      {...containerProps}
      languageChanged={({ }) => {
        setLang(localStorage.getItem("lang"));
      }}
    >
      <div
        className="site-layout-background dashboard"
        style={{ minHeight: 360 }}
      >
        <Row>
          <Col xs={24} md={12} lg={12}>
            <div
              className="card card-sm"
              style={{
                margin: "20px 20px 0 20px",
                background: "transparent",
                border: "none",
                boxShadow: "none",
              }}>
              <h2 style={{
                fontWeight: "600",
                color: "#214c7d"
              }}>
                {t("welcome")}
              </h2>
              <div style={{
                display: "flex",
                alignItems: "center",
              }}>

                <div
                  style={{
                    border: "2px solid #193d64",
                    borderRadius: "50px",
                    width: " 50px",
                    height: " 50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(localStorage.getItem("user_type") == "owner" ? NavigateTo.OWNER_PROFILE : NavigateTo.USER_PROFILE);
                  }}
                >
                  <img
                    style={{
                      borderRadius: "35px",
                      width: " 46px",
                      height: " 46px",
                    }}
                    alt=""
                    src={localStorage.getItem("userImage") && localStorage.getItem("userImage") != "" ? localStorage.getItem("userImage") : AppImage.defaultImage}
                  />
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                }}>

                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "25px",
                      color: "#316293",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // width: name.length > 8 ? 103 : 82,
                    }}
                  >
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                    // onClick={() => setShowSkillModal(true)}
                    >
                      {name?.length > 18 ? name?.substring(0, 8) + "..." : name || ""}
                    </span>
                    {/* {localStorage.getItem('fullName')} */}
                  </label>
                  <label
                    style={{
                      fontWeight: "400",
                      fontSize: "15px",
                      color: "#333",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // width: name.length > 8 ? 103 : 82,
                    }}
                  >
                    {new Date().toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' })}, {new Date().toLocaleDateString(lang, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                  </label>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} md={16} lg={12}>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              placeContent: "center",
              alignContent: "center",
              minHeight: "100%",
            }}>
              {/* stat cards */}
              <div style={{
                display: "flex",
              }}>
                {infoItems.map((item, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                          justifyContent: "end",
                          marginBottom: "10px",
                        }}
                      >
                        <h3 style={{
                          fontSize: "1rem",
                          color: "#a3a3a3",
                          fontWeight: "400",
                          padding: " 8px"
                        }}>
                          {item.title}
                        </h3>
                        <p
                          style={{
                            fontSize: "1.3rem",
                            fontWeight: "600",
                          }}
                        >
                          {item.label}
                        </p>
                      </div>
                      <hr className="vertical"></hr>
                    </>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24} lg={24}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "100%",
            }}>
              <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                <Card className="border-radius-lg ">
                  <div style={{
                    fontSize: "1.2rem",
                    color: "#214c7d",
                    marginBottom: "10px",
                  }}>
                    {t("overview")}
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} >
                    {adminData?.chart_data ? (
                      <ComplexLine dates={adminData?.chart_data} />
                    ) : (
                      <p style={{ color: "#acb2ba" }}>{t('ann_wil_appear_here')}</p>
                    )}
                  </div>
                </Card>
              </CardHoc>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12} lg={7}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "100%",
            }}>
              <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                <Card className="border-radius-lg "
                  style={{
                    marginBottom: "20px",
                  }}>
                  <div style={{
                    fontSize: "1.2rem",
                    color: "#214c7d",
                    marginBottom: "10px",
                  }}>
                    {t("statistics")}
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} >
                    {/* stat cards */}
                    <div style={{
                      display: "flex",
                      flexDirection: "column",

                    }}>
                      {infoItems2.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="card card-sm"
                            style={{
                              marginBottom: "10px",

                            }}
                          >
                            <h1 style={{
                              fontSize: "1rem",
                              color: "#214c7d",
                              fontWeight: "600",
                              padding: " 8px"
                            }}>
                              {item.title}
                            </h1>
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}>
                              <div
                                style={{
                                  position: "relative",
                                  padding: "0 24px",
                                }}
                              >
                                {item.icon}
                              </div>
                              <div className="stats" style={{ display: "flex" }}>
                                {item.items.map((item, index2) => {
                                  return (
                                    <div
                                      key={index2}
                                      style={{
                                        position: "relative",
                                        padding: "0 24px",
                                      }}
                                      className="stat-card"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h3
                                          style={{
                                            fontSize: "1.2rem",
                                            color: "#214c7d",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item.value}
                                        </h3>
                                        <p
                                          style={{
                                            fontSize: "1rem",
                                            color: "#3a3a3a",
                                          }}
                                        >
                                          {item.label}
                                        </p>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Card>
              </CardHoc>
            </div>
          </Col>
          <Col xs={24} md={12} lg={7}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "100%",
            }}>
              <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                <Card className="border-radius-lg ">
                  <div style={{
                    fontSize: "1.2rem",
                    color: "#214c7d",
                    marginBottom: "10px",
                  }}>
                    {t("latest_activity")}
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "500px",
                  }} >
                    {adminData?.latest_activity ? (
                      <Timeline
                        style={{
                          margin: "3% 4% 0% 4%",
                          padding: "2%",
                          maxHeight: "600px",
                          overflowY: "scroll",
                        }}
                      >
                        {adminData?.latest_activity.map((item, index) => {
                          return (
                            <Timeline.Item
                              key={index}
                              dot={item?.dot ? item?.dot : null}
                              color={item?.color ? item?.color : "blue"}
                            >
                              {item?.children}
                            </Timeline.Item>
                          );
                        })}
                      </Timeline>
                    ) : (
                      <p style={{ color: "#acb2ba" }}>{t('ann_wil_appear_here')}</p>
                    )}
                  </div>
                </Card>
              </CardHoc>
            </div>
          </Col>
          <Col xs={24} md={12} lg={10}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "100%",
            }}>
              <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                <Card className="border-radius-lg ">
                  <div style={{
                    fontSize: "1.2rem",
                    color: "#214c7d",
                    marginBottom: "10px",
                  }}>
                    {t("storage")}
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} >
                    {adminData?.file_status ? (
                      <StoragePie sizes={adminData?.file_status} />) : (
                      <p style={{ color: "#acb2ba" }}>{t('ann_wil_appear_here')}</p>
                    )}
                  </div>
                </Card>
              </CardHoc>
            </div>
          </Col>
        </Row>
      </div>
    </ContainerAdmin>
  );
};
export default AdminDashboard;
