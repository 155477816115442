import React from "react";
import { CardHoc } from "../../../../../hoc";
// import { Tabs } from "antd";
import TabScreen from "./tabScreen";
// import { useTranslation } from "react-i18next";

// const { TabPane } = Tabs;
const WorkerTasks = (props) => {
  const {
    // onTabActive = () => { },
    searchValue
  } = { ...props };
  // const { t } = useTranslation();
  // const [tabName, setTabName] = useState('');  
  return (
    <CardHoc cardPadding={'-5px 10px'} cardInnerPadding={'content-e remove_top_padding'}>
      <TabScreen taskStatus={'pending'} tabKey={'1'} searchValue={searchValue} />
      {/* <Tabs
        defaultActiveKey="1"
        onChange={() => {
          onTabActive(false);
          //  setTabName(val)
        }}
      >

        <TabPane tab={t('pending')} key={"1"}>
          <TabScreen taskStatus={'pending'} tabKey={'1'} searchValue={searchValue} />
        </TabPane>
        <TabPane tab={t('completed')} key={"2"}>
          <TabScreen taskStatus={'completed'} tabKey={'2'} searchValue={searchValue} />
        </TabPane>
      </Tabs> */}
    </CardHoc>

  );
};

export default WorkerTasks;
