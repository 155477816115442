import React, { useState, useEffect } from 'react'
import { AppImage } from '../../themes'
import PropTypes from "prop-types";
import { Tooltip, Popover, Modal } from "antd";
const CustomHelp = ({
    title = 'Dummy',
    labelRef,
    labels,
    customStyle,
    icon,
    tooltip,
    popover,
    popoverTitle,
    popoverWidth = "auto",
    popoverIcon,
    popoverOpen = false,
    popoverPlacement = "rightTop",
    modal,
    modalWidth = 800,
    onModalOpen,
    onModalClose,
    onClick,
    onLabelIconTap,
    triggerHandler
}) => {
    const [infoClicked, setInfoClicked] = useState(false);
    const [popoverOpenState, setPopoverOpenState] = useState(popoverOpen);
    const lang = localStorage.getItem("lang");
    
    useEffect(() => {
        setPopoverOpenState(popoverOpen);
    }, [popoverOpen]);

    return (
        labels && labels?.length ? (
            labels.map((item, index) => {
                return (
                    <label key={index} style={item.customLabelStyle}>
                        {item.labelTitle}
                    </label>
                );
            })
        ) : (
            <label style={customStyle} ref={labelRef} onClick={onClick}>
                {triggerHandler === undefined && title}
                <>
                    {tooltip && (
                        <Tooltip title={tooltip} overlayStyle={{
                            direction: lang === "ar" ? "rtl" : "ltr",
                            textAlign: lang === "ar" ? "right" : "left",
                        }}>
                            {
                                triggerHandler ? triggerHandler : (
                                    <span
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={onLabelIconTap}
                                    >
                                        <img
                                            src={AppImage.question}
                                            className={"labelIcon"}
                                            onClick={onLabelIconTap}

                                        />
                                    </span>
                                )
                            }
                        </Tooltip>
                    )}
                    {popover && (
                        <Popover
                            placement={popoverPlacement}
                            title={popoverTitle}
                            content={popover}
                            trigger="click"
                            open={popoverOpenState}
                            overlayClassName="custom-popover"
                            overlayStyle={{
                                width: popoverWidth,
                                direction: lang === "ar" ? "rtl" : "ltr",
                                textAlign: lang === "ar" ? "right" : "left",
                            }}>
                            {
                                triggerHandler ? triggerHandler : (
                                    <span
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={onLabelIconTap}
                                    >
                                        <img
                                            src={popoverIcon || AppImage.question}
                                            className={"labelIcon"}
                                            onClick={() => {
                                                onLabelIconTap && onLabelIconTap();
                                                setPopoverOpenState(!popoverOpenState)
                                            }}

                                        />
                                    </span>
                                )
                            }
                        </Popover>
                    )}
                    {modal && (
                        <>
                            {
                                triggerHandler ?
                                    <span
                                        onClick={() => {
                                            onModalOpen && onModalOpen();
                                            setInfoClicked(true);
                                        }}
                                    >
                                        {triggerHandler}
                                    </span> : (
                                        <span
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                onModalOpen && onModalOpen();
                                                setInfoClicked(true);
                                            }}
                                        >
                                            <img
                                                src={AppImage.question}
                                                className={"labelIcon"}
                                                onClick={onLabelIconTap}

                                            />
                                        </span>
                                    )
                            }
                            <Modal
                                title={title}
                                width={modalWidth}
                                open={infoClicked}
                                footer={[]}
                                onCancel={() => {
                                    setInfoClicked(false);
                                    onModalClose && onModalClose();
                                }}
                                style={{
                                    direction: lang === "ar" ? "rtl" : "ltr",
                                    textAlign: lang === "ar" ? "right" : "left",
                                }}
                            >
                                {modal}
                            </Modal>
                        </>
                    )}
                    {icon && (
                        <Tooltip title={title || t("click_to_edit")}>
                            <span
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={onLabelIconTap}
                            >
                                <img
                                    src={icon}
                                    className={"labelIcon"}
                                    onClick={onLabelIconTap}

                                />
                            </span>
                        </Tooltip>
                    )}
                </>
            </label>

        )
    )
}

CustomHelp.propTypes = {
    title: PropTypes.string,
    labelRef: PropTypes.object,
    labels: PropTypes.array,
    customStyle: PropTypes.object,
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    popover: PropTypes.object,
    popoverTitle: PropTypes.string,
    popoverWidth: PropTypes.string,
    popoverIcon: PropTypes.string,
    popoverOpen: PropTypes.bool,
    popoverPlacement: PropTypes.string,
    modal: PropTypes.object,
    modalWidth: PropTypes.number,
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    onLabelIconTap: PropTypes.func,
    onClick: PropTypes.func,
    triggerHandler: PropTypes.object
}

export default CustomHelp;