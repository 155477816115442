import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useLocation, useNavigate } from "react-router";
import { verifyRegisteredEmail } from "../../../redux/actions/Common";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { AppImage, NavigateTo } from "../../../themes";

const VerifyRegisteredEmail = () => {
  const location = useLocation();
  const token = location.pathname.split("/")[2];

  const [messageToDisplay, setMessageToDisplay] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // alert(token);
    const payload = {
      body: JSON.stringify({
        resetToken: token,
      }),
      success: (res) => { 
        setMessageToDisplay(res);
      },
      fail: (resf) => { 
        setMessageToDisplay(resf);
        
      },
    };
    isInternetConnected() && dispatch(verifyRegisteredEmail(payload));
  }, []);

  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="line_true stopOverFlow">
          <div className="content-e  ">
            <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <div className="text-center cost">
                <img src={AppImage.logo3} /> 
                  </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "60px",
                paddingBottom: "60px" 
              }}
            >
              <h6
                style={{
                  marginBottom: "25px",
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "29px",
                  marginBottom: "37px",
                  color: "#143c64",
                  marginTop: "12px",
                }}
              className="text-white  ps-3 headg_d">
                 {/* text-capitalize */}
                {messageToDisplay}
              </h6>
            </div>
            <div
              className="data_line"
              style={{ marginTop: 0, paddingTop: 0 }}
              onClick={() => navigate(NavigateTo.LOGIN)}
            >
              <button type="button" className="button-list focus">
                {"Go to login"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyRegisteredEmail;
