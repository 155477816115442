import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isInternetConnected } from "../../../../../../themes/utils/internetConnectionn";
import {
  AcceptModal,
  RejectModal,
} from "../../../validatorPendingTaskDeatils/Modals";
import { getTaskDetails } from "../../../../../../redux/actions/Tasks";
import UDT from "./Udt";
import { useParams } from "react-router";
import {
  stopLoading,
  startLoading,
} from "../../../../../../redux/actions/Common";
import Emitter from "../../../../../../themes/utils/CustomFunctions/Emiter";

const ImageAnnotate = (props) => {
  const {
    particularAnnotation,
    disputed = false,
    // imageIndex,
  } = {
    ...props,
  };
  const { taskId ,image_index} = useParams();
  const dispatch = useDispatch();
  image_index && localStorage.setItem('imageIndex',image_index);


  const imageArray = useSelector(
    (state) => state?.userTaskManagement?.imageArray
  );
  const classNames = useSelector(
    (state) => state?.userTaskManagement?.classNames
  );
  const itr = useSelector((state) => state?.userTaskManagement?.itr);

  const [acceptRejectApiBody, setAcceptRejectApiBody] = useState(); // accept modal
  let acceptRejectApiBodyy = acceptRejectApiBody;

  const [showAccpetModal, setShowAcceptModal] = useState(false); // accept modal
  const [showRejectModal, setShowRejectModal] = useState(false); // reject modal
  const [showCommentModal, setShowCommentModal] = useState(false); // comment modal
  const [rerender, setRerender] = useState(false); // comment modal
 
  const acceptAnnot = () => {
     const tempimageArray = JSON.parse(localStorage.getItem("imageArray"));
    tempimageArray[localStorage.getItem("imageIndex")].regions.map((item) => {
      if (item?.id == localStorage.getItem("clickedRegionId")) {
          setAcceptRejectApiBody(item?.aceeptRejectBody);
      } 
    });
    setShowAcceptModal(true);
   
  };
  const rejectAnnot = () => {
     const tempimageArray = JSON.parse(localStorage.getItem("imageArray"));
    tempimageArray[localStorage.getItem("imageIndex")].regions.map((item) => {
      if (item?.id == localStorage.getItem("clickedRegionId"))
        setAcceptRejectApiBody(item?.aceeptRejectBody);
      setShowRejectModal(true);
    });
  };  
  Emitter.on('accept',acceptAnnot);
  Emitter.on('reject',rejectAnnot); 
  const getTaskDetailss = () => {
    const tempBody =
      localStorage.getItem("workerPendingTaskId") ||
      localStorage.getItem("workerCompletedTaskId")
        ? {
            taskId,
            user_id: Number(localStorage.getItem("loggedInUserId")),
          }
        : localStorage.getItem("user_type") == 2
        ? {
            taskId,
            type: "owner",
          }
         : {
            taskId,
          };
    const payload = {
      body: tempBody,
      fail: (resf) => {
        console.log(resf, "resf of task details.");
        resf?.data &&
          toast.error(resf?.data, {
           
        className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

          });
        resf?.message &&
          toast.error(resf?.message, {
            
        className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

          });
      },
    }; //  task details info payload
    isInternetConnected("task") && dispatch(getTaskDetails(payload));
  };
  useEffect(() => {
    localStorage.setItem("showAccept", "true");
    return () => {
      localStorage.setItem("__REACT_WORKSPACE_LAYOUT_EXPANDED", false);
    };
  }, []); //showTickOrNot
  useEffect(() => {
      setRerender(!rerender);
      dispatch(startLoading());
      setTimeout(() => {
        setRerender(true); 
        // addButtons(true);
        dispatch(stopLoading()); 
      }, 50);
    
  }, [itr]); 
  useEffect(() => {
    (disputed == false || disputed == undefined) && getTaskDetailss();
    return () => {
      localStorage.removeItem("imageArray");
      localStorage.removeItem("clickedRegionId");
      localStorage.removeItem("regionId");
    };
  }, []);  
  return (
    <div className="image-completed-udt">
      {rerender ? (
        <UDT
          classLists={classNames}
          images={disputed ? [particularAnnotation] : imageArray}
          disputed={disputed}
          itrr={itr}
          rerender={rerender}  
        />
      ) : null}

      <AcceptModal
        acceptRejectApiBody={acceptRejectApiBodyy}
        showAccpetModal={showAccpetModal}
        hideModal={(hide) => {
          setShowAcceptModal(hide);
        }}
      />
      <RejectModal
        acceptRejectApiBody={acceptRejectApiBody}
        showRejectModal={showRejectModal}
        showCommentModal={showCommentModal}
        hideRejectModal={(hide) => {
          setShowRejectModal(hide);
          localStorage.setItem("showAccept", "true");
        }}
        hideCommentModal={(hide) => {
          setShowCommentModal(hide);
        }}
      />
    </div>
  );
};

export default ImageAnnotate;
 