import { useFormik } from "formik";
import { Modal, Table } from "antd";
import TextField from "../Textfield";
import { fieldIds } from "../../themes";
import { useTranslation } from "react-i18next";
import React from "react";
import { textAreaSize } from "../../themes/appconstant";
import { customFunctions } from "../../themes/utils/CustomFunctions";
import { constants } from "../../common/fileConstants/Admin/UserManagement";
import PropTypes from "prop-types";
const CustomModal = ({
  title = "",
  children,
  handleOk = () => { },
  modalWidth = 500,
  handleCancel = () => { },
  submitTitle = null,
  isModalVisible = false,
  hasTable = false,
  tableContents,
  tableColumns,
  dataToShow,
}) => {
  const { t } = useTranslation();

  const onSubmit = (values) => {
    handleOk({
      category: values.skillCategory[0].key,
      description: customFunctions.capitalizeFirstLetter(values.skillDescription),
      name: customFunctions.capitalizeFirstLetter(values.skillName),
      experience: values.skillExperience[0].val
    })
    formik.resetForm();
  };



  const initialValues = {
    skillCategory: "",
    skillName: '',
    skillExperience: "",
    skillDescription: "",
  };
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: constants.formikValidationSchemaAddSkillModal(),
  });
  const selectCustomStyle = {
    cursor: "pointer",
    caretColor: "transparent",
  };
  var AddSkillModalFormFields = [
    {
      type: "text",
      error: formik.errors.skillCategory,
      selectTrue: true,
      id: fieldIds.CATEGORY,
      selectOptions: dataToShow,
      value: formik.values.skillCategory[0]?.key || null, //formik.values.skillCategory,
      customInputStyle: selectCustomStyle,
      placeholder: t('select_cate'),
      fieldLabel: t('category'),
    },
    {
      type: "text",
      error: formik.errors.skillName,
      inputTrue: true,
      id: 'skillName',
      value: customFunctions.capitalizeFirstLetter(formik.values.skillName.trimLeft()),
      fieldLabel: t('name'),
      placeholder: t("enter_skill_name"),
      inputMaxLength: 50
    },
    {
      type: "text",
      textAreaTrue: true,
      isAutoComplete: false,
      tar: textAreaSize.rows,
      id: "skillDescription",
      tac: textAreaSize.columns,
      value: customFunctions.capitalizeFirstLetter(formik.values.skillDescription.trimLeft()),
      error: formik.errors.skillDescription,
      fieldLabel: t("description"),
      placeholder: t("enter_desc"),
      selectMaxLength: 50
    },
    {
      type: "text",
      error: formik.errors.skillExperience,
      selectTrue: true,
      id: fieldIds.EPERIENCE,
      selectOptions: [
        {
          name: "0-3",
          value: "0-3",
        },
        {
          name: "3-8",
          value: "3-8",
        },
        {
          name: "8-15",
          value: "8-15",
        },
        {
          name: "15-20",
          value: "15-20",
        },
      ],
      value: formik.values.skillExperience[0]?.key || null,
      customInputStyle: selectCustomStyle,
      fieldLabel: t('experience'),
      placeholder: t("select_exp"),
    },
  ];
  return (
    <Modal
      title={title}
      centered
      footer={null}
      onOk={() => { formik.resetForm(); handleOk() }}
      width={modalWidth}
      onCancel={() => { formik.resetForm(); handleCancel(); }}
      open={isModalVisible}
      wrapClassName="poolworkers"
      className={localStorage.lang == 'ar' ? 'select_data_user ar' : 'select_data_user en'}
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        {AddSkillModalFormFields.length ? (
          <div className="text-center">
            <div className="wrapper_line models_data">
              {AddSkillModalFormFields.map((items) => {
                return (
                  <div key={items.fieldLabel} className="wrapper_line form_wrapper remove-horizontal-space">
                    <TextField
                      {...items}
                      labelTitle={items.fieldLabel}
                      errorClassName={
                        localStorage.getItem("lang") == "ar"
                          ? "error ar"
                          : "error en"
                      }
                      selectOnChange={(val, options, label) => {
                        if (label == t('category')) {
                          formik.setValues({
                            ...formik.values,
                            skillCategory: [
                              { key: val, val: options.children },
                            ],
                          });

                        }
                        if (label == t('experience')) {

                          formik.setValues({
                            ...formik.values,
                            skillExperience: [
                              { key: val, val: options.children },
                            ],
                          });
                        }
                      }}
                      onChange={formik.handleChange}
                    />
                  </div>
                );
              })}
            </div>
            {submitTitle != null ? (
              <div className="text-center  model1">
                <button
                  type="submit"
                  className="button-list top_new color_new pt-50 bottom_button sp focus"
                >
                  {submitTitle}
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
      </form>
      {hasTable ? (
        <div
          className={`text-center modal2 remove-left-pad vct-modal  AnnotClassName `}
        >
          <div className="form-group wrapper_line models">
            <Table
              className="udt2"
              dataSource={tableContents}
              columns={tableColumns}
              showSorterTooltip={false}
              pagination={false}
            />
          </div>
        </div>
      ) : null}
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  modalWidth: PropTypes.number,
  formFields: PropTypes.array,
  submitTitle: PropTypes.string,
  children: PropTypes.element,
  hasTable: PropTypes.bool,
  tableContents: PropTypes.array,
  tableColumns: PropTypes.array,
  dataToShow: PropTypes.array,
};

export default CustomModal;
