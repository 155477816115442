/*

1. For Container :
    * handleClick,children,menuProps,breadCrumbItems,menuProps  
      are Mandatory props
    * Prop types
        {

            menuProps: SidebarMenus('user'),  // for side bar
            handleClick : ()=>{

            }, // callback for clicking sidebar menu
            children : contentChild = ()=>{
                return(
                    <></>
                )
            }, // jsx component child of Content
            breadCrumbItems: breadCrumbItems = [
                { 
                    link : NavigateTo.LINK_NAME,
                    label : Constants.labelname
                }
            ]
        }

*/

import React, { useEffect, useState } from "react";
import {
  Layout,
  Breadcrumb,
} from "antd";

import { Link } from "react-router-dom";
import Sidebar from "../../layout/sidebar";
import HeaderAdmin from "../../layout/AdminModule/header";
const ContainerAdmin = (props) => {
  const {
    children,
    menuProps,
    active,
    languageChanged = () => { },

    layoutClassName,
    breadCrumbItems = [
      {
        link: "/dummy",
        label: "Dummy",
      },
    ],
  } = { ...props };
  const { Content } = Layout;
  const [hamClicked, setHamClicked] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  useEffect(() => {
    const contentsComponent = document.querySelector(
      "section.ant-layout.new_layout"
    );
    const slashsibling = document.getElementsByClassName("slash-sibling");
    if (localStorage.getItem("lang") == "ar") {
      contentsComponent.setAttribute(
        "style",
        "margin-right: auto;margin-left:unset"
      );
      // breadcrumbs menu styling start
      if (slashsibling.length) {
        for (let i = 0; i < slashsibling.length; i++) {
          const slashParent = slashsibling[i].parentElement;
          slashParent.setAttribute(
            "style",
            "flex-direction: row-reverse;display: flex;"
          );
        }
      }
      // breadcrumbs menu styling end
    } else {
      if (slashsibling.length) {
        // breadcrumbs menu styling start
        for (let i = 0; i < slashsibling.length; i++) {
          const slashParent = slashsibling[i].parentElement;
          slashParent.setAttribute("style", "display: inline;");
        }
        // breadcrumbs menu styling end
      }
      contentsComponent.setAttribute(
        "style",
        "margin-left: auto;margin-right: unset;"
      );
    }
  });
  return (
    <Layout>
      <Sidebar
        isHamClicked={hamClicked}
        hamUnclicked={() => setHamClicked(true)}
        activeItem={active}
        menuProps={menuProps}
      />
      <Layout
        className={
          layoutClassName
            ?
            localStorage.getItem("lang") == "ar"
              ? `${layoutClassName} new_layout ar`
              : `${layoutClassName} new_layout en`
            :
            localStorage.getItem("lang") == "ar"
              ? "new_layout ar"
              : "new_layout en"
        }
      >
        <HeaderAdmin
          handleHamburgerClick={() => {
            setHamClicked(!hamClicked);
          }}
          handleLanguageChange={(val) => {
            setLang(val);
            languageChanged(true);
          }}
        />
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            className="breadcrumb_header"
            style={{
              paddingRight: lang == "ar" ? "0px" : null,
              paddingLeft: lang == "ar" ? "25px" : null,
            }}
          >
            <Breadcrumb
            >
              {breadCrumbItems.map((item, index) => {
                return index == breadCrumbItems.length - 1 ? (
                  <Breadcrumb.Item key={index}>{item.label}</Breadcrumb.Item>
                ) : (
                  <Breadcrumb.Item key={index}>
                    <Link

                      className="focus"
                      to={item.link}
                      style={{
                        color: "rgba(0, 0, 0, 0.45)",
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      {item.label}
                    </Link>
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          </div>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default ContainerAdmin;
