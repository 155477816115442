import {
    REPORT_WORKER_BY_VALIDATOR
} from '../actionTypes/actionTypes'
 
 

// VALIDATOR PENDING TASK DETAILS
export const report = (payload) => {
    return {
        type: REPORT_WORKER_BY_VALIDATOR ,
        payload
    } 
}  