import React from 'react'
import { AppImage } from '../../themes'
import PropTypes from "prop-types";

const CustomEmptyDiv = ({
  title = 'Dummy',
  imgHeight,
  imgWidth,
  containerWidth,
  containerHeight,
  showLoader,
  marginBottom = '20px',
  marginTop = '20px',
  margin = '25px',
  marginLeft,
  marginRight
}) => {
  return (
    <div
      className='EmptyDiv'
      style={{
        display: 'flex',
        height: containerHeight || '250px',
        width: containerWidth || '100%',
        color: '#c3bcbc',
        fontWeight: 400,
        fontSize: '17px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        boxShadow: '0px 0px 14px rgb(20 60 100 / 12%)',
        borderRadius: '20px',
        margin: margin,
        marginTop: marginTop,
        marginBottom: marginBottom,
        marginLeft: marginLeft,
        marginRight: marginRight
      }}>
      <img
        style={{
          height: imgHeight || '76px',
          width: imgWidth || '95px !important'
        }}
        src={AppImage.emptyImg}
      />
      <div style={{

      }}>
        {title}
      </div>
      {
        showLoader
          ?
          <div
            style={{
              zIndex: 9999999,
              position: 'absolute',
              display: 'flex',
              placeContent: 'center',
              alignItems: 'center',
              // top:100,
              inset: '0px',
              justifyContent: 'center',
            }}
          >
            <div className="loadingio-spinner-spin-ofny8427cln"><div className="ldio-isqqa2h0dbs">
              <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
            </div></div>

          </div>
          :
          null
      }
    </div>
  )
}

CustomEmptyDiv.propTypes = {
  title: PropTypes.string,
  imgHeight: PropTypes.string,
  imgWidth: PropTypes.string,
  containerWidth: PropTypes.string,
  containerHeight: PropTypes.string,
  showLoader: PropTypes.bool,
  marginBottom: PropTypes.string || PropTypes.number,
  marginTop: PropTypes.string || PropTypes.number,
  margin: PropTypes.string,
  marginLeft: PropTypes.string || PropTypes.number,
  marginRight: PropTypes.string || PropTypes.number
}

export default CustomEmptyDiv