import { Post } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl;

const Fetch = { 
  // validator comleted task details 
  reportWorkerByValidator(payload) {
    let url = baseUrl + "report_worker_or_validator/";
    return Post(payload, url);
  } 
};
 
export default Fetch;
