import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../hoc";
import {
  CustomEmptyDiv,
  CustomPageHeader,
  CustomTable,
  TextField,
} from "../../../molecules";
import { NavigateTo, SidebarMenus } from "../../../themes";
import { Collapse, Modal } from "antd";

import { useNavigate } from "react-router";
import AddTaskModal from "../createnewproject/addTaskModal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { addNewTaskToProject, getProjectDetails } from "../../../redux/actions/Project";
import { toast } from "react-toastify";

const { Panel } = Collapse;
// const { Option } = Select;

const OngoingProjectDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let projectId = localStorage.getItem("ongoingProjectId"); // implement it if api will be hit for this page

  const [projectInfo, setProjectInfo] = useState({
    taskListContents: []
  });
  const [isReport, setIsModalReport] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  // const [lang, setLang] = useState(localStorage.getItem("lang"));
  // const [addedTaskId, setAddedTaskId] = useState('');


  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.PROJECT,
      label: t("projects"),
    },
    {
      link: "",
      label: t("ongoing_prj_details"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t("projects"),
  };
  const [width, setWidth] = useState(window.innerWidth);
  /* page description */

  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: 'break-all'
  };
  const pn = [
    {
      labelTitle: `${t("proj_name")}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: projectInfo?.projName,
      customLabelStyle: rightFontStyle,
    },
  ]; // project name
  const tnot = [
    {
      labelTitle: `${t("total_tasks")}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: projectInfo?.totalTasks,
      customLabelStyle: rightFontStyle,
    },
  ]; // total tasks
  const ct = [
    {
      labelTitle: `${t("total_tasks_cmpltd")}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: projectInfo?.totalTasksCompleted,
      customLabelStyle: rightFontStyle,
    },
  ]; // total no of completed tasks
  const tp = [
    {
      labelTitle: `${t("pending")}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: projectInfo?.totalTasksPending,
      customLabelStyle: rightFontStyle,
    },
  ]; // total no of pending tasks

  const textFieldlabels = [pn, tnot, ct, tp];

  /* page description */

  /* drop down */

  // task list table info ( name, type, name of groups, status, action=>view )
  const taskListHeaders = width > 768 ? [
    {
      label: t("name"),
      headerWidth: "300px",
    },
    {
      label: t("type"),
      headerWidth: "300px",
    },
    {
      label: t("status"),
      headerWidth: "300px",
    },
    {
      label: t("validation_system"),
      headerWidth: "300px",
    },
    {
      label: t("action"),
      headerWidth: "150px",
      paddingLeft: "31px",
    },
  ] : [
    {
      label: t("name"),
      headerWidth: "300px",
    },
    {
      label: t("action"),
      headerWidth: "150px",
      paddingLeft: "31px",
    }
  ];


  const dropDownGroup = [
    // task list
    {
      table: {
        placeholder: t("task_list"),
        tableHeaders: taskListHeaders,
        tableContents: width > 768 ? projectInfo?.taskListContents : projectInfo?.taskListContents.map((item) => {
          return {
            0: `${item[0]} (${t(item[1])})`,
            1: item[4],
          }
        }),
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data task-table-ar"
            : "text_data task-table-en",
      },
    },
  ];

  /* drop down */

  const payload = {
    body: {
      projectId,
    },
    success: (res) => {
      let tempProjInfo = {};
      let tempTaskList = []; // (task name,task type,groups,status,view)
      res?.tasks.map((item) => {
        // let groupNames = [];
        // item?.task_group.map((item) => {
        //   groupNames.push(item?.name);
        // });
        tempTaskList.push({
          0: item?.name,
          1:
            t(item?.task_type),
          2: t(item?.status), //item?.status,
          3: t(item?.validation_system),
          4: {
            button: 'View',
            link: NavigateTo.TASK_DETAILS,
            id: item?.id, // pass task id here
          },
        })
      });

      tempProjInfo = {
        projName: res?.name,
        totalTasks: res?.tasks?.length,
        totalTasksCompleted: 0,
        totalTasksPending: 0,
        taskListContents: tempTaskList,
      };
      setProjectInfo(tempProjInfo);
      // setAddedTaskId(res?.data?.taskId);
    },
    // fail: (resf) => {
    // },
  };

  useEffect(() => {
    document.title = "WosoM";

    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }

    isInternetConnected() && dispatch(getProjectDetails(payload)); // implement it if api will be hit
  }, [showTaskModal, isReport]);

  return (
    <Container
      {...containerProps}
      // languageChanged={() => {
      //   setLang(localStorage.getItem("lang"));
      //   // it is reuqired to re render this whole component
      // }}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        <CustomPageHeader
          label={t("ongoing_prj_details")}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px",
            marginTop: "30px",
          }}
        >
          <CustomPageHeader
            wrapperClassName={
              "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
            }
            customWrapperStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          </CustomPageHeader>
        </CustomPageHeader>
        <div className="wrapper_line owner-ongoingprojdetails">
          {/* pageDescription */}
          {textFieldlabels.map((item, index) => {
            return <TextField labels={item} key={index} />;
          })}
          {/* pageDescription */}

          {/* dropdowns */}
          {dropDownGroup.map((item, index) => {
            return (
              <Collapse key={index} defaultActiveKey={["1"]} onChange={() => { }} className="task-list-ongoing-projdetails">
                <Panel id={index} header={item.table.placeholder} key={1}>
                  {
                    projectInfo?.taskListContents.length > 0 ?
                      <CustomTable
                        {...item.table}
                        buttonCallbackTrue={true}
                        handleClick={(label, id, link) => {
                          switch (label) {
                            case 'View': {
                              // id here is task id  
                              localStorage.setItem("ongoingProjectTaskId", id); // implement it if api will be hit for project details page

                              navigate(link + `/${id}`);
                              break;
                            }
                            case 'View Annotations': {

                              break;
                            }
                            default:
                              break;
                          }
                        }}
                      />
                      :
                      <CustomEmptyDiv title={t("tasks_appear_here")} />
                  }

                </Panel>
              </Collapse>
            );
          })}

          <AddTaskModal
            show={showTaskModal}
            hideTaskModal={(addedTaskId) => {
              if (addedTaskId) {
                const payloadNewTaskAdd = {
                  body: {
                    tasks: [addedTaskId],
                    projId: projectId
                  },
                  // success: (res) => {
                  // },
                  // fail: (resf) => {
                  // }
                }
                isInternetConnected() && dispatch(addNewTaskToProject(payloadNewTaskAdd));
              }
              setShowTaskModal(false);

            }
            }
          />

          {/* add task button */}
          <div style={{
            display: 'flex',
          }}>
            <button
              type="submit"
              className="button-list bottom_button pt-10 focus"
              onClick={() => {
                if (projectInfo?.taskListContents.length < 20)
                  setShowTaskModal(true);
                else {
                  toast.error(t('max_20_tasks'), {
                    position: 'top-right'
                  })
                }
              }}
            >
              {t("add_task")}
            </button>
            <button
              type="submit"
              className="button-list bottom_button pt-10 focus"
              onClick={() => {
                navigate(NavigateTo.TASK_LIST);
                localStorage.setItem('pastProjectId', projectId);
              }}
            >
              {t("export_data")}
            </button>
          </div>

          <Modal
            title={t("report")}
            open={isReport}
            onOk={() => setIsModalReport(false)}
            onCancel={() => setIsModalReport(false)}
            footer={null}
            centered
          >
            <div className="text-center  model1">
              <p>{t("u_sure_to_reprt_the_user")}</p>
            </div>
            <div className="text-center  model1">
              <button
                type="submit"
                className="button-list top_new"
                onClick={() => setIsModalReport(false)}
              >
                {t("yes")}
              </button>
              <button type="submit" onClick={() => setIsModalReport(false)}>
                {t("no")}
              </button>
            </div>
          </Modal>

        </div>
      </CardHoc>
    </Container>
  );
};

export default OngoingProjectDetails;
