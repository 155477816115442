import { USER_TABLE_ERROR, USER_TABLE_SUCCESS } from "../actionTypes/actionTypes"
import Fetch from "../Fetch/UserProfileApi"
import { call, put } from 'redux-saga/effects';
import { toast } from "react-toastify";
import { startLoading, stopLoading } from "../actions/Common";
import { setUserProfileInStore } from "../actions/Profile";
function* authanicationUser(result, history) {

    localStorage.clear()
    history.push("/")
    toast.error(result.result.message, {

        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

    })
}

export function* getTableUserApi(action) {
    try {
        const result = yield call(Fetch.userTableApi, action.payload, action.offset, action.limit, action.sortBy, action.order, action.history)

        if (result.status === 1) {
            yield put({
                type: USER_TABLE_SUCCESS,
                result: result.result.data,
                data: result.result.data,
                status: result.status,
                value: action.payload
            })

        }
        else if (result.status === 4) {
            yield call(authanicationUser, result, action.history)
        }
        else {
            yield put({
                type: USER_TABLE_ERROR,
                result: result?.result?.data?.data,
            })
        }
    }
    catch (error) {
        yield put({
            type: USER_TABLE_ERROR,
            result: error
        })
    }
}
export function* getUserProfileApi({
    payload: {
        success = () => { },
        fail = () => { }
    } = {}
}) {
    try {
        yield put(startLoading());
        const result = yield call(Fetch.getUserProfile)
        if (result.status === 1) {
            success(result?.result);
            const payload = result?.result;
            yield put(setUserProfileInStore(payload));
            yield put(stopLoading());
        }
        else if (result.status === 4) {
            yield put(stopLoading());
            fail(result);
        }
        else {
            yield put(stopLoading());
        }
    }
    catch (error) {
        yield put(stopLoading());
        fail(error);
    }
}

export function* profileCompletionApi(
    { payload: {
        body,
        success = () => { }
    } = {} }

) {
    try {
        yield put(startLoading());
        const result = yield call(Fetch.profileCompletion, body);
        if (result.status === 1) {
            success(result?.result);
            //  yield put(setUserProfileInStore(payload));
            yield put(stopLoading());
        }
        else if (result.status === 4) {
            yield put(stopLoading());
            //  fail(result?.result); 
        }
        else {
            yield put(stopLoading());
        }
    }
    catch (error) {
        yield put(stopLoading());
        // fail(error);
    }
} 