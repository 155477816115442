import React from "react";


const enInstructionExample = <>
    <h3>How to Write Task Instructions</h3>

    <h4>Step 1: Start with a Clear Goal</h4>
    <p>Your instructions should start with a clear goal that the user can easily understand. For example:</p>
    <p><i>This task is to transcribe the text in the image.</i></p>

    <h4>Step 2: Break Down the Task</h4>
    <p>Break down the task into smaller, manageable steps. For example:</p>
    <ul>
        <li>identify each object in the image</li>
        <li>transcribe the text in the image</li>
        <li>identify the language of the text</li>
    </ul>

    <h4>Step 3: Use <b>Bold</b> for Emphasis</h4>
    <p>Use the <b>B</b> button to emphasize key parts of each step.</p>

    <h4>Step 4: End with a Conclusion</h4>
    <p>End your instructions with a conclusion that signals the end of the task. For example:</p>

    <p><i>Remember, clear and concise instructions are key to helping users complete tasks successfully.</i></p>
</>;

const arInstructionExample = <>
    <h3>كيفية كتابة تعليمات المهمة</h3>

    <h4>الخطوة 1: ابدأ بهدف واضح</h4>
    <p>يجب أن تبدأ التعليمات بهدف واضح يمكن للمستخدم فهمه بسهولة. على سبيل المثال:</p>
    <p><i>هذه المهمة هي لتسريب النص في الصورة.</i></p>

    <h4>الخطوة 2: اقسم المهمة</h4>
    <p>قسّم المهمة إلى خطوات أصغر وأسهل. على سبيل المثال:</p>
    <ul>
        <li>تحديد كل كائن في الصورة</li>
        <li>تسريب النص في الصورة</li>
        <li>تحديد لغة النص</li>
    </ul>

    <h4>الخطوة 3: استخدم <b>Bold</b> للتأكيد</h4>
    <p>استخدم زر <b>B</b> لتأكيد الأجزاء الرئيسية من كل خطوة.</p>

    <h4>الخطوة 4: انتهي بخاتمة</h4>
    <p>انتهي من التعليمات بخاتمة تشير إلى نهاية المهمة. على سبيل المثال:</p>

    <p><i>تذكر أن التعليمات الواضحة والموجزة هي المفتاح لمساعدة المستخدمين على إكمال المهام بنجاح.</i></p>
</>;


export { enInstructionExample, arInstructionExample };