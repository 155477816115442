import { GET_PROJ_DETAILS, LOGOUT, SAVE_PROJ_DETAILS } from "./../../actionTypes/actionTypes";

const initialState = {
     projDetails : null
}

const ownerProjectDetailsReducer  = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT :
            return {
                ...state,
                projDetails : null
            };
        case  SAVE_PROJ_DETAILS : {
            return {
                ...state, 
                projDetails : action.payload
            };
        }
        case GET_PROJ_DETAILS : {
            return {
                ...state, 
                projDetails : action.payload
            };
        }
        default:
            return {
                ...state
            };

    }

}

export default ownerProjectDetailsReducer ;