import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../hoc";
import {
  CustomEmptyDiv,
  CustomPageHeader,
  CustomTable,
  TextField,
} from "../../../molecules";
import { NavigateTo, SidebarMenus } from "../../../themes";
import { Collapse, Modal  } from "antd";

import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getProjectDetails } from "../../../redux/actions/Project";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;
// const { Option } = Select;

const PastProjectDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let projectId = localStorage.getItem("pastProjectId"); // implement it if api will be hit for this page


  const [isReport, setIsModalReport] = useState(false);
  // const [lang, setLang] = useState(localStorage.getItem('lang'));

  const [taskListContents, setTaskListContents] = useState([]);

  const [projectInfo, setProjectInfo] = useState({
    projName: 'Project Name',
    projCompletionDate: '11/04/2022',
  });

  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t('dashboard'),
    },
    {
      link: NavigateTo.PROJECT,
      label: t('projects'),
    },
    {
      link: "",
      label: t('past_prj_details'),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t('projects'),
  };

  /* page description */

  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: 'break-all'
  };
  const pn = [
    {
      labelTitle: `${t('proj_name')}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: projectInfo?.projName,
      customLabelStyle: rightFontStyle,
    },
  ];
  const pcd = [
    {
      labelTitle: `${t('prj_cmpltn_date')}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: projectInfo?.projCompletionDate,
      customLabelStyle: rightFontStyle,
    },
  ];

  const textFieldlabels = [pn, pcd];

  /* page description */


  /* drop down */

  // task list table info ( name, type, task description, task completion date, action=>view )
  const taskListHeaders = [
    {
      label: t('tsk_name'),
      headerWidth: "200px",
    },
    {
      label: t('tsk_typ'),
      headerWidth: "400px",
    },
    {
      label: t('tsk_desc'),
      headerWidth: "300px",
    },
    {
      label: t('task_cmpltn_date'),
      headerWidth: "300px",
    },
    {
      label: t('action'),
      headerWidth: "154px",
      paddingLeft: "34px",
    },
  ];




  const dropDownGroup = [
    // task list
    {
      table: {
        placeholder: t('task_list'),
        tableHeaders: taskListHeaders,
        tableContents: taskListContents,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        tableClassName: localStorage.getItem('lang') == 'ar' ? 'text_data owner-past-proj-details-task-list-ar' : 'text_data owner-past-proj-details-task-list-en'
      },
    },
  ];

  /* drop down */


  const payload = {
    body: {
      projectId,
    },
    success: (res) => {

      let tempTaskList = []; // (task name,task type,task description,completion date,view)
      res?.data?.tasks.map((item) => {
        tempTaskList.push({
          0: item?.name,
          1:
            item?.task_type == "1"
              ? "image"
              : item?.task_type == "2"
                ? "text"
                : "audio", //item?.task_type,
          // 2:  groupNames.join(","),
          // 3:  item?.status == 0 ? ' pending' :  item?.status == 1 ? 'in-review' :item?.status ==2 ? 'completed':null, //item?.status,
          // 4: {
          //   button: t("view"),
          //   link: NavigateTo.TASK_DETAILS,
          //   id: item?.id, // pass task id here
          // },

          2: item?.description,
          3: item?.end_date.split('T')[0].split('-').reverse().join('-'),
          4: {
            button: "View",
            link: NavigateTo.TASK_DETAILS,
            id: {
              name: "tView",
              value: item?.id// pass task id here
            },
          }
        });
      });
      setTaskListContents(tempTaskList);

      let tempProjInfo = {
        projName: res?.data?.name,
        projCompletionDate: res?.data?.end_date.split('T')[0].split('-').reverse().join('-')
      };
      setProjectInfo(tempProjInfo);
    },
    fail: (resf) => {
      console.log(resf, "resf of ongoing proj details");
    },
  };
  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);


    isInternetConnected() && dispatch(getProjectDetails(payload)); // implement it if api will be hit

  }, []);

  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
      // if(isAnnotation){
      //   document.querySelector('.ant-modal-content').style.direction='rtl';
      // }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
      // if(isAnnotation){
      //   document.querySelector('.ant-modal-content').style.direction='ltr';
      // }
    }

  }, [isReport]);
  return (
    <Container
      {...containerProps}
      // languageChanged={() => {
      //   setLang(localStorage.getItem("lang"));
      //   // it is reuqired to re render this whole component
      // }}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        <CustomPageHeader
          label={t('past_prj_details')}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px",
            marginTop: "30px",
          }}
        ></CustomPageHeader>
        <div className="wrapper_line owner-ongoingprojdetails">
          {/* pageDescription */}
          {textFieldlabels.map((item) => {
            return <TextField labels={item} key={item} />;
          })}
          {/* pageDescription */}

          {/* dropdowns */}
          {dropDownGroup.map((item, index) => {
            return (
              <Collapse key={index} defaultActiveKey={["1"]} onChange={() => { }}>
                <Panel id={index} header={item.table.placeholder} key="1">
                  {
                    taskListContents.length > 0 ?
                      <CustomTable
                        {...item.table}
                        handleClick={(label, id) => {
                          switch (id?.name) {
                            case "tView":
                              {
                                // id here is task id
                                localStorage.setItem('pastProjectTaskId', id?.value) // implement it if api will be hit for project details page
                                navigate(NavigateTo.PAST_TASK_DETAILS + `/${id?.value}`);
                                break;
                              }
                            case "gView":
                              {
                                localStorage.setItem('pastProjectGroupId', id?.value) // implement it if api will be hit for project details page
                                navigate(NavigateTo.GROUP_DETAILS);
                                break;
                              }
                            case "wReport":
                              setIsModalReport(true);
                              break;
                            default:
                              break;
                          }
                        }}
                      />
                      :
                      <CustomEmptyDiv title={t("tasks_appear_here")} />
                  }

                </Panel>
              </Collapse>
            );
          })}
          {/* dropdowns */}

          {/* report modal */}
          <Modal
            title={t('report')}
            open={isReport}
            onOk={() => setIsModalReport(false)}
            onCancel={() => setIsModalReport(false)}
            footer={null}
            centered
          >
            <div className="text-center  model1">
              <p>{t('report')}</p>
            </div>
            <div className="text-center  model1"
              style={{
                direction: localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr'
              }}
            >
              <button
                type="submit"
                className="button-list top_new focus"
                onClick={() => setIsModalReport(false)}
              >
                {t('yes')}
              </button>
              <button
                type="submit"
                className="focus"
                onClick={() => setIsModalReport(false)}
              >
                {t('no')}
              </button>
            </div>
          </Modal>
        </div>
      </CardHoc>
    </Container>
  );
};

export default PastProjectDetails;
