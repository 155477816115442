import { RAISE_DISPUTE, DISPUTE_LISTING,ACCEPT_REJECT_DISPUTED_ANNOTATIONS,
    DISPUTE_DETAILS } from "../actionTypes/actionTypes"
 
export const raiseDispute = (payload) => {
    return {
        type: RAISE_DISPUTE ,
        payload: payload
    }
}
// VALIDATOR PENDING TASK DETAILS
export const disputeListing = (payload) => {
    return {
        type: DISPUTE_LISTING ,
        payload: payload
    }
} 
export const disputeDetails = (payload) => {
    return {
        type: DISPUTE_DETAILS ,
        payload: payload
    }
} 
 // Owner/Dashboard / Projects / Ongoing Project Details / Task Details / Dispute Annotation Details
export const acceptRejectDisputedAnnotations = (payload) => {
    return {
        type: ACCEPT_REJECT_DISPUTED_ANNOTATIONS ,
        payload: payload
    }
} 