/*

1. For Dropdown :
    * selectTrue,dropdownPlaceholder,selectOptions  
      are Mandatory props
    * Prop types
        {
            selectTrue : true, // Boolean to show dropdown or not
            dropdownPlaceholder : Constants.Placeholder_Name, // String
            selectOptions: [{{
                    name:'Abc',
                    value:'abc' 
                }}]
        }
2. For Input Field
    * inputTrue,id,type,value,placeholder,onChange
      are Mandatory props
    * onClick,isDisabled,customInputStyle,customLabelStyle,isAutoComplete 
      are optional props
    * Prop types
        {
            id : fieldIds.FIELD_NAME, // String ctrl+u
            type : 'text/button', // String (input field type),
            onChange : ()=> formik.handleChange, // callback for input field
            formik: formik, // remove
                    | |
                    \ /
                    useFormik({
                        initialValues,
                        validationSchema,
                        onSubmit, 
                        validate 
                    })
            inputTrue : true, // Boolean
            value : formik.values.field_name , // ctrl+l
            placeholder : Constants.Palceholder_Name, // String alt+t ,
            
            customInputStyle : {},
            isAutoComplete : false, // Boolean,
            onClick : ()=>{

            } //Callback Function,  
            labels : labels, // [{labelTitle:'',customLabelStyle:{}}] multiple labels 
            customLabelStyle : {}, // custom style for labels
            isDisabled : true , // Boolean for not taking input values
       }
 3. For TextArea Multiline
      * textAreaTrue,tar,tac
        are Mandatory props
      * Prop types 
        textAreaTrue : true, // Boolean
        tar : '50', // Number String for textArea Row
        tac : '4', // Number String for textArea Column

 4. For Error Field 
      * error 
        are Mandatory props
      * Prop types 
         error : formik.errors.firstName

 5. For Form Field Labels
      * labelTitle
        are Mandatory props
      * Prop types
         labelTitle : String
*/
import { Select } from "antd";
import React from "react";


const TextField = (props) => {
  const {
    id,
    type,
    tac,
    tar,
    value,
    error,
    child,
    labels = [],
    onChange = () => { },
    isDisabled = false,
    labelTitle = null,
    labelHeader = null,
    customHeaderStyle = {},
    inputTrue = false,
    selectTrue = false,
    onClick = () => { },
    customLabelStyle = {},
    textAreaTrue = false,
    customInputStyle = {},
    labelIcon = null,
    isAutoComplete = "none",
    placeholder = "dummy place holder",
    onClickLabelIcon = () => { },
    fieldIcons = [],
    onLabelIconTap = () => { },
    wrapperClassName,
    selectOptions = [{ value: "Dummy Value", name: "Dummy Name" }],
    errorClassName
    // dropdownPlaceholder='dummy place holder',
  } = { ...props };


  const { Option } = Select;
  return (
    <div className={wrapperClassName || "form-group"} style={{ position: 'relative' }}>
      {
        labels.length
          ?
          labels.map(item => {
            return (
              <label key={item} style={item.customLabelStyle}>{item.labelTitle}</label>
            )
          })
          :
          <>
            {labelHeader && <label style={customHeaderStyle}>{labelHeader}</label>}
            <label style={customLabelStyle}>
              {labelTitle}
              {
                labelIcon
                &&
                <span
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={onLabelIconTap}
                >
                  <img src={labelIcon} onClick={onClickLabelIcon} style={{ marginLeft: 10 }} />
                </span>
              }
            </label>
          </>
      }
      {inputTrue ? (
        <input
          id={id}
          type={type}
          value={value}
          onClick={onClick}
          onChange={onChange}
          className="form-control"
          style={customInputStyle}
          placeholder={placeholder}
          autoComplete={isAutoComplete}
          disabled={isDisabled}
        />
      ) : null}
      {textAreaTrue ? (
        <textarea
          id={id}
          rows={tar}
          cols={tac}
          type={type}
          value={value}
          onClick={onClick}
          onChange={onChange}
          className="form-control"
          style={customInputStyle}
          placeholder={placeholder}
          autoComplete={isAutoComplete}
        />
      ) : null}
      {selectTrue ? (
        <Select className="default_text data_popup" placeholder={placeholder}>
          {selectOptions.map((items) => {
            return <Option
              key={items.value}
              style={{
                backgroundColor: 'transparent'
              }}
              value={items.value}>{items.name}</Option>;
          })}
        </Select>
      ) : null}
      {error ? <div className={errorClassName || "error"}>{error}</div> : null}
      {
        child
        &&
        child
      }
      {
        fieldIcons.length
          ?
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              top: '-63px',
            }}>
            <div
              style={{
                position: 'absolute',
                right: '25px',
                display: 'inlineFlex',
                cursor: 'pointer'
              }}
              onClick={() => {
                const el = document.getElementsByClassName('id-control');
                if (el.length > 0) {
                  const val = el[0].value;
                  const num = Number(val.split(" ")[0]);
                  document.getElementsByClassName("id-control")[0].value = Number(num + 1)

                }
              }}
            >
              <img src={fieldIcons[0]} />
            </div>
            <div
              className="fi"
              style={{
                position: 'absolute',
                right: '0px',
                display: 'inlineFlex',
                top: '12px',
                right: '25px',
                cursor: 'pointer'
              }}
              onClick={() => {
                const el = document.getElementsByClassName('id-control');
                if (el.length > 0) {
                  const val = el[0].value;
                  const num = val.split(' ')[0];
                  num != 0
                    ?
                    document.getElementsByClassName("id-control")[0].value = (Number(num - 1))
                    :
                    document.getElementsByClassName("id-control")[0].value = (`0`)
                }
              }}
            >
              <img src={fieldIcons[1]} />
            </div>

          </div>
          :
          null

      }
    </div>
  );
};

export default TextField;
