import {
  customDispatch,
  customNavigate,
} from "../../Routes/navigationServices";
import navigateTo from "../../themes/routeNames";
import { logout } from "../actions/Common";

export const Post = (body, newurl, header = undefined) => {
  // header['Accept-Language'] = localStorage.lang ;
  const internetStatus = true;
  const url = newurl;
  const token = localStorage.getItem("token") && localStorage.getItem("token") != 'undefined' ? localStorage.getItem("token") : localStorage.getItem("tokenCheckForRouting");
  const headers = header !== undefined ? header : {
    "Content-Type": "application/json",
    "Accept-Language": localStorage.getItem("lang"),
  };

  if (localStorage.getItem("isRegistration") === "false") {
    headers["Authorization"] = "Bearer " + token;
  }

  const data = {
    method: "POST",
    headers: headers,
    body: header == undefined ? JSON.stringify(body) : body,
  };
  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        if (internetStatus === true) {
          if (responseData.status < 300) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                customDispatch(logout());
                customNavigate(navigateTo.LOGIN);
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status === 409) {
            return responseData.json().then((result) => {
              console.log(result, "resultresult");
              if (result) {
                return resolve({
                  status: 6,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status === 404) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 7,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          }
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      })
      .catch(() => {
        if (internetStatus == true) {
          return reject({
            result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
            status: 0,
          });
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      });
  });
};

export const UpdatePost = (body, newurl) => {
  const internetStatus = true;

  const url = newurl;
  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Token " + localStorage.getItem("token"),
      "Accept-Language": localStorage.getItem("lang"),
    },
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {

    fetch(url, data)
      .then((responseData) => {

        if (internetStatus === true) {
          console.log(responseData, "wudgiuwdgidgw");

          if (responseData.status == 200 || responseData.status == 201) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                customDispatch(logout());
                customNavigate(navigateTo.LOGIN);
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status === 409) {
            return responseData.json().then((result) => {
              console.log(result, "resultresult");
              if (result) {
                return resolve({
                  status: 6,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          }
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      })
      .catch(() => {
        if (internetStatus == true) {
          return reject({
            result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
            status: 0,
          });
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      });
  });
};

export const PostLink = (body, newurl) => {
  const internetStatus = true;

  const url = newurl;
  const data = {
    method: "POST",
    headers: {
      "Accept-Language": localStorage.getItem("lang"),
      "Content-Type": "application/json",
    },
    body: body,
  };
  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        if (internetStatus === true) {
          if (responseData.status == 200) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                customDispatch(logout());
                customNavigate(navigateTo.LOGIN);
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status === 409) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 6,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          }
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      })
      .catch(() => {
        if (internetStatus == true) {
          return reject({
            result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
            status: 0,
          });
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      });
  });
};
export const Put = (body, newurl) => {
  const internetStatus = true;
  const url = newurl;

  const data = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": localStorage.getItem("lang"),
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(body),
  };
  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        if (internetStatus === true) {
          if (responseData.status == 200 || responseData.status == 204) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                customDispatch(logout());
                customNavigate(navigateTo.LOGIN);
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status === 409) {
            return responseData.json().then((result) => {
              console.log(result, "resultresult");
              if (result) {
                return resolve({
                  status: 6,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          }
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      })
      .catch(() => {
        if (internetStatus == true) {
          return reject({
            result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
            status: 0,
          });
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      });
  });
};
export const Get = (newurl, auth) => {
  const internetStatus = true;
  const url = newurl;
  const token = localStorage.getItem("token") && localStorage.getItem('token') !== 'undefined' ? localStorage.getItem("token") : localStorage.getItem("tokenCheckForRouting");
  let Authorization = auth ? "Bearer " + token : "";

  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: Authorization,
      "Accept-Language": localStorage.getItem("lang"),
    },
  };
  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        if (internetStatus === true) {
          if (responseData.status == 200) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                customDispatch(logout());
                customNavigate(navigateTo.LOGIN);
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          }
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      })
      .catch(() => {
        if (internetStatus == true) {
          return reject({
            result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
            status: 0,
          });
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      });
  });
};
export const Delete = (newurl) => {
  const internetStatus = true;
  const url = newurl;
  const data = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Accept-Language": localStorage.getItem("lang"),
    },
  };
  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        if (internetStatus === true) {
          if (responseData.status == 200 || responseData.status == 204) {
            return resolve({
              status: 1,
              result: { message: "success" },
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                customDispatch(logout());
                customNavigate(navigateTo.LOGIN);
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          }
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      })
      .catch(() => {
        if (internetStatus == true) {
          return reject({
            result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
            status: 0,
          });
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      });
  });
};

export const PostFormData = (payloadBody, newurl) => {
  const internetStatus = true;
  let token = localStorage.getItem("token") && localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token") : localStorage.getItem("tokenCheckForRouting");
  let headers = {
    // "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + token,
  };
  const url = newurl;

  headers["Accept-Language"] = localStorage.lang;

  var bodyTobeSend = payloadBody;

  const data = {
    method: "POST",
    headers: headers,
    body: bodyTobeSend,
  };

  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        if (internetStatus === true) {
          if (responseData.status == 200 || responseData.status == 201) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                customDispatch(logout());
                customNavigate(navigateTo.LOGIN);
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status === 409) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 6,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status === 404) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 7,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          }
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      })
      .catch(() => {
        if (internetStatus == true) {
          return reject({
            result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
            status: 0,
          });
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      });
  });
};
export const PutFormField = (payloadBody, newurl) => {
  const internetStatus = true;
  const url = newurl;

  var bodyTobeSend = payloadBody;

  const data = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: "Token " + localStorage.getItem("token"),
      "Accept-Language": localStorage.getItem("lang"),
    },
    body: bodyTobeSend,
  };
  return new Promise((resolve, reject) => {
    fetch(url, data)
      .then((responseData) => {
        console.log(responseData);
        if (internetStatus === true) {
          if (responseData.status == 200) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 1,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 400) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 2,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status == 401 || responseData.status == 403) {
            return responseData.json().then((result) => {
              if (result) {
                customDispatch(logout());
                customNavigate(navigateTo.LOGIN);
                return resolve({
                  status: 4,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (
            responseData.status == 500 ||
            responseData.status === 504
          ) {
            return responseData.json().then((result) => {
              if (result) {
                return resolve({
                  status: 5,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          } else if (responseData.status === 409) {
            return responseData.json().then((result) => {
              console.log(result, "resultresult");
              if (result) {
                return resolve({
                  status: 6,
                  result: result,
                });
              } else {
                return reject({
                  status: 0,
                  result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
                });
              }
            });
          }
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      })
      .catch(() => {
        if (internetStatus == true) {
          return reject({
            result: localStorage.lang == 'en' ? "Something went wrong." : "هناك خطأ ما.",
            status: 0,
          });
        } else {
          return reject({
            result:
              localStorage.lang == "en"
                ? "Please check your internet connection."
                : "الرجاء التحقق من اتصال الانترنت الخاص بك.",
            status: 0,
          });
        }
      });
  });
};
