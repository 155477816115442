import Fetch from "../Fetch/SkillsApi";
import { put, call } from "redux-saga/effects";
import { startLoading, stopLoading } from "../actions/Common";


export function* getSkillCategoriesApi({
  payload: {
    success = () => { },
    fail = () => { }
  } = {}
}
) {
  try {
    const result = yield call(Fetch.skillCategoriesApi);
    yield put(startLoading());
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}

