import {ACCEPT_REJECT_NOTIFICATION,GET_USER_NOTIFICATION,GET_WORKER_NOTIFICATION,GET_VALIDATOR_NOTIFICATION } from '../actionTypes/actionTypes'
 
export const getUserNotification = (payload) => {
    return {
        type: GET_USER_NOTIFICATION, 
        payload
    }
}  

// below 2 are optional  in case the api comes individually
export const getWorkerNotification = (payload) => {
    return {
        type: GET_WORKER_NOTIFICATION,
        payload
    }
} 
export const getValidatorNotification = (payload) => {
    return {
        type: GET_VALIDATOR_NOTIFICATION,
        payload
    }
}  

export const acceptRejectNotification = (payload) => {
    return {
        type: ACCEPT_REJECT_NOTIFICATION,
        payload
    }
} 

