import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
const LoaderHoc = ({ children }) => {
  const loading = useSelector((state) => state.common.loading);
  const taskLoading = useSelector((state) => state.common);

  return (
    <>
      {children}
      {(!!loading || !!taskLoading?.taskLoading) && (
        <div
          style={{
            zIndex: 9999999,
            position: 'fixed',
            display: 'flex',
            placeContent: 'center',
            alignItems: 'center',
            top: 100,
            inset: '0px',
            justifyContent: 'center',
            // background: 'rgba(0,0,0,.5)'
          }}
        >
          <div className="loadingio-spinner-spin-ofny8427cln"><div className="ldio-isqqa2h0dbs">
            <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
          </div></div>

        </div>
      )}
    </>
  );
};

LoaderHoc.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoaderHoc;
