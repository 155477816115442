const navigateTo = {
    NOT_FOUND: '/notfound',
    OWNER_SET_PASSWORD: '/setPassword',
    USER_UDT_ADMIN: '/udtadmin',
    DISPUTE_DETAILS: '/disputedetails',
    ACCEPT_REJECT_ANNOT: '/workerAnnots',
    USER_UDT_OWNER: '/udtowner',

    AUDIO_TRANSCRIPT: '/audio_transcript',
    USER_UDT: '/user/completed/udt',
    OWNER_UDT: '/manage/completed/udt',

    LANDING: '/landing',
    PRIVACY: '/privacy',
    SERVICES: '/services',
    FEATURES: '/features',
    TERMS: '/terms',
    EMAIL_CONFIRMED: '/email_confirmed',
    RESETPASSWORD: `/reset-password/:uid/:token`,// temporary solution
    DEFAULT: '/',
    OTP: '/otp',
    LOGIN: '/login',
    PROJECT: '/manage/projects',
    PROFILE: '/manage/profile',
    REGISTER: '/register',
    ADD_GROUP: '/manage/addgroup',
    OWNER_DASHBOARD: '/manage/dashboard',
    EDIT_GROUP: '/manage/editgroup',
    EXPORT_DATA: '/manage/export',
    TASK_LIST: '/manage/export/tasks',
    EDIT_PROFLE: '/manage/profile/edit',
    TASK_DETAILS: '/manage/task/details',
    USER_PROFILE: '/user/profile',
    GROUP_DETAILS: '/manage/groupdetails',

    USER_DASHBOARD: '/user/dashboard',
    WORKER_PENDING_UDT: '/user/pending/udt',
    FORGOT_PASSWORD: '/forgotpassword',

    CHANGE_PASSWORD: '/manage/profile/password',

    GROUP_MANAGEMENT: '/manage/groupmanagement',
    PAST_TASK_DETAILS: '/pasttaskdetails',
    CREATE_NEW_PROJECT: '/manage/projects/new',
    PROFILECOMPLETION: `/profile_completion`,
    USER_NOTIFICATIONS: '/user/notifications',
    DISPUTE_ANNOTATION: '/disputeannotation',
    USER_TASK_MANAGEMENT: '/user/task/management',
    PAST_PROJECT_DETAILS: '/pastprojectdetails',
    USER_CHANGE_PASSWORD: '/user/change-password',
    ONGOING_PROJECT_DETAILS: '/manage/projects/details',
    WORKER_PENDING_TASK_DETAILS: '/pending/task/details',
    WORKER_COMPLETED_TASK_DETAILS: '/completed-task-details',
    VALIDATOR_PENDING_TASK_DETAILS: '/validator-pending-task-Details',
    VALIDATOR_COMPLETED_TASK_DETAILS: '/validator-completed-task-details',

    ADMIN_PROJECT: '/admin/project',
    ADMIN_PROFILE: '/admin/profile',
    ADMIN_ADDGROUP: '/admin/addgroup',
    ADMIN_EDIT_USER: '/admin/edituser',
    ADMIN_TASK_LIST: '/admin/tasklist',
    ADMIN_EDIT_GROUP: '/admin/editgroup',
    ADMIN_EXPORT_DATA: '/admin/exportdata',
    ADMIN_EDIT_PROFILE: '/admin/editprofle',
    ADMIN_TASK_DETAILS: '/admin/taskdetails',
    ADMIN_DASH_BOARD: '/admin/dashboard',
    ADMIN_GROUP_DETAILS: '/admin/groupdetails',
    ADMIN_CHANGE_PASSWORD: '/admin/changepassword',
    ADMIN_GROUP_MANAGEMENT: '/admin/groupmanagement',
    ADMIN_CREATE_NEW_PROJ: '/admin/createnewproject',
    ADMIN_PAST_TASK_DETAILS: '/admin/pasttaskdetails',
    ADMIN_DISPUTEANNOTATION: '/admin/disputeannotation',
    ADMIN_PAST_PROJ_DETAILS: '/admin/pastprojectdetails',
    ADMIN_ONGOING_PROJ_DETAILS: '/admin/ongoingprojectdetails',

    ADMIN_OWNER_MANAGEMENT: '/admin/ownermanagement',//"/admin/ownermanagement"
    ADMIN_USER_MANAGEMENT: '/admin/user/manage',//"/admin/usermanagement"
    ADMIN_PROJECT_MANAGEMENT: '/admin/project',//"/admin/projectadmin"
    ADMIN_OWNER_DETAILS: '/admin/OwnerDetails',//"/OwnerDetails"
    ADMIN_EDIT_DETAILS: '/admin/EditDetails',//"/OwnerDetails"
    ADMIN_ADD_OWNER_DETAILS: '/adddetails',//"/OwnerDetails"
    ADMIN_USER_DETAILS: '/admin/UserDetails',
    ADMIN_PORJ_DETAILS: '/admin/adminprojectdetails',
    ADMIN_TASK_DETAILS: '/admin/admintaskdetails',
    ADMIN_PROJ_TASK_DETAILS: '/admin/projecttaskdetails',
}

export default navigateTo;