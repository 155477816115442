import { Modal } from "antd";
import { toast } from "react-toastify";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { report } from "../../../../../../redux/actions/Report";
import { isInternetConnected } from "../../../../../../themes/utils/internetConnectionn";

const ReportModal = (props) => {
  const dispatch = useDispatch();

  const { rateTo, taskId, isReportVisible, hideModal = () => {} } = {
    ...props,
  };
  const { t } = useTranslation();
 
  const payloadReport = {
    body: JSON.stringify({
      reported_by: Number(localStorage.getItem("loggedInUserId")),
      reported_to: rateTo,
      type: 1, //1 worker , 2 validator
      task_id: taskId,
    }),
    success: (res) => {
      toast.success(res, {
        className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
    },
    fail: (resf) => {
      console.log(resf, "resf of  validator listing.");
    },
  }; //  report payload
  useEffect(() => {

    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isReportVisible]);
  return (
    <Modal  
      title={t("report")}
      open={isReportVisible}
      onOk={() => {
        hideModal(false);
      }}
      onCancel={() => {
        hideModal(false);
      }}
      footer={null}
      centered
    >
      <div className="text-center  model1 " style={{ marginTop: "10px" }}>
        <p>{t("report_user_for_task")}</p>
      </div>
      <div
        className="text-center  model1 removeMt"
        style={{
          marginBottom: "5px",
          direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
        }}
      >
        <button
          type="submit"
          className="button-list top_new focus"
          onClick={() => {
            isInternetConnected() && dispatch(report(payloadReport));
            hideModal(false);
          }}
        >
          {t("yes")}
        </button>
        <button type="submit" onClick={() => hideModal(false)}>
          {t("no")}
        </button>
      </div>
    </Modal>
  );
};

export default ReportModal;
