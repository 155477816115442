import React from 'react'

const CardHoc = (props) => {
  const {
    children,
    cardPadding = '0px 24px',
    cardInnerPadding,
    minHeight = 360,
    className = "site-layout-background"
  } = { ...props }


  return (
    // <Content style={{ margin: '10px 16px 0' }}>
    <div className={className} style={{ padding: cardPadding, paddingTop: '-35px !important', minHeight: minHeight }}>
      <div className={cardInnerPadding}>
        {/* "content-e tabs_button removeTopMagin" */}
        {children}
      </div>
    </div>
    // </Content>
  )
}

export default CardHoc