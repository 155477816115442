import React from "react";
import { NavigateTo } from "../themes";
import { PrivateRoute } from "./screen";

import {
  Routes,
  Route,
} from "react-router-dom";

//Landing imports
import {
  Landing,
  PrivacyPolicy,
  TermsCondition,
  Services
} from "../components/LandingModule";

//Login imports
import {
  Otp,
  Login,
  ForgotPassword,
  Register,
  ResetPassword,
  ProfileCompletion,
} from "../components/AuthenticationModules";

//Owner imports
import {
  Profile,
  Dashboard,
  ExportData,
  ChangePassword,
  GroupManagement,
  CreateNewProject,
  ProjectManagement,
} from "../components/OwnerModule";
import EditProfile from "../components/OwnerModule/profile/editProfile";
import AddGroup from "../components/OwnerModule/groupManagement/addgroup";
import EditGroup from "../components/OwnerModule/groupManagement/editgroup";
import TaskDetails from "../components/OwnerModule/projectmanagement/taskDetails";
import GroupDetails from "../components/OwnerModule/projectmanagement/groupDetails";
import ExportDataTaskList from "../components/OwnerModule/exportData/exportDataTaskList";
import PastProjectDetails from "../components/OwnerModule/projectmanagement/pastProjectDetails";
import OngoingProjectDetails from "../components/OwnerModule/projectmanagement/ongoingprojectdetails";
import DisputeAnnotationDetails from "../components/OwnerModule/projectmanagement/disputeAnnotationDetails";
import PastProjectDetialsTaskDetails from "../components/OwnerModule/projectmanagement/pastprojectDetails(taskdetails)";

//User imports
import {
  UserNotifications,
  UserProfile,
  UserTaskManagement,
} from "../components/UserModule";
import UserDashboard from "../components/UserModule/dashboard";
import UserChangePassword from "../components/UserModule/profile/changePassword";
import WorkerPendingTaskDetails from "../components/UserModule/taskManagement/workerPendingTaskDetails";
import UDT_Screen_Worker from "../components/UserModule/taskManagement/workerPendingTaskDetails/UdtScreen";
import UDT_Screen_Owner from "../components/UserModule/taskManagement/workerCompletedTaskDetails/UdtScreen";
import UDT_Screen_Admin from "../components/UserModule/taskManagement/workerCompletedTaskDetails/UdtScreen";
import WorkerCompletedTaskDetails from "../components/UserModule/taskManagement/workerCompletedTaskDetails";
import ValidatorPendingTaskDetails from "../components/UserModule/taskManagement/validatorPendingTaskDeatils";
import ValidatorCompletedTaskDetails from "../components/UserModule/taskManagement/validatorCompletedTaskDetails";

//admin imports
import AdminDashboard from "../components/AdminModule/adminDashboard/index";
import UserManagement from "../components/AdminModule/userManagement/index";
// import GroupManagementAdmin from "../components/AdminModule/groupManagement";
import OwnerManagement from "../components/AdminModule/ownerManagement/index";
import ProjectManagementAdmin from "../components/AdminModule/projectmanagement/index";
// import EditDetailsAdmin from "../components/AdminModule/ownerManagement/editdetails/index";
import OwnerDetailsAdmin from "../components/AdminModule/ownerManagement/ownerDetails/index";
import AddOwnerDetailsAdmin from "../components/AdminModule/ownerManagement/addOwnerDetails/index";
import ProjectDetailsAdmin from "../components/AdminModule/projectmanagement/projectDetails/index";
import ProjectTaskDetailsAdmin from "../components/AdminModule/projectmanagement/taskDetails/index";

//admin imports

// import AddGroupAdmin from "../components/AdminModule/groupManagement/addgroup";
// import EditGroupAdmin from "../components/AdminModule/groupManagement/editgroup";
import EditProfileAdmin from "../components/AdminModule/profile/editProfile";
import ChangepasswordAdmin from "../components/AdminModule/changepassword/index";

import UDT_Screen from "../components/UserModule/taskManagement/workerCompletedTaskDetails/UdtScreen";
import { AudioTranscript } from "../components/UserModule/taskManagement/workerPendingTaskDetails/Udts";
import PageNotFound from "../components/PageNotFound";
import EmailConfirmed from "../components/EmailConfirmed";
import VerifyRegisteredEmail from "../components/AuthenticationModules/verifyPage";
import AcceptRejectAnnot from "../components/UserModule/taskManagement/validatorPendingTaskDeatils/AcceptRejectAnnotation";
import EditUser from "../components/AdminModule/userManagement/editUser";
import Features from "../components/LandingModule/features";

const RoutesNew = () => {
  return (
    <div>
      <Routes>
        <Route path={NavigateTo.DEFAULT} element={<Landing />} />
        <Route
          path={NavigateTo.AUDIO_TRANSCRIPT}
          element={<AudioTranscript />}
        />


        {/* Landing Module routes */}
        <Route path={NavigateTo.LANDING} element={<Landing />} />
        <Route path={NavigateTo.PRIVACY} element={<PrivacyPolicy />} />
        <Route path={NavigateTo.TERMS} element={<TermsCondition />} />
        <Route path={NavigateTo.SERVICES} element={<Services />} />
        <Route path={NavigateTo.FEATURES} element={<Features />} />
        <Route path={NavigateTo.RESETPASSWORD} element={<ResetPassword />} />
        <Route path={NavigateTo.LOGIN} element={<Login />} />
        <Route
          path={NavigateTo.PROFILECOMPLETION}
          element={<ProfileCompletion />}
        />
        <Route
          path={NavigateTo.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route path={NavigateTo.OTP} element={<Otp />} />
        <Route path={NavigateTo.REGISTER} element={<Register />} />
        <Route path={"/verify/:token"} element={<VerifyRegisteredEmail />} />
        <Route path={NavigateTo.OWNER_SET_PASSWORD + "/:token"} element={< ResetPassword />} />

        {/* Landing Module routes */}

        {/* <Route path={"/Custom"} element={<CustomTable />} /> */}

        {/* admin routes for admin userType is 1*/}
        <Route
          path={NavigateTo.ADMIN_EDIT_USER}
          element={
            <PrivateRoute ut={'admin'}>
              <EditUser />
            </PrivateRoute>
          }
        />


        <Route
          path={NavigateTo.ADMIN_PROJECT}
          element={
            <PrivateRoute ut={'admin'}>
              <ProjectManagementAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ADMIN_EDIT_PROFILE}
          element={
            <PrivateRoute ut={'admin'}>
              <EditProfileAdmin />
            </PrivateRoute>
          }
        />

        <Route
          path={NavigateTo.ADMIN_DASH_BOARD}
          element={
            <PrivateRoute ut={'admin'}>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ADMIN_OWNER_MANAGEMENT}
          element={
            <PrivateRoute ut={'admin'}>
              <OwnerManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ADMIN_USER_MANAGEMENT}
          element={
            <PrivateRoute ut={'admin'}>
              <UserManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ADMIN_PROJECT_MANAGEMENT}
          element={
            <PrivateRoute ut={'admin'}>
              <ProjectManagementAdmin />
            </PrivateRoute>
          }
        />

        <Route
          path={NavigateTo.USER_UDT_ADMIN + '/:taskId'}
          element={
            <PrivateRoute ut={'admin'}>
              <UDT_Screen_Admin />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ADMIN_CHANGE_PASSWORD}
          element={
            <PrivateRoute ut={'admin'}>
              <ChangepasswordAdmin />
            </PrivateRoute>
          }
        />
        {/* <Route
            path={NavigateTo.ADMIN_GROUP_MANAGEMENT}
            element={
              <PrivateRoute ut={'admin'}>
                <GroupManagementAdmin />
              </PrivateRoute>
            }
          /> */}
        <Route
          path={NavigateTo.ADMIN_OWNER_DETAILS}
          element={
            <PrivateRoute ut={'admin'}>
              <OwnerDetailsAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ADMIN_EDIT_DETAILS}
          element={
            <PrivateRoute ut={'admin'}>
              <EditUser />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ADMIN_ADD_OWNER_DETAILS}
          element={
            <PrivateRoute ut={'admin'}>
              <AddOwnerDetailsAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ADMIN_PORJ_DETAILS}
          element={
            <PrivateRoute ut={'admin'}>
              <ProjectDetailsAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ADMIN_PROJ_TASK_DETAILS + '/:taskId'}
          element={
            <PrivateRoute ut={'admin'}>
              <ProjectTaskDetailsAdmin />
            </PrivateRoute>
          }
        />

        {/* owner Screens for owner userType is 2*/}
        <Route
          path={NavigateTo.OWNER_DASHBOARD}
          element={
            <PrivateRoute ut={'owner'}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.DISPUTE_DETAILS}
          element={
            <PrivateRoute ut={'owner'}>
              <DisputeAnnotationDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ADD_GROUP}
          element={
            <PrivateRoute ut={'owner'}>
              <AddGroup />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.PROFILE}
          element={
            <PrivateRoute ut={'owner'}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.EDIT_GROUP}
          element={
            <PrivateRoute ut={'owner'}>
              <EditGroup />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.EXPORT_DATA}
          element={
            <PrivateRoute ut={'owner'}>
              <ExportData />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.EDIT_PROFLE}
          element={
            <PrivateRoute ut={'owner'}>
              <EditProfile />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.TASK_DETAILS + '/:taskId'}
          element={
            <PrivateRoute ut={'owner'}>
              <TaskDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.PROJECT}
          element={
            <PrivateRoute ut={'owner'}>
              <ProjectManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.GROUP_DETAILS}
          element={
            <PrivateRoute ut={'owner'}>
              <GroupDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.TASK_LIST}
          element={
            <PrivateRoute ut={'owner'}>
              <ExportDataTaskList />
            </PrivateRoute>
          }
        />
        <Route
          path={localStorage.getItem('taskType') == 'Image' ? NavigateTo.USER_UDT_OWNER + '/:taskId/:image_index' : NavigateTo.USER_UDT_OWNER + '/:taskId/'}
          element={
            <PrivateRoute ut={'owner'}>
              <UDT_Screen_Owner />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.CHANGE_PASSWORD}
          element={
            <PrivateRoute ut={'owner'}>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.GROUP_MANAGEMENT}
          element={
            <PrivateRoute ut={'owner'}>
              <GroupManagement />
            </PrivateRoute>
          }
        />

        <Route
          path={NavigateTo.CREATE_NEW_PROJECT}
          element={
            <PrivateRoute ut={'owner'}>
              <CreateNewProject />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.PAST_PROJECT_DETAILS}
          element={
            <PrivateRoute ut={'owner'}>
              <PastProjectDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.DISPUTE_ANNOTATION + '/:disputeId'}
          element={
            <PrivateRoute ut={'owner'}>
              <DisputeAnnotationDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ONGOING_PROJECT_DETAILS}
          element={
            <PrivateRoute ut={'owner'}>
              <OngoingProjectDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.PAST_TASK_DETAILS + '/:taskId'}
          element={
            <PrivateRoute ut={'owner'}>
              <PastProjectDetialsTaskDetails />
            </PrivateRoute>
          }
        />
        {/* owner Screens */}

        {/* user Screens for user userType is 3 */}
        <Route
          path={NavigateTo.USER_DASHBOARD}
          element={
            <PrivateRoute ut={'performer'}>
              <UserDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.USER_NOTIFICATIONS}
          element={
            <PrivateRoute ut={'performer'}>
              <UserNotifications />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.USER_TASK_MANAGEMENT}
          element={
            <PrivateRoute ut={'performer'}>
              <UserTaskManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.VALIDATOR_COMPLETED_TASK_DETAILS + '/:taskId'}
          element={
            <PrivateRoute ut={'performer'}>
              <ValidatorCompletedTaskDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.ACCEPT_REJECT_ANNOT}
          element={
            <PrivateRoute ut={'performer'}>
              <AcceptRejectAnnot />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.USER_UDT + '/:taskId'}
          element={
            <PrivateRoute ut={'performer'}>
              <UDT_Screen />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.WORKER_COMPLETED_TASK_DETAILS + '/:taskId'}
          element={
            <PrivateRoute ut={'performer'}>
              <WorkerCompletedTaskDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.WORKER_PENDING_TASK_DETAILS + '/:taskId'}
          element={
            <PrivateRoute ut={'performer'}>
              <WorkerPendingTaskDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.VALIDATOR_PENDING_TASK_DETAILS + '/:taskId'}
          element={
            <PrivateRoute ut={'performer'}>
              <ValidatorPendingTaskDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.WORKER_PENDING_UDT}
          element={
            <PrivateRoute ut={'performer'}>
              <UDT_Screen_Worker />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.USER_PROFILE}
          element={
            <PrivateRoute ut={'performer'}>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route
          path={NavigateTo.USER_CHANGE_PASSWORD}
          element={
            <PrivateRoute ut={'performer'}>
              <UserChangePassword />
            </PrivateRoute>
          }
        />
        {/* user Screens */}
        <Route
          path={NavigateTo.EMAIL_CONFIRMED}
          element={
            <EmailConfirmed />
          }
        />
        <Route
          path={NavigateTo.NOT_FOUND}
          element={<PageNotFound />}
        // element={<Navigate to="/" replace />} in case you want to navigate to home page for unkonwn routes
        />
        <Route
          path="*"
          element={<PageNotFound />}
        // element={<Navigate to="/" replace />} in case you want to navigate to home page for unkonwn routes
        />
      </Routes>
    </div>
  );
};
export default RoutesNew;
