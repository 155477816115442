import {
  fieldIds,
  NavigateTo,
  SidebarMenus,
} from "../../../../themes";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "../../../../molecules/Admin";
import { CardAdmin, ContainerAdmin } from "../../../../hoc";
import { getOwnerDetails } from "../../../../redux/actions/Owner";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn";

const OwnerDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const ownerDetails = useSelector(
    (state) => state?.ownerManagement?.ownerDetails
  );

  const userId = localStorage.getItem("ownerId");
  // breadcrumb
  const breadCrumbItems = [
    {
      label: t("dashboard"),
      link: NavigateTo.ADMIN_DASH_BOARD,
    },
    {
      label: t("owner_mngmnt"),
      link: NavigateTo.ADMIN_OWNER_MANAGEMENT,
    },
    {
      label: t("owner_details"),
      link: "",
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("admin"),
    active: t('owner_mngmnt'),
  };

  const ownerDetailsFormFields = [
    {
      type: "text",
      error: null,
      inputTrue: true,
      id: fieldIds.OWNER_NAME,
      value: ownerDetails?.first_name + " " + ownerDetails?.last_name || "", // configure later
      placeholder: t("owner_name"),
      fieldLabel: t("owner_name"),
    },
    {
      type: "text",
      error: null,
      inputTrue: true,
      id: fieldIds.EMAIL,
      value: ownerDetails?.email || "", // configure later
      placeholder: t("email_address"),
      fieldLabel: t("email_address"),
    },
  ];
  useEffect(() => {
    const payloadGetOwnerDetails = {
      id: userId,
    };
    isInternetConnected() && dispatch(getOwnerDetails(payloadGetOwnerDetails));
  }, []);

  return (
    <ContainerAdmin
      {...containerProps}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <CardAdmin cardInnerPadding={"content-e"}>
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
          <h6 className="text-white text-capitalize ps-3">
            {t("owner_details")}
          </h6>
        </div>
        <div className="form-group text-center_d">
          <img
            src={ownerDetails?.image}
            height={"130px"}
            width={"130px"}
            style={{ borderRadius: "65px" }}
          />
        </div>
        {ownerDetailsFormFields.map((items, index) => {
          return (
            <div
              key={index}
              className="wrapper_line"
              style={{ padding: "0px 15px 5px" }}
            >
              <TextField
                {...items}
                labelTitle={items.fieldLabel}
                isDisabled={true}
              />
            </div>
          );
        })}
        {/* number */}
        <div className="wrapper_line" style={{ marginBottom: "-23px" }}>
          <label>{t("contact_num")}</label>
          <div
            className="form-group"
            style={{
              display: "flex",
              marginBottom: "0px",
            }}
          >
            <input
              id="countryCode"
              type="text"
              className="form-control"
              placeholder={"+"}
              name="countryCode"
              maxLength={5}
              style={{
                width: "91px",
                borderEndEndRadius: "9px",
                borderStartEndRadius: "9px",
                marginLeft: localStorage.lang == "ar" ? "10px" : "unset",
              }}
              disabled={true}
              autoComplete="off"
              value={ownerDetails?.country_code}
            />
            <input
              id="contactNum"
              style={{
                width: "90%",
                marginLeft: localStorage.lang == "ar" ? "unset" : "16px",
                borderStartStartRadius: "9px",
                borderEndStartRadius: "9px",
              }}
              type="text"
              className="form-control"
              placeholder={t('contact_num')}
              name="contactNum"
              maxLength={15}
              autoComplete="off"
              value={ownerDetails?.phone}
              disabled={true}
            />
          </div>
        </div>

        {/* number */}
      </CardAdmin>
    </ContainerAdmin>
  );
};

export default OwnerDetails;
