import { Delete, Get, Post, PostFormData, Put } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl;

const Fetch = {
  // owner

  taskTypesApi() {
    let url = baseUrl + "main/data/task_category";
    return Get(url);
  },
  documentApi(payload) {
    let url = baseUrl + "documents/";
    const formData = new FormData();
    formData.append("task_id", payload.task_id);
    formData.append("offset", payload.offset);
    formData.append("limit", payload.limit);
    formData.append("unannotated", payload.unAnnotated);

    return PostFormData(formData, url);
  },
  taskQuotaApi(payload) {
    let task_id = payload.task_id;
    let url = baseUrl + `tasks/${task_id}/quotas/`;
    const formData = new FormData();
    formData.append("task_id", payload.task_id);
    formData.append("email", payload.email);
    formData.append("adding", payload.adding);
    return PostFormData(formData, url);
  },
  documentDetailsApi(payload) {
    let task_id = payload.task_id;
    let url = baseUrl + `tasks/${task_id}/documents/`;
    const formData = new FormData();
    formData.append("by_user", JSON.stringify(payload.by_user));
    formData.append("by_answer", JSON.stringify(payload.by_answer));
    formData.append("limit", payload.limit);
    return PostFormData(formData, url);
  },
  taskUserRemoveApi(payload) {
    let task_id = payload.task_id;
    let url = baseUrl + `tasks/${task_id}/remove/`;
    const formData = new FormData();
    formData.append("email", payload.email);

    return PostFormData(formData, url);
  },
  taskUserReplaceApi(payload) {
    let task_id = payload.task_id;
    let url = baseUrl + `tasks/${task_id}/replace/`;
    const formData = new FormData();
    formData.append("email", payload.email);
    formData.append("replace_email", payload.replace_email);

    return PostFormData(formData, url);
  },
  taskUserInviteRemoveApi(payload) {
    let task_id = payload.task_id;
    let url = baseUrl + `tasks/${task_id}/remove_invite/`;
    const formData = new FormData();
    formData.append("email", payload.email);
    formData.append("invite_type", payload.invite_type);

    return PostFormData(formData, url);
  },
  // create project api
  taskPreviewApi(payload) {
    let url = baseUrl + "projects/tasks/preview/";
    const formData = new FormData();

    // convert all keys from payload to formData
    Object.keys(payload).map((key) => {
      if (
        key == "classes" ||
        key == "questions" ||
        key == "invites" ||
        key == "invite_validators"
      ) {
        if (payload[key] !== null) {
          formData.append(key, JSON.stringify(payload[key]));
        } else {
          formData.append(key, "");
        }
      } else {
        formData.append(key, payload[key] !== null ? payload[key] : "");
      }
    });
    return PostFormData(formData, url);
  },
  // create task done ml2
  createTaskApi(payload) {
    let url = baseUrl + "tasks/";

    const formData = new FormData();

    // convert all keys from payload to formData
    Object.keys(payload).map((key) => {
      if (
        key == "classes" ||
        key == "questions" ||
        key == "invites" ||
        key == "invite_validators"
      ) {
        if (payload[key] !== null) {
          formData.append(key, JSON.stringify(payload[key]));
        } else {
          formData.append(key, "");
        }
      } else {
        formData.append(key, payload[key] !== null ? payload[key] : "");
      }
    });
    return PostFormData(formData, url);
  },

  // get task details
  taskDetailsApi(payload) {
    let url;

    if (payload?.user_id) {
      url =
        baseUrl +
        `task_details/${payload?.taskId}?user_id=${payload?.user_id}&type=3&page=${payload?.pageNumber}`;
      return Get(url, true);
    }
    // worker case
    else {
      if (payload?.type == "owner" || localStorage.getItem("user_type") == 1) {
        url =
          baseUrl +
          `tasks/${payload?.taskId}/?type=1&page=${payload?.pageNumber}&page_size=${payload?.pageSize}`; // owner case
        return Get(url, true);
      } else
        url = baseUrl + `performer/tasks/${payload?.taskId}?type=2&page=${payload?.pageNumber}&page_size=${payload?.pageSize}`; // validator case
      return Get(url, true);
    }
  },
  inviteToTaskApi(payload) {
    let taskId = payload.taskId;
    let url = "";
    const formData = new FormData();

    if (payload?.invites) {
      url = baseUrl + `tasks/${taskId}/invite_bulk/`;
      formData.append("invites", JSON.stringify(payload.invites));
    } else {
      url = baseUrl + `tasks/${taskId}/invite/`;
      formData.append("email", payload.email);
      formData.append("invite_type", payload.invite_type);
    }

    return PostFormData(formData, url);
  },
  getTaskInvitesApi(payload) {
    let taskId = payload.taskId;
    let url = baseUrl + `tasks/${taskId}/invites/`;
    return Get(url, true);
  },
  // get added task list  done ml2
  getAddedTaskListApi() {
    let url = baseUrl + "tasks/";
    return Get(url, true);
  },

  // delete added task  done ml2
  deleteAddedTaskApi(payload) {
    let url = baseUrl + `tasks/${payload.id}`;

    return Delete(url, true);
  },

  // owner

  // user
  getWorkerPendingTaskApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let searchValue = payload.searchValue ? payload.searchValue : "";

    let url =
      baseUrl +
      `performer/tasks/?type=${1}&status=${0}&limit=${10}&offset=${pageNum *
      10 -
      10}&ordering=${ordering}&search=${searchValue}`;

    return Post(
      {
        task_id: payload?.taskId || "",
      },
      url
    );
  },
  getWorkerCompletedTaskApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let searchValue = payload.searchValue != "" ? payload.searchValue : "";

    let url =
      baseUrl +
      `task_listing/?type=${1}&status=${1}&limit=${10}&offset=${pageNum * 10 -
      10}&ordering=${ordering}&search=${searchValue}`;

    return Get(url, true);
  },
  getValidatorPendingTaskApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let searchValue = payload.searchValue != "" ? payload.searchValue : "";

    let url =
      baseUrl +
      `task_listing/?type=${2}&status=${1}&limit=${10}&offset=${pageNum * 10 -
      10}&ordering=${ordering}&search=${searchValue}`;

    return Get(url, true);
  },
  getValidatorCompletedTaskApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let searchValue = payload.searchValue != "" ? payload.searchValue : "";
    let start_date =
      payload.searched_Date.start_date != ""
        ? payload.searched_Date.start_date
        : "";
    let end_date =
      payload.searched_Date.end_date != ""
        ? payload.searched_Date.end_date
        : "";
    let url =
      baseUrl +
      `task_listing/?type=${2}&status=${2}&limit=${10}&offset=${pageNum * 10 -
      10}&ordering=${ordering}&search=${searchValue}&start_date=${start_date}&end_date=${end_date}`;

    return Get(url, true);
  },

  // Owner Dashborad/Projects/Ongoing Project Details/Task Details   start
  getValidtaorsListOwnerOngoingProjSagaApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let url;

    //  http://192.168.3.145:4000/api/worker_or_validator_listing?type=1&id=30.  type= 1 worker, type = 2 validator.  id = taskId
    // (pageNum*10)-10
    url =
      baseUrl +
      `validator_listing?&id=${payload?.taskId}&limit=${10}&offset=${pageNum *
      10 -
      10}&ordering=${ordering}`; //
    const auth = true;
    return Get(url, auth);
  },
  getWorkerListOwnerOngoingProjApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let ordering = payload.ordering != "" ? payload.ordering : "";
    let url =
      baseUrl +
      `worker_listing?&id=${payload?.taskId}&limit=${10}&offset=${pageNum * 10 -
      10}&ordering=${ordering}`; //
    const auth = true;
    return Get(url, auth);
  },
  getUserInvitaionListApi(payload) {
    // let pageNum = payload.pageNum!='' ? payload.pageNum : 1 ;
    // let ordering = payload.ordering!='' ? payload.ordering : '' ;
    let url = baseUrl + `worker_or_validator_invte_list?id=${payload?.taskId}`;
    const auth = true;
    return Get(url, auth);
  },
  addValidatorForTaskApi(payload) {
    let url = baseUrl + `addValidator/`;
    return Post(payload, url);
  },
  addWorkerForTaskApi(payload) {
    let url = baseUrl + `AddWorker/`;
    return Post(payload, url);
  },
  // Owner Dashborad/Projects/Ongoing Project Details/Task Details   start

  // Owner Dashborad/Projects/Ongoing Project Details   start
  getTaskListsApi(payload) {
    let url = baseUrl + `project_create/${payload?.projectId}`;
    delete payload.projectId;
    return Put(payload, url);
  },
  deleteTaskAdminApi(payload) {
    console.log(payload, "payload saga api");
    let url = baseUrl + `task_create/${payload?.id}`;

    return Delete(url);
  },
  taskListingExportApi(payload) {
    let pageNum = payload.pageNum != "" ? payload.pageNum : 1;
    let searchValue = payload.searchValue != "" ? payload.searchValue : "";
    let url =
      baseUrl +
      `tasks/completed/?project_id=${payload.projectId
      }&search=${searchValue}&offset=${pageNum * 10 - 10}&limit=${10}`; //
    let auth = true;
    return Get(url, auth);
  },

  // Owner Dashborad/Projects/Ongoing Project Details   end
};

export default Fetch;
