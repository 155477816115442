import {  SET_USER_LIST,SET_USER_DETAILS, LOGOUT } from "./../../../actionTypes/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    status: 0,  
    success : false ,
    updateSuccess : false,
    userDetails : null,
    userList : [],

}
const UserReducer = (state = initialState, action) => { 
  
    switch (action.type) {
        case LOGOUT :
            return {
                ...state,
                isLoading: false,
    error: null,
    status: 0,  
    success : false ,
    updateSuccess : false,
    userDetails : null,
    userList : [],

            };
        case SET_USER_LIST:
            return { 
                ...state ,
                userList :  action?.payload
            } 
        case SET_USER_DETAILS:
            return { 
                ...state ,
                userDetails :  action?.payload
            } 
        default:
            return state;

    }
}

export default UserReducer;