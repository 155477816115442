import Fetch from "../Fetch/Owner";
import { put, call } from "redux-saga/effects";
import { logout, startLoading, stopLoading } from "../actions/Common";
import { toast } from "react-toastify";
import { customFunctions } from "../../themes/utils/CustomFunctions";
import { createOwnerSuccess, setOwnerDetails } from "../actions/Owner";
import { AppImage, NavigateTo } from "../../themes";
import appconstant from "../../themes/appconstant";
import i18n from 'i18next'
export function* createOwnerSaga({ payload, navigate }) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.createOwnerApi, payload);

    if (result.status === 1) {
      customFunctions.showToast(
        i18n.t("owner_added"),
        true,
        "createOwnersuccess"
      );
      navigate(NavigateTo.ADMIN_OWNER_MANAGEMENT);
      yield put(createOwnerSuccess());
      yield put(stopLoading());
    }

    if (result.status === 2) {
      yield put(stopLoading());
      console.log(result?.result?.errors);
      if (result?.result?.errors?.email) {
        toast.error(result?.result?.errors?.email[0], {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
          toastId: "erremail",
        });
      } else if (result?.result?.errors?.phone_no) {
        toast.error(result?.result?.errors?.phone_no[0], {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
          toastId: "errNum",
        });
      }
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
export function* updateOwnerSaga({ payload, navigate }) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.updateOwnerApi, payload);
    if (result.status === 1) {
      customFunctions.showToast(
        i18n.t('owner_updated'),
        true,
        "createOwnersuccess"
      );
      if (result?.result?.email_changed == 1) {
        yield put(logout("i"));
        navigate(NavigateTo.LOGIN);
      } else {
        if (localStorage.getItem('user_type') == 2)
          navigate(NavigateTo.PROFILE);
        if (localStorage.getItem('user_type') == 1)
          navigate(NavigateTo.ADMIN_OWNER_MANAGEMENT);

      }
      yield put(createOwnerSuccess());
      yield put(stopLoading());
    } else if (result.status === 2) {
      yield put(stopLoading());
      result?.result?.error && toast.error(result?.result?.error, {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        toastId: "erremail",
      });

      result?.result?.errors?.phone_no &&
        toast.error(result?.result?.errors?.phone_no[0], {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
          toastId: "errNum",
        });
      result?.result?.errors?.email &&
        toast.error(result?.result?.errors?.email[0], {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
          toastId: "errNum",
        });

    }
  } catch (error) {
    yield put(stopLoading());
  }
}
export function* deleteOwnerSaga({ payload }) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.deleteOwnerApi, payload);

    if (result.status === 1) {
      customFunctions.showToast(
        result?.result?.data,
        true,
        "deleteOwnersuccess"
      );
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      customFunctions.showToast(
        result?.result?.errors?.email[0],
        false,
        "deleteOwnerfail"
      );
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
export function* blockUnblockOwnerSaga({ payload }) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.blockUnblockOwnerApi, payload);

    if (result.status === 1) {
      customFunctions.showToast(
        result?.result?.is_blocked ? i18n.t("blocked") : i18n.t("unblocked"),
        true
        // "blockUnblockOwnersuccess"
      );
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      // customFunctions.showToast(result?.result?.errors?.email[0],false,'blockUnblockOwnerfail');
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
export function* getOwnerDetailsSaga({ }) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getOwnerDetailsApi);

    if (result.status === 1) {
      let ownerDetails = {};
      ownerDetails = {
        first_name: result?.result?.first_name,
        last_name: result?.result?.last_name,
        email: result?.result?.email,
        phone: result?.result?.phone_number,
        country_code: `${result?.result?.country_code}`,
        image: result?.result?.data?.image
          ? result?.result?.data?.image.startsWith("h")
            ? result?.result?.data?.image
            : result?.result?.data?.image.includes("static")
              ? AppImage.defaultImage
              : appconstant.imageBaseUrl + result?.result?.data?.image
          : AppImage.defaultImage,
      };
      yield put(setOwnerDetails(ownerDetails));
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
