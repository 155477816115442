import React, { useState, useEffect } from "react";
import { Layout, Select } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
const { Header } = Layout;


const HeaderAdmin = (props) => {
  const { handleHamburgerClick, handleLanguageChange } = { ...props };
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');

  const changeLanguage = async (lng) => {
    i18n.changeLanguage(lng);
  };
  const handleChange = (val) => {
    localStorage.setItem('lang', val);
    changeLanguage(val);
    handleLanguageChange(val);
    setLang(val);
    const contentsComponent = document.querySelector('section.ant-layout.new_layout');
    const languageDropdownContainer = document.querySelector('.rc-virtual-list-holder-inner');
    const siderComponent = document.querySelector('section.ant-layout.ant-layout-has-sider .ant-layout-sider');
    const sidebarMenuItemsParentComponent = document.querySelectorAll('div.sidebar-menu-items-container');
    if (val == 'ar') {
      document.querySelector('.ant-layout.ant-layout-has-sider').setAttribute('style', 'flex-direction:row-reverse')
      siderComponent.setAttribute('style', 'right:0;width:250px');
      for (let i = 0; i < sidebarMenuItemsParentComponent.length; i++) {
        const ele = sidebarMenuItemsParentComponent[i];
        ele.setAttribute('style', 'width: 214px;text-align: end');
      }
      contentsComponent.setAttribute('style', 'margin-right: auto;');
      languageDropdownContainer.style.direction = 'rtl';
      // if(localStorage.getItem('user_type')==1){
      //   document.querySelector('.ant-menu-item.ant-menu-item-only-child:nth-child(2) span img').style.marginLeft='26px'
      //   document.querySelector('.ant-menu-item.ant-menu-item-only-child:nth-child(4) span img').style.marginLeft='26px'
      // }
    }
    else {
      document.querySelector('.ant-layout.ant-layout-has-sider').setAttribute('style', 'flex-direction:row')
      siderComponent.setAttribute('style', 'right:unset;width:250px');
      contentsComponent.setAttribute('style', 'margin-left: auto;');
      languageDropdownContainer.style.direction = 'ltr';
      // if(localStorage.getItem('user_type')==1){
      //   document.querySelector('.ant-menu-item.ant-menu-item-only-child:nth-child(2) span img').style.marginLeft='0px'
      //   document.querySelector('.ant-menu-item.ant-menu-item-only-child:nth-child(4) span img').style.marginLeft='0px'
      // }
    }
  }
  useEffect(() => {
    // if(localStorage.getItem('user_type')==1 && localStorage.getItem('lang')=='ar'){
    //   document.querySelector('.ant-menu-item.ant-menu-item-only-child:nth-child(2) span img').style.marginLeft='26px'
    //   document.querySelector('.ant-menu-item.ant-menu-item-only-child:nth-child(4) span img').style.marginLeft='26px'
    // }
    // if(localStorage.getItem('user_type')==1 && localStorage.getItem('lang')=='en'){
    //   document.querySelector('.ant-menu-item.ant-menu-item-only-child:nth-child(2) span img').style.marginLeft='0px'
    //   document.querySelector('.ant-menu-item.ant-menu-item-only-child:nth-child(4) span img').style.marginLeft='0px'
    // }
  })

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'en')
    }
  }, [])


  return (
    <div>
      <Header
        className="site-layout-sub-header-background admin-header"
        style={{ padding: 0 }}
      >
        <Select
          defaultValue={lang}
          style={{
            width: 222,
            top: '26px',
            left: localStorage.getItem('lang') == 'ar' ? 'unset' : '26px',
            right: localStorage.getItem('lang') == 'ar' ? '26px' : 'unset',

          }}
          name="language"
          className="select_new user_new_select"
          onChange={handleChange}
        >
          <option title="" color="#fff" className="language-dropdown" value="en">
            English
          </option>
          <option title="" value="ar">Arabic</option>
        </Select>
        <div className="iocns">
          <FontAwesomeIcon
            icon={faBars}
            className="lite-text"
            onClick={handleHamburgerClick}
          />
        </div>
      </Header>
    </div>
  );
};
export default HeaderAdmin;
