import React from 'react';
import ReactDataSheet from 'react-datasheet';


const imageCocoCodeString = `{
    "info": {
        "description": "Exported using WosoM",
        "url": ""
    },
    "licenses": [
        {
            "url": "",
            "id": 1,
            "name": "Unknown"
        }
    ],
    "images": [
        {
            "file_name": "image1.jpg",
            "height": 1080,
            "width": 1920,
            "id": 1
        }
    ],
    "annotations": [
        {
            "segmentation": [
                [
                    0,
                    0,
                    0,
                    1080,
                    1920,
                    1080,
                    1920,
                    0
                ]
            ],
            "area": 2073600,
            "iscrowd": 0,
            "image_id": 1,
            "bbox": [
                0,
                0,
                1920,
                1080
            ],
            "category_id": 1,
            "id": 1
        }
    ],
    "categories": [
        {
            "supercategory": "person",
            "id": 1,
            "name": "person"
        }
    ]
}`;
const imagePascalVoctString = `<annotation>
  <folder>images</folder>
  <filename>example.jpg</filename>
  <path>/path/to/example.jpg</path>
  <source>
      <database>Unknown</database>
  </source>
  <size>
      <width>640</width>
      <height>480</height>
      <depth>3</depth>
  </size>
  <segmented>0</segmented>
  <object>
      <name>cat</name>
      <pose>Unspecified</pose>
      <truncated>0</truncated>
      <difficult>0</difficult>
      <bndbox>
          <xmin>200</xmin>
          <ymin>150</ymin>
          <xmax>400</xmax>
          <ymax>350</ymax>
      </bndbox>
  </object>
</annotation>
`;
const imageYoloString = `CLS 0.0 0.0 0.0 0.0 0.0
CLS 0.0 0.0 0.0 0.0 0.0
CLS 0.0 0.0 0.0 0.0 0.0
CLS 0.0 0.0 0.0 0.0 0.0
....

CLS 0.0 0.0 0.0 0.0 0.0
`;
const audioJSONString = `[
    {
        "id": 1,
        "audio": "audio1.mp3",
        "text": "label1",
        "start": 0,
        "end": 10
    },
    {
        "id": 2,
        "audio": "audio2.mp3",
        "label": "label2",
        "start": 0,
        "end": 10
    }
]`
const textJSONString = `[
    {
        "id": 1,
        "paragraph": "Sit dolor amet ipsum",
        "question": "Lorem",
        "answer":"Lorem",
    },
    {
        "id": 2
        "paragraph": "Adipiscing,ipsum,amet",
        "question": "Dolor",
        "answer": "Dolor",
    },
]`
const audioXMLString = `<audios>
    <audio>
        <id>1</id>
        <audio>audio1.mp3</audio>
        <text>label1</text>
        <start>0</start>
        <end>10</end>
    </audio>
    <audio>
        <id>2</id>
        <audio>audio2.mp3</audio>
        <text>label2</text>
        <start>0</start>
        <end>10</end>
    </audio>
</audios>`;

function generateRandomSentence(length = 10, join_char = ' ') {
    const words = ['Lorem', 'ipsum', 'dolor', 'sit', 'amet', 'consectetur', 'adipiscing', 'elit'];
    const sentenceLength = Math.floor(Math.random() * length) + 1;
    const sentence = [];

    for (let i = 0; i < sentenceLength; i++) {
        const randomIndex = Math.floor(Math.random() * words.length);
        sentence.push(words[randomIndex]);
    }

    return sentence.join(join_char).charAt(0).toUpperCase() + sentence.join(join_char).slice(1);
}
let grid = [
    [
        { value: 'index', readOnly: true, className: "text-bold" },
        { value: 'paragraph', readOnly: true, className: "text-bold" },
        { value: 'question', readOnly: true, className: "text-bold" },
        { value: 'answers', readOnly: true, className: "text-bold" },
        { value: 'comments', readOnly: true, className: "text-bold" },
    ],

];

for (let i = 0; i < 10; i++) {
    grid.push([
        { value: i < 5 ? i + 1 : i + 40, readOnly: true },
        { value: generateRandomSentence(4), readOnly: true },
        { value: generateRandomSentence(1), readOnly: true },
        { value: generateRandomSentence(1), readOnly: true },
        { value: generateRandomSentence(1), readOnly: true },
    ]);
}
// replace the middle  row with a dots
grid[5] = [
    { value: '...', readOnly: true },
    { value: '...', readOnly: true },
    { value: '...', readOnly: true },
    { value: '...', readOnly: true },
    { value: '...', readOnly: true },
];

const textClassificationTable = <div className="sheet-container">
    <ReactDataSheet
        data={grid}
        valueRenderer={cell => cell.value}
        overflow="clip"
    />
</div>

grid = [
    [
        { value: 'index', readOnly: true, className: "text-bold" },
        { value: 'paragraph', readOnly: true, className: "text-bold" },
        { value: 'entities', readOnly: true, className: "text-bold" },
    ],

];

for (let i = 0; i < 10; i++) {
    grid.push([
        {
            value: i < 5 ? i + 1 : i + 40,
            readOnly: true
        },
        { value: generateRandomSentence(4), readOnly: true },
        { value: generateRandomSentence(4, ','), readOnly: true },
    ]);
}

grid[5] = [
    { value: '...', readOnly: true },
    { value: '...', readOnly: true },
    { value: '...', readOnly: true },
];


const textNerTable = <div className="sheet-container">
    <ReactDataSheet
        data={grid}
        valueRenderer={cell => cell.value}
        overflow="clip"
    />
</div>
export {
    imageCocoCodeString,
    imagePascalVoctString,
    imageYoloString,
    audioJSONString,
    audioXMLString,
    textClassificationTable,
    textNerTable,
    textJSONString
};