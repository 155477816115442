const ValidationConstants = {
    valProjName: 'valProjName',
    valAddress: 'valAddress',
    valTaskName: 'valTaskName',
    lastNameValidate: 'lastNameValidate',
    firstNameValidate: 'firstNameValidate',
    contactNum: 'contactNum',
    passLength: 'passLength',
    reqConfirmPassword: 'reqConfirmPassword',
    reqContactNum: 'reqContactNum',
    reqCitizenship: 'reqCitizenship',
    passValid: 'passValid',
    otpLength: 'otpLength',
    valEmail: 'valEmail',
    valCitizenship: 'valCitizenship',
    valGender: 'valGender',
    reqEmail: 'reqEmail',
    reqAddress: 'reqAddress',
    reqGender: 'reqGender',
    reqProjName: 'reqProjName',
    reqOwnerName: 'reqOwnerName',
    reqGroupName: 'reqGroupName',
    reqTaskName: 'reqTaskName',
    reqTaskType: 'reqTaskType',
    reqName: 'reqName',
    reqPhone: 'reqPhone',
    reqPlayerPosition: 'reqPlayerPosition',
    noSpace: 'noSpace',
    space: 'space',
    reqPassword: 'reqPassword',
    reqOldPassword: 'reqOldPassword',
    reqNewPassword: 'reqNewPassword',
    reqConfirmNewPassword: 'reqConfirmNewPassword',
    resPassword: 'resPassword',
    oldPass: 'oldPass',
    reqFirstName: 'reqFirstName',
    reqLastName: 'reqLastName',
    newPass: 'newPass',
    password: 'password',
    confirmSpace: 'confirmSpace',
    match: 'match',
    matchPassRegister: 'matchPassRegister',
    confirmPassword: 'confirmPassword',
    name2: 'name2',
    name30: 'name30',
    noBlankSpace: 'noBlankSpace',
    fileSize: 'fileSize',
    formats: 'formats',
    valid_Number: 'valid_Number',
    upload_Image: 'upload_Image',
    change_Image: 'change_Image',
    matchNewPassword: 'matchNewPassword'
};

export default ValidationConstants;