import React, { useEffect } from "react"
// import images from "../themes/appImage";
import PropTypes from "prop-types";

import {
  Link

} from "react-router-dom";
import images from "../../themes/appImage";

const HeaderLandingInner = ({
  toogled,
}) => {

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])


  return (
    <header className="headers color_inner">
      <div className="container">
        <nav className="navbar navbar-expand-lg">


          {/* <button class="navbar-toggler" onClick={() => handle()} type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
          </button> */}

          <div className={toogled ? 'collapse navbar-collapse  justify-content-end show' : 'collapse navbar-collapse  justify-content-end '} id="collapsibleNavbar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/landing" class="nav-link active">Home</Link>
              </li>
            </ul>
          </div>

          <Link to="/landing" class="navbar-brand" style={{ cursor: 'pointer' }}>
            <img src={images.logo} alt="" />  </Link>
        </nav>
      </div>
    </header>
  )
}

HeaderLandingInner.propTypes = {
  toogled: PropTypes.bool,
}


export default HeaderLandingInner