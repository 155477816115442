import * as Yup from "yup";
import { Modal } from "antd";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import React, { useState, useEffect } from "react";
import ValidationConstants from "../../../themes/validationConstants";
import { Constants, AppImage, NavigateTo } from "../../../themes/index";
import {
  resetPasswordAction,
} from "../../../redux/actions/Actions";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { verifyForgotToken } from "../../../redux/actions/Common";
import { useTranslation } from "react-i18next";
const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const token = location.pathname.split("/")[3];
  const uid = location.pathname.split("/")[2];
  const routeName = location.pathname.split("/")[1];
  const [disableButton, setDisableButton] = useState(false);
  const [tokenn, setTokenn] = useState(null); // when the token got from url is not correct
  // const [uidd, setUID] = useState(null); // when the token got from url is not correct

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqPassword),
        (value) => value?.trim()?.length > 0
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$&()\\-`.+,/\"^*?%-=,\[<\]>{}])[A-Za-z\d!@#$&()\\-`.+,/\"^*?%-\[\]=,<>{}]{6,1000}$/,

        t(ValidationConstants.passValid)
      )
      .required(t(ValidationConstants.reqPassword)),

    confirmPassword: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqConfirmPassword),
        (value) => value?.trim()?.length > 0
      )
      .required(t(ValidationConstants.reqConfirmPassword))
      .oneOf(
        [Yup.ref("password"), null],
        t(ValidationConstants.matchPassRegister)
      ),
  });
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const onSubmit = (values) => {
    setDisableButton(true);

    const payload = {
      body: {
        uid: uid,
        token: token,
        password: values.password,
      },
      success: (res) => {
        if (res == "Link has been expired.") {
        } else {
          setIsModalVisible(true);
        }
        setDisableButton(false);
      },
      fail: () => {
        setDisableButton(false);
      },
    };
    isInternetConnected() && dispatch(resetPasswordAction(payload));
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    // validate,
  });

  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
    localStorage.setItem("forgotToken", "true");
    const payload = {
      body: {
        uid: uid,
        token: token,
      },
      success: () => {
        setTokenn(true);
        // setUID(true);
      },
      fail: () => {
        setTokenn(false);
        // setUID(false);
      },
    };
    isInternetConnected() && dispatch(verifyForgotToken(payload));
  }, []);

  return (
    tokenn !== null &&
    <div className="image-bg">
      <div className="page-header">
        <div className="line_true stopOverFlow">
          <div className="content-e ">
            <div className="wrapper_line login">
              {tokenn == true ? (
                // {localStorage.getItem("forgotToken") != null &&
                // localStorage.getItem("forgotToken") == "true" ? (
                <>
                  {/* header */}
                  <div className="text-center cost">
                    <img src={AppImage.logo3} />
                    <h6 className="text-white text-capitalize ps-3 headg_d forgot_team">
                      {routeName == "setPassword"
                        ? Constants.SetPassword
                        : Constants.ResetPassword}
                    </h6>
                  </div>
                  {/* header */}

                  <form noValidate onSubmit={formik.handleSubmit}>
                    {/* Password field */}
                    <div
                      className="form-group"
                      style={{
                        marginTop: "70px",
                      }}
                    >
                      {/* <label>{Constants. .EmailAddress}</label> */}
                      <input
                        id={"password"}
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={formik.values.password.trimLeft()}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        maxLength={1000}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                      {formik.errors.password ? (
                        <div className="error">{formik.errors.password}</div>
                      ) : null}
                    </div>
                    {/* Password field */}

                    {/* Confirm Password field */}
                    <div className="form-group">
                      {/* <label>{Constants. .EmailAddress}</label> */}
                      <input
                        id={"confirmPassword"}
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={formik.values.confirmPassword.trimLeft()}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        maxLength={1000}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                      {formik.errors.confirmPassword ? (
                        <div className="error">
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                    {/* Confirm Password field */}

                    {/* Send Button */}
                    <div
                      className="data_line"
                      style={{
                        padding: "0",
                        marginBottom: "15px",
                        marginTop: "70px",
                      }}
                    >
                      <button
                        type="submit"
                        disabled={disableButton}
                        className="button-list focus"
                      >
                        {routeName == "setPassword" ? "Set" : Constants.Reset}
                      </button>
                    </div>
                    {/* Send Button */}
                  </form>
                </>
              ) : null}

              {tokenn == false ? (
                <>
                  <div className="text-center cost">
                    <img src={AppImage.logo3} />
                    <h6
                      style={{
                        marginBottom: "25px",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "29px",
                        marginBottom: "37px",
                        color: "#143c64",
                        marginTop: "12px",
                      }}
                      className="text-white  ps-3 headg_d forgot_team"
                    >
                      Your password link has been expired.
                    </h6>
                  </div>
                  <div
                    className="data_line"
                    style={{
                      padding: "0",
                      marginBottom: "15px",
                      marginTop: "40px",
                      fontWeight: "800",
                    }}
                  >
                    <button
                      onClick={() => {
                        navigate(NavigateTo.LOGIN);
                      }}
                      style={{
                        padding: "10px 21px",
                        fontSize: "15px",
                      }}
                      type="button"
                      className="button-list focus"
                    >
                      {Constants.Login}
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Modal
        closable={false}
        title={Constants.Success}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="success_data"
        centered
      >
        <div className="text-center  model1 paddinf_2">
          <p>
            {routeName == "setPassword"
              ? "Your password is set successfully."
              : Constants.successResetPass}
          </p>
        </div>
        <div className="text-center  model1 ">
          <Link to={NavigateTo.LOGIN}>
            <button
              type="submit"
              className="button-list top_new color_new bottom_button new_color"
              onClick={() => handleCancel(false)}
            >
              {Constants.done}
            </button>
          </Link>
        </div>
      </Modal>
    </div>
  );
};
export default ResetPassword;
