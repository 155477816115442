import { toast } from "react-toastify";
import {
  USER_CHANGE_PASSWORD_ERROR,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_FORGET_ERROR,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_REGISTER_ERROR,
  USER_RESET_PASSWORD_ERROR,
  USER_SET_PASSWORD_ERROR,
  USER_SET_PASSWORD_SUCCESS,
} from "../actionTypes/actionTypes";
import { put, call } from "redux-saga/effects";
import Fetch from "../Fetch/Api";
import { startLoading, stopLoading } from "../actions/Common";
import { customFunctions } from "../../themes/utils/CustomFunctions";
import i18n from 'i18next';


function* authanicationUser(result, history) {
  localStorage.clear();
  history.push("/");
  toast.error(result.result.message, {

    className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
    position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

  });
}


// pass action to saga function
//done
export function* PostLoginApi({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.loginApi, body);
    if (result.status == 1) {
      yield put({
        type: USER_LOGIN_SUCCESS,
        payload: result?.result?.data?.token,
      });
      localStorage.setItem("user_type", result?.result?.user_type);
      localStorage.setItem("fullName", result?.result?.full_name); // DO IT HERE
      if (result?.result?.image) {
        localStorage.setItem(
          "userImage", result?.result?.image
        );
      }
      success(result);

      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
      toast.error(result?.result?.non_field_errors, {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        toastId: "loginFail",
      });
    }
  } catch (error) {
    yield put({
      type: USER_LOGIN_ERROR,
      result: error,
    });
    yield put(stopLoading());
  }
}
//done
export default function* postForgotApi({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.forgotApi, body);

    if (result.status === 1) {
      yield put(stopLoading());
      success(result?.result?.msg);
    } else {
      yield put(stopLoading());
      fail(result);
    }
  } catch (error) {
    yield put(stopLoading());
    yield put({
      type: USER_FORGET_ERROR,
      result: error,
    });
  }
}
//done
export function* postResetPassApi({
  payload: { routeName, body, success = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    let result;
    if (routeName == "setPassword") {
      result = yield call(Fetch.OwnerSetPasswordApi, body);
    } else {
      result = yield call(Fetch.ResetPassApi, body);
    }
    if (result.status === 1) {
      success(result?.result);

      if (result?.result?.status == 400) {
        toast.error(result?.result?.message, {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
          toastId: "tokenExpired",
        });
      }

      toast.success(result?.result?.detail, {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        toastId: "resetSuccess",
      });
      yield put(stopLoading());
    } else {
      toast.error(result?.result?.message, {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        toastId: "resetSuccess",
      });
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    yield put({
      type: USER_RESET_PASSWORD_ERROR,
      result: error,
    });
  }
}
//done
export function* registerUserApi({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    if (body.image != null) {
      localStorage.setItem("isFormData", "true");
    } else {
      localStorage.setItem("isFormData", "false");
    }


    const result = yield call(Fetch.RegitserUserApi, body);
    if (result.status === 1) {
      yield put(stopLoading());
      success(result?.result);
      localStorage.setItem("isFormData", "false");
    }
    result?.result?.errors &&
      toast.error(i18n.t(result?.result?.errors[0]), {

        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

        toastId: 'errEmail'
      });
    result?.result?.error &&
      toast.error(i18n.t(result?.result?.error), {

        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

        toastId: 'errphone'
      });
    result?.result?.errors?.phone_no[0] &&
      customFunctions.showToast(
        result?.result?.errors?.phone_no[0],
        false,
        "dnum"
      );
    if (result.status === 2) {
      yield put(stopLoading());
      localStorage.setItem("isFormData", "false");



    } else {
      fail(result);
      yield put(stopLoading());
      localStorage.setItem("isFormData", "false");
    }
  } catch (error) {
    localStorage.setItem("isFormData", "false");
    yield put(stopLoading());
    yield put({
      type: USER_REGISTER_ERROR,
      result: error,
    });
  }
}
export function* postChangePassApi(action) {
  try {
    const result = yield call(Fetch.changePassApi, action.payload);
    yield put(startLoading());
    if (result.status === 1) {
      yield put({
        type: USER_CHANGE_PASSWORD_SUCCESS,
        result: result?.result,
        status: result.status,
        value: action.payload,
      });
      localStorage.clear();
      action.history.push("/");
      toast.success(result?.result?.message, {

        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

      });
      yield put(stopLoading());
    } else if (result.status === 4) {
      yield put(stopLoading());

      yield call(authanicationUser, result, action.history);
    } else {
      yield put({
        type: USER_CHANGE_PASSWORD_ERROR,
        result: result?.result?.data?.data,
      });
      toast.error(result?.result?.message, {
        toastId: "est",
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    yield put({
      type: USER_CHANGE_PASSWORD_ERROR,
      result: error,
    });
  }
}
export function* postSetPassApi(action) {
  try {
    const result = yield call(Fetch.setPassApi, action.userId, action.payload);
    yield put(startLoading());
    if (result.status === 1) {
      yield put({
        type: USER_SET_PASSWORD_SUCCESS,
        result: result?.result,
        status: result.status,
        data: result?.result,
        value: action.payload,
      });
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      yield put({
        type: USER_SET_PASSWORD_ERROR,
        result: result?.result?.data?.data,
      });
    }
  } catch (error) {
    yield put(stopLoading());
    yield put({
      type: USER_SET_PASSWORD_ERROR,
      result: error,
    });
  }
}
export function* otpVerificationApi({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());

    const result = yield call(Fetch.otpVerificationApi, body);

    if (result.status === 1) {
      yield put(stopLoading());
      success(result);
    } else {
      yield put(stopLoading());
      fail(result);
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
export function* logoutSaga({ payload }) {
  try {
    yield put(startLoading());
    let result;
    if (payload) {
      result = yield call(Fetch.logoutApi);
    } else {
      localStorage.clear();
      i18n.changeLanguage('en');
      toast.error(i18n.t('session_timed_out'), {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        toastId: "logoutSuccess",
      });
    }

    if (result.status === 1) {
      yield put(stopLoading());
      toast.success(i18n.t('logged_out'), {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        toastId: "logoutSuccess",
      });
      localStorage.clear();
      i18n.changeLanguage('en');
    } else {
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
