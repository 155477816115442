import {
  AppImage,
  NavigateTo,
  SidebarMenus,
} from "../../../themes";
import { useFormik } from "formik";
import { Select, Modal, Table, Tooltip, Rate } from "antd";
import { CardHoc, Container } from "../../../hoc";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import AddWorkerModal from "./addWorkerModal";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { customFunctions } from "../../../themes/utils/CustomFunctions";
import { useDispatch } from "react-redux";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import {
  shouldMemberBeRemoved,
  updateGroup,
} from "../../../redux/actions/Group";
import appconstant from "../../../themes/appconstant";
import { constants } from "../../../common/fileConstants/Owner/groupManagement";

const EditGroup = () => {
  const grpNameRef = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [removed, setRemoved] = useState([]);
  const [removalId, setRemovalId] = useState("");
  const [selectedIds, setselectedIds] = useState([]);
  const [isModalRemove, setIsModalRemove] = useState(false);
  const [showWorkerModal, setShowWrokerModal] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [addedGroupMembers, setAddedGroupMembers] = useState([]);
  const [inviteWorkerEmails, setInviteWorkerEmails] = useState();
  const breadCrumbItems = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.GROUP_MANAGEMENT,
      label: t("grp_mngmnt"),
    },
    {
      link: "",
      label: t("edit_group"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t('grp_mngmnt'),
  };
  const showModalRemove = () => {
    setIsModalRemove(true);
  };
  const handleOkRemove = () => {
    setIsModalRemove(false);
  };
  const handleCancelRemove = () => {
    setIsModalRemove(false);
  };
  const dataSource = addedGroupMembers;

  const columns = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => index + 1,
    },
    {
      title: t("profile_img"),
      dataIndex: "profile",
      key: "profile",
      render: (value) => {
        return (
          <img
            style={{
              width: "44px",
              height: "44px",
              borderRadius: "44px",
            }}
            src={
              value
                ? value.startsWith("h")
                  ? value
                  : value.includes("static")
                    ? AppImage.defaultImage
                    : appconstant.imageBaseUrl + value
                : AppImage.defaultImage
            }
          />
          // </span>
        );
      },
    },
    {
      title: t("user_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,

      sorter: (a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      },
    },
    {
      title: t("rating"),
      dataIndex: "rating",
      key: "rating",
      sorter: true,
      width: 280,

      sorter: (a, b) => a.rating - b.rating,
      render: (value) => {
        return (
          <Rate
            style={{
              fontSize: "20px",
              position: "absolute",
              top: "24px",
              right: "105px",
              maxWidth: "fit-content",
            }}
            className="edit-group-star"
            allowHalf
            value={value}
            disabled={true}
          />
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "Posted",
      key: "Posted",
      render: (text, record) => {
        return (
          <div>
            <button
              type="button"
              className="focus"
              onClick={() => {
                setRemovalId(record.id);
                showModalRemove();
              }}
            >
              {t("remove")}
            </button>
          </div>
        );
      },
    },
  ];
  const dataArray = [
    {
      name: "Micheal",
      profile: AppImage.user,
      rating: AppImage.star,
    },
    {
      name: "Micheal",
      profile: AppImage.user,
      rating: AppImage.star,
    },
    {
      name: "Micheal",
      profile: AppImage.user,
      rating: AppImage.star,
    },
  ];
  const data123 = (values_data) => {
    return (
      <div className="data_news">
        <div className="first_text">
          <img src={values_data.profile} style={{ width: "20px" }}></img>
        </div>
        <div className="first_text">{values_data.name}</div>
        <div>
          <img src={values_data.rating}></img>
        </div>
      </div>
    );
  };
  const { Option } = Select;
  const children = [];
  for (let i = 0; i < dataArray.length; i++) {
    children.push(<Option key={i}>{data123(dataArray[i])}</Option>);
  }
  const dataArrayEmail = [
    {
      name: "abc12@yopmail.com",
    },
    {
      name: "m12@yopmail.com",
    },
  ];
  const Emaildata = (values_data_new) => {
    return (
      <div className="data_news">
        <div className="first_text">{values_data_new.name}</div>
      </div>
    );
  };
  const childrenEmail = [];
  for (let i = 0; i < dataArrayEmail.length; i++) {
    childrenEmail.push(<Option key={i}>{Emaildata(dataArrayEmail[i])}</Option>);
  }

  const onSubmit = (values) => {
    let bodyTobeSend;
    if (selectedIds.length > 0) {
      bodyTobeSend = {
        name: values.grpName,
        members: selectedIds,
        groupId: location?.state?.groupId,
      };
    }
    if (inviteWorkerEmails && inviteWorkerEmails.length > 0) {
      bodyTobeSend = {
        name: values.grpName,
        non_users: inviteWorkerEmails,
        groupId: location?.state?.groupId,
      };
    }
    if (
      selectedIds.length > 0 &&
      inviteWorkerEmails &&
      inviteWorkerEmails.length > 0
    ) {
      bodyTobeSend = {
        name: values.grpName,
        members: selectedIds,
        non_users: inviteWorkerEmails,
        groupId: location?.state?.groupId,
      };
    }
    const payloadForGroupUpdate = {
      success: (res) => {
        toast.success(res?.message, {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
          toastId: "samegroupnameSuccess",
        });
        navigate(NavigateTo.GROUP_MANAGEMENT);
      },
      fail: (res) => {
        if (res.result.errors?.name) {
          toast.error(res.result.errors.name[0], {
            className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
            toastId: "samegroupnameError",
          });
        } else {
          toast.error(res.result.errors.non_field_errors[0], {
            className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
            toastId: "samegroupnameError",
          });
        }
      },
      body: bodyTobeSend,
    };

    if (selectedIds.length == 0) {
      toast.error(t("add_min_1grp_member"), {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
    }
    else {
      isInternetConnected() && dispatch(updateGroup(payloadForGroupUpdate));
      // navigate(NavigateTo.GROUP_MANAGEMENT);
    }
  };
  const initialValues = {
    grpName: location?.state?.groupName || "Group Name",
  };
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: constants.formikValidationSchemaEG(localStorage.lang),
  });
  useEffect(() => {
    if (location?.state?.groupId) {
      document.title = "WosoM";
      window.scrollTo(0, 0);
      const groupMembersModified = [];
      const ids = [];
      let groupMembers;
      if (location?.state?.groupMemberss?.length) {
        groupMembers = location?.state?.groupMemberss;

        groupMembers.map((item, index) => {
          ids.push(item.id);
          groupMembersModified.push({
            id: item?.id,
            key: index + 1,
            profile: item?.image
              ? item?.image.startsWith("h")
                ? item?.image
                : item?.image.includes("static")
                  ? AppImage.defaultImage
                  : appconstant.imageBaseUrl + item?.image
              : AppImage.defaultImage,
            name: item?.first_name + " " + item?.last_name,
            rating: item?.average_rating,
          });
        });
        setAddedGroupMembers(groupMembersModified);
        setselectedIds(ids);
      }
    } else {
      navigate(NavigateTo.GROUP_MANAGEMENT);
    }
  }, []); //formik.isSubmitting, formik.errors
  useEffect(() => {
    if (formik.values.grpName != "" && formik.values.grpName == " ") {
      formik.setFieldError("grpName", t("plz_entr_grp_name"));
    }
    if (
      formik.values.grpName.length < 3 &&
      formik.values.grpName != " " &&
      formik.values.grpName != ""
    ) {
      formik.setFieldError(
        "grpName",
        t("grp_name_min_3char")
      );
    }
  }, [addedGroupMembers, formik.isSubmitting, formik.errors]);

  useEffect(() => { }, [isModalRemove, showWorkerModal]);
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isModalRemove]);
  useEffect(() => {
    Object.keys(formik.errors).forEach(fieldName => {
      formik.setFieldTouched(fieldName)
    });
  }, [lang])
  return (
    <Container
      {...containerProps}
      languageChanged={() => {
        setLang(localStorage.getItem("lang"));
      }}
    >
      <CardHoc cardInnerPadding={"content-e"}>
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
          <h6 className="text-white text-capitalize ps-3">{t("edit_group")}</h6>
        </div>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="wrapper_line">
            {/* group name */}
            <div className="form-group">
              <label>{t("grp_name")}</label>
              <input
                id="grpName"
                ref={grpNameRef}
                value={customFunctions.capitalizeFirstLetter(
                  formik.values.grpName.trimLeft()
                )}
                onChange={formik.handleChange}
                type="text"
                className="form-control"
                placeholder={t("grp_name")}
                maxLength={15}
                onKeyDown={(e) => {
                  if (e.key == " " && formik.values.grpName == "") {
                    formik.setFieldValue("grpName", "");
                  }
                }}
                autoComplete="off"
              />
              {formik.errors.grpName ? (
                <div
                  className={
                    localStorage.getItem("lang") == "ar"
                      ? "error ar"
                      : "error en"
                  }
                >
                  {formik.errors.grpName}
                </div>
              ) : null}
            </div>
            {/* add group members */}
            <div className="form-group">
              {/* tap group members */}
              <label style={{ cursor: "pointer" }}>
                {t("group_members")}{" "}
                <Tooltip title={t("tap_to_add_workers")}>
                  <img
                    style={{ cursor: "pointer" }}
                    src={AppImage.adduser}
                    onClick={() => setShowWrokerModal(true)}
                  />
                </Tooltip>
              </label>
              {/* group members list */}
              {addedGroupMembers.length != 0 ? (
                <div className="inner_box_box">
                  <Table
                    className="group-members-table"
                    dataSource={dataSource}
                    columns={columns}
                    showSorterTooltip={false}
                  />
                </div>
              ) : (
                <div
                  style={{
                    borderRadius: 20,
                    backgroundColor: "#f0f4ff",
                    boxShadow: "0px 20px 34px rgb(20 60 100 / 34%)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: "250px",
                      color: "#c3bcbc",
                      fontWeight: 400,
                      fontSize: "17px",
                      alignItems: "center",
                      justifyContent: "center",

                      flexDirection: "column",
                    }}
                  >
                    <img
                      style={{
                        height: "76px",
                        width: "95px !important",
                      }}
                      src={AppImage.emptyImg}
                    />
                    <div style={{}}>{t("added_wrkr_appear_here")}</div>
                  </div>
                </div>
              )}
            </div>

            <button type="submit" className="button-list pt-10 focus">
              {t("update")}
            </button>
            <div></div>
          </div>
        </form>

        <AddWorkerModal
          grpName={formik.values.grpName}
          show={showWorkerModal}
          hideWorkerModal={(
            addedGrpMembers,
            inviteWorkerEmails,
            selectedIds
          ) => {
            setInviteWorkerEmails(inviteWorkerEmails);
            setselectedIds(selectedIds);
            setAddedGroupMembers(addedGrpMembers);
            setShowWrokerModal(false);
          }}
          RemovedaddedGrpMembers={addedGroupMembers}
          RemovedselectedIds={selectedIds}
          groupId={location?.state?.groupId}
        />
        {/* remove modal */}
        <Modal
          title={t("remove")}
          open={isModalRemove}
          onOk={handleOkRemove}
          onCancel={handleCancelRemove}
          footer={null}
          // className="success_data"
          centered
        >
          {/* remove title */}
          <div className="text-center  model1">
            <p>{t("u_sure_to_rmv_this_usr")}</p>
          </div>
          <div
            className="text-center  model1"
            style={{
              direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
            }}
          >
            {/* yes button */}
            <button
              type="button"
              className="button-list top_new focus"
              onClick={() => {
                const payloadShouldRemove = {
                  body: JSON.stringify({
                    user_id: removalId,
                    group_id: location?.state?.groupId,
                  }),
                  success: () => {
                    const tempSelectedIds = [...selectedIds];
                    const tempAddedWorkerList = [...addedGroupMembers];
                    tempSelectedIds.splice(
                      tempSelectedIds.indexOf(removalId),
                      1
                    );

                    let indexx;
                    for (
                      let index = 0;
                      index < tempAddedWorkerList.length;
                      index++
                    ) {
                      const element = tempAddedWorkerList[index];
                      if (element.id == removalId) {
                        indexx = index;
                        break;
                      }
                    }

                    // let temp;
                    if (indexx > -1) {
                      // only splice tempAddedWorkerList when item is found
                      tempAddedWorkerList.splice(indexx, 1); // 2nd parameter means remove one item only
                    }
                    setAddedGroupMembers(tempAddedWorkerList);

                    setselectedIds(tempSelectedIds);
                    setRemoved(!removed);
                  },
                  fail: () => {
                    toast.error(t("member_cant_removed"), {
                      className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
                      position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT
                    });
                  },
                };

                isInternetConnected() &&
                  dispatch(shouldMemberBeRemoved(payloadShouldRemove));
                handleCancelRemove(false);
              }}
            >
              {t("yes")}
            </button>
            {/* no button */}
            <button
              type="button"
              className="focus"
              onClick={() => handleCancelRemove(false)}
            >
              {t("no")}
            </button>
          </div>
        </Modal>
      </CardHoc>
    </Container>
  );
};

export default EditGroup;
