import { Delete, Get, Post, PutFormField } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl; // "https://beta.WosoM.ai/api/"

const Fetch = {
  // Admin
  // owner
  createOwnerApi(payload) {
    let url = baseUrl + `owner_crud/`;

    const formData = new FormData();

    formData.append('email', payload?.email);
    formData.append('phone_no', payload?.phone_no);
    formData.append('country_code', payload?.country_code);
    formData.append('phone', payload?.phone);
    formData.append('user_type', 2);
    formData.append('first_name', payload?.first_name);
    formData.append('last_name', payload?.last_name);
    formData.append('full_name', payload?.full_name);

    if (payload?.image)
      formData.append('image', payload?.image);
    else formData.append('image', '');

    const body = formData;
    const headers = {
      Accept: "application/json",
      Authorization: "Token " + localStorage.getItem("token"),
    };

    return Post(body, url, headers);
  },
  updateOwnerApi(payload) {
    let url = baseUrl + `owner_crud/${payload?.id}`;

    const formData = new FormData();

    formData.append('email', payload?.email);
    formData.append('phone_no', payload?.phone_no);
    formData.append('phone', payload?.phone);
    formData.append('user_type', 2);
    formData.append('first_name', payload?.first_name);
    formData.append('country_code', payload?.country_code);
    formData.append('last_name', payload?.last_name);

    if (payload?.image)
      formData.append('image', payload?.image);
    // else formData.append('image','');

    const body = formData;


    return PutFormField(body, url);
  },
  deleteOwnerApi(payload) {
    let url = baseUrl + `owner_crud/${payload?.id}`;

    return Delete(url, true);
  },
  blockUnblockOwnerApi(payload) {
    let url = baseUrl + `admin/users/block/`;

    return Post(payload, url);
  },
  getOwnerDetailsApi() {
    let url = baseUrl + "user/profile/";

    return Get(url, true);
  },
};

export default Fetch;