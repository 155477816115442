import { Get, Post } from "./FetchMethods"; 
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl;

const Fetch = {

  raiseDisputeApi (payload) {
    let url = baseUrl + `RaiseDispute/` ; 
    return Post(payload,url);
  },
  disputeListsApi (payload) {
    let url = baseUrl + `DisputedAnotationList/?task_id=${payload?.taskId}` ; 
    let auth =true ;
    return Get(url,auth); 
  },  
  disputeDetailsApi (payload) {
    let url = baseUrl + `DisputedAnotationList/?id=${payload?.disputeAnnotationId}` ; 
    let auth =true ;

    return Get(url,auth);
  }, 
  accpetRejectDisputedAnnotsApi (payload) {
    let url = baseUrl + `OwnerApproveReject/` ; 
    return Post(payload,url);
  }, 
}

export default Fetch ;