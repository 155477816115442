import { Modal } from "antd";
import React, { useEffect
 } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { acceptRejectAnnotations } from "../../../../../../redux/actions/Annotations";
import { setImageLoading } from "../../../../../../redux/actions/Common";
import { getTaskDetails, saveItr } from "../../../../../../redux/actions/Tasks";
import { isInternetConnected } from "../../../../../../themes/utils/internetConnectionn";

const AcceptModal = (props) => {
  const { acceptRejectApiBody, showAccpetModal, hideModal = () => {},get_TaskDetails=()=>{} } = {
    ...props,
  };
  const { taskId } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const tempbody = { ...acceptRejectApiBody };
  const tempImageArray = useSelector(state=>state?.userTaskManagement?.itr);

  tempbody["status"] = 2;
  const tempBody =
    localStorage.getItem("workerPendingTaskId") ||
    localStorage.getItem("workerCompletedTaskId")
      ? {
          taskId,
          user_id: Number(localStorage.getItem("loggedInUserId")),
        }
      : localStorage.getItem("user_type") == 2
      ? {
          taskId,
          type: "owner",
        }
      : {
          taskId,
        };
  const payload = {
    body: tempBody, 
    fail: (resf) => {
      console.log(resf, "resf of task details.");
      resf?.data &&
        toast.error(resf?.data, {
          className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      resf?.message &&
        toast.error(resf?.message, {
          className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
    },
  }; //  task details info payload 

  const payloadAccept = {
    body: JSON.stringify(tempbody),
    success: (res) => {
      localStorage.setItem("showTickOrNot", false);
      if(localStorage.getItem('taskType')=='Image'){
          dispatch(
          saveItr(tempImageArray[localStorage.getItem("imageIndex")].src)
        ); 
        isInternetConnected("task") && dispatch(getTaskDetails(payload));
      dispatch(setImageLoading(Math.random()));
            
      }
      get_TaskDetails(true);
        toast.success(res?.data?.message, {
        className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
      hideModal(false); 
    },
    fail: (resf) => {
       toast.error(resf?.data, {
        className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
    },
  }; //  payloadAccept
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll('button[aria-label="Close"]') ;
    if(modalPopUpCrossButton && localStorage.getItem('lang')=='ar'){
     for( let i=0;i<modalPopUpCrossButton.length;i++){
       modalPopUpCrossButton[i].style.right= 'unset';
       modalPopUpCrossButton[i].style.left= '0' ;
     }
    }
    if(modalPopUpCrossButton && localStorage.getItem('lang')=='en'){
     
     for( let i=0;i<modalPopUpCrossButton.length;i++){ 
       modalPopUpCrossButton[i].style.right= '0' ;
       modalPopUpCrossButton[i].style.left= 'unset' ;
     }
    }
  }, [showAccpetModal]); 
  return (
    <Modal
      title={t("approve")}
      open={showAccpetModal}
      onOk={() => {
        hideModal(false);
        }}
      onCancel={() => {
        hideModal(false, false);
        }}
      footer={null} 
      className={ localStorage.lang == 'ar' ? 'success_data delete new_layout ar'  : "success_data delete new_layout en" } 
      centered
    >
      <div className="text-center  model1">
        <p>{t("u_sure_to_aprov_this_annot")}</p>
      </div>
      <div className="text-center  model1">
        <button
          type="button"
          className="button-list top_new focus comment-button"
          onClick={() => { 
            isInternetConnected() &&
              dispatch(acceptRejectAnnotations(payloadAccept));
          }}
        >
          {t('yes')}
        </button>
        <button
          type="button"
          className="focus"
          onClick={() => {
            hideModal(false, false);
          }}
        >
          {t('no')}
        </button>
      </div>
    </Modal>
  );
};

export default AcceptModal;
