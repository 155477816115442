import {
  AppImage,
  SidebarMenus,
  NavigateTo,
} from "../../../themes/index";
import { Tooltip } from "antd";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Container } from "../../../hoc";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { customFunctions } from "../../../themes/utils/CustomFunctions";
import { getOwnerDetails, updateOwner } from "../../../redux/actions/Owner";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { constants } from "../../../common/fileConstants/Admin/OwnerManagement/index";


const EditProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userId = localStorage.getItem("loggedInUserId");
  const ownerDetails = useSelector(
    (state) => state?.ownerManagement?.ownerDetails
  );

  const [background, setBackground] = useState();
  const [uploadedImage, setImage] = useState(null);
  const [lang, setLang] = useState(localStorage.getItem("lang"));

  const initialValues = {
    ownerFirstName: "",
    ownerLastName: "",
    contactNum: "",
    email: "",
    countryCode: "",
  };
  const onSubmit = (values) => {
    localStorage.setItem(
      "fullName",
      values?.ownerFirstName + " " + values?.ownerLastName
    );

    const payloadCreateOwner = {
      email: values?.email,
      phone_no: values?.contactNum,
      phone: values.countryCode + values?.contactNum,
      first_name: values?.ownerFirstName,
      last_name: values?.ownerLastName,
      image: uploadedImage,
      id: userId,
      country_code: values.countryCode,
    };
    isInternetConnected() &&
      dispatch(updateOwner(payloadCreateOwner, navigate));
  };

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: constants.formikValidationSchema(localStorage.lang),
  });
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      setImage(img);
      const fileType = img.type.split("/")[0];
      const fileSize = img.size; //size is in bytes

      /*
       check here if FileType is other than IMAGE then show alert and 
       if FileSize is greater than 2MB then show alert.
      */
      fileType == "image"
        ? customFunctions.FileSizeConverter(fileSize, "MB") > 1.9
          ? toast.error(`${t("size_less_than_2Mb")}`, {
            className:
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
            position:
              localStorage.lang == "ar"
                ? toast.POSITION.TOP_LEFT
                : toast.POSITION.TOP_RIGHT,
            toastId: "wrongSize",
          })
          : setBackground(URL.createObjectURL(img))
        : toast.error(t("upload_file_types"), {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
          toastId: "wrongFile",
        });
    }
  };
  const handleOnChange = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("contactNum", e.target.value);
      }
    } else {
      formik.setFieldValue("contactNum", "");
    }
  };
  const handleOnChangeCountryCode = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}+\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("countryCode", temp);
      }
    } else {
      formik.setFieldValue("countryCode", "");
    }
  };
  const ProfileImage = () => {
    return (
      <div
        style={{
          width: "100%",
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          marginTop: "40px ",
        }}
      >
        <Tooltip
          title={
            uploadedImage == null
              ? t("upload_your_photo")
              : t("change_your_photo")
          }
          key={"#143C64"}
          placement="right"
        >
          <input
            id={"register-upload"}
            accept={".png,.jpg,.jpeg,.svg"}
            style={{
              display: "none",
            }}
            type="file"
            name="uploadImage"
            onChange={(event) => {
              handleImageChange(event);
            }}
            onClick={(event) => {
              event.target.value = null; // use this to validate image upon uploading wrong image many times
            }}
            autoComplete="off"
          />
          <label
            htmlFor="register-upload"
            style={{
              position: "relative",
            }}
          >
            <img
              style={{
                width: "101px",
                height: "101px",
                marginTop: "-35px",
                cursor: "pointer",
                borderRadius: "101px",
              }}
              id="base-image"
              src={background}
            />
            <div
              onClick={() => { }}
              style={{
                position: "absolute",
                right: 0,
                bottom: "-4px",
                backgroundColor: "white",
                borderRadius: 30,
                cursor: "pointer",
              }}
            >
              <img src={AppImage.plusIcon} width={30} height={30} />
            </div>
          </label>
          {/* </div> */}
        </Tooltip>
      </div>
    );
  };
  const breadCrumbItems = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.PROFILE,
      label: t("profile"),
    },
    {
      link: "",
      label: t("edit_prfl"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t("profile"),
  };

  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "WosoM";
    const payloadGetOwnerDetails = {
      id: userId,
    };
    isInternetConnected() && dispatch(getOwnerDetails(payloadGetOwnerDetails));
  }, []);
  useEffect(() => {
    localStorage.setItem(
      "fullName",
      ownerDetails?.first_name + " " + ownerDetails?.last_name
    );

    formik.setValues({
      ownerFirstName: ownerDetails?.first_name, // "Michael",
      ownerLastName: ownerDetails?.last_name, // "Smith",
      contactNum: ownerDetails?.phone, // "+91 5463789320",
      email: ownerDetails?.email,
      countryCode: ownerDetails?.country_code,
    });
    setBackground(ownerDetails?.image);
  }, [ownerDetails]);
  useEffect(() => {
    Object.keys(formik.errors).forEach((fieldName) => {
      formik.setFieldTouched(fieldName);
    });
  }, [lang])

  return (
    <Container
      {...containerProps}
      languageChanged={() => {
        setLang(localStorage.getItem("lang"));
        // it is reuqired to re render this whole component
      }}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <div className="content-e">
            {/* edit profile title */}
            <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <h6 className="text-white text-capitalize ps-3">
                {t("edit_prfl")}
              </h6>
            </div>
            <div className="wrapper_line">
              {/* profile image */}
              <ProfileImage />

              {/* first name */}
              <div className="form-group">
                <label>{t("first_name")}</label>
                <input
                  type="text"
                  id="ownerFirstName"
                  value={
                    (formik.values.ownerFirstName &&
                      formik.values.ownerFirstName.trimLeft())
                  }
                  onChange={formik.handleChange}
                  className="form-control"
                  placeholder={t("first_name")}
                  autoComplete="off"
                  maxLength={20}
                />
                {formik.errors.ownerFirstName ? (
                  <div
                    className={
                      localStorage.getItem("lang") == "ar"
                        ? "error ar"
                        : "error en"
                    }
                  >
                    {formik.errors.ownerFirstName}
                  </div>
                ) : null}
              </div>
              {/* last name */}
              <div className="form-group">
                <label>{t("last_name")}</label>
                <input
                  id="ownerLastName"
                  value={
                    (formik.values.ownerLastName &&
                      formik.values.ownerLastName.trimLeft())
                  }
                  onChange={formik.handleChange}
                  type="text"
                  className="form-control"
                  placeholder={t("last_name")}
                  autoComplete="off"
                  maxLength={20}
                />
                {formik.errors.ownerLastName ? (
                  <div
                    className={
                      localStorage.getItem("lang") == "ar"
                        ? "error ar"
                        : "error en"
                    }
                  >
                    {formik.errors.ownerLastName}
                  </div>
                ) : null}
              </div>
              {/* number */}
              <div
                className="wrapper_line"
                style={{ marginBottom: "-23px", marginLeft: "-15px" }}
              >
                <label>{t("contact_num")}</label>
              </div>

              <div className="verify" style={{ marginBottom: "16px" }}>
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    marginBottom: "0px",
                    width: "100%",
                  }}
                >
                  <input
                    id="countryCode"
                    type="text"
                    className="form-control"
                    placeholder={"+"}
                    name="countryCode"
                    maxLength={5}
                    style={{
                      width: "91px",
                      borderEndEndRadius: "9px",
                      borderStartEndRadius: "9px",
                      marginLeft: localStorage.lang == "ar" ? "10px" : "unset",
                      marginRight: localStorage.lang == "en" ? "12px" : "unset",
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "." ||
                        e.key === "{" ||
                        e.key === "}" ||
                        e.key === "*"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    autoComplete="off"
                    value={
                      formik.values.countryCode || ownerDetails?.country_code
                    }
                    onChange={(e) => handleOnChangeCountryCode(formik, e)}
                  />
                  <input
                    id="contactNum"
                    style={{
                      width: "90%",
                      marginLeft: "16px",
                      borderStartStartRadius: "9px",
                      borderEndStartRadius: "9px",

                      marginLeft: localStorage.lang == "ar" ? "unset" : "unset",
                      width: localStorage.lang == "ar" ? "88.5%%" : "90%",
                    }}
                    type="text"
                    className="form-control"
                    placeholder={t("contact_num")}
                    name="contactNum"
                    maxLength={15}
                    onKeyDown={(e) => {
                      if (
                        e.key === "." ||
                        e.key === "{" ||
                        e.key === "}" ||
                        e.key === "*"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    autoComplete="off"
                    value={formik.values.contactNum}
                    onChange={(e) => handleOnChange(formik, e)}
                  />
                </div>
              </div>
              {/* error */}
              <div>
                {formik.errors.countryCode ? (
                  <div>
                    {formik.errors.countryCode ? (
                      <div
                        className="error"
                        style={{
                          display: localStorage.lang == "ar" ? "flex" : "unset",
                          marginRight:
                            localStorage.lang == "ar" ? "10px" : "unset",
                        }}
                      >
                        {formik.errors.countryCode}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    {formik.errors.contactNum ? (
                      <div
                        className="error"
                        style={{
                          display: localStorage.lang == "ar" ? "flex" : "unset",
                          marginRight:
                            localStorage.lang == "ar" ? "10px" : "unset",
                        }}
                      >
                        {formik.errors.contactNum}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
              {/* number */}

              {/* email  */}
              <div className="form-group">
                <label>{t("email_address")}</label>
                <div className="verify">
                  <input
                    id="email"
                    value={
                      (formik.values.email && formik.values.email.trimLeft())
                    }
                    onChange={formik.handleChange}
                    type="text"
                    className="form-control"
                    placeholder={t("email_address")}
                    autoComplete="off"
                    maxLength={320}
                  />
                </div>
                {
                  <div
                    style={{
                      color: "grey",
                      fontSize: "13px",
                      marginTop: "8px",
                      marginLeft: " 7px",
                    }}
                  >
                    {
                      t('verify_email_addrs')
                    }
                  </div>
                }
                {formik.errors.email ? (
                  <div
                    className={
                      localStorage.getItem("lang") == "ar"
                        ? "error ar"
                        : "error en"
                    }
                  >
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>

              {/* update button */}
              <div className="text_button">
                <button
                  type="submit"
                  className="button-list bottom_button pt-10 focus"
                >
                  {t("update")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Container>
  );
};
export default EditProfile;
