import {
  AppImage,
  Constants,
  NavigateTo,
  SidebarMenus,
  fieldIds,
} from "../../../themes";
import { useFormik } from "formik";
import { Tooltip, Rate, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CardHoc, Container } from "../../../hoc";
import React, { useEffect, useState } from "react";
import { textAreaSize } from "../../../themes/appconstant";
import {
  udateUserProfile,
} from "../../../redux/actions/Profile";
import { CustomModal, CustomPageHeader, TextField } from "../../../molecules";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn/index";
import { customFunctions } from "../../../themes/utils/CustomFunctions";
import { toast } from "react-toastify";
import AddSkillModal from "./addSkillModal";
import { useTranslation } from "react-i18next";
import { getSkillCategories } from "../../../redux/actions/Skills";
import {
  getLanguageList,
  getCountry,
} from "../../../redux/actions/Common";
import { getUserDetails } from "../../../redux/actions/User";
import { constants } from "../../../common/fileConstants/User/profile";


const UserProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.common.profileLoad);

  const skillSection = [
    {
      label: t("category"),
      value: "Category 1",
    },
    {
      label: t("name"),
      value: "Name 1",
    },
    {
      label: t("description"),
      value: "Lorem ipsum is a text.",
    },
    {
      label: t("experience"),
      value: "1 year",
    },
    {
      label: t("add_info"),
      value: "Dummy",
    },
  ];


  const [genderVal, setGender] = useState(null);
  const [visible, setVisible] = useState(false);
  const [availVal, setValAvail] = useState(null);

  const [background, setBackground] = useState();
  const [uploadedImage, setImage] = useState(null);

  let addTaskModalDataToShow = {}; // this will have CommonCategory as keys.


  const [countryOptions, setCountryOptions] = useState(null);

  const [languageChildren, setLanguageChildren] = useState([]);
  const [citizenshipOptions, setCitizenshipOptions] = useState([]);
  const [deviceOptions, _setDeviceOptions] = useState([
    {
      name: t("mobile"),
      value: "mobile",
    },
    {
      name: t("laptop"),
      value: "laptop",
    },
    {
      name: t("tablet"),
      value: "tablet",
    },
    {
      name: t("desktop"),
      value: "desktop",
    },
  ]);



  // const addSkill={};
  const [skillOptions, setSkillOptions] = useState([]);
  const skillNameOptions = []

  const [skills, setSkills] = useState([]);
  // Language DropDown

  // Language DropDown

  const selectPlaceholders = [
    t('select_smthng', { something: t('category') }),
    t('select_smthng', { something: t('name') }),
    t('select_smthng', { something: t('experience') }),
  ];
  const initialValues = {
    firstName: "",
    lastName: "",

    email: "",
    availability: "",
    address: "",
    languages: languageChildren,

    gender: "",

    education: "",
    deviceAvailable: [],

    skills: [],
    rate: "",

    countryCode: "+",
    otp: "",
    citizenship: citizenshipOptions,
    country: "",
  };
  const onSubmit = (values) => {

    const payloadUpdate =
    {
      first_name: values?.firstName,
      last_name: values?.lastName,
      languages: values?.languages?.length > 0 ? values?.languages?.map(item => {
        return { code: item.key }
      }) : [],
      country: values?.country?.length > 0 ? {
        code: values?.country[0]?.key
      } : null,
      citizenships: values?.citizenship?.length > 0 ? values?.citizenship?.map(item => {
        return { code: item.key }
      }) : [],
      education_level: values?.education?.length > 0 ? values?.education[0]?.key : null,
      address: values?.address,
      availability: availVal,
      gender: genderVal ? genderVal : null,
      skills: skills?.length > 0 ? skills : [],
      devices: values?.deviceAvailable?.length > 0 ? values?.deviceAvailable : null,
    }
    const payloadImage = {
      image: uploadedImage,
    };

    isInternetConnected() && dispatch(udateUserProfile(payloadUpdate));
    uploadedImage &&
      isInternetConnected() &&
      dispatch(udateUserProfile(payloadImage));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: constants.formikValidationSchemaPF(),
    onSubmit,
  });

  /* Container props */

  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.USER_PROFILE,
      label: t("profile"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("user"),
    active: t("profile"),
  };
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      setImage(img);
      const fileType = img.type.split("/")[0];
      const fileSize = img.size; //size is in bytes

      fileType == "image"
        ? customFunctions.FileSizeConverter(fileSize, "MB") > 1.9
          ? toast.error(t("size_less_than_2Mb"), {
            className:
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
            position:
              localStorage.lang == "ar"
                ? toast.POSITION.TOP_LEFT
                : toast.POSITION.TOP_RIGHT,
            toastId: "wrongSize",
          })
          : setBackground(URL.createObjectURL(img))
        : toast.error(t("upload_file_types"), {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
          toastId: "wrongFile",
        });
    }
  };
  /* Container props */
  const ProfileImage = () => {
    return (
      <div
        style={{
          width: "100%",
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Tooltip
            title={
              uploadedImage == null
                ? t('upload_your_photo')
                : t('change_your_photo')
            }
            key={"#143C64"}
            placement="right"
          >
            <input
              id={"register-upload"}
              accept={".png,.jpg,.jpeg,.svg"}
              style={{
                display: "none",
              }}
              type="file"
              name="uploadImage"
              onChange={(event) => {
                handleImageChange(event);
              }}
              autoComplete="off"
            />
            <label
              htmlFor="register-upload"
              style={{
                position: "relative",
              }}
            >
              <img
                style={{
                  width: "101px",
                  height: "101px",
                  marginTop: "-35px",
                  cursor: "pointer",
                  borderRadius: "101px",
                }}
                id="base-image"
                src={background}
              />
              <div
                onClick={() => { }}
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: "-20px",
                  backgroundColor: "white",
                  borderRadius: 30,
                  cursor: "pointer",
                }}
              >
                <img src={AppImage.plusIcon} width={30} height={30} />

              </div>
            </label>
          </Tooltip>
          <Tooltip
            title={t("rating") + " " + formik.values.rate}
            key={"#143d4"}
            placement="right"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Rate
                style={{
                  fontSize: "20px",
                  marginTop: "9px",
                }}
                className={
                  localStorage.lang == "ar"
                    ? "edit-group-star user-profile ar"
                    : "edit-group-star en"
                }
                allowHalf
                value={formik.values.rate}
                disabled={true}
              />
            </div>
          </Tooltip>
          {/* </div> */}
        </div>

      </div>
    );
  };

  /* personal details */
  const increaseDecrease = (
    <div className="form-group">
      <input
        id={"id"}
        value={availVal}
        onChange={(e) => e.preventDefault}
        className="id-control form-control"
        readOnly={true} // it prevents user to write any value
        autoComplete="off"
      />
    </div>
  );

  const personalFormFields = [
    {
      type: "text",
      error: formik.errors.firstName,
      inputTrue: true,
      id: fieldIds.FIRSTNAME,
      value:
        formik.values.firstName &&
        customFunctions.capitalizeFirstLetter(
          formik.values.firstName.trimLeft()
        ), // configure later
      placeholder: t("first_name"),
      fieldLabel: t("first_name"),
    },
    {
      type: "text",
      error: formik.errors.lastName,
      // labelIcon: AppImage.plus,
      inputTrue: true,
      id: fieldIds.LASTNAME,
      value:
        formik.values.lastName &&
        customFunctions.capitalizeFirstLetter(
          formik.values.lastName.trimLeft()
        ), // configure later
      placeholder: t("last_name"),
      fieldLabel: t("last_name"),
      // isDisabled: lDisable,
      inputMaxLength: 20,
      // onLabelIconTap: () => {
      //   document.getElementById(fieldIds.LASTNAME).focus();

      //   setLDisable(false);
      // },
    },
    {
      type: "text",
      error: formik.errors.email,
      // labelIcon: AppImage.plus,
      inputTrue: true,
      id: fieldIds.EMAIL,
      value: formik.values.email,
      placeholder: t("email_address"),
      fieldLabel: t("email_address"),
      isDisabled: true,
      inputMaxLength: 320,
      // onLabelIconTap: () => {
      //   document.getElementById(fieldIds.EMAIL).focus();

      //   setEDisable(false);
      // },
    },
  ];
  const personalFormFields2 = [
    {
      error: formik.errors.address,
      type: "text",
      // labelIcon: AppImage.plus,
      inputTrue: true,
      id: fieldIds.ADDRESS,
      fieldLabel: t("address"),
      value:
        formik.values.address &&
        customFunctions.capitalizeFirstLetter(formik.values.address.trimLeft()),
      placeholder: t("address"),
      inputMaxLength: 500,
    },
    {
      type: "text",
      fieldLabel: t("gender"),
      selectTrue: true,
      // labelIcon: AppImage.plus,
      id: fieldIds.GENDER,
      value: formik.values.gender[0]?.key || null,
      error: formik.errors.gender,
      placeholder: t("select_gender"),
      selectOptions: [
        { name: t("male"), value: "male" },
        { name: t("female"), value: "female" },
        { name: t("other"), value: "other" },
      ],
      // isDisabled: gDisable,
      // onLabelIconTap: () => setGDisable(false),
      selectOnChange: (val, options) => {
        setGender(val);
        formik.setValues({
          ...formik.values,
          gender: [{ key: val, val: options.children }],
        });
      },
    },
  ];
  const personalFormFields3 = [{
    id: "country",
    selectTrue: true,
    showSearch: true,
    placeholder: t("select_country"),
    value: formik.values.country[0]?.key || null,
    error: formik.errors.country,
    fieldLabel: t("country"),
    selectOptions: countryOptions,
    selectOnChange: (val, option) => {
      formik.setValues({
        ...formik.values,
        country: [{ key: val, val: option.children }],
      });
    },
  },

  {
    id: "citizenship",
    selectTrue: true,
    selectMode: "multiple",
    placeholder: t("select_citizenship"),
    value: formik.values.citizenship || [],
    error: formik.errors.citizenship,
    fieldLabel: t("citizenship"),
    selectOptions: citizenshipOptions,
    selectOnChange: (val) => {
      formik.setValues({
        ...formik.values,
        citizenship: val,
      });
    },
  },
  {
    id: "languages",
    selectTrue: true,
    selectMode: "multiple",
    // labelIcon: AppImage.plus,
    selectOptions: languageChildren,
    placeholder: t("select_languages"),
    value: formik.values.languages || [],
    error: formik.errors.languages,
    fieldLabel: t("languages"),
    selectOnChange: (val) => {
      formik.setValues({
        ...formik.values,
        languages: val,
      });
    },
  },
  ];
  const educationFormFields = [
    {
      selectTrue: true,
      id: fieldIds.EDUCATION_LEVEL,
      selectOptions: [
        {
          name: t("primary"),
          value: "primary",
        },
        {
          name: t("secondary"),
          value: "secondary",
        },
        {
          name: t("bach_deg"),
          value: "bachelor",
        },
        {
          name: t("mastr_deg"),
          value: "master",
        },
        {
          name: t("phd_deg"),
          value: "phd",
        },
      ],
      value:
        formik.values.education && formik.values.education[0]?.key || null,
      error: formik.errors.education,
      placeholder: t("slct_edu_lvl"),
      fieldLabel: t("education_level"),
      selectOnChange: (val, option) => {
        formik.setValues({
          ...formik.values,
          education: [{
            key: val,
            val: option.children,
          }]
        });
      },
    },
    {
      id: fieldIds.DEVICE_AVAILABLE,
      selectTrue: true,
      selectMode: "multiple",
      placeholder: t("slct_devices"),
      value: formik.values.deviceAvailable || [],
      error: formik.errors.deviceAvailable,
      fieldLabel: t("dev_avail"),
      selectOptions: deviceOptions,
      selectOnChange: (val) => {
        formik.setValues({
          ...formik.values,
          deviceAvailable: val,
        });
      },
    },
    {
      error: formik.errors.availability,
      fieldIcons: [AppImage.upArrow, AppImage.downArrow],
      child: increaseDecrease,
      id: fieldIds.AVAILABILITY,
      fieldLabel: t("availability") + ' ' + `(${t('hours')})`,
      value: formik.values.availability,
      placeholder: t("availability"), // 30 hours
      setValAvail: setValAvail,
    },
  ];

  const selectCustomStyle = {
    cursor: "pointer",
    caretColor: "transparent",
  };
  const AddTaskModalFormFields = [
    {
      type: "text",
      error: formik.errors.skillCategory,
      selectTrue: true,
      id: "skillCategory",
      value: formik.values.skillCategory,
      selectOptions: skillOptions,
      customInputStyle: selectCustomStyle,
      placeholder: t("select_cate"),
      fieldLabel: t("category"),
      selectOnChange: (val) => {
        formik.setValues({
          ...formik.values,
          skillCategory: val,
        });
      },
    },
    {
      type: "text",
      error: formik.errors.skillName,
      selectTrue: true,
      id: "skillName",
      selectOptions: skillNameOptions,
      value: formik.values.skillName, // configure later
      customInputStyle: selectCustomStyle,
      placeholder: t("select_smthng", { thing: t("name") }),
      fieldLabel: t("name"),
      selectOnChange: (val) => {
        formik.setValues({
          ...formik.values,
          skillName: val,
        });
      },
    },
    {
      type: "text",
      textAreaTrue: true,
      isAutoComplete: false,
      tar: textAreaSize.rows,
      id: "skillDescription",
      tac: textAreaSize.columns,
      value: formik.values.skillDescription, // configure later
      onChange: () => formik.handleChange,
      fieldLabel: t("description"), //  t('select_smthng',{thing:'Name'}),
      placeholder: t("enter_desc"),
      error: formik.errors.skillDescription,
    },
    {
      type: "text",
      error: formik.errors.skillExperience,
      selectTrue: true,
      id: "skillExperience",
      selectOptions: skillOptions,
      value: formik.values.skillExperience, // configure later
      customInputStyle: selectCustomStyle,
      placeholder: t("select_smthng", { thing: t("experience") }),
      fieldLabel: t("experience"),
      dropdownPlaceholder: Constants.Skill_Category,
      selectOnChange: (val) => {
        formik.setValues({
          ...formik.values,
          skillExperience: val,
        });
      },
    },
    {
      type: "text",
      textAreaTrue: true,
      isAutoComplete: false,
      tar: textAreaSize.rows,
      id: "skillAddInfo",
      tac: textAreaSize.columns,
      value: formik.values.skillAddInfo, // configure later
      onChange: () => formik.handleChange,
      fieldLabel: t("add_info"),
      placeholder: t("enter_add_info"),
      error: formik.errors.skillAddInfo,
    },
  ];
  const AddTaskModalProps = {
    title: t("add_skills"),

    formFields: AddTaskModalFormFields,
    addSkillOnSubmit: () => {
      formik.setValues({
        ...formik.values,
        skills: "something",
      });
    },
    modalWidth: 923,
    submitTitle: t("add"),
  };
  const skillsCard = skillSection.length ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        paddingLeft: "9px",
        paddingTop: "14px",
      }}
    >
      {skills != null &&
        skills.map(
          (item, index) => {
            return (
              <div
                key={index}
                className="skillCard"
                style={{
                  width: "220px",
                  position: "relative",
                  marginRight: "15px",
                  marginBottom: "20px",
                }}
              >
                <div
                  key={index}
                  style={{
                    padding: 10,
                  }}
                >
                  <div className="skillHeading">
                    <b>{t("name")}</b>: {item.name}
                  </div>

                  {index != skillSection.length - 1 && (
                    <div
                      style={{
                        border: "1px solid #8BBAE8",
                      }}
                    />
                  )}
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      whiteSpace: "break-spaces",
                    }}
                    className="skillSubHeading"
                  >
                    <b>{t("category")}</b>: {skillOptions.map((item2) => {
                      if (item2.value == item.category) {
                        return item2.name;
                      }
                    })}
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      whiteSpace: "break-spaces",
                    }}
                    className="skillSubHeading"
                  >
                    <b>{t("description")}</b>: {item.description}
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      whiteSpace: "break-spaces",
                    }}
                    className="skillSubHeading"
                  >
                    <b>{t("experience")}</b>: {item.experience}
                  </div>
                </div>

                <div
                  onClick={() => {
                    //remove skill
                    var tempSkills = skills;
                    skills.splice(index, 1);
                    setSkills([...tempSkills]);
                  }}
                  style={{
                    position: "absolute",
                    top: "-14px",
                    cursor: "pointer",
                    right: localStorage.lang == "ar" ? "unset" : "-13px",
                    left: localStorage.lang == "ar" ? "-12px" : "unset",
                  }}
                >
                  <img src={AppImage.crossButton} width={40} height={40} />
                </div>
              </div>
            );
          }
        )}
    </div>
  ) : null;
  const AddSkillModalProps = {
    title: t('add_skills'),
    handleOk: (data) => {
      setVisible(false);
      setSkills([...skills, data]);
    },
    modalWidth: 550,
    handleCancel: () => {
      setVisible(false);
    },
    submitTitle: t('add'),
    isModalVisible: visible,
  };

  useEffect(() => {
    document.title = "WosoM";

    const payloadForSkills = {
      success: (res) => {
        var modifiedResult = [];
        res.filter((item) => {
          modifiedResult.push({ name: t(item.name), value: item.id });
        });
        setSkillOptions(modifiedResult);
      },
      fail: (err) => {
        toast(err);
      },
    };
    isInternetConnected() &&
      dispatch(getSkillCategories(payloadForSkills));

    const payloadForLanguages = {

      success: (res) => {
        var modifiedResult = [];
        res.filter((item) => {
          modifiedResult.push({ name: `${item.name} (${item.native})`, value: item.code });
        });
        setLanguageChildren(modifiedResult);
      },
      fail: (err) => {
        toast(err);
      },
    };
    isInternetConnected("prf") &&
      dispatch(getLanguageList(payloadForLanguages));

    const payloadForCountry = {
      success: (res) => {
        var modifiedResultCountry = [];
        res.filter((item) => {
          modifiedResultCountry.push({ name: `${item.name} (${item.native})`, value: item.code, phone_code: item.phone_code });
        });
        setCountryOptions(modifiedResultCountry);
        setCitizenshipOptions(modifiedResultCountry);
      },
      fail: (err) => {
        toast(err);
      },
    };
    isInternetConnected() && dispatch(getCountry(payloadForCountry)); // country

    const payloadGetUserDetails = {
      success: (res) => {
        setValAvail(res?.availability || 20);

        let tempSkills = [];
        res?.skills.map((item) => {
          tempSkills.push({
            category: item?.category,
            description: item?.description,
            experience: item?.experience,
            information: item?.info,
            name: item?.name,
          });
        });
        setSkills(tempSkills);
        setGender(res?.gender);
        formik.setValues({
          firstName: res?.first_name, //userDetails?.name.split(" ")[0],
          lastName: res?.last_name, //userDetails?.name.split(" ")[1],
          email: res?.email,
          availability: res?.availability || 20,
          address: res?.address || "",
          languages: res?.languages.map(item => {
            return { key: item?.code, val: item?.code }
          }) || null, //languageChildren,
          gender: [{ key: res?.gender, val: res?.gender }] || null, //userDetails?.gender,
          rate: res?.rate,
          education: res?.education_level ? [{ key: res?.education_level, val: res?.education_level }] : null,
          country:
            [{ key: res?.country?.code, val: res?.country?.code }] || null,
          citizenship: res?.citizenships?.map(item => {
            return { key: item?.code, val: item?.code }
          }) || null,
          deviceAvailable: res?.devices || [],
          skills: res?.skills,
          countryCode: res?.country_code || "+91",
          otp: res?.phone_number || "83838389811",
        });

        setBackground(res?.image ? res?.image : AppImage.defaultImage);
      },
    };
    isInternetConnected() &&
      dispatch(getUserDetails(payloadGetUserDetails));
  }, []); //addTaskModalDataToShow


  const handleOnChange = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("otp", e.target.value);
      }
    } else {
      formik.setFieldValue("otp", "");
    }
  };
  const handleOnChangeCountryCode = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}+\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("countryCode", temp);
      }
    } else {
      formik.setFieldValue("countryCode", "");
    }
  };
  const formFeild = (item, index) => {
    return (
      <div
        key={index}
        className="wrapper_line form_wrapper"
        style={{ padding: "0px 15px 5px" }}
      >
        <TextField
          {...item}
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              [e.target.id]: e.target.value.trimLeft(),
            });
          }}
          labelTitle={item.fieldLabel}
          errorClassName={
            localStorage.getItem("lang") == "ar"
              ? "error ar"
              : "error en"
          }
        />
      </div>
    );
  }
  return (
    <Container
      {...containerProps}
    >
      <CardHoc cardInnerPadding={"content-e "}>
        {
          <>
            <CustomPageHeader
              label={t("profile")}
              wrapperClassName={
                "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
              }
              customWrapperStyle={{
                marginBottom: "20px",
              }}
            />

            <ProfileImage />

            <form
              className="user-profile "

              noValidate
              onSubmit={formik.handleSubmit}
            >
              <Row>
                <Col span={12}>
                  {personalFormFields.map((items, index) => {
                    return formFeild(items, index);
                  })}
                </Col>
                <Col span={12}>

                  {personalFormFields2.map((items, index) => {
                    return formFeild(items, index);
                  })}
                  {/* t("contact_num") */}
                  <div
                    className="wrapper_line form_wrapper"
                    style={{ padding: "0px 15px 5px" }}
                  >
                    <div className="form-group">
                      {/* TODO: disable this but enable with verify */}
                      <label
                        style={{
                          fontWeight: "500",
                          color: "#143c64",
                          fontSize: "15px",
                          display: "block",
                        }}
                      >
                        {t("contact_num")}
                      </label>
                      <div
                        className="form-group"
                        style={{
                          display: "flex",
                        }}
                      >
                        <input
                          id="countryCode"
                          type="text"
                          className="form-control"
                          placeholder={"+"}
                          name="countryCode"
                          maxLength={5}
                          style={{
                            width: "91px",
                            borderEndEndRadius: "9px",
                            borderStartEndRadius: "9px",
                            marginLeft:
                              localStorage.lang == "ar" ? "10px" : "unset",
                            marginRight:
                              localStorage.lang == "en" ? "12px" : "unset",
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === "." ||
                              e.key === "{" ||
                              e.key === "}" ||
                              e.key === "*"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          value={formik.values.countryCode}
                          onChange={(e) => handleOnChangeCountryCode(formik, e)}
                        />
                        <input
                          id="otp"
                          style={{
                            width: "84%",
                            marginLeft: "16px",
                            borderStartStartRadius: "9px",
                            borderEndStartRadius: "9px",
                            marginLeft:
                              localStorage.lang == "ar" ? "unset" : "unset",
                            width: localStorage.lang == "ar" ? "88.5%%" : "90%",
                          }}
                          type="text"
                          className="form-control"
                          placeholder={t('contact_num')}
                          name="otp"
                          maxLength={15}
                          onKeyDown={(e) => {
                            if (
                              e.key === "." ||
                              e.key === "{" ||
                              e.key === "}" ||
                              e.key === "*"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          value={formik.values.otp}
                          onChange={(e) => handleOnChange(formik, e)}
                        />
                      </div>
                      <div>
                        {formik.errors.countryCode ? (
                          <div>
                            {formik.errors.countryCode ? (
                              <div
                                className="error"
                                style={{
                                  display:
                                    localStorage.lang == "ar" ? "flex" : "unset",
                                  marginRight:
                                    localStorage.lang == "ar" ? "10px" : "unset",
                                }}
                              >
                                {formik.errors.countryCode}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div>
                            {formik.errors.otp ? (
                              <div
                                className="error"
                                style={{
                                  display:
                                    localStorage.lang == "ar" ? "flex" : "unset",
                                  marginRight:
                                    localStorage.lang == "ar" ? "10px" : "unset",
                                }}
                              >
                                {formik.errors.otp}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {personalFormFields3.map((items, index) => {
                    return formFeild(items, index);
                  })}
                </Col>
                <Col span={12}>
                  {/* Education section */}
                  {educationFormFields.map((items, index) => {
                    return formFeild(items, index);
                  })}
                </Col>
              </Row>

              {/* skills */}
              <div className="wrapper_line ">
                <label>
                  {t('skills')}
                  <Tooltip title={t('clck_to_add_skills')}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setVisible(true);
                      }}
                    >
                      <img src={AppImage.plus} className="labelIcon" />
                    </span>
                  </Tooltip>
                </label>
                <div
                  className="form-group "
                  style={{
                    position: "relative",
                    overflowX:
                      skillsCard != null && skillsCard.props.children.length > 5
                        ? "scroll"
                        : "unset",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "150%",
                      paddingLeft: "9px",
                    }}
                  ></div>
                  {skillsCard}
                </div>
                {formik.errors.skills ? (
                  <div className={"error"}>{formik.errors.skills}</div>
                ) : null}
              </div>
              <CustomModal
                formik={formik}
                {...AddSkillModalProps}
                dataToShow={skillOptions}
                selectPlaceholders={selectPlaceholders}
              />
              <AddSkillModal
                {...AddTaskModalProps}
                show={false} //showSkillModal
                dataToShow={addTaskModalDataToShow}
                hideSkillModal={() => {
                  setShowSkillModal(false);
                  formik.setValues({
                    ...formik.values,
                    skills: "something",
                  });
                }}
              />
              {formik.errors.skills ? (
                <div
                  className={
                    localStorage.getItem("lang") == "ar"
                      ? "error ar"
                      : "error en"
                  }
                >
                  {formik.errors.skills}
                </div>
              ) : null}
              {/* submit buttons */}
              <div className="subheader" style={{ padding: "0px 15px 39px" }}>
                <Link to={NavigateTo.USER_CHANGE_PASSWORD}>
                  <button
                    type="button"
                    className="button-list bottom_button pt-10 change_button_pu focus"
                    style={{ width: "300px!important" }}
                  >
                    {t("change_password")}
                  </button>
                </Link>

                <button
                  type="submit"
                  disabled={isLoading}
                  className="button-list bottom_button pt-10 focus"
                  style={{ marginLeft: "20px" }}
                >
                  {t("save")}
                </button>
              </div>
            </form>
          </>
        }
      </CardHoc>
    </Container>
  );
};

export default UserProfile;
