import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function StoragePie({ sizes }) {
    let chart;
    const { t } = useTranslation();
    const colors = [
        am4core.color("#123a65"),
        am4core.color("#184f8b"),
        am4core.color("#2a80ff")
    ];
    useEffect(() => {
        chart = am4core.create("chartdiv2", am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.legend = new am4charts.Legend();

        chart.data = Object.keys(sizes).map((key) => {
            return {
                type: t(key),
                // two decimal places
                size: sizes[key].toFixed(2)
            };
        });

        var series = chart.series.push(new am4charts.PieSeries3D());
        series.dataFields.value = "size";
        series.dataFields.category = "type";
        //tooltip
        series.slices.template.tooltipText = "{category}: {value.value} MB";
        series.colors.list = colors;

    }, [sizes]);
    return (
        <div id="chartdiv2" style={{ width: "100%", height: "500px" }}></div>
    );
}

StoragePie.propTypes = {
    sizes: PropTypes.array
};

export default StoragePie;