import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../../../hoc";
import {
  CustomEmptyDiv,
  CustomPageHeader,
  CustomTable,
  TextField,
} from "../../../../../molecules";
import {
  // AppImage,
  NavigateTo,
  SidebarMenus,
} from "../../../../../themes";
import { Collapse, Table, Modal, Tooltip } from "antd";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { isInternetConnected } from "../../../../../themes/utils/internetConnectionn";
import { useDispatch } from "react-redux";
import { getTaskDetails } from "../../../../../redux/actions/Tasks";
import appconstant from "../../../../../themes/appconstant";
import parse from "html-react-parser";
const { Panel } = Collapse;

const AcceptRejectAnnot = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const taskId = localStorage.getItem("workerPendingTaskId");

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [classNames, setClassNames] = useState([]);
  const [classLists, setClassLists] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  // const [audio, setAudio] = useState(null);

  // const [questions, setQuestions] = useState(null);
  // const [segmentation, setSegmentation] = useState(null);
  // const [pdfUrl, setPdfUrl] = useState();

  const [annotDetails, setAnnotDetails] = useState([]);
  const allPreviouslyDoneAnnotations = [];
  const [annotationDS, setAnnotationDS] = useState([]); //  annotation data
  const [imageIndex, setImageIndex] = useState("");
  const [taskInfo, setTaskInfo] = useState({
    taskName: "",
    taskType: "",
    instruction: "",
    description: "",
  });
  // const [taskStatus, setTaskStatus] = useState(null);
  const reRenderCustomPageHeader = false;

  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: t("task_management"),
    },
    {
      link: NavigateTo.WORKER_PENDING_TASK_DETAILS,
      label: t("wrker_pend_task_details"),
    },
  ];

  /* page description */

  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: "break-all",
  };
  const wn = [
    { labelTitle: t("worker_name") + " : ", customLabelStyle: leftFontstyle },
    {
      labelTitle: "Dummy Worker Name",
      customLabelStyle: rightFontStyle,
    },
  ]; // worker name
  const tnl = [
    { labelTitle: t("tsk_name") + " : ", customLabelStyle: leftFontstyle },
    {
      labelTitle: taskInfo.taskName,
      customLabelStyle: rightFontStyle,
    },
  ]; // task name

  const ttl = [
    { labelTitle: t("tsk_typ") + " : ", customLabelStyle: leftFontstyle },
    {
      labelTitle: taskInfo.taskType,
      customLabelStyle: rightFontStyle,
    },
  ]; // task type

  const tdl = [
    {
      labelTitle: t("tsk_desc") + " : ",
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo.description,
      customLabelStyle: rightFontStyle,
    },
  ]; // task description
  const textFieldlabels = [wn, tnl, ttl, tdl];

  /* page description */

  /* drop down */
  const AnnotationModalProps = {
    title: t("annot_list"),
    handleOk: () => {
      setIsModalVisible(false);
    },
    onSubmit: () => {
      setIsModalVisible(false);
    },
    modalWidth: 923,
    handleCancel: () => {
      setIsModalVisible(false);
    },
    isModalVisible: isModalVisible,
  };
  const udtBreadCrumbsEn = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: "Dashboard",
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: "Task Management",
    },
    {
      link: NavigateTo.WORKER_PENDING_TASK_DETAILS,
      label: "Worker Pending Task Details",
    },
    {
      link: "",
      label: "Annotate",
    },
  ];
  const udtBreadCrumbsAr = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: "لوحة القيادة",
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: "ادارة المهام",
    },
    {
      link: NavigateTo.WORKER_PENDING_TASK_DETAILS,
      label: "العامل تفاصيل المهمة المعلقة",
    },
    {
      link: "",
      label: "وسم",
    },
  ];
  const udtContainerProps = {
    breadCrumbItems: {
      en: udtBreadCrumbsEn,
      ar: udtBreadCrumbsAr,
    },
    menuProps: SidebarMenus("user"),
    active: t("task_management"),
  };
  const ActionColumn = {
    title: t("action"),
    dataIndex: "action",
    key: "action",
    width: "43pc",
    render: () => {
      return (
        <div
          style={{
            textAlign: "center",
          }}
        >
          {/* view button */}
          <button
            type="button"
            style={{
              padding: "6px 20px 5px 20px",
            }}
            className="button-list accordin focus raise-dispute"
            onClick={() => {
              navigate(NavigateTo.WORKER_PENDING_UDT, {
                state: {
                  udtContainerProps,
                  classLists: classNames,
                  taskId: 6, // pass task id here
                  images: imageArray,
                  imageIndex: imageIndex,
                  particularAnnotation: annotDetails,
                  allPreviouslyDoneAnnotations: allPreviouslyDoneAnnotations,
                },
              });
            }}
          >
            {t("view")}
          </button>
          {/* Accept button */}
          <button
            type="button"
            style={{
              padding: "6px 20px 5px 20px",
            }}
            className="button-list accordin focus raise-dispute"
            onClick={() => {
              setShowAcceptModal(true);
            }}
          >
            {t("acpt")}
          </button>
          {/* Reject button */}
          <button
            type="button"
            style={{
              padding: "6px 20px 5px 20px",
            }}
            className="button-list accordin focus raise-dispute"
            onClick={() => {
              setShowRejectModal(true);
            }}
          >
            {t("reject")}
          </button>
        </div>
      );
    },
  };
  const annotationC = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      width: 100,
      render: (value, item, index) => index + 1,
    },
    {
      title: t("annot_name"),
      dataIndex: "annotation_name",
      key: "annotation_name",
      width: 300,
      sorter: false,
      render: (value) => {
        return <Tooltip title={value}>{value}</Tooltip>;
      },
    },
    {
      title: t("file_id"),
      dataIndex: "file_id",
      key: "file_id",
      width: 300,
      sorter: false,
      render: (value) => {
        const tempVal =
          localStorage.lang == "ar"
            ? value
              .split("")
              .reverse()
              .concat("")
            : value;

        return (
          <Tooltip
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
            title={tempVal}
          >
            {tempVal}
          </Tooltip>
        );
      },
    },
    {
      title: t("class"),
      dataIndex: "class",
      key: "class",
      width: 150,
      sorter: false,
    },
    ActionColumn,
  ];
  // const imageForFileType = AppImage.file_n;
  const classListHeaders = [
    {
      label: "",
      headerWidth: "150px",
    },
    {
      label: t("clss_name"),
      headerWidth: "150px",
    },
    {
      label: t("annotations"),
      headerWidth: "250px",
    },
    {
      label: t("action"),
      paddingLeft: "70px",
      headerWidth: "80px",
    },
  ];
  const dropDownGroup = [
    {
      table: {
        placeholder: t("cls_list"),
        dropDownName: "Class",
        tableHeaders: classListHeaders,
        tableContents: classLists, //classListContents,
        ModalH: annotationC,
        ModalC: annotationDS,
        listLength: classLists.length,
        ModalProps: AnnotationModalProps,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data user-task-management-worker-pending-task-details-class-list-ar"
            : "text_data user-task-management-worker-pending-task-details-class-list-en",
      },
    },
  ];

  /* drop down */

  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("user"),
    active: t("task_management"),
  };

  // const [lang, setLang] = useState(localStorage.getItem("lang"));

  const payload = {
    body: {
      taskId,
    },
    success: (res) => {
      setTaskInfo({
        taskName: res?.data?.task_name,
        taskType:
          res?.data?.task_type == 1
            ? "Image"
            : res?.data?.task_type == 2
              ? "Text"
              : "Audio",
        instruction: res?.data?.instruction,
        description: res?.data?.description,
      });
      // setTaskStatus(res?.data?.status);

      if (res?.data?.task_type == 1) {
        localStorage.setItem("taskType", "Image");
        let tempImageArray = [];
        res?.data?.filesData.map((item, index) => {
          // image id
          tempImageArray.push({
            name: `Image-${index + 1}`,
            src: appconstant.imageBaseUrl + item?.file_name,
            regions: item?.jsonFile != null ? item?.jsonFile?.regions : [],
            id: item?.id,
          });
        });
        setImageArray(tempImageArray);

        if (res?.data?.class_name != "") {
          let tempClassName = JSON.parse(res?.data?.class_name);
          setClassNames(tempClassName);
        }

        let tempClassLists = [];
        res?.data?.filesData.map((item) => {
          tempClassLists.push({
            0: { image: appconstant.imageBaseUrl + item?.file_name },
            1: JSON.parse(item?.class_name).join(","), //+'oiyoeiyfowyfooiyoeiyfowyfooiyoeiyfowyfooiyoeiyfowyfo',
            2: item?.jsonFile ? item?.jsonFile?.regions.length : 0,
            3: {
              button: "View Annotations",
              link: NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: item?.jsonFile?.regions, // send anything like image id item?.id or annot details
            },
          });
        });
        setClassLists(tempClassLists);
      } // image

      if (res?.data?.task_type == 2) {
        localStorage.setItem("taskType", "Text");
        if (res?.data?.text_type == "segmentation") {
          localStorage.setItem("textType", "segmentation");
          // setSegmentation(res?.data?.segmantations);
        }
        if (res?.data?.text_type == "questions") {
          localStorage.setItem("textType", "questions");

          // setPdfUrl(res?.data?.pdfUrls);
          //"https://arxiv.org/pdf/1908.07069.pdf"
          const tempQuestions = [];
          res?.data?.question?.questionsData?.map((item) => {
            tempQuestions.push({
              name: "input0",
              type:
                item?.answerType == "Check-Box"
                  ? "checkbox"
                  : "radio",
              title: item?.question,
              choices: item?.answers,
            });
          });
          // setQuestions(tempQuestions);
        }
      } // text

      // if (res?.data?.task_type == 3) {
      //   localStorage.setItem("taskType", "Audio");

      //   let tempAudioArray = [];
      //   res?.data?.filesData.map((item, index) => {
      //     // audio id
      //     tempAudioArray = {
      //       name: `Audio-${index + 1}`,
      //       url: appconstant.imageBaseUrl + item?.file_name,
      //       regions: item?.jsonFile != null ? item?.jsonFile?.regions : [],
      //       id: item?.id,
      //     };
      //   });
      //   // setAudio(tempAudioArray);
      // } // audio
    },
    fail: (resf) => {
      console.log(resf, "resf of task details.");
    },
  }; //  task details info payload

  useEffect(() => {
    window.document.title = "WosoM";
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    const annotModalTabelCells = document.querySelectorAll(
      ".worker-pending-annot-modal th, .worker-pending-annot-modal td"
    );
    const tableHeaderOneSideMargins = document.querySelectorAll(
      ".ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before"
    );

    // const modalContent = document.querySelector('.ant-modal-content') ;
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
      if (isModalVisible) {
        document.querySelector(".ant-modal-content").style.direction = "rtl";
        for (let i = 0; i < annotModalTabelCells.length; i++) {
          const element = annotModalTabelCells[i];
          element.style.textAlign = "right";
        }
        for (let i = 0; i < tableHeaderOneSideMargins.length; i++) {
          const element = tableHeaderOneSideMargins[i];
          element.style.right = "unset";
          element.style.left = "0px";
        }
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
      if (isModalVisible) {
        document.querySelector(".ant-modal-content").style.direction = "ltr";

        for (let i = 0; i < annotModalTabelCells.length; i++) {
          const element = annotModalTabelCells[i];
          element.style.textAlign = "left";
        }
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    isInternetConnected() && dispatch(getTaskDetails(payload));
  }, []);

  return (
    <Container
      {...containerProps}

    >
      <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        {/* page header */}
        <CustomPageHeader
          label={t("wrker_pend_task_details")}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px",
          }}
          islanguageChanged={reRenderCustomPageHeader}
        ></CustomPageHeader>

        {/* page body */}
        <div className="wrapper_line">
          {/* pageDescription */}
          {textFieldlabels.map((item) => {
            return <TextField labels={item} key={item} />;
          })}

          {/* task Instruction */}
          <label style={leftFontstyle}>{t("instructions")} :</label>

          <div
            className="no-custom-input"
            style={{
              marginTop: "12px",
            }}
          >
            {taskInfo?.instruction ? (
              parse(taskInfo?.instruction)
            ) : (
              <p style={{ color: "#acb2ba" }}>{t("inst_wil_appear_here")}</p>
            )}
          </div>
          {/* task Instruction */}

          {/* pageDescription */}

          {/* dropdowns for pending task */}
          {taskInfo.taskType == "Image" || taskInfo.taskType == "Segmentation"
            ? dropDownGroup.map((item, index) => {
              return (
                <Collapse
                  key={index}
                  defaultActiveKey={["1"]}
                  onChange={() => {
                    const isDropdownOpened = document
                      .querySelector(".ant-collapse-header")
                      .getAttribute("aria-expanded");
                    const dropdownArrowsOnClickStyle = document.querySelectorAll(
                      ".ant-collapse-arrow svg"
                    );
                    if (isDropdownOpened == "true") {
                    } else {
                      dropdownArrowsOnClickStyle[0].setAttribute(
                        "style",
                        "transform: rotate(-90deg) !important;"
                      );
                    }
                  }}
                >
                  <Panel id={index} header={item.table.placeholder} key="1">
                    {item?.table?.tableContents.length ? (
                      <CustomTable
                        {...item.table}
                        handleClick={(label, id, link, row2, trIndex) => {
                          switch (label) {
                            case "View Annotations": {
                              if (id != null) {
                                let tempAnnotDetails = [];
                                const annots = id;
                                annots.map((item) => {
                                  tempAnnotDetails.push({
                                    annotation_name: item?.type,
                                    class: item?.cls,
                                    file_id: item?.id,
                                  });
                                });
                                setAnnotationDS(tempAnnotDetails);
                                setAnnotDetails(id);
                              }
                              localStorage.setItem("imageIndex", trIndex);
                              setImageIndex(trIndex);
                              setIsModalVisible(true);
                              break;
                            }
                            default:
                              break;
                          }
                        }}
                      />
                    ) : (
                      <CustomEmptyDiv
                        marginBottom={0}
                        margin={"25px"}
                        title={
                          item?.table?.dropDownName +
                          " " +
                          t("list_appear_here")
                        }
                      />
                    )}
                  </Panel>
                </Collapse>
              );
            })
            : null}

          {/* <ClassListAnnotationModal /> */}
          <Modal
            className="worker-pending-annot-modal"
            title={AnnotationModalProps.title}
            centered
            footer={null}
            onOk={AnnotationModalProps.handleOk}
            width={AnnotationModalProps.modalWidth}
            onCancel={AnnotationModalProps.handleCancel}
            open={isModalVisible}
          >
            {annotationDS.length ? (
              <Table
                className="annotation-popup"
                dataSource={annotationDS}
                columns={annotationC}
                showSorterTooltip={false}
                pagination={true}
              />
            ) : (
              <CustomEmptyDiv title={t("annots_wil_appear_here")} />
            )}
          </Modal>

          {/* accept modal */}
          <Modal
            className="accept-annotation-modal"
            title={t("acpt")}
            open={showAcceptModal}
            onOk={() => {
              setShowAcceptModal(false);
            }}
            onCancel={() => {
              setShowAcceptModal(false);
            }}
            footer={null}
            centered
          >
            <div className="text-center  model1">
              <p>{t("u_sure_to_acpt_this_annot")}</p>
            </div>
            <div className="text-center  model1">
              <button
                type="button"
                className="button-list top_new focus"
                onClick={() => setShowAcceptModal(false)}
              >
                {t("yes")}
              </button>
              <button
                type="button"
                className="focus"
                onClick={() => setShowAcceptModal(false)}
              >
                {t("no")}
              </button>
            </div>
          </Modal>

          {/* Reject modal */}
          <Modal
            className="accept-annotation-modal"
            title={t("reject")}
            open={showRejectModal}
            onOk={() => {
              setShowRejectModal(false);
            }}
            onCancel={() => {
              setShowRejectModal(false);
            }}
            footer={null}
            centered
          >
            <div className="text-center  model1">
              <p>{t("plz_add_cmnts_for_wrkr")}</p>
            </div>
            <div className="text-center  model1">
              <button
                type="button"
                className="button-list top_new focus"
                onClick={() => {
                  setShowRejectModal(false);
                }}
              >
                {t("yes")}
              </button>
              <button
                type="button"
                className="focus"
                onClick={() => {
                  setShowRejectModal(false);
                }}
              >
                {t("no")}
              </button>
            </div>
          </Modal>
        </div>
      </CardHoc>
    </Container>
  );
};

export default AcceptRejectAnnot;
