const images = {
  privacypolicyimg: require("../images/privacy-policy.png").default,
  logo: require("../images/logo.png").default,
  plus: require("../images/plus_five.png").default,
  usermessage: require("../images/user_message.png").default,
  user: require("../images/user.png").default,
  star: require("../images/star.png").default,
  user1: require("../images/user1.png").default,
  groupstar: require("../images/group_star.png").default,
  plusIcon: require("../images/plusIcon.png").default,
  crossButton: require("../images/crossButton.png").default,
  user2: require("../images/user2.png").default,
  user3: require("../images/user3.png").default,
  user4: require("../images/user4.png").default,
  user5: require("../images/user5.png").default,
  user6: require("../images/user6.png").default,
  adduser: require("../images/Add User.png").default,
  plus: require("../images/Plus.png").default,
  pdf: require("../images/Pdf.png").default,
  pdfs: require("../images/pdfs.png").default,
  profile: require("../images/profile.png").default,
  user_1: require("../images/user_1.png").default,
  user_2: require("../images/user_2.png").default,
  eye: require("../images/eye.png").default,
  eye_w: require("../images/eye_w.png").default,
  x: require("../images/x.png").default,
  user12: require("../images/user12.png").default,
  star12: require("../images/star_r.png").default,
  profile_new: require("../images/profile_new.png").default,
  profile_data12: require("../images/profile_data.png").default,
  logo3: require("../images/logo_3.png").default,
  Activity: require("../images/Activity.png").default,
  PaperPlus: require("../images/Paper Plus.png").default,
  PaperUpload: require("../images/Paper Upload.png").default,
  Paper: require("../images/Paper.png").default,
  Profile_1: require("../images/Profile (1).png").default,
  Logout: require("../images/Logout.png").default,
  frame: require("../images/Frame 1.png").default,
  dashboard_new: require("../images/dashboard_new.png").default,
  not_started: require("../images/not_started.png").default,
  progress: require("../images/progress.png").default,
  done: require("../images/done.png").default,
  icont1: require("../images/icont1.png").default,
  icont2: require("../images/icont2.png").default,
  icont3: require("../images/icont3.png").default,
  file_n: require("../images/file_n.png").default,
  PaperDownload: require("../images/Paper-Download.png").default,

  ta1d: require("../images/doubleTickTask.png").default,
  tc1d: require("../images/singleTickTask.png").default,
  tpo: require("../images/tickTriTask.png").default,
  tct: require("../images/todayCalendar.png").default,

  pad: require("../images/foldedPaper.png").default,
  pcd: require("../images/tickPaper.png").default,
  ppo: require("../images/paperTriangle.png").default,
  pct: require("../images/todayCalendar.png").default,
  bbox: require("../images/bbox_example.png").default,
  polygon: require("../images/polygon_example.png").default,
  transcription: require("../images/transcription_example.png").default,
  classification: require("../images/question_example.png").default,
  segmentation: require("../images/segmentation_example.png").default,
  notification: require("../images/bell.png").default,
  taskManagement: require("../images/taskSetting.png").default,
  question: require("../images/question.png").default,
  info: require("../images/info.png").default,
  inReviewTask: require("../images/in-review.png").default,
  completedTask: require("../images/completed.png").default,
  pendingTask: require("../images/pending.png").default,
  downArrow: require("../images/downArrow.png").default,
  upArrow: require("../images/upArrow.png").default,
  registerProfile: require("../images/register-profile.png").default,


  //Landing Page images

  img_2: require("../images/img_2.png").default,
  image12: require("../images/image12.png").default,
  clip: require("../images/clip.png").default,
  check: require("../images/check.png").default,
  //   Manager: require("../images/Manager.png").default,
  text12: require("../images/texte12.png").default,
  Unlock: require("../images/Unlockd.png").default,
  //   Profiledata: require("../images/Profiledata.png").default,
  Profiledataimg: require("../images/Profiledataimg.png").default,
  // Illustration: require("../images/Illustration.png").default,
  Illustration: require("../images/application-main-features.png").default,
  detailsuser: require("../images/detailsuser.png").default,
  usermanagement: require("../images/user-management.png").default,
  ownermanagement: require("../images/owner-manageement.png").default,
  // aboutimg: require("../images/about-img.png").default,
  aboutimg: require("../images/about-us.png").default,
  leftimg: require("../images/left.png").default,
  rightimg: require("../images/right.png").default,
  leftimg12: require("../images/left12.png").default,
  rightimg12: require("../images/right12.png").default,
  //   privacypolicyimg: require("../images/privacy-policy.png").default,
  tc1: require("../images/tc1.png").default,
  tick: require("../images/tick.png").default,
  emptyImg: require("../images/empty_data.png").default,
  crossIcon: require("../images/icon_close.png").default,
  cross12: require("../images/cross12.png").default,
  one: require("../images/one.png").default,
  two: require("../images/two.png").default,
  three: require("../images/three.png").default,
  four: require("../images/four.png").default,
  five: require("../images/five.png").default,
  dropdownDownArrow: require("../images/dropdownDownArrow.svg").default,
  pageNotFound: require("../images/page_not_found.png").default,
  confirm: require("../images/confirm.png").default,
  defaultImage: require('../images/default_user.png').default,
  //Landing Page images


  volumeFileImg: require('../images/volume-file-img.png').default,
  hillImg: require('../images/hills.png').default,
  background: require('../images/landingBack.svg').default

};

export default images;
