import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";
import {
  Col,
  Row,
  Skeleton,
  Card,
  Timeline,
  Space,
  Table,
  Tooltip as TooltipAntd,
} from "antd";
import {
  faUser,
  faEnvelope
} from "@fortawesome/free-regular-svg-icons";
import {
  faRunning
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClockCircleOutlined, NumberOutlined, CopyOutlined, TagsOutlined, InfoCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../hoc";
import { useDispatch } from "react-redux";
import TasksBar from "./tasksBar";
import TasksPie from "./tasksPie";
import { SidebarMenus, AppImage } from "../../../themes";
// import { CustomTable } from "../../../molecules";
import { getOwnerDashboardData } from "../../../redux/actions/Dashboard";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { useTranslation } from "react-i18next";



ChartJS.register(
  Title,
  Legend,
  Tooltip,
  LinearScale,
  BarElement,
  CategoryScale
);
const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const breadCrumbItems = [
    {
      link: "",
      label: t("dashboard"),
    },
  ];
  const lang = localStorage.getItem("lang");
  const name = localStorage.getItem("fullName");
  const [taskInfo, setTaskInfo] = useState({
    tasks: [],
    task_count: 0,
    workers: [],
    workers_count: 0,
    annotator_count: 0,
    document_count: 0,
    annotation_count: 0,
    tasks_annotated: 0,
    tasks_progress: 0,
    active_users: 0,
    inactive_users: 0,
    invites: [],
    invite_count: 0,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const iconStyle = {
    fontSize: "5vh",
    color: "#143c64",
    marginBottom: "10px",
  }
  const columnsWorkers = width > 768 ? [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (value, record) => {
        return (
          <>
            <img
              width={45}
              height={45}
              style={{ borderRadius: 10, marginLeft: 10, marginRight: 10 }}
              src={record?.image ? record?.image : AppImage.defaultImage}
            /> {value}
          </>
        );
      },
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      width: 300,
    },
    {
      title: t("total_annotations"),
      dataIndex: "total_annotated",
      key: "total_annotated",
      width: 300,
    },
    {
      title: t("last_active"),
      dataIndex: "last_active",
      key: "last_active",
      width: 300,
      render: (value) => {
        return (
          <>
            {value ? new Date(value).toLocaleString() : "-"}
          </>
        );
      },
    }
  ] : [{
    title: t("name"),
    dataIndex: "name",
    key: "name",
    width: 300,
    render: (value, record) => {
      return (
        <>
          <img
            width={45}
            height={45}
            style={{ borderRadius: 10, marginLeft: 10, marginRight: 10 }}
            src={record?.image ? record?.image : AppImage.defaultImage}
          /> {value}
        </>
      );
    },
  }, {
    title: t("total_annotations"),
    dataIndex: "total_annotated",
    key: "total_annotated",
    width: 300,
  },]
  const infoItems = [
    {
      "icon": <NumberOutlined
        style={iconStyle}
      />,
      "title": t("tasks"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: taskInfo?.tasks?.length,
        }
      ]
    },
    {
      "icon": <FontAwesomeIcon icon={faUser} style={iconStyle} />,
      "title": t("annotators"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: taskInfo?.annotator_count,
        }
      ]
    },
    {
      "icon": <CopyOutlined
        style={iconStyle}
      />,
      "title": t("documents"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: thousands_separators(taskInfo?.document_count),
        }
      ]
    },
    {
      "icon": <TagsOutlined
        style={iconStyle}
      />,
      "title": t("annotations"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: thousands_separators(taskInfo?.annotation_count),
        }
      ]
    }
  ];
  const infoItems2 = [
    {
      "icon": <FontAwesomeIcon icon={faRunning} style={iconStyle} />,
      "title": t("active_users"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: taskInfo?.active_users,
        }
      ]
    },
    {
      "icon": <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />,
      "title": t("total_invites"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: taskInfo?.invite_count,
        }
      ]
    },
    {
      "icon": <ClockCircleOutlined
        style={{
          fontSize: "5vh",
          color: "#143c64",
        }}
      />,
      "title": t("inactive_users"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: taskInfo?.inactive_users,
        }
      ]
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t("dashboard"),
  };
  function thousands_separators(num) {
    if (num === 0 || num == NaN) return 0;
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }
  function download_csv(type) {
    switch (type) {
      case "workers":
        let csvString = "data:text/csv;charset=utf-8,";
        csvString += "Name,Email,Total Annotations, Last Active\n";
        taskInfo.workers
          .sort((a, b) => {
            return parseInt(b.total_annotated.toString().replace(',', '')) - parseInt(a.total_annotated.toString().replace(',', ''))
          })
          .forEach(function (row) {
            csvString += row.name + "," + row.email + "," + row.total_annotated.toString().replace(',', '') + "," + (row.last_active ? new Date(row.last_active).toLocaleString().replace(',', ' ') : "-") + "\n";
          });
        var encodedUri = encodeURI(csvString);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Workers.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        break;
    }
  }
  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    const payload = {
      success: (res) => {
        let info = res;
        let ann_count = 0;
        let doc_count = 0;
        let annt_count = 0;
        let tsk_ann = 0;
        let tsk_prog = 0;
        let avt_urs = 0;
        let inavt_urs = 0;
        let wrkrs = [];
        res?.tasks?.map((item) => {
          ann_count += item.annotator_count;
          doc_count += item.document_count;
          annt_count += item.total_annotated;
          if (item.total_annotated === item.document_count) {
            tsk_ann++;
          } else if (item.total_annotated > 0) {
            tsk_prog++;
          }
        })
        res?.workers?.map((item) => {
          // if item last active is less than one month
          if (item.last_active && new Date(item.last_active) > new Date(new Date().setDate(new Date().getDate() - 30))) {
            avt_urs++;
          } else {
            inavt_urs++;
          }
          wrkrs.push({
            image: item?.image,
            name: item?.name,
            email: item?.email,
            total_annotated: thousands_separators(item?.total_annotated),
            last_active: item?.last_active,
          })
        })
        info.workers = wrkrs.sort((a, b) => {
          return parseInt(b.total_annotated.toString().replace(',', '')) - parseInt(a.total_annotated.toString().replace(',', ''))
        });
        info.active_users = avt_urs;
        info.inactive_users = inavt_urs;
        info.invites = res?.invites.map((item) => {
          return {
            dot: <InfoCircleOutlined style={{ fontSize: "16px", color: "#316293" }} />,
            children: (
              <p>
                <b>{item?.name}</b> {t("invited_task")} <b>{item?.task}</b>
                &nbsp; {t("as")} {item?.invite_type}  (<b>{item?.is_accepted ? t("accepted") : t("rejected")}</b>) &nbsp;
                <b>{new Date(item?.created_at).toLocaleString()}</b>
              </p >
            ),
          }
        }).reverse() || [];
        info.invite_count = res?.invites?.length;
        info.annotator_count = ann_count;
        info.document_count = doc_count;
        info.annotation_count = annt_count;
        info.tasks_annotated = tsk_ann;
        info.tasks_progress = tsk_prog;
        setTaskInfo(info);
      },
      fail: () => {
      },
    };
    isInternetConnected() && dispatch(getOwnerDashboardData(payload));
  }, []);

  return (
    <Container {...containerProps}>
      {taskInfo?.tasks.length > 0 ? (
        <>
          <Row>
            <Col xs={24} md={24} lg={5}>
              <div
                className="card card-sm"
                style={{
                  margin: "20px 20px 0 20px",
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}>
                <h2 style={{
                  fontWeight: "600",
                  color: "#214c7d"
                }}>
                  {t("welcome")}
                </h2>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                }}>

                  <div
                    style={{
                      border: "2px solid #193d64",
                      borderRadius: "50px",
                      width: " 50px",
                      height: " 50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(localStorage.getItem("user_type") == "owner" ? NavigateTo.OWNER_PROFILE : NavigateTo.USER_PROFILE);
                    }}
                  >
                    <img
                      style={{
                        borderRadius: "35px",
                        width: " 46px",
                        height: " 46px",
                      }}
                      alt=""
                      src={localStorage.getItem("userImage") && localStorage.getItem("userImage") != "" ? localStorage.getItem("userImage") : AppImage.defaultImage}
                    />
                  </div>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                  }}>

                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "25px",
                        color: "#316293",
                        paddingRight: "8px",
                        paddingLeft: "8px",
                        cursor: "pointer",
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // width: name.length > 8 ? 103 : 82,
                      }}
                    >
                      <TooltipAntd title={name} className="userName">
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {name?.length > 18 ? name?.substring(0, 8) + "..." : name || ""}
                        </span>
                      </TooltipAntd>
                      {/* {localStorage.getItem('fullName')} */}
                    </label>
                    <label
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "#333",
                        paddingRight: "8px",
                        paddingLeft: "8px",
                        cursor: "pointer",
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // width: name.length > 8 ? 103 : 82,
                      }}
                    >
                      {new Date().toLocaleDateString(lang, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </label>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={19}>
              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "100%",
              }}>
                <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                  <Card className="border-radius-lg ">
                    <div style={{
                      fontSize: "1.2rem",
                      color: "#214c7d",
                      marginBottom: "10px",
                    }}>
                      {t("overview")}
                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }} >
                      {/* stat cards */}
                      <div style={{
                        display: "flex",
                        flexDirection: width > 1900 ? "row" : "column",
                      }}>

                        {infoItems.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="card card-sm"
                              style={{
                                margin: "10px",
                              }}
                            >
                              <h1 style={{
                                fontSize: "1rem",
                                color: "#214c7d",
                                fontWeight: "600",
                                padding: " 8px"
                              }}>
                                {item.title}
                              </h1>
                              <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}>
                                <div
                                  style={{
                                    position: "relative",
                                    padding: "0 24px",
                                  }}
                                >
                                  {item.icon}
                                </div>
                                <div className="stats" style={{ display: "flex" }}>
                                  {item.items.map((item, index2) => {
                                    return (
                                      <div
                                        key={index2}
                                        style={{
                                          position: "relative",
                                          padding: "0 24px",
                                        }}
                                        className="stat-card"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h3
                                            style={{
                                              fontSize: "1.2rem",
                                              color: "#214c7d",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {item.value}
                                          </h3>
                                          <p
                                            style={{
                                              fontSize: "1rem",
                                              color: "#3a3a3a",
                                            }}
                                          >
                                            {item.label}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </Card>
                </CardHoc>
              </div>
            </Col>
          </Row>
          {taskInfo?.taskName !== "" ? (
            <div className="collapse-details">
              <Row>
                <Col xs={24} md={12} lg={16}>
                  <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                    <Card
                      className="border-radius-lg "
                      style={{
                        marginBottom: "20px",
                      }}>
                      <div style={{
                        fontSize: "1.2rem",
                        color: "#214c7d",
                        // fontWeight: "600",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}>
                        {t("tasks")}
                      </div>
                      <div>
                        <TasksBar tasks={taskInfo?.tasks} />
                      </div>
                    </Card>
                  </CardHoc>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                    <Card className="border-radius-lg "
                      style={{
                        marginBottom: "20px",
                      }}>
                      <div style={{
                        fontSize: "1.2rem",
                        color: "#214c7d",
                        // fontWeight: "600",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}>
                        {t("status")}
                      </div>
                      <div
                        style={{
                          marginTop: "12px",
                          padding: "1%",
                          minHeight: "300px",
                        }}
                      >
                        {taskInfo?.task_count > 0 && (
                          <TasksPie total={taskInfo?.task_count} annotated={taskInfo?.tasks_annotated} progress={taskInfo?.tasks_progress} />
                        )}
                      </div>
                    </Card>
                  </CardHoc>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={12} lg={6}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}>
                    <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                      <Card className="border-radius-lg "
                        style={{
                          marginBottom: "20px",
                        }}>
                        <div style={{
                          fontSize: "1.2rem",
                          color: "#214c7d",
                          marginBottom: "10px",
                        }}>
                          {t("workers")}
                        </div>
                        <div style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }} >
                          {/* stat cards */}
                          <div style={{
                            display: "flex",
                            flexDirection: "column",

                          }}>
                            {infoItems2.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="card card-sm"
                                  style={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  <h1 style={{
                                    fontSize: "1rem",
                                    color: "#214c7d",
                                    fontWeight: "600",
                                    padding: " 8px"
                                  }}>
                                    {item.title}
                                  </h1>
                                  <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}>
                                    <div
                                      style={{
                                        position: "relative",
                                        padding: "0 24px",
                                      }}
                                    >
                                      {item.icon}
                                    </div>
                                    <div className="stats" style={{ display: "flex" }}>
                                      {item.items.map((item, index2) => {
                                        return (
                                          <div
                                            key={index2}
                                            style={{
                                              position: "relative",
                                              padding: "0 24px",
                                            }}
                                            className="stat-card"
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <h3
                                                style={{
                                                  fontSize: "1.2rem",
                                                  color: "#214c7d",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {item.value}
                                              </h3>
                                              <p
                                                style={{
                                                  fontSize: "1rem",
                                                  color: "#3a3a3a",
                                                }}
                                              >
                                                {item.label}
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </Card>
                    </CardHoc>
                    <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                      <Card className="border-radius-lg "
                        style={{
                          marginBottom: "20px",
                        }}>
                        <div style={{
                          fontSize: "1.2rem",
                          color: "#214c7d",
                          marginBottom: "10px",
                        }}>
                          {t("invites")}
                        </div>
                        {taskInfo?.invites ? (
                          <Timeline
                            style={{
                              margin: "3% 4% 0% 4%",
                              padding: "2%",
                              maxHeight: "350px",
                              overflowY: "scroll",
                            }}
                          >
                            {taskInfo?.invites.map((item, index) => {
                              return (
                                <Timeline.Item
                                  key={index}
                                  dot={item?.dot ? item?.dot : null}
                                  color={item?.color ? item?.color : "blue"}
                                >
                                  {item?.children}
                                </Timeline.Item>
                              );
                            })}
                          </Timeline>
                        ) : (
                          <p style={{ color: "#acb2ba" }}>{t('ann_wil_appear_here')}</p>
                        )}
                      </Card>
                    </CardHoc>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={18}>
                  <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                    <Card
                      className="border-radius-lg "
                      style={{
                        marginBottom: "20px",
                      }}>
                      {/* workers list start*/}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <div style={{
                          fontSize: "1.2rem",
                          color: "#214c7d",
                          // fontWeight: "600",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}>
                          {t("wrkr_list")}
                        </div>
                        <button
                          style={{
                            marginLeft: 15,
                            padding: "5px 15px",
                            minWidth: "max-content",
                          }}
                          type="button" onClick={() => download_csv("workers")}
                          className="button-list bottom_button pt-10 focus"
                        >
                          <CloudDownloadOutlined style={{
                            fontSize: "1.2rem",
                            marginRight: "5px",
                          }}

                          /> {t("export_wrkr_list")}
                        </button>
                      </div>
                      <CardHoc
                        cardInnerPadding={"content-e"}
                        cardPadding={"0px"}
                        minHeight={0}
                      >
                        {taskInfo?.workers.length != 0 ? (
                          <div id="paginationOfgroup">
                            <Table
                              className="table table-sm"
                              dataSource={taskInfo?.workers}
                              columns={columnsWorkers}
                              pagination={true}
                              showSorterTooltip={false}
                            />
                          </div>
                        ) : (
                          <p>{t('wrkr_appear_here')}</p>
                        )}
                      </CardHoc>
                    </Card>
                  </CardHoc>
                </Col>
              </Row>
            </div>
          ) : (
            <Skeleton style={{
              padding: "10px",
            }} active />
          )}
        </>
      ) : (
        <div style={{
          padding: "50px",
        }}>
          <Space >
            <Skeleton.Button active={true} size={'large'} shape={'round'} block={true} />
            <Skeleton.Avatar active={true} size={'large'} shape={'round'} />
            <Skeleton.Input active={true} size={'large'} />
          </Space>
          <br />
          <br />
          <Skeleton.Button active={true} size={'large'} shape={'round'} block={true} />
          <br />
          <br />
          <Skeleton.Input active={true} size={'large'} block={true} />
          <br />
          <br />
          <br />
          <br />
          <Skeleton.Button active={true} size={'large'} shape={'round'} block={true} />
          <br />
          <br />
          <Skeleton.Input active={true} size={'large'} block={true} />
          <br />
          <br />
          <br />
          <br />
          <Skeleton.Button active={true} size={'large'} shape={'round'} block={true} />
          <br />
          <br />
          <Skeleton.Input active={true} size={'large'} block={true} />
          <br />
          <br />
          <br />
          <br />
          <Skeleton.Button active={true} size={'large'} shape={'round'} block={true} />
          <br />
          <br />
          <Skeleton.Input active={true} size={'large'} block={true} />
          <br />
          <br />
        </div>
      )}
    </Container >
  );
};

export default Dashboard;
