import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../hoc";
import { CustomEmptyDiv, CustomPageHeader } from "../../../molecules";
import { NavigateTo, SidebarMenus } from "../../../themes";
import { Table, Modal, Select, Pagination, Row, Col, Space, Tooltip } from "antd";
import { HistoryOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { exportData } from "../../../redux/actions/Project";
import { getTaskListExport } from "../../../redux/actions/Tasks";
import { customFunctions } from "../../../themes/utils/CustomFunctions";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import * as examples from "./exampels";

const { Option } = Select;

const ExportDataTaskList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [pageNum, setPageNum] = useState(1);
  const [ordering, setOrdering] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [total, setTotalTasks] = useState(0);
  // const [selectedType, setSelectedType] = useState('image') // image, audio, text
  const [dataSource, setTaskListContents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showSuccessModal, setshowSuccessModal] = useState(false);
  const [exportStatus, setExportStatus] = useState("not_started");
  const [downloadData, setDownloadData] = useState(null);
  const [exportItem, setExportItem] = useState(null); // [id, type, task_type, status, export_status, total_annotated, total_validated
  const [dropdownValues, setDropdownvalues] = useState({
    taskId: null,
    taskType: null,
    selectedType: 'image',
    fileFormat: 0,
    dateFormat: null,
    fieldNames: null,
    byWords: null,
    is_partial: false
  });
  // const [lang, setLang] = useState(localStorage.getItem("lang"));

  let projectId = localStorage.getItem("pastProjectId"); // implement it if api will be hit for this page

  const showModal = (data) => {
    // console.log(item?.type, 'type');
    // setSelectedType(item?.type);
    let item = data?.item;
    const tempValue = { ...dropdownValues };
    tempValue["taskId"] = item?.id;
    tempValue["taskName"] =  item?.name;
    tempValue["taskType"] = item?.task_type;
    tempValue["selectedType"] = item?.type;
    tempValue["fileFormat"] = item?.type == "image" ? 0 : item?.type == "audio" ? 3 : item?.task_type == 'classification' ? 5 : item?.task_type == 'text_transcription' ? 6 : 1;
    tempValue["dateFormat"] = null;
    tempValue["fieldNames"] = null;
    tempValue["byWords"] = null;
    tempValue["is_partial"] = true
    setDropdownvalues(tempValue);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const breadCrumbItems = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.ONGOING_PROJECT_DETAILS,
      label: t("projects"),
    },
    {
      link: "",
      label: t("task_list"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t('export_data'),
  };
  const SuccessModal = (item) => {
    return (
      <Modal
        // title={"Alert"}
        open={showSuccessModal}
        closable={false}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
        footer={null}
        centered
      >
        <div className="text-center  model1"
          style={{
            padding: '20px'
          }}>
          <p>{t("partial_task_export")}</p>
        </div>
        <div className="text-center button_d_inline model1">
          <button
            type="button"
            className="button-list top_new active"
            onClick={() => {
              setshowSuccessModal(false);
              setExportStatus("not_started");
              showModal(item);
            }}
          >
            {t("yes")}
          </button>
          <button
            type="button"
            className="button-list top_new"
            onClick={() => {
              setshowSuccessModal(false);
            }}
          >
            {t("no")}
          </button>
        </div>
      </Modal>
    );
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: t("tsk_name"),
      dataIndex: "name",
      key: "name",
      sorter: false,
      width: 150,
    },
    {
      title: t("tsk_typ"),
      dataIndex: "type",
      key: "type",
      sorter: false,
      width: 150,
      render: (item) => {
        return t(item)
      }
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      sorter: false,
      width: 150,
      render: (item) => {
        return t(item)
      }
    },
    {
      title: t("export_status"),
      dataIndex: "export_status",
      key: "export_status",
      sorter: false,
      width: 200,
      render: (item) => {
        return t(item)
      }
    },
    {
      title: t("total_annotated"),
      dataIndex: "total_annotated",
      key: "total_annotated",
      sorter: false,
      width: 200,
      render: (item) => {
        return t(item)
      }
    },
    {
      title: t("total_validated"),
      dataIndex: "total_validated",
      key: "total_validated",
      sorter: false,
      width: 200,
      render: (item) => {
        return t(item)
      }
    },
    {
      title: t("action"),
      dataIndex: "Posted",
      key: "Posted",
      width: 300,
      render: (value, item) => {
        return (
          <div>
            {item?.export_status != "no_data" ? (
              <>
                <Tooltip title={t("recent_exports")}>
                  <button
                    type="button"
                    style={{
                      maxWidth: "40px",
                      width: "40px",
                    }}
                  >
                    <HistoryOutlined />
                  </button>
                </Tooltip>
                <button
                  type="submit"
                  className="focus"
                  onClick={() => {
                    setExportItem(item);
                    setshowSuccessModal(true);
                  }}
                >
                  {item?.export_status == "partial" ? t("export") : t("partial_export")}
                </button>
              </>
            ) : <button
              type="button"
              className="focus"
              disabled
              style={{
                background: "rgb(155 154 154)",
                color: "#000000",
                borderColor: "#D3D3D3",
                cursor: "not-allowed"
              }}
            >
              {t("still_in_progress")}
            </button>
            } </div>
        );
      },
    },
  ];

  ``
  const payload = {
    body: {
      projectId,
      pageNum,
      searchValue,
      ordering,
    },
    success: (res) => {
      let tempTaskList = []; // (task name,task type,statusview)

      res?.map((item, index) => {
        tempTaskList.push({
          id: item?.id,
          key: index,
          name: item?.name,
          task_type: item?.task_type,
          type:
            item?.task_type == "bbox" || item?.task_type == "polygon"
              ? "image"
              : item?.task_type == "segmentation" || item?.task_type == "classification" || item?.task_type == "text_transcription"
                ? "text"
                : "audio",
          status: item?.status,
          total_annotated: item?.total_annotated || 0,
          total_validated: item?.total_validated || 0,
          export_status: item?.export_status,
        });
      });
      setTaskListContents(tempTaskList);
      setTotalTasks(res?.count);
    },
    fail: (resf) => {
      console.log(resf, "resf of ongoing proj details");
    },
  };
  useEffect(() => {
    document.title = "WosoM";
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    const modalDropdownPlaceholders = document.querySelectorAll(
      ".ant-modal-content .form-group .default_text .ant-select-selector"
    );
    const modalDropdownArrows = document.querySelectorAll(
      // ".ant-select-arrow"
      ".ant-select.default_text"
    );
    const modalDropdownItems = document.querySelectorAll(
      // "div.ant-select-dropdown"
      ".ant-select-item.ant-select-item-option"
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
      if (isModalVisible) {
        // console.log(modalDropdownItems,'modalDropdownItems===')
        document.querySelector(".ant-modal-content").style.direction = "rtl";
        for (let i = 0; i < modalDropdownPlaceholders.length; i++) {
          const element = modalDropdownPlaceholders[i];
          element.style.textAlign = "right";
        }
        for (let i = 0; i < modalDropdownArrows.length; i++) {
          const element = modalDropdownArrows[i];
          element.style.left = "19px";
          element.style.right = "usnet";
          // element.style.direction  = 'ltr'
        }
        for (let i = 0; i < modalDropdownItems.length; i++) {
          const element = modalDropdownItems[i];
          element.style.direction = "rtl";
        }
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
      if (isModalVisible) {
        document.querySelector(".ant-modal-content").style.direction = "ltr";
        for (let i = 0; i < modalDropdownPlaceholders.length; i++) {
          const element = modalDropdownPlaceholders[i];
          element.style.textAlign = "left";
        }
        for (let i = 0; i < modalDropdownArrows.length; i++) {
          const element = modalDropdownArrows[i];
          element.style.left = "unset";
          element.style.right = "19px";
          // element.style.direction  = 'ltr'
        }
        for (let i = 0; i < modalDropdownItems.length; i++) {
          const element = modalDropdownItems[i];
          element.style.direction = "ltr";
        }
      }
    }
  }, [isModalVisible]);


  useEffect(() => {
    isInternetConnected() && dispatch(getTaskListExport(payload)); // implement it if api will be hit
  }, [pageNum, searchValue, ordering]);
  return (
    <Container
      {...containerProps}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <CustomPageHeader
        wrapperClassName={"top_button_item d-flex justify-content-between"}
      >
        <div className="button_left contain">
          <button
            type="submit"
            className="button-list top_new focus"
            style={{ visibility: "hidden" }}
          >
            {t("AddNewgroup")}
          </button>
          <input
            type="text"
            className="search-bar"
            placeholder={t("search")}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            autoComplete="off"
          />
        </div>
      </CustomPageHeader>
      <CardHoc cardInnerPadding={"content-e owner-export-card "}>
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
          <h6
            className="text-white text-capitalize ps-3"
            style={{ fnotSize: "20px" }}
          >
            {t("task_list")}
          </h6>
        </div>
        {dataSource.length ? (
          <>
            <Table
              className="export-proj-task-list"
              dataSource={dataSource}
              columns={columns}
              showSorterTooltip={false}
              pagination={false}
              onChange={(pagination, filters, sorter) => {
                if (sorter.order == undefined) {
                  setOrdering("");
                }
                if (
                  sorter.order == "ascend" &&
                  sorter.order != undefined &&
                  sorter.columnKey == "name"
                ) {
                  setOrdering("-name");
                }
                if (
                  sorter.order == "descend" &&
                  sorter.order != undefined &&
                  sorter.columnKey == "name"
                ) {
                  setOrdering("name");
                }
                if (
                  sorter.order == "ascend" &&
                  sorter.order != undefined &&
                  sorter.columnKey == "type"
                ) {
                  setOrdering("-task_type");
                }
                if (
                  sorter.order == "descend" &&
                  sorter.order != undefined &&
                  sorter.columnKey == "type"
                ) {
                  setOrdering("task_type");
                }
              }}
            />
            <Pagination
              showTitle={false}
              style={{
                paddingTop: "30px",
                paddingBottom: "30px",
                maxWidth: "fit-content",
                right: "33px",
                left: "auto",
                position: "absolute",
              }}
              className="pagination-component"
              defaultCurrent={1}
              total={total} //
              size="medium"
              onChange={(item) => {
                setPageNum(item);
              }}
            />
          </>
        ) : (
          <CustomEmptyDiv
            marginLeft={"16px"}
            marginRight={"16px"}
            title={t('tasks_appear_here')}
          />
        )}
      </CardHoc>
      <Modal
        title={
          `${dropdownValues.selectedType == "image" ?
            t("exporting_image_task") :
            dropdownValues.selectedType == "text" ?
              t("exporting_text_task") :
              t("exporting_audio_task")} : ${dropdownValues.taskName}`
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={800}
        // className="success_data"
        className={localStorage.lang == 'ar' ? 'success_data ar' : 'success_data en'}
        centered
      >
        <Row className="model1 export_template">
          <Col span={8}>
            <div className="form-group data_new">
              <label className="data_new color_tasker">
                {t("select_export_format")}
              </label>
              <Select
                onClick={() => {
                  if (localStorage.getItem("lang") == "ar") {
                    setTimeout(() => {
                      const ele = document.querySelectorAll(
                        ".rc-virtual-list-holder-inner"
                      );
                      ele.forEach(element => {
                        element.classList.add("new_layout");
                        element.classList.add("ar");
                      });
                    }, 10);
                  }
                  else {
                    const ele = document.querySelectorAll(
                      ".rc-virtual-list-holder-inner"
                    );
                    ele.forEach(element => {
                      element.classList.remove("new_layout");
                      element.classList.remove("ar");
                    });
                  }
                }}

                className="default_text"
                placeholder={t("select_file_format")}
                value={dropdownValues?.fileFormat}

                onChange={(val) => {

                  const tempValue = { ...dropdownValues };
                  tempValue["fileFormat"] = val;
                  setDropdownvalues(tempValue);
                }}
                defaultValue={0}
              >

                {
                  dropdownValues.selectedType == "image" ? (
                    <>
                      <Option value={0}>COCO</Option>
                      <Option value={1}>PASCAL</Option>
                      <Option value={2}>YOLO</Option>
                    </>
                  ) : dropdownValues.selectedType == "audio" ? (
                    <>
                      <Option value={3}>JSON</Option>
                      <Option value={4}>XML</Option>
                    </>
                  ) : (
                    <>
                      <Option value={5}>XLSX</Option>
                      <Option value={6}>CSV</Option>
                      <Option value={7}>JSON</Option>
                    </>
                  )
                }
              </Select>
            </div>
            <div className="form-group data_new">
              <label className="data_new color_tasker">
                {t("normalize_field_names")}
              </label>

              <Select
                onClick={() => {
                  if (localStorage.getItem("lang") == "ar") {
                    setTimeout(() => {
                      const ele = document.querySelectorAll(
                        ".rc-virtual-list-holder-inner"
                      );
                      ele.forEach(element => {
                        element.classList.add("new_layout");
                        element.classList.add("ar");
                      });
                    }, 10);
                  }
                  else {
                    const ele = document.querySelectorAll(
                      ".rc-virtual-list-holder-inner"
                    );
                    ele.forEach(element => {
                      element.classList.remove("new_layout");
                      element.classList.remove("ar");
                    });
                  }
                }}
                value={dropdownValues?.fieldNames}
                onChange={(val) => {
                  const tempValue = { ...dropdownValues };
                  tempValue["fieldNames"] = val;
                  setDropdownvalues(tempValue);
                }}
                className="default_text"
                placeholder={t("yes_no")}
              >
                <Option value={1}>{t("yes")}</Option>
                <Option value={0}>{t("no")}</Option>
              </Select>
            </div>
            <div>
              <div className="form-group data_new">
                <label className="data_new color_tasker">
                  {t('splt_by_annots')}
                </label>
                <Select
                  onClick={() => {
                    if (localStorage.getItem("lang") == "ar") {
                      setTimeout(() => {
                        const ele = document.querySelectorAll(
                          ".rc-virtual-list-holder-inner"
                        );
                        ele.forEach(element => {
                          element.classList.add("new_layout");
                          element.classList.add("ar");
                        });
                      }, 10);
                    }
                    else {
                      const ele = document.querySelectorAll(
                        ".rc-virtual-list-holder-inner"
                      );
                      ele.forEach(element => {
                        element.classList.remove("new_layout");
                        element.classList.remove("ar");
                      });
                    }
                  }}
                  value={dropdownValues?.byWords}
                  onChange={(val) => {
                    const tempValue = { ...dropdownValues };
                    tempValue["byWords"] = val;
                    setDropdownvalues(tempValue);
                  }}
                  className="default_text"
                  placeholder={t("yes_no")}
                >
                  <Option value={1}>{t("yes")}</Option>
                  <Option value={0}>{t("no")}</Option>
                </Select>
              </div>
            </div>
          </Col>
          <Col span={16}>
            <label className="data_new color_tasker">
              {t("example_output")}:&nbsp; <b> {
                dropdownValues?.fileFormat == 0 ?
                  "COCO" :
                  dropdownValues?.fileFormat == 1 ?
                    "PASCAL" :
                    dropdownValues?.fileFormat == 3 ?
                      "AUDIO-JSON" :
                      dropdownValues?.fileFormat == 4 ?
                        "AUDIO-XML" :
                        dropdownValues?.fileFormat == 5 ?
                          "TEXT-XLSX" :
                          dropdownValues?.fileFormat == 6 ?
                            "TEXT-CSV" :
                            dropdownValues?.fileFormat == 7 ?
                              "TEXT-JSON" :
                              "YOLO"
              }
              </b>
            </label>
            <div style={{
              boxShadow: 'rgb(177 174 174) 0px 0px 5px 0px;',
              borderRadius: '16px',
              height: '300px',
              padding: '10px',
              overflowY: 'scroll'
            }}>
              {[0, 1, 2, 3, 4, 7].includes(dropdownValues?.fileFormat) ? (
                <SyntaxHighlighter
                  language={
                    [0, 3, 7].includes(dropdownValues?.fileFormat) ? "json" : [1, 4].includes(dropdownValues?.fileFormat) ? "xml" : "cfg"
                  }
                  style={lightfair}
                >
                  {
                    dropdownValues?.fileFormat == 0 ?
                      examples.imageCocoCodeString :
                      dropdownValues?.fileFormat == 1 ?
                        examples.imagePascalVoctString :
                        dropdownValues?.fileFormat == 2 ?
                          examples.textJSONString :
                          dropdownValues?.fileFormat == 3 ?
                            examples.audioJSONString :
                            dropdownValues?.fileFormat == 4 ?
                              examples.audioXMLString :
                              dropdownValues?.fileFormat == 7 ?
                                examples.textJSONString :
                                examples.imageYoloString
                  }
                </SyntaxHighlighter>) : (
                dropdownValues?.fileFormat == 5 || dropdownValues?.fileFormat == 6 ? examples.textClassificationTable : examples.textNerTable
              )}
            </div>
          </Col>
        </Row>
        <Row style={{
          marginTop: '20px'

        }}>
          <Col span={24} style={{ display: 'flex', justifyContent: "center" }}>
            <Space direction="vertical" size="large" style={{ alignItems: "center" }} >
              {exportStatus == "exported" ?
                (
                  <>
                    <div style={{
                      marginBottom: '20px'
                    }}>
                      {
                        downloadData?.export_data &&
                        <button
                          type="submit"
                          className="button-list top_new color_new focus"
                          onClick={() => {
                            const xhr = new XMLHttpRequest();
                            xhr.open('GET', downloadData?.export_data, true);
                            xhr.responseType = 'blob';

                            xhr.onload = () => {
                              if (xhr.status === 200) {
                                // Create a link element and download the file
                                const link = document.createElement('a');
                                link.href = window.URL.createObjectURL(xhr.response);
                                link.download = downloadData?.export_data.split("/").pop();
                                link.click();
                              }
                            };

                            xhr.send();
                          }}
                        >
                          {t("download_annotations")}
                        </button>
                      }
                      {
                        downloadData?.export_zip &&
                        <button
                          type="submit"
                          className="button-list top_new color_new focus"
                          onClick={() => {
                            window.open(downloadData?.export_zip, "_blank");
                          }}
                        >
                          {t("download_data")}
                        </button>
                      }
                    </div>
                    <button
                      type="submit"
                      className="button-list top_new"
                      onClick={() => {
                        handleCancel(false);
                      }}
                    >
                      {t("close")}
                    </button>
                  </>
                ) : (exportStatus == "exporting" ? (<>
                  <button
                    type="button"
                    className="btn focus"
                    disabled
                    style={{
                      background: "rgb(155 154 154)",
                      color: "#000000",
                      borderColor: "#D3D3D3",
                      cursor: "not-allowed"
                    }}
                  >
                    {t("cancel")}
                  </button>
                </>
                ) : (
                  <button
                    type="submit"
                    className="button-list top_new color_new focus"
                    onClick={() => {
                      if (
                        dropdownValues?.fieldNames != null &&
                        dropdownValues?.fileFormat != null &&
                        dropdownValues?.byWords != null
                      ) {
                        let paylaodData = {
                          body: {
                            task_id: dropdownValues?.taskId,
                            export_type:
                              dropdownValues?.fileFormat == 0 ? "image_coco" : dropdownValues?.fileFormat == 1 ? "image_pascal_voc" : dropdownValues?.fileFormat == 2 ? "image_yolo" : dropdownValues?.fileFormat == 3 ? "audio_transcription_json" : dropdownValues?.fileFormat == 4 ? "audio_transcription_xml" : dropdownValues?.fileFormat == 5 || dropdownValues?.fileFormat == 7 ? "text_classification" : dropdownValues?.fileFormat == 6 ? "text_transcription" : "text_ner",
                            export_format: dropdownValues?.fileFormat == 0 || dropdownValues?.fileFormat == 3 || dropdownValues?.fileFormat == 7 ? "json" : dropdownValues?.fileFormat == 1 ? "xml" : dropdownValues?.fileFormat == 2 ? "txt" : dropdownValues?.fileFormat == 4 ? "xml" : dropdownValues?.fileFormat == 5 ? "xlsx" : "csv",
                            normalize: dropdownValues?.fieldNames,
                            split: dropdownValues?.byWords,
                            is_partial: dropdownValues?.is_partial
                          },
                          success: (res) => {
                            setExportStatus("exported");
                            setDownloadData(res);
                          },
                          fail: () => {
                            // console.log(resf, "resf of ongoing proj details");
                            setExportStatus("not_started");
                            customFunctions.showToast(
                              t("something_went_wrong"),
                              false
                            );
                          },
                        };
                        setExportStatus("exporting");
                        isInternetConnected() && dispatch(exportData(paylaodData));
                        // handleCancel(false);
                      } else {
                        customFunctions.showToast(
                          t("provide_all_data_to_export"),
                          false
                        );
                      }
                    }}
                  >
                    {t("export")}
                  </button>
                ))
              }
            </Space>
          </Col>
        </Row>
      </Modal>
      <SuccessModal item={exportItem} />
    </Container >
  );
};

export default ExportDataTaskList;
