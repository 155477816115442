import { LOGOUT, USER_LOGIN_ERROR, USER_LOGIN_LOAD, USER_LOGIN_SUCCESS } from "./../../actionTypes/actionTypes";


const initialState = {
    isLoading: false,
    error: null,
    status: 0,
    data: {},
    netConnected: false,
    currentUser: null
}

// Login Reducer --->


const userLoginReducer = (state = initialState, action) => {
    // console.log(action,'all actions***')
    switch (action.type) {
        // case REHYDRATE:
        //     return {
        //       ...initialState,
        //       ...((action.payload || {}).common || {}),
        //       isLoading: false,
        //       netConnected: false,
        //       currentUser: null
        //     };
        // case RESET:
        //     return {
        //         ...initialState,
        //         netConnected: state.netConnected,
        //         authToken: null
        //     };
        case LOGOUT:
            return {
                ...state,
                isLoading: false,
                error: null,
                status: 0,
                data: {},
                netConnected: false,
                currentUser: null
            };
        case USER_LOGIN_LOAD:
            // localStorage.setItem('userLoginData',btoa(JSON.stringify(action.payload)));
            return {
                ...state,
                isLoading: true
            };

        case USER_LOGIN_SUCCESS:
            localStorage.setItem("token", action.payload);
            return {
                ...state,
                isLoading: false,

            };

        case USER_LOGIN_ERROR: {
            // console.log(action,'action==>')
            return {
                ...state,
                isLoading: false,

            };
        }
        default:
            return {
                ...state
            };

    }

}

export default userLoginReducer;