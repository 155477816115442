import { Get, Post, /* Put */ } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl;

const Fetch = {
  // Common
  contactUsApi(payload) {
    let url = baseUrl + `contact`;// 
    const header = {
      "Content-Type": "application/json"
    }
    return Post(JSON.stringify(payload), url, header);
  },
  logApi(payload) {
    let url = baseUrl + `log/`;// 
    const header = {
      "Content-Type": "application/json"
    }
    return Post(payload, url, header);
  },
  languageChangedApi() {
    let url = baseUrl + ``;//
    let body = {};
    return Post(JSON.stringify(body), url);
  },
  workersValidatorsList(payload) {
    let url;
    let pageNum = payload.pageNum != '' ? payload.pageNum : 1;
    url = baseUrl + `projects/tasks/pool/?limit=${10}&offset=${(pageNum * 10) - 10}`;//

    return Post(payload, url);
  },
  changePasswordApi(payload) {
    let url = baseUrl + `user/password/`;
    return Post(payload, url);
  },
  // language not done
  languageListApi() {
    let url = baseUrl + `language/`;
    return Get(url);
  },
  deviceListApi() {
    let url = baseUrl + "devices/";
    return Get(url);
  },
  // citizenship
  countryListApi() {
    let url = baseUrl + "countries/";
    return Get(url);
  },
  // country 
  countryApi() {
    let url = baseUrl + "countries/";
    return Get(url);
  },
  verifyRegisteredEmailApi(payload) {
    let url = baseUrl + "Verify";
    const header = {
      "Content-Type": "application/json"
    }
    return Post(payload, url, header);
  },
  verifyForgotPassTokenApi(payload) {
    let url = baseUrl + "user/password/verify/";

    return Post(payload, url);
  },
  userProfileUpdateApi(payload) {
    let url = baseUrl + `user/profile/`;

    return Post(payload, url);
  },
  userProfileUpdateImageApi(payload) {
    let url = baseUrl + `user/profile/image/`;

    const formData = new FormData();
    formData.append('image', payload?.image);
    const header = {
    }
    return Post(formData, url, header);

  },

  // Common 
};

export default Fetch;
