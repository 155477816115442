import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function TimelineBar({ dates }) {
    let chart;
    const { t } = useTranslation();

    useEffect(() => {
        chart = am4core.create("chartdiv2", am4charts.XYChart);
        chart.paddingRight = 20;

        chart.data = dates;
        chart.dateFormatter.inputDateFormat = "H d/M/YYYY";
        chart.dateFormatter.dateFormat = "hh a d/M";
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.baseInterval = { timeUnit: "hour", count: 1 };
        dateAxis.dateFormats.setKey('hour', 'hh a');
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;

        var series = chart.series.push(new am4charts.StepLineSeries());
        //series color
        series.stroke = am4core.color("#194c7f");
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "count";
        series.tooltipText = `{valueY.value} ${t("annotations")}`;
        series.strokeWidth = 3;
        //tooltip color
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#194c7f");

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.fullWidthLineX = true;
        chart.cursor.lineX.strokeWidth = 0;
        chart.cursor.lineX.fill = chart.colors.getIndex(2);
        chart.cursor.lineX.fillOpacity = 0.1;

        chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
        chart.zoomOutButton.background.fill = am4core.color("#194c7f");
        chart.zoomOutButton.icon.stroke = am4core.color("#FFF");
        chart.zoomOutButton.icon.strokeWidth = 2;
        chart.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#194c7f");
        chart.scrollbarX = new am4core.Scrollbar();
    }, [dates]);
    return (
        <div id="chartdiv2" style={{ width: "100%", height: "500px" }}></div>
    );
}

TimelineBar.propTypes = {
    dates: PropTypes.array
};

export default TimelineBar;