import React, { useState } from "react";
import { Layout, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { GB, SA } from 'country-flag-icons/react/3x2'
import { useTranslation } from "react-i18next";

const { Header } = Layout;



const HeaderUser = (props) => {
  const { headerClassName, handleHamburgerClick, handleLanguageChange } = { ...props };

  const { i18n } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');

  const changeLanguage = async (lng) => {
    i18n.changeLanguage(lng);
  };
  const { Option } = Select;
  const handleChange = (val) => {
    localStorage.setItem('lang', val);
    changeLanguage(val);
    handleLanguageChange(val);
    setLang(val);
    const contentsComponent = document.querySelector('section.ant-layout.new_layout');
    const siderComponent = document.querySelector('section.ant-layout.ant-layout-has-sider .ant-layout-sider');
    const sidebarMenuItemsParentComponent = document.querySelectorAll('div.sidebar-menu-items-container')
    if (val == 'ar') {
      siderComponent.setAttribute('style', 'right:0;width:250px');
      contentsComponent.setAttribute('style', 'margin-left:unset;');
      for (let i = 0; i < sidebarMenuItemsParentComponent.length; i++) {
        const ele = sidebarMenuItemsParentComponent[i];
        ele.setAttribute('style', 'width: 214px;text-align: end');
      }
    }
    else {
      siderComponent.setAttribute('style', 'right:unset;width:250px');
      contentsComponent.setAttribute('style', 'margin-right: unset;');
    }
    // isInternetConnected() && dispatch(langugaeChnaged());
  }

  return (
    <div>
      <Header
        className={headerClassName || "site-layout-sub-header-background"}
        style={{
          padding: 0
        }}
      >
        <Select
          defaultValue={
            localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
          }
          className={localStorage.user_type == '2' ? 'select_new user_new_select user_specific owner' : "select_new user_new_select user_specific user"}
          style={{
            width: 200,
            top: '26px',
            left: lang == 'ar' ? "15%" : null, //132,
            right: lang == 'ar' ? null : "15%",

          }}
          name="language"
          onChange={handleChange}
        >
          <Option key='en' className="language-dropdown" value='en'>
            <GB title="English"
              style={{
                width: '20%',
                marginRight: '10px',
                marginLeft: '10px'
              }} />
            English
          </Option>
          <Option key='ar' className="language-dropdown" value='ar'>
            <SA title="Arabic"
              style={{
                width: '20%',
                marginRight: '10px',
                marginLeft: '10px'
              }} />
            العربية
          </Option>
        </Select>
        <div className="iocns">
          <FontAwesomeIcon
            icon={faBars}
            className="lite-text"
            color="#37597c"
            onClick={handleHamburgerClick}
          />
        </div>
      </Header>
    </div>
  );
};
export default HeaderUser;
