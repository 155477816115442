import * as Yup from "yup";
import i18n from "i18next";

export const constants = {
  formikValidationSchema: (lang) => {
    switch (lang) {
      case "en":
        return Yup.object().shape({
          OldPassword: Yup.string()
            .test(
              "trim",
              i18n.t("enter_old_password"),
              (value) => value?.trim()?.length > 0
            )
            .required(i18n.t("plz_enter_password")),

          NewPassword: Yup.string()
            .test(
              "trim",
              i18n.t("enter_new_password"),
              (value) => value?.trim()?.length > 0
            )
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,20}$/,
              i18n.t("paswrd_condtn")
            )
            .required(i18n.t("plz_entr_new_pass")),
          ConfirmNewPassword: Yup.string()
            .test(
              "trim",
              i18n.t("enter_confrm_password"),
              (value) => value?.trim()?.length > 0
            )
            .oneOf(
              [Yup.ref("NewPassword"), null],
              i18n.t("confrm_must_match_wid_new")
            )
            .required(i18n.t("enter_confrm_password")),
        });
      case "ar":
        return Yup.object().shape({
            OldPassword: Yup.string()
              .test(
                "trim",
                i18n.t("enter_old_password"),
                (value) => value?.trim()?.length > 0
              )
              .required(i18n.t("plz_enter_password")),
  
            NewPassword: Yup.string()
              .test(
                "trim",
                i18n.t("enter_new_password"),
                (value) => value?.trim()?.length > 0
              )
              .required(i18n.t("plz_entr_new_pass")),
            ConfirmNewPassword: Yup.string()
              .test(
                "trim",
                i18n.t("enter_confrm_password"),
                (value) => value?.trim()?.length > 0
              )
              .oneOf(
                [Yup.ref("NewPassword"), null],
                i18n.t("confrm_must_match_wid_new")
              )
              .required(i18n.t("enter_confrm_password")),
          });
      default:
        break;
    }
  },
};
