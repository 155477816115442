import {
  AppImage, 
  NavigateTo,
  SidebarMenus, 
} from "../../../themes";
import { useFormik } from "formik";
import {
  getLanguageList,
  getWorkersValidatorsList,
  stopLoading,
} from "../../../redux/actions/Common";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { CardHoc, Container } from "../../../hoc";
import { CustomEmptyDiv } from "../../../molecules";
import appconstant from "../../../themes/appconstant";
import React, { useEffect, useRef, useState } from "react";
import { createGroup } from "../../../redux/actions/Group";
import { Table, Modal, Select, Pagination, Rate } from "antd"; 
import { customFunctions } from "../../../themes/utils/CustomFunctions";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { constants } from "../../../common/fileConstants/Owner/groupManagement";
 
// const { Option } = Select;

const AddGroup = () => {
  const children = [];
  const childrenEmail = [];
  const { Option } = Select;
  
  const grpRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addedWorkerRef = useRef(null);
  
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");  
  const [pageNum, setPageNum] = useState(1);
  const [removalId, setRemovalId] = useState("");
  // const [addedWorker, setAdded] = useState(false);
  const [ratingVal, setRatingVal] = useState(null);
  const [selectedIds, setselectedIds] = useState([]); 
  const [languageVal, setLanguageVal] = useState(null);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [currentlyActive, setCurrentlyActive] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [languageChildren, setLanguageChildren] = useState([]);
  const [addedWorkersList, setAddedWorkersList] = useState([]);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  // const [showInTableCurrActive, setShowInTableCurrActive] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0); // total workers and validators
  
  const initialValues = {
    groupName: "",
    selectedWorkers: [],
    inviteList: [],
  };
  const breadCrumbItems = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.GROUP_MANAGEMENT,
      label: t("grp_mngmnt"),
    },
    {
      link: "",
      label: t("add_new_group"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t('grp_mngmnt'),
  }; 
  const showModal = () => {
    setIsModalVisible(true);
    setMin("");
    setMax("");
    setLanguageVal(null);
    setRatingVal(null);
    setPageNum(0);
    setDefaultCurrent(1);
    // setAddedWorkersList([])
    // setselectedIds([])
  };
  const handleOk = () => {
    setIsModalVisible(false);
    // setMin('');
    // setMax('');
    // setLanguageVal(null);
    // setRatingVal(null);

    localStorage.removeItem("selectedFromPool");
    // console.log('ok')
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setPageNum(1);

    // setAddedWorkersList([]);
    // setselectedIds([])
    // setMin('');
    // setMax('');
    // setLanguageVal(null);
    // setRatingVal(null);

    // setAddedWorkersList([])
    // setselectedIds([])

    localStorage.removeItem("selectedFromPool");
  };
  const [isModalRemove, setIsModalRemove] = useState(false);
  const showModalRemove = () => {
    setIsModalRemove(true);
  };
  const handleOkRemove = () => {
    setIsModalRemove(false);
  };
  const handleCancelRemove = () => {
    setIsModalRemove(false);
  };
  const dataArray = [
    {
      name: "Micheal",
      profile: AppImage.user,
      rating: AppImage.star,
    },
    {
      name: "Micheal",
      profile: AppImage.user,
      rating: AppImage.star,
    },
    {
      name: "Micheal",
      profile: AppImage.user,
      rating: AppImage.star,
    },
  ];
  const data123 = (values_data) => {
    return (
      <div className="data_news">
        <div className="first_text">
          <img src={values_data.profile} style={{ width: "20px" }}></img>
        </div>
        <div className="first_text">{values_data.name}</div>
        <div>
          <img src={values_data.rating}></img>
        </div>
      </div>
    );
  };
  for (let i = 0; i < dataArray.length; i++) {
    children.push(<Option key={i}>{data123(dataArray[i])}</Option>);
  }
  const dataArrayEmail = [];
  const Emaildata = (values_data_new) => {
    return (
      <div className="data_news">
        <div className="first_text">{values_data_new.name}</div>
      </div>
    );
  };
  for (let i = 0; i < dataArrayEmail.length; i++) {
    childrenEmail.push(<Option key={i}>{Emaildata(dataArrayEmail[i])}</Option>);
  }   
  const workerColumns = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => index + 1, //((pageNum*10)-10)+(index + 1)
    },
    {
      title: t("profile_img"),
      dataIndex: "profile",
      key: "profile",
      render: (value) => {
        return ( 
          <img
            style={{
              width: "44px",
              height: "44px",
              borderRadius: "44px",
            }}
            // src={value!=null ? (value.startsWith('h') ? value : appconstant.imageBaseUrl+value ) : AppImage.defaultImage}
            src={
              value != null
                ? value.startsWith("h")
                  ? value
                  : value.includes("static")
                  ? AppImage.defaultImage
                  : appconstant.imageBaseUrl + value
                : AppImage.defaultImage
            }
          /> 
        );
      },
    },
    {
      title: t("user_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      //
      sorter: (a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      },
    },
    {
      title: t("rating"),
      dataIndex: "rating",
      key: "rating",
      sorter: true,
      width: 280,
      //
      sorter: (a, b) => a.rating - b.rating,
      render: (value) => {
        return ( 
          <Rate
            style={{
              fontSize: "20px",
              position: "absolute",
              top: "24px",
              right: "105px",
              maxWidth: "fit-content",
            }}
            className="edit-group-star"
            allowHalf
            value={value}
            disabled={true}
          /> 
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "Posted",
      key: "Posted",
      render: (text, record) => {
        return (
          <div>
            <button
              type="button"
              className="focus"
              onClick={() => {
                setRemovalId(record.id);
                showModalRemove();
              }}
            >
              {t("remove")}
            </button>
          </div>
        );
      },
    },
  ]; 
  const onSubmit = (values) => {
    // const totalInvitations = selectedIds.concat(values.inviteList);

    let bodyTobeSend;
    if (values.inviteList.length != 0 && selectedIds.length != 0) {
      bodyTobeSend = {
        name: values.groupName,
        members: selectedIds,
        non_users: values.inviteList,
      };
    }
    if (values.inviteList.length == 0 && selectedIds.length != 0) {
      bodyTobeSend = {
        name: values.groupName,
        members: selectedIds,
      };
    }
    if (values.inviteList.length != 0 && selectedIds.length == 0) {
      bodyTobeSend = {
        name: values.groupName,
        non_users: values.inviteList,
      };
    }
    const payloadForCreateGroup = {
      success: () => {
        toast.success(t("grp_created"), {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
          toastId: "groupAdded",
        });
        navigate(NavigateTo.GROUP_MANAGEMENT);
      },
      fail: (res) => {
        console.log(res, "res==fail");
        // if(res.result.errors.name[0]=='create group with this name already exists'){
        toast.error(t("chk_grp_name_email_exists"), {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
          toastId: "samegroupnameError",
        });
        // }
      },
      body: bodyTobeSend,
    };
    // totalInvitations.length > 0 &&
    if (selectedIds.length > 0)
      isInternetConnected() && dispatch(createGroup(payloadForCreateGroup));
    else
      toast.error(t("add_min_1_wrkr"), {
        className:
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,
        toastId: "addonegroup",
      });
    // navigate(NavigateTo.GROUP_MANAGEMENT);
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema : constants.formikValidationSchemaAG(localStorage.lang),
  });
  const testIncludes = (prevVal, passedinstring) => {
    var regex = new RegExp(prevVal.join("|"), "i");
    return regex.test(passedinstring);
  };
  const validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(localStorage.lang == 'ar' )
    return true ;
    else return re.test(email);
  };
  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
    if (formik.values.groupName != "" && formik.values.groupName == " ") {
      formik.setFieldError("groupName", t("plz_entr_grp_name"));
    }
    if (
      formik.values.groupName.length < 3 &&
      formik.values.groupName != " " &&
      formik.values.groupName != ""
    ) {
      formik.setFieldError(
        "groupName",
        t("grp_name_min_3char")
      );
    }
  }, [formik.isSubmitting, formik.errors, addedWorkersList]); 
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    const currentlyActiveRatingDropdownItems = document.querySelectorAll(
      ".ant-select-item.ant-select-item-option"
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
    if (isModalVisible && localStorage.getItem("lang") == "ar") {
      document.querySelector(".top_data_1+label.label_data").style.textAlign =
        "right";
      for (let i = 0; i < currentlyActiveRatingDropdownItems.length; i++) {
        const element = currentlyActiveRatingDropdownItems[i];
        element.style.direction = "rtl";
      }
    }
    if (isModalVisible && localStorage.getItem("lang") == "en") {
      document.querySelector(".top_data_1+label.label_data").style.textAlign =
        "left";
      for (let i = 0; i < currentlyActiveRatingDropdownItems.length; i++) {
        const element = currentlyActiveRatingDropdownItems[i];
        element.style.direction = "ltr";
      }
    }

    if (formik.values.groupName != "" && formik.values.groupName == " ") {
      formik.setFieldError("groupName", t("entr_prj_name"));
    }
  }, [isModalVisible, isModalRemove]);
  useEffect(() => {
    const payload = {
      body: {
        pageNum: languageVal || min || max ? 1 : pageNum,
        min,
        max,
        languageVal,
      },
      success: (res) => {
        setTotalUsers(res?.count);
        const modifiedRes = [];
        res?.results?.filter((item) => {
          modifiedRes.push({
            id: item.id,
            profile: item?.image
              ? item?.image.startsWith("h")
                ? item?.image
                : item?.image.includes("static")
                ? AppImage.defaultImage
                : appconstant.imageBaseUrl + item?.image
              : AppImage.defaultImage,
            name: item.first_name + " " + item.last_name,
            rating: item.average_rating || 0,
            tickTrue: false,
          });
        });
        localStorage.setItem("tempArr", JSON.stringify(res?.results));
        setCurrentlyActive(modifiedRes);
        dispatch(stopLoading());
      },
      fail: (resf) => {
        console.log(resf);
      },
    };
    isInternetConnected() && dispatch(getWorkersValidatorsList(payload));

    const payloadForLanguages = {
      success: (res) => {
        var modifiedResult = [];
        res.filter((item) => {
          modifiedResult.push({ name: item.name, value: item.id });
        });
        setLanguageChildren(modifiedResult);
      },
      fail: (err) => {
        toast(err);
      },
    };
    isInternetConnected() && dispatch(getLanguageList(payloadForLanguages));
    // let tempArr = [];
    // currentlyActive.filter((item) => {
    //   item["tickTrue"] = false;
    //   tempArr.push(item);
    // });
    // setCurrentlyActive(tempArr);
  }, [pageNum, min, max, languageVal, isModalVisible, selectedIds]); // 
  useEffect(() => {
    Object.keys(formik.errors).forEach(fieldName => {
      formik.setFieldTouched(fieldName)
    }); 
  }, [lang])
  return (
    <Container
      {...containerProps}
      languageChanged={() => {
        setLang(localStorage.getItem("lang"));
      }}
    >
      <CardHoc cardInnerPadding={"content-e"}>
        {/* add new group label */}
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
          <h6 className="text-white text-capitalize ps-3">
            {t("add_new_group")}
          </h6>
        </div>

        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="wrapper_line">
            {/* group name */}
            <div className="form-group">
              <label>{t("grp_name")}</label>
              <input
                type="text"
                id={"groupName"}
                ref={grpRef}
                onChange={formik.handleChange}
                className="form-control"
                placeholder={t("grp_name")}
                maxLength={15}
                value={customFunctions.capitalizeFirstLetter(
                  formik.values.groupName.trimLeft()
                )}
                autoComplete="off"
              />
              {formik.errors.groupName ? (
                <div
                  className={
                    localStorage.getItem("lang") == "ar"
                      ? "error ar"
                      : "error en"
                  }
                  style={{ paddingLeft: 0 }}
                >
                  {formik.errors.groupName}
                </div>
              ) : null}
            </div>

            {/* SELECT FROM CURRENTLY ACTIVE */}
            <div className="form-group new_pool">
              <label>{t("select_crntly_active")}</label>
              <input
                type="button"
                ref={addedWorkerRef}
                className="form-control"
                // placeholder="Email Addresss"
                value={t("select_crntly_active")}
                autoComplete="off"
                onClick={() => {
                  // setAdded(false);
                  showModal();
                }}
              />
            </div>

            {/* ADDED WORKERS LIST */}
            {addedWorkersList.length != 0 && (
              <div className="form-group">
                <div className="inner_box_box">
                  <Table
                    dataSource={addedWorkersList}
                    columns={workerColumns}
                    showSorterTooltip={false}
                  />
                </div>
              </div>
            )}

            {formik.errors.selectedWorkers ? (
              <div
                className={
                  localStorage.getItem("lang") == "ar" ? "error ar" : "error en"
                }
                style={{ paddingLeft: 0 }}
              >
                {formik.errors.selectedWorkers}
              </div>
            ) : null}

            {/* ADD WORKER THROUGH EMAIL EMAIL_SELECTER */}
            <div className="form-group owner-add-new-group">
              <label>{t("add_wrkr_thr_email")}</label>
              <Select
                mode="tags"
                tokenSeparators={[","]}
                id="inviteList"
                value={formik.values.inviteList}
                onChange={(val) => {
                  let prevVal = [...formik.values.inviteList];

                  let hasit =
                    val.length != 0
                      ? testIncludes(prevVal, val[val.length - 1])
                      : true;

                  if (
                    (val.length == 1 && validateEmail(val[val.length - 1])) ||
                    (hasit == false && validateEmail(val[val.length - 1]))
                  ) {
                    formik.setValues({
                      ...formik.values,
                      inviteList: val,
                    });
                  } else if (val.length === 0) {
                    formik.setValues({
                      ...formik.values,
                      inviteList: val,
                    });
                  } else {
                    // if (hasit && validateEmail(val[val.length - 1]))
                    //   toast.error(t("email_already_entered"), {
                    //     className:
                    //       localStorage.lang == "ar"
                    //         ? "Select_data ar"
                    //         : "Select_data en",
                    //     position:
                    //       localStorage.lang == "ar"
                    //         ? toast.POSITION.TOP_LEFT
                    //         : toast.POSITION.TOP_RIGHT,
                    //     toastId: "wrongEmail",
                    //   });
                    if (validateEmail(val[val.length - 1]) == false)
                      toast.error(t('entr_vld_email'), {
                        className:
                          localStorage.lang == "ar"
                            ? "Select_data ar"
                            : "Select_data en",
                        position:
                          localStorage.lang == "ar"
                            ? toast.POSITION.TOP_LEFT
                            : toast.POSITION.TOP_RIGHT,
                        toastId: "wrongEmail",
                      });
                      else {
                        let prevVal = [...formik.values.inviteList];
                        let hasitt = testIncludes(
                          val,
                          prevVal[prevVal.length - 1]
                        );

                        if (hasitt)
                          toast.error(t("email_already_entered"), {
                            className:
                              localStorage.lang == "ar"
                                ? "Select_data ar"
                                : "Select_data en",
                            position:
                              localStorage.lang == "ar"
                                ? toast.POSITION.TOP_LEFT
                                : toast.POSITION.TOP_RIGHT,
                            toastId: "wrongEmail",
                          });
                        else {
                          formik.setValues({
                            ...formik.values,
                            inviteList: val,
                          });
                        }
                      }
                  }
                }}
                onKeyDown={() => {
                  
                  if (localStorage.getItem("lang") == "ar") {
                    setTimeout(() => {
                      const ele = document.querySelectorAll(
                        ".rc-virtual-list-holder-inner"
                      );
                      ele.forEach((element) => {
                        element.classList.add("new_layout");
                        element.classList.add("ar");
                      });
                    }, 10);
                  } else {
                    const ele = document.querySelectorAll(
                      ".rc-virtual-list-holder-inner"
                    );
                    ele.forEach((element) => {
                      element.classList.remove("new_layout");
                      element.classList.remove("ar");
                    });
                  }
                }}
                className="default_text group-add-worker show-caret"
                style={{ width: "100%" }}
                placeholder={t("add_wrkr_thr_email_adrs")}
              >
                {childrenEmail}
              </Select>
            </div>

            <button
              type="submit"
              className="button-list bottom_button pt-10 focus"
            >
              {t("add")}
            </button>

            <div></div>
          </div>
        </form>

        {/* currently active modal */}
        <Modal
          title={t("crntly_active")}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          wrapClassName={
            localStorage.getItem("lang") == "ar"
              ? "poolworkers owner-group-management-add-group-ar"
              : '"poolworkers owner-group-management-add-group-en'
          }
          footer={null}
          width={646}
          centered
          className={
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en"
          }
        >
          {/* workers list with language and rating dropdown */}
          <div className="text-center  model1">
            <div className="form-group wrapper_line models">
              <div className="new_new_data">
                {/* drop downs */}
                <div className="top_data_1">
                  {/* language dropdown */}
                  <Select
                    placeholder={t("lang")}
                    onChange={(val) => {
                      setLanguageVal(val);
                    }}
                    value={languageVal}
                    onClick={() => {
                      if (localStorage.getItem("lang") == "ar") {
                        setTimeout(() => {
                          const ele = document.querySelectorAll(
                            ".rc-virtual-list-holder-inner"
                          );
                          ele.forEach((element) => {
                            element.classList.add("new_layout");
                            element.classList.add("ar");
                          });
                        }, 10);
                      } else {
                        const ele = document.querySelectorAll(
                          ".rc-virtual-list-holder-inner"
                        );
                        ele.forEach((element) => {
                          element.classList.remove("new_layout");
                          element.classList.remove("ar");
                        });
                      }
                    }}
                  >
                    {languageChildren.map((item) => {
                      return <Option  key={item} value={item.value}>{item.name}</Option>;
                    })}
                  </Select>
                  {/* rating dropdown */}
                  <Select
                    placeholder={t("rating")}
                    value={ratingVal}
                    onChange={(val) => {
                      setMin(val.split("-")[0]);
                      setMax(val.split("-")[1]);
                      setRatingVal(val);
                      if (localStorage.getItem("lang") == "ar") {
                        document.querySelectorAll(
                          ".rc-virtual-list"
                        )[0].style.direction = "rtl";
                      } else {
                        document.querySelectorAll(
                          ".rc-virtual-list"
                        )[0].style.direction = "ltr";
                      }
                    }}
                    onClick={() => {
                      if (localStorage.getItem("lang") == "ar") {
                        setTimeout(() => {
                          const ele = document.querySelectorAll(
                            ".rc-virtual-list-holder-inner"
                          );
                          ele.forEach((element) => {
                            element.classList.add("new_layout");
                            element.classList.add("ar");
                          });
                        }, 10);
                      } else {
                        const ele = document.querySelectorAll(
                          ".rc-virtual-list-holder-inner"
                        );
                        ele.forEach((element) => {
                          element.classList.remove("new_layout");
                          element.classList.remove("ar");
                        });
                      }
                    }}
                  >
                    <Option value="0-1">0-1</Option>
                    <Option value="1-2">1-2</Option>
                    <Option value="2-3">2-3</Option>
                    <Option value="3-4">3-4</Option>
                    <Option value="4-5">4-5</Option>
                  </Select>
                </div>
                {/* drop downs */}
                <label className="label_data">
                  {t("select_crntly_active")}
                </label>
                {currentlyActive.length > 0 ? (
                  <>
                    {/* select from currently active  list */}
                    <div className="data_news poolworkers_ppop">
                      {currentlyActive.map((item, index) => {
                        return (
                          // row //
                          <div
                          key={index}
                            className="sperator_pool"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const tempSelectedIds = [...selectedIds];
                              const tempAddedWorkerList = [...addedWorkersList];
                              if (tempSelectedIds.indexOf(item.id) == -1) {
                                tempSelectedIds.push(item.id);
                                tempAddedWorkerList.push(item);
                                setAddedWorkersList(tempAddedWorkerList);
                                setselectedIds(tempSelectedIds);
                              } else {
                                tempSelectedIds.splice(
                                  tempSelectedIds.indexOf(item.id),
                                  1
                                );
                                let indexx;
                                for (
                                  let index = 0;
                                  index < tempAddedWorkerList.length;
                                  index++
                                ) {
                                  const element = tempAddedWorkerList[index];
                                  if (element.id == item.id) {
                                    indexx = index;
                                    break;
                                  }
                                }
                                // let temp;
                                if (indexx > -1) {
                                  // only splice tempAddedWorkerList when item is found
                                  tempAddedWorkerList.splice(indexx, 1); // 2nd parameter means remove one item only
                                }
                                setAddedWorkersList(tempAddedWorkerList);

                                setselectedIds(tempSelectedIds);
                              }
                            }}
                          >
                            {/* worker profile img */}
                            <div className="first_text">
                              <img
                                src={item.profile}
                                style={{
                                  width: "27px",
                                  height: "27px",
                                  borderRadius: "27px",
                                }}
                              ></img>
                            </div>

                            {/* worker name */}
                            <div
                              className="first_text"
                              style={{
                                width: "215px",
                                paddingLeft: "5px",
                                textAlign: "start",
                                paddingRight: "5px",
                              }}
                            >
                              {item.name}
                            </div>

                            {/* rating  */}
                            <div>
                              <Rate
                                style={{
                                  fontSize: "20px",
                                }}
                                className="edit-group-star"
                                allowHalf
                                value={item?.rating}
                                disabled={true}
                              />
                            </div>

                            {/* tick for selected workers */}
                            {selectedIds.map((items, index) =>
                              items == item.id ? (
                                <div className="star_new" key={index}>
                                  <img src={AppImage.tick}></img>
                                </div>
                              ) : null
                            )}
                          </div>
                        );
                      })}
                    </div>

                    {/* pagination */}
                    <Pagination                
                  showTitle = {false}
                      style={{
                        paddingTop: "30px",
                      }}
                      className={
                        localStorage.lang == "ar"
                          ? "pagination-component ar"
                          : "pagination-component"
                      }
                      defaultCurrent={defaultCurrent}
                      current={pageNum}
                      total={totalUsers}
                      size="medium"
                      onChange={(item) => {
                        setPageNum(item);
                      }}
                    />
                  </>
                ) : (
                  <CustomEmptyDiv marginTop={"30px"} title={t("no_users_found")} />
                )}
              </div>
            </div>
          </div>
        </Modal>
        {/* currently active modal */}

        {/* remove modal */}
        <Modal
          title={t("remove")}
          open={isModalRemove}
          onOk={handleOkRemove}
          onCancel={handleCancelRemove}
          footer={null}
          centered
        >
          {/* remove title */}
          <div className="text-center  model1">
            <p>{t("u_sure_to_rmv_this_usr")}</p>
          </div>
          <div
            className="text-center  model1"
            style={{
              direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
            }}
          >
            {/* yes button */}
            <button
              type="button"
              className="button-list top_new focus"
              onClick={() => {
                const tempSelectedIds = [...selectedIds];
                const tempAddedWorkerList = [...addedWorkersList];
                tempSelectedIds.splice(tempSelectedIds.indexOf(removalId), 1);

                let indexx;
                for (
                  let index = 0;
                  index < tempAddedWorkerList.length;
                  index++
                ) {
                  const element = tempAddedWorkerList[index];
                  if (element.id == removalId) {
                    indexx = index;
                    break;
                  }
                }
                // let temp;
                if (indexx > -1) {
                  // only splice tempAddedWorkerList when item is found
                  tempAddedWorkerList.splice(indexx, 1); // 2nd parameter means remove one item only
                }
                setAddedWorkersList(tempAddedWorkerList);
                // setShowInTableCurrActive(tempAddedWorkerList);

                setselectedIds(tempSelectedIds);
                handleCancelRemove(false);
              }}
            >
              {t("yes")}
            </button>
            {/* no button */}
            <button
              type="button"
              className="focus"
              onClick={() => handleCancelRemove(false)}
            >
              {t("no")}
            </button>
          </div>
        </Modal>
        {/* remove modal */}
      </CardHoc>
    </Container>
  );
};

export default AddGroup;
