import { Get, Put } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl;

const Fetch = {
  // NOTIFICATION
  // done
  userNotificationApi(payload) {
    let url = baseUrl + `user/notification/?limit=${10}&offset=${(payload?.pageNum * 10) - 10}&type=${payload?.type}`;
    return Get(url, true);
  },

  // below 2 are optional in case the api comes individually
  acceptRejectNotificationApi(payload) {
    let url = baseUrl + `user/task_invite/${payload.notificationId}/`;
    return Put({
      is_accepted: payload.is_accepted ? "accepted" : "rejected",
    }, url);
  },
  // NOTIFICATION
};

export default Fetch;
