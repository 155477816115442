import Fetch from "../Fetch/ProjectApi";
import { toast } from "react-toastify";
import { put, call } from "redux-saga/effects";
import { startLoading, stopLoading } from "../actions/Common";
import { customFunctions } from "../../themes/utils/CustomFunctions";
import i18n from 'i18next';
// owner
export function* postOwnerCreateNewProjectSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.ownerCreateNewProjectApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* addNewTaskToProjectSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.addNewTaskToProjectApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getOwnerOngoingProjectListsSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getOngoingProjectListsApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getOwnerPastProjectListsSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getPastProjectListsApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getProjectDetailsSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    // yield delay(500);
    yield put(startLoading());
    const result = yield call(Fetch.getProjectDetailsApi, body);
    // const result = yield call(FetchTask.getWorkerPendingTaskApi,body); 
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
// owner dash quick link proj table
export function* getOwnerDashQuickLinkProjSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getOwnerDashQuickLinkProjApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
// owner dash quick link total tasks table
export function* getOwnerDashQuickLinkTotalTaskSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getOwnerDashQuickLinkTotalTaskApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}

// owner

// admin

export function* getAdminProjectListsSaga({ payload: { body, success = () => { }, fail = () => { } } = {}, }) {
  try {

    yield put(startLoading());
    const result = yield call(Fetch.getProjectListsAdminApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
  }
}

export function* deleteProjectSaga({ payload }) {
  try {
    // yield delay(500);
    yield put(startLoading());
    const result = yield call(Fetch.deleteProjectAdminApi, payload);
    if (result.status === 1) {

      customFunctions.showToast(result?.result?.data, true, 'projdeletesuccess');

      yield put(stopLoading());
    } else {
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
export function* exportDataSaga({ payload: {
  body,
  success = () => { },
  fail = () => { },
} = {
  } }) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.exportDataApi, body);
    // console.log(result);
    if (result.status === 1) {

      customFunctions.showToast(i18n.t('file_exported'), true, 'dataExporteddd');
      success(result?.result);
      yield put(stopLoading());
    } else {
      toast.error(result?.result?.message, {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        toastId: "wrongFile",
      });
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
  }
}

// admin


