import { Link } from "react-router-dom";
import images from "../../../themes/appImage";
import React, { useEffect, useState } from "react";
import { HeaderLanding } from "../../../layout/LandingModule";
import { Constants, NavigateTo, ValidationConstants } from "../../../themes";
import { CustomFooter } from "../../../molecules";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { contactUs } from "../../../redux/actions/Common";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { customFunctions } from "../../../themes/utils/CustomFunctions";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Landing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sticky = false;
  const isVisible = false;
  const [toogled, setToogle] = useState(false);
  // const isLoading = useSelector(state=>state?.common?.loading);
  const handletoogle = () => {
    setToogle(!toogled);
  };
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const initialValues = {
    email: "",
    msg: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .test(
        "trim",
        t(ValidationConstants.reqEmail),
        (value) => value?.trim()?.length > 0
      )
      .matches(
        /^(([a-zA-Z0-9]+)||([a-zA-Z0-9_.-]+[a-zA-Z0-9]+))@([0-9a-zA-Z]+)([^0-9])([0-9a-zA-Z]+)||([a-zA-Z0-9])(\.[a-zA-z]{2,5})$/,

        t(ValidationConstants.valEmail)
      )
      .email(t(ValidationConstants.valEmail))
      .max(320, t("Maximum 320 characters are allowed."))
      .required(t(ValidationConstants.reqEmail)),
    msg: Yup.string()
      .matches(
        /^[-a-zA-Z0-9-()]+([-a-zA-Z0-9-(),'"?/!@.\s]+)*$/,

        t("Please enter message.")
      )
      .required(t("Please enter message."))
      .max(500, t("Maximum 500 charachters are allowed."))
      .min(20, t("Message must be minimum 20 characters long.")),
  });
  const onSubmit = () => {
    const payload = {
      email: formik.values.email,
      msg: formik.values.msg,
    };

    isInternetConnected() && dispatch(contactUs(payload));
    setTimeout(() => {
      formik.resetForm();
    }, 5000);

  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "WosoM";
  }, []);

  return (
    <div>
      <Helmet>
        <meta property="og:title" content="WosoM Annotation platform" />
        <meta property="og:description" content="WosoM annotation platform for AI based data annotation and processing" />
        <meta property="og:image" content="https://beta.wosom.ai/static/media/helmet.png" />
        <meta property="og:url" content="https://beta.wosom.ai" />
      </Helmet>
      <div className="landing-page">
        <HeaderLanding handle={handletoogle} toogled={toogled} sticky={sticky} />

        {/* Hero Section  */}
        <section className="slider">
          <div className="container">
            <div className="row">
              <div className="Smackem-details">
                <h2 className="card" style={{
                  margin: "10px",
                  background: " #143c6ee6"
                }}>{Constants.HeroSectionHeader}</h2>
                <p
                  style={{
                    margin: "10px",
                    background: " #143c6ee6"
                  }}
                  className="card heroheader"
                >{Constants.HeroSectionDescription}</p>

                <div className="common_btn" style={{ visibility: "hidden" }}>
                  <button type="button" className="btn btn-primary focus">
                    {Constants.LearnMore}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Hero Section  */}

        {/* Application Main Features Section  */}
        <section className="screenshot">
          <div className="container">
            <div className="row align-item-center">
              <h2 className="heading  slideInUp wow features">
                <img src={images.leftimg12} />
                {Constants.AppplicationMainFeatureHeading}
                <img src={images.rightimg12} />
              </h2>
              <div className="d-flex center_data ">
                <div className="d-flex w-100 justify-content-center appss">
                  <div className="mobile_circle">
                    <img src={images.Illustration} />
                  </div>
                </div>
                <div className="text-center slideInDown  wow w-100 text-left">
                  <p
                    className="paraP"

                  >
                    {Constants.AppplicationMainFeatureDescription}
                  </p>
                  <div className="read2">
                    {/* <a class="nav-link login_button" href="#"> Read More</a> */}
                    <Link class="nav-link login_button" to={NavigateTo.FEATURES}>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Application Main Features Section  */}

        {/* About Us Section  */}
        <section className="aboutus data_about_us" id="about_usone">
          <div className="container">
            <div className="row align-item-center">
              <h2
                className="heading  slideInUp wow"
                style={{ marginTop: "50px" }}
              >
                <img src={images.leftimg12}></img>
                {Constants.AboutUs}
                <img src={images.rightimg12} />
              </h2>
              <div className="d-flex center_data center_data">
                <div className="text-center slideInDown  wow w-100 text-left">
                  <p
                    className="paraP"

                  >
                    {Constants.AboutUsDescription}
                  </p>
                  <div
                    className="read"
                    style={{
                      marginLeft: "40px",
                    }}
                  >
                    <Link class="nav-link login_button" to={NavigateTo.SERVICES}>
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-center appss">
                  <div className="mobile_circle">
                    {/* <img src={images.aboutimg} /> */}
                    <img className="about-us-img" src={images.aboutimg} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* About Us Section  */}

        {/* Contact Us Section  */}
        <section className="contact_us data_new" id="contactusone">
          <div className="container">
            <div className="row align-item-center slideInDown wow">
              <h2 className="heading  slideInUp wow abou_heading">
                <img src={images.leftimg12}></img>
                {Constants.contactus}
                <img src={images.rightimg12} />
              </h2>
              <div className="contact_us_box_new">
                <div className="wrapper_line password_size">
                  <form noValidate onSubmit={formik.handleSubmit}
                  // onReset={formik.resetForm()}
                  >
                    <div className="form-group">
                      <label>{Constants.EmailAddress}</label>
                      <input
                        autoComplete="off"
                        id="email"
                        type="text"
                        className="form-control"
                        placeholder="Email Address"
                        maxLength={320}
                        value={formik.values.email.trimLeft()}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label>{Constants.Message}</label>
                      <textarea
                        type="text"
                        id={"msg"}
                        // id: fieldIds.ADDRESS,
                        // fieldLabel: t("address"),
                        value={
                          formik.values.msg &&
                          customFunctions.capitalizeFirstLetter(
                            formik.values.msg.trimLeft()
                          )
                        }
                        onChange={formik.handleChange}
                        // inputMaxLength='500'
                        className="form-control"
                        placeholder={Constants.Message}
                        style={{ height: "142px" }}
                      />
                      {formik.errors.msg ? (
                        <div className="error">{formik.errors.msg}</div>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      className="button-list bottom_button pt-10 focus"
                    >
                      {Constants.Send}
                    </button>
                  </form>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Contact Us Section  */}

        {/* Footer Section  */}
        <CustomFooter />
        {/* Footer Section  */}

        {isVisible && (
          <div onClick={scrollToTop}>
            <a href="#" className="scrollup"></a>
          </div>
        )}
      </div>
    </div >
  );
};

export default Landing;
