import {
  LANGUAGE_CHANGED,
  CHANGE_PASSWORD,
  SEARCH_VALUE,
  GET_COUNTRY,
  WORKERS_VALIDATORS_LIST,
  DISABLE_PROFILE_COMPLETION,
  VERIFY_REGISTERED_EMAIL,
  VERIFY_FORGOT_TOKEN,
  START_LOADING,
  STOP_LOADING,
  GET_LANGUAGE_LIST,
  GET_DEVICE_LIST,
  GET_COUNTRY_LIST,
  SEARCH_DATE,
  DISPUTE_LIST_START_LOADING,
  PASS_SUCESS,
  LOGOUT,
  SET_IMAGE_LOADING,
  CONTACT_US,
  LOG
} from "../actionTypes/actionTypes";

export const logout = (payload) => {
  return {
    type: LOGOUT,
    payload,
  };
};
export const langugaeChnaged = (payload) => {
  return {
    type: LANGUAGE_CHANGED,
    payload,
  };
};

export const getWorkersValidatorsList = (payload) => {
  return {
    type: WORKERS_VALIDATORS_LIST,
    payload,
  };
};

export const changePassword = (payload, navigate) => {
  return {
    type: CHANGE_PASSWORD,
    payload,
    navigate,
  };
};
export const passSuccess = () => {
  return {
    type: PASS_SUCESS,
  };
};
export const startLoading = () => {
  return {
    type: START_LOADING,
  };
};
export const stopLoading = () => {
  return {
    type: STOP_LOADING,
  };
};
export const profilestartLoading = () => {
  return {
    type: "PROFILE_START_LOADING",
  };
};
export const profilestopLoading = () => {
  return {
    type: "PROFILE_STOP_LOADING",
  };
};
export const disputeListStartLoading = () => {
  return {
    type: DISPUTE_LIST_START_LOADING,
  };
};
export const disputeListStopLoading = () => {
  return {
    type: DISPUTE_LIST_START_LOADING,
  };
};
export const taskstartLoading = () => {
  return {
    type: "TASK_START_LOADING",
  };
};
export const taskstopLoading = () => {
  return {
    type: "TASK_STOP_LOADING",
  };
};
export const getLanguageList = (payload) => {
  return {
    type: GET_LANGUAGE_LIST,
    payload,
  };
};

export const getDevices = (payload) => {
  return {
    type: GET_DEVICE_LIST,
    payload,
  };
};
// citizenship
export const getCountries = (payload) => {
  return {
    type: GET_COUNTRY_LIST,
    payload,
  };
};
// country
export const getCountry = (payload) => {
  return {
    type: GET_COUNTRY,
    payload,
  };
};
export const verifyRegisteredEmail = (payload) => {
  return {
    type: VERIFY_REGISTERED_EMAIL,
    payload,
  };
};
export const verifyForgotToken = (payload) => {
  return {
    type: VERIFY_FORGOT_TOKEN,
    payload,
  };
};

export const disableProfileCompletion = (payload) => {
  return {
    type: DISABLE_PROFILE_COMPLETION,
    payload,
  };
};
export const saveSearchValue = (payload) => {
  return {
    type: SEARCH_VALUE,
    payload,
  };
};
export const saveSearchDate = (payload) => {
  return {
    type: SEARCH_DATE,
    payload,
  };
};

export const setImageLoading = (payload) => {
  return {
    type: SET_IMAGE_LOADING,
    payload,
  };
};
export const contactUs = (payload) => {
  return {
    type: CONTACT_US,
    payload,
  };
};
export const log = (payload) => {
  return {
    type: LOG,
    payload,
  };
};
