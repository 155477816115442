import {
    USER_SET_PASSWORD_ERROR, USER_SET_PASSWORD_LOAD, USER_SET_PASSWORD_SUCCESS, USER_RESET_PASSWORD_LOAD,
    USER_RESET_PASSWORD_SUCCESS,
    LOGOUT
} from "./../../actionTypes/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    status: 0,
    urlStatus: null,
    result: [],
    dataValue: true,
    data: {},
    isShow: null
}

const setPassReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
                isLoading: false,
                error: null,
                status: 0,
                urlStatus: null,
                result: [],
                dataValue: true,
                data: {},
                isShow: null
            };
        case USER_RESET_PASSWORD_LOAD:
            return { ...state, isShow: null, isLoading: true, }
        case USER_RESET_PASSWORD_SUCCESS:
            return { ...state, isShow: action.result, isLoading: false, }
        case USER_SET_PASSWORD_LOAD:
            return { ...state, isLoading: true, error: null, }
        case USER_SET_PASSWORD_SUCCESS:
            let status = action?.result?.success
            return {
                ...state,
                result: action.result,
                urlStatus: status,
                status: action.status,
                data: action.result.data,
                isShow: true,
                isLoading: false,
            }
        case USER_SET_PASSWORD_ERROR:
            return {
                ...state,
                isLoading: false,
                urlStatus: false,
                isShow: false,

            }
        default:
            return state;

    }
}

export default setPassReducer;