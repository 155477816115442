/*

1. For Dropdown :
    * selectTrue,dropdownPlaceholder,selectOptions  
      are Mandatory props
    * Prop types
        {
            selectTrue : true, // Boolean to show dropdown or not
            dropdownPlaceholder : Constants.Placeholder_Name, // String
            selectOptions: [{{
                    name:'Abc',
                    value:'abc' 
                }}]
        }
2. For Input Field
    * inputTrue,id,type,value,placeholder,onChange
      are Mandatory props
    * onClick,isDisabled,customInputStyle,customLabelStyle,isAutoComplete 
      are optional props
    * Prop types
        {
            id : fieldIds.FIELD_NAME, // String ctrl+u
            type : 'text/button', // String (input field type),
            onChange : ()=> formik.handleChange, // callback for input field
            formik: formik, // remove
                    | |
                    \ /
                    useFormik({
                        initialValues,
                        validationSchema,
                        onSubmit, 
                        validate 
                    })
            inputTrue : true, // Boolean
            value : formik.values.field_name , // ctrl+l
            placeholder : Constants.Palceholder_Name, // String alt+t ,
            
            customInputStyle : {},
            isAutoComplete : false, // Boolean,
            onClick : ()=>{

            } //Callback Function,  
            labels : labels, // [{labelTitle:'',customLabelStyle:{}}] multiple labels 
            customLabelStyle : {}, // custom style for labels
            isDisabled : true , // Boolean for not taking input values
       }
 3. For TextArea Multiline
      * textAreaTrue,tar,tac
        are Mandatory props
      * Prop types 
        textAreaTrue : true, // Boolean
        tar : '50', // Number String for textArea Row
        tac : '4', // Number String for textArea Column

 4. For Error Field 
      * error 
        are Mandatory props
      * Prop types 
         error : formik.errors.firstName

 5. For Form Field Labels
      * labelTitle
        are Mandatory props
      * Prop types
         labelTitle : String
*/
import { Select } from "antd";
import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { CustomHelp } from '../index'

const TextField = ({
  id,
  type,
  tac,
  tar,
  value = "",
  error,
  child,
  labels = [],
  onChange = () => { },
  isDisabled = false,
  labelTitle = null,
  labelTooltop = null,
  labelPopover = null,
  labelModal = null,
  inputTrue = false,
  inputClassName = "",
  selectTrue = false,
  onClick = () => { },
  customLabelStyle = {},
  textAreaTrue = false,
  customInputStyle = {},
  labelIcon = null,
  isAutoComplete = "none",
  placeholder = "dummy place holder",
  // onClickLabelIcon = () => { },
  fieldIcons = [],
  onLabelIconTap = () => { },
  selectMode = null,
  selectDefaultValue = "",
  selectOptions = [{ name: "Dummy name", value: "Dummy Value" }],
  selectOnChange = () => { },
  errorClassName,
  inputMaxLength,
  onKeyDownTrue,
  showSearch,
  onTextChange,
  // toolTipMsg,
  selectMaxLength,
  childLength,
  setValAvail,
  refVal,
}) => {
  const [disableDownArrow, setDisableDownArrow] = useState(false);
  const [disableUpperArrow, setDisableUpperArrow] = useState(false);
  // const { t } = useTranslation();

  return (
    selectOptions != undefined && (
      <div
        className="form-group"
        style={{
          position: "relative",
          overflowX:
            labelTitle == "Skills" ? (childLength > 2 ? "scroll" : null) : null,
        }}
      >
        <CustomHelp
          title={labelTitle}
          labelRef={refVal}
          labels={labels}
          customStyle={customLabelStyle}
          icon={labelIcon}
          onLabelIconTap={onLabelIconTap}
          tooltip={labelTooltop}
          popover={labelPopover}
          modal={labelModal}
        />

        {inputTrue ? (
          <input
            id={id}
            type={type}
            value={value}
            onClick={onClick}
            onChange={onChange || onTextChange}
            className={error ? `form-control ${errorClassName} ${inputClassName}` : `form-control ${inputClassName}`}
            style={customInputStyle}
            placeholder={placeholder}
            autoComplete={isAutoComplete}
            disabled={isDisabled}
            maxLength={inputMaxLength}
            onKeyDown={(e) => {
              if (type === "number" && e.key === ".") {
                e.preventDefault();
              }
              if ((e.key === " " || e.key === "Meta") && onKeyDownTrue) {
                e.preventDefault();
              }
            }}
          />
        ) : null}
        {textAreaTrue ? (
          <textarea
            id={id}
            rows={tar}
            cols={tac}
            type={type}
            value={value}
            onClick={onClick}
            onChange={onChange}
            className={error ? `form-control ${errorClassName} ${inputClassName}` : `form-control ${inputClassName}`}
            style={customInputStyle}
            placeholder={placeholder}
            autoComplete={isAutoComplete}
            disabled={isDisabled}
            maxLength={selectMaxLength}
          />
        ) : null}
        {selectTrue ? (
          <Select
            id={id}
            showSearch={showSearch} // for search
            size="large"
            value={value}
            mode={selectMode}
            style={{ width: "100%" }}
            defaultValue={selectDefaultValue}
            className={error ? `language-dropdown ${errorClassName} ${inputClassName}` : `language-dropdown ${inputClassName}`}
            onClick={() => {
              // console.log(localStorage.getItem("lang"), 'lang');
              if (localStorage.getItem("lang") == "ar") {
                setTimeout(() => {
                  const ele = document.querySelectorAll(
                    ".rc-virtual-list-holder-inner"
                  );
                  console.log(ele, 'ele');
                  ele.forEach((element) => {
                    element.classList.add("new_layout");
                    element.classList.add("ar");
                  });
                }, 10);
              } else {
                const ele = document.querySelectorAll(
                  ".rc-virtual-list-holder-inner"
                );
                ele.forEach((element) => {
                  element.classList.remove("new_layout");
                  element.classList.remove("en");
                });
              }
            }}
            onChange={(value, option) => {
              selectOnChange(value, option, labelTitle);
            }}
            onKeyDown={() => {
              if (localStorage.getItem("lang") == "ar") {
                setTimeout(() => {
                  const ele = document.querySelectorAll(
                    ".rc-virtual-list-holder-inner"
                  );
                  ele.forEach((element) => {
                    element.classList.add("new_layout");
                    element.classList.add("ar");
                  });
                }, 10);
              } else {
                const ele = document.querySelectorAll(
                  ".rc-virtual-list-holder-inner"
                );
                ele.forEach((element) => {
                  element.classList.remove("new_layout");
                  element.classList.remove("ar");
                });
              }
            }}
            placeholder={placeholder}
            disabled={isDisabled}
            // onSearch={(value) => console.log(value, "value searched select")} // for search  optional
            optionFilterProp="children" // for search
          >
            {selectOptions.map((items, index) => {
              return (
                <Select.Option
                  key={index}
                  style={{
                    backgroundColor: "transparent",
                  }}
                  value={items.value}
                >
                  {items.name}
                </Select.Option>
              );
            })}
          </Select>
        ) : null
        }
        {child && child}
        {
          error ? (
            <div className={"error"}>{error}</div>
          ) : null
        }
        {/* {errorMessage ? <div className="error">{errorMessage}</div> : null} */}
        {
          fieldIcons.length ? (
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                top: "-63px",
              }}
            >
              {/* Availability upper Arrow */}
              <div
                className="arrow-icon"
                style={{
                  position: "absolute",
                  right: "25px",
                  display: "inlineFlex",
                  cursor: disableUpperArrow ? "unset" : "pointer",
                }}
                onClick={() => {
                  const el = document.getElementsByClassName("id-control");
                  if (el.length > 0) {
                    setDisableDownArrow(false);
                    const val = el[0].value;
                    let num = Number(val.split(" ")[0]);//.split(" ")[0]
                    if (num >= 59) {
                      console.log('Number(num + 1)');
                      setDisableUpperArrow(true);
                      setValAvail(`60`);
                      document.getElementsByClassName("id-control")[0].value =
                        `60`;
                    } else {
                      console.log(Number(num + 1), 'Number(num + 1)');

                      let val = Number(num + 1);
                      document.getElementsByClassName("id-control")[0].value =
                        val;
                      setValAvail(val);
                    }
                  }
                }}
              >
                <img
                  style={{ opacity: disableUpperArrow ? 0.3 : null }}
                  src={fieldIcons[0]}
                />
              </div>
              {/* Availability upper Arrow */}

              {/* Availability down Arrow */}
              <div
                className="arrow-icon"
                style={{
                  position: "absolute",
                  display: "inlineFlex",
                  top: "12px",
                  right: "25px",
                  cursor: disableDownArrow ? "unset" : "pointer",
                }}
                onClick={() => {
                  const el = document.getElementsByClassName("id-control");
                  if (el.length > 0) {
                    setDisableUpperArrow(false);
                    const val = el[0].value;
                    const num = Number(val.split(" ")[0]);

                    if (num == 20) {
                      setDisableDownArrow(true);
                      setValAvail(`20`);

                      document.getElementsByClassName("id-control")[0].value =
                        `20`;
                    } else {
                      let val = Number(num - 1);
                      document.getElementsByClassName("id-control")[0].value =
                        val;
                      setValAvail(val);
                    }
                  }
                }}
              >
                <img
                  style={{ opacity: disableDownArrow ? 0.3 : null }}
                  src={fieldIcons[1]}
                />
              </div>
              {/* Availability down Arrow */}
            </div>
          ) : null
        }
      </div >
    )
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  tac: PropTypes.string,
  tar: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  child: PropTypes.any,
  labels: PropTypes.array,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  labelTitle: PropTypes.string,
  labelTooltop: PropTypes.string,
  labelPopover: PropTypes.object,
  labelModal: PropTypes.object,
  inputTrue: PropTypes.bool,
  inputClassName: PropTypes.string,
  selectTrue: PropTypes.bool,
  onClick: PropTypes.func,
  customLabelStyle: PropTypes.object,
  textAreaTrue: PropTypes.bool,
  customInputStyle: PropTypes.object,
  labelIcon: PropTypes.any,
  isAutoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  onClickLabelIcon: PropTypes.func,
  fieldIcons: PropTypes.array,
  onLabelIconTap: PropTypes.func,
  selectMode: PropTypes.string,
  selectDefaultValue: PropTypes.string,
  selectOptions: PropTypes.array,
  selectClassname: PropTypes.string,
  selectOnChange: PropTypes.func,
  errorClassName: PropTypes.string,
  inputMaxLength: PropTypes.number,
  onKeyDownTrue: PropTypes.bool,
  showSearch: PropTypes.bool,
  onTextChange: PropTypes.func,
  toolTipMsg: PropTypes.string,
  selectMaxLength: PropTypes.number,
  childLength: PropTypes.number,
  setValAvail: PropTypes.func,
  refVal: PropTypes.any,
};

export default TextField;
