import {
  LOGOUT,
  SAVE_TASKID,
  SAVE_VALIDATOR_COMPLETED_TASKS,
  SAVE_VALIDATOR_PENDING_TASKS,
  SAVE_WORKER_COMPLETED_TASKS,
  SAVE_WORKER_PENDING_TASKS,
  SAVE_ITR,
SAVE_CLASS_NAMES,
SAVE_IMAGE_ARRAY,
} from "./../../actionTypes/actionTypes";

const initialState = {
  validatorCompletedTasks: [],
  validatorPendingTasks: [],
  workerPendingTasks: [],
  workerCompletedTasks: [],
  taskId: null,
  imageArray :   JSON.parse(localStorage.getItem("imageArray")) || [],
  classNames : [],
  itr:  ""
};

const userTaskManagement = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        validatorCompletedTasks: [],
        validatorPendingTasks: [],
        workerPendingTasks: [],
        workerCompletedTasks: [], 
        taskId:null,
        imageArray :   JSON.parse(localStorage.getItem("imageArray")) || [],
        classNames : [],
        itr: ''
      };
    case SAVE_ITR:
      return {
        ...state, 
        itr: action.payload,
      };
    case SAVE_CLASS_NAMES:
      return {
        ...state, 
        classNames: action.payload,
      };
    case SAVE_IMAGE_ARRAY:
      return {
        ...state, 
        imageArray: action.payload,
      };
    case SAVE_TASKID:
      return {
        ...state, 
        taskId: action.payload,
      };
    case SAVE_VALIDATOR_COMPLETED_TASKS: {
      return {
        ...state,
        validatorCompletedTasks: action.payload,
      };
    }
    case SAVE_VALIDATOR_PENDING_TASKS: {
      return {
        ...state,
        validatorPendingTasks: action.payload,
      };
    }
    case SAVE_WORKER_COMPLETED_TASKS: {
      return {
        ...state,
        workerCompletedTasks: action.payload,
      };
    }
    case SAVE_WORKER_PENDING_TASKS: {
      return {
        ...state,
        workerPendingTasks: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default userTaskManagement;
