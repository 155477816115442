import { Modal, Pagination, Rate, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomEmptyDiv } from "../../../../../../molecules";
import { AppImage } from "../../../../../../themes";
const { Option } = Select;

const CurrentlyActiveValidators = (props) => {
  const {
    isModalVisibleWorker,
    setLanguageVal = () => { },
    totalUsers,
    languageChildren,
    ratingVal,
    setMin = () => { },
    setMax = () => { },
    setRatingVal = () => { },
    currentlyActive,
    selectedIds,
    showValidatorLists,
    setShowValidatorList = () => { },
    languageVal,
    setselectedIds = () => { },
    setPageNumUser = () => { },

    hideModal = () => { },
  } = { ...props };
  const { t } = useTranslation();
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isModalVisibleWorker]);

  return (
    <Modal
      title={t("crntly_active")}
      open={isModalVisibleWorker}
      onOk={() => {
        hideModal(false);
        localStorage.removeItem("selectedFromPool");
      }}
      onCancel={() => {
        hideModal(false);
        localStorage.removeItem("selectedFromPool");
      }}
      wrapClassName="poolworkers"
      // className="currently-active Select_data"
      footer={null}
      width={646}
      // className="Select_data"
      className={localStorage.lang == 'ar' ? 'currently-active Select_data ar' : 'currently-active Select_data en'}
      centered
    >
      <div className="text-center  model1">
        <div className="form-group wrapper_line models">
          {/* Select From Currently Active */}
          <div className="new_new_data">
            <div className="top_data_1">
              <Select
                placeholder={t("lang")}
                onChange={(val) => {
                  setLanguageVal(val);
                }}
                value={languageVal}
                onClick={() => {
                  if (localStorage.getItem("lang") == "ar") {
                    setTimeout(() => {
                      const ele = document.querySelectorAll(
                        ".rc-virtual-list-holder-inner"
                      );
                      ele.forEach((element) => {
                        element.classList.add("new_layout");
                        element.classList.add("ar");
                      });
                    }, 10);
                  } else {
                    const ele = document.querySelectorAll(
                      ".rc-virtual-list-holder-inner"
                    );
                    ele.forEach((element) => {
                      element.classList.remove("new_layout");
                      element.classList.remove("ar");
                    });
                  }
                }}
              >
                {languageChildren.map((item) => {
                  return <Option key={item} value={item.value}>{item.name}</Option>;
                })}
              </Select>
              <Select
                placeholder={t("rating")}
                value={ratingVal}
                onChange={(val) => {
                  setMin(val.split("-")[0]);
                  setMax(val.split("-")[1]);
                  setRatingVal(val);
                  if (localStorage.getItem("lang") == "ar") {
                    document.querySelectorAll(
                      ".rc-virtual-list"
                    )[0].style.direction = "rtl";
                  } else {
                    document.querySelectorAll(
                      ".rc-virtual-list"
                    )[0].style.direction = "ltr";
                  }
                }}
                onClick={() => {
                  if (localStorage.getItem("lang") == "ar") {
                    setTimeout(() => {
                      const ele = document.querySelectorAll(
                        ".rc-virtual-list-holder-inner"
                      );
                      ele.forEach((element) => {
                        element.classList.add("new_layout");
                        element.classList.add("ar");
                      });
                    }, 10);

                  } else {
                    const ele = document.querySelectorAll(
                      ".rc-virtual-list-holder-inner"
                    );
                    ele.forEach((element) => {
                      element.classList.remove("new_layout");
                      element.classList.remove("ar");
                    });
                  }
                }}
              >
                <Option value="0-1">0-1</Option>
                <Option value="1-2">1-2</Option>
                <Option value="2-3">2-3</Option>
                <Option value="3-4">3-4</Option>
                <Option value="4-5">4-5</Option>
              </Select>
            </div>
            <label className="label_data">
              {t("select_crntly_active")}
            </label>
            {/* select from currently active  */}
            {currentlyActive.length > 0 ? (
              <>
                <div className="data_news poolworkers_ppop">
                  {currentlyActive.map((item, index) => {
                    return (
                      // row //
                      <div
                        key={index}
                        className="sperator_pool"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const tempSelectedIds = [...selectedIds];
                          const tempAddedWorkerList = [...showValidatorLists];
                          if (tempSelectedIds.indexOf(item.id) == -1) {
                            tempSelectedIds.push(item.id);

                            tempAddedWorkerList.push(item);
                            setShowValidatorList(tempAddedWorkerList);
                            // setTempValidatorList(tempAddedWorkerList);

                            setselectedIds(tempSelectedIds);
                          } else {
                            tempSelectedIds.splice(
                              tempSelectedIds.indexOf(item.id),
                              1
                            );

                            let indexx;
                            for (
                              let index = 0;
                              index < tempAddedWorkerList.length;
                              index++
                            ) {
                              const element = tempAddedWorkerList[index];
                              if (element.id == item.id) {
                                indexx = index;
                                break;
                              }
                            }
                            // const index = uar.indexOf(5);
                            // let temp;
                            if (indexx > -1) {
                              // only splice tempAddedWorkerList when item is found
                              tempAddedWorkerList.splice(indexx, 1); // 2nd parameter means remove one item only
                            }
                            setShowValidatorList(tempAddedWorkerList);
                            // setTempValidatorList(tempAddedWorkerList);

                            setselectedIds(tempSelectedIds);
                          }
                        }}
                      >
                        {/* worker profile img */}
                        <div className="first_text">
                          <img
                            src={item.profile}
                            style={{
                              width: "27px",
                              height: "27px",
                              borderRadius: "27px",
                            }}
                          ></img>
                        </div>

                        {/* worker name */}
                        <div
                          className="first_text"
                          style={{
                            width: "215px",
                            paddingLeft: "5px",
                            textAlign: "start",
                            paddingRight: "5px",
                          }}
                        >
                          {item.name}
                        </div>

                        {/* rating  */}
                        <div>
                          <Rate
                            style={{
                              fontSize: "20px",
                            }}
                            className="edit-group-star"
                            allowHalf
                            value={item.rating}
                            disabled={true}
                          />
                          {/* <img src={AppImage.star}></img> */}
                        </div>

                        {/* tick for selected workers */}
                        {selectedIds.map((items, index) =>
                          items == item.id ? (
                            <div key={index} className={localStorage.getItem('lang') == 'en' ? "star_new" : "star_new ar"}>
                              <img src={AppImage.tick}></img>
                            </div>
                          ) : null
                        )}
                      </div>
                    );
                  })}
                </div>
                <Pagination
                  showTitle={false}
                  className={localStorage.lang == 'ar' ? "pagination-component ar" : "pagination-component"}

                  style={{
                    // paddingTop: "30px",
                    left: "0",
                    right: "0",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingTop: "30px",
                    maxWidth: "fit-content",
                    marginTop: "24px",
                  }}
                  // className="pagination-component"
                  defaultCurrent={1}
                  total={totalUsers} //totalUsers
                  size="medium"
                  onChange={(item) => {
                    setPageNumUser(item);
                  }}
                />
              </>
            ) : (
              <CustomEmptyDiv marginTop={"30px"} title={t("no_user_found")} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CurrentlyActiveValidators;
