import {
    USER_CHANGE_PASSWORD_LOAD,
    USER_DASHBOARD_LOAD,
    USER_ENABLE_DISABLE_LOAD,
    USER_FORGET_LOAD,
    USER_LOGIN_LOAD, 
    USER_REGISTER_LOAD,
    USER_RESET_PASSWORD_LOAD,
    USER_SET_PASSWORD_LOAD,
    USER_TABLE_LOAD,
    USER_UPDATE_LOAD,
    USER_VIEW_LOAD,
    OTP_VERIFICATION
}
    from "../actionTypes/actionTypes"

// done
export const loginAction = (newData) => {
    return {
        type: USER_LOGIN_LOAD,
        payload: newData
    }

}

// done
export const forgetPassAction = (payload) => {
        return {
        type: USER_FORGET_LOAD,
        payload: payload
    }
}
//done
export const resetPasswordAction = (payload) => { 
     
    return {
        type: USER_RESET_PASSWORD_LOAD,
        payload: payload
    }
}
//done
export const registerUserAction = (payload) => {  
    return {
        type: USER_REGISTER_LOAD, 
        payload: payload
    }
}
export const changePassAction = (newData, history) => {
    return {
        type: USER_CHANGE_PASSWORD_LOAD,
        payload: newData,
        history
    }
} 

export const dashboardAction = (history, payload) => {
    return {
        type: USER_DASHBOARD_LOAD,
        history,
        payload
    }
}



// User management -->

export const userTableAction = (search, offset, limit, sortBy, order, status, history) => {
    return {
        type: USER_TABLE_LOAD,
        payload: search, offset, limit, sortBy, order, status, history

    }
}

//View User --->

export const viewUserAction = (id, history) => {
    return {
        type: USER_VIEW_LOAD,
        payload: id,
        history

    }
}

// enable and disable action ------->
export const enableDisableAction = (id, isBlock, history, search, offset, limit, sortBy, order, filterStatus) => {
    return {
        type: USER_ENABLE_DISABLE_LOAD,
        id: id,
        isBlock: isBlock,

        history,
        search, offset, limit, sortBy, order, filterStatus, isBlock
    }
}


//----> update 
export const updateUserDetails = (newData, history) => { 
    return {
        type: USER_UPDATE_LOAD,

        newData: newData,
        history: history
    }
}

export const setPassAction = (userId, value, history) => {
    return {
        type: USER_SET_PASSWORD_LOAD,
        userId: userId,
        payload: value, history
    }
}
export const otpVerification = ( payload ) => {
    return {
        type: OTP_VERIFICATION, 
        payload: payload 
    }
}

