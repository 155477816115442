import * as Yup from "yup";
import i18n from "i18next";

export const constants = {
  formikValidationSchemaEditUser: () => {
    return Yup.object().shape({
      firstName: Yup.string()
        .matches(/^[a-zA-Z\u0600-\u06FF.'\s]+$/, i18n.t('valid_first_name'))
        .max(20, i18n.t('max_chars', { max: '20' }))
        .min(2, i18n.t('string_min', { min: '2', name: i18n.t('first_name') }))
        .required(i18n.t('please_enter_first_name')),

      lastName: Yup.string()
        .required(i18n.t('please_enter_last_name'))
        .matches(/^[a-zA-Z\u0600-\u06FF.'\s]+$/, i18n.t('valid_last_name'))
        .max(20, i18n.t('max_chars', { max: '20' }))
        .min(2, i18n.t('string_min', { min: '2', name: i18n.t('last_name') })),

      email: Yup.string()
        .email(i18n.t('entr_valid_email_adrs'))
        .required(i18n.t('pls_entr_email'))
        .max(320, i18n.t('max_chars', { max: '320' })),

      address: Yup.string()
        .matches(
          /^[-a-zA-Z0-9\u0600-\u06FF-()]+([-a-zA-Z0-9\u0600-\u06FF-(),'"?/!@.\s]+)*$/,
          i18n.t('plz_enter_something', { something: i18n.t('vld_addr') })
        )
        .max(500, i18n.t('max_chars', { max: '500' }))
        .min(5, i18n.t('string_min', { min: '5', name: i18n.t('address') })),
      otp: Yup.string()
        .matches(
          /^\+?\d{10,15}$/,
          i18n.t('enter_valid_contact_num')
        )
        .required(i18n.t('plz_enter_something', { something: i18n.t('contact_num_fullstop') })), // for contact num
      countryCode: Yup.string()
        .matches(
          /^[+][^0]\d{0,3}$/,
          i18n.t('plz_enter_something', { something: i18n.t('vld_cntry_code') })
        )
        .required(i18n.t('plz_enter_something', { something: i18n.t('cntry_code_fullstop') })), // for contact num 
    });
  },
  formikValidationSchemaAddSkillModal: () => {
    return Yup.object().shape({
      skillCategory: Yup.array().min(
        1,
        i18n.t("plz_select_smthng", {
          something: i18n.t("skill_cat_fullstop"),
        })
      ),
      skillName: Yup.string()
        .test(
          "trim",
          i18n.t("pls_entr_name"),
          (value) => value?.trim()?.length > 0
        )
        .max(50, i18n.t("max_chars", { max: "50" }))
        .min(
          3,
          i18n.t("string_min", { min: "3", name: i18n.t("skill_name") })
        )
        .required(
          i18n.t("plz_enter_something", {
            something: i18n.t("skill_name_fullstop"),
          })
        ),

      skillDescription: Yup.string()
        .test(
          "trim",
          i18n.t("plz_enter_something", {
            something: i18n.t("description") + ".",
          }),
          (value) => value?.trim()?.length > 0
        )
        .max(200, i18n.t("max_chars", { max: "200" }))
        .min(
          3,
          i18n.t("string_min", { min: "3", name: i18n.t("description") })
        )
        .required(
          i18n.t("plz_enter_something", {
            something: i18n.t("description") + ".",
          })
        ),
      skillExperience: Yup.array().min(
        1,
        i18n.t("plz_select_smthng", {
          something: i18n.t("experience") + ".",
        })
      ),
    });
  },
};
