import React from 'react'
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
  scales: {
    y: {
      suggestedMax: 10,
      min: 0
    }
  }
};
let labels;
const CustomChartGroup = (props) => {
  const {
    chartProps = [
      {
        heading: 'Tasks allocated in last 1 week',
        data_type: 'day'
      }
    ]
  } = { ...props }

  const { t } = useTranslation();

  const dataType = (type, dataInfo) => {
    let data = {};
    if (type === "day") {
      labels = [t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')];
    } else if (type === "week") {
      labels = [t('week1'), t('week2'), t('week3'), t('week4'), t('week5')];
    } else if (type === "quarter") {
      if ((12 - (new Date().getMonth() + 1)) <= 11 && (12 - (new Date().getMonth() + 1)) >= 9) {
        labels = [
          t("jan"),
          t("feb"),
          t("mar")
        ];
      }
      if ((12 - (new Date().getMonth() + 1)) <= 8 && (12 - (new Date().getMonth() + 1)) >= 6) {
        labels = [t("apr"),
        t("may"),
        t("jun")];
      }
      if ((12 - (new Date().getMonth() + 1)) <= 5 && (12 - (new Date().getMonth() + 1)) >= 3) {
        labels = [t("jul"),
        t("aug"),
        t("sep")];
      }
      if ((12 - (new Date().getMonth() + 1)) <= 2 && (12 - (new Date().getMonth() + 1)) >= 0) {
        labels = [t("oct"),
        t("nov"),
        t("dec")];
      }
      // labels = [
      //   t("01 Jan-31 Mar"),
      //   t("01 Apr-30 June"),
      //   t("01 July-30 Sep"),
      //   t("01 Oct-31 Dec"),
      // ];
    } else if (type === "month") {
      labels = [
        t("jan"),
        t("feb"),
        t("mar"),
        t("apr"),
        t("may"),
        t("jun"),
        t("jul"),
        t("aug"),
        t("sep"),
        t("oct"),
        t("nov"),
        t("dec"),
      ];
    }

    data = {
      labels,
      datasets: [
        {
          label: t("dataset"),
          data: dataInfo, //faker.datatype.number({ min: 0, max: 1000 }), // pass data in the following format [65, 59, 80, 81, 56, 55, 40]
          backgroundColor: "#022649",
        },
      ],
    };

    return data;
  };

  return (
    chartProps.map(items => {
      return <div key={items.heading} className="col-xl-3 col-sm-4 mb-xl-0 mb-3">
        <Bar options={options} data={dataType(items.data_type, items.dataInfo)} />
        <h3>{items.heading}</h3>
      </div>
    })

  )
}

export default CustomChartGroup