import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../../hoc";
import {
  CustomEmptyDiv,
  CustomPageHeader,
  CustomTable,
  TextField,
} from "../../../../molecules";
import {
  AppImage,
  NavigateTo,
  SidebarMenus,
} from "../../../../themes";
import { Collapse } from "antd"; //
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { getTaskDetails, saveTaskId } from "../../../../redux/actions/Tasks";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn";
import { useDispatch } from "react-redux";
import appconstant from "../../../../themes/appconstant";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import { AnnotationModal, ReportModal } from "./Modals";

const { Panel } = Collapse;

const ValidatorPendingTaskDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // breadcrumb start
  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: t("task_management"),
    },
    {
      link: "",
      label: t("val_pen_task_details"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("user"),
    active: t('task_management'),
  };
  // breadcrumb start

  // localstorage start
  const { taskId } = useParams(); //localStorage.getItem("validatorPendingTaskId");
  // const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [reload, setReload] = useState(false);
  const [workerLists, setWorkers] = useState([]);
  const [tableData, setTableData] = useState([]); // class list annotation MODAL

  // text
  const [textLists, setTextLists] = useState([]);
  const [annotationTextDS, setAnnotationTextDS] = useState([]);

  const [questions, setQuestions] = useState(null);
  // text

  // localstorage end

  // common start
  const [rateTo, setRateTo] = useState(null);
  const [projId, setProjId] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null); //location.state?.status
  const [taskInfo, setTaskInfo] = useState({
    taskName: "",
    taskType: "",
    instruction: "",
    description: "",
  });
  const reRenderCustomPageHeader = false;
  // common end

  // modals start
  const [isModalVisible, setIsModalVisible] = useState(false); // annotation modal
  const [isReportVisible, setIsReportModalVisible] = useState(false);
  // modals end

  // image class start
  const [classLists, setClassLists] = useState([]);
  const [annotationDS, setAnnotationDS] = useState([]);
  const [classNames, setClassNames] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [annotDetails, setAnnotDetails] = useState([]);
  const [imageIndex, setImageIndex] = useState("");
  // image class start

  //audio start 
  const [audioLists, setAudioLists] = useState([]);
  const [audioIndex, setAudioIndex] = useState(null);
  const [annotationAudioDS, setAnnotationAudioDS] = useState([]);
  //audio end

  // others

  // const [audio, setAudio] = useState(null);

  /* page description */
  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: "break-all",
  };
  const tnl = [
    { labelTitle: `${t("tsk_name")} :`, customLabelStyle: leftFontstyle },
    {
      labelTitle: t(taskInfo.taskName),
      // "Task",
      customLabelStyle: rightFontStyle,
    },
  ]; // task name
  const ttl = [
    { labelTitle: `${t("tsk_typ")} :`, customLabelStyle: leftFontstyle },
    {
      labelTitle: t(taskInfo.taskType),
      customLabelStyle: rightFontStyle,
    },
  ]; // task type

  const tdl = [
    {
      labelTitle: `${t("tsk_desc")} :`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo.description,
      customLabelStyle: rightFontStyle,
    },
  ]; // task description
  const prog = [
    {
      labelTitle: `${t('progress')} :`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo.progress,
      customLabelStyle: rightFontStyle,
    },
  ]; // task description
  const textFieldlabels = [tnl, ttl, tdl, prog];

  /* page description */

  // drop down start
  const classListHeaders = [
    {
      label: "", //"File Image",
      headerWidth: "150px",
    },
    {
      label: t("clss_name"),
      headerWidth: "150px",
    },
    {
      label: t("annotations"),
      headerWidth: "200px",
    },
    {
      label: t("action"),
      paddingLeft: localStorage.getItem("lang") == "ar" ? "88px" : "75px",
      headerWidth: localStorage.getItem("lang") == "ar" ? "140px" : "80px",
    },
  ];

  const audioLisHeaders = [
    {
      label: "",
      headerWidth: "150px",
    },
    {
      label: t("file_name"),
      headerWidth: "150px",
    },
    {
      label: t("annotations"),
      headerWidth: "250px",
    },
    {
      label: t("action"),
      paddingLeft: "70px",
      headerWidth: "80px",
      textAlign: "left",
    },
  ]; // outer list table
  const workerListHeaders = [
    {
      label: t("profile"),
      headerWidth: "150px",
    },
    {
      label: t("name"),
      headerWidth: "150px",
    },
    {
      label: t("email_address"),
      headerWidth: "250px",
    },
    {
      label: t("action"),
      paddingLeft: "70px",
      headerWidth: "80px",
      textAlign: "left",
    },
  ]; // outer list table

  const dropDownGroup = [
    {
      table: {
        hasNestedDropDown: false,
        placeholder: t("cls_list"),
        tableHeaders:
          classListHeaders,
        tableContents:
          classLists,
        tableContentsHasArrayOFObj: true,
        AnnotClassName:
          localStorage.getItem("lang") == "ar"
            ? "validator-completed-annot-headers-ar-language"
            : "validator-completed-annot-headers-en-language",
        tBodyClassName: "last-child",
        tdClassName:
          localStorage.getItem("lang") == "ar"
            ? "validator-completed-class-list-cells-ar-language"
            : "validator-completed-class-list-cells-en-language",
      }, // class list
    },
    {
      table: {
        placeholder:
          taskInfo?.taskType == "audio" ? t("audio_list") : t("txt_list"),
        tableHeaders:
          audioLisHeaders,
        tableContents:
          taskInfo?.taskType == "audio" ? audioLists : textLists,
        tableContentsHasArrayOFObj: true,
        tdImageWidth: taskInfo?.taskType == "text" ? "unset" : null,
        tdImageHeight: taskInfo?.taskType == "text" ? "unset" : null,
        tdImageBorderRadius: taskInfo?.taskType == "text" ? "unset" : null,
        tBodyClassName: "last-child",
        AnnotClassName: "wptd",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data user-task-manage-worker-completed-task-details-class-list-ar"
            : "text_data user-task-manage-worker-completed-task-details-class-list-en",
      },
    }, // audio Lists
    {
      table: {
        placeholder: t("worker_list"),
        tableHeaders:
          workerListHeaders,
        tableContents:
          workerLists,

        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        AnnotClassName: "wptd",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data user-task-manage-worker-completed-task-details-worker-list-ar"
            : "text_data user-task-manage-worker-completed-task-details-worker-list-en",
      },
    }, // worker lists 
  ];
  // dropdown end

  // payload start
  const payload = {
    body: {
      taskId,
    },
    success: (res) => {
      setTaskInfo({
        taskName: res?.data?.name,
        taskType:
          res?.data?.task_type == 1
            ? "image"
            : res?.data?.task_type == 2
              ? "text"
              : "audio",
        instruction: res?.data?.instruction,
        description: res?.data?.description,
        progress: `${res?.approved_annotation}/${res?.total_annotation}`,
      });
      let tempStatus =
        res?.data?.status == 0
          ? "pending"
          : res?.data?.status == 2
            ? "completed"
            : "pending";

      setTaskStatus(tempStatus); //res?.data?.status
      setProjId(res?.data?.project_id);

      if (res?.data?.task_type == 1) {
        localStorage.setItem("taskType", "Image");

        let classNames = [];
        res?.data?.classes.map((item) => {
          classNames.push(item?.name);
        });

        setClassNames(classNames);

        let tempClassLists = [];
        let tempImageArray = [];
        // let tempApiJsonImage = [];
        res?.data?.media.map((item, index) => {
          let imageDetails = {};
          imageDetails["annotationId"] = item?.id;
          imageDetails["jsonFile"] = item?.annotation;

          imageDetails["task_id"] = res?.data?.id;
          imageDetails["workerId"] = item?.worker_id; //res?.data?.workers[0].id;
          imageDetails["validator_id"] = res?.data?.validators[0].id;
          imageDetails["annotStatus"] = item?.status;
          var classNames_list = [];
          item?.annotation?.forEach((ann) => {
            if (ann?.regions?.cls)
              classNames_list.push(ann?.regions?.cls);
          })
          tempClassLists.push({
            0: { image: appconstant.imageBaseUrl + item?.path },
            1: classNames_list.length > 0 ? classNames_list.join(",") : t("no_class"),
            2: item?.annotation ? item?.annotation?.length : 0,
            3: {
              button: 'View Annotations',
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: imageDetails, // send anything like image id item?.id or annot details
            },
          }); // tempClassLists

          // const tempJSON = item?.annotation[item?.annotation.length - 1].Anotation_id?.jsonFile ;
          // const worker_id =item?.annotation[item?.annotation.length - 1].worker_id.id ;
          // const validator_id =item?.annotation[item?.annotation.length - 1].validator_id.id ;

          //  const tempRegion = tempJSON?.regions;



          tempImageArray.push({
            name: `Image-${index + 1}`,
            src: appconstant.imageBaseUrl + item?.path,
            regions: item?.annotation.length != 0
              ? item?.annotation[item?.annotation.length - 1].jsonFile
                ?.regions
              : [],

            id: item?.id
          }); // tempImageArray


        });
        setClassLists(tempClassLists);
        setImageArray(tempImageArray);

        let tempWorkers = [];
        res?.data?.workers.map((item) => {
          tempWorkers.push({
            0: {
              image: item?.user_id?.image
                ? appconstant.imageBaseUrl + item?.user_id?.image
                : AppImage.defaultImage,
            },
            1: item?.user_id?.first_name + " " + item?.user_id?.last_name,
            2: item?.user_id?.email,
            3: {
              button: "Report",
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: item?.user_id?.id, // send anything like image id item?.id or annot details
            },
          });
        });
        setWorkers(tempWorkers);
      } // image

      if (res?.data?.task_type == 2) {
        localStorage.setItem("taskType", "Text");

        if (res?.data?.text_type == "segmentation") {
          localStorage.setItem("textType", "segmentation");

          let tempTextLists = [];
          let tempClassLists = [];
          let tempWorkers = [];
          res?.data?.workers.map((item) => {
            tempWorkers.push({
              0: {
                image: item?.user_id?.image
                  ? appconstant.imageBaseUrl + item?.user_id?.image
                  : AppImage.defaultImage,
              },
              1: item?.user_id?.first_name + " " + item?.user_id?.last_name,
              2: item?.user_id?.email,
              3: {
                button: "Report",
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: item?.user_id?.id, // send anything like image id item?.id or annot details
              },
            });
          });
          setWorkers(tempWorkers);
          res?.data?.media.map((item) => {
            let textFileName = item?.path; //.substring(0, 15) + "..."; //.split("/");
            let textDetails = {
              jsonFileForStartTask:
                item?.annotation[item?.annotation.length - 1]?.Anotation_id
                  ?.jsonFile,
              jsonFile: item?.annotation,
              paragraph: item?.path,
              totalTasks: res?.data?.media?.length,

              task_id: item?.task_id,
              anotation_id: item?.id,
              worker_id: item?.worker_id,
              media_id: item?.id,
            };
            tempTextLists.push({
              0: { image: AppImage.file_n },
              1: textFileName, //textFileName[textFileName.length - 1],
              2: item?.annotation ? item?.annotation?.length : 0,
              3: {
                button: 'View Annotations',
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: textDetails, // send anything like image id item?.id or annot details
              },
            }); // tempTextLists
          });
          setTextLists(tempTextLists);

          res?.data?.classes.map((item) => {
            tempClassLists.push({
              id: item?.id,
              displayName: item?.name,
              task_id: item?.task_id,
            });
          });
          setClassLists(tempClassLists);
        }
        if (res?.data?.text_type == "questions") {
          localStorage.setItem("textType", "questions");

          localStorage.setItem("textType", "questions");
          let tempTextLists = [];
          const tempQuestions = [];
          // let annotCount = 0;

          JSON.parse(res?.data?.question?.questionsData)?.map((item, index) => {
            tempQuestions.push({
              name: `input${index}`,
              type:
                item?.answerType == "Check-Box"
                  ? "checkbox"
                  : "radio",
              title: item?.question,
              choices: item?.answers,
            });
          });

          res?.data?.media.map((item) => {
            // if (item?.annotation?.length == tempQuestions.length) {
            //   annotCount++;
            // }

            let textFileName = item?.path.split("/");
            let textDetails = {
              jsonFileForStartTask:
                item?.annotation[item?.annotation.length - 1]?.jsonFile,
              jsonFile: item?.annotation,
              pdfUrl: appconstant?.pdfBaseUrl + item?.path,
              task_id: item?.task_id,
              anotation_id: item?.id,
              worker_id: item?.worker_id,
              media_id: item?.id,
              totalTasks: res?.data?.media?.length,
            };
            tempTextLists.push({
              0: { image: AppImage.file_n },
              1: textFileName[textFileName.length - 1],
              2: item?.annotation ? item?.annotation?.length : 0,
              3: {
                button: 'View Annotations',
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: textDetails, // send anything like image id item?.id or annot details
              },
            }); // tempTextLists
          });
          // setPdfUrl(tempPdfUrls);//res?.data?.pdfUrls
          setQuestions(tempQuestions);
          setTextLists(tempTextLists);
          setTableData(res?.data?.media);

          let tempWorkers = [];
          res?.data?.workers.map((item) => {
            tempWorkers.push({
              0: {
                image: item?.user_id?.image
                  ? appconstant.imageBaseUrl + item?.user_id?.image
                  : AppImage.defaultImage,
              },
              1: item?.user_id?.first_name + " " + item?.user_id?.last_name,
              2: item?.user_id?.email,
              3: {
                button: "Report",
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: item?.user_id?.id, // send anything like image id item?.id or annot details
              },
            });
          });
          setWorkers(tempWorkers);
        }
      } // text

      // audio
      if (res?.data?.task_type == 3) {
        localStorage.setItem("taskType", "Audio");

        let tempAudioArray = [];
        let tempAudioLists = [];

        res?.data?.media.map((item) => {
          let audioFileName = item?.path.split("/");
          let audioDetails = {};

          audioDetails["jsonFile"] = item?.annotation || [];
          audioDetails["audioUrl"] = appconstant.imageBaseUrl + item?.path;

          audioDetails["task_id"] = res?.data?.id;
          audioDetails["workerId"] = item?.worker_id; //res?.data?.workers[0].id;
          audioDetails["validator_id"] = res?.data?.validators[0].id;
          audioDetails["annotationId"] = item?.id;
          audioDetails["annotStatus"] = item?.status;

          tempAudioLists.push({
            0: { image: AppImage.volumeFileImg },
            1: audioFileName[audioFileName.length - 1],
            2: item?.annotation ? item?.annotation?.length : 0,
            // 2: item?.jsonFile ? item?.jsonFile.length : 0,
            3: {
              button: 'View Annotations',
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: audioDetails, // send anything like image id item?.id or annot details
            },
          }); // tempAudioLists

          tempAudioArray.push({
            // name: `Audio-${index + 1}`,
            url: appconstant.imageBaseUrl + item?.path,
            // jsonFile: item?.annotation != null ? item?.annotation : [], // jsonFile
            jsonFile:
              item?.annotation != null
                ? item?.annotation[item?.annotation.length - 1]?.jsonFile
                : [], // jsonFile

            task_id: item?.task_id,
            anotation_id: item?.id,
            worker_id: item?.worker_id,
          });
        });

        setAudioLists(tempAudioLists);

        // setAudio(tempAudioArray);
        let tempWorkers = [];
        res?.data?.workers.map((item) => {
          tempWorkers.push({
            0: {
              image: item?.user_id?.image
                ? appconstant.imageBaseUrl + item?.user_id?.image
                : AppImage.defaultImage,
            },
            1: item?.user_id?.first_name + " " + item?.user_id?.last_name,
            2: item?.user_id?.email,
            3: {
              button: "Report",
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: item?.user_id?.id, // send anything like image id item?.id or annot details
            },
          });
        });

        setWorkers(tempWorkers);
      } // audio
    },
    fail: (resf) => {
      console.log(resf, "resf of task details.");
      resf?.data &&
        toast.error(resf?.data, {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
      resf?.message &&
        toast.error(resf?.message, {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        });
    },
  }; //  task details info payload
  // payload end

  // useEffect start
  useEffect(() => {
    window.document.title = "WosoM";

  }, [isModalVisible, isReportVisible]);
  useEffect(() => {
    reload && isInternetConnected("task") && dispatch(getTaskDetails(payload));
  }, [reload]);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(saveTaskId(taskId));
    isInternetConnected("task") && dispatch(getTaskDetails(payload));
  }, []);
  // useEffect end

  return (
    <Container
      {...containerProps}
    // languageChanged={(val) => {
    //   setLang(localStorage.getItem("lang"));
    // }}
    >
      <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        <CustomPageHeader
          label={t("val_pen_task_details")}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px",
          }}
          islanguageChanged={reRenderCustomPageHeader}
        >
          {taskStatus ? (
            <CustomPageHeader
              label={
                `${t("status")}: ${t(taskStatus)}` //${('pending_status')}
              } //"Status: Pending"
              wrapperClassName={
                "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
              }
              customWrapperStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "-16px",
              }}
            >
              <div
                style={{
                  marginTop: "-5px",
                }}
              >
                <img
                  src={
                    taskStatus == "pending"
                      ? AppImage.pendingTask
                      : AppImage.completedTask
                  }
                />
              </div>
            </CustomPageHeader>
          ) : null}
        </CustomPageHeader>
        <div className="wrapper_line">
          {/* pageDescription */}
          {textFieldlabels.map((item) => {
            return <TextField labels={item} key={item} />;
          })}
          {/* pageDescription */}

          {/* task Instruction */}
          <label style={leftFontstyle}>{t("instructions")} :</label>
          <div
            className="get-original-text-color"
            style={{
              marginTop: "12px",
            }}
          >
            <div className="ck-content">{parse(taskInfo.instruction)}</div>
          </div>
          {/* task Instruction */}

          {taskInfo.taskType == "image" ||
            taskInfo.taskType == "segmentation" ? (
            // class list
            <Collapse
              defaultActiveKey={["1"]}
              onChange={() => {
                const isDropdownOpened = document
                  .querySelector(".ant-collapse-header")
                  .getAttribute("aria-expanded");
                const dropdownArrowsOnClickStyle = document.querySelectorAll(
                  ".ant-collapse-arrow svg"
                );
                if (isDropdownOpened == "true") {
                  // .setAttribute('style','transform: rotate(-90deg);');
                } else {
                  dropdownArrowsOnClickStyle[0].setAttribute(
                    "style",
                    "transform: rotate(-90deg) !important;"
                  );
                }
              }}
            >
              <Panel header={dropDownGroup[0].table.placeholder} key="1">
                {classLists.length > 0 ? (
                  <CustomTable
                    {...dropDownGroup[0].table}
                    handleClick={(label, id, link, row2, trIndex) => {
                      switch (label) {
                        case 'View Annotations': {
                          if (id != null) {
                            let tempAnnotDetails = [];
                            const annots = id;
                            annots?.jsonFile?.map((item) => {
                              tempAnnotDetails.push({
                                annotation_name:
                                  item?.Anotation_id?.regions.type,
                                class: item?.Anotation_id?.regions.cls,
                                file_id:
                                  item?.Anotation_id?.regions.id,
                                worker:
                                  item?.worker_id?.user_id?.first_name +
                                  " " +
                                  item?.worker_id?.user_id?.last_name,
                                status:
                                  item?.status == 3
                                    ? "Rejected"
                                    : item?.status == 2
                                      ? "Approved"
                                      : item?.status == 4
                                        ? "Disputed"
                                        : "Pending",
                                statusToShow: item?.status == 3
                                  ? "rejected"
                                  : item?.status == 2
                                    ? "approved"
                                    : item?.status == 4
                                      ? "disputed"
                                      : "pending",

                                // for raise dispute
                                annotationId: item?.id,
                                // for buttons to show if raise dispute or view comment or disable raise dispute
                                annotStatus: item?.status,
                                // to show vComments
                                vComments: item?.comment?.comment,
                                annotDetails: item?.Anotation_id?.jsonFile,
                                workerId: item?.worker_id?.id,
                                validator_id: item?.validator_id?.id,
                                annotation_id: item?.Anotation_id?.id,
                              });
                            });
                            setAnnotationDS(tempAnnotDetails);
                            setAnnotDetails(id);
                          }
                          localStorage.setItem('imageIndex', trIndex);
                          setImageIndex(trIndex);
                          localStorage.setItem('imageIndex', trIndex);
                          setReload(false);
                          setIsModalVisible(true);
                          break;
                        }
                        default:
                          break;
                      }
                    }}
                  />
                ) : (
                  <CustomEmptyDiv title={t("clss_appear_here")} />
                )}
              </Panel>
            </Collapse>
          ) : (
            // audio list
            <Collapse defaultActiveKey={["1"]} onChange={() => { }}>
              <Panel header={dropDownGroup[1].table.placeholder} key="1">
                {audioLists.length > 0 || textLists.length > 0 ? (
                  <CustomTable
                    {...dropDownGroup[1].table}
                    handleClick={(label, id, link, row2, trIndex) => {
                      switch (label) {
                        case 'View Annotations': {
                          if (taskInfo?.taskType == "audio") {
                            if (id != null) {
                              let tempAnnotDetails = [];
                              const audioDetails = id;

                              audioDetails?.jsonFile.map((item) => {
                                tempAnnotDetails.push({
                                  annotation_name:
                                    item?.Anotation_id?.regions?.data?.note,
                                  annotationId: item?.id,
                                  individualAnnots: [
                                    item?.Anotation_id?.regions,
                                  ],
                                  worker:
                                    item?.worker_id?.user_id?.first_name +
                                    " " +
                                    item?.worker_id?.user_id?.last_name,
                                  status:
                                    item?.status == 3
                                      ? "Rejected"
                                      : item?.status == 2
                                        ? "Approved"
                                        : item?.status == 4
                                          ? "Disputed"
                                          : "Pending",
                                  statusToShow: item?.status == 3
                                    ? "rejected"
                                    : item?.status == 2
                                      ? "approved"
                                      : item?.status == 4
                                        ? "disputed"
                                        : "pending",
                                  workerId: item?.worker_id?.id,
                                  validator_id: item?.validator_id?.id,
                                  annotation_id: item?.Anotation_id?.id,
                                });
                              });
                              setAnnotationAudioDS(tempAnnotDetails); // data to show for Audio annotation list table of modal
                              setAnnotDetails(id); // all annotations for single audio
                            }
                            setAudioIndex(trIndex);
                            setReload(false);
                            setIsModalVisible(true);
                          }
                          if (taskInfo?.taskType == "text") {
                            if (id != null) {
                              if (
                                localStorage.getItem("textType") == "questions"
                              ) {
                                const textDetails = id;
                                localStorage.setItem(
                                  "tableData",
                                  JSON.stringify(tableData)
                                );

                                let tempAnnotDetails = [];
                                textDetails?.jsonFile.map((item) => {
                                  const questionIndex =
                                    item?.Anotation_id?.region_id;

                                  const annotName =
                                    questions[
                                      questionIndex.split("")[
                                      questionIndex.length - 1
                                      ]
                                    ]?.title;

                                  tempAnnotDetails.push({
                                    annotation_name: annotName,
                                    status:
                                      item?.status == 3
                                        ? "Rejected"
                                        : item?.status == 2
                                          ? "Approved"
                                          : item?.status == 4
                                            ? "Disputed"
                                            : "Pending",
                                    statusToShow: item?.status == 3
                                      ? "rejected"
                                      : item?.status == 2
                                        ? "approved"
                                        : item?.status == 4
                                          ? "disputed"
                                          : "pending",
                                    // for raise dispute
                                    annotationId: item?.id,
                                    // for buttons to show if raise dispute or view comment or disable raise dispute
                                    annotStatus: item?.status,
                                    // to show vComments
                                    vComments: item?.comment,
                                    questions: [
                                      questions[
                                      questionIndex.split("")[
                                      questionIndex.length - 1
                                      ]
                                      ],
                                    ],
                                    // pdfUrl: textDetails?.pdfUrl,
                                    pdfUrl: item?.Anotation_id?.media_id?.path,
                                    savedAnnotation: {
                                      [item?.Anotation_id?.region_id]: item
                                        ?.Anotation_id?.regions,
                                    },
                                    worker:
                                      item?.worker_id?.user_id?.first_name +
                                      " " +
                                      item?.worker_id?.user_id?.last_name,
                                    // valId:
                                    workerId: item?.worker_id?.id,
                                    validator_id: item?.validator_id?.id,
                                    annotation_id: item?.Anotation_id?.id,
                                    //   item?.comment[item?.comment.length - 1]
                                    //     ?.validator_id,
                                  });
                                });
                                setAnnotationTextDS(tempAnnotDetails);
                                setAnnotDetails(id);
                              }
                              if (
                                localStorage.getItem("textType") ==
                                "segmentation"
                              ) {
                                const textDetails = id;
                                let tempAnnotDetails = [];
                                textDetails?.jsonFile.map((item) => {
                                  const annotName =
                                    item?.Anotation_id?.regions?.text;
                                  tempAnnotDetails.push({
                                    annotation_name:
                                      // annotName.length > 10
                                      //   ? annotName.substring(0, 10)
                                      //   :
                                      annotName,
                                    worker:
                                      item?.worker_id?.user_id?.first_name +
                                      " " +
                                      item?.worker_id?.user_id?.last_name,
                                    status:
                                      item?.status == 3
                                        ? "Rejected"
                                        : item?.status == 2
                                          ? "Approved"
                                          : item?.status == 4
                                            ? "Disputed"
                                            : "Pending",
                                    // for raise dispute
                                    annotationId: item?.id,
                                    // for buttons to show if raise dispute or view comment or disable raise dispute
                                    annotStatus: item?.status,
                                    // to show vComments
                                    vComments: item?.comment,
                                    paragraph: item?.paragraph,
                                    particularAnnot: [
                                      item?.Anotation_id?.regions,
                                    ],
                                    totalAnnots: item?.jsonFile,
                                    segmentAllAnnots:
                                      textDetails?.jsonFileForStartTask,

                                    // workerId: item?.worker_id?.user_id?.id,

                                    // valId:
                                    //   item?.comment[item?.comment.length - 1]
                                    //     ?.validator_id,

                                    workerId: item?.worker_id?.id,
                                    validator_id: item?.validator_id?.id,
                                    annotation_id: item?.Anotation_id?.id,
                                  });
                                });
                                setAnnotationTextDS(tempAnnotDetails);
                                setAnnotDetails(id);
                              }
                            }
                            setReload(false);
                            setIsModalVisible(true);
                          }

                          break;
                        }

                        default:
                          break;
                      }
                    }} // for buttons in a row of table
                  />
                ) : (
                  <CustomEmptyDiv
                    title={`${taskInfo?.taskType == "audio"
                      ? t("audio_list")
                      : t("text_lists")
                      }  ${t('wil_appear_here')}`}
                  />
                )}
              </Panel>
              {/* Audio */}
            </Collapse>
          ) // Audio 
          }

          {/* worker lists */}
          <Collapse
            defaultActiveKey={["1"]}
            className="workerlist-val-pending-taskdetails"
          >
            <Panel header={dropDownGroup[2].table.placeholder} key="1">
              {workerLists.length > 0 ? (
                <CustomTable
                  {...dropDownGroup[2].table}
                  handleClick={(label, id) => {
                    switch (label) {
                      case "Report": {
                        if (id != null) {
                          setIsReportModalVisible(true);
                          setRateTo(id);
                        }
                        break;
                      }
                      default:
                        break;
                    }
                  }}
                />
              ) : (
                <CustomEmptyDiv title={t("wrkr_list_appear_here")} />
              )}
            </Panel>
          </Collapse>

          {/* Anntation Modal */}
          <AnnotationModal
            getTaskDetails={(setValue) => {
              setReload(setValue);
            }}
            // image class details
            classNames={classNames}
            imageArray={imageArray}
            imageIndex={imageIndex}
            annotDetails={annotDetails}
            annotationDS={annotationDS}
            // audio details
            annotationAudioDS={annotationAudioDS}
            audioIndex={audioIndex}
            audioLists={audioLists}
            // text
            annotationTextDS={annotationTextDS}
            classLists={classLists}
            projId={projId}
            isModalVisible={isModalVisible}
            hideModal={(hide) => {
              setIsModalVisible(hide);
            }}
          />

          {/* report modal */}
          <ReportModal
            rateTo={rateTo}
            taskId={taskId}
            isReportVisible={isReportVisible}
            hideModal={(hide) => {
              setIsReportModalVisible(hide);
            }}
          />
        </div>
      </CardHoc>
    </Container>
  );
};

export default ValidatorPendingTaskDetails;
