import React from "react";
import { Select, Radio } from "antd";

const QuestionAnnotate = (props) => {
  const { questions, pdfUrls, savedAnnots } = { ...props };

  return (
    <div className="udt_container">
      <div className="button_udt_flex"></div>
      <div className="flex_udt">
        <div className="paragraphs_udt">
          <p>{pdfUrls}</p>
        </div>
        <div className="card half_width">
          {questions[0]?.type == "checkbox" ? (
            <div
              style={{
                fontSize: "13px",
                textAlign:
                  localStorage.getItem("lang") == "ar" ? "right" : "left",
                color: "#2b5483",
                padding: "10px 21px",
                fontWeight: "600",
              }}
              className="email_selecter"
            >
              <div className="title_udt">{questions[0]?.title}</div>
              <div className="udt_heading">
                {
                  <Select
                    disabled
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Select"
                    className="udt_selector"
                    value={savedAnnots[Object.keys(savedAnnots)[0]]}//savedAnnots[`input${qnindex}`]
                  // onChange={(value) => onChangeRadio(value, j, i)}
                  />
                }
              </div>
            </div>
          ) : (
            <div
              style={{
                fontSize: "13px",
                textAlign:
                  localStorage.getItem("lang") == "ar" ? "right" : "left",
                color: "#2b5483",
                padding: "10px 21px",
                fontWeight: "600",
              }}
            >
              <div className="title_udt">{questions[0]?.title}</div>
              <div className="udt_heading">
                <Radio.Group
                  value={savedAnnots[Object.keys(savedAnnots)[0]]} //{savedAnnots[`input${qnindex}`]} //savedAnnots[`input${qnindex}`]}
                  size="large"
                  disabled
                  style={{
                    margin: "5px 0px",
                    width: "20%",
                  }}
                  className="udt_heading"
                >
                  {questions[0]?.choices.map((item, index) => {
                    return (
                      <Radio
                        key={index}
                        value={item}
                        style={{
                          color: "#2b5483",
                          fontWeight: "600",
                        }}
                      >
                        {item}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionAnnotate;
