import * as Yup from "yup";
import { Modal } from "antd";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ValidationConstants from "../../../themes/validationConstants";
import { Constants, AppImage, NavigateTo } from "../../../themes/index";
import { forgetPassAction } from "../../../redux/actions/Actions";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
  }, []);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
    navigate(NavigateTo.LOGIN)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    navigate(NavigateTo.LOGIN)
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .test('trim', t(ValidationConstants.reqEmail), value => value?.trim()?.length > 0)

      .matches(
        /^(([a-zA-Z0-9]+)||([a-zA-Z0-9_.-]+[a-zA-Z0-9]+))@([0-9a-zA-Z]+)([^0-9])([0-9a-zA-Z]+)||([a-zA-Z0-9])(\.[a-zA-z]{2,5})$/,
        t(ValidationConstants.valEmail)
      )
      .email(t(ValidationConstants.valEmail))
      .max(320, t(ValidationConstants.maxEmail))
      .required(t(ValidationConstants.reqEmail))

  });
  const initialValues = {
    email: ''
  }
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      return (errors.email = t(ValidationConstants.reqEmail));
    }
    if (!/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-z]+(\.[a-zA-z]{2,8})+$/.test(values.email)) {
      return (errors.email = t(ValidationConstants.valEmail));
    }
  }
  const onSubmit = (values) => {
    // if( formik.errors?.email==undefined &&  formik.values.email!='' )
    // {
    const payload = {
      body: {
        email: (values?.email).toLocaleLowerCase(),
      },
      success: () => {
        setIsModalVisible(true);
      },
      fail: (resf) => {
        let err = resf?.result[Object.keys(resf?.result)[0]];
        toast.error(t(err), {
          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
          toastId: "forgotToast",
        });
      },
    };
    isInternetConnected() && dispatch(forgetPassAction(payload));
    // }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validate,
  })
  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="line_true stopOverFlow">
          <div className="content-e " style={{ position: 'relative' }}>
            <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 ">
              <h6 className="text-white line_element text-capitalize ps-3 data ">
                <Link className="focus" to={NavigateTo.LOGIN}>
                  {" "}
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
              </h6>
            </div>
            <div className="wrapper_line login">
              {/* header */}
              <div className="text-center cost ">
                <img src={AppImage.logo3} />
                <h6 className="text-white text-capitalize ps-3 headg_d forgot_team">
                  {Constants.ForgotPassword}
                </h6>
              </div>
              {/* header */}

              <form noValidate onSubmit={formik.handleSubmit}>
                {/* Email field */}
                <div
                  className="form-group"
                  style={{
                    marginTop: "70px",
                  }}
                >
                  {/* <label>{Constants. .EmailAddress}</label> */}
                  <input
                    id={'email'}
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                    value={formik.values.email.trimLeft()}
                    onChange={formik.handleChange}
                    maxLength={320}
                    autoComplete={'off'}
                  />
                  {formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                </div>
                {/* Email field */}

                {/* Send Button */}
                <div
                  className="data_line"
                  style={{
                    padding: "0",
                    marginBottom: "15px",
                    marginTop: "70px",
                  }}
                >
                  <button
                    type="submit"
                    className="button-list focus"
                  >
                    {Constants.Send}
                  </button>
                </div>
                {/* Send Button */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={Constants.Success}
        open={isModalVisible}
        onOk={() => {
          handleOk();
        }}
        onCancel={() => {
          handleCancel();
        }}
        footer={null}
        className="success_data"
        centered
      >
        <div className="text-center  model1 paddinf_2">
          <p>{Constants.sentdata}</p>
        </div>
        <div className="text-center  model1 ">
          <Link to={NavigateTo.LOGIN}>
            <button
              type="submit"
              className="button-list top_new color_new bottom_button new_color focus"
              onClick={() => handleCancel(false)}
            >
              {Constants.done}
            </button>
          </Link>
          {/* <button type="submit" onClick={() => handleCancel(false)}>Cancel</button> */}
        </div>
      </Modal>
    </div>
  );
};
export default ForgotPassword;