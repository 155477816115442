import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../hoc";
import {
  CustomEmptyDiv,
  CustomPageHeader,
  CustomTable,
  TextField,
} from "../../../molecules";
import { AppImage, NavigateTo, SidebarMenus } from "../../../themes";
import {
  Collapse,
  Table,
  Modal,
  // Select,
  Tooltip,
  Rate,
} from "antd";
import { useParams } from "react-router";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { useDispatch } from "react-redux";
import {
  getTaskDetails,
  saveTaskId,
} from "../../../redux/actions/Tasks";
import appconstant from "../../../themes/appconstant";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { giveRate } from "../../../redux/actions/Rating";
import { toast } from "react-toastify";
import { report } from "../../../redux/actions/Report";
import { AnnotationModal } from "./pastProjectTaskDetailsModals";

const { Panel } = Collapse;
// const { Option } = Select;
const PastProjectTaskDetails = () => {
  const { t } = useTranslation();

  //audio start
  const [audioLists, setAudioLists] = useState([]);
  // const [audioIndex, setAudioIndex] = useState("");
  const [annotationAudioDS, setAnnotationAudioDS] = useState([]);
  //audio end

  const [annotationDS, setAnnotationDS] = useState([]);
  const [questions, setQuestions] = useState(null);
  const [textLists, setTextLists] = useState([]);
  const [annotationTextDS, setAnnotationTextDS] = useState([]);

  const [rateTo, setRateTo] = useState(null);
  const [ratingVal, setRatingVal] = useState(0);
  const [ordering, setOrdering] = useState("");
  // const [tableName, setTableName] = useState("");
  const [toUserType, setToUserType] = useState(null);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [vComments, setVComments] = useState([]);

  const [imageArray, setImageArray] = useState([]);
  const [classNames, setClassNames] = useState([]);
  const [annotDetails, setAnnotDetails] = useState([]);
  const [imageIndex, setImageIndex] = useState("");

  // const [totalValidators, setTotalValidators] = useState(1);
  const [dataSourceValidator, setDataSourceValidator] = useState([]); // validators

  // const [totalWorkers, setTotalWorkers] = useState(1);
  const [dataSourceWorker, setDataSourceWorker] = useState([]); // workers table

  const [isReport, setIsModalReport] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isAnnotation, setIsModalAnnotation] = useState(false);
  // const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [isRatingVisible, setIsRatingModalVisible] = useState(false);
  const [classLists, setClassLists] = useState([]);
  const [taskInfo, setTaskInfo] = useState({
    taskName: "",
    taskType: "",
    textType: "",
    instruction: "",
    description: "",
    endDate: "",
    instruction: "<p></p>",
  });
  // const [taskStatus, setTaskStatus] = useState("");
  const { taskId } = useParams(); //localStorage.getItem("pastProjectTaskId");
  const dispatch = useDispatch();

  // (image, name^,email^, phNumber^ , action => remove,change,report)
  const columnsValidators = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => index + 1,
    },
    {
      title: t("profile_img"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (value) => {
        return (
          <img
            width={45}
            height={45}
            style={{ borderRadius: 10 }}
            src={
              value ? appconstant.imageBaseUrl + value : AppImage.defaultImage
            }
          />
        );
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 300,
      sorter: true,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      sorter: true,
      width: 280,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("contact_num"),
      dataIndex: "phNumber",
      key: "phNumber",
      sorter: true,
      width: 280,
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      render: (value, record) => {
        return (
          <div
            style={{
              width: "max-content",
            }}
          >
            <button
              type="button"
              className="focus"
              onClick={() => {
                setRateTo(record?.id);
                setRatingVal(0);
                setIsRatingModalVisible(true);
              }}
            >
              {t("rate")}
            </button>
            <button
              type="button"
              className="focus"
              onClick={() => {
                setRateTo(record?.id);
                setIsModalReport(true);
                setToUserType(2);
              }}
            >
              {t("report")}
            </button>
            <button
              type="button"
              className="focus"
              onClick={() => {
                setShowCommentModal(true);
              }}
            >
              {t("view_cmnts")}
            </button>
          </div>
        );
      },
    },
  ];
  // (image, name^, phNumber^ , action => report,remove )

  const columnsWorkers = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => index + 1,
    },
    {
      title: t("profile_img"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (value) => {
        return (
          <img
            width={45}
            height={45}
            style={{ borderRadius: 10 }}
            src={
              value ? appconstant.imageBaseUrl + value : AppImage.defaultImage
            }
          />
        );
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 300,
      sorter: true,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("contact_num"),
      dataIndex: "phNumber",
      key: "phNumber",
      sorter: true,
      width: 280,
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      render: (value, record) => {
        return (
          <div>
            <button
              type="button"
              className="focus"
              onClick={() => {
                setRateTo(record?.id);
                setIsModalReport(true);
                setToUserType(1);
              }}
            >
              {t("report")}
            </button>
          </div>
        );
      },
    },
  ];
  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.PROJECT,
      label: t("projects"),
    },
    {
      link: NavigateTo.PAST_PROJECT_DETAILS,
      label: t("past_prj_details"),
    },
    {
      link: "",
      label: t("task_details"),
    },
  ];

  /* page description */
  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: "break-all",
  };
  const tn = [
    {
      labelTitle: `${t('tsk_name')}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo?.taskName,
      customLabelStyle: rightFontStyle,
    },
  ];
  const tt = [
    {
      labelTitle: `${t('tsk_typ')}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: t(taskInfo?.taskType),
      customLabelStyle: rightFontStyle,
    },
  ];
  const tcd = [
    {
      labelTitle: `${t('task_cmpltn_date')}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo?.endDate,
      customLabelStyle: rightFontStyle,
    },
  ];

  const td = [
    {
      labelTitle: `${t('tsk_desc')}:`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo?.description,
      customLabelStyle: rightFontStyle,
    },
  ];

  const textFieldlabels = [tn, tt, tcd, td];

  /* page description */

  /* drop down */

  // class list info ( file image, class name, annotations, action => view annotation )
  const classListHeaders = [
    {
      label: "",
      headerWidth: "200px",
    },
    {
      label: t("clss_name"),
      headerWidth: "400px",
    },
    {
      label: t("annotations"),
      headerWidth: "300px",
    },
    {
      label: t("action"),
      headerWidth: "300px",
      paddingLeft: "81px",
    },
  ];
  const audioLisHeaders = [
    {
      label: t("file_img"),
      headerWidth: "150px",
    },
    {
      label: t("file_name"),
      headerWidth: "150px",
    },
    {
      label: t("annotations"),
      headerWidth: "250px",
    },
    {
      label: t("action"),
      paddingLeft: "70px",
      headerWidth: "80px",
    },
  ]; // outer list table

  const dropDownGroup = [
    {
      table: {
        placeholder: t("cls_list"),
        listLength: classLists.length,
        tableName: "Classes",
        tableHeaders: classListHeaders,
        tableContents: classLists,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data owner-past-proj-details-task-details-class-list-ar"
            : "text_data owner-past-proj-details-task-details-class-list-en",
      },
    }, //class lists
    {
      table: {
        placeholder:
          taskInfo?.taskType == "audio" ? t("audio_list") : t("txt_list"),
        tableHeaders: audioLisHeaders,
        tableContents: taskInfo?.taskType == "audio" ? audioLists : textLists,
        tableContentsHasArrayOFObj: true,
        tdImageWidth: taskInfo?.taskType == "text" ? "unset" : null,
        tdImageHeight: taskInfo?.taskType == "text" ? "unset" : null,
        tdImageBorderRadius: taskInfo?.taskType == "text" ? "unset" : null,
        tBodyClassName: "last-child",
        AnnotClassName: "wptd",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data user-task-manage-worker-completed-task-details-class-list-ar"
            : "text_data user-task-manage-worker-completed-task-details-class-list-en",
      },
    }, // audio Lists
  ];

  /* drop down */

  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t("projects"),
  };

  const payload = {
    body: {
      taskId,
      type: "owner",
    },
    success: (res) => {
      let taskType =
        res?.data?.task_type == 1
          ? "image"
          : res?.data?.task_type == 2
            ? "text"
            : "audio";

      localStorage.setItem("taskType", taskType);

      setTaskInfo({
        taskName: res?.data?.name,
        taskType: taskType,
        instruction: res?.data?.instruction,
        description: res?.data?.description,
        endDate: res?.data?.end_date
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-"),
        instruction: res?.data?.instruction,
      });
      // validators
      const tempValidators = [];
      let tempComments = [];
      if (res?.data?.validators) {
        // setTotalValidators(res?.data?.validators?.length);
        res?.data?.validators.map((item) => {
          tempComments = item?.comment;
          tempValidators.push({
            id: item?.user_id?.id, //item?.custom_user?.id,
            image: item?.user_id?.image,
            name: item?.user_id?.first_name + " " + item?.user_id?.last_name,
            email: item?.user_id?.email,
            phNumber: item?.user_id?.phone_no, // remove,change,report
          });
        });
      }

      setVComments(tempComments);
      setDataSourceValidator(tempValidators);
      // workers
      // setTotalWorkers(res?.count);
      let tempWorker = [];
      res?.data?.workers.map((item) => {
        tempWorker.push({
          image: item?.user_id?.image,
          name: item?.user_id?.first_name + " " + item?.user_id?.last_name,
          phNumber: item?.user_id?.phone_no, // report, remove
          id: item?.user_id?.id,
        });
      });
      setDataSourceWorker(tempWorker);

      // let tempStatus =
      //   res?.data?.status == 0
      //     ? " pending"
      //     : res?.data?.status == 1
      //       ? "in-review"
      //       : res?.data?.status == 2
      //         ? "completed"
      //         : null;
      // setTaskStatus(tempStatus);

      if (res?.data?.task_type == 1) {
        localStorage.setItem("taskType", "Image");

        let classNames = [];
        res?.data?.classes.map((item) => {
          classNames.push(item?.name);
        });

        setClassNames(classNames);

        let tempClassLists = [];
        let tempImageArray = [];
        // let tempApiJsonImage = [];
        res?.data?.media.map((item, index) => {
          let imageDetails = {};
          imageDetails["annotationId"] = item?.id;
          imageDetails["jsonFile"] = item?.annotation; //item?.jsonFile?.regions;

          imageDetails["task_id"] = res?.data?.id;
          imageDetails["workerId"] = item?.worker_id; //res?.data?.workers[0].id;
          imageDetails["validator_id"] = res?.data?.validators[0].id;
          tempClassLists.push({
            0: { image: appconstant.imageBaseUrl + item?.path },
            1: classNames.join(","),
            2: item?.annotation ? item?.annotation?.length : 0,
            3: {
              button: "View Annotations",
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: imageDetails, // send anything like image id item?.id or annot details
            },
          }); // tempClassLists
          tempImageArray.push({
            name: `Image-${index + 1}`,
            src: appconstant.imageBaseUrl + item?.media_id?.path,
            // regions: item?.jsonFile != null ? item?.jsonFile?.regions : [],
            regions:
              item?.annotation.length != 0
                ? item?.annotation[item?.annotation.length - 1].jsonFile
                  ?.regions
                : [],
            id: item?.id,
          }); // tempImageArray
          // tempApiJsonImage.push({
          //   jsonFile: item?.jsonFile != null ? item?.jsonFile : [], // jsonFile
          //   task_id: item?.task_id,
          //   anotation_id: item?.id,
          //   worker_id: item?.worker_id?.id,
          // }); // tempApiJsonImage
        });
        setClassLists(tempClassLists);
        setImageArray(tempImageArray);

        // setApiJsonImage(tempApiJsonImage);
      } // image
      if (res?.data?.task_type == 2) {
        localStorage.setItem("taskType", "Text");
        if (res?.data?.text_type == "segmentation") {
          localStorage.setItem("textType", "segmentation");

          let tempTextLists = [];
          let tempClassLists = [];
          res?.data?.media.map((item) => {
            let textFileName = item?.path; //.substring(0, 15) + "..."; //.split("/");
            let textDetails = {
              jsonFileForStartTask:
                item?.annotation[item?.annotation.length - 1]?.Anotation_id
                  ?.jsonFile,
              jsonFile: item?.annotation,
              paragraph: item?.path,
              totalTasks: res?.data?.media?.length,

              task_id: item?.task_id,
              anotation_id: item?.id,
              worker_id: item?.worker_id,
              media_id: item?.id,
            };
            tempTextLists.push({
              0: { image: AppImage.file_n },
              1: textFileName, //textFileName[textFileName.length - 1],
              2: item?.annotation ? item?.annotation?.length : 0,
              3: {
                button: "View Annotations",
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: textDetails, // send anything like image id item?.id or annot details
              },
            }); // tempTextLists
          });
          setTextLists(tempTextLists);

          res?.data?.classes.map((item) => {
            tempClassLists.push({
              id: item?.id,
              displayName: item?.name,
              task_id: item?.task_id,
            });
          });
          setClassLists(tempClassLists);
        }
        if (res?.data?.text_type == "questions") {
          localStorage.setItem("textType", "questions");

          localStorage.setItem("textType", "questions");
          let tempTextLists = [];
          const tempQuestions = [];

          JSON.parse(res?.data?.question?.questionsData)?.map((item, index) => {
            tempQuestions.push({
              name: `input${index}`,
              type:
                item?.answerType == "Check-Box"
                  ? "checkbox"
                  : "radio",
              title: item?.question,
              choices: item?.answers,
            });
          });

          res?.data?.media.map((item) => {
            let textFileName = item?.path.split("/");
            let textDetails = {
              jsonFileForStartTask:
                item?.annotation[item?.annotation.length - 1]?.jsonFile,
              jsonFile: item?.annotation,
              pdfUrl: appconstant?.pdfBaseUrl + item?.path,
              task_id: item?.task_id,
              anotation_id: item?.id,
              worker_id: item?.worker_id,
              media_id: item?.id,
              totalTasks: res?.data?.media?.length,
            };
            tempTextLists.push({
              0: { image: AppImage.file_n },
              1: textFileName[textFileName.length - 1],
              2: item?.annotation ? item?.annotation?.length : 0,
              3: {
                button: "View Annotations",
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: textDetails, // send anything like image id item?.id or annot details
              },
            }); // tempTextLists
          });
          // setPdfUrl(tempPdfUrls);//res?.data?.pdfUrls
          setQuestions(tempQuestions);
          setTextLists(tempTextLists);
        }
      } // text
      if (res?.data?.task_type == 3) {
        localStorage.setItem("taskType", "Audio");

        let tempAudioArray = [];
        let tempAudioLists = [];

        res?.data?.media.map((item) => {
          let audioFileName = item?.path.split("/");
          let audioDetails = {};

          audioDetails["jsonFile"] = item?.annotation || [];
          audioDetails["audioUrl"] = appconstant.imageBaseUrl + item?.path;

          audioDetails["task_id"] = res?.data?.id;
          audioDetails["workerId"] = res?.worker_id; //res?.data?.workers[0].id;
          audioDetails["validator_id"] = res?.data?.validators[0].id;
          audioDetails["annotationId"] = item?.id;

          tempAudioLists.push({
            0: { image: AppImage.volumeFileImg },
            1: audioFileName[audioFileName.length - 1],
            2: item?.annotation ? item?.annotation.length : 0,
            3: {
              button: "View Annotations",
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: audioDetails, // send anything like image id item?.id or annot details
            },
          }); // tempAudioLists

          tempAudioArray.push({
            // name: `Audio-${index + 1}`,
            url: appconstant.imageBaseUrl + item?.path,
            jsonFile: item?.annotation != null ? item?.annotation : [], // jsonFile
            task_id: item?.task_id,
            anotation_id: item?.id,
            worker_id: item?.worker_id?.id,
          });
        });
        setAudioLists(tempAudioLists);
        // setAudio(tempAudioArray);
      } // audio
    },
    fail: (resf) => {
      resf?.data &&
        toast.error(resf?.data, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
        });
      resf?.message &&
        toast.error(resf?.message, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
        });
    },
  }; //  task details info payload
  const payloadRate = {
    body: JSON.stringify({
      rate: ratingVal,
      user_by: rateTo, // in api way user_to
      user_to: Number(localStorage.getItem("loggedInUserId")), // in api way user_by
    }),
    success: () => {
      toast.success(t("rating_done"), {
        className:
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,
      });
    },
    fail: (resf) => {
      console.log(resf, "resf of  validator listing.");
    },
  }; //  rate payload
  const payloadReport = {
    body: JSON.stringify({
      reported_by: Number(localStorage.getItem("loggedInUserId")),
      reported_to: rateTo,
      type: toUserType, //1 worker , 2 validator
      // "Anotation_id":anotation_id,
      task_id: taskId,
    }),
    success: (res) => {
      toast.success(res, {
        className:
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,
      });
    },
    fail: (resf) => {
      console.log(resf, "resf of  validator listing.");
    },
  }; //  report payload

  useEffect(() => {
    dispatch(saveTaskId(taskId));
    isInternetConnected() && dispatch(getTaskDetails(payload)); // task deatils
  }, []);
  // useEffect(() => { }, [isRatingVisible, isAnnotation]);
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isReport, isRatingVisible, showCommentModal]);

  useEffect(() => {
    dataSourceWorker.sort((a, b) => {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;
      return 0;
    });
    //TODO: check if this is working
  }, [ordering])
  return (
    <Container
      {...containerProps}
      // languageChanged={() => {
      //   setLang(localStorage.getItem("lang"));
      //   // it is reuqired to re render this whole component
      // }}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"}>
        <CustomPageHeader
          label={t('task_details')}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px",
            marginTop: "30px",
          }}
        >
          <CustomPageHeader
            label={`${t('status')}: ${t('completed')}`}
            wrapperClassName={
              "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
            }
            customWrapperStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginLeft: 10 }}>
              <img src={AppImage.completedTask} />
            </div>
          </CustomPageHeader>
        </CustomPageHeader>
        <div className="wrapper_line owner-ongoingprojdetails">
          {/* pageDescription */}
          {textFieldlabels.map((item) => {
            return <TextField labels={item} key={item} />;
          })}
          {/* pageDescription */}
          {/* task Instruction */}
          <label style={leftFontstyle}>{t("instructions")}:</label>
          <div
            className="get-original-text-color"
            style={{
              marginTop: "12px",
            }}
          >
            <div className="ck-content">{parse(taskInfo.instruction)}</div>
          </div>
          {/* task Instruction */}
          {/* dropdowns */}

          {taskInfo.taskType == "image" ||
            taskInfo.taskType == "segmentation" ? (
            // class list
            <Collapse
              defaultActiveKey={["1"]}
              onChange={() => {
                const isDropdownOpened = document
                  .querySelector(".ant-collapse-header")
                  .getAttribute("aria-expanded");
                const dropdownArrowsOnClickStyle = document.querySelectorAll(
                  ".ant-collapse-arrow svg"
                );
                if (isDropdownOpened == "true") {
                  // .setAttribute('style','transform: rotate(-90deg);');
                } else {
                  dropdownArrowsOnClickStyle[0].setAttribute(
                    "style",
                    "transform: rotate(-90deg) !important;"
                  );
                  // dropdownArrowsOnClickStyle[i].setAttribute('style','transform: rotate(90deg);');
                }
              }}
            >
              <Panel header={dropDownGroup[0].table.placeholder} key="1">
                {classLists.length > 0 ? (
                  <CustomTable
                    {...dropDownGroup[0].table}
                    handleClick={(label, id, link, row2, trIndex) => {
                      switch (label) {
                        case "View Annotations": {
                          if (id != null) {
                            let tempAnnotDetails = [];
                            const annots = id;
                            annots?.jsonFile?.map((item) => {
                              tempAnnotDetails.push({
                                annotation_name:
                                  item?.Anotation_id?.regions.type,
                                class: item?.Anotation_id?.regions.cls,
                                file_id:
                                  // Number(
                                  //   String(
                                  item?.Anotation_id?.regions.id,
                                // ).substring(0, 8)
                                // )
                                worker:
                                  item?.worker_id?.user_id?.first_name +
                                  " " +
                                  item?.worker_id?.user_id?.last_name,

                                // for raise dispute
                                annotationId: annots?.annotationId,
                                // for buttons to show if raise dispute or view comment or disable raise dispute
                                annotStatus: annots?.annotStatus,
                                // to show vComments
                                vComments: annots?.vComments,
                                annotDetails: item?.Anotation_id?.jsonFile,
                              });
                            });
                            setAnnotationDS(tempAnnotDetails);
                            setAnnotDetails(id);
                          }
                          localStorage.setItem("imageIndex", trIndex);
                          setImageIndex(trIndex);
                          setIsModalVisible(true);
                          break;
                        }
                        case "Rate": {
                          setIsRatingModalVisible(true);
                          break;
                        }
                        case "Report":
                          setIsModalReport(true);
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                ) : (
                  <CustomEmptyDiv title={t("clss_appear_here")} />
                )}
              </Panel>
            </Collapse>
          ) : (
            // audio list
            <Collapse defaultActiveKey={["1"]} onChange={() => { }}>
              <Panel header={dropDownGroup[1].table.placeholder} key="1">
                {audioLists.length > 0 || textLists.length > 0 ? (
                  <CustomTable
                    {...dropDownGroup[1].table}
                    handleClick={(label, id/* , link, row2, trIndex */) => {
                      switch (label) {
                        case "View Annotations": {
                          if (taskInfo?.taskType == "audio") {
                            if (id != null) {
                              let tempAnnotDetails = [];
                              const audioDetails = id;
                              audioDetails?.jsonFile.map((item) => {
                                tempAnnotDetails.push({
                                  annotation_name:
                                    item?.Anotation_id?.regions?.data?.note,
                                  annotationId: item?.id,
                                  worker:
                                    item?.worker_id?.user_id?.first_name +
                                    " " +
                                    item?.worker_id?.user_id?.last_name,

                                  url: item?.Anotation_id?.url,
                                  jsonFile: [item?.Anotation_id?.regions],
                                });
                              });
                              setAnnotationAudioDS(tempAnnotDetails); // data to show for Audio annotation list table of modal
                              setAnnotDetails(id); // all annotations for single audio
                            }
                            // setAudioIndex(trIndex);
                            setIsModalVisible(true);
                          }
                          if (taskInfo?.taskType == "text") {
                            if (id != null) {
                              if (
                                localStorage.getItem("textType") ==
                                "segmentation"
                              ) {
                                const textDetails = id;

                                let tempAnnotDetails = [];
                                textDetails?.jsonFile.map((item) => {
                                  const annotName =
                                    item?.Anotation_id?.regions?.text;

                                  tempAnnotDetails.push({
                                    annotation_name:
                                      // annotName.length > 10
                                      //   ? annotName.substring(0, 10)
                                      //   :
                                      annotName,
                                    status:
                                      item?.status == 3
                                        ? "Rejected"
                                        : item?.status == 2
                                          ? "Approved"
                                          : item?.status == 4
                                            ? "Disputed"
                                            : "Pending",
                                    // for raise dispute
                                    annotationId: item?.id,
                                    // for buttons to show if raise dispute or view comment or disable raise dispute
                                    annotStatus: item?.status,
                                    // to show vComments
                                    vComments: item?.comment,
                                    worker:
                                      item?.worker_id?.user_id?.first_name +
                                      " " +
                                      item?.worker_id?.user_id?.last_name,
                                    validator:
                                      item?.validator_id?.user_id?.first_name +
                                      " " +
                                      item?.validator_id?.user_id?.last_name,
                                    paragraph: item?.paragraph,
                                    particularAnnot: [
                                      item?.Anotation_id?.regions,
                                    ],
                                    segmentAllAnnots:
                                      textDetails?.jsonFileForStartTask,
                                    totalAnnots: item?.jsonFile,
                                  });
                                });
                                setAnnotationTextDS(tempAnnotDetails);
                              }
                              if (
                                localStorage.getItem("textType") == "questions"
                              ) {
                                const textDetails = id;
                                let tempAnnotDetails = [];
                                textDetails?.jsonFile.map((item) => {
                                  const questionIndex =
                                    item?.Anotation_id?.region_id;
                                  const annotName =
                                    questions[
                                      questionIndex.split("")[
                                      questionIndex.length - 1
                                      ]
                                    ]?.title;
                                  tempAnnotDetails.push({
                                    annotation_name: annotName,
                                    status:
                                      item?.status == 3
                                        ? "Rejected"
                                        : item?.status == 2
                                          ? "Approved"
                                          : item?.status == 4
                                            ? "Disputed"
                                            : "Pending",
                                    // for raise dispute
                                    annotationId: item?.id,
                                    // for buttons to show if raise dispute or view comment or disable raise dispute
                                    annotStatus: item?.status,
                                    // to show vComments
                                    vComments: item?.comment,
                                    questions: [
                                      questions[
                                      questionIndex.split("")[
                                      questionIndex.length - 1
                                      ]
                                      ],
                                    ],
                                    // pdfUrl: textDetails?.pdfUrl,
                                    pdfUrl: item?.Anotation_id?.media_id?.path,
                                    savedAnnotation: {
                                      [item?.Anotation_id?.region_id]: item
                                        ?.Anotation_id?.regions,
                                    },

                                    workerId: item?.worker_id?.id,
                                    validator_id: item?.validator_id?.id,
                                    annotation_id: item?.Anotation_id?.id,

                                    worker:
                                      item?.worker_id?.user_id?.first_name +
                                      " " +
                                      item?.worker_id?.user_id?.last_name,
                                    validator:
                                      item?.validator_id?.user_id?.first_name +
                                      " " +
                                      item?.validator_id?.user_id?.last_name,
                                  });
                                });
                                setAnnotationTextDS(tempAnnotDetails);
                                setAnnotDetails(id);
                              }
                            }
                            setIsModalVisible(true);
                          }

                          break;
                        }
                        case "Rate": {
                          setIsRatingModalVisible(true);
                          break;
                        }
                        case "Report":
                          setIsModalReport(true);
                          break;
                        default:
                          break;
                      }
                    }} // for buttons in a row of table
                  />
                ) : (
                  <CustomEmptyDiv
                    title={`${taskInfo?.taskType == "audio"
                      ? t("audio_list")
                      : t("txt_list")
                      } ${t('wil_appear_here')}`}
                  />
                )}
              </Panel>
              {/* Audio */}
            </Collapse>
          ) // Audio
          }

          {/* workers list start*/}
          <div className="form-group" style={{ marginTop: "5%" }}>
            <label
              style={{
                marginBottom: "-13px",
                fontWeight: "600",
                fontSize: "16px",
                // marginTop: "45px",
              }}
            >
              {t("wrkr_list")}
            </label>
            <CardHoc
              cardInnerPadding={"content-e"}
              cardPadding={"0px"}
              minHeight={0}
            >
              {dataSourceWorker.length != 0 ? (
                <div id="paginationOfgroup">
                  <Table
                    dataSource={dataSourceWorker}
                    columns={columnsWorkers}
                    pagination={true}
                    showSorterTooltip={false}
                    onChange={(pagination, filters, sorter) => {
                      // setTableName("worker");
                      if (sorter.order == undefined) {
                        setOrdering("");
                      }

                      if (
                        sorter.order == "ascend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "name"
                      ) {
                        setOrdering("first_name");
                      }
                      if (
                        sorter.order == "descend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "name"
                      ) {
                        setOrdering("-first_name");
                      }

                      if (
                        sorter.order == "ascend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "phNumber"
                      ) {
                        setOrdering("phone_no");
                      }
                      if (
                        sorter.order == "descend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "phNumber"
                      ) {
                        setOrdering("-phone_no");
                      }
                    }}
                  />
                </div>
              ) : (
                <CustomEmptyDiv title={t("wrkr_appear_here")} />
              )}
            </CardHoc>
          </div>
          {/* workers list end*/}

          {/* dropdowns */}

          {/* validators list start*/}
          <div className="form-group">
            <label
              style={{
                marginBottom: "-13px",
                fontWeight: "600",
                fontSize: "16px",
                marginTop: "45px",
              }}
            >
              {t('validators_list')}
            </label>
            <CardHoc
              cardInnerPadding={"content-e"}
              cardPadding={"0px"}
              minHeight={0}
            >
              {dataSourceValidator.length != 0 ? (
                <div
                  id="paginationOfgroup"
                  className="vlist-pastproj-task-details"
                >
                  <Table
                    dataSource={dataSourceValidator}
                    columns={columnsValidators}
                    pagination={true}
                    showSorterTooltip={false}
                    onChange={(pagination, filters, sorter) => {
                      // setTableName("validator");
                      if (sorter.order == undefined) {
                        setOrdering("");
                      }
                      if (
                        sorter.order == "ascend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "name"
                      ) {
                        setOrdering("first_name");
                      }
                      if (
                        sorter.order == "descend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "filetype"
                      ) {
                        setOrdering("-first_name");
                      }
                      if (
                        sorter.order == "ascend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "email"
                      ) {
                        setOrdering("email");
                      }
                      if (
                        sorter.order == "descend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "email"
                      ) {
                        setOrdering("-email");
                      }
                      if (
                        sorter.order == "ascend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "phNumber"
                      ) {
                        setOrdering("phone_no");
                      }
                      if (
                        sorter.order == "descend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "phNumber"
                      ) {
                        setOrdering("-phone_no");
                      }
                    }}
                  />
                </div>
              ) : (
                <CustomEmptyDiv title={t("val_appear_here")} />
              )}
            </CardHoc>
          </div>
          {/* validators list end*/}

          {/* AnnotationsList modal */}
          <AnnotationModal
            // image class details
            classNames={classNames}
            imageArray={imageArray}
            imageIndex={imageIndex}
            annotDetails={annotDetails}
            annotationDS={annotationDS}
            // audio details
            annotationAudioDS={annotationAudioDS}
            // text
            annotationTextDS={annotationTextDS}
            classLists={classLists}
            // projId={projId}
            isModalVisible={isModalVisible}
            hideModal={(hide) => {
              setIsModalVisible(hide);
            }}
          />

          {/* report validator */}
          <Modal
            title={t('report')}
            open={isReport}
            onOk={() => setIsModalReport(false)}
            onCancel={() => setIsModalReport(false)}
            footer={null}
            centered
          >
            <div className="text-center  model1">
              <p>{t('report')}</p>
            </div>
            <div
              className="text-center  model1"
              style={{
                direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
              }}
            >
              <button
                type="submit"
                className="button-list top_new focus"
                onClick={() => {
                  isInternetConnected() && dispatch(report(payloadReport));
                  setIsModalReport(false);
                }}
              >
                {t('yes')}
              </button>
              <button
                type="submit"
                className="focus"
                onClick={() => setIsModalReport(false)}
              >
                {t('no')}
              </button>
            </div>
          </Modal>

          {/* rating modal */}
          <Modal
            title={t('rating')}
            open={isRatingVisible}
            onOk={() => {
              setIsRatingModalVisible(false);
            }}
            onCancel={() => {
              setIsRatingModalVisible(false);
            }}
            footer={null}
            centered
            className="rating-modal"
          >
            <div className="text-center  model1" style={{ marginBottom: 40 }}>
              {/* <img src={AppImage.star12} />  */}
              <Rate
                className="stars test1"
                allowHalf
                value={ratingVal}
                onChange={(val) => {
                  setRatingVal(val);
                }}
              />
            </div>
            <div
              className="text-center  model1"
              style={{
                marginBottom: "10px",
              }}
            >
              <button
                type="submit"
                className="button-list top_new focus"
                onClick={() => {
                  if (ratingVal) {
                    isInternetConnected() && dispatch(giveRate(payloadRate));
                    toast.success(t("rating_gvn_scsfl"), {
                      className:
                        localStorage.lang == "ar"
                          ? "Select_data ar"
                          : "Select_data en",
                      position:
                        localStorage.lang == "ar"
                          ? toast.POSITION.TOP_LEFT
                          : toast.POSITION.TOP_RIGHT,
                      toastId: "rating",
                    });
                    setIsRatingModalVisible(false);
                  } else {
                    toast.error(t("enter_rating"), {
                      className:
                        localStorage.lang == "ar"
                          ? "Select_data ar"
                          : "Select_data en",
                      position:
                        localStorage.lang == "ar"
                          ? toast.POSITION.TOP_LEFT
                          : toast.POSITION.TOP_RIGHT,
                    });
                  }
                }}
              >
                {t('done')}
              </button>
            </div>
          </Modal>

          {/* Comment modal */}
          <Modal
            title={t("val_cmnts")}
            style={{
              paddingTop: 20,
              paddingBottom: 20,
            }}
            open={showCommentModal}
            onOk={() => {
              setShowCommentModal(false);
            }}
            onCancel={() => {
              setShowCommentModal(false);
            }}
            footer={null}
            centered
          >
            <div className="text-center  model1">
              <div className="form-group wrapper_line models">
                <div className="new_new_data">
                  {vComments.length > 0 ? (
                    <>
                      {/* comments */}
                      <div className="data_news poolworkers_ppop">
                        {vComments.map((item, index) => {
                          return (
                            // row //
                            <div
                              key={index}
                              className="sperator_pool"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {/* comments */}
                              <div
                                className="first_text"
                                style={{
                                  // width: "215px",
                                  paddingTop: "5px",
                                  textAlign: "start",
                                  paddingBottom: "5px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  textAlign: "start",
                                }}
                              >
                                <Tooltip
                                  title={item?.comment}
                                  key={"#143C64"}
                                  placement="right"
                                >
                                  {item?.comment}
                                </Tooltip>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <CustomEmptyDiv
                      marginTop={30}
                      title={t("val_cmnts_appear_here")}
                    />
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </CardHoc>
    </Container>
  );
};

export default PastProjectTaskDetails;
