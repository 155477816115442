import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import images from "../themes/appImage";
import { AppImage } from "../themes";
import { isInternetConnected } from "../themes/utils/internetConnectionn";
import { logout } from "../redux/actions/Common";
import { useDispatch } from "react-redux";
import i18n from "i18next";
const { Sider } = Layout;

const Sidebar = (props) => {
  const {
    menuProps, // define in src/themes/sidebarMenus.js
    activeItem,
    isHamClicked,
    hamUnclicked = () => {},
  } = { ...props };

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const onMenuClick = (e) => { 
    navigate(e.key);
    const siderComponent = document.querySelector(
      "section.ant-layout.ant-layout-has-sider .ant-layout-sider"
    );
    const userProfileComponent = document.querySelector(
      ".select_new.user_new_select.user_specific"
    );
    if (localStorage.getItem("lang") == "ar") {
      if (localStorage.getItem("user_type") == 3) {
        userProfileComponent.setAttribute(
          "style",
          "width: 222px;right: 399px;top: 26px;"
        );
      }
      siderComponent.setAttribute("style", "right:0 !important;width:250px");
    } else {
      siderComponent.setAttribute("style", "left:0;");
      // contentsComponent.setAttribute("style", "margin-right: unset;margin-left:auto");
      if (localStorage.getItem("user_type") == 3) {
        userProfileComponent.setAttribute(
          "style",
          "width: 222px;right: 132px;top: 26px;"
        );
      }
    }
  };
  let activeKey = "";
  const [ham, setHam] = useState(isHamClicked);
  const [widthResized, setWidthResized] = useState(window.innerWidth);
  const lang = localStorage.getItem("lang");

  useEffect(() => {
    function handleResize() { 
      setWidthResized(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    setHam(isHamClicked);
     
  }, [isHamClicked,ham,lang]); 
  
// console.log(widthResized,'widthRe  sized',ham,'ham',isHamClicked,'isHamClicked');
  return (
    
    <Sider
      className="full-width"
      style={{
        display: widthResized <= "991" && ham ? "none" : "unset",
        right: lang == "ar" ? 0 : null,
      }}
      collapsedWidth="0"
      width={widthResized <= "991" && ham ? "100%" : "250px"}
    >
      <div className="logo">
        <Link to={menuProps[0].link}>
          <img src={images.logo} />
        </Link>
      </div>

      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={[activeKey]}
        selectedKeys={[activeKey]}
        onClick={(e) => {
          if (
            (e.key == "/login" && isInternetConnected()) ||
            e.key != "/login"
          ) {
            onMenuClick(e);
          }
        }}
      >
        {menuProps.map((item, index) => {
          return (
            <Menu.Item
              style={{
                backgroundColor: activeItem == item.label ? "#022649" : null,
              }}
              id={index}
              key={item.link}
            >
              <Link
                className="focus"
                onClick={() => {
                  if (item.label == i18n.t("logout")) {
                    isInternetConnected() && dispatch(logout("i"));
                  }
                }}
                to=""
              >
                {localStorage.getItem("lang") == "ar" ? (
                  <div
                    className="sidebar-menu-items-container"
                    style={{
                      width: "214px",
                      textAlign: "end",
                    }}
                  >
                    <span
                      style={{
                        marginRight: "7px",
                      }}
                      className="line_dash"
                    >
                      {item.label}
                    </span>
                    <img src={item.icon} />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "214px",
                      textAlign: "start",
                    }}
                  >
                    <img src={item.icon} />
                    <span className="line_dash">{item.label}</span>
                  </div>
                )}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
      {widthResized <= "991" ? (
        <div
          style={{
            position: "absolute",
            content: "",
            top: "9px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            hamUnclicked();
            // setHam(!isHamClicked);
            setHam(true);
          }}
        >
          <img width={"30px"} src={AppImage.crossIcon} />
        </div>
      ) : null}
    </Sider>
     
  );
};
export default Sidebar;
