import { Modal, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CustomEmptyDiv } from "../../../../../molecules";
import { NavigateTo, SidebarMenus } from "../../../../../themes";

const AnnotationModal = (props) => {
  const {
    classNames,
    imageArray,
    imageIndex,
    // annotDetails,
    // projId,
    annotationDS,
    annotationTextDS,
    classLists,
    isModalVisible,
    annotationAudioDS,
    // udtBreadCrumbsAdmin,
    udtContainerPropsAdmin,
    hideModal = () => { },
  } = { ...props };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const taskId = useSelector((state) => state?.userTaskManagement?.taskId);
  const taskType = localStorage.getItem("taskType");
  const showAccpetModal = false;

  const udtBreadCrumbsEn = udtContainerPropsAdmin?.breadCrumbItems?.en || [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: "Dashboard",
    },
    {
      link: NavigateTo.PROJECT,
      label: "Projects",
    },
    {
      link: NavigateTo.ONGOING_PROJECT_DETAILS,
      label: "Ongoing Project Details",
    },
    {
      link: NavigateTo.TASK_DETAILS + `/${taskId}`,
      label: "Task Details",
    },
    {
      link: "",
      label: "Annotate",
    },
  ];
  const udtBreadCrumbsAr = udtContainerPropsAdmin?.breadCrumbItems?.ar || [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: "لوحة القيادة",
    },
    {
      link: NavigateTo.PROJECT,
      label: "المشاريع",
    },
    {
      link: NavigateTo.ONGOING_PROJECT_DETAILS,
      label: "تفاصيل المشروع الجاري",
    },
    {
      link: NavigateTo.TASK_DETAILS + `/${taskId}`,
      label: "تفاصيل المهمة",
    },
    {
      link: "",
      label: "وسم",
    },
  ];
  const udtContainerProps = udtContainerPropsAdmin || {
    breadCrumbItems: {
      en: udtBreadCrumbsEn,
      ar: udtBreadCrumbsAr
    },
    menuProps: SidebarMenus("owner"),
    active: t("projects"),
  };
  // image table columns
  const annotationC = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      width: 150,
      render: (value, item, index) => index + 1,
    },
    {
      title: t("worker_name"),
      dataIndex: "worker",
      key: "worker",
      width: 100,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip title={val} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("val_name"),
      dataIndex: "validator",
      key: "validator",
      width: 100,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip title={val} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("annot_name"),
      dataIndex: "annotation_name",
      key: "annotation_name",
      width: 200,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip title={t(val)} placement="topLeft">
            {t(val)}
          </Tooltip>
        );
      },
    },
    {
      title: t("file_id"),
      dataIndex: "file_id",
      key: "file_id",
      width: 100,
      sorter: false,
      render: (value) => {
        const tempVal = localStorage.lang == 'ar' ? value.split('').reverse().concat('') : value;

        return (
          <Tooltip placement="topLeft" title={tempVal}>
            {tempVal}
          </Tooltip>
        );
      }
    },
    {
      title: t("class"),
      dataIndex: "class",
      key: "class",
      width: 250,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip title={val} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
    },
    /*{
      title: t("status"),
      dataIndex: "status",
      key: "status",
      width: 200,
      sorter: false,
      render : (i,rec)=> t(rec?.statusToShow)
    },*/
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text, record) => {
        return (
          <div
            style={{
              marginLeft: "12px",
            }}
          >
            {/* view button */}
            <button
              type="button"
              className="focus"
              onClick={() => {
                navigate(
                  udtContainerPropsAdmin
                    ? NavigateTo.USER_UDT_ADMIN + `/${taskId}`
                    : NavigateTo.USER_UDT_OWNER +
                    `/${taskId}/${localStorage.getItem("imageIndex")}`,
                  {
                    state: {
                      udtContainerProps,
                      taskType,
                      removeIcos: true,
                      classLists: classNames,
                      images: imageArray,
                      imageIndex: imageIndex,
                      particularAnnotation: record?.annotDetails,
                    },
                  }
                );
              }}
            >
              {t("view")}
            </button>
          </div>
        );
      },
    },
  ];

  //audio table columns.
  const annotationAudioC = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      width: 150,
      render: (value, item, index) => index + 1,
    },
    {
      title: t("worker_name"),
      dataIndex: "worker",
      key: "worker",
      width: 100,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip title={val} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("val_name"),
      dataIndex: "validator",
      key: "validator",
      width: 100,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip title={val} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("annot_name"),
      dataIndex: "annotation_name",
      key: "annotation_name",
      width: 200,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip title={val} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
    },
    /*{
      title: t("status"),
      dataIndex: "status",
      key: "status",
      width: 200,
      sorter: false,
      render : (i,rec)=> t(rec?.statusToShow)
    },*/
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text, record, index) => {
        return (
          <div
            style={{
              marginLeft: "12px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* view button */}
            <button
              type="button"
              className="focus"
              onClick={() => {
                let singleAudio = [
                  {
                    url: record?.url,
                    jsonFile: record?.jsonFile, // jsonFile
                    task_id: 1,
                    anotation_id: 1,
                    worker_id: 1,
                  },
                ];

                navigate(
                  udtContainerPropsAdmin
                    ? NavigateTo.USER_UDT_ADMIN + `/${taskId}`
                    : NavigateTo.USER_UDT_OWNER + `/${taskId}`,
                  {
                    state: {
                      taskType,
                      udtContainerProps,
                      audio: singleAudio,
                    },
                  }
                );

                if (localStorage.getItem("taskType") == "Text") {
                  if (localStorage.getItem("textType") == "questions") {
                    navigate(
                      udtContainerPropsAdmin
                        ? NavigateTo.USER_UDT_ADMIN + `/${taskId}`
                        : NavigateTo.USER_UDT_OWNER + `/${taskId}`,
                      {
                        state: {
                          taskType,
                          udtContainerProps,
                          pdfUrl: record?.pdfUrl,
                          questions: record?.questions,
                          particularAnnotation: record?.savedAnnotation,
                          pdfUrl: record?.pdfUrl,
                          qnindex: index,
                        },
                      }
                    );
                  }
                  if (localStorage.getItem("textType") == "segmentation") {
                    navigate(
                      udtContainerPropsAdmin
                        ? NavigateTo.USER_UDT_ADMIN + `/${taskId}`
                        : NavigateTo.USER_UDT_OWNER + `/${taskId}`,
                      {
                        state: {
                          taskType,
                          udtContainerProps,
                          particularAnnotation: record?.particularAnnot,
                          segmentAllAnnots: record?.segmentAllAnnots,
                          classes: classLists,
                          paragraph: record?.paragraph,
                        },
                      }
                    );
                  }
                }
              }}
            >
              {t("view")}
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    // setIsModalVisible(modalVisible);
  }, [showAccpetModal, annotationAudioDS]);
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll('button[aria-label="Close"]');
    if (modalPopUpCrossButton && localStorage.getItem('lang') == 'ar') {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = 'unset';
        modalPopUpCrossButton[i].style.left = '0';
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem('lang') == 'en') {

      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = '0';
        modalPopUpCrossButton[i].style.left = 'unset';
      }
    }
  }, [isModalVisible]);
  return (
    <>
      {/* annotation modal */}
      <Modal
        title={t("annot_list")}
        centered
        footer={null}
        onOk={() => {
          hideModal(false);
        }}
        width="75%"
        onCancel={() => {
          {
            hideModal(false);
          }
        }}
        open={isModalVisible}
        wrapClassName="poolworkers"
        // className="validator-pending-annot-modal"
        className={localStorage.lang == 'ar' ? 'validator-pending-annot-modal ' : 'validator-pending-annot-modal'}//Select_data ar
      >
        {
          <div
            className={`text-center modal2 remove-left-pad vct-modal  AnnotClassName `}
          >
            <div
              className={
                localStorage.lang == "en"
                  ? "new_layout en form-group wrapper_line models"
                  : "new_layout ar form-group wrapper_line models"
              }
              style={{
                direction: localStorage.lang == "ar" ? "rtl" : "ltr",
              }}
            >
              {annotationAudioDS?.length ||
                annotationDS?.length ||
                annotationTextDS?.length ? (
                <Table
                  pagination={true}
                  columns={
                    localStorage.getItem("taskType") == "Audio"
                      ? annotationAudioC
                      : localStorage.getItem("taskType") == "Image"
                        ? annotationC
                        : annotationAudioC
                  }
                  showSorterTooltip={false}
                  dataSource={
                    localStorage.getItem("taskType") == "Audio"
                      ? annotationAudioDS
                      : localStorage.getItem("taskType") == "Image"
                        ? annotationDS
                        : annotationTextDS
                  }
                  className={
                    // localStorage.lang  == 'ar' ?
                    "udt worker-completed-taskdetails-annot ongoing-proj-task-details  " //pagination-component ar
                    //  :
                    //  "udt worker-completed-taskdetails-annot ongoing-proj-task-details  pagination-component"
                  }
                />
              ) : (
                <CustomEmptyDiv title={t("annots_wil_appear_here")} />
              )}
            </div>
          </div>
        }
      </Modal>
    </>
  );
};

export default AnnotationModal;
