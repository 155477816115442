import React, { useEffect } from "react";
// import images from "../themes/appImage";
import NavigateTo from "../../themes/routeNames";

import { Link } from "react-router-dom";
import images from "../../themes/appImage";
import { Constants } from "../../themes";
import PropTypes from "prop-types";
const HeaderLanding = ({
  toogled,
  sticky,
}) => {
  const loggedin_user_type = (localStorage.getItem('user_type') != null && localStorage.getItem('user_type')) || null;

  const navigateToDashboard = (userType) => {
    if (userType == 'admin') {

      return NavigateTo.ADMIN_DASH_BOARD

      // return NavigateTo.ADMIN_DASH_BOARD 
    }
    if (userType == 'owner') {
      if (localStorage.getItem('goToDashboard') == 'true' && localStorage.getItem('goToDashboard') != null)
        return NavigateTo.OWNER_DASHBOARD
      else return NavigateTo.LOGIN
      // return NavigateTo.OWNER_DASHBOARD
    }
    if (userType == 'performer') {
      if (localStorage.getItem('goToDashboard') == 'true' && localStorage.getItem('goToDashboard') != null)
        return NavigateTo.USER_DASHBOARD
      else return NavigateTo.LOGIN
    }
    else {
      return NavigateTo.LOGIN
    }

  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <header
      className="headers"
      style={{
        backgroundColor: sticky ? "rgba(0, 0, 0, 0.8)" : "transparent",
      }}
    >
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div
            className={
              toogled
                ? "collapse navbar-collapse  justify-content-end show"
                : "collapse navbar-collapse  justify-content-end "
            }
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/landing" class="nav-link active">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a href="#about_usone" className="nav-link">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contactusone">
                  Contact Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link login_button" href={navigateToDashboard(loggedin_user_type)}>
                  {
                    (localStorage.getItem('goToDashboard') == 'true' && localStorage.getItem('goToDashboard') != null)
                      ?
                      Constants.home
                      :
                      Constants.Login
                  }
                  {/* {loggedin_user_type!= null ? Constants.home : Constants.Login} */}
                </a>
              </li>
            </ul>
          </div>

          <a
            className="navbar-brand"
            href="javascript:void(0);"
            style={{ cursor: "default" }}
          >
            <img src={images.logo} alt="" />{" "}
          </a>
        </nav>
      </div>
    </header>
  );
};

HeaderLanding.propTypes = {
  toogled: PropTypes.bool,
  sticky: PropTypes.bool,
};
export default HeaderLanding;
