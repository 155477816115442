import { LOGOUT, USER_ENABLE_DISABLE_LOAD, USER_ENABLE_DISABLE_SUCCESS, USER_TABLE_ERROR, USER_TABLE_LOAD, USER_TABLE_SUCCESS }
    from "./../../actionTypes/actionTypes";


const initialState = {
    isLoading: false,
    error: null,
    status: 0,
    paging: {},
    isBlock: false,

    data: []
}

const userTableReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
                isLoading: false,
                error: null,
                status: 0,
                paging: {},
                isBlock: false,
            
                data: []
            };
        case USER_TABLE_LOAD:
            return { ...state, isLoading: true, error: null }

        case USER_TABLE_SUCCESS:
            return {
                ...state,
                data: action.result.list,
                isLoading: false,
                status: action.status,
                paging: action.result.paging
            }
        case USER_ENABLE_DISABLE_LOAD:
            return { ...state, isLoading: true, error: null, enableStatus: false }

        case USER_ENABLE_DISABLE_SUCCESS:
            let id = action.value;
            let userListing = [...state.data];
            let userBlockIndex = userListing.findIndex(user => user._id === id);
            let value = true;
            if (userBlockIndex > -1) {
                value = !userListing[userBlockIndex].isBlock;
                userListing[userBlockIndex].isBlock = value;
            }
            state.data = userListing;
            return {
                ...state,
                result: action.result,
                editData: action.result.data,
                isLoading: false,
                status: action.status,

            }
        case USER_TABLE_ERROR:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state;
    }
}

export default userTableReducer;