import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from "prop-types";
const PrivateRoute = ({ ut, children }) => {
  const user_type = localStorage.getItem('user_type');
  const tokenCheckForRouting = localStorage.getItem('tokenCheckForRouting');
  if (tokenCheckForRouting && user_type === ut) {
    return children
  }
  return (<Navigate to="/" />);
};

const PublicRoute = ({ children }) => {
  return !localStorage.getItem('tokenCheckForRouting') ? children : <Navigate to="/" />;
};

PrivateRoute.propTypes = {
  ut: PropTypes.string,
  children: PropTypes.node,
}
PublicRoute.propTypes = {
  isAuth: PropTypes.bool,
  children: PropTypes.node,
}


export { PrivateRoute, PublicRoute };