import Fetch from "../Fetch/AnnotationApi";
import { toast } from "react-toastify";
import { put, call } from "redux-saga/effects";
import { startLoading, stopLoading } from "../actions/Common";

export function* getAnnotationListsSaga({
  payload: { success = () => {}, fail = () => {}, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getAnnotationListsApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* saveAnnotationsSaga({
  payload: { success = () => {}, fail = () => {}, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.saveAnnotationsSagaApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      toast.error(result?.result?.data?.message, {
        className:
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,
      });
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* saveValidationsSaga({
  payload: { success = () => {}, fail = () => {}, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.saveValidationsSagaApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      toast.error(result?.result?.data?.message, {
        className:
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,
      });
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}

// VALIDATOR PENDING TASK DETAILS
export function* acceptRejectAnnotationsSaga({
  payload: { success = () => {}, fail = () => {}, body } = {},
}) {
  try {
    // success();
    yield put(startLoading());
    const result = yield call(Fetch.acceptRejectAnnotationsApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
