import React from "react";


const enQuestionTypeExample = <>
    <p>answer types have the following options: </p>
    <ul>
        <li><b>Radio button</b>: this is used to select a single option of many </li>
        <li><b>Check box</b>: this is used to select one or more options</li>
        <li><b>Yes/No</b>: a special version of the radio, buttons are used instead of a dropdown</li>
    </ul>
</>;

const arQuestionTypeExample = <>
    <p>يحتوي نوع الإجابة على الخيارات التالية:</p>
    <ul>
        <li><b>زر الراديو</b>: يستخدم لتحديد خيار واحد من بين العديد</li>
        <li><b>زر التحقق</b>: يستخدم لتحديد خيار واحد أو أكثر</li>
        <li><b>نعم / لا</b>: إصدار خاص من زر الراديو ، يتم استخدام الأزرار بدلاً من القائمة المنسدلة</li>
    </ul>
</>;

const enQuestionAnswerConditionalExample = <>
    <p>you can add extra inputs depending on the current input</p>
    <p>when the option is selected the user will see the extra input</p>
    <p>Example:
        <ul>
            <li>if the user selects other in the how did you hear about us? question, then an extra input will appear asking for more details</li>
        </ul>
    </p>
    <p>this could be optional</p>
</>;

const arQuestionAnswerConditionalExample = <>
    <p>يمكنك إضافة مدخلات إضافية اعتمادًا على المدخل الحالي</p>
    <p>عند تحديد الخيار ، سيرى المستخدم المدخل الإضافي</p>
    <p>مثال:
        <ul>
            <li>إذا اختار المستخدم آخر في سؤال كيف سمعت عنا؟ ، فسيظهر مدخل إضافي يطلب المزيد من التفاصيل</li>
        </ul>
    </p>
    <p>يمكن أن يكون هذا اختياريًا</p>
</>;


export { enQuestionTypeExample, arQuestionTypeExample, enQuestionAnswerConditionalExample, arQuestionAnswerConditionalExample };