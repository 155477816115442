  const local_Storage={
    language:(action,payload)=>{
        switch (action) {
            case 'set':
                localStorage.setItem('languages',payload);
                break;
            case 'get':
                localStorage.getItem('languages');
                break;
        
            default:
                break;
        }
    }
     ,  
    citizenship(action,payload){
        switch (action) {
            case 'set':
                localStorage.setItem('citizenship',payload);
                break;
            case 'get':
                localStorage.getItem('citizenship');
                break;
        
            default:
                break;
        }
    },
    annotaionClassName(action,payload){
        switch (action) {
            case 'set':
                localStorage.setItem('annotaionClassName',payload);
                break;
            case 'get':
                localStorage.getItem('annotaionClassName');
                break;
        
            default:
                break;
        }
    }
};
export default local_Storage