import * as Yup from "yup";
import i18n from "i18next";

export const constants = {
  formikValidationSchemaCN: (lang) => {
    switch (lang) {
      case "en":
        return Yup.object().shape({
          projName: Yup.string()
            .matches(
              /^[^\s!#%^*]+[\w\d\s@,.<>:;'"?/\\{}()\]\[\-_*&$]+([-a-zA-Z]+[^\s]+)*[\w\d\s@,.<>:;'"?/\\{}()\]\[\-_*&$]$/,
              i18n.t("entr_vld_name")
            )
            .required(i18n.t("entr_prj_name"))
            .max(30, i18n.t("max_30_char"))
            .min(2, i18n.t("entr_min_2chars")),
        });
      case "ar":
        return Yup.object().shape({
          projName: Yup.string()
            .required(i18n.t("entr_prj_name"))
            .max(30, i18n.t("max_30_char"))
            .min(2, i18n.t("entr_min_2chars")),
        });
      default:
        break;
    }
  }
};
