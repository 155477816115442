import { combineReducers } from "redux";
import updateUserDetailsReducer from "./UserManagement/UpdateUserDetails";
import userLoginReducer from "./Authenticate/loginReducer";
import forgotPassReducer from "./Authenticate/ForgotPassReducer";
import dashboardReducer from "./Dashboard/DashboardReducer";
import userTableReducer from "./UserManagement/UserTableReducer";
import changePassReducer from "./Authenticate/ChangePassword";
import viewReducer from "./UserManagement/ViewDetails";
import editUserDetailsReducer from "./UserManagement/EditUserDetailsReducer";
import setPassReducer from "./Authenticate/SetPasswordReducer";
import common from './Common/index';
import profile  from './Profile';
import taskListReducer  from './Project/SaveTaskListReducer';
import ownerProjDetails  from './Project/OwnerProjectDetails';
import userTaskManagement  from './Task/UserTaskManagement';
import ownerManagement  from './Admin/OwnerManagement/index';
import userManagement  from './Admin/UserManagement/index';
import projManagement  from './Admin/ProjectManagement/index';

const rootReducers = combineReducers({
    // login:userLoginReducer,
    common,
    userLoginReducer,
    forgotPassReducer,
    dashboardReducer,
    userTableReducer,
    changePassReducer,
    viewReducer,
    editUserDetailsReducer,
    updateUserDetailsReducer,
    setPassReducer,
    profile ,
    taskListReducer,
    ownerProjDetails,
    userTaskManagement,
    ownerManagement,
    userManagement,
    projManagement
})

export default rootReducers;