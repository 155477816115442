import React, { useCallback, useEffect, useState } from "react";
import ReactImageAnnotate from "../../../../../../libs/react-image-annotate";

const Udt = (props) => {
  const { disputed = false, classLists, images, itrr } = {
    ...props,
  };

  const [itr, setItr] = useState();
  const [hide_Next, setHideNext] = useState(
    localStorage.getItem("imageIndex") == images.length - 1 ? true : false
  ); // itr = image to render
  const [hide_Prev, setHidePrev] = useState(
    localStorage.getItem("imageIndex") == 0 ? true : false
  ); // itr = image to render


  const onNextImagee = useCallback((output ) => {
    const tempImages = JSON.parse(localStorage.getItem('imageArray'));

    if (tempImages.length > output?.selectedImage + 1) {
      setHidePrev(false);
      setItr(tempImages[output?.selectedImage + 1]?.src);
      localStorage.setItem("imageIndex", output?.selectedImage + 1);
    }
    if (tempImages.length == output?.selectedImage + 2) {
      setHideNext(true);
    }
    // handleAcceptReject();
    localStorage.setItem("imageIndex", output?.selectedImage + 1);
  }, []); //  onNextImagee
  const onPrevImage = useCallback((output) => {
    if (output?.selectedImage - 1 == 0) setHidePrev(true);
    if (output?.selectedImage - 1 == 0) {
      setTimeout(() => {
        const cloneButton = document.querySelector(
          "button.MuiButton-root.css-1e6y48t-MuiButtonBase-root-MuiButton-root:nth-child(3)"
        );
        cloneButton.setAttribute("style", "display:none !important ;");
      }, 50);
    }
    setHideNext(false);
    const tempImages = JSON.parse(localStorage.getItem('imageArray'));
    setItr(tempImages[output?.selectedImage - 1]?.src);
    localStorage.setItem("imageIndex", output?.selectedImage - 1);
    // handleAcceptReject();

  }, []); // onPrevImage
  useEffect(() => {
    if (!disputed)
      setItr(itrr)
    else setItr('itrr')
  }, [itrr, images]);


  return (
    <>
      {itr && images ? (

        disputed
          ?
          <ReactImageAnnotate
            labelImages
            allowComments
            fullImageSegmentationMode={true}
            editing={false}
            regionClsList={classLists}
            enabledTools={[]}
            images={images}
            hideNext={true}
            hidePrev={true}
          />
          :
          <ReactImageAnnotate
            labelImages
            allowComments
            fullImageSegmentationMode={true}
            editing={false}
            regionClsList={classLists}
            enabledTools={[]}
            // to show only single image
            // images={[particularAnnotation]}

            // to show all images in next prev
            images={images}
            selectedImage={itr}
            hideNext={hide_Next}
            hidePrev={hide_Prev}
            onPrevImage={onPrevImage}
            onNextImage={onNextImagee}
          />
      ) : null
      }

    </>
  );
};

export default Udt;
