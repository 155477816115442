import {  Delete, Get, Post, Put } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl;

const Fetch = { 
  // Owner
  // Create New Project not done
  ownerCreateNewProjectApi(payload) {
    let url = baseUrl + "projects/";
    return Post(payload, url);
  },
  addNewTaskToProjectApi(payload) {
    let url = baseUrl + `project_create/${payload.projId}`;
    delete payload.projId ; 
    return Put(payload, url);
  },
  getProjectListsAdminApi(payload) {
    
    let pageNum = payload.pageNum!='' ? payload.pageNum : 1 ; 
    let ordering = payload.ordering!='' ? payload.ordering : '' ;
    let searchValue = payload.searchValue!='' ? payload.searchValue : '' ;
    let url = baseUrl + `admin/projects/?type=${3}&ordering=${ordering}&search=${searchValue}&offset=${(pageNum*10)-10}&limit=${10}` ;//
    let auth = true;
    return Get(url,auth);
  },
  getOngoingProjectListsApi(payload) {
    
    let pageNum = payload.pageNum!='' ? payload.pageNum : 1 ; 
    let ordering = payload.ordering!='' ? payload.ordering : '' ;
    let searchValue = payload.searchValue!='' ? payload.searchValue : '' ;
    let url = baseUrl + `projects/?type=${0}&ordering=${ordering}&search=${searchValue}&offset=${(pageNum*10)-10}&limit=${10}` ;//
    // project_listing?type=1&search=&ordering=start_date offset=${(pageNum*10)-10}
    let auth = true;
    return Get(url,auth);
  },
  getPastProjectListsApi(payload) {
    
    let pageNum = payload.pageNum!='' ? payload.pageNum : 1 ; 
    let ordering = payload.ordering!='' ? payload.ordering : '' ;
    let searchValue = payload.searchValue!='' ? payload.searchValue : '' ;
    let url = baseUrl + `project_listing?type=${2}&ordering=${ordering}&search=${searchValue}&offset=${(pageNum*10)-10}&limit=${10}` ;//
    // let url = baseUrl + `project_listing?limit=${payload.limit ? payload.limit : 10}&offset=${(pageNum*10)-10}&ordering=${ordering}&search=${searchValue}` ;//
    let auth = true;
     return Get(url,auth);
  },
  getProjectDetailsApi(payload){   
     let url = baseUrl + `projects/${payload.projectId}` ;//
    let auth = true ;
    return Get(url,auth);

  }, 
 
  // owner dash quick link proj
  getOwnerDashQuickLinkProjApi(payload){
    let pageNum = payload.pageNum!='' ? payload.pageNum : 1 ; 
    let ordering = payload.ordering!='' ? payload.ordering : '' ; 
    let url = baseUrl + `owner_ongoing_project_listing?limit=${10}&offset=${(pageNum*10)-10}&ordering=${ordering}` ;


    // let url = baseUrl + `OwnerProjectListDashBoard/` ;//
    let auth = true ;
    return Get(url,auth);

  },
  // owner dash quick link total task list
  getOwnerDashQuickLinkTotalTaskApi(payload){
    let pageNum = payload.pageNum!='' ? payload.pageNum : 1 ; 
    let ordering = payload.ordering!='' ? payload.ordering : '' ; 
    let url = baseUrl + `owner_task_listing?limit=${10}&offset=${(pageNum*10)-10}&ordering=${ordering}` ;


   
    // let url = baseUrl + `OwnerTaskListDashBoard/` ;//
    let auth = true ;
    return Get(url,auth);

  },

  deleteProjectAdminApi(payload){ 
    console.log(payload,'payload in api');

    let url = baseUrl + `project_create/${payload?.id}` ;
 
    return Delete(url);

  },
  // export data
  exportDataApi(payload){  

    let url = baseUrl + `projects/export/` ;
 
    return Post(payload,url);

  }
};
 
export default Fetch;
