import Fetch from "../Fetch/Api"
import { call, put } from 'redux-saga/effects';
import { toast } from "react-toastify";
import { USER_VIEW_ERROR, USER_VIEW_SUCCESS } from "../actionTypes/actionTypes";
function* authanicationUser(result, history) {
    localStorage.clear()
    history.push("/")
    toast.error(result.result.message,{

        className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

    })
}



function* getUserDetailsApi(action) {

    try {
        const result = yield call(Fetch.userDetailsApi, action.payload)

        if (result.status === 1) {
            yield put({
                type: USER_VIEW_SUCCESS,
                result: result?.result,
                status: result.status,
                value: action.payload
            })
        }
        else if (result.status === 4) {
            yield call(authanicationUser, result, action.history)
        }
        else {
            yield put({
                type: USER_VIEW_ERROR,
                result: result?.result?.data?.data,
            })
        }
    }
    catch (error) {
        yield put({
            type: USER_VIEW_ERROR,
            result: error
        })
    }
}

export default getUserDetailsApi;