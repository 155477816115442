import Fetch from "../Fetch/Report";
import { put, call } from "redux-saga/effects";
import { startLoading, stopLoading } from "../actions/Common";  

 
export function* reportWorkerByValidatorSaga({ 
  payload: {
    success = () => {}, 
    fail = () => {} ,
    body
  } = {}
}
) {
  try {
    const result = yield call(Fetch.reportWorkerByValidator,body);  
    yield put(startLoading());
    if (result.status === 1) { 
      success(result?.result?.data);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
 
