import { LOGOUT, USER_EDIT_ERROR, USER_EDIT_LOAD, USER_EDIT_SUCCESS } from "./../../actionTypes/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    status: 0,
    editData: {},

}
const editUserDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
                isLoading: false,
                error: null,
                status: 0,
                editData: {},
            };
        case USER_EDIT_LOAD:
            return { ...state, isLoading: true, error: null }

        case USER_EDIT_SUCCESS:
            return {
                ...state,
                result: action.result,
                editData: action.result.data,
                isLoading: false,
                status: action.status,
            }

        case USER_EDIT_ERROR:
            return {
                ...state,
                isLoading: false,
            }


        default:
            return state;

    }
}

export default editUserDetailsReducer;