import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { acceptRejectAnnotations } from "../../../../../../redux/actions/Annotations";
import { setImageLoading } from "../../../../../../redux/actions/Common";
import { getTaskDetails, saveItr } from "../../../../../../redux/actions/Tasks";
import { customFunctions } from "../../../../../../themes/utils/CustomFunctions";
import { isInternetConnected } from "../../../../../../themes/utils/internetConnectionn";

const RejectModal = (props) => {
  const {
    acceptRejectApiBody,
    showRejectModal,
    showCommentModal = false,
    get_TaskDetails = () => {},
    hideRejectModal = () => {},
    hideCommentModal = () => {},
  } = { ...props };
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { taskId } = useParams();
  const tempImageArray = useSelector((state) => state?.userTaskManagement?.itr);

  const [commentValue, setCommentValue] = useState(""); // comment modal
  const tempBody =
    localStorage.getItem("workerPendingTaskId") ||
    localStorage.getItem("workerCompletedTaskId")
      ? {
          taskId,
          user_id: Number(localStorage.getItem("loggedInUserId")),
        }
      : localStorage.getItem("user_type") == 2
      ? {
          taskId,
          type: "owner",
        }
      : {
          taskId,
        };
  const payload = {
    body: tempBody,
    fail: (resf) => {
      console.log(resf, "resf of task details.");
      resf?.data &&
        toast.error(resf?.data, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
        });
      resf?.message &&
        toast.error(resf?.message, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
        });
    },
  }; //  task details info payload
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [showRejectModal]);
  return (
    <Modal
      title={showRejectModal ? t("reject") : t("cmnt")}
      open={showRejectModal || showCommentModal}
      onOk={() => {
        if (showRejectModal) hideRejectModal(false);
        if (showCommentModal) hideCommentModal(false);
      }}
      onCancel={() => {
        if (showRejectModal) hideRejectModal(false);
        if (showCommentModal) hideCommentModal(false, false);
      }}
      footer={null}
      // className="success_data delete"
      centered
      className={
        localStorage.lang == "ar"
          ? "success_data delete new_layout ar"
          : "success_data delete new_layout en"
      }
    >
      {/* comment box or title */}
      <div className="text-center  model1">
        {showRejectModal ? (
          <p>{t("u_sure_to_rjct_this_annot")}</p>
        ) : (
          <div className="form-group">
            <textarea
              id="comment"
              value={customFunctions.capitalizeFirstLetter(
                commentValue.trimLeft()
              )}
              onChange={(e) => {
                setCommentValue(
                  customFunctions.capitalizeFirstLetter(e.target.value)
                );
              }}
              maxLength={100}
              className="form-control"
              placeholder={t("plz_entr_cmnts")}
              style={{ height: "142px" }}
            />
          </div>
        )}
      </div>

      {/* buttons */}
      <div className="text-center  model1">
        {/* yes or submit button */}
        <button
          type="button"
          className="button-list top_new focus comment-button" 
          
          onClick={() => { 
            if (showRejectModal) {
              // reject modal
              hideRejectModal(false);
              hideCommentModal(true);
            } else {
              // comment modal
              if (commentValue != "") {
                let tempAcceptRejectApiBody = { ...acceptRejectApiBody };
                tempAcceptRejectApiBody["comment"] = commentValue;
                tempAcceptRejectApiBody["status"] = 3;

                const payloadReject = {
                  body: JSON.stringify(tempAcceptRejectApiBody),
                  success: (res) => {
                    localStorage.setItem("showTickOrNot", false);
                    if (localStorage.getItem("taskType") == "Image") {
                      dispatch(
                        saveItr(
                          tempImageArray[localStorage.getItem("imageIndex")].src
                        )
                      );
                      isInternetConnected("task") &&
                        dispatch(getTaskDetails(payload));
                      dispatch(setImageLoading(Math.random()));
                    }
                    get_TaskDetails(true);
                    setCommentValue("");
                    toast.success(res?.data?.message, {
                      className:
                        localStorage.lang == "ar"
                          ? "Select_data ar"
                          : "Select_data en",
                      position:
                        localStorage.lang == "ar"
                          ? toast.POSITION.TOP_LEFT
                          : toast.POSITION.TOP_RIGHT,
                    });
                    hideCommentModal(false);
                  },
                  fail: (resf) => {
                    console.log(resf, "resf of task details.");
                    toast.error(resf?.data, {
                      position: "top-right",
                    });
                  },
                }; //  payloadReject

                isInternetConnected() &&
                  dispatch(acceptRejectAnnotations(payloadReject));
              } else {
                toast.error(t("enter_comnt"), {
                  className:
                    localStorage.lang == "ar"
                      ? "Select_data ar"
                      : "Select_data en",
                  position:
                    localStorage.lang == "ar"
                      ? toast.POSITION.TOP_LEFT
                      : toast.POSITION.TOP_RIGHT,
                });
              }
            }
          }}
        >
          {showRejectModal ? t('yes') : t("submit")}
        </button>
        {/* no or cancel button */}
        <button
          type="button"
          className="focus"
          onClick={() => {
            // setAcceptOrRejectAnnot(null);
            if (showRejectModal) {
              hideRejectModal(false);
            } else {
              // setShowCommentModal(false);
              hideCommentModal(false, false);
            }
          }}
        >
          {showRejectModal ? t('no') : t("cancel")}
        </button>
      </div>
    </Modal>
  );
};

export default RejectModal;
