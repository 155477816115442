import {
  GET_TASK_LISTS,
  EXPORT_TASK_LISTING,
  ADD_VALIDATOR_FOR_TASK,
  REMOVE_USER_FROM_OWNER_ONGOING_PROJ_TASK,
  UPDATE_USER_QUOTA_FROM_OWNER_ONGOING_PROJ_TASK,
  REPLACE_USER_FROM_OWNER_ONGOING_PROJ_TASK,
  REMOVE_USER_INVITE_FROM_OWNER_ONGOING_PROJ_TASK,
  GET_USER_INVITATION_OWNER_ONGOING_PROJ,
  GET_VALIDATORS_LIST_OWNER_ONGOING_PROJ,
  SAVE_WORKER_PENDING_TASKS,
  SAVE_WORKER_COMPLETED_TASKS,
  GET_VALIDATOR_PENDING_TASKS,
  SAVE_VALIDATOR_PENDING_TASKS,
  GET_VALIDATOR_COMPLETED_TASKS,
  GET_WORKER_PENDING_TASKS,
  GET_WORKER_COMPLETED_TASKS,
  GET_TASK_DETAILS,
  GET_TASK_PREVIEW,
  CREATE_TASK,
  INVITE_TO_TASK,
  GET_INVITED_USERS,
  DELETE_ADDED_TASK,
  GET_ADDED_TASK_LIST,
  GET_TASK_TYPES,
  SAVE_VALIDATOR_COMPLETED_TASKS,
  GET_WORKERS_LIST_OWNER_ONGOING_PROJ,
  ADD_WORKER_FOR_TASK,
  DELETE_TASK_ADMIN,
  SAVE_TASKID,
  SAVE_IMAGE_ARRAY,
  SAVE_CLASS_NAMES,
  SAVE_ITR,
  GET_DOCUMENT,
  GET_DOCUMENT_DETAILS
} from "../actionTypes/actionTypes";

export const getTaskTypes = (payload) => {
  return {
    type: GET_TASK_TYPES,
    payload,
  };
};

// Owner createproject start

// create task
export const createTask = (payload) => {
  return {
    type: CREATE_TASK,
    payload,
  };
};

// invite to task
export const inviteToTask = (payload) => {
  return {
    type: INVITE_TO_TASK,
    payload,
  };
};

// get invited users
export const getInvitedUsersList = (payload) => {
  return {
    type: GET_INVITED_USERS,
    payload,
  };
};



export const getTaskDetails = (payload) => {
  return {
    type: GET_TASK_DETAILS,
    payload,
  };
};
export const getTaskPreview = (payload) => {
  return {
    type: GET_TASK_PREVIEW,
    payload,
  };
};
export const getDocument = (payload) => {
  return {
    type: GET_DOCUMENT,
    payload,
  };
};
export const getDocumentDetails = (payload) => {
  return {
    type: GET_DOCUMENT_DETAILS,
    payload,
  };
};
// get added task
export const getAddedTaskList = (payload) => {
  return {
    type: GET_ADDED_TASK_LIST,
    payload,
  };
};

// delete task
export const deleteAddedTask = (payload) => {
  return {
    type: DELETE_ADDED_TASK,
    payload,
  };
};
// Owner createproject end

// Owner Dashborad/Projects/Ongoing Project Details/Task Details   start
export const getValidtaorsListOwnerOngoingProj = (payload) => {
  return {
    type: GET_VALIDATORS_LIST_OWNER_ONGOING_PROJ,
    payload: payload
  }

}
export const getWorkerListOwnerOngoingProj = (payload) => {
  return {
    type: GET_WORKERS_LIST_OWNER_ONGOING_PROJ,
    payload: payload
  }

}
export const getInvitedUsers = (payload) => {
  return {
    type: GET_USER_INVITATION_OWNER_ONGOING_PROJ,
    payload: payload
  }

}
export const removeUser = (payload) => {

  return {
    type: REMOVE_USER_FROM_OWNER_ONGOING_PROJ_TASK,
    payload: payload
  }

}
export const updateQuota = (payload) => {

  return {
    type: UPDATE_USER_QUOTA_FROM_OWNER_ONGOING_PROJ_TASK,
    payload: payload
  }

}
export const replaceTaskUser = (payload) => {

  return {
    type: REPLACE_USER_FROM_OWNER_ONGOING_PROJ_TASK,
    payload: payload
  }

}
export const removeUserInvite = (payload) => {

  return {
    type: REMOVE_USER_INVITE_FROM_OWNER_ONGOING_PROJ_TASK,
    payload: payload
  }
}
export const addValidatorForTask = (payload) => {

  return {
    type: ADD_VALIDATOR_FOR_TASK,
    payload: payload
  }

}
export const addWorkerForTask = (payload) => {

  return {
    type: ADD_WORKER_FOR_TASK,
    payload: payload
  }

}
// Owner Dashborad/Projects/Ongoing Project Details/Task Details   end

// Owner Dashborad/Projects/Ongoing Project Details   start
export const getTaskLists = (payload) => {

  return {
    type: GET_TASK_LISTS,
    payload: payload
  }

}
// Owner Dashborad/Projects/Ongoing Project Details   end


// user task management

export const getWorkerPendingTasks = (payload) => {
  return {
    type: GET_WORKER_PENDING_TASKS,
    payload,
  };
};
export const getWorkerCompletedTasks = (payload) => {
  return {
    type: GET_WORKER_COMPLETED_TASKS,
    payload,
  };
};
export const getValidatorPendingTasks = (payload) => {
  return {
    type: GET_VALIDATOR_PENDING_TASKS,
    payload,
  };
};

export const getValidatorCompletedTasks = (payload) => {
  return {
    type: GET_VALIDATOR_COMPLETED_TASKS,
    payload,
  };
};

export const saveValidatorPendingTasks = (payload) => {
  return {
    type: SAVE_VALIDATOR_PENDING_TASKS,
    payload,
  };
};
export const saveValidatorCompletedTasks = (payload) => {
  return {
    type: SAVE_VALIDATOR_COMPLETED_TASKS,
    payload,
  };
};
export const saveWorkerPendingTasks = (payload) => {
  return {
    type: SAVE_WORKER_PENDING_TASKS,
    payload,
  };
};
export const saveWorkerCompletedTasks = (payload) => {
  return {
    type: SAVE_WORKER_COMPLETED_TASKS,
    payload,
  };
};
// user task management


// admin 
// proj management

export const deleteAdminTask = (payload) => {
  return {
    type: DELETE_TASK_ADMIN,
    payload,
  };
};

export const getTaskListExport = (payload) => {
  return {
    type: EXPORT_TASK_LISTING,
    payload,
  };
};
export const saveTaskId = (payload) => {
  return {
    type: SAVE_TASKID,
    payload,
  };
};
export const saveClassNames = (payload) => {
  return {
    type: SAVE_CLASS_NAMES,
    payload,
  };
};
export const saveImageArray = (payload) => {
  return {
    type: SAVE_IMAGE_ARRAY,
    payload,
  };
};
export const saveItr = (payload) => {
  console.log('calling saveitr');
  return {
    type: SAVE_ITR,
    payload,
  };
};

