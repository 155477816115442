import images from "../../../themes/appImage";
import React, { useEffect, useState } from "react";
import { HeaderLandingInner } from "../../../layout/LandingModule";
import { CustomFooter } from "../../../molecules";

const SERVICES = () => {
  const sticky = false;
  const isVisible = false;
  const [toogled, setToogle] = useState(false);

  const handletoogle = () => {
    setToogle(!toogled);
  };
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="landing-page back-pic">
      <HeaderLandingInner
        handle={handletoogle}
        toogled={toogled}
        sticky={sticky}
      />

      <section className="aboutus data_about_us privacy_details">
        <div className="container">
          <div className="row align-item-center">
            <h2
              className="heading  slideInUp wow"
              style={{ marginTop: "69px" }}
            >
              <img src={images.leftimg12}></img>
              About Us
              <img src={images.rightimg12} />
            </h2>
            <div className="">
              <div className="d-flex w-100 justify-content-center appss">
                <div className="mobile_circle ser-width">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "25px",
                        lineHeight: "34px",
                        initialLetter: "2",
                        marginLeft: "-24px",
                      }}
                    >
                      &nbsp;&nbsp;&nbsp;
                      <span style={{ fontSize: "45px" }}>W</span>
                      hat is WosoM?
                    </p>
                    <p>
                      WosoM is the first language-specific data annotation tool
                      developed specifically for the Arabic language content
                      with full regard to its unique semantic and syntactic
                      structures. It is a comprehensive tool developed to
                      simplify a number of tasks and empower organizations to
                      take full control of the massive amounts of relevant
                      information that are routinely collected through various
                      channels and often neglected due to sheer volume. By
                      enabling automated data cleaning and data annotation for
                      several types of content, Wosom.ai liberates data
                      analytics departments while at the same time providing
                      them with powerful features that drive innovation and
                      change across the board. Its narrow focus on Arabic
                      linguistic and cultural space allows this tool to be
                      better optimized, more precise, and more context aware
                      than general purpose data management tools for the global
                      market typically are.
                    </p>
                    <p></p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "-32px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="about-us-img"
                      // style={{ height: "51%" }}
                      src="/static/media/privacy-policy.ba072408.png"
                    />
                  </div>
                </div>
              </div>

              <div className="text-center slideInDown home_trrr">
                <h4>How WosoM works?</h4>
                <p>
                  From the client side, most functionalities of WosoM are
                  available through a user-friendly app that can be conveniently
                  accessed from any digital device. This channel is used for
                  data delivery, data annotation, and several other options,
                  with a direct line of communication with customer support. It
                  comes with a simple dashboard that allows clients to monitor
                  the status of every project in real time and schedule new
                  tasks as they see fit. In addition to functions of the app,
                  clients can request data management or cleansing services
                  through e-mail or other communication channels. Since the
                  solution was built with accumulated expertise of our mother
                  company as its foundation, it includes advanced AI technology
                  on the server side and delivers valuable, business-ready data.
                  It greatly simplifies multiple processes and serves as an
                  extension of the client’s analytics capacity without taking up
                  a lot of resources or requiring costly hardware upgrades.
                </p>

                <h4>What types of data can be annotated?</h4>

                <p>
                  An important quality of the WosoM.ai app is that it supports
                  several different data formats, effectively granting full
                  multimedia evaluation capacity to the client. Every type of
                  information is analyzed in a specific way, taking into account
                  the limitations and distinctive features typical for the
                  format. The following data formats can be annotated or
                  processed with our WosoM solution:
                </p>
                <ul>
                  <li>
                    <p>Text-based data</p>
                  </li>
                  <li>
                    <p>Image data</p>
                  </li>
                  <li>
                    <p>Audio stream data</p>
                  </li>
                  <li>
                    <p>Video stream data</p>
                  </li>
                  <li>
                    <p>Marketing performance data</p>
                  </li>
                </ul>

                <h4>Main benefits from WosoM.ai deployment</h4>

                <p>
                  Organizations that adopt WosoM.ai as their primary data
                  annotation and management tool can benefit in multiple ways
                  from this technological upgrade. The ability to keep all
                  incoming data well organized and associate it with a clear
                  meaning helps businesses to make additional insights and
                  interpret the trends more accurately. This shortens the
                  reaction time to emergencies as well as unexpected
                  opportunities, and improves organizational agility to a
                  considerable degree. WosoM positively impacts project
                  management capacity while allowing the organization to spend
                  less time on data discovery and retrieval, and to gather
                  relevant information quickly in the times of crisis. In this
                  sense, it is an important tool for attraction of new
                  customers, more efficient handling of ongoing projects, and
                  sustainable long term growth and expansion.
                </p>

                <h4>Computer Vision</h4>

                <h4>
                  Wasim offers many services, the most important of which are:
                </h4>
                <p
                  style={{
                    fontSize: "23px",
                    fontWeight: '600'
                  }}
                >
                  - 2D Image Annotation :
                </p>
                <p>
                  Using simple and effective tools that enable 2D image
                  annotation operations, it provides support for most types of
                  image annotations in several forms such as polygon, bbox and
                  line tools.
                </p>
                <p
                  style={{
                    fontSize: "23px",
                    fontWeight: '600'
                  }}
                >
                  - Image Segmentation :
                </p>
                <p>
                  Accurate labeling of images using advanced and easy interfaces
                  to achieve maximum accuracy as quickly as possible, which is
                  an important factor in labeling the most complex projects.
                </p>

                <p>
                  {" "}
                  Through these services It is possible to identify objects of
                  variable scale to a large extent, from very large objects such
                  as buildings or terrain features to very small objects such as
                  people, vehicles or even food ingredients. The other
                  possibility is to search for the exact discrepancy between a
                  group of images of the same object, for example identifying
                  damaged elements in a large group of images, and these
                  services are highly effective and efficient, no matter how
                  large the dataset is.
                </p>

                <h4>Natural Language Processing</h4>

                <h4>
                  Wasim offers many services, the most important of which are:
                </h4>

                <p
                  style={{
                    fontSize: "23px",
                    fontWeight: '600'
                  }}
                >
                  - Text segmentation :
                </p>
                <p>
                  The process of dividing the text into meaningful units, such
                  as words, sentences, or topics, and explaining the type of
                  each of them, by using automatic learning approaches to divide
                  compound words. This is the goal. The process also aims to
                  assess the quality of word segmentation by comparing it to a
                  set of references.
                </p>

                <p
                  style={{
                    fontSize: "23px",
                    fontWeight: '600'
                  }}
                >
                  - Classification of media and the relationships between them
                  (Named Entity Relations) :
                </p>

                <p>
                  It is a task for extracting information from text that
                  identifies and categorizes named entities (media) that is
                  mentioned in this text, then its divided into our predefined
                  categories such as names of people, organizations, locations,
                  businesses, medical codes, time expressions, quantities,
                  monetary values, percentages, etc.
                </p>

                <p>It has many use cases such as :</p>
                <ul>
                  <li>
                    <p>Recommendation applications:</p>
                  </li>
                </ul>

                <p
                  style={{
                    marginLeft: "40px",
                  }}
                >
                    Let&apos;s take YouTube for example:
                </p>

                <p
                  style={{
                    marginLeft: "50px",
                  }}
                >
                  Jim Carrey comedies on YouTube, you will get more
                  recommendations labeled as the Jim Carrey comedian.
                </p>

                <ul>
                  <li>
                    <p>News rating:</p>
                  </li>
                </ul>

                <p
                  style={{
                    marginLeft: "40px",
                  }}
                >
                  For news of several lines, the following words are sufficient
                  to classify this news:
                </p>

                <ul
                  style={{
                    marginLeft: "30px",
                  }}
                >
                  <li>
                    {" "}
                    <p>Location: Paris</p>
                  </li>
                  <li>
                    {" "}
                    <p>Organization: Charlie Hebdo</p>
                  </li>
                  <li>
                    {" "}
                    <p>
                      Characters: Prophet Muhammad (peace and blessings be upon
                      him)
                    </p>
                  </li>
                </ul>

                <p
                  style={{
                    fontSize: "23px",
                    fontWeight: '600'
                  }}
                >
                  - Extracting keywords of the text (Keyword Extractions) :
                </p>

                <p>
                  When searching for a topic in a large number of texts, we want
                  to understand the basic information and what the speech
                  revolves around in each of these documents, so we usually move
                  towards extracting (main) keywords. Keyword extraction is an
                  automated process of extracting the most important words and
                  phrases within a given document. For example, an article
                  consisting of many pages can be expressed in a few simple
                  sentences such as (“Umar was called al-Farouk”, “he expanded
                  the sanctuary” ....) so that we know that the article talks
                  about a definition of Umar ibn al-Khattab, may God be pleased
                  with him, and therefore we know if the subject of the article
                  is relevant to the intended research.
                </p>

                <p
                  style={{
                    fontSize: "23px",
                    fontWeight: '600'
                  }}
                >
                  - Audio Transcription :
                </p>
                <p>
                  Vocalization is the process of converting speech in an audio
                  file into written text. This could be any audio recording,
                  interview recording, academic research, WosoM uses easy and
                  accurate dialect tools to create high quality audio
                  transcripts.
                </p>

                <p
                  style={{
                    fontSize: "23px",
                    fontWeight: '600'
                  }}
                >
                  - Audio Classification :
                </p>
                <p>
                  The process of listening to and analyzing audio recordings,
                  through which the contents of audio files such as dialogue,
                  music and background sounds are sorted. It is an important
                  part of automatic speech recognition and text-to-speech
                  projects and vice versa WosoM uses advanced interfaces to
                  extract classes from audio files.
                </p>

                <h4>Classification as multi-class and annotation</h4>

                <p>
                  Regardless of the medium, WosoM is able to check and
                  categorize items based on a predefined set of criteria, with
                  an unlimited number of categories. In the case of photos,
                  there are options to recognize hashtags, addresses, and place
                  geo-tagging on a map. While text classification is used for a
                  number of applications, including sorting user responses such
                  as opinions or emotions, or to automate topic identification
                  in large groups of documents.
                </p>

                <h4>Optimizing data</h4>

                <p>
                  WosoM offers several options that enable customers to improve
                  data quality. The platform contributes to data management
                  through a number of options, including removing redundant or
                  low-value data and providing segmented and shortened sets of
                  properly formatted data. It is possible to perform all routine
                  data revision tasks through WosoM platform, or by requesting a
                  service dedicated to a specific project. The platform seeks to
                  raise the quality of data through the use of several
                  automation methodologies to perform this task in an optimal
                  way and produce concise data sets characterized by a high
                  concentration of business data of the most important value.
                </p>

                <h4>Project management</h4>

                <p>
                  WosoM provides the opportunity for business owners to add
                  workers to their projects and follow up on continuous progress
                  using advanced tools to create easy-to-manage projects, while
                  ensuring direct and continuous communication with the
                  customer. The platform provides everything customers need to
                  accomplish data annotation tasks. Our first goal is customer
                  satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CustomFooter />

      {isVisible && (
        <div onClick={scrollToTop}>
          <a href="#" className="scrollup"></a>
        </div>
      )}
    </div>
  );
};

export default SERVICES;
