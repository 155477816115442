import React /* , { Profiler }  */ from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./i18n";
import { Provider } from "react-redux";
import { store } from "../src/redux/store/store";
import { setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { BrowserRouter as Router } from "react-router-dom";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

// function onRender(
//   id, // the "id" prop of the Profiler tree that has just committed
//   phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
//   actualDuration, // time spent rendering the committed update
//   baseDuration, // estimated time to render the entire subtree without memoization
//   startTime, // when React began rendering this update
//   commitTime, // when React committed this update
//   interactions // the Set of interactions belonging to this update
// ) {
//   console.log({
//     id: id,
//     phase: phase,
//     actualDuration: actualDuration / 1000,
//     baseDuration: baseDuration / 1000,
//     startTime: startTime / 1000,
//     commitTime: commitTime / 1000,
//     interactions: interactions,
//   });
// }

am4core.useTheme(am4themes_animated);

ReactDOM.render(
  <Router>
    <Provider store={store}>
      {/* <Profiler id="App" onRender={onRender}>
        <App />
      </Profiler> */}
      <App />
    </Provider>
  </Router>,
  document.getElementById("root")
);

