import { Modal } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ReportModal = (props) => {
  const {
    isReport,

    hideModal = () => {},
  } = { ...props };

  const { t } = useTranslation();
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isReport]);
  return (
    <Modal
      title={t("report")}
      open={isReport}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      footer={null}
      centered
    >
      <div className="text-center  model1">
        <p>{t("u_sure_to_reprt_the_user")}</p>
      </div>
      <div
        className="text-center  model1"
        style={{
          direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
        }}
      >
        <button
          type="button"
          className="button-list top_new focus"
          onClick={() => hideModal(false)}
        >
          {t("yes")}
        </button>
        <button
          type="button"
          className="focus"
          onClick={() => hideModal(false)}
        >
          {t("no")}
        </button>
      </div>
    </Modal>
  );
};

export default ReportModal;
