import React from 'react'
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
  scales: {
    y: {
      suggestedMax: 10,
      min: 0
    }
  }
};
const CustomChartGroup = (props) => {
  let labels;
  const {
    chartProps = [
      {
        heading: 'Tasks allocated in last 1 week',
        data_type: 'day'
      }
    ],

  } = { ...props }

  const { t } = useTranslation();

  const dataType = (type, dataInfo) => {
    let data = {};
    if (type === "day") {
      labels = [t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')];
    } else if (type === "week") {
      labels = [t('week1'), t('week2'), t('week3'), t('week4'), t('week5')];
    } else if (type === "quarter") {
      labels = [
        t("01 Jan-31 Mar"),
        t("01 Apr-30 June"),
        t("01 July-30 Sep"),
        t("01 Oct-31 Dec"),
      ];
    } else if (type === "month") {
      labels = [
        t("jan"),
        t("feb"),
        t("mar"),
        t("apr"),
        t("may"),
        t("jun"),
        t("jul"),
        t("aug"),
        t("sep"),
        t("oct"),
        t("nov"),
        t("dec"),
      ];
    }

    data = {
      labels,
      datasets: [
        {
          label: t('dataset'),
          data: dataInfo,  //showData ? labels.map(() => faker.datatype.number({ min: 0, max: 1000 })) : 0,
          backgroundColor: "#022649",
        },
      ],
    };

    return data;
  };

  return (
    <div className="content-new card-width-fixed">
      {
        chartProps.map(items => {

          return <div key={items.heading} className="col-xl-3 col-sm-4 mb-xl-0 mb-3">
            <Bar options={options} data={dataType(items.data_type, items.dataInfo)} />
            <h3>{items.heading}</h3>
          </div>
        })
      }
    </div>
  )
}

export default CustomChartGroup