import {
  // getWorkerCompletedTasks,
  getWorkerPendingTasks,
  // saveWorkerCompletedTasks,
  saveWorkerPendingTasks,
} from "../../../../../../redux/actions/Tasks";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Pagination, Table, Tooltip, Row, Col, Card, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomEmptyDiv } from "../../../../../../molecules";
import { NavigateTo } from "../../../../../../themes";
import { isInternetConnected } from "../../../../../../themes/utils/internetConnectionn";
import { EyeOutlined, CodeSandboxOutlined, RadiusUprightOutlined, TranslationOutlined, PartitionOutlined, EditOutlined, SnippetsOutlined } from '@ant-design/icons';

const TabScreen = (props) => {
  const { taskStatus, tabKey, searchValue } = { ...props };
  const { Meta } = Card;
  const { Text } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [pageNum, setPageNum] = useState(1);
  const [ordering, setOrdering] = useState("");
  const [totalPending, setTotalPending] = useState(1);
  // const [totalCompleted, setTotalCompleted] = useState(1);

  const dataP = useSelector(
    (state) => state?.userTaskManagement?.workerPendingTasks
  );
  // const dataC = useSelector(
  //   (state) => state?.userTaskManagement?.workerCompletedTasks
  // );
  const buttonActionClicks = (param) => {
    const action = param[0];
    const workStatus = param[1];
    const taskType = param[2] == 'image' ? 'Image' : param[2] == 'text' ? 'Text' : 'Audio';
    const taskId = param[3];

    localStorage.setItem("workStatus", workStatus);
    localStorage.setItem("taskType", taskType);

    switch (action) {
      case t("view"): {
        if (taskStatus == "pending") {
          navigate(NavigateTo.WORKER_PENDING_TASK_DETAILS + `/${taskId}`);
          localStorage.setItem("workerPendingTaskId", taskId);
        }
        if (taskStatus == "completed") {
          navigate(NavigateTo.WORKER_COMPLETED_TASK_DETAILS + `/${taskId}`);
          localStorage.setItem("workerCompletedTaskId", taskId);
        }
        break;
      }
      default:
        break;
    }
  };
  function thousands_separators(num) {
    if (num === 0 || num == NaN) return 0;
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return num_parts.join(".");
  }
  const columns = [
    {
      title: "#",
      dataIndex: "srno",
      key: "srno",
      width: 100,
      render: (value, item, index) => pageNum * 10 - 10 + (index + 1),
    },
    {
      title: t("proj_name"),
      dataIndex: "projName",
      key: "projName",
      width: 200,
      sorter: true,
      render: (value) => {
        return <Tooltip title={value}>
          {value}
        </Tooltip>
      }
    },
    {
      title: t("tsk_name"),
      dataIndex: "task_name",
      key: "task_name",
      width: 200,
      sorter: true,
      render: (value) => {
        return <Tooltip title={value}>
          {value}
        </Tooltip>
      }
    },
    {
      title: t('tsk_typ'),
      dataIndex: "task_type",
      key: "task_type",
      width: 200,
      // sorter: true,
      render: (item) => t(item)

    },
    {
      title: t('wrkr_typ'),
      dataIndex: "worker_type",
      key: "worker_type",
      width: 200,
      // sorter: true,
      render: (item) => t(item)

    },
    // {
    //   title: t("tsk_strt_date"),
    //   dataIndex: "task_start_date",
    //   key: "task_start_date",
    //   width: 100,
    //   sorter: true,
    // },
    {
      title: t("tsk_status"),
      dataIndex: "task_status",
      key: "task_status",
      width: 200,
      sorter: true,
      render: (i, record) => {
        // console.log(record.statusToShow, 'statusToShow');
        return (t(record?.statusToShow))
      }
    },
    {
      title: t("doc_count"),
      dataIndex: "document_count",
      key: "document_count",
      width: 50,
      sorter: true,
      render: (value, record) => {
        return <Tooltip title={value}>
          {thousands_separators(record?.total_annotated)}   /   {thousands_separators(value)}
        </Tooltip>
      }
    },
    {
      title: t("action"),
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (value, record) => {
        return value.map((action) => {
          return (
            <div
              key={action}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <button
                className="focus"
                type="submit"
                style={{
                  width: "20px",
                }}
                onClick={() => {
                  buttonActionClicks([
                    t('view'),
                    record?.task_status,
                    record?.task_type,
                    record?.id,
                  ]);
                }}
              >
                <EyeOutlined /> {t('view')}
              </button>
            </div>
          );
        });
      },
    },
  ];


  useEffect(() => {
    const payloadForPending = {
      body: {
        pageNum,
        searchValue,
        ordering,
      },
      success: (res) => {
        const modifiedRes = [];
        setTotalPending(res?.total);
        res?.data?.filter((item) => {
          modifiedRes.push({
            task_name: item?.name,
            projName: item?.project_name,
            task_start_date: item?.created_at
              .split("T")[0]
              .split("-")
              .reverse()
              .join("-"), // "2022-09-13T10:36:42.495000" date format
            task_status:
              item?.status == 0
                ? "Pending"
                : item?.status == 1
                  ? "In Review"
                  : "Pending",
            statusToShow: t(item?.status),
            task_type: t(item?.task_type),
            worker_type: t(item?.type),
            document_count: item?.document_count,
            total_annotated: item?.total_annotated,
            actions: [t("view")],
            id: item?.id,
          });
        });
        dispatch(saveWorkerPendingTasks(modifiedRes));
      },
      fail: (err) => {
        console.log(err, "err  Worker Pending Tasks");
      },
    };

    // on rsize event
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    isInternetConnected('task') &&
      tabKey == 1 &&
      dispatch(getWorkerPendingTasks(payloadForPending));

    // const payloadForCompleted = {
    //   body: {
    //     pageNum,
    //     searchValue,
    //     ordering,
    //   },
    //   success: (res) => {
    //     const modifiedRes = [];
    //     res?.results?.filter((item) => {
    //       modifiedRes.push({
    //         task_name: item?.name,
    //         projName: item?.project_detail?.project_name,
    //         task_start_date: item?.start_date
    //           .split("T")[0]
    //           .split("-")
    //           .reverse()
    //           .join("-"),
    //         task_status: "Completed",
    //         statusToShow: "completed",
    //         task_type:
    //           item?.task_type == 1
    //             ? "image"
    //             : item?.task_type == 2
    //               ? "text"
    //               : item?.task_type == 3
    //                 ? "audio"
    //                 : null,
    //         actions: [t("view")],
    //         id: item?.id,
    //       });
    //     });
    //     dispatch(saveWorkerCompletedTasks(modifiedRes));
    //   },
    //   fail: (err) => {
    //     console.log(err, "err  Worker completed Tasks");
    //   },
    // };
    // isInternetConnected('task') &&
    //   tabKey == 2 &&
    //   dispatch(getWorkerCompletedTasks(payloadForCompleted));
  }, [pageNum, ordering, searchValue]);

  return (
    <div className="form-group" style={{ position: "relative" }}>
      <div className="inner_box_box">
        {/* worker pending */}
        {tabKey == 1 ? (
          dataP?.length > 0 ? (
            <div id={"noti-table-parent"}>
              {//windw is mobile size
                width < 768 ? (
                  <div
                    className="table-responsive"
                    style={{ padding: "2% 1% 6% 1%", marginBottom: '3vh' }}
                  >
                    <Row gutter={[16, 16]}>
                      {dataP?.map((item, index) => {
                        const taskType = item.task_type.toLowerCase();
                        const iconStyle = {
                          color: "#143c64",
                          fontSize: 40,
                          margin: 5,
                        };
                        let icon = null;
                        switch (taskType) {
                          case "bbox":
                            icon = (
                              <CodeSandboxOutlined
                                key={index}
                                style={iconStyle}
                              />
                            );
                            break;
                          case "polygon":
                            icon = (
                              <RadiusUprightOutlined
                                key={index}
                                style={iconStyle}
                              />
                            );
                            break;
                          case "transcription":
                            icon = (
                              <TranslationOutlined
                                key={index}
                                style={iconStyle}
                              />
                            );
                            break;
                          case "segmentation":
                            icon = <PartitionOutlined style={iconStyle} />;
                            break;
                          case "classification":
                            icon = <SnippetsOutlined style={iconStyle} />;
                            break;
                          case "text_transcription":
                            icon = <EditOutlined style={iconStyle} />;
                            break;
                        }
                        const taskIcons = <Tooltip
                          key={index}
                          title={t(taskType)}
                          placement={
                            localStorage.getItem("lang") == "ar"
                              ? "topRight"
                              : "topLeft"
                          }
                        >
                          {icon}
                        </Tooltip>
                        return (
                          <Col xs={24} md={12} lg={8} key={index}>
                            <Card
                              className="card"
                              style={{
                                width: "100%",
                                marginTop: 10,
                                borderRadius: 16,
                              }}
                            >
                              <Meta
                                avatar={
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "12vh",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {taskIcons}
                                  </div>
                                }
                              />
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <div>
                                  <h6 className="text-white text-capitalize ps-3">
                                    <b>{item.task_name}</b>
                                  </h6>
                                  <Text type="secondary">
                                    <b>{item.task_start_date}</b>
                                  </Text>
                                </div>
                                <hr className="vertical" style={{
                                  height: "60px",
                                  top: 0,
                                }} />
                                <div style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-evenly",
                                }}>
                                  <Text type="primary" style={{
                                    fontSize: "1.2rem",
                                  }}>
                                    {t("assigned")}: <b>{item.document_count}</b>
                                  </Text>
                                  <Text type="secondary" style={{
                                    fontSize: "1rem",
                                  }}>
                                    {t("status")}:  <b>{item.statusToShow}</b>
                                  </Text>
                                </div>
                              </div>
                              <div
                              >
                                <button
                                  className="btn btn-lg"
                                  type="button"
                                  onClick={() => {
                                    buttonActionClicks([
                                      t('view'),
                                      item?.task_status,
                                      item?.task_type,
                                      item?.id,
                                    ]);
                                  }}
                                >
                                  <EyeOutlined /> {t("view")}
                                </button>
                              </div>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  <Table
                    className="worker-pending-tasktable"
                    dataSource={dataP}
                    columns={columns} // (task_name^, task_start_date^, task_status^, task_type, actions, id)
                    showSorterTooltip={false}
                    pagination={false}
                    onChange={
                      (pagination, filters, sorter) => {
                        if (sorter.order == undefined) {
                          setOrdering("");
                        }
                        if (
                          sorter.order == "ascend" &&
                          sorter.order != undefined &&
                          sorter.columnKey == "task_name"
                        ) {
                          setOrdering("name");
                        }
                        if (
                          sorter.order == "descend" &&
                          sorter.order != undefined &&
                          sorter.columnKey == "task_name"
                        ) {
                          setOrdering("-name");
                        }
                        if (
                          sorter.order == "ascend" &&
                          sorter.order != undefined &&
                          sorter.columnKey == "task_start_date"
                        ) {
                          setOrdering("start_date");
                        }
                        if (
                          sorter.order == "descend" &&
                          sorter.order != undefined &&
                          sorter.columnKey == "task_start_date"
                        ) {
                          setOrdering("-start_date");
                        }
                        if (
                          sorter.order == "ascend" &&
                          sorter.order != undefined &&
                          sorter.columnKey == "projName"
                        ) {
                          setOrdering("project_name");
                        }
                        if (
                          sorter.order == "descend" &&
                          sorter.order != undefined &&
                          sorter.columnKey == "projName"
                        ) {
                          setOrdering("-project_name");
                        }
                      }}
                  />
                )}
              <Pagination
                showTitle={false}
                style={{
                  maxWidth: "fit-content",
                  right: "20px",
                  left: "auto",
                  position: "absolute",
                  bottom: "20px",
                }}
                className="pagination-component"
                defaultCurrent={1}
                total={totalPending}
                size="medium"
                onChange={(item) => {
                  setPageNum(item);
                }}
              />
            </div>
          ) : (
            <CustomEmptyDiv
              title={t("pend_task_appear_here")}
              marginLeft={"15"}
              marginRight={"15"}
              marginBottom={"2"}
            />
          )
        ) : null}
      </div>
    </div>
  );
};

export default TabScreen;
