
import { Delete, Get } from "./FetchMethods";
import { Constants } from "../../themes";

export const baseUrl = Constants.baseUrl; // "https://beta.WosoM.ai/api/"

const Fetch = {
  // Admin
  // user
  getOwnerDetailsApi() {
    let url = baseUrl + `user/profile/`;
    return Get(url, true);
  },
  userDeleteApi(payload) {
    let url = baseUrl + `user_delete/${payload?.id}`;
    return Delete(url);
  },
};

export default Fetch;