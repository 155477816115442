import "draft-js/dist/Draft.css";
import { useFormik } from "formik";
import {
  deleteAddedTask,
  getAddedTaskList,
} from "../../../redux/actions/Tasks";
import { toast } from "react-toastify";
import AddTaskModal from "./addTaskModal";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { CardHoc, Container } from "../../../hoc";
import { CustomEmptyDiv } from "../../../molecules";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { Modal, Select, Table, Tooltip } from "antd";
import { createNewProject } from "../../../redux/actions/Project";
import { customFunctions } from "../../../themes/utils/CustomFunctions";
import { AppImage, NavigateTo, SidebarMenus } from "../../../themes/index";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { constants } from "../../../common/fileConstants/Owner/createNewPrj";

// const { Content } = Layout;

const CreateNewProject = () => {
  const children = [];
  const childrenEmail = [];
  const classnamevalue = [];
  const { Option } = Select;

  const projRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [pageNum, setPageNum] = useState(1);
  const [taskIds, setTasksId] = useState([]);
  const [ordering, setOrdering] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [totalTasks, setTotalTasks] = useState(0);
  const [taskRemoveId, setTaskRemoveId] = useState(null);
  const [isModalRemove, setIsModalRemove] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [dataSourceTaskList, setDataSourceTaskList] = useState([]);
  // const [fieldTouched, setFieldTouched] = useState([]);
  // const [addTaskModalDataToShow, setAddTaskModalDataToShow] = useState({}); // it will  have taskType keys .

  const isDisable = useSelector((state) => state.common.loading);

  const breadCrumbItems = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: "",
      label: t("create_new_prj"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t("create_new_prj"),
  };
  // taskList
  // use effect for ordering on datasource

  useEffect(() => {
    if (ordering == "name" || ordering == "-name") {
      dataSourceTaskList.sort((a, b) => {
        const nameA = a.taskname.toUpperCase(); // ignore upper and lowercase
        const nameB = b.taskname.toUpperCase(); // ignore upper and lowercase

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setDataSourceTaskList([...dataSourceTaskList]);

    }
    if (ordering == "task_type" || ordering == "-task_type") {
      dataSourceTaskList.sort((a, b) => {
        const nameA = a.tasktype.toUpperCase(); // ignore upper and lowercase
        const nameB = b.tasktype.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setDataSourceTaskList([...dataSourceTaskList]);
    }
  }, [ordering]);

  //[];
  const columnsTaskList = [
    {
      title: "#",
      dataIndex: "srno",
      key: "srno",
      width: 100,
      render: (value, item, index) => pageNum * 10 - 10 + (index + 1),
    },
    {
      title: t("tsk_name"),
      dataIndex: "taskname",
      key: "taskname",
      width: 250,
      sorter: (a, b) => {
        const nameA = a.taskname.toUpperCase(); // ignore upper and lowercase
        const nameB = b.taskname.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      },

      // sorter: (a, b) => a.taskname.length - b.taskname.length,
    },
    {
      title: t("tsk_typ"),
      dataIndex: "tasktype",
      key: "tasktype",
      width: 250,
      sorter: true,
      render: (item, record) => {
        return record?.taskTypeNum == 'bbox' || record?.taskTypeNum == 'polygon'
          ? t("image")
          : record?.taskTypeNum == 'classification' || record?.taskTypeNum == 'segmentation'
            ? t("text")
            : t("audio");
      },
    },
    {
      title: t("validation_system"),
      dataIndex: "validation_system",
      key: "validation_system",
      width: 250,
      render: (item, record) => {
        return t(record?.validation_system)
      }
    },
    {
      title: t("action"),
      dataIndex: "remove",
      key: "remove",
      width: 250,
      render: (text, record) => {
        return (
          <div>
            <button
              type="button"
              className="focus"
              onClick={() => {
                showModalRemove();
                setTaskRemoveId(record.id);
              }}
            >
              {t("remove")}
            </button>
          </div>
        );
      },
    },
  ];
  // taskList

  const dataArrayEmail = [
    {
      name: "abc12@yopmail.com",
    },
    {
      name: "m12@yopmail.com",
    },
  ];
  const dataArray = [
    {
      name: "Micheal",
      profile: AppImage.user,
      rating: AppImage.star,
    },
    {
      name: "Micheal",
      profile: AppImage.user,
      rating: AppImage.star,
    },
    {
      name: "Micheal",
      profile: AppImage.user,
      rating: AppImage.star,
    },
  ];
  const Classvalue = [
    {
      name: "class1",
    },
    {
      name: "class2",
    },
  ];
  const showModalRemove = () => {
    setIsModalRemove(true);
  };
  // const handleOkRemove = () => {
  //   setIsModalRemove(false);
  // };
  const handleCancelRemove = () => {
    setIsModalRemove(false);
  };
  const data123 = (values_data) => {
    return (
      <div className="data_news">
        <div className="first_text">
          <img src={values_data.profile} style={{ width: "20px" }}></img>
        </div>
        <div className="first_text">{values_data.name}</div>
        <div>
          <img src={values_data.rating}></img>
        </div>
      </div>
    );
  };
  for (let i = 0; i < dataArray.length; i++) {
    children.push(<Option key={i}>{data123(dataArray[i])}</Option>);
  }
  const Emaildata = (values_data_new) => {
    return (
      <div className="data_news">
        <div className="first_text">{values_data_new.name}</div>
      </div>
    );
  };
  for (let i = 0; i < dataArrayEmail.length; i++) {
    childrenEmail.push(<Option key={i}>{Emaildata(dataArrayEmail[i])}</Option>);
  }
  const ClassValue = (values_data_new) => {
    return (
      <div className="data_news">
        <div className="first_text">{values_data_new.name}</div>
      </div>
    );
  };
  for (let i = 0; i < Classvalue.length; i++) {
    classnamevalue.push(<Option key={i}>{ClassValue(Classvalue[i])}</Option>);
  }

  const onSubmit = (values) => {
    const payloadForCreateProject = {
      body: {
        name: values.projName,
        tasks: taskIds,
      },
      success: (res) => {
        toast.success(res.message, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
          toastId: "projCreated",
        });
        navigate(NavigateTo.PROJECT);
      },
      fail: () => {
        console.log("");
      },
    };
    if (totalTasks != 0) {
      isInternetConnected() &&
        dispatch(createNewProject(payloadForCreateProject));
    }
    if (totalTasks == 0) {
      toast.error(t("add_min_1_task"), {
        className:
          localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,
        toastId: "addTaskReq",
      });
    }
  };
  const initialValues = {
    projName: "",
  };
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema: constants.formikValidationSchemaCN(localStorage.lang),
  });
  useEffect(() => {
    document.title = "WosoM";

    if (formik.values.projName != "" && formik.values.projName == " ") {
      formik.setFieldError("projName", t("entr_prj_name"));
    }
    if (
      formik.values.projName.length < 3 &&
      formik.values.projName != " " &&
      formik.values.projName != ""
    ) {
      formik.setFieldError("projName", t("prj_name_min_3chars"));
    }
  }, [formik.isSubmitting, formik.errors, showTaskModal]);

  useEffect(() => {
    document.title = "WosoM";

    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
    Object.keys(formik.errors).forEach((fieldName) => {
      formik.setFieldTouched(fieldName);
    });
  }, [isModalRemove, lang]);

  useEffect(() => {
    const payloadForGetTaskLists = {
      success: (res) => {
        let modifiedRes = [];
        let tempTasksId = [];
        setTotalTasks(res?.length);
        res?.filter((item, index) => {
          tempTasksId.push(item?.id);
          modifiedRes.push({
            id: item?.id,
            srno: index,
            taskname: item?.name,
            taskTypeNum: item?.task_type,
            tasktype: t(item?.task_type),
            validation_system: item?.validation_system,
          });
        });

        setDataSourceTaskList(modifiedRes); // to show in table
        setTasksId(tempTasksId);
      },
      fail: (err) => {
        console.log("");
        toast(err);
      },
    };
    isInternetConnected() && dispatch(getAddedTaskList(payloadForGetTaskLists));
  }, [deleted, pageNum]); // ordering

  return (
    <Container
      {...containerProps}
      languageChanged={() => {
        setLang(localStorage.getItem("lang"));
      }}
    >
      <CardHoc cardInnerPadding={"content-e"}>
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
          <h6 className="text-white text-capitalize ps-3">
            {t("create_new_prj")}
          </h6>
        </div>

        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="wrapper_line">
            {/* project name */}
            <div className="form-group">
              <label>{t("proj_name")}</label>
              <input
                id="projName"
                type="text"
                ref={projRef}
                maxLength={30}
                className="form-control"
                placeholder={t("proj_name")}
                onChange={formik.handleChange}
                value={customFunctions.capitalizeFirstLetter(
                  formik.values.projName.trimLeft()
                )}
                autoComplete="off"
              />
              {formik.errors.projName ? (
                <div
                  className={
                    localStorage.getItem("lang") == "ar"
                      ? "error ar"
                      : "error en"
                  }
                >
                  {formik.errors.projName}
                </div>
              ) : null}
            </div>

            {/* add task field */}
            <div
              className="add_task"
              style={{ marginTop: "40px", marginBottom: "40px" }}
            >
              <label>
                {t("add_task")}{" "}
                <Tooltip title={t("tap_to_add_task")}>
                  <img
                    style={{ cursor: "pointer" }}
                    src={AppImage.plus}
                    onClick={() => {
                      if (totalTasks < 20) setShowTaskModal(true);
                      else {
                        toast.error(t("max_20_tasks"), {
                          className:
                            localStorage.lang == "ar"
                              ? "Select_data ar"
                              : "Select_data en",
                          position:
                            localStorage.lang == "ar"
                              ? toast.POSITION.TOP_LEFT
                              : toast.POSITION.TOP_RIGHT,
                          toastId: "max20tasks",
                        });
                      }
                    }}
                  />
                </Tooltip>
              </label>
              <input
                id="addTask"
                style={{ display: "none" }}
                value={formik.values.addTask}
              />
              {formik.errors.addTask ? (
                <div
                  className={
                    localStorage.getItem("lang") == "ar"
                      ? "error ar"
                      : "error en"
                  }
                >
                  {formik.errors.addTask}
                </div>
              ) : null}
            </div>

            {/* task list table */}
            <div className="form-group">
              <label>{t("task_list")}</label>
              {dataSourceTaskList.length != 0 ? (
                <div className="inner_box_box">
                  <Table
                    dataSource={dataSourceTaskList}
                    columns={columnsTaskList}
                    showSorterTooltip={false}
                    pagination={true}
                    className="create-new-project-tasklist-table"
                    onChange={(pagination, filters, sorter) => {
                      // setPageNum
                      if (pagination?.current) setPageNum(pagination?.current);
                      if (sorter.order == undefined) {
                        setOrdering("");
                      }
                      if (
                        sorter.order == "ascend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "taskname"
                      ) {
                        setOrdering("name");
                      }
                      if (
                        sorter.order == "descend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "taskname"
                      ) {
                        setOrdering("-name");
                      }
                      if (
                        sorter.order == "ascend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "tasktype"
                      ) {
                        setOrdering("task_type");
                      }
                      if (
                        sorter.order == "descend" &&
                        sorter.order != undefined &&
                        sorter.columnKey == "tasktype"
                      ) {
                        setOrdering("-task_type");
                      }
                    }}
                  />
                </div>
              ) : (
                <CustomEmptyDiv title={t("added_tasks_appear_here")} />
              )}
            </div>

            {/* add new project button */}
            <button
              type="submit"
              disabled={isDisable}
              onClick={() => { }}
              className="button-list bottom_button pt-10 focus"
            >
              {t("add")}
            </button>
            {/* </Link> */}
            <div></div>
          </div>
        </form>

        <AddTaskModal
          show={showTaskModal}
          // dataToShow={addTaskModalDataToShow}
          hideTaskModal={() => {
            setDeleted(!deleted); // only just to re-render and rehit the get task list api
            setShowTaskModal(false);
          }}
        />

        {/* remove modal */}
        <Modal
          maskClosable={false}
          title={t("remove")}
          open={isModalRemove}
          onOk={() => {
            setIsModalRemove(false);
          }}
          onCancel={() => {
            setIsModalRemove(false);
          }}
          footer={null}
          centered
        >
          <div className="text-center  model1">
            <p>{t("remove_task")}</p>
          </div>
          <div
            className="text-center  model1"
            style={{
              direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
            }}
          >
            <button
              type="submit"
              className="button-list top_new focus"
              onClick={() => {
                const payloadForTaskRemove = {
                  body: {
                    id: taskRemoveId,
                  },
                  success: (res) => {
                    setDeleted(!deleted);
                    toast.success(res.message, {
                      className:
                        localStorage.lang == "ar"
                          ? "Select_data ar"
                          : "Select_data en",
                      position:
                        localStorage.lang == "ar"
                          ? toast.POSITION.TOP_LEFT
                          : toast.POSITION.TOP_RIGHT,
                      toastId: "taskRemoved",
                    });
                  },
                  fail: () => {
                    console.log("");
                  },
                };

                isInternetConnected() &&
                  dispatch(deleteAddedTask(payloadForTaskRemove));

                setIsModalRemove(false);
              }}
            >
              {t("yes")}
            </button>
            <button
              type="submit"
              className="focus"
              onClick={() => {
                handleCancelRemove(false);
              }}
            >
              {t("no")}
            </button>
          </div>
        </Modal>
      </CardHoc>
    </Container>
  );
};
export default CreateNewProject;
