import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal } from "antd";
import { TextField } from "../../../molecules";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { textAreaSize } from "../../../themes/appconstant";
import PropTypes from "prop-types";
// const { Option } = Select;

const AddSkillModal = ({
  // show = false,
  hideSkillModal = () => { },
  title = "",
  modalWidth = 500,
  submitTitle = null,
  dataToShow = {}
}) => {
  const { t } = useTranslation();
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const skillOptions = dataToShow.skillsCategory;
  const skillNameOptions = [
    {
      name: "Cde",
      value: "cde",
    },
  ];
  const selectCustomStyle = {
    cursor: "pointer",
    caretColor: "transparent",
  };
  const onSubmit = () => {
    hideSkillModal();
  };
  const initialValues = {
    skillCategory: '',
    skillName: '',
    skillDescription: '',
    skillExperience: '',
    skillAddInfo: '',
  };

  const validationSchema = Yup.object().shape({
    skillCategory: Yup.string().required(t('plz_select_smthng', { something: t('skill_cat_fullstop') })),
    skillName: Yup.string()
      .test('trim', t('plz_enter_something', { something: t('name') }), value => value?.trim()?.length > 0)
      .required(t('plz_enter_something', { something: t('vald_name_full_stop') })),

    skillDescription: Yup.string()
      .test('trim', t('plz_enter_something', { something: t('description') + '.' }), value => value?.trim()?.length > 0)
      .required(t('plz_enter_something', { something: t('skill_desc_fullstop') }))
      .matches(
        /^(\S)[\w\s\d\S]+[^\s]$/,
        t('plz_enter_something', { something: t('vald_name_full_stop') })
      )
      .max(100, t('max_chars', { max: '100' }))
      .min(20, t('string_min', { min: '20', name: t('name') }))
    ,
    skillExperience: Yup.string().required(t('please_select_experience')),
    skillAddInfo: Yup.string()
      .test('trim', t('pls_enter_add_info'), value => value?.trim()?.length > 0)
      .matches(
        /^(\S)[\w\s\d\S]+[^\s]$/,
        t('pls_enter_add_info')
      )
      .max(100, t('max_chars', { max: '100' }))
      .min(20, t('string_min', { min: '20', name: t('add_info') }))
  });

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });
  const addSkillModalFormFields = [
    {
      type: "text",
      error: formik.errors.skillCategory,
      selectTrue: true,
      id: "skillCategory",
      value: formik.values.skillCategory || t('skill_category_ph'),
      selectOptions: dataToShow.skillsCategory,
      customInputStyle: selectCustomStyle,
      fieldLabel: t('category'),
      selectOnChange: (val) => {
        formik.setValues({
          ...formik.values,
          skillCategory: val,
        });
      },
    },
    {
      type: "text",
      error: formik.errors.skillName,
      selectTrue: true,
      id: "skillName",
      selectOptions: skillNameOptions,
      value: formik.values.skillName || t('skill_name_ph'), // configure later
      customInputStyle: selectCustomStyle,
      fieldLabel: t('name'),
      selectOnChange: (val) => {
        formik.setValues({
          ...formik.values,
          skillName: val,
        });
      },
    },
    {
      type: "text",
      textAreaTrue: true,
      isAutoComplete: false,
      tar: textAreaSize.rows,
      id: "skillDescription",
      tac: textAreaSize.columns,
      value: formik.values.skillDescription, // configure later
      onChange: () => formik.handleChange,
      fieldLabel: t('description'),
      placeholder: t('enter_desc'),
      error: formik.errors.skillDescription,
    },
    {
      type: "text",
      error: formik.errors.skillExperience,
      selectTrue: true,
      id: "skillExperience",
      selectOptions: skillOptions,
      value: formik.values.skillExperience || t('skill_experience_ph'), // configure later
      customInputStyle: selectCustomStyle,
      fieldLabel: t('experience'),
      selectOnChange: (val) => {
        formik.setValues({
          ...formik.values,
          skillExperience: val,
        });
      },
    },
    {
      type: "text",
      textAreaTrue: true,
      isAutoComplete: false,
      tar: textAreaSize.rows,
      id: "skillAddInfo",
      tac: textAreaSize.columns,
      value: formik.values.skillAddInfo, // configure later
      onChange: () => formik.handleChange,
      fieldLabel: t('add_info'),
      placeholder: t('enter_add_info'),
      error: formik.errors.skillAddInfo
    },
  ];
  useEffect(() => {
    // setIsModalVisible(show);
    const modalPopUpCrossButton = document.querySelectorAll('button[aria-label="Close"]');
    const modalLabelsAndPlaceholders = document.querySelectorAll('.poolworkers .ant-select-selection-item,.poolworkers .form-group > label, .poolworkers .skillAddInfo');
    const modalDropdownArrows = document.querySelectorAll('.poolworkers span.ant-select-arrow');
    const textAreas = document.querySelectorAll('textarea');
    if (modalPopUpCrossButton && localStorage.getItem('lang') == 'ar') {
      for (let i = 0; i < textAreas.length; i++) {
        const element = textAreas[i];
        element.style.textAlign = 'right';
      }
      for (let i = 0; i < modalLabelsAndPlaceholders.length; i++) {
        const element = modalLabelsAndPlaceholders[i];
        element.style.textAlign = 'right';
      }
      for (let i = 0; i < modalDropdownArrows.length; i++) {
        const element = modalDropdownArrows[i];
        element.style.right = 'unset';
        element.style.left = '19px';
      }
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = 'unset';
        modalPopUpCrossButton[i].style.left = '0';
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem('lang') == 'en') {
      for (let i = 0; i < modalLabelsAndPlaceholders.length; i++) {
        const element = modalLabelsAndPlaceholders[i];
        element.style.textAlign = 'left';
      }
      for (let i = 0; i < modalDropdownArrows.length; i++) {
        const element = modalDropdownArrows[i];
        element.style.left = 'unset';
        element.style.right = '19px';
      }
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = '0';
        modalPopUpCrossButton[i].style.left = 'unset';
      }
    }
  });
  useEffect(() => {
  }, [dataToShow])


  return (
    <Modal
      title={title}
      centered
      footer={null}
      // onOk={() => {
      //   // setIsModalVisible(false);
      // }}
      width={modalWidth}
      onCancel={() => {
        // setIsModalVisible(false);
        hideSkillModal();
      }}
      open={false}//isModalVisible
      wrapClassName="poolworkers"
    >
      <form noValidate onSubmit={formik.handleSubmit}>
        {addSkillModalFormFields.length ? (
          <div className="text-center">
            <div className="wrapper_line models_data">
              {addSkillModalFormFields.map((items, index) => {
                return (
                  <div key={index} className="wrapper_line form_wrapper remove-horizontal-space">
                    <TextField
                      {...items}
                      labelTitle={items.fieldLabel}
                      onChange={formik.handleChange}//onTextChange} 
                      errorClassName={localStorage.getItem('lang') == 'ar' ? 'error ar' : 'error en'}
                    />
                  </div>
                );
              })}
            </div>
            {submitTitle != null ? (
              <div className="text-center  model1">
                <button
                  type="submit"
                  className="button-list top_new color_new pt-50 bottom_button sp focus"
                >
                  {submitTitle}
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
      </form>
    </Modal>
  );
};

AddSkillModal.propTypes = {
  show: PropTypes.bool,
  hideSkillModal: PropTypes.func,
  title: PropTypes.string,
  modalWidth: PropTypes.number,
  submitTitle: PropTypes.string,
  dataToShow: PropTypes.object,
};

export default AddSkillModal;
