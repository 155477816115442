import { OWNER_SUCCESS,SET_OWNER_LIST,SET_OWNER_DETAILS, LOGOUT } from "./../../../actionTypes/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    status: 0,  
    success : false ,
    updateSuccess : false,
    ownerDetails : null,
    ownerList : [],

}
const ownerReducer = (state = initialState, action) => {
    // console.log(action?.payload);
    switch (action.type) {
        case LOGOUT :
            return {
                ...state,
                isLoading: false,
                error: null,
                status: 0,  
                success : false ,
                updateSuccess : false,
                ownerDetails : null,
                ownerList : [],
            };
        case OWNER_SUCCESS:
            return { 
                ...state ,
                success :  true
            }
        case SET_OWNER_LIST:
            return { 
                 ...state ,
                 ownerList :  action?.payload
             }
        case SET_OWNER_DETAILS:
            return { 
                 ...state ,
                 ownerDetails :  action?.payload
             }
 
        default:
            return state;

    }
}

export default ownerReducer;