import { 
    STOP_LOADING ,
    START_LOADING,
    DISABLE_PROFILE_COMPLETION,
    SEARCH_VALUE,
    SEARCH_DATE,
    DISPUTE_LIST_START_LOADING,
    DISPUTE_LIST_STOP_LOADING,
    PASS_SUCESS,
    LOGOUT,
    SET_IMAGE_LOADING
 } from "./../../actionTypes/actionTypes";

const initialState = {
    loading: false ,
    taskLoading: false ,
    disableProfile : false ,
    searchValue : '' ,
    searchDate : '' ,
    passSuccess : false,
    profileLoad:false,
    imageLoading : Math.random()
};
const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT :
            return {
              ...state,
              loading: false ,
              taskLoading: false ,
              disableProfile : false ,
              searchValue : '' ,
              searchDate : '' ,
              passSuccess : false,
              profileLoad:false.valueOf,
              imageLoading : null
            };
        case SET_IMAGE_LOADING:
            return {
              ...state,
              imageLoading: action.payload,
            };
        case START_LOADING:
            return {
              ...state,
              loading: true,
            };
       case STOP_LOADING:
            return {
              ...state,
              loading: false,
            };
        case 'PROFILE_START_LOADING':
            return {
              ...state,
              profileLoad: true,
            };
       case 'PROFILE_STOP_LOADING':
            return {
              ...state,
              profileLoad: false,
            };
        case DISPUTE_LIST_START_LOADING:
            return {
              ...state,
              disputeLoading: true,
            };
       case DISPUTE_LIST_STOP_LOADING:
            return {
              ...state,
              disputeLoading: false,
            };
        case 'TASK_START_LOADING': 
            return {
              ...state,
              taskLoading: true,
            };
       case "TASK_STOP_LOADING":
            return {
              ...state,
              taskLoading: false,
            };
       case DISABLE_PROFILE_COMPLETION:
            return {
              ...state,
              disableProfile : action.payload 
            };
       case SEARCH_VALUE:
            return {
              ...state,
              searchValue : action.payload 
            };
       case SEARCH_DATE:
            return {
              ...state,
              searchDate : action.payload 
            };
       case PASS_SUCESS:
            return {
              ...state,
              passSuccess : action.payload 
            };
        default:
            return {
                ...state
            }
    }
}

export default commonReducer;