import { Modal, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CustomEmptyDiv } from "../../../../../molecules";
import { NavigateTo, SidebarMenus } from "../../../../../themes";

const AnnotationModal = (props) => {
  const {
    // classNames, 
    imageArray,
    imageIndex,
    // annotDetails,
    // projId,
    annotationDS,
    annotationTextDS,
    classLists,
    isModalVisible,
    annotationAudioDS,
    hideModal = () => { },
  } = { ...props };

  const navigate = useNavigate();
  const { t } = useTranslation();

  const taskType = localStorage.getItem("taskType");
  const taskId = useSelector(state => state?.userTaskManagement?.taskId);
  const showAccpetModal = false;
  const udtBreadCrumbsAr = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: "Dashboard",
    },
    {
      link: NavigateTo.PROJECT,
      label: "Projects",
    },
    {
      link: NavigateTo.PAST_PROJECT_DETAILS,
      label: "Past Project Details",
    },
    {
      link: NavigateTo.PAST_TASK_DETAILS + `/${taskId}`,
      label: "Task Details",
    },
    {
      link: "",
      label: "Annotate",
    },
  ];
  const udtBreadCrumbsEn = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: "لوحة القيادة",
    },
    {
      link: NavigateTo.PROJECT,
      label: "المشاريع",
    },
    {
      link: NavigateTo.PAST_PROJECT_DETAILS,
      label: "تفاصيل المشروع السابق",
    },
    {
      link: NavigateTo.PAST_TASK_DETAILS + `/${taskId}`,
      label: "تفاصيل المهمة",
    },
    {
      link: "",
      label: "وسم",
    },
  ];

  const udtContainerProps = {
    breadCrumbItems: {
      en: udtBreadCrumbsEn,
      ar: udtBreadCrumbsAr
    },
    menuProps: SidebarMenus("owner"),
    active: t('projects'),
  };

  // image table columns
  const annotationC = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      width: 150,
      render: (value, item, index) => index + 1,
    },
    {
      title: t('worker_name'),
      dataIndex: "worker",
      key: "worker",
      width: 100,
      sorter: false,
      render: (val) => {
        return <Tooltip title={val}>
          {val}
        </Tooltip>
      }
    },
    {
      title: t("annot_name"),
      dataIndex: "annotation_name",
      key: "annotation_name",
      width: 200,
      sorter: false,
      render: (val) => {
        return <Tooltip title={val}>
          {val}
        </Tooltip>
      }
    },
    {
      title: t("file_id"),
      dataIndex: "file_id",
      key: "file_id",
      width: '14%',
      sorter: false,
      render: (value) => {
        const tempVal = localStorage.lang == 'ar' ? value.split('').reverse().concat('') : value;

        return (
          <Tooltip placement="topLeft" title={tempVal}>
            {tempVal}
          </Tooltip>
        );
      }
    },
    {
      title: t("class"),
      dataIndex: "class",
      key: "class",
      width: 250,
      sorter: false,
      render: (val) => {
        return <Tooltip title={val}>
          {val}
        </Tooltip>
      }
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text, record) => {
        return (
          <div
            style={{
              marginLeft: "12px",
            }}
          >
            {/* view button */}
            <button
              type="button"
              className="focus"
              onClick={() => {
                navigate(NavigateTo.USER_UDT_OWNER + `/${taskId}/${localStorage.getItem('imageIndex')}`, {
                  state: {
                    udtContainerProps,
                    taskType,
                    removeIcos: true,
                    images: imageArray,
                    imageIndex: imageIndex,
                    particularAnnotation: record?.annotDetails,
                  },
                });
              }}
            >
              {t("view")}
            </button>

          </div>
        );
      },
    },
  ];

  //audio table columns. 
  const annotationAudioC = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      width: 150,
      render: (value, item, index) => index + 1,
    },
    {
      title: t('worker_name'),
      dataIndex: "worker",
      key: "worker",
      width: 100,
      sorter: false,
    },
    {
      title: t("annot_name"),
      dataIndex: "annotation_name",
      key: "annotation_name",
      width: 200,
      sorter: false,
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text, record, index) => {
        return (
          <div
            style={{
              marginLeft: "12px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* view button */}
            <button
              type="button"
              className="focus"
              onClick={() => {
                if (localStorage.getItem("taskType") == "Audio") {
                  let singleAudio = [
                    {
                      url: record?.url,
                      jsonFile: record?.jsonFile,// jsonFile
                      task_id: 1,
                      anotation_id: 1,
                      worker_id: 1,
                    },
                  ];

                  navigate(NavigateTo.USER_UDT_OWNER + `/${taskId}`, {
                    state: {
                      taskType,
                      udtContainerProps,
                      audio: singleAudio,
                    },
                  });
                }
                if (localStorage.getItem('taskType') == 'Text') {
                  if (localStorage.getItem('textType') == 'questions') {
                    navigate(NavigateTo.USER_UDT_OWNER + `/${taskId}`, {
                      state: {
                        taskType,
                        udtContainerProps,
                        pdfUrl: record?.pdfUrl,
                        questions: record?.questions,
                        particularAnnotation: record?.savedAnnotation,
                        pdfUrl: record?.pdfUrl,
                        qnindex: index,
                      },
                    });
                  }
                  if (localStorage.getItem('textType') == 'segmentation') {
                    navigate(NavigateTo.USER_UDT_OWNER + `/${taskId}`, {
                      state: {
                        taskType,
                        udtContainerProps,
                        particularAnnotation: record?.particularAnnot,
                        segmentAllAnnots: record?.segmentAllAnnots,
                        classes: classLists,
                        paragraph: record?.paragraph
                      },
                    });
                  }
                }
              }}
            >
              {t("view")}
            </button>


          </div>
        );
      },
    },
  ];

  useEffect(() => {
  }, [showAccpetModal, annotationAudioDS]);

  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll('button[aria-label="Close"]');
    if (modalPopUpCrossButton && localStorage.getItem('lang') == 'ar') {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = 'unset';
        modalPopUpCrossButton[i].style.left = '0';
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem('lang') == 'en') {

      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = '0';
        modalPopUpCrossButton[i].style.left = 'unset';
      }
    }
  }, [isModalVisible]);

  return (
    <>
      {/* annotation modal */}
      <Modal
        title={t("annot_list")}
        centered
        footer={null}
        onOk={() => {
          hideModal(false);
        }}
        width='75%'
        onCancel={() => {
          {
            hideModal(false);
          }
        }}
        open={isModalVisible}
        wrapClassName="poolworkers"
        className={localStorage.lang == 'ar' ? 'new_layout ar validator-pending-annot-modal' : 'new_layout en validator-pending-annot-modal'}
      >
        {
          <div
            className={`text-center modal2 remove-left-pad vct-modal  AnnotClassName `}
          >
            <div className="form-group wrapper_line models">

              {annotationAudioDS.length || annotationDS?.length ||
                annotationTextDS?.length ? (
                <Table
                  pagination={true}
                  columns={
                    localStorage.getItem("taskType") == "Audio"
                      ?
                      annotationAudioC
                      : localStorage.getItem("taskType") == "Image"
                        ? annotationC
                        : annotationAudioC
                  }
                  showSorterTooltip={false}
                  dataSource={
                    localStorage.getItem("taskType") == "Audio"
                      ? annotationAudioDS
                      : localStorage.getItem("taskType") == "Image"
                        ? annotationDS
                        : annotationTextDS
                  }
                  className="udt worker-completed-taskdetails-annot past-proj-task-details"
                />
              ) : (
                <CustomEmptyDiv title={t("annots_wil_appear_here")} />
              )}
            </div>
          </div>
        }
      </Modal>
    </>
  );
};

export default AnnotationModal;
