import Fetch from "../Fetch/User";
import { put, call } from "redux-saga/effects";
import { logout, startLoading, stopLoading } from "../actions/Common";
import { customFunctions } from "../../themes/utils/CustomFunctions";


export function* getUserDetailsSaga({
  payload: { success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getOwnerDetailsApi);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result?.result);
      yield put(logout());
    } else {
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
export function* userDeleteSaga({ payload }) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.userDeleteApi, payload);

    if (result.status === 1) {
      customFunctions.showToast(result?.result?.data, true, "dltUsersuccess");

      yield put(stopLoading());
    } else {
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
