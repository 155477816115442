import { Modal, Pagination, Table } from "antd";
import { Link } from "react-router-dom";
import { CardHoc, Container } from "../../../hoc";
import React, { useEffect, useState } from "react";
import { CustomPageHeader } from "../../../molecules";
import { AppImage, NavigateTo, SidebarMenus } from "../../../themes";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { useDispatch } from "react-redux";
import { deleteGroup, /* getAllGroupLists */ } from "../../../redux/actions/Group";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const GroupManagement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [totalGroups, _setTotalGroups] = useState(0);
  const [groupId, setGroupId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [deleted, setDeleted] = useState(false);

  const [pageNum, setPageNum] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const [ordering, setOrdering] = useState("");

  const [dataSource, _setDataSource] = useState([]);
  const columns = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => pageNum * 10 - 10 + (index + 1),
    },
    {
      title: t("group_id"),
      dataIndex: "groupid",
      key: "groupid",
      sorter: true,
      width: 90,
    },
    {
      title: t("grp_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: t("total_allocated_tasks"),
      dataIndex: "totalTasks",
      key: "totalTasks",
      sorter: false,
      width: 280,
    },
    {
      title: t("action"),
      dataIndex: "Posted",
      key: "Posted",
      render: (value, item) => {
        return (
          <div className="tex-center_">
            <button
              type="button"
              className="focus"
              onClick={() => {
                navigate(NavigateTo.EDIT_GROUP, {
                  state: {
                    groupName: item.name,
                    groupId: item?.groupid?.props?.children,
                    groupMembers: JSON.stringify([
                      {
                        id: "032957A8d660AF0aE8551e74",
                        name: "string",
                        email: "user@example.com",
                        image: "string",
                        rating: 4,
                      },
                    ]),
                    groupMemberss: item?.groupMembers,
                    tasks: JSON.stringify(item.tasks),
                  },
                });
              }}
            >
              {t("edit")}
            </button>
            {/* </Link> */}
            <button
              type="button"
              className="focus"
              onClick={() => {
                setGroupId(item?.groupid?.props?.children);
                setIsModalVisible(true);
              }}
            >
              {t("delete")}
            </button>
          </div>
        );
      },
    },
  ];

  const breadCrumbItems = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: "",
      label: t("grp_mngmnt"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t("grp_mngmnt"),
  };
  const initialValues = {
    searchValue: searchValue,
  };
  const formik = useFormik({
    initialValues,
  });
  useEffect(() => {
    document.title = "WosoM";
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    // const payload = {
    //   body: {
    //     pageNum,
    //     searchValue,
    //     ordering,
    //   },
    //   success: (res) => {
    //     const modifiedRes = [];
    //     setTotalGroups(res?.count);
    //     res?.results?.filter((item, index) => {
    //       modifiedRes.push({
    //         key: index + 1,
    //         name: item?.name,
    //         groupid: <span>{item?.id}</span>,
    //         totalTasks: item?.task_allocated, //item.tasks.length, change it later
    //         groupMembers: item.members,
    //       });
    //     });
    //     setDataSource(modifiedRes);
    //   },
    //   fail: (err) => {
    //     console.log(err, "err");
    //   },
    // };
    // isInternetConnected &&
      // isModalVisible == false &&
      // dispatch(getAllGroupLists(payload));
  }, [deleted, pageNum, searchValue, ordering]);

  return (
    <Container
      {...containerProps}
    >
      <CustomPageHeader
        wrapperClassName={"top_button_item d-flex justify-content-between"}
      >
        <div className="button_left contain">
          <Link to={NavigateTo.ADD_GROUP}>
            <button type="button" className="button-list top_new focus">
              {t("add_new_group")}
            </button>
          </Link>
          <input
            type="text"
            className="search-bar"
            id="searchValue"
            name="searchValue"
            value={formik.values.searchValue}
            onChange={(e) => {
              formik.setValues({
                searchValue: e.target.value,
              });
              setSearchValue(e.target.value);
              setPageNum(1);
            }}
            placeholder={t("search")}
            autoComplete="off"
          />
        </div>
      </CustomPageHeader>

      <CardHoc cardInnerPadding={"content-e"}>
        {/* group management title */}
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
          <h6 className="text-white text-capitalize ps-3">{t("grp_mngmnt")}</h6>
        </div>

        {/* added group list */}
        {dataSource.length != 0 ? (
          <div id="paginationOfgroup" className="group-table-owner">
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              showSorterTooltip={false}
              onChange={(pagination, filters, sorter ) => {
                if (sorter.order == undefined) {
                  setOrdering("");
                }
                if (
                  sorter.order == "ascend" &&
                  sorter.order != undefined &&
                  sorter.columnKey == "groupid"
                ) {
                  setOrdering("-id");
                }
                if (
                  sorter.order == "descend" &&
                  sorter.order != undefined &&
                  sorter.columnKey == "groupid"
                ) {
                  setOrdering("id");
                }
                if (
                  sorter.order == "ascend" &&
                  sorter.order != undefined &&
                  sorter.columnKey == "name"
                ) {
                  setOrdering("-name");
                }
                if (
                  sorter.order == "descend" &&
                  sorter.order != undefined &&
                  sorter.columnKey == "name"
                ) {
                  setOrdering("name");
                }
              }}
            />
            <Pagination
              showTitle={false}
              style={{
                paddingTop: "30px",
                paddingBottom: "30px",
                maxWidth: "fit-content",
                right: "33px",
                left: "auto",
                position: "absolute",
              }}
              className="pagination-component"
              defaultCurrent={1}
              total={totalGroups} //
              size="medium"
              onChange={(item) => {
                setPageNum(item);
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              height: "250px",
              color: "#c3bcbc",
              fontWeight: 400,
              fontSize: "17px",
              alignItems: "center",
              justifyContent: "center",

              flexDirection: "column",
            }}
          >
            <img
              style={{
                height: "76px",
                width: "95px !important",
              }}
              src={AppImage.emptyImg}
            />
            <div style={{}}>{t("added_grps_appear_here")}</div>
          </div>
        )}
      </CardHoc>

      {/* remove group confirmation  modal */}
      <Modal
        title={t("delete")}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className="success_data delete"
        centered
      >
        {/* remove title */}
        <div
          className={
            localStorage.lang == "en"
              ? "text-center  model1"
              : "text-center  model1 ar"
          }
        >
          <p>{t("u_sure_to_dlt_this_grp")}</p>
        </div>

        <div
          className="text-center  model1"
          style={{
            direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
          }}
        >
          {/* yes button */}
          <button
            type="button"
            className="button-list top_new focus"
            onClick={() => {
              const payloadForDeleteGroup = {
                success: () => {
                  toast.success(t("grp_dltd"), {
                    className:
                      localStorage.lang == "ar"
                        ? "Select_data ar"
                        : "Select_data en",
                    position:
                      localStorage.lang == "ar"
                        ? toast.POSITION.TOP_LEFT
                        : toast.POSITION.TOP_RIGHT,
                    toastId: "groupDeleted",
                  });
                  setDeleted(!deleted);
                },
                fail: (res) => {
                  toast.error(res?.result?.errors?.non_field_errors[0], {
                    position: "top-right",
                    toastId: "deletegrp",
                  });
                },
                groupId,
              };
              isInternetConnected() &&
                dispatch(deleteGroup(payloadForDeleteGroup));
              setIsModalVisible(false);
            }}
          >
            {t("yes")}
          </button>
          {/* no button */}
          <button
            type="button"
            className="focus"
            onClick={() => setIsModalVisible(false)}
          >
            {t("no")}
          </button>
        </div>
      </Modal>
    </Container>
  );
};

export default GroupManagement;
