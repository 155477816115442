import React, { useEffect, useState } from "react";
import { CardHoc, Container } from "../../../../hoc";
import {
  CustomEmptyDiv,
  CustomPageHeader,
  CustomTable,
  TextField,
} from "../../../../molecules";
import {
  AppImage,
  NavigateTo,
  SidebarMenus,
} from "../../../../themes";
import { Collapse, Modal, Rate } from "antd";
import {  useParams } from "react-router";
import { useTranslation } from "react-i18next";
import appconstant from "../../../../themes/appconstant";
import { useDispatch } from "react-redux";
import { getTaskDetails, saveTaskId } from "../../../../redux/actions/Tasks";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn";
import parse from "html-react-parser";
import { giveRate } from "../../../../redux/actions/Rating";
import { toast } from "react-toastify";
import { AnnotationModal } from "./Modals/index";

const { Panel } = Collapse;

// NOTE : DATE RANGE PICKER NOT SHOWING DUE TO SOME RE RENDERING ISSUE DO IT LATER

const ValidatorCompletedTaskDetails = () => {
  const { t } = useTranslation();
  const { taskId } = useParams(); //localStorage.getItem("validatorCompletedTaskId");
  const dispatch = useDispatch();
  // const [taskType, setTaskType] = useState(localStorage.getItem("taskType"));

  // const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false); //annnot modal
  const [isRatingVisible, setIsRatingModalVisible] = useState(false); // rating modal
  // const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [workerLists, setWorkers] = useState([]);

  // text
  const [textLists, setTextLists] = useState([]);
  const [annotationTextDS, setAnnotationTextDS] = useState([]);
  // text

  const [rateVal, setRateVal] = useState(null);
  const [workerId, setWorkerId] = useState(null);
  // const [validatorId, setValidatorId] = useState(null);

  const [annotDetails, setAnnotDetails] = useState([]);
  const [annotationDS, setAnnotationDS] = useState([]); // annotationImageDS
  const [imageIndex, setImageIndex] = useState("");

  //audio start
  const [audioLists, setAudioLists] = useState([]);
  const [audioIndex, setAudioIndex] = useState(null);
  const [annotationAudioDS, setAnnotationAudioDS] = useState([]);
  //audio end

  const [classNames, setClassNames] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [questions, setQuestions] = useState(null);
  // const [audio, setAudio] = useState(null);
  const [classLists, setClassLists] = useState([]);
  const [taskInfo, setTaskInfo] = useState({
    taskName: "",
    taskType: "",
    instruction: "",
    description: "",
    taskCompletionDate: null,
  });

  const reRenderCustomPageHeader = false;
  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.USER_TASK_MANAGEMENT,
      label: t("task_management"),
    },
    {
      link: NavigateTo.VALIDATOR_COMPLETED_TASK_DETAILS,
      label: t("val_cmpltd_task_details"),
    },
  ];

  /* page description */
  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
  };
  const rightFontStyle = {  
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: "break-all",
  };
  const tnl = [
    { labelTitle: `${t("tsk_name")} :`, customLabelStyle: leftFontstyle },
    {
      labelTitle: taskInfo.taskName,
      customLabelStyle: rightFontStyle,
    },
  ];

  const ttl = [
    { labelTitle: `${t("tsk_typ")} :`, customLabelStyle: leftFontstyle },
    {
      labelTitle: t(taskInfo.taskType),
      customLabelStyle: rightFontStyle,
    },
  ];
  const tdl = [
    {
      labelTitle: `${t("tsk_desc")} :`,
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo.description,
      customLabelStyle: rightFontStyle,
    },
  ];
  const tcdl = [
    {
      labelTitle: t("task_cmpltn_date"),
      customLabelStyle: leftFontstyle,
    },
    {
      labelTitle: taskInfo.taskCompletionDate,
      customLabelStyle: rightFontStyle,
    },
  ];
  let textFieldlabels;
  if (taskInfo.taskCompletionDate) {
    textFieldlabels = [tnl, ttl, tdl, tcdl];
  } else {
    textFieldlabels = [tnl, ttl, tdl];
  }

  /* page description */

  /* drop down */

  /* drop down */

  const audioLisHeaders = [
    {
      label: t("file_img"),
      headerWidth: "150px",
    },
    {
      label: t("file_name"),
      headerWidth: "150px",
    },
    {
      label: t("annotations"),
      headerWidth: "250px",
    },
    {
      label: t("action"),
      paddingLeft: "70px",
      headerWidth: "80px",
    },
  ]; // outer list table
  const workerListHeaders = [
    {
      label: t("profile"),
      headerWidth: "150px",
    },
    {
      label: t("name"),
      headerWidth: "150px",
    },
    {
      label: t("email"),
      headerWidth: "250px",
    },
    {
      label: t("action"),
      paddingLeft: "70px",
      headerWidth: "86px",
    },
  ]; // outer list table
  const classListHeaders = [
    {
      label: "",
      headerWidth: "110px",
      textAlign: localStorage.getItem("lang") == "ar" ? "end" : "start",
    },
    {
      label: t("clss_name"),
      headerWidth: "200px",
      textAlign: localStorage.getItem("lang") == "ar" ? "end" : "start",
    },
    {
      label: t("annotations"),
      headerWidth: "50px",
      textAlign: localStorage.getItem("lang") == "ar" ? "end" : "start",
    },
    {
      label: t("action"),
      headerWidth: "127px", //"150px",
      paddingLeft: localStorage.getItem("lang") == "ar" ? "95px" : "73px",
    },
  ];
  const dropDownGroup = [
    {
      table: {
        hasNestedDropDown: false,
        placeholder: t("cls_list"),
        tableHeaders: classListHeaders,
        tableContents: classLists,
        tableContentsHasArrayOFObj: true,
        AnnotClassName:
          localStorage.getItem("lang") == "ar"
            ? "validator-completed-annot-headers-ar-language"
            : "validator-completed-annot-headers-en-language",
        tBodyClassName: "last-child",
        tdClassName:
          localStorage.getItem("lang") == "ar"
            ? "validator-completed-class-list-cells-ar-language"
            : "validator-completed-class-list-cells-en-language",
      }, // class list
    }, // class lists
    {
      table: {
        placeholder:
          taskInfo?.taskType == "audio" ? t("audio_list") : t("txt_list"),
        tableHeaders: audioLisHeaders,
        tableContents: taskInfo?.taskType == "audio" ? audioLists : textLists,
        // audioLists,
        tableContentsHasArrayOFObj: true,
        tdImageWidth: taskInfo?.taskType == "text" ? "unset" : null,
        tdImageHeight: taskInfo?.taskType == "text" ? "unset" : null,
        tdImageBorderRadius: taskInfo?.taskType == "text" ? "unset" : null,
        tBodyClassName: "last-child",
        AnnotClassName: "wptd",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data user-task-manage-worker-completed-task-details-class-list-ar"
            : "text_data user-task-manage-worker-completed-task-details-class-list-en",
      },
    }, // audio Lists
    {
      table: {
        placeholder: t("worker_list"),
        tableHeaders: workerListHeaders,
        tableContents: workerLists,
        tableContentsHasArrayOFObj: true,
        tBodyClassName: "last-child",
        AnnotClassName: "wptd",
        tableClassName:
          localStorage.getItem("lang") == "ar"
            ? "text_data user-task-manage-worker-completed-task-details-worker-list-ar vctd"
            : "text_data user-task-manage-worker-completed-task-details-worker-list-en vctd",
      },
    }, // worker lists
  ];

  const NestedDropDowns = (props) => {
    const { list = [] } = { ...props };

    return list.map((item, index) => {
      return (
        <Collapse defaultActiveKey={["1"]} key={index}>
          <Panel id={index} header={item?.table?.placeholder} key="1">
            {item?.table?.hasNestedDropDown ? (
              <div style={{ padding: 15 }}>
                <TextField labelTitle={t('grp_name')} />
                <NestedDropDowns list={item?.table?.dropDownGroup} />
              </div>
            ) : (
              <CustomTable {...item?.table} buttonCallbackTrue={false} />
            )}
          </Panel>
        </Collapse>
      );
    });
  };
  /* drop down */

  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("user"),
    active: t("task_management"),
  };

  const payload = {
    body: {
      taskId,
    },
    success: (res) => {
      // setValidatorId(res?.data?.validators[0]?.id);
      setTaskInfo({
        taskName: res?.data?.name,
        taskType:
          res?.data?.task_type == 1
            ? "image"
            : res?.data?.task_type == 2
            ? "text"
            : "audio",
        instruction: res?.data?.instruction,
        description: res?.data?.description,
        taskCompletionDate: res?.data?.end_date
          ? res?.data?.end_date
              ?.split("T")[0]
              .split("-")
              .reverse()
              .join("-")
          : null,
      });
      // image
      if (res?.data?.task_type == 1) {
        localStorage.setItem("taskType", "Image");

        let classNames = [];
        res?.data?.classes.map((item) => {
          classNames.push(item?.name);
        });

        setClassNames(classNames);

        let tempClassLists = [];
        let tempImageArray = [];
        // let tempApiJsonImage = [];

        res?.data?.media.map((item, index) => {
          let imageDetails = {};
          imageDetails["annotationId"] = item?.id;
          imageDetails["jsonFile"] = item?.annotation;

          imageDetails["task_id"] = res?.data?.id;
          imageDetails["workerId"] = item?.worker_id; //res?.data?.workers[0].id;
          imageDetails["validator_id"] = res?.data?.validators[0].id;
          imageDetails["annotStatus"] = item?.status;

          tempClassLists.push({
            0: { image: appconstant.imageBaseUrl + item?.path },
            1: classNames.join(","),
            2: item?.annotation ? item?.annotation?.length : 0,
            3: {
              button: "View Annotations" ,
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: imageDetails, // send anything like image id item?.id or annot details
            },
          }); // tempClassLists
          tempImageArray.push({
            name: `Image-${index + 1}`,
            src: appconstant.imageBaseUrl + item?.path,
            regions:
              item?.annotation.length != 0
                ? item?.annotation[item?.annotation.length - 1].jsonFile
                    ?.regions
                : [],
            id: item?.id,
          }); // tempImageArray
        });
        setClassLists(tempClassLists);
        setImageArray(tempImageArray);

        let tempWorkers = [];
        res?.data?.workers.map((item) => {
          tempWorkers.push({
            0: {
              image: item?.user_id?.image
                ? appconstant.imageBaseUrl + item?.user_id?.image
                : AppImage.defaultImage,
            },
            1: item?.user_id?.first_name + " " + item?.user_id?.last_name,
            2: item?.user_id?.email,
            3: {
              button: "Rate",
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: item?.user_id?.id, // send anything like image id item?.id or annot details
            },
          });
        });
        setWorkers(tempWorkers);
      } // image

      // text
      if (res?.data?.task_type == 2) {
        localStorage.setItem("taskType", "Text");

        if (res?.data?.text_type == "segmentation") {
          localStorage.setItem("textType", "segmentation");
          let tempWorkers = [];
          let tempTextLists = [];
          let tempClassLists = [];

          res?.data?.workers.map((item) => {
            tempWorkers.push({
              0: { image: appconstant.imageBaseUrl + item?.user_id?.image },
              1: item?.user_id?.first_name + " " + item?.user_id?.last_name,
              2: item?.user_id?.email,
              3: {
                button: "Rate",
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: item?.user_id?.id, // send anything like image id item?.id or annot details
              },
            });
          });
          setWorkers(tempWorkers);

          res?.data?.media.map((item) => {
            let textFileName = item?.path; //.substring(0, 15) + "..."; //.split("/");
            let textDetails = {
              jsonFileForStartTask:
                item?.annotation[item?.annotation.length - 1]?.Anotation_id
                  ?.jsonFile,
              jsonFile: item?.annotation,
              paragraph: item?.path,
              totalTasks: res?.data?.media?.length,

              task_id: item?.task_id,
              anotation_id: item?.id,
              worker_id: item?.worker_id,
              media_id: item?.id,
            };
            tempTextLists.push({
              0: { image: AppImage.file_n },
              1: textFileName, //textFileName[textFileName.length - 1],
              2: item?.annotation ? item?.annotation?.length : 0,
              3: {
                button: "View Annotations" ,
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: textDetails, // send anything like image id item?.id or annot details
              },
            }); // tempTextLists
          });
          setTextLists(tempTextLists);

          res?.data?.classes.map((item) => {
            tempClassLists.push({
              id: item?.id,
              displayName: item?.name,
              task_id: item?.task_id,
            });
          });
          setClassLists(tempClassLists);
        }

        if (res?.data?.text_type == "questions") {
          localStorage.setItem("textType", "questions");

          localStorage.setItem("textType", "questions");
          let tempTextLists = [];
          const tempQuestions = [];
          // let annotCount = 0;

          JSON.parse(res?.data?.question?.questionsData)?.map((item, index) => {
            tempQuestions.push({
              name: `input${index}`,
              type:
                item?.answerType == "Check-Box"
                  ? "checkbox"
                  : "radio",
              title: item?.question,
              choices: item?.answers,
            });
          });

          res?.data?.media.map((item) => {
            // if (item?.annotation?.length == tempQuestions.length) {
            //   annotCount++;
            // }

            let textFileName = item?.path.split("/");
            let textDetails = {
              jsonFileForStartTask:
                item?.annotation[item?.annotation.length - 1]?.jsonFile,
              jsonFile: item?.annotation,
              pdfUrl: appconstant?.pdfBaseUrl + item?.path,
              task_id: item?.task_id,
              anotation_id: item?.id,
              worker_id: item?.worker_id,
              media_id: item?.id,
              totalTasks: res?.data?.media?.length,
            };
            tempTextLists.push({
              0: { image: AppImage.file_n },
              1: textFileName[textFileName.length - 1],
              2: item?.annotation ? item?.annotation?.length : 0,
              3: {
                button: "View Annotations" ,
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: textDetails, // send anything like image id item?.id or annot details
              },
            }); // tempTextLists
          });
          // setPdfUrl(tempPdfUrls);//res?.data?.pdfUrls
          setQuestions(tempQuestions);
          setTextLists(tempTextLists);

          let tempWorkers = [];
          res?.data?.workers.map((item) => {
            tempWorkers.push({
              0: { image: appconstant.imageBaseUrl + item?.user_id?.image },
              1: item?.user_id?.first_name + " " + item?.user_id?.last_name,
              2: item?.user_id?.email,
              3: {
                button: "Rate",
                link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
                id: item?.user_id?.id, // send anything like image id item?.id or annot details
              },
            });
          });
          setWorkers(tempWorkers);
        }
      } // text

      // audio
      if (res?.data?.task_type == 3) {
        localStorage.setItem("taskType", "Audio");

        let tempAudioArray = [];
        let tempAudioLists = [];
        res?.data?.media.map((item) => {
          let audioFileName = item?.path.split("/");
          let audioDetails = {};

          audioDetails["jsonFile"] = item?.annotation || [];
          audioDetails["audioUrl"] = appconstant.imageBaseUrl + item?.path;

          audioDetails["task_id"] = res?.data?.id;
          audioDetails["workerId"] = item?.worker_id; //res?.data?.workers[0].id;
          audioDetails["validator_id"] = res?.data?.validators[0].id;
          audioDetails["annotationId"] = item?.id;
          audioDetails["annotStatus"] = item?.status;

          tempAudioLists.push({
            0: { image: AppImage.volumeFileImg },
            1: audioFileName[audioFileName.length - 1],
            // 1: classNames.join(","),
            2: item?.annotation ? item?.annotation?.length : 0,
            // 2: item?.jsonFile ? item?.jsonFile.length : 0,
            3: {
              button: "View Annotations" ,
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: audioDetails, // send anything like image id item?.id or annot details
            },
          }); // tempAudioLists
          // audio id
          tempAudioArray.push({
            url: appconstant.imageBaseUrl + item?.path,
            jsonFile:
              item?.annotation != null
                ? item?.annotation[item?.annotation.length - 1]?.jsonFile
                : [], // jsonFile
            task_id: item?.task_id,
            anotation_id: item?.id,
            worker_id: item?.worker_id,
          });
        });
        setAudioLists(tempAudioLists);
        // setAudio(tempAudioArray);

        let tempWorkers = [];
        res?.data?.workers.map((item) => {
          tempWorkers.push({
            0: {
              image: item?.user_id?.image
                ? appconstant.imageBaseUrl + item?.user_id?.image
                : AppImage.defaultImage,
            },
            1: item?.user_id?.first_name + " " + item?.user_id?.last_name,
            2: item?.user_id?.email,
            3: {
              button: "Rate",
              link: "", //NavigateTo.WORKER_PENDING_TASK_DETAILS,
              id: item?.user_id?.id, // send anything like image id item?.id or annot details
            },
          });
        });
        setWorkers(tempWorkers);
      } // audio
    },
    fail: (resf) => {
      console.log(resf, "resf of task details.");
      resf?.data &&
        toast.error(resf?.data, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
        });
      resf?.message &&
        toast.error(resf?.message, {
          className:
            localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
          position:
            localStorage.lang == "ar"
              ? toast.POSITION.TOP_LEFT
              : toast.POSITION.TOP_RIGHT,
        });
    },
  }; //  task details info payload
  const payloadRate = {
    body: JSON.stringify({
      rate: rateVal,
      user_by: workerId, // in api way user_to
      user_to: Number(localStorage.getItem("loggedInUserId")), // in api way user_by
    }),
    success: (res) => {
      console.log(res);
    },
    fail: (resf) => {
      console.log(resf, "resf of task details.");
    },
  }; //  task details info payload
  useEffect(() => {
    window.document.title = "WosoM";
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    const annotModalTabelCells = document.querySelectorAll(
      ".validator-completed-annot-modal th, .validator-completed-annot-modal td"
    );
    // const modalContent = document.querySelector('.ant-modal-content') ;
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
      if (isModalVisible) {
        document.querySelector(".ant-modal-content").style.direction = "rtl";
        for (let i = 0; i < annotModalTabelCells.length; i++) {
          const element = annotModalTabelCells[i];
          element.style.textAlign = "right";
        }
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
      if (isModalVisible) {
        document.querySelector(".ant-modal-content").style.direction = "ltr";
        for (let i = 0; i < annotModalTabelCells.length; i++) {
          const element = annotModalTabelCells[i];
          element.style.textAlign = "left";
        }
      }
    }
  }, [isModalVisible, isRatingVisible]);
  useEffect(() => {
    dispatch(saveTaskId(taskId));
    isInternetConnected("task") && dispatch(getTaskDetails(payload));
  }, []);
  return (
    <Container
      {...containerProps}
    >
      <CardHoc
        cardInnerPadding={"content-e tabs_button removeTopMagin val-completed"}
      >
        <CustomPageHeader
          label={t("val_cmpltd_task_details")}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px",
          }}
          islanguageChanged={reRenderCustomPageHeader}
        >
          <CustomPageHeader
            label={`${t("status")}: ${t("completed")}`}
            wrapperClassName={
              "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
            }
            customWrapperStyle={{
              display: "flex",
              marginTop: "-16px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginTop: "-6px",
              }}
            >
              <img src={AppImage.completedTask} />
            </div>
          </CustomPageHeader>
        </CustomPageHeader>
        <div className="wrapper_line">
          {/* pageDescription */}
          {textFieldlabels.map((item) => {
            return <TextField labels={item} key={item} />;
          })}
          {/* pageDescription */}

          {/* task Instruction */}
          <label style={leftFontstyle}>{t("instructions")} :</label>

          <div
            className="get-original-text-color"
            style={{
              marginTop: "12px",
            }}
          >
            <div className="ck-content">
              {taskInfo?.instruction ? (
                parse(taskInfo?.instruction)
              ) : (
                <p style={{ color: "#acb2ba" }}>
                  {t('inst_wil_appear_here')}
                </p>
              )}
            </div>
          </div>
          {/* task Instruction */}

          {taskInfo.taskType == "image" ||
          taskInfo.taskType == "segmentation" ? (
            // class list
            <Collapse
              defaultActiveKey={["1"]}
              onChange={() => {
                const isDropdownOpened = document
                  .querySelector(".ant-collapse-header")
                  .getAttribute("aria-expanded");
                const dropdownArrowsOnClickStyle = document.querySelectorAll(
                  ".ant-collapse-arrow svg"
                );
                if (isDropdownOpened == "true") { 
                } else {
                  dropdownArrowsOnClickStyle[0].setAttribute(
                    "style",
                    "transform: rotate(-90deg) !important;"
                  ); 
                }
              }}
              className={
                localStorage.lang == "ar"
                  ? "val-compltd-taskDetails ar"
                  : "val-compltd-taskDetails en"
              }
            >
              <Panel header={dropDownGroup[0].table.placeholder} key="1">
                {classLists.length > 0 ? (
                  <CustomTable
                    {...dropDownGroup[0].table}
                    handleClick={(label, id, link, row2, trIndex) => {
                      switch (label) {
                        case  "View Annotations" : {
                          if (id != null) {
                            let tempAnnotDetails = [];
                            const annots = id;
                            annots?.jsonFile?.map((item) => {
                              tempAnnotDetails.push({
                                annotation_name:
                                  item?.Anotation_id?.regions.type,
                                class: item?.Anotation_id?.regions.cls,
                                file_id: 
                                  item?.Anotation_id?.regions.id, 
                                worker:
                                  item?.worker_id?.user_id?.first_name +
                                  " " +
                                  item?.worker_id?.user_id?.last_name,
                                status:
                                  item?.status == 3
                                    ? "Rejected"
                                    : item?.status == 2
                                    ? "Approved"
                                    : "Pending",
                                // for raise dispute
                                annotationId: item?.id,
                                // for buttons to show if raise dispute or view comment or disable raise dispute
                                // annotStatus: 'Approved',
                                // to show vComments
                                vComments: item?.comment?.comment,
                                annotDetails: item?.Anotation_id?.jsonFile,
                                workerId: item?.worker_id?.id,
                                validator_id: item?.validator_id?.id,
                                annotation_id: item?.Anotation_id?.id,
                              });
                            });
                            setAnnotationDS(tempAnnotDetails);
                            setAnnotDetails(id);
                          }
                          localStorage.setItem("imageIndex", trIndex);
                          setImageIndex(trIndex);
                          // setReload(false);
                          setIsModalVisible(true);
                          break;
                        }
                        default:
                          break;
                      }
                    }}
                  />
                ) : (
                  <CustomEmptyDiv title={t("clss_appear_here")} />
                )}
              </Panel>
            </Collapse>
          ) : ( 
            // audio list
            <Collapse
              defaultActiveKey={["1"]}
              onChange={() => {}}
              className={
                localStorage.lang == "ar"
                  ? "val-compltd-taskDetails ar"
                  : "val-compltd-taskDetails en"
              }
            >
              <Panel header={dropDownGroup[1].table.placeholder} key="1">
                {audioLists.length > 0 || textLists.length > 0 ? (
                  <CustomTable
                    {...dropDownGroup[1].table}
                    handleClick={(label, id, link, row2, trIndex) => {
                      switch (label) {
                        case  "View Annotations" : {
                          if (taskInfo?.taskType == "audio") {
                            if (id != null) {
                              let tempAnnotDetails = [];
                              const audioDetails = id;

                              audioDetails?.jsonFile.map((item) => {
                                tempAnnotDetails.push({
                                  annotation_name: 
                                    item?.Anotation_id?.regions?.data?.note, // this only for validator case
                                  annotationId: item?.id,
                                  worker:
                                    item?.worker_id?.user_id?.first_name +
                                    " " +
                                    item?.worker_id?.user_id?.last_name,
                                  individualAnnots: [
                                    item?.Anotation_id?.regions,
                                  ], 
                                  workerId: item?.worker_id?.id,
                                  validator_id: item?.validator_id?.id,
                                  annotation_id: item?.Anotation_id?.id,
                                });
                              });
                              setAnnotationAudioDS(tempAnnotDetails); // data to show for Audio annotation list table of modal
                              setAnnotDetails(id); // all annotations for single audio
                            }
                            setAudioIndex(trIndex); 
                            setIsModalVisible(true);
                          }
                          if (
                            localStorage.getItem("textType") == "segmentation"
                          ) {
                            if (id != null) {
                              const textDetails = id;
                              let tempAnnotDetails = [];
                              textDetails?.jsonFile.map((item) => {
                                const annotName =
                                  item?.Anotation_id?.regions?.text;
                                tempAnnotDetails.push({
                                  annotation_name: 
                                    annotName,
                                  worker:
                                    item?.worker_id?.user_id?.first_name +
                                    " " +
                                    item?.worker_id?.user_id?.last_name,
                                  status:
                                    item?.status == 3
                                      ? "Rejected"
                                      : item?.status == 2
                                      ? "Approved"
                                      : item?.status == 4
                                      ? "Disputed"
                                      : "Pending",
                                  // for raise dispute
                                  annotationId: item?.id,
                                  // for buttons to show if raise dispute or view comment or disable raise dispute
                                  annotStatus: item?.status,
                                  // to show vComments
                                  vComments: item?.comment,
                                  paragraph: item?.paragraph,
                                  particularAnnot: [
                                    item?.Anotation_id?.regions,
                                  ],
                                  totalAnnots: item?.jsonFile,
                                  segmentAllAnnots:
                                    textDetails?.jsonFileForStartTask, 
                                  workerId: item?.worker_id?.id,
                                  validator_id: item?.validator_id?.id,
                                  annotation_id: item?.Anotation_id?.id,
                                });
                              });
                              setAnnotationTextDS(tempAnnotDetails);
                              setAnnotDetails(id);
                            }
                            setIsModalVisible(true);
                          }
                          if (localStorage.getItem("textType") == "questions") {
                            if (id != null) {
                              const textDetails = id;
                              let tempAnnotDetails = [];
                              textDetails?.jsonFile.map((item) => {
                                const questionIndex =
                                  item?.Anotation_id?.region_id;
                                const annotName =
                                  questions[
                                    questionIndex.split("")[
                                      questionIndex.length - 1
                                    ]
                                  ]?.title;
                                tempAnnotDetails.push({
                                  annotation_name: 
                                    annotName,
                                  worker:
                                    item?.worker_id?.user_id?.first_name +
                                    " " +
                                    item?.worker_id?.user_id?.last_name,
                                  status:
                                    item?.status == 3
                                      ? "Rejected"
                                      : item?.status == 2
                                      ? "Approved"
                                      : item?.status == 4
                                      ? "Disputed"
                                      : "Pending",
                                  // for raise dispute
                                  annotationId: item?.id,
                                  // for buttons to show if raise dispute or view comment or disable raise dispute
                                  annotStatus: item?.status,
                                  // to show vComments
                                  vComments: item?.comment,
                                  questions: [
                                    questions[
                                      questionIndex.split("")[
                                        questionIndex.length - 1
                                      ]
                                    ],
                                  ],
                                  pdfUrl: item?.Anotation_id?.media_id?.path,
                                  savedAnnotation: {
                                    [item?.Anotation_id?.region_id]: item
                                      ?.Anotation_id?.regions,
                                  },
                                  workerId: item?.worker_id?.id,
                                  validator_id: item?.validator_id?.id,
                                  annotation_id: item?.Anotation_id?.id,
                                });
                              });
                              setAnnotationTextDS(tempAnnotDetails);
                              setAnnotDetails(id);
                            }
                            setIsModalVisible(true);
                          }
                          break;
                        }

                        default:
                          break;
                      }
                    }} // for buttons in a row of table
                  />
                ) : (
                  <CustomEmptyDiv
                    title={`${
                      taskInfo?.taskType == "audio"
                        ? t("audio_list")
                        : t("txt_list")
                    }  ${t('wil_appear_here')}`}
                  />
                )}
              </Panel>
              {/* Audio */}
            </Collapse>
          ) // Audio
          // ) : null
          }

          {/* Modals */}

          {/* worker lists */}
          <Collapse defaultActiveKey={["1"]}>
            <Panel header={dropDownGroup[2].table.placeholder} key="1">
              {workerLists.length > 0 ? (
                <CustomTable
                  {...dropDownGroup[2].table}
                  handleClick={(label, id) => {
                    switch (label) {
                      case  "Rate" : {
                        if (id != null) {
                          setIsRatingModalVisible(true);
                          setWorkerId(id);
                        }
                        break;
                      }
                      default:
                        break;
                    }
                  }}
                />
              ) : (
                <CustomEmptyDiv title={t("wrkr_appear_here")} />
              )}
            </Panel>
          </Collapse>

          {/* Anntation Modal */}
          <AnnotationModal
            audioLists={audioLists} 
            // image class details
            classNames={classNames}
            imageArray={imageArray}
            imageIndex={imageIndex}
            annotDetails={annotDetails}
            annotationDS={annotationDS}
            // audio details
            annotationAudioDS={annotationAudioDS}
            audioIndex={audioIndex}
            // text
            classLists={classLists}
            annotationTextDS={annotationTextDS}
            // projId={projId}
            isModalVisible={isModalVisible}
            hideModal={(hide) => {
              setIsModalVisible(hide);
            }}
          />

          {/* Rating modal */}
          <Modal
            title={t('rating')}
            open={isRatingVisible}
            onOk={() => {
              setIsRatingModalVisible(false);
            }}
            onCancel={() => {
              setIsRatingModalVisible(false);
            }}
            footer={null}
            centered
            className="rating-modal"
          >
            <div className="text-center  model1" style={{ marginBottom: 40 }}> 
              <Rate
                className="stars test1"
                allowHalf
                value={rateVal}
                onChange={(val) => {
                  setRateVal(val);
                }}
              />
            </div>
            <div
              className="text-center  model1"
              style={{
                marginBottom: "10px",
              }}
            >
              <button
                type="submit"
                className="button-list top_new focus"
                onClick={() => {
                  if (rateVal) {
                    isInternetConnected("task") &&
                      dispatch(giveRate(payloadRate));
                    toast(t("rating_gvn_scsfl"), {
                      className:
                        localStorage.lang == "ar"
                          ? "Select_data ar"
                          : "Select_data en",
                      position:
                        localStorage.lang == "ar"
                          ? toast.POSITION.TOP_LEFT
                          : toast.POSITION.TOP_RIGHT,
                      toastId: "rating",
                    });
                    setIsRatingModalVisible(false);
                  } else {
                    toast.error(t("enter_rating"), {
                      className:
                        localStorage.lang == "ar"
                          ? "Select_data ar"
                          : "Select_data en",
                      position:
                        localStorage.lang == "ar"
                          ? toast.POSITION.TOP_LEFT
                          : toast.POSITION.TOP_RIGHT,
                    });
                  }
                }}
              >
                {t('done')}
              </button>
            </div>
          </Modal>

          {/* Modals */}
        </div>
      </CardHoc>
    </Container>
  );
};

export default ValidatorCompletedTaskDetails;
