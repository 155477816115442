import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function TasksPie({ total, annotated, progress }) {
    let chart;
    const { t } = useTranslation();
    useEffect(() => {
        let colors = [
            am4core.color("#123a65"),
            am4core.color("#184f8b"),
            am4core.color("#1e64b1"),
            am4core.color("#2479d7"),
            am4core.color("#2a8efd"),
            am4core.color("#2a80ff")
        ];
        chart = am4core.create("chartdiv2", am4charts.PieChart);

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "val";
        pieSeries.dataFields.category = "type";

        // Let's cut a hole in our Pie chart the size of 30% the radius
        chart.innerRadius = am4core.percent(50);

        // Put a thick white border around each Slice
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 0;
        pieSeries.slices.template
            // change the cursor on hover to make it apparent the object can be interacted with
            .cursorOverStyle = [
                {
                    "property": "cursor",
                    "value": "pointer"
                }
            ];

        pieSeries.alignLabels = false;
        pieSeries.labels.template.bent = true;
        pieSeries.labels.template.radius = 3;
        pieSeries.labels.template.padding(0, 0, 0, 0);

        pieSeries.ticks.template.disabled = true;
        pieSeries.colors.list = colors;
        // Create a base filter effect (as if it's not there) for the hover to return to
        var shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter);
        shadow.opacity = 0;

        // Create hover state
        var hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

        // Slightly shift the shadow and make it more prominent on hover
        var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter);
        hoverShadow.opacity = 0.7;
        hoverShadow.blur = 5;

        // Add a legend
        chart.legend = new am4charts.Legend();

        chart.data = [{
            "type": t("remaining"),
            "val": total
        }, {
            "type": t("completed"),
            "val": annotated
        }, {
            "type": t("in_progress"),
            "val": progress
        }];

    }, [total, annotated, progress]);
    return (
        <div id="chartdiv2" style={{ width: "100%", height: "500px" }}></div>
    );
}

TasksPie.propTypes = {
    total: PropTypes.number,
    annotated: PropTypes.number,
    progress: PropTypes.number
};


export default TasksPie;
