import { LOGOUT, USER_UPDATE_ERROR, USER_UPDATE_LOAD, USER_UPDATE_SUCCESS } from "./../../actionTypes/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    status: 0,
    updateData: {

    }
}

const updateUserDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return {
                ...state,
                isLoading: false,
                error: null,
                status: 0,
                updateData: {
            
                } 
            };
        case USER_UPDATE_LOAD:
            return { ...state, isLoading: true, error: null }
        case USER_UPDATE_SUCCESS:
            return {
                ...state,
                updateData: action.result.data,
                isLoading: false,
                status: action.status,
            }
        case USER_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state;

    }
}

export default updateUserDetailsReducer;