import {
  fieldIds,
  NavigateTo,
  SidebarMenus,
} from "../../../../themes";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { CardHoc, Container } from "../../../../hoc";
import { CustomPageHeader, TextField } from "../../../../molecules";
import { changePassword, logout } from "../../../../redux/actions/Common";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const UserChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passSuccess = useSelector(state => state?.common?.passSuccess);
  const [lang, setLang] = useState(localStorage.getItem("lang"));

  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t('dashboard'),
    },
    {
      link: NavigateTo.USER_PROFILE,
      label: t('profile'),
    },
    ,
    {
      link: NavigateTo.CHANGE_PASSWORD,
      label: t('change_password'),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("user"),
    active: t('profile')
  };
  const initialValues = {
    OldPassword: "",
    NewPassword: "",
    ConfirmNewPassword: "",
  };
  const validationSchema = Yup.object().shape({
    OldPassword: Yup.string()
      .test('trim', t('enter_old_password'), value => value?.trim()?.length > 0)
      .required(t('enter_old_password')),

    NewPassword: Yup.string()
      .test('trim', t('plz_entr_new_pass'), value => value?.trim()?.length > 0)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,20}$/,
        t("paswrd_condtn")
      )
      .required(t("plz_entr_new_pass")),

    ConfirmNewPassword: Yup.string()
      .test('trim', t('plz_enter_something', { something: t('cnfrm_new_pass') }), value => value?.trim()?.length > 0)
      .oneOf(
        [Yup.ref("NewPassword"), null],
        t("cnfrm_new_pass_mst_match_wid_new_pass")
      )
      .required(t("enter_confrm_password")),
  });
  const onSubmit = (values) => {
    const payloadForChangePass = {
      password: values?.OldPassword,
      new_password: values?.NewPassword
    };
    isInternetConnected('prf') && dispatch(changePassword(payloadForChangePass, navigate));
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });
  const passwordFormFields = [
    {
      error: formik.errors.OldPassword,
      placeholder: t("old_password"),
      inputTrue: true,
      type: "password",
      id: fieldIds.OLD_PASSWORD,
      fieldLabel: t("old_password"),
      onKeyDownTrue: true,
      value: formik.values.OldPassword.trimLeft(),
    },
    {
      error: formik.errors.NewPassword,
      placeholder: t("new_password"),
      inputTrue: true,
      type: "password",
      id: fieldIds.NEW_PASSWORD,
      fieldLabel: t("new_password"),
      onKeyDownTrue: true,
      value: formik.values.NewPassword.trimLeft(),
    },
    {
      error: formik.errors.ConfirmNewPassword,
      placeholder: t("cnfrm_new_pass"),
      inputTrue: true,
      type: "password",
      id: fieldIds.CONFIRM_NEW_PASSWORD,
      fieldLabel: t("cnfrm_new_pass"),
      onKeyDownTrue: true,
      value: formik.values.ConfirmNewPassword.trimLeft(),
    },
  ];
  useEffect(() => {
    if (passSuccess) {
      navigate(NavigateTo.LOGIN);
      dispatch(logout('i'));
    }
  }, [passSuccess])
  useEffect(() => {
    Object.keys(formik.errors).forEach(fieldName => {
      formik.setFieldTouched(fieldName)
    });
  }, [lang])
  return (
    <Container {...containerProps}
      languageChanged={() => {
        setLang(localStorage.getItem("lang"));
      }}
    >
      <CardHoc cardInnerPadding={'content-e  '}>
        <CustomPageHeader
          label={t('change_password')}
          wrapperClassName={
            "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
          }
          customWrapperStyle={{
            marginBottom: "20px",
            paddingTop: '17px'
          }}
        />
        <form noValidate onSubmit={formik.handleSubmit}>
          {passwordFormFields.map((items) => {
            return (
              <div className="wrapper_line form_wrapper reduceHorizontalPadding" key={items}>
                <TextField
                  {...items}
                  labelTitle={items.title}
                  onChange={formik.handleChange}
                  errorClassName={localStorage.getItem('lang') == 'ar' ? 'error ar' : 'error en'}
                />
              </div>
            );
          })}
          <div className="wrapper_line form_wrapper reduceHorizontalPadding extraPad">
            <button type="submit" className="button-list bottom_button pt-10 focus">
              {t('update')}
            </button>
          </div>
        </form>
      </CardHoc>
    </Container>
  );
};

export default UserChangePassword;
