import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function TasksBar({ tasks }) {
    let chart;
    const { t } = useTranslation();

    useEffect(() => {
        chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.responsive.enabled = true;
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        let colors = [
            am4core.color("#123a65"),
            am4core.color("#184f8b"),
            am4core.color("#1e64b1"),
            am4core.color("#2479d7"),
            am4core.color("#2a8efd"),
            am4core.color("#2a80ff")
        ];

        chart.data = tasks?.map((task) => {
            return {
                category: task?.name,
                value1: task?.total_annotated,
                value2: task?.document_count - task?.total_annotated,
            };
        });

        chart.colors.list = colors;
        chart.colors.step = 2;
        chart.padding(30, 30, 10, 30);
        chart.legend = new am4charts.Legend();

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = true;
        valueAxis.renderer.minWidth = 50;


        var series1 = chart.series.push(new am4charts.ColumnSeries());
        series1.columns.template.width = am4core.percent(60);
        series1.columns.template.tooltipText =
            "{name}: ({valueY}) {valueY.totalPercent.formatNumber('#.00')}%";
        series1.name = t("annotated");
        series1.dataFields.categoryX = "category";
        series1.dataFields.valueY = "value1";
        series1.dataFields.valueYShow = "totalPercent";
        series1.dataItems.template.locations.categoryX = 0.5;
        series1.stacked = true;
        series1.tooltip.pointerOrientation = "vertical";

        var bullet1 = series1.bullets.push(new am4charts.LabelBullet());
        bullet1.interactionsEnabled = false;
        bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet1.label.fill = am4core.color("#ffffff");
        bullet1.locationY = 0.5;

        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.columns.template.width = am4core.percent(60);
        series2.columns.template.tooltipText =
            "{name}: ({valueY}) {valueY.totalPercent.formatNumber('#.00')}%";
        series2.name = t("remaining");
        series2.dataFields.categoryX = "category";
        series2.dataFields.valueY = "value2";
        series2.dataFields.valueYShow = "totalPercent";
        series2.dataItems.template.locations.categoryX = 0.5;
        series2.stacked = true;
        series2.tooltip.pointerOrientation = "vertical";

        var bullet2 = series2.bullets.push(new am4charts.LabelBullet());
        bullet2.interactionsEnabled = false;
        bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet2.locationY = 0.5;
        bullet2.label.fill = am4core.color("#ffffff");

        // var series3 = chart.series.push(new am4charts.ColumnSeries());
        // series3.columns.template.width = am4core.percent(80);
        // series3.columns.template.tooltipText =
        //     "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        // series3.name = t("remaining");
        // series3.dataFields.categoryX = "category";
        // series3.dataFields.valueY = "value3";
        // series3.dataFields.valueYShow = "totalPercent";
        // series3.dataItems.template.locations.categoryX = 0.5;
        // series3.stacked = true;
        // series3.tooltip.pointerOrientation = "vertical";

        // var bullet3 = series3.bullets.push(new am4charts.LabelBullet());
        // bullet3.interactionsEnabled = false;
        // bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        // bullet3.locationY = 0.5;
        // bullet3.label.fill = am4core.color("#ffffff");
        chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
        chart.zoomOutButton.background.fill = am4core.color("#194c7f");
        chart.zoomOutButton.icon.stroke = am4core.color("#FFF");
        chart.zoomOutButton.icon.strokeWidth = 2;
        chart.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#194c7f");
        chart.scrollbarX = new am4core.Scrollbar();
    }, [tasks]);
    return (
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    );
}

TasksBar.propTypes = {
    tasks: PropTypes.array
};

export default TasksBar;