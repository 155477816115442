import React from "react";
import * as Yup from "yup";
import randomColor from "randomcolor";
import Wavesurfer from "wavesurfer.js";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import * as WaveformRegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import {
  faPlay,
  faForward,
  faPause,
  faBackward,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import {
  stopLoading,
  startLoading,
} from "../../../../../../redux/actions/Common";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
const AudioTranscript = ({ audio }) => {
  const { t } = useTranslation();

  const waveform = useRef(null);
  const dispatch = useDispatch();

  let audioUrls = [];
  audio.map((item) => {
    audioUrls.push(item?.url);
  });
  const [isPlaying, setPlay] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [allAnnotations, setAllAnnotations] = useState(
    // audio[0].jsonFile[0]
    audio[0].jsonFile
  ); //

  const [url, setUrl] = useState(audioUrls[0]);
  const playAudio = () => {
    // Check if the audio is already playing
    if (waveform.current.isPlaying()) {
      waveform.current.pause();
      setPlay(false);
    } else {
      waveform.current.play();
      setPlay(true);
    }
  };
  const frowardAudio = () => {
    waveform.current.skipForward(0.5);
  };
  const backwardAudio = () => {
    waveform.current.skipBackward(0.5);
  };
  const editAnnotation = () => {
    let form = document.forms.edit;

    form.style.display = "block";
    formik.setValues({
      ...formik.values,
      note: audio[currentIndex]?.jsonFile[0]?.data?.note, //region.data.note,
    });
  };
  const drawPrevAnnots = () => {
    allAnnotations.forEach(function (region) {
      region.color = randomColor({
        luminosity: "light",
        alpha: 0.3,
        format: "rgba",
      });
      waveform.current.addRegion(region);
    });
  };
  const saveRegions = () => {
    let tempRegions = [];
    Object.keys(waveform.current.regions.list).map(function (id) {
      let region = waveform.current.regions.list[id];
      if (region.data.note) {
        tempRegions.push({
          start: region.start,
          end: region.end,
          attributes: region.attributes,
          // data:region.data.note
          data: { note: region.data.note },
        });
      } else {
        waveform.current.regions.list[id].remove();
      }
    });
    setAllAnnotations(tempRegions);
    const saveAnnotObj = localStorage.getItem(`annotObj`)
      ? {
        ...JSON.parse(localStorage.getItem(`annotObj`)),
        [currentIndex]: tempRegions,
      }
      : {
        [currentIndex]: tempRegions,
      };
    localStorage.setItem(`annotObj`, JSON.stringify(saveAnnotObj));

    const tempAnnotObj = [
      ...JSON.parse(localStorage.getItem("annotObjForApi")),
    ];
    tempAnnotObj[currentIndex].jsonFile = [...tempRegions];
    localStorage.setItem("annotObjForApi", JSON.stringify(tempAnnotObj));
  };
  const OnNextButtonClick = () => {
    // remove the form on next click
    let form = document.forms.edit;
    form.style.display = "none";

    setCurrentIndex((prev) => {
      setUrl(audioUrls[(prev + 1) % 3]);

      // remove the regions of previous audio url's annots
      waveform.current.clearRegions();

      // set the current audio url
      waveform.current.load(audioUrls[(prev + 1) % 3]);

      // draw the the regions of current audio's url if previously drawn
      drawPrevAnnots((prev + 1) % 3);

      return (prev + 1) % 3;
    });
  };
  const OnPrevButtonClick = () => {
    // remove the form on prev click
    let form = document.forms.edit;
    form.style.display = "none";

    setCurrentIndex((prev) => {
      setUrl(audioUrls[(prev - 1) % 3]);

      // remove the regions of previous audio url's annots
      waveform.current.clearRegions();

      // set the current audio url
      waveform.current.load(audioUrls[(prev - 1) % 3]);

      // draw the the regions of current audio's url if previously drawn
      drawPrevAnnots((prev - 1) % 3);

      return (prev - 1) % 3;
    });
  };

  // FORMIK
  const validationSchema = Yup.object().shape({
    note: Yup.string().required(t("plz_add_note")),
  });
  const initialValues = {
    note: "",
  };
  const onSubmit = () => {
    const region = waveform.current.regions.list[waveform.current.regions.list.length - 1];

    let form = document.forms.edit;
    region.update({
      start: form.elements.start.value,
      end: form.elements.end.value,
      data: {
        note: form.elements.note.value,
      },
    });
    form.style.display = "none";
    saveRegions();
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  // FORMIK

  useEffect(() => {
    window.scrollTo(0, 0);



    if (!waveform.current) {
      dispatch(startLoading());

      // Create a wavesurfer object
      // More info about options here https://wavesurfer-js.org/docs/options.html
      waveform.current = Wavesurfer.create({
        container: "#waveform",
        waveColor: "#567FFF",
        barGap: 0,
        barWidth: 1,
        barRadius: 3,
        cursorWidth: 3,
        barMinHeight: 1,
        height: 100,
        responsive: true,
        cursorColor: "#567FFF",
        waveColor: "grey",
        drag: false,
        // plugins: [WaveformRegionsPlugin.create({ maxLength: 90 })],
      });
      // Load audio from a remote url.
      waveform.current.load(url);
      editAnnotation();
      waveform.current.on("ready", () => {
        dispatch(stopLoading());
      });
      // Perform action when new region is created
      waveform.current.on("region-created", (e) => {
        e.drag = false; // to disable drag of already created regions
      });
      // drawPrevAnnots();
      waveform.current.on("region-click", editAnnotation);
    }
    return () => {
      waveform.current.pause();
    }
  }, []);

  return (
    <div
      style={{
        flexDirection: "column",
        width: "95%",
        margin: 25,
        padding: 20,
        borderRadius: "10px",
        boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
        backgroundColor: "rgb(239 243 254)",
      }}
    >

      {/* prev , save, next buttons start */}
      <div
        style={{
          "display": "flex", "alignItems": "baseline", "justifyContent": "space-around", "marginInline": "190px"
        }}
      >
        {/* prev button */}
        <button
          style={{
            top: "135px",
            border: "0px",
            left: "45%",
            width: "73px",
            color: "white",
            height: "29px",
            fontWeight: 500,
            borderRadius: "10px",
            // position: "absolute",
            cursor: currentIndex > 0 ? "pointer" : "no-drop",
            background: currentIndex > 0 ? "#1c426d" : "#a6b3c1",
          }}
          title="Next"
          onClick={() => OnPrevButtonClick()}
          disabled={currentIndex > 0 ? false : true}
        >
          {t('prev')}
        </button>

        {/* next button */}
        <button
          style={{
            top: "135px",
            right: "30%",
            width: "73px",
            border: "0px",
            height: "29px",
            color: "white",
            fontWeight: 500,
            borderRadius: "10px",
            // position: "absolute",
            background:
              currentIndex < audioUrls.length - 1 ? "#1c426d" : "#a6b3c1",
            cursor: currentIndex < audioUrls.length - 1 ? "pointer" : "no-drop",
          }}
          disabled={currentIndex < audioUrls.length - 1 ? false : true}
          onClick={() => OnNextButtonClick()}
        >
          {t('next')}
        </button>
      </div>
      {/* prev , save, next buttons end */}

      <div
        style={{
          color: "#1a3e67",
          "display": "flex", "justifyContent": "center",
          top: "8.8pc",
          fontWeight: "800",
          left: "47.6pc",
        }}
      >
        {`${currentIndex + 1}//${audioUrls.length}`}
      </div>
      {/* audio tool */}
      <div
        id="waveform"
        style={{
          boxShadow: "0px 0px 14px rgb(20 60 100 / 12%) ",
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "rgb(239 243 254)",
          marginTop: 10,
          position: "relative",
        }}
      />
      {/* audio tool */}

      {/* backward,play,forward */}
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          height: "50px",
          marginLeft: "auto",
          marginRight: "auto",
          width: "25%",
          justifyContent: "space-around",
        }}
      >
        {/* forward button */}
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50px",
            background: "rgb(31 71 118)",
            border: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={backwardAudio}
        >
          <FontAwesomeIcon icon={faBackward} style={{ color: "#fff" }} />
        </div>
        {/* play pause button */}
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50px",
            background: "rgb(31 71 118)",
            border: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={playAudio}
        >
          <FontAwesomeIcon
            icon={isPlaying ? faPause : faPlay}
            style={{ color: "#fff" }}
          />
        </div>
        {/* backward button */}
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50px",
            background: "rgb(31 71 118)",
            border: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={frowardAudio}
        >
          <FontAwesomeIcon icon={faForward} style={{ color: "#fff" }} />
        </div>
      </div>
      {/* backward,play,forward */}

      {/* start,end,note form fields, save and delete buttons */}
      <form
        // role="form"
        name="edit"
        style={{
          transition: "opacity 300ms linear",
          margin: "30px 0",
          borderRadius: "10px",
          boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
          backgroundColor: "rgb(239 243 254)",
          padding: "20px",
        }}
        onSubmit={formik.handleSubmit}
        noValidate
      >


        {/*  transcription  */}
        <div className="form-group">
          <label
            htmlFor="note"
            style={{
              fontFamily: 'font-family: "Montserrat", sans-serif',
              color: "rgb(20, 60, 100)",
              fontWeight: "500",
            }}
          >
            {t('transcription')}
          </label>
          <input
            disabled
            id="note"
            className="form-control"
            value={formik.values.note}
            onChange={formik.handleChange}
            rows="3"
            name="note"
            placeholder={t("entr_wrds_u_hear")}
            style={{
              marginTop: "12px",
            }}
          ></input>
          {formik.errors ? (
            <div className="error" style={{ paddingLeft: 0 }}>
              {formik.errors.note}
            </div>
          ) : null}
        </div>
        {/*  transcription  */}
      </form>
      {/* start,end,note form field, save and delete buttons */}


    </div>
  );
};

AudioTranscript.propTypes = {
  audio: PropTypes.array,
  audioUrl: PropTypes.string,
};
export default AudioTranscript;
