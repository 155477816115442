import {EXPORT_DATA,DELETE_PROJECT_ADMIN,ADD_NEW_TASK_TO_PROJ,OWNER_DASH_TASK_LISTS,OWNER_DASH_PROJ_LISTS,GET_PROJECT_DETAILS, GET_PAST_PROJ_LISTS,GET_ONGOING_PROJ_LISTS, POST_NEW_PROJECT, SAVE_PROJ_DETAILS, GET_PROJ_DETAILS, GET_ADMIN_PROJ_LISTS, SET_ADMIN_PROJ_LISTS } from '../actionTypes/actionTypes'
 
// OWNER  START

// DASHBOARD QUICK LINKS PROJ LIST
export const getDashProjs = (payload) => {
    return {
        type: OWNER_DASH_PROJ_LISTS ,
        payload
    } 
}  
// DASHBOARD QUICK LINKS TASK LIST
export const getDashTaskLists = (payload) => {
    return {
        type: OWNER_DASH_TASK_LISTS ,
        payload
    }  
}  
 

//CREATE NEW PROJECT
export const createNewProject = (payload) => {
    return {
        type: POST_NEW_PROJECT ,
        payload
    } 
}  
export const addNewTaskToProject = (payload) => {
    return {
        type: ADD_NEW_TASK_TO_PROJ ,
        payload
    } 
}  
 
// GET ONGOING PROJECT LISTS
export const getOngoingProjectLists = (payload) => {
    return {
        type: GET_ONGOING_PROJ_LISTS ,
        payload
    } 
}   

// GET PAST PROJECT LISTS
export const getPastProjectLists = (payload) => {
    return {
        type: GET_PAST_PROJ_LISTS ,
        payload
    } 
}   
 
// SAVE ONGOING PROJECT DETAILS
export const saveOngoingProjectDetails = (payload) => {
    return {
        type: SAVE_PROJ_DETAILS ,
        payload
    } 
}   

// GET ONGOING PROJECT DETAILS
export const getOngoingProjectDetails = (payload) => {
    return {
        type: GET_PROJ_DETAILS ,
        payload
    } 
}   

// GET PROJECT DETAILS
export const getProjectDetails = (payload) => {
    return {
        type: GET_PROJECT_DETAILS ,
        payload
    } 
}   

// OWNER END

// ADMIN

// OWNER MANAGEMENT

export const getProjectListAdmin = (payload) => {
    return {
        type: GET_ADMIN_PROJ_LISTS ,
        payload
    } 
}  

// PROJ MANAGEMENT

export const setProjectListAdmin= (payload) => {
    return {
        type:  SET_ADMIN_PROJ_LISTS,
        payload
    } 
}  
export const deleteProjAdmin = (payload) => {
    console.log(payload,'pay');
    return {
        type:  DELETE_PROJECT_ADMIN,
        payload
    } 
}  

// Export Data
export const exportData = (payload) => {
 
    return {
        type:  EXPORT_DATA,
        payload
    } 
}  