import { useFormik } from "formik";
import { Layout } from "antd";
import { Breadcrumb } from "antd";
import Sidebar from "../../../layout/sidebar";
import images from "../../../themes/appImage";
import HeaderAdmin from "../../../layout/header";
import React, { useState, useEffect } from "react";
import appconstant from "../../../themes/appconstant";
import MobileSidebar from "../../../layout/mobilesidebar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavigateTo, SidebarMenus } from "../../../themes";
import { constants } from "../../../common/fileConstants/Admin/UserManagement/index";

const {  Content } = Layout;

const EditProfile = () => {
  const { t } = useTranslation();
  const [isVisible, setVisible] = useState(false);
  const lang = localStorage.lang;
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  const initialValues = {
    firstname: "Michael",
    lastName: "Smith",
    contactNum: "+91 5463789320",
    email: "micheal23@yopmail.com",
  };
  const onSubmit = () => {};
 
  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema : constants.formikValidationSchema(lang),
  });
  useEffect(() => {
    document.title = "WosoM";
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Object.keys(formik.errors).forEach(fieldName => {
      formik.setFieldTouched(fieldName)
    }); 
  }, [lang])
  return (
    <Layout>
      <Sidebar menuProps={SidebarMenus("admin")} />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={NavigateTo.ADMIN_DASH_BOARD}>{t('dashboard')}</Link>
          </Breadcrumb.Item>
            <Breadcrumb.Item>{t('edit_prfl')}</Breadcrumb.Item>
        </Breadcrumb>
        <Content style={{ margin: "24px 16px 0" }}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              <div className="content-e">
                <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                  <h6 className="text-white text-capitalize ps-3">
                    {t('edit_prfl')}
                  </h6>
                </div>
                <div className="wrapper_line">
                  {/* profile image */}
                  <div className="form-group text-center_d">
                    <img
                      src={images.profile_new}
                      style={{ cursor: "pointer" }}
                      title="Click to change image"
                    />
                  </div>
                  {/* first name */}
                  <div className="form-group">
                    <label>{appconstant.FirstName}</label>
                    <input
                      id="firstName"
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder={t("first_name")}
                      autoComplete={'off'}
                      />
                    {formik.errors.firstname ? (
                      <div className="error">{formik.errors.firstname}</div>
                    ) : null}
                  </div>
                  {/* last name */}
                  <div className="form-group">
                    <label>{appconstant.LastName}</label>
                    <input
                      id="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control"
                      placeholder={t("last_name")}
                      autoComplete={'off'}
                      />
                    {formik.errors.lastName ? (
                      <div className="error">{formik.errors.lastName}</div>
                    ) : null}
                  </div>
                  {/* contact number */}
                  <div className="form-group">
                    <label>{appconstant.ContactNumber}</label>
                    <div className="verify">
                      <input
                        id="contactNum"
                        value={formik.values.contactNum}
                        onChange={formik.handleChange}
                        type="text"
                        className="form-control"
                        placeholder={t("contact_num")}
                        autoComplete={'off'}
                        />
                      <button
                        type="submit"
                        className="button-list bottom_button focus"
                      >
                        {t('verify')}
                      </button>
                      {formik.errors.contactNum ? (
                        <div className="error">{formik.errors.contactNum}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* email address */}
                  <div className="form-group">
                    <label>{appconstant.EmailAddress}</label>
                    <div className="verify">
                      <input
                        id="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        type="text"
                        className="form-control"
                        placeholder={t("email_address")}
                        autoComplete={'off'}
                        />
                      <button
                        type="submit"
                        className="button-list bottom_button focus"
                      >
                        {t('verify')}
                      </button>
                      {formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* update button */}
                  <div className="text_button"> 
                    <button
                      type="submit"
                      className="button-list bottom_button pt-10 focus"
                    >
                      {t('update')}
                    </button> 
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Content>
      </Layout>
    </Layout>
  );
};
export default EditProfile;
