import { Modal, Pagination, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { ContainerAdmin } from "../../../hoc";
import { NavigateTo, SidebarMenus } from "../../../themes";
import { CustomEmptyDiv, CustomHelp } from "../../../molecules";
import { TextField } from "../../../molecules/Admin";
import { useTranslation } from "react-i18next";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";

import {
  deleteProjAdmin,
  getProjectListAdmin,
} from "../../../redux/actions/Project";
import { useDispatch, /* useSelector */ } from "react-redux";
import { AppImage } from "../../../themes";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Row, Col } from "antd";

const ProjectManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [taskId, setTaskId] = useState(null);

  // const projList = useSelector((state) => state?.projManagement?.projList);
  const [pageNum, setPageNum] = useState(1);
  const [ordering, setOrdering] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [total, setTotalProjects] = useState(0);
  const [textFieldlabels, setTextFieldlabels] = useState([]);
  const [taskFieldlabels, setTaskFieldlabels] = useState([]);
  const [taskFieldlabels2, setTaskFieldlabels2] = useState([]);
  const [taskUsers, setTaskUsers] = useState([]); // [{}
  // const [ownerDetailsFormFields, setOwnerDetailsFormFields] = useState([]); // [{}]
  const [taskListContents, setTaskListContents] = useState([]);
  const [dataSource, setAddedProjects] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); // delete modal
  const [isModalVisibleView, setIsModalVisibleView] = useState(false); // view modal
  function thousands_separators(num) {
    if (num === 0 || num == NaN) return 0;
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return num_parts.join(".");
  }
  const taskListHeaders = [
    {
      title: t("task_name"),
      dataIndex: "name",
      key: "name",
      width: 100,
    },
    {
      title: t("task_type"),
      dataIndex: "type",
      key: "task_type",
      render: (val, record) => {
        let date = new Date(record?.created_at).toLocaleDateString(localStorage.getItem("lang"), {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return (
          <CustomHelp
            popover={
              <>
                <p><b>{t("validation_system")}</b>: {record?.validation_system}</p>
                <p><b>{t("task_created")}</b>: {date}</p>
              </>
            }
            popoverTitle={t("task_type")}
            popoverIcon={AppImage.info}
            title={val}
          />
        )
      }
    },
    {
      title: t("document_count"),
      dataIndex: "document_count",
      key: "document_count",
      render: (val) => {
        return thousands_separators(val)
      }
    },
    {
      title: t("annotator_count"),
      dataIndex: "annotator_count",
      key: "annotator_count",
    },
    {
      title: t("validator_count"),
      dataIndex: "validator_count",
      key: "validator_count",
    },
    {
      title: t("total_annotated"),
      dataIndex: "total_annotated",
      key: "total_annotated",
      render: (val) => {
        return thousands_separators(val)
      }
    },
    {
      title: t("total_validated"),
      dataIndex: "total_validated",
      key: "total_validated",
    },
    {
      title: t("action"),
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (_val, record) => {
        return (
          <div className="tex-center_"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CustomHelp
              triggerHandler={
                <button
                  type="submit"
                  className="focus"
                  onClick={() => {
                    setTaskFieldlabels([
                      {
                        labelTitle: record?.name,
                        labelHeader: t("task_name"),
                      },
                      {
                        labelTitle: record?.type,
                        labelHeader: t("task_type"),
                      },
                      {
                        labelTitle: record?.validation_system,
                        labelHeader: t("validation_system"),
                      },
                      {
                        labelTitle: record?.invite_list ? record?.invite_list?.length : 0,
                        labelHeader: t("invite_list"),
                      },
                      {
                        labelTitle: new Date(record?.created_at).toLocaleDateString(localStorage.getItem("lang"), {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }),
                        labelHeader: t("task_created"),
                      }
                    ]);
                    setTaskFieldlabels2([
                      {
                        labelTitle: thousands_separators(record?.document_count),
                        labelHeader: t("document_count"),
                      },
                      {
                        labelTitle: record?.annotator_count,
                        labelHeader: t("annotator_count"),
                      },
                      {
                        labelTitle: record?.validator_count,
                        labelHeader: t("validator_count"),
                      },
                      {
                        labelTitle: thousands_separators(record?.total_annotated),
                        labelHeader: t("total_annotated"),
                      },
                      {
                        labelTitle: thousands_separators(record?.total_validated),
                        labelHeader: t("total_validated"),
                      },
                    ]);
                    setTaskUsers(record?.annotators.sort((a, b) => a?.annotated - b?.annotated));
                  }}
                >
                  <EyeOutlined />  {t("view")}
                </button>
              }
              title={t("task_details")}
              modal={
                <div>
                  <Row style={{
                    display: "flex",
                    justifyContent: "space-between",

                  }}>
                    <Col span={10} className="card margin-m">
                      {taskFieldlabels.map((item) => {
                        const LabelStyle = localStorage.getItem("lang") == "ar" ?
                          rightFontStyle : leftFontstyle;
                        const HeaderStyle = localStorage.getItem("lang") == "ar" ?
                          leftBoldFontstyle : rightBoldFontStyle;
                        return (
                          <>
                            <TextField
                              wrapperClassName={"admin-projdetails admin-user-details"}
                              customLabelStyle={LabelStyle}
                              customHeaderStyle={HeaderStyle}
                              {...item}
                            />
                          </>
                        );
                      })}
                    </Col>
                    <Col span={10} className="card margin-m">
                      {taskFieldlabels2.map((item) => {
                        const LabelStyle = localStorage.getItem("lang") == "ar" ?
                          rightFontStyle : leftFontstyle;
                        const HeaderStyle = localStorage.getItem("lang") == "ar" ?
                          leftBoldFontstyle : rightBoldFontStyle;
                        return (
                          <>
                            <TextField
                              wrapperClassName={"admin-projdetails admin-user-details"}
                              customLabelStyle={LabelStyle}
                              customHeaderStyle={HeaderStyle}
                              {...item}
                            />
                          </>
                        );
                      })}
                    </Col>
                  </Row>

                  <div
                    style={{
                      width: "100%",
                      minHeight: "400px",
                      padding: "1%",
                    }}
                  >
                    <div style={{
                      fontSize: "1.2rem",
                      color: "#214c7d",
                      marginBottom: "10px",
                    }}>
                      {t("users")}
                    </div>
                    <Table
                      pagination={true}
                      dataSource={taskUsers}
                      columns={userListHeaders}
                      className="admin-project-management-table table-sm"
                    > </Table>
                  </div>
                </div>
              }
            />
            <Dropdown
              trigger={"click"} menu={{ items: userMenuItems }} placement="bottomLeft" arrow={{ pointAtCenter: true }} >
              <button
                className="overflow-menu"
                onClick={() => { }}
              >
                <MoreOutlined style={{
                  color: "#193d64",
                  fontSize: "20px",
                  fontWeight: "bold",
                }} />
              </button>
            </Dropdown>
          </div>
        );
      },
    }
  ];
  const userListHeaders = [
    {
      title: t("name"),
      dataIndex: "full_name",
      key: "full_name",
      width: 100,
      render: (val, record) => {
        return (
          <Tooltip title={record?.email} placement="topLeft">
            {val}
          </Tooltip>
        )
      }
    },
    {
      title: t("assigned"),
      dataIndex: "assigned",
      key: "assigned",
      width: 100,
    },
    {
      title: t("annotated"),
      dataIndex: "annotated",
      key: "annotated",
      width: 100,
    },
    {
      title: t("quotas"),
      dataIndex: "quotas",
      key: "quotas",
      width: 100,
      render: (val) => { return val.length }
    },
  ]
  const userMenuItems = [
    // delete button
    {
      key: '2',
      label: (
        <button
          type="submit"
          className="btn focus"
          // onClick={() => {
          //   setIsModalVisible(true);
          // }}
          style={{
            cursor: "not-allowed",
          }}
          disabled
        >
          {t("delete")}
        </button>
      ),
    },
  ];
  const breadCrumbItems = [
    {
      link: NavigateTo.ADMIN_DASH_BOARD,
      label: t("dashboard"),
    },
    {
      link: "",
      label: t("prj_mngmnt"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("admin"),
    active: t('prj_mngmnt'),
  };
  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
    whiteSpace: "nowrap",
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: "break-word",
  };
  const leftBoldFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 600,
    whiteSpace: "nowrap",
  };
  const rightBoldFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 600,
    wordBreak: "break-word",
  };
  const columns = [
    {
      title: t("proj_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (val) => {
        return (
          <div
            style={{
              paddingLeft: "10px",
            }}
          >
            <Tooltip title={val} placement="topLeft">
              {val}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: t("owner_name"),
      dataIndex: "owner",
      key: "onwer",
      sorter: true,
      render: (val, record) => {
        return (
          <Tooltip title={record?.owner_email} placement="topLeft">
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("task_types"),
      dataIndex: "tasks",
      key: "tasks",
      sorter: true,
      render: (val) => {
        let task_types = ['bbox', 'polygon', 'transcription', 'segmentation', 'classification'];
        let tasks = [];
        task_types.map((item) => {
          tasks.push({
            task_type: item,
            count: val?.filter((task) => task?.task_type == item).length
          })
        })
        let task_types_value = tasks?.filter((item) => item?.count > 0).map((item) => t(item?.task_type));
        return (
          <>
            <CustomHelp
              popover={
                <span style={{ display: "block" }}>
                  {tasks?.map((item, index) =>
                    <p key={index}>
                      {item?.task_type}: <b>{item?.count}</b>
                    </p>)}
                </span>
              }
              popoverTitle={t("task_types")}
              popoverIcon={AppImage.info}
              title={`${task_types_value.length > 0 ? task_types_value.join(", ") : t("empty")}`}>
            </CustomHelp>
          </>
        );
      }
    },
    {
      title: t("proj_status"),
      dataIndex: "ProjectStatus",
      key: "ProjectStatus",
      sorter: true,
      render: (item) => {
        return (<>
          <img
            style={{
              marginRight: "12px",
            }}
            src={
              item == "Completed"
                ? AppImage.completedTask
                : AppImage.pendingTask
            }
          />
          {t(item)}
        </>
        );
      }
    },
    {
      title: t("action"),
      dataIndex: "Posted",
      key: "Posted",
      width: "10%",
      render: (val, record) => {
        return (
          <div className="tex-center_">
            <button
              type="submit"
              className="focus"
              onClick={() => {
                setTextFieldlabels([
                  {
                    labelTitle: record?.name,
                    labelHeader: t("project_name"),
                  },
                  {
                    labelTitle: record?.owner,
                    labelHeader: t("owner_name"),
                  },
                  {
                    labelTitle: record?.owner_email,
                    labelHeader: t("owner_email"),
                  },
                  {
                    labelTitle: <>
                      <img
                        style={{
                          marginRight: "12px",
                        }}
                        src={
                          record?.ProjectStatus == "Completed"
                            ? AppImage.completedTask
                            : AppImage.pendingTask
                        }
                      />
                      {t(record?.ProjectStatus)}
                    </>,
                    labelHeader: t("proj_status"),
                  },
                ]);
                setTaskListContents(record?.tasks?.map((item) => {
                  return {
                    name: item?.name,
                    description: item?.description,
                    type: t(item?.task_type),
                    validation_system: t(item?.validation_system),
                    documents: item?.documents,
                    invite_list: item?.invite_list,
                    created_at: item?.created_at,
                    document_count: item?.document_count,
                    annotator_count: item?.annotator_count,
                    validator_count: item?.validator_count,
                    total_annotated: item?.total_annotated,
                    total_validated: item?.total_validated,
                    annotators: item?.annotators,
                    validators: item?.validators,
                  }
                }));
                setIsModalVisibleView(true);
              }}
            >
              <EyeOutlined />  {t("view")}
            </button>
            <Dropdown
              trigger={"click"} menu={{ items: userMenuItems }} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
              <button
                className="overflow-menu"
                onClick={() => { }}
              >
                <MoreOutlined style={{
                  color: "#193d64",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                /></button>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  const payload = {
    body: {
      pageNum: pageNum,
      searchValue: searchValue,
      ordering: ordering,
    },
    success: (res) => {
      setAddedProjects(res);
      setTotalProjects(res.count);
    },
  };
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    isInternetConnected() && dispatch(getProjectListAdmin(payload));
  }, []);

  return (
    <ContainerAdmin
      {...containerProps}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      {/* button and search bar */}
      <div className="top_button_item d-flex justify-content-between">
        <div className="button_left contain">
          <button
            type="submit"
            style={{ visibility: "hidden" }}
            className="button-list top_new focus"
          >
            {" "}
          </button>
          <input
            type="text"
            className="search-bar"
            placeholder={t("search")}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setPageNum(1);
            }}
          />
        </div>
      </div>
      {/* button and search bar */}

      {/* project management table */}

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360, paddingTop: "0px" }}
      >
        <div className="content-e owner_content">
          {/* table header */}
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {t("prj_mngmnt")}
            </h6>
          </div>
          {dataSource.length ? (
            <>
              <Table
                className="admin-project-management-table"
                dataSource={dataSource}
                columns={columns}
                showSorterTooltip={false}
                pagination={false}
                onChange={(pagination, filters, sorter) => {
                  if (sorter.order == undefined) {
                    setOrdering("");
                  }
                  if (
                    sorter.order == "ascend" &&
                    sorter.order != undefined &&
                    sorter.columnKey == "Project"
                  ) {
                    setOrdering("name");
                  }
                  if (
                    sorter.order == "descend" &&
                    sorter.order != undefined &&
                    sorter.columnKey == "Project"
                  ) {
                    setOrdering("-name");
                  }
                  if (
                    sorter.order == "ascend" &&
                    sorter.order != undefined &&
                    sorter.columnKey == "Owner"
                  ) {
                    setOrdering("start_date");
                  }
                  if (
                    sorter.order == "descend" &&
                    sorter.order != undefined &&
                    sorter.columnKey == "Owner"
                  ) {
                    setOrdering("-start_date");
                  }
                  if (
                    sorter.order == "ascend" &&
                    sorter.order != undefined &&
                    sorter.columnKey == "tasks"
                  ) {
                    setOrdering("start_date");
                  }
                  if (
                    sorter.order == "descend" &&
                    sorter.order != undefined &&
                    sorter.columnKey == "tasks"
                  ) {
                    setOrdering("-start_date");
                  }
                  if (
                    sorter.order == "ascend" &&
                    sorter.order != undefined &&
                    sorter.columnKey == "ProjectStatus"
                  ) {
                    setOrdering("-start_date");
                  }
                  if (
                    sorter.order == "descend" &&
                    sorter.order != undefined &&
                    sorter.columnKey == "ProjectStatus"
                  ) {
                    setOrdering("start_date");
                  }
                }}
              />
              <Pagination
                showTitle={false}
                style={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  maxWidth: "fit-content",
                  right: "33px",
                  left: "auto",
                  position: "absolute",
                }}
                className="pagination-component"
                defaultCurrent={1}
                total={total}
                size="medium"
                onChange={(item) => {
                  setPageNum(item);
                }}
              />
            </>
          ) : (
            <CustomEmptyDiv
              marginLeft={"16px"}
              marginRight={"16px"}
              title={t('prj_appear_here')}
            />
          )}
        </div>
      </div>

      {/* project management table */}

      {/* delete project modal */}
      <Modal
        title={t("delete")}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className="success_data delete"
        centered
      >
        <div className="text-center  model1">
          <p>{t("u_sure_to_dlt_this_prj")}</p>
        </div>
        <div
          className="text-center  model1"
          style={{
            direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
          }}
        >
          <button
            type="submit"
            className="button-list top_new focus"
            onClick={() => {
              const payloadDelete = {
                // id: projId,
              };
              isInternetConnected() && dispatch(deleteProjAdmin(payloadDelete));
              isInternetConnected() && dispatch(getProjectListAdmin(payload));
              setIsModalVisible(false);
            }}
          >
            {t("yes")}
          </button>
          <button
            type="submit"
            className="focus"
            onClick={() => setIsModalVisible(false)}
          >
            {t("no")}
          </button>
        </div>
      </Modal>
      {/* view modal */}
      <Modal
        title={t("view_project")}
        open={isModalVisibleView}
        onOk={() => setIsModalVisibleView(false)}
        onCancel={() => setIsModalVisibleView(false)}
        footer={null}
        className="success_data delete"
        centered
        width={"90%"}
      >
        <div>
          {textFieldlabels.map((item) => {
            const LabelStyle = localStorage.getItem("lang") == "ar" ?
              rightFontStyle : leftFontstyle;
            const HeaderStyle = localStorage.getItem("lang") == "ar" ?
              leftBoldFontstyle : rightBoldFontStyle;
            return (
              <>
                <TextField
                  wrapperClassName={"admin-projdetails admin-user-details"}
                  customLabelStyle={LabelStyle}
                  customHeaderStyle={HeaderStyle}
                  {...item}
                />
              </>
            );
          })}

          {/* dropdowns */}
          {
            taskListContents.length > 0 ?
              <div className="content-e owner_content">
                {/* table header */}
                <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                  <h6 className="text-white text-capitalize ps-3">
                    {t("task_list")}
                  </h6>
                </div>
                <Table
                  className="admin-project-management-table"
                  dataSource={taskListContents}
                  columns={taskListHeaders}
                  pagination={false}
                />
                <Pagination
                  showTitle={false}
                  style={{
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    maxWidth: "fit-content",
                    right: "auto",
                    left: "85%",
                    position: "relative",
                  }}
                  className="pagination-component"
                  defaultCurrent={1}
                  total={taskListContents.length}
                  size="medium"
                />
              </div>
              :
              <CustomEmptyDiv title={t("tasks_appear_here")} />
          }


          {/* delete project modal */}
          <Modal
            title={t('delete')}
            open={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            // className="success_data delete"
            className={localStorage.lang == "ar" ? "new_layout ar success_data delete" : "en success_data delete"}
            centered
          >
            <div className="text-center  model1">
              <p>{t('u_sure_to_dlt_this_task')}</p>
            </div>
            <div className="text-center  model1">
              <button
                type="submit"
                className="button-list top_new focus"
                onClick={() => {
                  const payloadDelete = {
                    // id: taskId
                  }
                  isInternetConnected() && dispatch(deleteAdminTask(payloadDelete));
                  isInternetConnected() && dispatch(getProjectDetails(payload)); // implement it if api will be hit

                  setIsModalVisible(false);
                }}
              >
                {t('yes')}
              </button>
              <button type="submit" className="focus" onClick={() => setIsModalVisible(false)}>
                {t('no')}
              </button>
            </div>
          </Modal>
        </div>
      </Modal>
    </ContainerAdmin>
  );
};

export default ProjectManagement;
