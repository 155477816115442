import Fetch from "../Fetch/TasksApi";
import { put, call, delay } from "redux-saga/effects";
import {
  startLoading,
  stopLoading,
  taskstartLoading,
  taskstopLoading,
} from "../actions/Common";
import { saveClassNames, saveImageArray, saveItr } from "../actions/Tasks";
import { customFunctions } from "../../themes/utils/CustomFunctions";
import appconstant from "../../themes/appconstant";

export function* getTasksTypeApi({
  payload: { success = () => { }, fail = () => { } } = {},
}) {
  try {
    const result = yield call(Fetch.taskTypesApi);
    yield put(startLoading());
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getDocument({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    const result = yield call(Fetch.documentApi, body);
    yield put(startLoading());
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getDocumentDetails({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    const result = yield call(Fetch.documentDetailsApi, body);
    yield put(startLoading());
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
// owner create new project task creation

export function* createTaskSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.createTaskApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    console.log("catchh", error);
    yield put(stopLoading());
    // fail(error);
  }
}
export function* removeTaskUserSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.taskUserRemoveApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  }
  catch (error) {
    console.log("catchh", error);
    yield put(stopLoading());
    // fail(error);
  }
}
export function* removeTaskUserInviteSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.taskUserInviteRemoveApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  }
  catch (error) {
    console.log("catchh", error);
    yield put(stopLoading());
    // fail(error);
  }
}
export function* inviteToTaskSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.inviteToTaskApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    console.log("catchh", error);
    yield put(stopLoading());
    // fail(error);
  }
}
export function* taskInvitesSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getTaskInvitesApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    console.log("catchh", error);
    yield put(stopLoading());
    // fail(error);
  }
}

export function* taskPreviewSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.taskPreviewApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    console.log("catchh", error);
    yield put(stopLoading());
    // fail(error);
  }
}
export function* taskDetailsSaga({
  payload: { body, success, fail = () => { } } = {},
}) {
  try {
    yield put(taskstartLoading());
    yield put(
      saveImageArray(JSON.parse(localStorage.getItem("imageArray")) || [])
    );
    yield put(saveClassNames([]));
    yield put(saveItr(""));
    const result = yield call(Fetch.taskDetailsApi, body);

    if (result.status === 1) {
      const res = result?.result;
      if (!success) {
        if (res?.data?.task_type == 1) {
          let tempImageArray = [];
          let classNames = [];
          res?.data?.classes.map((item) => {
            classNames.push(item?.name);
          });
          res?.data?.media.map((items, index) => {
            let tempRegion = [];
            items?.annotation.map((item) => {
              let regionObj =
                localStorage.getItem("workerPendingTaskId") ||
                  localStorage.getItem("workerCompletedTaskId")
                  ? item?.regions
                  : item?.Anotation_id?.regions;

              const tempAnnotStatus = item?.status;

              regionObj["status"] =
                tempAnnotStatus == 3
                  ? "Rejected"
                  : tempAnnotStatus == 2
                    ? "Approved"
                    : tempAnnotStatus == 4
                      ? "Disputed"
                      : "Pending";
              if (
                localStorage.getItem("validatorPendingTaskId")
                // localStorage.getItem("workerCompletedTaskId") || localStorage.getItem("ongoingProjectTaskId") || localStorage.getItem('projectTaskId')
              ) {
                if (regionObj["status"] == "Pending") {
                  regionObj["show"] = true;
                } else {
                  regionObj["show"] = false;
                }
              } else {
                regionObj["show"] = false;
              }

              // aceeptRejectBody prop
              if (localStorage.getItem("validatorPendingTaskId")) {
                const body = {
                  id: item?.id, //simple id

                  project_id: res?.data?.project_id,
                  comment: null, // blank for accept case

                  task_id: res?.data?.id,
                  worker_id: item?.worker_id?.id, //
                  validator_id: item?.validator_id.id, //
                  annotation_id: item.Anotation_id?.id, //
                };
                regionObj["aceeptRejectBody"] = body;
              }
              tempRegion.push(regionObj);
            });
            tempImageArray.push({
              name: `Image-${index + 1}`,
              src: appconstant.imageBaseUrl + items?.path,
              regions: items?.annotation.length != 0 ? tempRegion : [],
              id: items?.id,
            }); // tempImageArray
          });
          yield put(saveImageArray(tempImageArray));
          yield put(saveClassNames(classNames));
          localStorage.setItem("imageArray", JSON.stringify(tempImageArray));
          if (localStorage.getItem("imageIndex")) {
            yield put(
              saveItr(tempImageArray[localStorage.getItem("imageIndex")].src)
            );
          }

          //  else {
          //   yield put(saveItr(tempImageArray[imageIndex].src));
          // }
        } // image
        // }
      } else success(result?.result);
      yield put(taskstopLoading());
    } else {
      yield put(taskstopLoading());
      fail(result?.result);
    }
  } catch (error) {
    console.log("catchh", error);
    yield put(taskstopLoading());
    fail(error);
  }
}
export function* getAddedTaskListSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getAddedTaskListApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* deleteAddedTaskSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.deleteAddedTaskApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
// owner create new project task creation

// Owner Dashborad/Projects/Ongoing Project Details/Task Details   start
export function* getValidtaorsListOwnerOngoingProjSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(
      Fetch.getValidtaorsListOwnerOngoingProjSagaApi,
      body
    );
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getWorkerListOwnerOngoingProjSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getWorkerListOwnerOngoingProjApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getUserInvitaionListSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getUserInvitaionListApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* removeUserSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.taskUserRemoveApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* taskQuotaSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.taskQuotaApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* replaceUserSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.taskUserReplaceApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* addValidatorForTaskSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.addValidatorForTaskApi, body);
    if (result.status === 1) {
      success(result?.result);

      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* addWorkerForTaskSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.addWorkerForTaskApi, body);
    if (result.status === 1) {
      success(result?.result);

      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}

// Owner Dashborad/Projects/Ongoing Project Details/Task Details   end

// user task management
export function* getWorkerPendingTaskSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getWorkerPendingTaskApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getWorkerCompletedTaskSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield delay(500);
    yield put(startLoading());
    const result = yield call(Fetch.getWorkerCompletedTaskApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getValidatorPendingTaskSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield delay(500);
    yield put(startLoading());
    const result = yield call(Fetch.getValidatorPendingTaskApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getValidatorCompletedTaskSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield delay(500);
    yield put(startLoading());
    const result = yield call(Fetch.getValidatorCompletedTaskApi, body);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
// user task management

// proj management

export function* deleteTaskAdminSaga({ payload }) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.deleteTaskAdminApi, payload);
    if (result.status === 1) {
      customFunctions.showToast(
        result?.result?.data,
        true,
        "projdeletesuccess"
      );

      yield put(stopLoading());
    } else {
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
  }
}
export function* taskListingExportSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield delay(500);
    yield put(startLoading());
    const result = yield call(Fetch.taskListingExportApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
