import {
  AppImage,
  fieldIds,
  NavigateTo,
  SidebarMenus, 
} from "../../../../themes"; 
import { Tooltip } from "antd";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "../../../../molecules/Textfield";
import { CardAdmin, ContainerAdmin } from "../../../../hoc";
import { createOwner } from "../../../../redux/actions/Owner";
import { customFunctions } from "../../../../themes/utils/CustomFunctions";
import { isInternetConnected } from "../../../../themes/utils/internetConnectionn";
import { constants } from "../../../../common/fileConstants/Admin/OwnerManagement/index";

const AddOwnerDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstnameRef = useRef(null); 

  const [uploadedImage, setImage] = useState(null);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [background, setBackground] = useState(AppImage.registerProfile);

  // bread crumbs
  const breadCrumbItems = [
    {
      label: t("dashboard"),
      link: NavigateTo.ADMIN_DASH_BOARD,
    },
    {
      label: t("owner_mngmnt"),
      link: NavigateTo.ADMIN_OWNER_MANAGEMENT,
    },
    {
      label: t("add_new_owner"),
      link: "",
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("admin"),
    active: t('owner_mngmnt'),
  };
 
  // formik
  const onSubmit = (values) => {
    const payloadCreateOwner = {
      email: (values?.email).toLowerCase(),
      phone_no:  values?.contactNum,
      country_code:  values.countryCode ,
      phone:  values.contactNum  ,
      first_name: values?.ownerFirstName,
      last_name: values?.ownerLastName,
      full_name: values?.ownerFirstName + ' ' +values?.ownerLastName,
      image: uploadedImage,
    };
    isInternetConnected() &&
      dispatch(createOwner(payloadCreateOwner, navigate));
  };
  const initialValues = {
    ownerFirstName: "",
    ownerLastName: "",
    email: "",
    contactNum: "",
    countryCode: "+",
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: constants.formikValidationSchema(localStorage.lang),
  });
  // formik

  // formfields

  const ownerDetailsFormFields = [
    {
      type: "text",
      error: formik.errors.ownerFirstName,
      inputTrue: true,
      id: fieldIds.OWNER_FIRST_NAME,
      value: customFunctions.capitalizeFirstLetter(
        formik.values.ownerFirstName.trimLeft()
      ), // configure later
      placeholder: t('first_name'),
      fieldLabel: t('first_name'),
      inputMaxLength: 20,
      fieldRef: firstnameRef,
    },
    {
      type: "text",
      error: formik.errors.ownerLastName,
      inputTrue: true,
      id: fieldIds.OWNER_LAST_NAME,
      value: customFunctions.capitalizeFirstLetter(
        formik.values.ownerLastName.trimLeft()
      ), // configure later
      placeholder: t('last_name'),
      fieldLabel: t('last_name'),
      inputMaxLength: 20,
    },
    {
      type: "text",
      error: formik.errors.email,
      inputTrue: true,
      id: fieldIds.EMAIL,
      value: formik.values.email.trimLeft(), // configure later
      placeholder: t("email_address"),
      fieldLabel: t("email_address"),
      inputMaxLength: 320,
    }, 
  ];
  // formfields

  // functions
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      setImage(img);
      const fileType = img.type.split("/")[0];
      const fileSize = img.size; //size is in bytes

      /*
       check here if FileType is other than IMAGE then show alert and 
       if FileSize is greater than 2MB then show alert.
      */
      fileType == "image"
        ? customFunctions.FileSizeConverter(fileSize, "MB") > 1.9
          ? toast.error(`${t('size_less_than_2Mb')}`, {
            className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
            toastId: "wrongSize",
            })
          : setBackground(URL.createObjectURL(img))
        : toast.error(t('upload_file_types'), { 
          className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
          toastId: "wrongFile",
          });
    }
  };
  const handleOnChange = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("contactNum", e.target.value);
      }
    } else {
      formik.setFieldValue("contactNum", "");
    }
  };
  const handleOnChangeCountryCode = (formik, e) => {
    if (e.target.value) {
      var reg = new RegExp(/^\d*[\.{1}+\d*]\d*$/);

      const temp = e.target.value;
      if (reg.test(temp)) {
        formik.setFieldValue("countryCode", temp);
      }
    } else {
      formik.setFieldValue("countryCode", "");
    }
  };
  useEffect(() => {
    Object.keys(formik.errors).forEach((fieldName) => {
      formik.setFieldTouched(fieldName);
    }); 
  }, [lang])
  
   
  return (
    <ContainerAdmin
      {...containerProps}
      languageChanged={() => {
        setLang(localStorage.getItem("lang"));
      }}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <CardAdmin
        cardInnerPadding={
          localStorage.getItem("lang") == "ar" ? "content-e ar" : "content-e en"
        }
      >
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
          <h6 className="text-white text-capitalize ps-3">
            {t("add_new_owner")}
          </h6>
        </div>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tooltip  
              title={
                uploadedImage == null
                  ? t('upload_your_photo')
                  : t("change_your_photo")
              }
              key={"#143C64"}
              placement={ 'right'}
            >
              <input
                id={"register-upload"}
                accept={".png,.jpg,.jpeg,.svg"}
                style={{
                  display: "none",
                }}
                type="file"
                name="uploadImage"
                onChange={(event) => {
                  handleImageChange(event);
                }}
                onClick={(event) => {
                  event.target.value = null; // use this to validate image upon uploading wrong image many times
                }}
              />
              <label
                htmlFor="register-upload"
                style={{
                  position: "relative",
                }}
              >
                <img
                  style={{
                    marginTop: "-15px",
                    marginBottom: "15px",
                    cursor: "pointer",
                    width: "130px",
                    height:
                      background != AppImage.registerProfile ? "130px" : null,
                    borderRadius:
                      background != AppImage.registerProfile ? "130px" : null,
                  }}
                  width="20%"
                  id="base-image"
                  src={background}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "3px",
                    bottom: "-33px",
                    borderRadius: 38,
                    cursor: "pointer",
                  }}
                >
                  <img src={AppImage.plusIcon} width={38} height={38} />
                </div>
              </label>
            </Tooltip>
          </div>
          {ownerDetailsFormFields.map((items, index) => {
            return (
              <div
                key={index}
                className="wrapper_line"
                style={{ padding: "0px 15px 5px" }}
              >
                <TextField
                  {...items}
                  onChange={formik.handleChange}
                  labelTitle={items.fieldLabel}
                  errorClassName={
                    localStorage.getItem("lang") == "ar"
                      ? "error ar"
                      : "error en"
                  }
                />
              </div>
            );
          })}

           {/* number */}
          <div className="wrapper_line" style={{ marginBottom: "-23px" }}>
            <label>{t("contact_num")}</label>
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              marginLeft: localStorage.lang == 'ar' ? "unset" : '20px',
              marginRight : localStorage.lang == 'ar' ? '16px' : "unset",
              marginBottom: "0px",
            }}
          >
            <input
              id="countryCode"
              type="text"
              className="form-control"
              placeholder={"+"}
              name="countryCode"
              maxLength={5}
              style={{
                width: "91px",
                borderEndEndRadius: "9px",
                borderStartEndRadius: "9px",
                marginLeft :  localStorage.lang == 'ar' ? "10px" : 'unset',
              }}
              onKeyDown={(e) => {
                if (
                  e.key === "." ||
                  e.key === "{" ||
                  e.key === "}" ||
                  e.key === "*"
                ) {
                  e.preventDefault();
                }
              }}
              autoComplete="off"
              value={formik.values.countryCode}
              onChange={(e) => handleOnChangeCountryCode(formik, e)}
            />
            <input
              id="contactNum"
              style={{
                width: localStorage.lang == 'ar' ? '90%' : "88%",
                marginLeft: "16px",
              
                borderStartStartRadius: "9px",
                borderEndStartRadius: "9px",
              }}
              type="text"
              className="form-control"
              placeholder={t('contact_num')}
              name="contactNum"
              maxLength={15}
              onKeyDown={(e) => {
                if (
                  e.key === "." ||
                  e.key === "{" ||
                  e.key === "}" ||
                  e.key === "*"
                ) {
                  e.preventDefault();
                }
              }}
              autoComplete="off"
              value={formik.values.contactNum}
              onChange={(e) => handleOnChange(formik, e)}
            />
          </div> 
          {/* error */}
          <div
            style={{
              marginLeft: "15px",
            }}
          >
            {formik.errors.countryCode ? (
              <div>
                {formik.errors.countryCode ? (
                  <div  
                    className="error" 
                    style={{
                      display: localStorage.lang == 'ar' ? 'flex' : 'unset',
                      marginRight:  localStorage.lang == 'ar' ? '10px' : 'unset',
                    }}
                  >{formik.errors.countryCode}</div>
                ) : null}
              </div>
            ) : (
              <div>
                {formik.errors.contactNum ? (
                  <div className="error"
                  style={{
                    display: localStorage.lang == 'ar' ? 'flex' : 'unset',
                    marginRight:  localStorage.lang == 'ar' ? '10px' : 'unset',
                  }}
                  >{formik.errors.contactNum}</div>
                ) : null}
              </div>
            )}
          </div>
          {/* number */}

          {
            <div className="wrapper_line text_button">
               <button
                type="submit"
                className="button-list bottom_button pt-10 focus"
                style={{
                  marginRight : localStorage.lang == 'ar' ? 'unset' : '17px'
                }}
              >
                {t("add")}
              </button>
             </div>
          }
        </form>
      </CardAdmin>
    </ContainerAdmin>
  );
};

export default AddOwnerDetails;
