import React, { useState, useEffect } from "react";
import { Modal, Select } from "antd";
import images from "../../../themes/appImage";
import { useNavigate, useParams } from "react-router";
import appconstant from "../../../themes/appconstant";
import { Collapse } from "antd";
import {
  AppImage,
  SidebarMenus,
  NavigateTo,
} from "../../../themes/index";
import { Container } from "../../../hoc";
import { useTranslation } from "react-i18next";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import {
  acceptRejectDisputedAnnotations,
  disputeDetails,
} from "../../../redux/actions/DisputedAnnots";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { report } from "../../../redux/actions/Report";

const { Panel } = Collapse;

const DisputeAnnotationDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { disputeId } = useParams();
  const others = JSON.parse(localStorage.getItem("others"));
  const [projectId, setProjectId] = useState("");
  // const [pdfUrl, setpdfUrl] = useState("");
  // const [qnindex, setqnindex] = useState("");
  const [disputeAcceptedOrRejected, setRisputeAcceptedOrRejected] = useState(
    null
  );

  const [rateTo, setRateTo] = useState(null);
  const [toUserType, setToUserType] = useState(null);
  const [taskIdForReport, setTaskIdForReport] = useState(null);
  const [task_Id, setTaskId] = useState(null);
  // const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [annotId, setAnnotId] = useState(null);
  const [disputedId, setDisputeId] = useState(null);
  const [disputedAnnotInfo, setDisputedAnnotInfo] = useState({
    fileImg: images.file_n,
    taskType: "Image",
    className: "Bridge",
    taskDescription: "Lorem ipsuum.",
  });
  const [validatorDetails, setValDetails] = useState({
    id: 1,
    img: images.file_n,
    name: "dummy",
    email: "asd@wd.com",
    number: "23132323233",
    comments: [{ comment: "efefef" }, { comment: "fewfef" }],
  });
  const [workerDetails, setWorkerDetails] = useState({
    id: 1,
    img: images.file_n,
    name: "dummy",
  });

  const { t } = useTranslation();

  // function callback() {

  // }
  const [isAnnotation, setIsModalAnnotation] = useState(false); // accept modal
  const showModalAnnotation = () => {
    setIsModalAnnotation(true);
  };
  const handleOkAnnotation = () => {
    setIsModalAnnotation(false);
  };
  const handleCanceAnnotation = () => {
    setIsModalAnnotation(false);
  };
  const [isAnnotationreject, setIsModalAnnotationreject] = useState(false);
  const showModalAnnotationreject = () => {
    setIsModalAnnotationreject(true);
  };
  const handleOkAnnotationreject = () => {
    setIsModalAnnotationreject(false);
  };
  const handleCanceAnnotationreject = () => {
    setIsModalAnnotationreject(false);
  };
  const [isReport, setIsModalReport] = useState(false);
  const showModalReport = () => {
    setIsModalReport(true);
  };
  const handleOkReport = () => {
    setIsModalReport(false);
  };
  const handleCancelReport = () => {
    setIsModalReport(false);
  };
  const ReportedWorker = (
    <table
      className={
        localStorage.getItem("lang") == "ar"
          ? "text_data owner-ongoing-proj-details-task-details-dispute-annot-ar"
          : "text_data owner-ongoing-proj-details-task-details-dispute-annot-en"
      }
    >
      <tbody>
        {validatorDetails?.comments.map((item, index) => {
          return (
            <tr className="top_bar" key={index}>
              <td>
                {index + 1}
                {". "}
                {item?.comment}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  const dataArray = [
    {
      name: "Micheal",
      profile: images.user,
      rating: images.star,
    },
    {
      name: "Micheal",
      profile: images.user,
      rating: images.star,
    },
    {
      name: "Micheal",
      profile: images.user,
      rating: images.star,
    },
  ];
  const data123 = (values_data) => {
    return (
      <div className="data_news">
        <div className="first_text">
          <img src={values_data.profile} style={{ width: "20px" }}></img>
        </div>
        <div className="first_text">{values_data.name}</div>
        <div>
          <img src={values_data.rating}></img>
        </div>
      </div>
    );
  };
  const { Option } = Select;
  const children = [];
  for (let i = 0; i < dataArray.length; i++) {
    children.push(<Option key={i}>{data123(dataArray[i])}</Option>);
  }
  // function handleChange(value) {
  //   console.log(``);
  // }
  const dataArrayEmail = [
    {
      name: "abc12@yopmail.com",
    },
    {
      name: "m12@yopmail.com",
    },
  ];
  const Emaildata = (values_data_new) => {
    return (
      <div className="data_news">
        <div className="first_text">{values_data_new.name}</div>
      </div>
    );
  };
  const childrenEmail = [];
  for (let i = 0; i < dataArrayEmail.length; i++) {
    childrenEmail.push(<Option key={i}>{Emaildata(dataArrayEmail[i])}</Option>);
  }
  const Classvalue = [
    {
      name: "class1",
    },
    {
      name: "class2",
    },
  ];
  const ClassValue = (values_data_new) => {
    return (
      <div className="data_news">
        <div className="first_text">{values_data_new.name}</div>
      </div>
    );
  };
  const classnamevalue = [];
  for (let i = 0; i < Classvalue.length; i++) {
    classnamevalue.push(<Option key={i}>{ClassValue(Classvalue[i])}</Option>);
  }

  const breadCrumbItems = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.PROJECT,
      label: t("projects"),
    },
    {
      link: NavigateTo.ONGOING_PROJECT_DETAILS,
      label: t("ongoing_prj_details"),
    },
    {
      link: NavigateTo.TASK_DETAILS + `/${taskIdForReport}`,
      label: t("task_details"),
    },
    {
      link: "",
      label: t("dis_annot_details"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t('projects'),
  };
  const udtBreadCrumbsEn = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: "Dashboard",
    },
    {
      link: NavigateTo.PROJECT,
      label: "Projects",
    },
    {
      link: NavigateTo.ONGOING_PROJECT_DETAILS,
      label: "Ongoing Project Details",
    },
    {
      link: NavigateTo.TASK_DETAILS + `/${taskIdForReport}`,
      label: "Task Details",
    },
    {
      link: NavigateTo.DISPUTE_ANNOTATION + `/${disputeId}`,
      label: "Dispute Annotation Details",
    },
    {
      link: "",
      label: 'Annotate',
    },
  ];
  const udtBreadCrumbsAr = [
    {
      link: NavigateTo.USER_DASHBOARD,
      label: "لوحة القيادة",
    },
    {
      link: NavigateTo.PROJECT,
      label: "المشاريع",
    },
    {
      link: NavigateTo.ONGOING_PROJECT_DETAILS,
      label: "تفاصيل المشروع الجاري",
    },
    {
      link: NavigateTo.TASK_DETAILS + `/${taskIdForReport}`,
      label: "تفاصيل المهمة",
    },
    {
      link: NavigateTo.DISPUTE_ANNOTATION + `/${disputeId}`,
      label: "تفاصيل التعليق التوضيحي",
    },
    {
      link: "",
      label: "وسم",
    },
  ];
  const udtContainerProps = {
    breadCrumbItems: {
      en: udtBreadCrumbsEn,
      ar: udtBreadCrumbsAr
    },
    menuProps: SidebarMenus("owner"),
    active: t('projects'),
  };
  const payloadDisputeDetails = {
    body: {
      disputeAnnotationId: disputeId,
    },
    success: (res) => {
      let classes,
        img,
        taskId, // task type
        description,
        valId,
        valImg,
        valName,
        valEmail,
        valNum,
        valComments,
        workerId,
        workerImg,
        workerName,
        annotId,
        task_id,
        pId,
        valIdReport,
        workerIdReport,
        disputedId;

      res?.results?.map((item) => {
        taskId = item?.task_id?.task_type; // task type
        setTaskIdForReport(item?.task_id?.id);
        if (taskId == 1) {
          // image
          let tempClassNames = [];
          item?.Anotation_id?.jsonFile?.regions.map((item) => {
            tempClassNames.push(item?.cls);
          });
          classes = tempClassNames;
          annotId = item?.Anotation_id?.id;
          disputedId = item?.dispute_status_id?.id;
          img = item?.Anotation_id?.jsonFile?.src
            ? item?.Anotation_id?.jsonFile?.src.startsWith("h")
              ? item?.Anotation_id?.jsonFile?.src
              : item?.Anotation_id?.jsonFile?.src.includes("static")
                ? AppImage.defaultImage
                : appconstant.imageBaseUrl + item?.Anotation_id?.jsonFile?.src
            : AppImage.defaultImage;

          pId = item?.task_id?.project_id;
          task_id = item?.task_id?.id;
          description = item?.task_id?.description;
          valId = item?.validator_id?.id;
          valImg = item?.validator_id?.user_id?.image;
          valName =
            item?.validator_id?.user_id?.first_name +
            " " +
            item?.validator_id?.user_id?.last_name;
          valEmail = item?.validator_id?.user_id?.email;
          valNum = item?.validator_id?.user_id?.phone_no;
          valComments = item?.Anotation_id?.comment;
          workerId = item?.worker_id?.id;
          workerImg = item?.worker_id?.user_id?.image;
          workerIdReport = item?.worker_id?.user_id?.id;
          valIdReport = item?.validator_id?.user_id?.id;
          workerName =
            item?.worker_id?.user_id?.first_name +
            " " +
            item?.worker_id?.user_id?.last_name;

          setDisputedAnnotInfo({
            fileImg: img,
            taskType: taskId,
            className: classes,
            taskDescription: description,
          });
        } // image
        if (taskId == 3 || taskId == 2) {
          // audio
          annotId = item?.Anotation_id?.id;
          disputedId = item?.dispute_status_id?.id;
          img = taskId == 2 ? AppImage.file_n : AppImage.volumeFileImg; //item?.Anotation_id?.jsonFile?.src;
          pId = item?.task_id?.project_id;
          task_id = item?.task_id?.id;
          description = item?.task_id?.description;
          valId = item?.validator_id?.id;
          workerIdReport = item?.worker_id?.user_id?.id;
          valIdReport = item?.validator_id?.user_id?.id;
          valImg = item?.validator_id?.user_id?.image;
          valName =
            item?.validator_id?.user_id?.first_name +
            " " +
            item?.validator_id?.user_id?.last_name;
          valEmail = item?.validator_id?.user_id?.email;
          valNum = item?.validator_id?.user_id?.phone_no;
          valComments = item?.Anotation_id?.comment;
          workerId = item?.worker_id?.id;
          workerImg = item?.worker_id?.user_id?.image;
          workerName =
            item?.worker_id?.user_id?.first_name +
            " " +
            item?.worker_id?.user_id?.last_name;
          setDisputedAnnotInfo({
            fileImg: img,
            taskType: taskId,
            taskDescription: description,
          });
        } // audio
        if (taskId == 2 && item?.task_id?.text_type == 'questions') {
          // setqnindex();
          // setpdfUrl();
        }
        setRisputeAcceptedOrRejected(item?.status);
      });
      setAnnotId(annotId);
      setDisputeId(disputedId);
      setTaskId(task_id);
      setProjectId(pId);
      setValDetails({
        id: valId,
        img: valImg,
        name: valName,
        email: valEmail,
        number: valNum,
        comments: valComments,
        valIdReport,
      });
      setWorkerDetails({
        id: workerId,
        img: workerImg,
        name: workerName,
        workerIdReport,
      });
    },
    fail: (resf) => {
      console.log({ resf });
    },
  };
  const payloadDisputeAccept = {
    body: JSON.stringify({
      id: disputedId, //dispute id
      anotation_id: annotId,
      task_id: task_Id,
      project_id: projectId,
      status: 2,
      worker_id: workerDetails?.id,
      validator_id: validatorDetails?.id,
    }),
    success: (res) => {
      toast.success(res?.data?.message, {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        toastId: "accept",
      });
      isInternetConnected() && dispatch(disputeDetails(payloadDisputeDetails));
    },
    fail: (resf) => {
      console.log({ resf });
    },
  };
  const payloadDisputeReject = {
    body: JSON.stringify({
      id: disputedId, //dispute id
      task_id: task_Id,
      anotation_id: annotId,
      status: 3, //2 approve, 3 reject
      worker_id: workerDetails?.id,
      validator_id: validatorDetails?.id,
      project_id: projectId,
    }),
    success: (res) => {
      toast.success(res?.data?.message, {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
        toastId: "reject",
      });
      isInternetConnected() && dispatch(disputeDetails(payloadDisputeDetails));
    },
    fail: (resf) => {
      console.log({ resf });
    },
  };
  const payloadReport = {
    body: JSON.stringify({
      reported_by: Number(localStorage.getItem("loggedInUserId")),
      reported_to: rateTo,
      type: toUserType, //1 worker , 2 validator
      // "Anotation_id":anotation_id,
      task_id: taskIdForReport,
    }),
    success: (res) => {
      toast.success(res, {
        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
      });
    },
    fail: (resf) => {
      console.log(resf, "resf of  validator listing.");
    },
  }; //  report payload
  useEffect(() => {
    document.title = "WosoM";
    isInternetConnected() && dispatch(disputeDetails(payloadDisputeDetails));
  }, []);
  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
      if (isAnnotation) {
        document.querySelector(".ant-modal-content").style.direction = "rtl";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
      if (isAnnotation) {
        document.querySelector(".ant-modal-content").style.direction = "ltr";
      }
    }
  }, [isReport, isAnnotation, isAnnotationreject]);

  return (
    <Container
      {...containerProps}
      // languageChanged={() => {
      //   setLang(localStorage.getItem("lang"));
      //   // it is reuqired to re render this whole component
      // }}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e padding_new">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {t("dispute_details")}
            </h6>
          </div>
          <div className="wrapper_line">
            {/* fileImg */}
            <div className="form-group">
              <label>{t("file_img")}</label>
              <img
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 10,
                }}
                src={disputedAnnotInfo.fileImg}
              />
            </div>

            {/* task type */}
            <div className="form-group">
              <label
                style={{
                  display: "inline",
                  color: "#143C64",
                  fontWeight: 500,
                }}
              >{t("tsk_typ")}:</label>
              <span
                style={{
                  display: "inline",
                  marginLeft: 10,
                  color: "#316293",
                  fontWeight: 500,
                  wordBreak: "break-all",
                }}
              >
                {disputedAnnotInfo.taskType == "1"
                  ? t("image")
                  : disputedAnnotInfo.taskType == "2"
                    ? t("text")
                    : t("audio")} </span>
            </div>
            {/* class name */}
            {disputedAnnotInfo.taskType == "Image" ? (
              <div className="form-group">
                <label>{t("clss_name")}:</label>
                <span>{disputedAnnotInfo.className}</span>
              </div>
            ) : null}

            {/* task desc */}
            <div className="form-group">
              <label
                style={{
                  display: "inline",
                  color: "#143C64",
                  fontWeight: 500,
                }}
              >{t("tsk_desc")}:</label>
              <span
                style={{
                  display: "inline",
                  marginLeft: 10,
                  color: "#316293",
                  fontWeight: 500,
                  wordBreak: "break-all",
                }}
              >
                {disputedAnnotInfo.taskDescription}
              </span>
            </div>
            {/* </div> */}

            {/* accept/rject button */}
            <div className="button_box">
              {disputeAcceptedOrRejected == 4 ? (
                <>
                  <button
                    type="button"
                    className="button-list focus"
                    onClick={() => {
                      showModalAnnotation();
                    }}
                  >
                    {t("acpt")}
                  </button>
                  <button
                    type="button"
                    // style={{
                    //   background: `linear-gradient(180deg, #727577 0%, #A1A1A2 100%)`
                    // }}
                    className="button-list focus"
                    onClick={() => {
                      showModalAnnotationreject();
                    }}
                  >
                    {t("reject")}
                  </button>
                  <button
                    type="button"
                    // style={{
                    //   background: `linear-gradient(180deg, #727577 0%, #A1A1A2 100%)`
                    // }}
                    className="button-list focus"
                    onClick={() => {
                      if (others?.taskType == "Image") {
                        navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}/0`, {
                          state: {
                            udtContainerProps,

                            taskType: others?.taskType,
                            removeIcos: true,

                            classLists: others?.classLists,
                            images: others?.images,
                            imageIndex: 0, //others?.imageIndex,
                            disputed: true,
                            particularAnnotation: others?.particularAnnotation,
                          },
                        });
                      }
                      if (others?.taskType == "Audio") {
                        let singleAudio = [
                          {
                            url: others?.url, //others?.
                            jsonFile: others?.individualAnnots, //annotDetails?.jsonFile[index]?.regions, // jsonFile
                            task_id: 1,
                            anotation_id: 1,
                            worker_id: 1,
                            disputed: true,
                          },
                        ];
                        navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                          state: {
                            taskType: others?.taskType,
                            udtContainerProps,
                            audio: singleAudio,
                            disputed: true,
                          },
                        });
                      }
                      if (others?.taskType == "Text") {
                        if (localStorage.getItem("textType") == "questions") {
                          navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                            state: {
                              taskType: others?.taskType,
                              udtContainerProps,
                              pdfUrl: others?.pdfUrl,
                              questions: others?.questions,
                              particularAnnotation:
                                others?.particularAnnotation,
                              qnindex: others?.qnindex,
                              disputed: true,
                            },
                          });
                        }
                        if (
                          localStorage.getItem("textType") == "segmentation"
                        ) {
                          navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                            state: {
                              taskType: others?.taskType,
                              udtContainerProps,

                              particularAnnotation:
                                others?.particularAnnotation,
                              segmentAllAnnots: others?.segmentAllAnnots,
                              classes: others?.classes,
                              totalAnnots: others?.totalAnnots,
                              paragraph: others?.paragraph,
                              disputed: true,
                            },
                          });
                        }
                      }
                    }}
                  >
                    {t("view")}
                  </button>
                </>
              ) : disputeAcceptedOrRejected == 2 ? (
                <>
                  <button
                    type="button"
                    className="button-list focus"
                    style={{
                      background: `linear-gradient(180deg, #727577 0%, #A1A1A2 100%)`,
                      cursor: "no-drop",
                    }}
                    disabled={true}
                    onClick={() => {
                      showModalAnnotation();
                    }}
                  >
                    {t("accepted")}
                  </button>
                  <button
                    type="button"
                    // style={{
                    //   background: `linear-gradient(180deg, #727577 0%, #A1A1A2 100%)`
                    // }}
                    className="button-list focus"
                    onClick={() => {
                      if (others?.taskType == "Image") {

                        navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                          state: {
                            udtContainerProps,

                            taskType: others?.taskType,
                            removeIcos: true,

                            classLists: others?.classLists,
                            images: others?.images,
                            imageIndex: 0, //others?.imageIndex
                            particularAnnotation: others?.particularAnnotation,
                          },
                        });
                      }
                      if (others?.taskType == "Audio") {
                        let singleAudio = [
                          {
                            url: others?.url, //others?.
                            jsonFile: others?.individualAnnots, //annotDetails?.jsonFile[index]?.regions, // jsonFile
                            task_id: 1,
                            anotation_id: 1,
                            worker_id: 1,
                          },
                        ];
                        navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                          state: {
                            taskType: others?.taskType,
                            udtContainerProps,
                            audio: singleAudio,
                          },
                        });
                      }
                      if (others?.taskType == "Text") {
                        if (localStorage.getItem("textType") == "questions") {
                          navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                            state: {
                              taskType: others?.taskType,
                              udtContainerProps,
                              pdfUrl: others?.pdfUrl,
                              questions: others?.questions,
                              particularAnnotation:
                                others?.particularAnnotation,
                            },
                          });
                        }
                        if (
                          localStorage.getItem("textType") == "segmentation"
                        ) {
                          navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                            state: {
                              taskType: others?.taskType,
                              udtContainerProps,

                              particularAnnotation:
                                others?.particularAnnotation,
                              segmentAllAnnots: others?.segmentAllAnnots,
                              classes: others?.classes,
                              totalAnnots: others?.totalAnnots,
                              paragraph: others?.paragraph,
                            },
                          });
                        }
                      }
                    }}
                  >
                    {t("view")}
                  </button>
                </>
              ) : disputeAcceptedOrRejected == 3 ? (
                <>
                  <button
                    type="button"
                    style={{
                      background: `linear-gradient(180deg, #727577 0%, #A1A1A2 100%)`,
                      cursor: "no-drop",
                    }}
                    disabled={true}
                    className="button-list focus"
                    onClick={() => {
                      showModalAnnotationreject();
                    }}
                  >
                    {t("rejected")}
                  </button>
                  <button
                    type="button"
                    // style={{
                    //   background: `linear-gradient(180deg, #727577 0%, #A1A1A2 100%)`
                    // }}
                    className="button-list focus"
                    onClick={() => {
                      if (others?.taskType == "Image") {

                        navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                          state: {
                            udtContainerProps,

                            taskType: others?.taskType,
                            removeIcos: true,

                            classLists: others?.classLists,
                            images: others?.images,
                            imageIndex: 0, //others?.imageIndex
                            particularAnnotation: others?.particularAnnotation,
                          },
                        });
                      }
                      if (others?.taskType == "Audio") {
                        let singleAudio = [
                          {
                            url: others?.url, //others?.
                            jsonFile: others?.individualAnnots, //annotDetails?.jsonFile[index]?.regions, // jsonFile
                            task_id: 1,
                            anotation_id: 1,
                            worker_id: 1,
                          },
                        ];
                        navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                          state: {
                            taskType: others?.taskType,
                            udtContainerProps,
                            audio: singleAudio,
                          },
                        });
                      }
                      if (others?.taskType == "Text") {
                        if (localStorage.getItem("textType") == "questions") {
                          navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                            state: {
                              taskType: others?.taskType,
                              udtContainerProps,
                              pdfUrl: others?.pdfUrl,
                              questions: others?.questions,
                              particularAnnotation:
                                others?.particularAnnotation,
                            },
                          });
                        }
                        if (
                          localStorage.getItem("textType") == "segmentation"
                        ) {
                          navigate(NavigateTo.USER_UDT_OWNER + `/${taskIdForReport}`, {
                            state: {
                              taskType: others?.taskType,
                              udtContainerProps,

                              particularAnnotation:
                                others?.particularAnnotation,
                              segmentAllAnnots: others?.segmentAllAnnots,
                              classes: others?.classes,
                              totalAnnots: others?.totalAnnots,
                              paragraph: others?.paragraph,
                            },
                          });
                        }
                      }
                    }}
                  >
                    {t("view")}
                  </button>
                </>
              ) : null}


            </div>

            {/* validator & worker info */}
            <div className="validation_data_point">
              {/* validator info */}
              <div className="validator_details data_new">
                <h4 className="small_details">{t("validator_details")}</h4>

                {/* image */}
                <div style={{ marginTop: 30, marginBottom: 20 }}>
                  <img
                    src={validatorDetails?.img || AppImage.defaultImage}
                    width={40}
                    height={40}
                    style={{ borderRadius: 40 }}
                  />
                </div>
                {/* name */}
                <div className="form-group">
                  <label>{t("val_name")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("usr_name")}
                    value={validatorDetails?.name}
                    disabled
                    autoComplete="off"
                  />
                </div>
                {/* email */}
                <div className="form-group">
                  <label>{t("email_address")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("email_address")}
                    value={validatorDetails?.email}
                    disabled
                    autoComplete="off"
                  />
                </div>
                {/* number */}
                <div className="form-group">
                  <label>{t("contact_num")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("contact_num")}
                    value={validatorDetails?.number}
                    disabled
                    autoComplete="off"
                  />
                </div>
                {/* comments */}
                <div className="form-group">
                  <Collapse defaultActiveKey={["1"]}/*  onChange={()=)} */>
                    <Panel header={t("validator_comments")} key="1">
                      {ReportedWorker}
                    </Panel>
                  </Collapse>
                </div>
                {/* report button */}
                <div className="button_box details_annotation">
                  <button
                    type="submit"
                    className="button-list focus"
                    onClick={() => {
                      showModalReport();
                      setRateTo(validatorDetails?.valIdReport);
                      setToUserType(2);
                    }}
                  >
                    {t("report")}
                  </button>
                </div>
              </div>

              {/* worker info */}
              <div
                className="validator_details workers"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* worker details */}
                <div>
                  <h4 className="small_details">{t("worker_details")}</h4>

                  {/* image */}
                  <div style={{ marginTop: 30, marginBottom: 20 }}>
                    <img
                      src={workerDetails?.img || AppImage.defaultImage}
                      width={40}
                      height={40}
                      style={{ borderRadius: 40 }}
                    />
                  </div>
                  {/* name */}
                  <div className="form-group">
                    <label>{t("worker_name")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("worker_name")}
                      value={workerDetails?.name}
                      disabled
                      autoComplete="off"
                    />
                  </div>

                </div>

                {/* report button */}
                <div className="button_box margin_box">
                  <button
                    type="submit"
                    className="button-list focus"
                    onClick={() => {
                      showModalReport();
                      setRateTo(workerDetails?.workerIdReport);

                      setToUserType(1);
                    }}
                  >
                    {t("report")}
                  </button>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      {/* accept dispute request modal */}
      <Modal
        title={t("confirmation")}
        open={isAnnotation}
        onOk={handleOkAnnotation}
        onCancel={handleCanceAnnotation}
        wrapClassName="poolworkers"
        footer={null}
        width={600}
        centered
      >
        <div className="text-center  model1">
          <p className="text_l">{t("accept_dispute_request")}</p>
        </div>
        <div
          className="text-center  model1"
          style={{
            direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
          }}
        >
          <button
            type="submit"
            style={{
              background: `linear-gradient(180deg, #194C7F 0%, #143C64 100%)`,
            }}
            className="button-list focus"
            onClick={() => {
              isInternetConnected() &&
                dispatch(acceptRejectDisputedAnnotations(payloadDisputeAccept));
              handleCanceAnnotation(false);
            }}
          >
            {t("yes")}
          </button>
          <button
            type="submit"
            style={{
              background: `linear-gradient(180deg, #727577 0%, #A1A1A2 100%)`,
            }}
            className="button-list focus"
            onClick={() => handleCanceAnnotation(false)}
          >
            {t("no")}
          </button>
        </div>
      </Modal>

      {/* reject dispute request modal */}
      <Modal
        title={t("confirmation")}
        open={isAnnotationreject}
        onOk={handleOkAnnotationreject}
        onCancel={handleCanceAnnotationreject}
        wrapClassName="poolworkers"
        footer={null}
        width={600}
        centered
      >
        <div className="text-center  model1">
          <p className="text_l">{t("reject_dispute_request")}</p>
        </div>
        <div
          className="text-center  model1"
          style={{
            direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
          }}
        >
          <button
            type="submit"
            className="button-list top_new focus"
            style={{
              background: `linear-gradient(180deg, #194C7F 0%, #143C64 100%)`,
            }}
            onClick={() => {
              isInternetConnected() &&
                dispatch(acceptRejectDisputedAnnotations(payloadDisputeReject));
              handleCanceAnnotationreject(false);
            }}
          >
            {t("yes")}
          </button>
          <button
            type="submit"
            className="focus"
            style={{
              background: `linear-gradient(180deg, #727577 0%, #A1A1A2 100%)`,
            }}
            onClick={() => handleCanceAnnotationreject(false)}
          >
            {t("no")}
          </button>
        </div>
      </Modal>

      {/* report modal */}
      <Modal
        title={t("report")}
        open={isReport}
        onOk={handleOkReport}
        onCancel={handleCancelReport}
        footer={null}
        centered
      >
        <div className="text-center  model1">
          <p>{t("u_sure_to_reprt_the_user")}</p>
        </div>
        <div
          className="text-center  model1"
          style={{
            direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
          }}
        >
          <button
            type="submit"
            className="button-list top_new focus"
            onClick={() => {
              isInternetConnected() && dispatch(report(payloadReport));
              handleCancelReport(false);
            }}
          >
            {t("yes")}
          </button>
          <button
            type="submit"
            className="focus"
            onClick={() => handleCancelReport(false)}
          >
            {t("no")}
          </button>
        </div>
      </Modal>
    </Container>
  );
};
export default DisputeAnnotationDetails;
