import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";

function ComplexLine({ dates }) {
    let chart;
    // const { t } = useTranslation();
    const colors = [
        am4core.color("#123a65"),
        am4core.color("#184f8b"),
        am4core.color("#2a80ff")
    ];
    const user64 = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBVcGxvYWRlZCB0bzogU1ZHIFJlcG8sIHd3dy5zdmdyZXBvLmNvbSwgR2VuZXJhdG9yOiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4NCjxzdmcgaGVpZ2h0PSI4MDBweCIgd2lkdGg9IjgwMHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIA0KCSB2aWV3Qm94PSIwIDAgNjAuNjcxIDYwLjY3MSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPGVsbGlwc2Ugc3R5bGU9ImZpbGw6IzAyMjY0OTsiIGN4PSIzMC4zMzYiIGN5PSIxMi4wOTciIHJ4PSIxMS45OTciIHJ5PSIxMi4wOTciLz4NCgkJPHBhdGggc3R5bGU9ImZpbGw6IzAyMjY0OTsiIGQ9Ik0zNS42NCwzMC4wNzlIMjUuMDMxYy03LjAyMSwwLTEyLjcxNCw1LjczOS0xMi43MTQsMTIuODIxdjE3Ljc3MWgzNi4wMzdWNDIuOQ0KCQkJQzQ4LjM1NCwzNS44MTgsNDIuNjYxLDMwLjA3OSwzNS42NCwzMC4wNzl6Ii8+DQoJPC9nPg0KPC9nPg0KPC9zdmc+";
    const annotation64 = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPgo8c3ZnIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDE2IDE2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMxODRlODciIGNsYXNzPSJiaSBiaS10YWdzLWZpbGwiPgogIDxwYXRoIGQ9Ik0yIDJhMSAxIDAgMCAxIDEtMWg0LjU4NmExIDEgMCAwIDEgLjcwNy4yOTNsNyA3YTEgMSAwIDAgMSAwIDEuNDE0bC00LjU4NiA0LjU4NmExIDEgMCAwIDEtMS40MTQgMGwtNy03QTEgMSAwIDAgMSAyIDYuNTg2VjJ6bTMuNSA0YTEuNSAxLjUgMCAxIDAgMC0zIDEuNSAxLjUgMCAwIDAgMCAzeiIvPgogIDxwYXRoIGQ9Ik0xLjI5MyA3Ljc5M0ExIDEgMCAwIDEgMSA3LjA4NlYyYTEgMSAwIDAgMC0xIDF2NC41ODZhMSAxIDAgMCAwIC4yOTMuNzA3bDcgN2ExIDEgMCAwIDAgMS40MTQgMGwuMDQzLS4wNDMtNy40NTctNy40NTd6Ii8+Cjwvc3ZnPg=="
    const invite64 = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSI+DQo8cGF0aCBkPSJNMS42MDE3NSA0LjIwMTE0QzIuMTQ5OTcgMy40NzI1OCAzLjAyMTU4IDMgNCAzSDIwQzIwLjk3ODQgMyAyMS44NSAzLjQ3MjU4IDIyLjM5ODIgNC4yMDExM0wxMiAxMS43NjM1TDEuNjAxNzUgNC4yMDExNFoiIGZpbGw9IiMzMzg0ZmEiLz4NCjxwYXRoIGQ9Ik0xIDYuMjM2NVYxOEMxIDE5LjY1MjMgMi4zNDc3MiAyMSA0IDIxSDIwQzIxLjY1MjMgMjEgMjMgMTkuNjUyMyAyMyAxOFY2LjIzNjQ5TDEzLjE3NjMgMTMuMzgxQzEyLjQ3NSAxMy44OTEgMTEuNTI1IDEzLjg5MSAxMC44MjM3IDEzLjM4MUwxIDYuMjM2NVoiIGZpbGw9IiMzMzg0ZmEiLz4NCjxzdHlsZSB4bWxucz0iIiBjbGFzcz0iZGFya3JlYWRlciBkYXJrcmVhZGVyLS1mYWxsYmFjayI+aHRtbCwgYm9keSwgYm9keSA6bm90KGlmcmFtZSkgew0KICAgIGJhY2tncm91bmQtY29sb3I6ICMxODFhMWIgIWltcG9ydGFudDsNCiAgICBib3JkZXItY29sb3I6ICM3NzZlNjIgIWltcG9ydGFudDsNCiAgICBjb2xvcjogI2U4ZTZlMyAhaW1wb3J0YW50Ow0KfTwvc3R5bGU+PC9zdmc+"
    useEffect(() => {
        chart = am4core.create("chartdiv", am4charts.XYChart);

        //

        // Increase contrast by taking evey second color
        chart.colors.list = colors;

        // Add data
        chart.data = generateChartData();

        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 100;

        // Create series
        function createAxisAndSeries(field, name, opposite, bulletimg) {
            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            if (chart.yAxes.indexOf(valueAxis) != 0) {
                valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
            }

            var series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.strokeWidth = 2;
            series.yAxis = valueAxis;
            series.name = name;
            series.tooltipText = "{name}: [bold]{valueY}[/]";
            series.tensionX = 0.9;
            series.showOnInit = true;
            var bullet = series.bullets.push(new am4charts.Bullet());
            bullet.width = 12;
            bullet.height = 12;
            bullet.horizontalCenter = "middle";
            bullet.verticalCenter = "middle";
            var triangle = bullet.createChild(am4core.Image);
            triangle.propertyFields.href = bulletimg;
            triangle.width = 20;
            triangle.height = 20;
            triangle.verticalCenter = "top";

            valueAxis.renderer.line.strokeOpacity = 1;
            valueAxis.renderer.line.strokeWidth = 2;
            valueAxis.renderer.line.stroke = series.stroke;
            valueAxis.renderer.labels.template.fill = series.stroke;
            valueAxis.renderer.opposite = opposite;
        }

        createAxisAndSeries("visits", "Signup", false, "bullet1");
        createAxisAndSeries("hits", "Annotations", false, "bullet2");
        createAxisAndSeries("views", "Invites", false, "bullet3");

        // Add legend
        chart.legend = new am4charts.Legend();
        //legen bullet
        chart.legend.useDefaultMarker = true;
        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
        chart.zoomOutButton.background.fill = am4core.color("#194c7f");
        chart.zoomOutButton.icon.stroke = am4core.color("#FFF");
        chart.zoomOutButton.icon.strokeWidth = 2;
        chart.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#194c7f");
        // generate some random data, quite different range

        function generateChartData() {
            var chartData = [];

            var merged = [];
            dates.annotations.forEach(function (item) {
                merged.push({
                    date: item.date,
                    annotations: item.count,
                });
            });
            dates.users.forEach(function (item) {
                var found = merged.find(function (element) {
                    return element.date === item.date;
                });
                if (found) {
                    found.users = item.count;
                } else {
                    merged.push({
                        date: item.date,
                        users: item.count,
                    });
                }
            });
            dates.invites.forEach(function (item) {
                var found = merged.find(function (element) {
                    return element.date === item.date;
                });
                if (found) {
                    found.invites = item.count;
                } else {
                    merged.push({
                        date: item.date,
                        invites: item.count,
                    });
                }
            });
            merged.sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });
            merged = merged.filter(function (item) {
                return item.date != null;
            });

            merged.forEach(function (item) {
                var newDate = new Date(item.date);
                chartData.push({
                    date: newDate,
                    visits: item.users,
                    hits: item.annotations,
                    views: item.invites,
                    bullet1: user64,
                    bullet2: annotation64,
                    bullet3: invite64
                });
            });
            return chartData;
        }
    }, [dates]);
    return (
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    );
}

ComplexLine.propTypes = {
    dates: PropTypes.array
};

export default ComplexLine;