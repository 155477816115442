import {SHOULD_MEMBER_REMOVED, GROUP_INVITATIONS,GET_ALL_GROUPS,CREATE_GROUP,UPDATE_GROUP,DELETE_GROUP } from '../actionTypes/actionTypes'
 
export const shouldMemberBeRemoved = (payload) => {
    return {
        type: SHOULD_MEMBER_REMOVED,
        payload
    }
} 
export const getAllGroupLists = (payload) => {
    return {
        type: GET_ALL_GROUPS,
        payload
    }
} 
export const createGroup = (payload) => {
    return {
        type: CREATE_GROUP,
        payload
    }
} 
export const updateGroup = (payload) => {
    return {
        type: UPDATE_GROUP,
        payload 
    } 
} 
export const deleteGroup = (payload) => {    
    return {
        type: DELETE_GROUP,
        payload
    }
} 
export const getGroupInvitations = (payload) => {    
    return {
        type: GROUP_INVITATIONS,
        payload
    }
} 