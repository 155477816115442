import React from "react";
import {useLocation} from 'react-router';
import { Link } from "react-router-dom";
import images from "../../themes/appImage";
import { Constants, NavigateTo } from "../../themes";

const CustomFooter = () => {
  const location = useLocation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    /* Footer Section  */
    <section className="foter_bottom">
      <div className="container">
        <div className="footer-bottom">
          <div className="row landing_footer">
            <div className="col-sm-4 text-left">
              <h4>
                <img src={images.rightimg}></img>
                <span className="links">{Constants.LeftSideHeader}</span>
                <img src={images.leftimg}></img>
              </h4>
              <ul>
                <li>
                  <Link to={NavigateTo.PRIVACY}>{Constants.PrivacyPolicy}</Link>
                </li>
                <li>
                  <Link to={NavigateTo.TERMS}>{Constants.TermsConditions}</Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-4 text-center">
              {location.pathname == NavigateTo.LANDING ? (
                <span onClick={() => scrollToTop()}>
                  <img src={images.logo} alt="" />
                </span>
              ) : (
                <Link to={NavigateTo.LANDING} style={{ cursor: "pointer" }}>
                  <img src={images.logo} alt="" />
                </Link>
              )}
            </div>
            <div className="col-sm-4 text-right">
              <h4>
                <img src={images.rightimg}></img>
                <span className="links">{Constants.RightSideHeader}</span>
                <img src={images.leftimg}></img>
              </h4>
              <ul className="text_center_iu">
                <li>{Constants.Contact}</li>
                <li>{Constants.AdressInfo}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    /* Footer Section  */
  );
};

export default CustomFooter;
