/*

1. For CustomTab :
    * tabProps, 
      are Mandatory props
    * Prop types
        {
            tabProps = [
                {
                    tabName: Constants.Tab_Name1,
                    child: () => {
                    return <div>Tab1</div>;
                    },
                },
                {
                    tabName: Constants.Tab_Name2,
                    child: () => {
                    return <div>Tab1</div>;
                    },
                },
            ] // array of child and tabname objs for tab
        }

*/

import React from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const CustomTab = (props) => {
  const {
    tabProps = [
      {
        tabName: "Tab11",
        tabContetn: () => {
          return <div>Tab1</div> 
        },
      },
      {
        tabName: "Tab2",
        tabContetn: () => {
          return <div>Tab1</div> 
        },
      },
    ],
  } = { ...props };
  return (
    <Tabs defaultActiveKey="0">
      {tabProps.map((item,index) => {
        return (
          <TabPane tab={item.tabName} key={index.toString()}>
            {item.tabContetn}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default CustomTab;
