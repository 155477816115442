import Fetch from "../Fetch/Notification";
import { put, call } from "redux-saga/effects";
import { startLoading, stopLoading } from "../actions/Common";  

 
export function* getUserNotificationSaga({
  payload: { success = () => {}, fail = () => {},body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.userNotificationApi,body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* acceptRejectNotificationSaga({
  payload: { success = () => {}, fail = () => {},body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.acceptRejectNotificationApi,body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      fail(result?.result);
      yield put(stopLoading());
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
 
