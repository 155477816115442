import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { AppImage } from "../../../themes";
import {
  FileOutlined,
  UsergroupAddOutlined,
  SafetyOutlined,
  GroupOutlined,
  TagsOutlined,
  FilterOutlined,
  NumberOutlined,
} from "@ant-design/icons";
import PropType from "prop-types";


ChartJS.register(ArcElement, Tooltip, Legend);


const TaskPreviewModal = ({ data, validationSystem }) => {
  const { t } = useTranslation();
  const [chart, setChart] = useState({
    labels: ["Red", "Blue", "Yellow", "Green", "Purple"],
    datasets: [
      {
        label: t("annotator_quota"),
        data: [12, 19, 3, 5, 2],
        backgroundColor: [
          "#143c64",
          "#325977",
          "#4F7689",
          "#6C939C",
          "#89B0AE",
        ],
        borderWidth: 3,
        roundedCorners: true,
      },
    ],
  });
  const [values, setValues] = useState([
    {
      title: t("avg_task_in_all_prj"),
      value: 100,
      icon: <img src={AppImage.icont1} width={40} height={40} />,
    },
    {
      title: t("entr_vld_name"),
      value: 3,
      icon: <img src={AppImage.icont1} width={40} height={40} />,
    },
    {
      title: t("image_list"),
      value: 2,
      icon: <img src={AppImage.icont1} width={40} height={40} />,
    },
  ]);
  const [values2, setValues2] = useState([
    {
      title: t("avg_task_in_all_prj"),
      value: 100,
      icon: <img src={AppImage.icont1} width={40} height={40} />,
    },
    {
      title: t("entr_vld_name"),
      value: 3,
      icon: <img src={AppImage.icont1} width={40} height={40} />,
    },
    {
      title: t("image_list"),
      value: 2,
      icon: <img src={AppImage.icont1} width={40} height={40} />,
    },
  ]);
  const [loaded, setLoaded] = useState(false);

  const cardProps = [
    {
      type: "chart",
      value: (
        <Doughnut
          data={chart}
          width={100}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: validationSystem == "primary" ? t("documents_per_annotator") : t("annotators_per_group"),
              },
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  beforeBody: () => {
                    if (validationSystem == "primary")
                      return t("documents_per_annotator");
                    else return t("annotators_per_group");
                  },
                },
              },
            },
          }}
        />
      ),
      span: 6,
    },
    {
      type: "card",
      values: values,
    },
    {
      type: "card",
      values: values2,
    },
  ];

  useEffect(() => {
    let labels = [];
    let dt = [];
    let values = [];
    let values2 = [];
    switch (validationSystem) {
      case "primary":
        values = [
          {
            title: t("document_count"),
            value: data?.document_count,
            icon: <FileOutlined style={{ fontSize: 40 }} />,
          },
          {
            title: t("max_annotator_count"),
            value: data?.annotator_count,
            icon: <UsergroupAddOutlined style={{ fontSize: 40 }} />,
          },
          {
            title: t("validatior_count"),
            value: t("no_limit"),
            icon: <SafetyOutlined style={{ fontSize: 40 }} />,
          },
        ];
        data?.quotas?.map((item, index) => {
          labels.push(t("annotator") + " " + (index + 1));
          dt.push(item?.documents?.length);
        });
        break;
      case "grouping":
        values = [
          {
            title: t("document_count"),
            value: data?.document_count,
            icon: <FileOutlined style={{ fontSize: 40 }} />,
          },
          {
            title: t("group_count"),
            value: data?.group_count,
            icon: <GroupOutlined style={{ fontSize: 40 }} />,
          },
          {
            title: t("validatior_count"),
            value: data?.validator_count,
            icon: <SafetyOutlined style={{ fontSize: 40 }} />,
          },
        ];
        values2 = [
          {
            title: t("annotator_quota"),
            value: data?.annotator_quota,
            icon: <TagsOutlined style={{ fontSize: 40 }} />,
          },
          {
            title: t("validator_quota"),
            value: data?.validator_quota,
            icon: <FilterOutlined style={{ fontSize: 40 }} />,
          },
          {
            title: t("validator_per_group"),
            value: data?.validator_per_group,
            icon: <NumberOutlined style={{ fontSize: 40 }} />,
          },
        ];
        data?.groups?.map((item, index) => {
          labels.push(t("group") + " " + (index + 1));
          dt.push(item?.annotators?.length);
        });
        break;
      default:
        break;
    }
    setChart({
      labels: labels,
      datasets: [
        {
          label: t("annotator_quota"),
          data: dt,
          backgroundColor: [
            "#143c64",
            "#325977",
            "#4F7689",
            "#6C939C",
            "#89B0AE",
          ],
          borderWidth: 3,
          roundedCorners: true,
        },
      ],
    });
    setValues(values);
    setValues2(values2);
    setLoaded(true);
  }, [data]);

  return loaded ? (
    <Row style={{ height: "80%", margin: "1%" }}>
      {cardProps.map((item) => {
        return (item.type == "chart" && chart != undefined) ||
          (item.type == "card" && item.values.length > 0) ? (
          <Col
            style={{
              margin: "1%",
            }}
            span={
              item.span
                ? item.span
                : 16 /
                (cardProps.filter((x) => {
                  return x.values?.length > 0 || x.type == "chart";
                }).length -
                  1)
            }
          >
            {item.type == "card" ? (
              <div className="card">
                {item.values.map((value, index) => {
                  return (
                    <div key={index} className="card-header p-3 pt-2">
                      <div className="text-start pt-1">
                        <p className="text-sm mb-0">
                          <div
                            className="icon  icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {value.icon}
                            <h4 className="mb-0 weight">{value.value}</h4>
                          </div>
                          {value.title}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="card">{item.value}</div>
            )}
          </Col>
        ) : null;
      })}
    </Row>
  ) : (
    <div></div>
  );
};

TaskPreviewModal.propTypes = {
  data: PropType.object,
  taskType: PropType.string,
  validationSystem: PropType.string,
};
export default TaskPreviewModal;
