import {
    RATE_WORKER_BY_VALIDATOR
} from '../actionTypes/actionTypes'
 
// OWNER  START

// DASHBOARD QUICK LINKS PROJ LIST
export const giveRate = (payload) => {
    return {
        type: RATE_WORKER_BY_VALIDATOR ,
        payload
    } 
}  