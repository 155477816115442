import React, { useEffect, useState } from "react"



import images from "../../../themes/appImage";
import { HeaderLandingInner } from "../../../layout/LandingModule";
import { CustomFooter } from "../../../molecules";



const TermsCondition = () => {

    // var settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };
    useEffect(() => {
        document.title = 'WosoM';
        window.scrollTo(0, 0)

    }, [])

    const [toogled, setToogle] = useState(false);

    const handletoogle = () => {
        setToogle(!toogled)
    }

    const sticky = false;
    const isVisible = false;
    // const [loader, setLoader] = useState(false)


    // Show button when page is scorlled upto given distance
    // const toggleVisibility = () => {
    //     if (window.pageYOffset > 300) {
    //         setIsVisible(true);
    //     } else {
    //         setIsVisible(false);
    //     }
    // };

    // const addclass = () => {
    //     if (window.pageYOffset > 20) {
    //         setsticky(true);
    //     } else {
    //         setsticky(false);
    //     }
    // };

    // Set the top cordinate to 0
    // make scrolling smooth
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };


    return (
        <div className="landing-page">
            <HeaderLandingInner handle={handletoogle} toogled={toogled} sticky={sticky} />


            <section className="aboutus data_about_us privacy_details" >
                <div className="container">
                    <div className="row align-item-center">
                        <h2 className="heading  slideInUp wow" style={{ marginTop: '69px' }}><img src={images.leftimg12}></img>Terms and Conditions<img src={images.rightimg12} /></h2>
                        <div className="">
                            <div className="d-flex w-100 justify-content-center appss">
                                <div className="mobile_circle">
                                    <img src={images.tc1} />
                                </div>
                            </div>

                            <div className="text-center slideInDown home_trrr">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 ofFinibus Bonorum et  (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum,  comes from a line in section 1.10.32.</p>
                                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 fromFinibus Bonorum et by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 ofFinibus Bonorum et  (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum,  comes from a line in section 1.10.32.</p>


                                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 fromFinibus Bonorum et by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 year eory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum,  comes from a line in section 1.10.32.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <CustomFooter />

            {isVisible &&
                <div onClick={scrollToTop}>
                    <a href="#" className="scrollup"></a></div>}

        </div>
    )
}

export default TermsCondition