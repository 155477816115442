import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { SettingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
    saveAnnotations,
    saveValidations,
} from "../../../../../../../redux/actions/Annotations";
import { isInternetConnected } from "../../../../../../../themes/utils/internetConnectionn";
import parse from "html-react-parser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Radio, Divider, Modal, Input, Form } from "antd";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { NavigateTo } from "../../../../../../../themes";

import PropTypes from "prop-types";



const TextTranscript = ({ texts, workerType, }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();


    if (texts.length == 0) {
        navigate(NavigateTo.NOT_FOUND);
    }
    // check the latest annotation with a validation (validated = true)
    // and set the current index to that index
    const checkLatestAnnotation = () => {
        let tempIndex = 0;
        texts.map((item, index) => {
            if (workerType == "validator") {
                if (item?.jsonFile?.validation?.validated == true) {
                    tempIndex = index;
                }
            } else {
                if (item?.jsonFile?.length > 0 && item?.jsonFile?.[0].metadata[0]?.data?.note) {
                    tempIndex = index;
                }
            }
        });
        const index = tempIndex; //% 3 ;
        let paragraph = textUrls[index];
        setUrl(paragraph);
        let getAnnot = JSON.parse(localStorage.getItem("annotObj"))[index];
        if (getAnnot == null) {
            getAnnot = [{
                "text": textUrls[index],
            }]
        }

        updateAnnotation(getAnnot);
        setCurrentIndex(index);
        setCurrentIndex(tempIndex);
    };

    let [textUrls, _setTextUrls] = useState(texts.map((item) => item?.paragraph));
    const arabicRegex = /[\u0600-\u06FF]/;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [settingsClicked, setSettingsClicked] = useState(false);
    const [infoClicked, setInfoClicked] = useState(false);
    const [isArabic, setIsArabic] = useState(false);
    const [annotField, setannotField] = useState("");
    const [validField, setvalidField] = useState("valid");
    const [validatedField, setvalidatedField] = useState(false);
    const [url, setUrl] = useState(texts[0]?.paragraph);
    const [comment, setComment] = useState("");
    const [pageNumber, setPageNumber] = useState(currentIndex + 1);

    const editAnnotation = () => {
        let form = document.forms.edit;

        form.style.display = "block";
        if (texts[currentIndex]?.jsonFile) {
            let valid = texts[currentIndex]?.jsonFile?.validation?.is_valid;
            let validated = texts[currentIndex]?.jsonFile?.validation?.validated;
            let comment = texts[currentIndex]?.jsonFile?.validation?.comment;

            setvalidatedField(validated);
            if (validated == true) {
                setvalidField(valid ? "valid" : "invalid");
                setComment(comment ? comment : "");
            }
            let note = texts[currentIndex]?.jsonFile[0]?.metadata[0]?.data?.note;
            setannotField(note ? note : ""); //region.data.note,
        }
    };
    const updateAnnotation = (getAnnot) => {
        let valid = getAnnot?.validation?.is_valid;
        let validated = getAnnot?.validation?.validated;
        let comment = getAnnot?.validation?.comment;
        setvalidatedField(validated ? validated : false);
        if (validated == true) {
            setvalidField(valid ? "valid" : "invalid");
            setComment(comment ? comment : "");
        } else {
            setvalidField("valid");
            setComment("");
        }
        let note = getAnnot[0]?.text ? getAnnot[0]?.text : getAnnot[0]?.data?.note ? getAnnot[0]?.data?.note : url;
        setannotField(note); //region.data.note,
        // console.log(arabicRegex.test(note));
        setIsArabic(arabicRegex.test(note));
    };
    const saveRegions = (val) => {
        let tempRegions = [];
        if (annotField.trim().length == 0) {
            toast.error(t("no_ann"), {
                className:
                    localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
                position:
                    localStorage.lang == "ar"
                        ? toast.POSITION.TOP_LEFT
                        : toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (workerType == "validator") {
            if (!val) {
                val = comment;
            }
            let valid = validField == "valid" ? true : false;
            if (valid == false && val == "") {
                toast.error(t("plz_add_note"), {
                    className:
                        localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
                    position:
                        localStorage.lang == "ar"
                            ? toast.POSITION.TOP_LEFT
                            : toast.POSITION.TOP_RIGHT,
                });
                return false;
            }

            tempRegions.push({
                end: 0,
                start: Math.random(),
                attributes: {},
                data: { note: val, valid: valid },
                document_id: texts[currentIndex]?.media_id,
            });
        } else {
            tempRegions.push({
                end: 0,
                start: Math.random(),
                attributes: {},
                data: { note: annotField, valid: false },
                document_id: texts[currentIndex]?.media_id,
            });
        }


        const saveAnnotObj = localStorage.getItem(`annotObj`)
            ? {
                ...JSON.parse(localStorage.getItem(`annotObj`)),
                [currentIndex]: tempRegions,
            }
            : {
                [currentIndex]: tempRegions,
            };
        localStorage.setItem(`annotObj`, JSON.stringify(saveAnnotObj));

        const tempAnnotObj = {
            ...JSON.parse(localStorage.getItem("annotObjForApi")),
        };
        // tempAnnotObj[currentIndex].jsonFile = [...tempRegions];
        let tempObj = {
            "text": tempAnnotObj[currentIndex],
            "jsonFile": [...tempRegions],
            "media_id": texts[currentIndex]?.media_id,
        }
        localStorage.setItem("annotObjForApi", JSON.stringify(tempObj));
        return true;
        // );
    };
    const saveAnnotationForApi = (source) => {
        let done = saveRegions();
        if (!done) {
            return false;
        }
        const tempBody = JSON.parse(
            localStorage.getItem("annotObjForApi")
        );

        if (workerType == "validator") {
            let item = tempBody[currentIndex];
            let jsonFile =
                item.jsonFile != undefined
                    ? item.jsonFile.length
                        ? item.jsonFile[0]
                        : item.jsonFile
                    : {};
            let note = jsonFile?.data?.note;

            let valid = jsonFile?.data?.valid;

            let validation = {
                document_id: item?.media_id,
                metadata: item?.jsonFile ? item.jsonFile : [],
                annotation_valid: valid !== undefined ? valid : false,
                comment: note ? note : "",
            };

            const payloadToSaveValidation = {
                body: {
                    task_id: localStorage.getItem("workerPendingTaskId"),
                    validation: validation,
                },
                success: () => {
                    if (source == 'save')
                        toast.success(t("val_svd"), {
                            className:
                                localStorage.lang == "ar"
                                    ? "Select_data ar"
                                    : "Select_data en",
                            position:
                                localStorage.lang == "ar"
                                    ? toast.POSITION.TOP_LEFT
                                    : toast.POSITION.TOP_RIGHT,

                            toastId: "annotSaved",
                        });
                },

            };

            isInternetConnected() &&
                dispatch(saveValidations(payloadToSaveValidation));
        } else {
            let item = tempBody;
            let jsonFile =
                item.jsonFile != undefined
                    ? item.jsonFile.length
                        ? item.jsonFile[0]
                        : item.jsonFile
                    : {};
            let note = jsonFile?.data?.note;
            if (!note) {
                note = jsonFile?.text;
            }
            let annotation = {
                document_id: item.media_id,
                annotation: {
                    text: note ? note : "",
                    metadata: note ? item.jsonFile : [],
                },
            };

            const payloadToSaveAnnotation = {
                body: {
                    task_id: localStorage.getItem("workerPendingTaskId"),
                    annotation: annotation,
                },
                success: () => {
                    if (source == 'save')
                        toast.success(t("ann_svd"), {
                            className:
                                localStorage.lang == "ar"
                                    ? "Select_data ar"
                                    : "Select_data en",
                            position:
                                localStorage.lang == "ar"
                                    ? toast.POSITION.TOP_LEFT
                                    : toast.POSITION.TOP_RIGHT,

                            toastId: "annotSaved",
                        });
                },
            };

            isInternetConnected() &&
                dispatch(saveAnnotations(payloadToSaveAnnotation));
        }
    };
    const OnNextButtonClick = () => {
        const index = currentIndex + 1; //% 3 ;
        setUrl(textUrls[index]);
        let getAnnot = JSON.parse(localStorage.getItem("annotObj"))[index];
        if (getAnnot.length == 0) {
            getAnnot = [{
                "text": textUrls[index],
            }]
        }

        updateAnnotation(getAnnot);
        setCurrentIndex(index);
        saveAnnotationForApi("next");
    };
    const OnPageChange = (e) => {
        let index = e; //% 3 ;

        if (typeof index !== "number") {
            index = parseInt(index);
        }
        setCurrentIndex(index - 1);
        setUrl(textUrls[index]);
        // waveform.current.load(textUrls[index]);
        // setUrl(textUrls[index]);

        let getAnnot = JSON.parse(localStorage.getItem("annotObj"))[index];
        if (getAnnot.length == 0) {
            getAnnot = [{
                "text": textUrls[index],
            }]
        }

        updateAnnotation(getAnnot);
        setSettingsClicked(false);
        setInfoClicked(false);
    };

    const OnPrevButtonClick = () => {
        const index = currentIndex - 1; // % 3 ;
        setUrl(textUrls[index]);
        // waveform.current.load(textUrls[index]);
        // setUrl(textUrls[index]);

        let getAnnot = JSON.parse(localStorage.getItem("annotObj"))[index];
        if (getAnnot.length == 0) {
            getAnnot = [{
                "text": textUrls[index],
            }]
        }

        updateAnnotation(getAnnot);
        setCurrentIndex(index);
    };
    // FORMIK
    const validationSchema = Yup.object().shape({
        note: Yup.string().required(t("plz_add_note")),
    });
    const initialValues = {
        note: "",
    };
    const onSubmit = () => {
        saveRegions();
    };
    const formik = useFormik({
        onSubmit,
        initialValues,
        validationSchema,
    });
    function containsArabic(str) {
        return /[\u0600-\u06FF]/.test(str);
    }
    function fontSize(str) {
        if (str.length < 50) {
            return "32px";
        } else if (str.length < 100) {
            return "30px";
        } else if (str.length < 150) {
            return "28px";
        } else if (str.length < 200) {
            return "26px";
        } else {
            return "24px";
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        let tempAnnotObj = [];
        localStorage.removeItem("annotObj");
        localStorage.removeItem("annotObjForApi");
        localStorage.setItem("annotObjForApi", JSON.stringify(texts));

        texts.map((item) => {
            tempAnnotObj.push(item?.jsonFile);
        });
        localStorage.setItem("annotObj", JSON.stringify(tempAnnotObj)); //TODO: fix the call from pending to fill the paragraphs add the jsonFile to the paragraphs
        setIsArabic(arabicRegex.test(texts[0]));

        editAnnotation();
        checkLatestAnnotation(); // check the latest annotation with a validation (validated = true)
    }, []);

    return (
        <div
            style={{
                margin: 25,
                padding: 20,
                width: "95%",
                borderRadius: "10px",
                flexDirection: "column",
                backgroundColor: "rgb(239 243 254)",
                boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
            }}
        >
            {/*page number and settings and info */}
            <div
                style={{
                    top: "10.8pc",
                    left: "47.6pc",
                    color: "#1a3e67",
                    fontWeight: "800",
                    display: "flex",
                    justifyContent: "right",
                    marginTop: "14px",
                    flexDirection: localStorage.lang == "ar" ? "row-reverse" : "row",
                }}
            >
                <div
                    style={{
                        width: "40px",
                        height: "40px",
                        border: "0px",
                        display: "flex",
                        cursor: "pointer",
                        borderRadius: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px",
                        background: "rgb(31 71 118)",
                    }}
                    onClick={() => {
                        setInfoClicked(true);
                    }}
                >
                    <InfoCircleOutlined style={{ color: "#fff", fontSize: "20px" }} />
                </div>
                <div
                    style={{
                        width: "40px",
                        height: "40px",
                        border: "0px",
                        display: "flex",
                        cursor: "pointer",
                        borderRadius: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px",
                        background: "rgb(31 71 118)",
                    }}
                    onClick={() => {
                        setSettingsClicked(true);
                    }}
                >
                    <SettingOutlined style={{ color: "#fff", fontSize: "20px" }} />
                </div>
                <div
                    style={{
                        background: "rgb(31 71 118 / 16%)",
                        borderRadius: "10px",
                        padding: " 8px"
                    }}
                >
                    <span
                        style={{
                            fontWeight: "800",
                            fontSize: "16px",
                            color: "#1a3e67",
                            marginRight: "10px",
                            marginTop: "-2px",
                        }}
                    >{currentIndex + 1}</span> / {textUrls.length}
                </div>
                <button
                    style={{
                        marginLeft: "10px",
                        width: "60px",
                        height: "40px",
                        border: "0px",
                        display: "flex",
                        cursor: "pointer",
                        borderRadius: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px",
                        cursor: currentIndex > 0 ? "pointer" : "no-drop",
                        background: currentIndex > 0 ? "rgb(31 71 118)" : "#a6b3c1",
                    }}
                    title={t("prev")}
                    onClick={() => OnPrevButtonClick()}
                    disabled={currentIndex > 0 ? false : true}
                >
                    <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#fff", fontSize: "20px" }} />
                </button>
                <button
                    style={{
                        marginLeft: "1px",
                        width: "60px",
                        height: "40px",
                        border: "0px",
                        display: "flex",
                        cursor: "pointer",
                        borderRadius: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px",
                        background:
                            currentIndex < textUrls.length - 1 ? "rgb(31 71 118)" : "#a6b3c1",
                        cursor: currentIndex < textUrls.length - 1 ? "pointer" : "no-drop",
                    }}
                    disabled={currentIndex < textUrls.length - 1 ? false : true}
                    onClick={() => OnNextButtonClick()}
                >
                    <FontAwesomeIcon icon={faChevronRight} style={{ color: "#fff", fontSize: "20px" }} />
                </button>
                <Modal
                    title={t("settings")}
                    open={settingsClicked}
                    footer={[]}
                    onCancel={() => {
                        setSettingsClicked(false);
                    }}
                >
                    <label
                        htmlFor="page"
                        style={{
                            fontWeight: "500",
                            color: "rgb(20, 60, 100)",
                        }}
                    >
                        {t("select_page")}
                    </label>
                    <Form layout="inline">
                        <Input
                            placeholder={`${t("select_page")} (${currentIndex + 1} / ${textUrls.length
                                } )`}
                            type="number"
                            onChange={(e) => {
                                setPageNumber(e.target.value);
                            }}
                            name="page"
                            min={1}
                            max={textUrls.length}
                            size="large"
                            style={{
                                width: "80%",
                            }}
                        //value={currentIndex + 1}
                        />
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                border: "0px",
                                display: "flex",
                                cursor: "pointer",
                                borderRadius: "10px",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "rgb(31 71 118)",
                            }}
                            onClick={() => {
                                OnPageChange(pageNumber);
                            }}
                        >
                            <CheckOutlined style={{ color: "#fff", fontSize: "20px" }} />
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title={t("info")}
                    open={infoClicked}
                    footer={[]}
                    onCancel={() => {
                        setInfoClicked(false);
                    }}
                >
                    {/*  instructions  */}
                    <div className="form-group">
                        <label
                            htmlFor="end"
                            style={{
                                fontWeight: "500",
                                color: "rgb(20, 60, 100)",
                                fontFamily: 'font-family: "Montserrat", sans-serif',
                            }}
                        >
                            {t("instructions")}
                        </label>
                        <div
                            className="get-original-text-color"
                            style={{
                                marginTop: "12px",
                                padding: "20px",
                            }}
                        >
                            <div className="ck-content">
                                {parse(localStorage.getItem("instruction"))}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            {/*page number and settings and info end */}
            {/* Text start */}
            <div className="flex_udt mt-2">
                <div className="paragraphs_udt">
                    <p
                        style={{
                            fontSize: fontSize(url),
                            textAlign:
                                containsArabic(url) ? "right" : "left",
                            color: "#2b5483",
                            padding: "10px 21px",
                            // fontWeight: "600",
                        }}
                    >{url}</p>
                </div>
            </div>
            {/* Text end */}
            {/* start,end,note form fields, save and delete buttons */}
            <form
                // role="form"
                name="edit"
                style={{
                    // display: "none",
                    padding: "20px",
                    margin: "30px 0",
                    borderRadius: "10px",
                    transition: "opacity 300ms linear",
                    backgroundColor: "rgb(239 243 254)",
                    boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
                }}
                onSubmit={formik.handleSubmit}
                noValidate
            >

                {/*  transcriptions  */}
                {workerType == "annotator" && (
                    <div className="form-group">
                        <label
                            htmlFor="note"
                            style={{
                                fontWeight: "500",
                                color: "rgb(20, 60, 100)",
                                fontFamily: 'font-family: "Montserrat", sans-serif',
                            }}
                        >
                            {t("transcription")}
                        </label>
                        <textarea
                            id="note"
                            rows="3"
                            name="note"
                            style={{
                                marginTop: "11px",
                            }}
                            dir={isArabic ? "rtl" : "ltr"}
                            disabled={validatedField == true && validField == "valid"}
                            className={`form-control ${isArabic ? "ar" : ""}`}
                            value={annotField}
                            onChange={(e) => {
                                // if(e.target.value.trimLeft()){
                                setannotField(e.target.value);
                                // }
                            }}
                            placeholder={t("edit_transcription")}
                        ></textarea>
                        {validatedField == true && (
                            <div className="form-group">
                                <label
                                    htmlFor="note"
                                    style={{
                                        fontWeight: "500",
                                        color: "rgb(20, 60, 100)",
                                        fontFamily: 'font-family: "Montserrat", sans-serif',
                                    }}
                                >
                                    {t("validation")}
                                </label>

                                <h4
                                    style={{
                                        marginTop: "12px",
                                        padding: "20px",
                                        backgroundColor: "rgb(239 243 254)",
                                        borderRadius: "10px",
                                        boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
                                    }}
                                >
                                    {validField == "valid"
                                        ? t("valid")
                                        : `${t("invalid")} (${comment})`}
                                </h4>
                            </div>
                        )}
                    </div>
                )}
                {/*  transcriptions  */}
                {/*  validator  */}
                {workerType == "validator" && (
                    <div>
                        {annotField.trim().length > 0 ? (
                            <>
                                <div className="form-group">
                                    <label
                                        htmlFor="note"
                                        style={{
                                            fontWeight: "500",
                                            color: "rgb(20, 60, 100)",
                                            fontFamily: 'font-family: "Montserrat", sans-serif',
                                        }}
                                    >
                                        {t("transcription")}
                                    </label>

                                    <h4
                                        style={{
                                            marginTop: "12px",
                                            padding: "20px",
                                            backgroundColor: "rgb(239 243 254)",
                                            borderRadius: "10px",
                                            boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
                                        }}
                                    >
                                        {annotField}
                                    </h4>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor="note"
                                        style={{
                                            fontWeight: "500",
                                            color: "rgb(20, 60, 100)",
                                            fontFamily: 'font-family: "Montserrat", sans-serif',
                                        }}
                                    >
                                        {t("validation")}
                                    </label>
                                    <Divider orientation="left" plain></Divider>
                                    <Radio.Group
                                        value={validField}
                                        onChange={(e) => {
                                            setvalidField(e.target.value);
                                            // saveRegions(comment, e.target.value == "valid");
                                        }}
                                    >
                                        <Radio.Button
                                            value="valid"
                                            style={{
                                                width: "70px",
                                                border: "0px",
                                                color: "white",
                                                fontWeight: 500,
                                                fontSize: "20px",
                                                borderRadius: "10px",
                                                marginRight: "10px",
                                                // position: "absolute",
                                                background:
                                                    validField == "valid" ? "#1c426d" : "#a6b3c1",
                                            }}
                                        >
                                            <CheckOutlined />
                                        </Radio.Button>
                                        <Radio.Button
                                            value="invalid"
                                            style={{
                                                width: "70px",
                                                border: "0px",
                                                color: "white",
                                                fontWeight: 500,
                                                fontSize: "20px",
                                                borderRadius: "10px",
                                                background:
                                                    validField != "valid" ? "#1c426d" : "#a6b3c1",
                                            }}
                                        >
                                            <CloseOutlined />
                                        </Radio.Button>
                                    </Radio.Group>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor="comment"
                                        style={{
                                            fontWeight: "500",
                                            color: "rgb(20, 60, 100)",
                                            fontFamily: 'font-family: "Montserrat", sans-serif',
                                        }}
                                    >
                                        {t("comment")}
                                    </label>
                                    <input
                                        id="comment"
                                        rows="3"
                                        name="comment"
                                        style={{
                                            marginTop: "12px",
                                        }}
                                        className="form-control"
                                        onChange={(e) => {
                                            setComment(e.target.value.trim());
                                            //saveRegions(e.target.value.trim(), valid == "valid");
                                        }}
                                        value={comment}
                                        placeholder={t("add_comment")}
                                    ></input>
                                </div>
                            </>
                        ) : (
                            <div className="form-group">
                                <label
                                    htmlFor="note"
                                    style={{
                                        fontWeight: "500",
                                        color: "rgb(20, 60, 100)",
                                        fontFamily: 'font-family: "Montserrat", sans-serif',
                                    }}
                                >
                                    {t("transcription")}
                                </label>

                                <h4
                                    style={{
                                        marginTop: "12px",
                                        padding: "20px",
                                        backgroundColor: "rgb(239 243 254)",
                                        borderRadius: "10px",
                                        boxShadow: "0px 0px 14px rgb(20 60 100 / 12%)",
                                    }}
                                >
                                    {t("no_transcription")}
                                </h4>
                            </div>
                        )}
                    </div>
                )}
                {/*  validator  */}
                {/* prev , save, next buttons start */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "center",
                    }}
                >
                    {/* save   button */}
                    <div
                        style={{
                            top: "7.2pc",
                            left: "44.6pc",
                            marginInline: "20px",
                        }}
                    >
                        <button
                            type="button"
                            disabled={
                                workerType != "validator" && annotField.trim().length == 0
                                    ? true
                                    : false
                            }
                            style={{
                                cursor:
                                    workerType != "validator" && annotField.trim().length == 0
                                        ? "no-drop"
                                        : "pointer",
                                background:
                                    workerType != "validator" && annotField.trim().length == 0
                                        ? "#a6b3c1"
                                        : "#1c426d",
                            }}
                            className={"button-list bottom_button pt-10 focus"}
                            onClick={() => saveAnnotationForApi("save")}
                        >
                            {t("save")}
                        </button>
                    </div>
                </div>
                {/* prev , save, next buttons end */}
            </form>
            {/* start,end,note form field, save and delete buttons */}

        </div >
    );
};


TextTranscript.propTypes = {
    texts: PropTypes.array,
    textUrl: PropTypes.array,
    workerType: PropTypes.string,
    media_id: PropTypes.number,
}

export default TextTranscript;
