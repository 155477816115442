import { Modal } from "antd";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import ReactImageAnnotate from "../../../../../../libs/react-image-annotate";
import React, { useCallback, useEffect, useState } from "react";
import { saveAnnotations } from "../../../../../../redux/actions/Annotations";
import { isInternetConnected } from "../../../../../../themes/utils/internetConnectionn";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const ImageAnnotate = (props) => {
  const { apiJsonImage, classLists, images, imageIndex, task_id } = { ...props };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const taskInstruction = localStorage.getItem("instruction");
  const [isModalRemove, setModalRemove] = useState(false);
  const [hide_Next, setHideNext] = useState(false); // itr = image to render
  const [itr, setItr] = useState(images[imageIndex].src); // itr = image to render
  const [hide_Prev, setHidePrev] = useState(imageIndex == 0 ? true : false); // itr = image to render
  const enabledTools =
    localStorage.getItem("annotType") == "bbox"
      ? ["create-box"]
      : ["create-polygon"];
  const onExitImage = useCallback((output) => {
    const tempJson = [];
    let no_diffClass = true;
    let regionsCount = 0; // send this
    let classAdded = true; // send this

    output?.images.map((item, index) => {
      if (item?.regions.length != 0) {
        regionsCount++;

        item?.regions?.map((item) => {
          if (!item?.cls) {
            classAdded = false;
            return;
          }
        });
      }
      tempJson.push({
        jsonFile: item,
        task_id: task_id,
        anotation_id: apiJsonImage[index].anotation_id,
        worker_id: apiJsonImage[index].worker_id,
        media_id: apiJsonImage[index].media_id,
      });
    });

    let isCompleted = regionsCount == images.length ? true : false;

    tempJson.map((item) => {
      item["media_type"] = 1;
      item["is_completed"] = isCompleted;
    });
    let regions = output?.images[imageIndex]?.regions;
    if (regions.length == 0) {
      toast.error(t("create_annotations_first"), {

        className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

      })
      return;
    }
    let annotation = {
      document_id: apiJsonImage[imageIndex].media_id,
      annotation: regions
    };
    const payloadToSaveAnnotation = {
      body: {
        task_id: localStorage.getItem("workerPendingTaskId"),
        annotation: annotation,
      },
      success: () => {
        localStorage.removeItem("imageIndex");
        toast.success(t("ann_svd"), {

          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

          toastId: "annotSaved",
        });
      },

    };
    classAdded && no_diffClass
      ? isInternetConnected() &&
      dispatch(saveAnnotations(payloadToSaveAnnotation))
      : !classAdded
        ? toast.error(t("add_class_to_annots"), {

          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

        })
        : toast.error(t("dont_add_new_clss"), {

          className: localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
          position: localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

        });
  }, []); //  onExitImage
  const onNextImagee = useCallback((output) => {
    if (images.length > output?.lastAction?.imageIndex + 1) {
      setHidePrev(false);
      setItr(images[output?.lastAction?.imageIndex + 1]?.src);
      localStorage.setItem("imageIndex", output?.lastAction?.imageIndex + 1);
    }
    if (images.length == output?.lastAction?.imageIndex + 2) {
      setHideNext(true);
    }
  }, []); //  onNextImagee
  const onPrevImage = useCallback((output) => {
    if (output?.lastAction?.imageIndex - 1 == 0) setHidePrev(true);
    // if (output?.lastAction?.imageIndex - 1 == 0) {
    //   setTimeout(() => {
    //     const cloneButton = document.querySelector(
    //       "button.MuiButton-root.css-1e6y48t-MuiButtonBase-root-MuiButton-root:nth-child(3)"
    //     );
    //     cloneButton.setAttribute("style", "display:none !important ;");
    //   }, 50);
    // }

    setHideNext(false);

    setItr(images[output?.lastAction?.imageIndex - 1]?.src);
    localStorage.setItem("imageIndex", output?.lastAction?.imageIndex - 1);
  }, []); // onPrevImage

  useEffect(() => {
    localStorage.setItem("imageIndex", 0);

    document.querySelectorAll(
      ".css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root"
    )[1].style.display = "none";

    document.querySelectorAll(
      ".css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root"
    )[1].style.display = "none";
    if (!document.getElementById("exclam")) {
      const infoButton = document.createElement("button");

      infoButton.setAttribute(
        "class",
        "MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButtonBase-root ForwardRef(Button)-root-31 ForwardRef(Button)-root-33 css-1e6y48t-MuiButtonBase-root-MuiButton-root"
      );

      infoButton.setAttribute("tabindex", "0");
      infoButton.setAttribute("type", "button");
      infoButton.setAttribute("id", "exclam");
      infoButton.setAttribute(
        "style",
        "margin-left: -20px;margin-right: 0px;margin-top:9px;"
      );
      function clicked() {
        setModalRemove(true);
      }
      infoButton.addEventListener("click", clicked);

      const divParent = document.createElement("div");
      divParent.setAttribute("style", "");

      const divChild1 = document.createElement("div");

      divChild1.setAttribute(
        "style",
        "color:#193d64;font-size: 16px;padding-bottom: 18px;"
      );
      divChild1.innerText = "!";

      const divChild2 = document.createElement("div");
      divChild2.setAttribute(
        "style",
        "color:#193d64;font-size: 11px;font-weight: 600;"
      );
      divChild2.innerText = "Info";

      divParent.appendChild(divChild1);
      divParent.appendChild(divChild2);
      infoButton.appendChild(divParent);

      const getButtonParents = document.querySelectorAll(
        ".div-root-1>div:first-child"
      );
      getButtonParents[0].appendChild(infoButton);
      const getNextEleParent = document.querySelectorAll(
        ".udt-annotate.keep-save-button button.MuiButton-root:nth-child(3)>div>div:last-child"
      );
      getNextEleParent[0].setAttribute("class", "div-root-30");
    }

    return () => {
      localStorage.setItem('__REACT_WORKSPACE_LAYOUT_EXPANDED', false);
    }
  }, []); //  useEffect


  return (
    <>
      <ReactImageAnnotate
        labelImages
        editing={false}
        images={images}
        showTags={true}
        // allowComments
        selectedImage={itr}
        onExit={onExitImage}
        hideNext={hide_Next}
        hidePrev={hide_Prev}
        onPrevImage={onPrevImage}
        onNextImage={onNextImagee}
        regionClsList={classLists}
        enabledTools={enabledTools}
      />
      {/* info modal */}
      <Modal
        centered
        footer={null}
        okType={false}
        title={t("instructions")}
        closable={true}
        onCancel={() => setModalRemove(false)}
        open={isModalRemove}
      >
        <div className="wrapper_line">
          <div
            className="get-original-text-color"
            style={{
              marginTop: "12px",
            }}
          >
            <div className="ck-content">{parse(taskInstruction)}</div>
          </div>
        </div>

        {/* close button */}
        <div className="text-center  model1">
          <button
            type="button"
            style={{
              cursor: "pointer",
            }}
            className="focus"
            onClick={() => setModalRemove(false)}
          >
            {"Close"}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ImageAnnotate;
