import Fetch from "../Fetch/DashboardApi";
import { put, call, delay } from "redux-saga/effects";
import { startLoading, stopLoading } from "../actions/Common";
import { setOwnerList } from "../actions/Owner";
// import { setUserList } from "../actions/User";
import { AppImage } from "../../themes";
import appconstant from "../../themes/appconstant";
// import i18n from "i18next";

export function* getUserDashboardSaga({
  payload: { success = () => { }, fail = () => { } } = {},
}) {
  try {
    let result;
    yield put(startLoading());
    result = yield call(Fetch.userDashboardWorkerApi);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getOwnerDashboardSaga({
  payload: { success = () => { }, fail = () => { } } = {},
}) {
  try {
    let result;
    yield put(startLoading());
    result = yield call(Fetch.ownerDashboardApi);

    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getAdminDashboardSaga({
  payload: { body, success = () => { }, fail = () => { } } = {},
}) {
  try {
    let result;
    yield put(startLoading());
    if (body.dashType == "quicklinks") {
      result = yield call(Fetch.adminDashboarQuickLinksdApi, body);
    } else {
      result = yield call(Fetch.adminDashboarStatisticsdApi, body);
    }
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else {
      yield put(stopLoading());
      fail(result?.result);
    }
  } catch (error) {
    yield put(stopLoading());
    fail(error);
  }
}
export function* getOwnerListAdminSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield delay(500);
    yield put(startLoading());
    const result = yield call(Fetch.getOwnerListAdminApi, body);
    if (result.status === 1) {
      success(result?.result);

      let tempOwnerList = [];
      result?.result?.results.map((item, index) => {
        tempOwnerList.push({
          key: index,
          id: item?.id,
          profile: item?.image
            ? item?.image.startsWith("h")
              ? item?.image
              : item?.image.includes("static")
                ? AppImage.defaultImage
                : appconstant.imageBaseUrl + item?.image
            : AppImage.defaultImage,
          name: item?.first_name + " " + item?.last_name,
          email: item?.email,
          Contact: `${item?.country_code + item?.phone_no}`,
          isBlocked: item?.is_blocked,
          total: result?.result?.count,
        });
      });
      yield put(setOwnerList(tempOwnerList));
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getUserListAdminSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getUserListAdminApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* impersonateUserSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.impersonateUserApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getProjCreatedAdminSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getProjCreatedAdminApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getProjCompletedAdminSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getProjCompletedAdminApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getImagesAdminSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getImagesAdminApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
export function* getAnnotationAdminSaga({
  payload: { success = () => { }, fail = () => { }, body } = {},
}) {
  try {
    yield put(startLoading());
    const result = yield call(Fetch.getAnnotationAdminApi, body);
    if (result.status === 1) {
      success(result?.result);
      yield put(stopLoading());
    } else if (result.status === 4) {
      fail(result);
      yield put(stopLoading());
    } else {
      fail(result);
      yield put(stopLoading());
    }
  } catch (error) {
    fail(error);
    yield put(stopLoading());
  }
}
