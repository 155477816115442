import { Link } from "react-router-dom";
import { ContainerAdmin } from "../../../hoc";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { CustomEmptyDiv } from "../../../molecules";
import { AppImage } from "../../../themes";
import {
  EyeOutlined,
  MoreOutlined
} from "@ant-design/icons";
import { TextField } from "../../../molecules/Admin";

import { useDispatch } from "react-redux";
import { Modal, Pagination, Table, Tooltip, Dropdown, Rate } from "antd";
import { userDelete } from "../../../redux/actions/User";
import { NavigateTo, SidebarMenus } from "../../../themes";
import { blockUnblockOwner } from "../../../redux/actions/Owner";
import { getUserListAdmin, impersonateUser } from "../../../redux/actions/Dashboard";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { useNavigate } from "react-router";
const UserManagement = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ordering, setOrdering] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [pageNum, setPageNum] = useState(1);
  // const [userId, setUserId] = useState("");
  const [userFieldlabels, setUserFieldlabels] = useState([]);

  const [userskillDataSource, setUserskillDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [addedUserList, setAddedUserList] = useState([]);
  const [isModalVisibleView, setIsModalVisibleView] = useState(false); // delete modal
  const [isModalVisible, setIsModalVisible] = useState(false); // delete modal
  const [isModalVisibleBlock, setIsModalVisibleBlock] = useState(false); // block modal
  const [blockUnblockLabel, setBlockUnblockLabel] = useState(""); // delete modal
  const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
    whiteSpace: "nowrap",
  };
  const rightFontStyle = {
    marginRight: 10,
    display: "inline",
    marginLeft: 10,
    color: "#316293",
    fontWeight: 500,
    wordBreak: "break-word",
  };
  const breadCrumbItems = [
    {
      link: NavigateTo.ADMIN_DASH_BOARD,
      label: t("dashboard"),
    },
    {
      link: "",
      label: t("user_mngmnt"),
    },
  ];
  const skillCol = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 250,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      width: 250,
      sorter: false,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("experience"),
      dataIndex: "experience",
      key: "experience",
      width: 250,
      sorter: false,
    },
  ];
  const userMenuItems = [
    // edit button
    {
      key: '1',
      label: (
        <Link to={NavigateTo.ADMIN_EDIT_DETAILS}>
          <button
            type="submit"
            className="btn focus"
          >
            {t("edit")}
          </button>
        </Link>
      ),
    },

    // block button
    {
      key: '3',
      label: (
        <button
          type="submit"
          className="btn focus"
          onClick={() => {
            setIsModalVisibleBlock(true);
          }}
        >
          {blockUnblockLabel}
        </button>
      ),
    },
    // delete button
    {
      key: '2',
      label: (
        <button
          type="submit"
          className="btn focus"
          // onClick={() => {
          //   setIsModalVisible(true);
          // }}
          style={{
            cursor: "not-allowed",
          }}
          disabled
        >
          {t("delete")}
        </button>
      ),
    },
    // impersonate button
    {
      key: '4',
      label: (
        <button
          type="submit"
          className="btn focus"
          onClick={() => {
            const impoersonatePayload = {
              body: {
                email: localStorage.getItem("userEmail"),
              },
              success: (res) => {

                localStorage.setItem("lang", "en");
                const userType = res?.user_type;

                localStorage.setItem(
                  "tokenCheckForRouting",
                  res?.access_token
                );

                if (res?.access_token) {
                  localStorage.setItem("goToDashboard", "true");
                  localStorage.setItem("userType", userType);
                  localStorage.setItem("isRegistration", false);
                  localStorage.setItem('tokenCheckForRouting', res?.access_token); // check for routing without otp verification
                  localStorage.setItem("token", res?.access_token);
                  localStorage.setItem("user_type", res?.user_type);
                  localStorage.setItem("user", res?.email);
                  localStorage.setItem("fullName", res?.full_name); // DO IT HERE
                  if (res?.image) {
                    localStorage.setItem("userImage", res?.image);
                  } else {
                    localStorage.setItem("userImage", "");
                  }
                }
                switch (
                userType // use userType to check navigate accordingly
                ) {
                  case 'admin': {
                    navigate(NavigateTo.ADMIN_DASH_BOARD);
                    break;
                  }
                  case 'owner':
                    res?.access_token ? navigate(NavigateTo.OWNER_DASHBOARD) : navigate(NavigateTo.OTP);
                    break;
                  case 'performer':
                    res?.access_token ? navigate(NavigateTo.USER_DASHBOARD) : navigate(NavigateTo.OTP);
                    break;
                }
              }
            };
            isInternetConnected() && dispatch(impersonateUser(impoersonatePayload));
          }}
        >
          {t("impersonate")}
        </button>
      ),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("admin"),
    active: t("user_mngmnt"),
  };
  const columns = [
    {
      title: t("name"),
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
      width: 300,
      render: (val, record) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  marginRight: "12px",
                }}
                src={
                  !record?.is_blocked
                    ? AppImage.completedTask
                    : AppImage.pendingTask
                }
              />
              <div
                style={{
                  border: "2px solid #193d64",
                  borderRadius: "50px",
                  width: " 39px",
                  height: " 39px",
                  cursor: "pointer",
                }}
              >
                <img style={{
                  borderRadius: "35px",
                  width: " 36px",
                  height: " 36px",
                  marginRight: "12px",
                }}
                  src={record?.profile} /> {record?.first_name + " " + record?.last_name}
              </div>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t("email_address"),
      dataIndex: "email",
      key: "email",
      sorter: true,
      width: 150,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },
    {
      title: t("user_type"),
      dataIndex: "user_type",
      key: "user_type",
      sorter: true,
      width: 75,
      render: (val) => {
        return t(val);
      },
    },
    {
      title: t("contact_num"),
      dataIndex: "phone",
      key: "phone",
      width: 150,
      sorter: true,
      render: (val) => {
        return (
          <Tooltip
            title={val}
            placement={
              localStorage.getItem("lang") == "ar" ? "topRight" : "topLeft"
            }
          >
            {val}
          </Tooltip>
        );
      },
    },

    {
      title: t("action"),
      dataIndex: "Posted",
      key: "Posted",
      width: 150,
      // width: "130%",
      render: (val, record) => {
        return (
          <div className="tex-center_">
            <button
              type="submit"
              className="focus"
              onClick={() => {
                setUserFieldlabels([
                  {
                    labelTitle: record?.first_name,
                    labelHeader: t("first_name"),
                  },
                  {
                    labelTitle: record?.last_name,
                    labelHeader: t("last_name"),
                  },
                  {
                    labelTitle: record?.email,
                    labelHeader: t("email_address"),
                  },
                  {
                    labelTitle: t(record?.user_type),
                    labelHeader: t("user_type"),
                  },
                  {
                    labelTitle: record?.phone,
                    labelHeader: t("contact_num"),
                  },
                  {
                    labelTitle: record?.availability,
                    labelHeader: t("availability"),
                  },
                  {
                    labelTitle: t(record?.education),
                    labelHeader: t("education"),
                  },
                  {
                    labelTitle: <span>
                      <Rate
                        style={{
                          fontSize: "20px",
                        }}
                        className={
                          localStorage.lang == "ar"
                            ? "edit-group-star admin-user-details-star ar"
                            : "edit-group-star admin-user-details-star en"
                        }
                        allowHalf
                        value={record?.rating}
                        disabled={true}
                      />
                    </span>,
                    labelHeader: t("rating"),
                  },
                  {
                    labelTitle: record?.languages.map((item) => {
                      return `${item?.name} (${item?.native})`;
                    }).join(", "),
                    labelHeader: t("lang_spoken"),
                  }
                ])
                setUserskillDataSource(record?.skills);
                setIsModalVisibleView(true);
              }}
            >
              <EyeOutlined />  {t("view")}
            </button>
            <Dropdown
              trigger={"click"} menu={{ items: userMenuItems }} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
              <button
                className="overflow-menu"
                onClick={() => {
                  localStorage.setItem("userId", record?.id);
                  localStorage.setItem("userEmail", record?.email);
                  if (record?.is_blocked) {
                    setBlockUnblockLabel(t("unblock"));
                  } else {
                    setBlockUnblockLabel(t("block"));
                  }
                }}
              >
                <MoreOutlined style={{
                  color: "#193d64",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                /></button>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  const payloadUserList = {
    body: {
      pageNum: searchValue && searchValue != "" ? 1 : pageNum,
      ordering,
      searchValue,
    },
    success: (result) => {
      let tempUserList = [];
      result?.data?.map((item, index) => {
        tempUserList.push({
          key: index,
          id: item?.id,
          profile: item?.image
            ? item?.image.startsWith("h")
              ? item?.image
              : item?.image.includes("static")
                ? AppImage.defaultImage
                : appconstant.imageBaseUrl + item?.image
            : AppImage.defaultImage,
          first_name: item?.first_name,
          last_name: item?.last_name,
          user_type: item?.user_type,
          email: item?.email,
          phone: `${item?.country_code} ${item?.phone_number}`,
          availability: item?.availability
            ? item?.availability
            : `20 ${t("hours")}`,
          is_blocked: item?.is_blocked,
          languages: item?.languages,
          education: item?.education_level,
          rating: item?.rating,
          skills: item?.skills,
        });
      });
      setTotal(result?.total);
      setAddedUserList(tempUserList);
    }
  };

  useEffect(() => {
    document.title = "WosoM";
    isInternetConnected() && dispatch(getUserListAdmin(payloadUserList));
  }, []);

  useEffect(() => {
    isInternetConnected() && dispatch(getUserListAdmin(payloadUserList));
  }, [pageNum, ordering, searchValue]);

  useEffect(() => {
    const modalPopUpCrossButton = document.querySelectorAll(
      'button[aria-label="Close"]'
    );
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "unset";
        modalPopUpCrossButton[i].style.left = "0";
      }
    }
    if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
      for (let i = 0; i < modalPopUpCrossButton.length; i++) {
        modalPopUpCrossButton[i].style.right = "0";
        modalPopUpCrossButton[i].style.left = "unset";
      }
    }
  }, [isModalVisibleBlock, isModalVisible]);

  return (
    <ContainerAdmin
      {...containerProps}
      layoutClassName={
        localStorage.getItem("lang") == "ar" ? "new_layout ar" : "new_layout en"
      }
    >
      {/* button and search bar */}
      <div className="top_button_item d-flex justify-content-between">
        <div className="button_left contain">
          <button
            type="submit"
            style={{ visibility: "hidden" }}
            className="button-list top_new"
          >
            {" "}
          </button>
          <input
            type="text"
            className="search-bar"
            placeholder={t("search")}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            autoComplete={"off"}
          />
        </div>
      </div>
      {/* button and search bar */}

      {/* user Management */}
      {/* {
        dataSource.length
        ?  */}
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360, paddingTop: "0px" }}
      >
        <div className="content-e owner_content">
          {/* table header */}
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {t("user_mngmnt")}
            </h6>
          </div>
          {addedUserList.length != 0 ? (
            <>
              <Table
                dataSource={addedUserList}
                columns={columns}
                showSorterTooltip={false}
                pagination={false}
                onChange={(pagination, filters, sorter) => {
                  // profile,name^,email^,Contact^,availability^
                  if (sorter.order == undefined) {
                    setOrdering("");
                  }
                  if (sorter.order == "ascend" && sorter.columnKey == "name") {
                    setOrdering("first_name");
                  }
                  if (sorter.order == "descend" && sorter.columnKey == "name") {
                    setOrdering("-first_name");
                  }
                  if (sorter.order == "ascend" && sorter.columnKey == "email") {
                    setOrdering("email");
                  }
                  if (
                    sorter.order == "descend" &&
                    sorter.columnKey == "email"
                  ) {
                    setOrdering("-email");
                  }
                  if (
                    sorter.order == "ascend" &&
                    sorter.columnKey == "Contact"
                  ) {
                    setOrdering("phone_no");
                  }
                  if (
                    sorter.order == "descend" &&
                    sorter.columnKey == "Contact"
                  ) {
                    setOrdering("-phone_no");
                  }
                  if (
                    sorter.order == "ascend" &&
                    sorter.columnKey == "availability"
                  ) {
                    setOrdering("availability");
                  }
                  if (
                    sorter.order == "descend" &&
                    sorter.columnKey == "availability"
                  ) {
                    setOrdering("-availability");
                  }
                }}
              />
              <Pagination
                showTitle={false}
                style={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  maxWidth: "fit-content",
                  right: "33px",
                  left: "auto",
                  position: "absolute",
                }}
                className="pagination-component"
                defaultCurrent={1}
                total={total} //
                size="medium"
                onChange={(val) => {
                  setPageNum(val);
                }}
              />
            </>
          ) : (
            <CustomEmptyDiv title={t("added_users_appear_here")} />
          )}
        </div>
      </div>

      {/* user Management */}
      {/* view modal */}
      <Modal
        title={t("view_user")}
        open={isModalVisibleView}
        onOk={() => setIsModalVisibleView(false)}
        onCancel={() => setIsModalVisibleView(false)}
        footer={null}
        className="success_data delete"
        centered
        width={localStorage.getItem("lang") == "ar" ? "70%" : "70%"}
      >
        <div className="wrapper_line scroll">
          {/* pageDescription */}
          {userFieldlabels.map((item, index) => {
            const LabelStyle = localStorage.getItem("lang") == "ar" ?
              rightFontStyle : leftFontstyle;
            const HeaderStyle = localStorage.getItem("lang") == "ar" ?
              leftFontstyle : rightFontStyle;
            return (
              <>
                <TextField
                  key={index}
                  wrapperClassName={"admin-projdetails admin-user-details"}
                  customLabelStyle={LabelStyle}
                  customHeaderStyle={HeaderStyle}
                  {...item}
                />
              </>
            );
          })}
          {userskillDataSource?.length ? (
            <>
              <label style={leftFontstyle}>{t("skills")}</label>
              <Table
                pagination={true}
                columns={skillCol}
                showSorterTooltip={false}
                dataSource={userskillDataSource}
                className="udt worker-completed-taskdetails-annot val-pending-annot-pop-table user-deatils-admin"
              />
            </>
          ) : (
            <CustomEmptyDiv title={t("wrkr_skills_appear_here")} />
          )}
          {/* pageDescription */}
        </div>
      </Modal>
      {/* delete modal */}
      <Modal
        title={t("delete")}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className="success_data delete"
        centered
      >
        <div className="text-center  model1">
          <p>{t("u_sure_to_smthng_user", { action: t("delete") })}</p>
        </div>
        <div
          className="text-center  model1"
          style={{
            direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
          }}
        >
          <button
            type="button"
            className="button-list top_new focus"
            onClick={() => {
              const deletePayload = {
                // id: userId,
              };
              isInternetConnected() && dispatch(userDelete(deletePayload));
              isInternetConnected() &&
                dispatch(getUserListAdmin(payloadUserList));
              setIsModalVisible(false);
            }}
          >
            {t("yes")}
          </button>
          <button
            type="button"
            className="focus"
            onClick={() => setIsModalVisible(false)}
          >
            {t("no")}
          </button>
        </div>
      </Modal>

      {/* block modal */}
      <Modal
        title={blockUnblockLabel == t("block") ? t("block") : t("unblock")}
        open={isModalVisibleBlock}
        onOk={() => setIsModalVisibleBlock(false)}
        onCancel={() => setIsModalVisibleBlock(false)}
        footer={null}
        className="success_data delete"
        centered
      >
        <div className="text-center  model1">
          <p>
            {blockUnblockLabel == t("block")
              ? t("u_sure_to_smthng_user", { action: t("block") })
              : t("u_sure_to_smthng_user", { action: t("unblock") })}
          </p>
        </div>
        <div
          className="text-center  model1"
          style={{
            direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
          }}
        >
          <button
            type="submit"
            className="button-list top_new focus"
            onClick={() => {
              const deletePayload = {
                user_id: localStorage.getItem("userId"),
                is_blocked: blockUnblockLabel == t("block") ? true : false, // block 1 unblock  0
              };
              isInternetConnected() &&
                dispatch(blockUnblockOwner(deletePayload));
              isInternetConnected() &&
                dispatch(getUserListAdmin(payloadUserList));
              setIsModalVisibleBlock(false);
            }}
          >
            {t("yes")}
          </button>
          <button
            type="submit"
            className="focus"
            onClick={() => {
              setIsModalVisibleBlock(false);
            }}
          >
            {t("no")}
          </button>
        </div>
      </Modal>
    </ContainerAdmin>
  );
};

export default UserManagement;
