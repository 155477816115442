import { LOGOUT, USER_CHANGE_PASSWORD_ERROR, USER_CHANGE_PASSWORD_LOAD, USER_CHANGE_PASSWORD_SUCCESS } from "./../../actionTypes/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    status: 0,
    data: {}
}

const changePassReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT :
            return {
                ...state,
                isLoading: false,
                error: null,
                status: 0,
                data: {}

            };
        case USER_CHANGE_PASSWORD_LOAD:
            return {
                ...state
            }
        case USER_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                result: action.result,
                data: action.result.data,
                isLoading: false,
                status: action.status
            }


        case USER_CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                isLoading: false
            }

        default:
            return {
                ...state
            }
    }
}

export default changePassReducer;