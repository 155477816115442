import {
    LOGOUT,
  USER_FORGET_ERROR,
  USER_FORGET_LOAD,
  USER_FORGET_SUCCESS,
} from "./../../actionTypes/actionTypes";

const initialState = {
  isLoading: false,
  error: null,
  status: 0,
  data: {},
};

const forgotPassReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        isLoading: false,
        error: null,
        status: 0,
        data: {},
      };
    case USER_FORGET_LOAD:
      return { ...state, isLoading: true, error: null };

    case USER_FORGET_SUCCESS:
      return {
        ...state,
        // result:action.result,
        // data:action.result.data,
        // isLoading: false,
        // status: action.status,
      };

    case USER_FORGET_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default forgotPassReducer;
