import React from 'react';
import { CardHoc } from "../../../hoc";

import { CustomPageHeader, TextField } from "../../../molecules";
import { AppImage } from "../../../themes";
import Protptype from "prop-types";

const leftFontstyle = {
    display: "inline",
    color: "#143C64",
    fontWeight: 500,
};

const taskTypesExample = ({
    cardPadding = '0px 0px',
    cardInnerPadding = "content-e tabs_button removeTopMagin",
    maxHeight = 500,
    t
}) => {

    return (
        <div
            style={{
                padding: cardPadding,
                paddingTop: '-35px !important',
                maxHeight: maxHeight,
                overflowY: "scroll"
            }}
        >
            <CardHoc className="" cardInnerPadding={cardInnerPadding}>
                <CustomPageHeader
                    label={t("image_annotation")}
                    wrapperClassName={
                        "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
                    }
                    customWrapperStyle={{
                        marginBottom: "20px",
                        marginTop: "30px",
                    }}
                >
                </CustomPageHeader>
                <div className="wrapper_line owner-ongoingprojdetails">
                    <TextField labelTitle={t("bbox")} customLabelStyle={leftFontstyle} />
                    <label style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        marginBottom: "10px",
                        display: "block",
                    }}>
                        {t("bbox_example")}
                        <img
                            src={AppImage.bbox}
                            style={{
                                width: "100%",
                                marginTop: "10px",
                            }}
                        />
                    </label>
                    <TextField labelTitle={t("polygon")} customLabelStyle={leftFontstyle} />
                    <label style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        marginBottom: "10px",
                        display: "block",
                    }}>
                        {t("polygon_example")}
                        <img
                            src={AppImage.polygon}
                            style={{
                                width: "100%",
                                marginTop: "10px",
                            }}
                        />
                    </label>
                </div>
            </CardHoc>
            <CardHoc className="" cardInnerPadding={cardInnerPadding}>
                <CustomPageHeader
                    label={t("audio_annotation")}
                    wrapperClassName={
                        "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
                    }
                    customWrapperStyle={{
                        marginBottom: "20px",
                        marginTop: "30px",
                    }}
                >
                </CustomPageHeader>
                <div className="wrapper_line owner-ongoingprojdetails">
                    <TextField labelTitle={t("transcription")} customLabelStyle={leftFontstyle} />
                    <label style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        marginBottom: "10px",
                        display: "block",
                    }}>
                        {t("audio_example")}
                        <img
                            src={AppImage.transcription}
                            style={{
                                width: "100%",
                                marginTop: "10px",
                            }}
                        />
                    </label>
                </div>
            </CardHoc>
            <CardHoc className="" cardInnerPadding={cardInnerPadding}>
                <CustomPageHeader
                    label={t("text_annotation")}
                    wrapperClassName={
                        "bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 one_line_data"
                    }
                    customWrapperStyle={{
                        marginBottom: "20px",
                        marginTop: "30px",
                    }}
                >
                </CustomPageHeader>
                <div className="wrapper_line owner-ongoingprojdetails">
                    <TextField labelTitle={t("segmentation")} customLabelStyle={leftFontstyle} />
                    <label style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        marginBottom: "10px",
                        display: "block",
                    }}>
                        {t("segmentation_example")}
                        <img
                            src={AppImage.segmentation}
                            style={{
                                width: "100%",
                                marginTop: "10px",
                            }}
                        />
                    </label>
                    <TextField labelTitle={t("classification")} customLabelStyle={leftFontstyle} />
                    <label style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        marginBottom: "10px",
                        display: "block",
                    }}>
                        {t("classification_example")}
                        <img
                            src={AppImage.classification}
                            style={{
                                width: "100%",
                                marginTop: "10px",
                            }}
                        />
                    </label>
                </div>
            </CardHoc>
        </div>
    )
}

taskTypesExample.propTypes = {
    children: Protptype.node,
    cardPadding: Protptype.string,
    cardInnerPadding: Protptype.string,
    t: Protptype.func
}

export default taskTypesExample;