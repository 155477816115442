import { Constants } from "../../themes";
import { Post, PostLink } from "./FetchMethods";

export const baseUrl = Constants.baseUrl;

const Fetch = {
  // Authentication
  // done
  loginApi(payload) {
    let url = baseUrl + "oauth/token/";

    return Post(payload, url);
  },
  // done
  forgotApi(payload) {
    let url = baseUrl + "user/password/forget/";
    return Post(payload, url);
  },
  // done
  ResetPassApi(payload) {
    let url = baseUrl + `user/password/reset/`;
    return Post(payload, url);
  },
  OwnerSetPasswordApi(payload) {
    let url = baseUrl + `OwnersetPassword/`;
    let body = JSON.stringify(payload.body);
    return PostLink(body, url);
  },
  // done
  RegitserUserApi(payload) {
    let url = baseUrl + "sign_up/";

    return Post(payload, url);
  },
  // Authentication
  otpVerificationApi(payload) {
    let url = baseUrl + `oauth/token/`;

    return Post(payload, url); //true
  },
  logoutApi() {
    let url = baseUrl + `logout/`;
    const payload = {};
    return Post(payload, url); //true
  },
};

export default Fetch;
