import { Pagination, Table } from "antd"; 
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { CardHoc, Container } from "../../../hoc";
import React, { useEffect, useState } from "react";
import { getTaskListExport } from "../../../redux/actions/Tasks";
import { CustomEmptyDiv, CustomPageHeader } from "../../../molecules";
import { NavigateTo, SidebarMenus } from "../../../themes";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";

const ExportData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageNum, setPageNum] = useState(1);
  const [ordering, setOrdering] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);

  const breadCrumbItems = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: NavigateTo.USER_PROFILE,
      label: t("export_data"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t('export_data'),
  };

  const columns = [
    {
      title: "#",
      dataIndex: "srno",
      key: "srno",
      width: 150,
      render: (value, item, index) => index + 1,
    },
    {
      title: t("proj_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 250,
    },
    {
      title: t("prj_start_date"),
      dataIndex: "date",
      key: "date",
      sorter: true,
      width: 250,
    },
    {
      title: t("prj_end_date"),
      dataIndex: "enddate",
      key: "enddate",
      sorter: true,
      width: 250,
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      sorter: false,
      width: 180,
      render : (item)=>{
        return(
          t(item)
        )
      }
    },
    {
      title: t("action"),
      dataIndex: "Posted",
      key: "Posted",
      render: (val,record) => {
        return (
          <div> 
              <button type="submit" className="focus" onClick={()=>{
                navigate(NavigateTo.TASK_LIST);
                localStorage.setItem('pastProjectId',record?.id);
              }}>
                {t("view")}
              </button> 
          </div>
        );
      },
    }, 
  ];
  useEffect(() => {
    const payload = {
      body: {
        pageNum,
        searchValue,
        ordering,
      },
      success: (res) => {
        const modifiedRes = [];
        setTotalProjects(res?.length);
        res?.filter((item, index) => {
          modifiedRes.push({
            key: index + 1,
            name: item?.name, // "Dummmy Project",
            date: item?.created_at
              .split("T")[0]
              .split("-")
              .reverse()
              .join("-"), //"04/04/2022",
            enddate: item?.created_at
              .split("T")[0]
              .split("-")
              .reverse()
              .join("-"), //"01/01/2022 ",
            status: item?.status, //"Completed",
            id: item?.id,
          });
        });
        setDataSource(modifiedRes);
      },
      fail: (err) => {
        console.log(err, "err");
      },
    };
    isInternetConnected() && dispatch(getTaskListExport(payload));
  }, [pageNum, searchValue, ordering]);

  return (
    <Container {...containerProps}>
      <CustomPageHeader
        wrapperClassName={"top_button_item d-flex justify-content-between"}
      >
        <div className="button_left contain">
          <button
            className="button-list top_new focus"
            style={{ visibility: "hidden" }}
          />  
          <input
            type="text"
            className="search-bar"
            placeholder={t("search")}
            autoComplete="off"
            onChange={(e) => { 
              setSearchValue(e.target.value);
              setPageNum(1);
            }}
          />
        </div>
      </CustomPageHeader>
      <CardHoc cardInnerPadding={"content-e owner-export-card "}>
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
          <h6
            className="text-white text-capitalize ps-3"
            style={{ fnotSize: "20px" }}
          >
            {t("export_data")}
          </h6>
        </div>
       {
          dataSource.length
          ?
          <>
          <Table
            className="completed-to-be-export-projs"
            dataSource={dataSource}
            columns={columns}
            showSorterTooltip={false}
            pagination={false}
            onChange={(pagination, filters, sorter) => {
              if (sorter.order == undefined) {
                setOrdering("");
              }
              if (
                sorter.order == "ascend" &&
                sorter.order != undefined &&
                sorter.columnKey == "name"
              ) {
                setOrdering("name");
              }
              if (
                sorter.order == "descend" &&
                sorter.order != undefined &&
                sorter.columnKey == "name"
              ) {
                setOrdering("-name");
              }
              if (
                sorter.order == "ascend" &&
                sorter.order != undefined &&
                sorter.columnKey == "date"
              ) {
                setOrdering("start_date");
              }
              if (
                sorter.order == "descend" &&
                sorter.order != undefined &&
                sorter.columnKey == "date"
              ) {
                setOrdering("-start_date");
              }
              if (
                sorter.order == "ascend" &&
                sorter.order != undefined &&
                sorter.columnKey == "enddate"
              ) {
                setOrdering("end_date");
              }
              if (
                sorter.order == "descend" &&
                sorter.order != undefined &&
                sorter.columnKey == "enddate"
              ) {
                setOrdering("-end_date");
              }
            }}
          />
          <Pagination                
                  showTitle = {false}
            style={{
              "paddingTop":"30px","paddingBottom":"30px","maxWidth":"fit-content","right":"33px","left":"auto","position":"absolute"
            }}
            className="pagination-component"
            defaultCurrent={1}
            total={totalProjects}
            size="medium"
            onChange={(item) => {
              setPageNum(item);
            }}
          />
        </>
        :
               <CustomEmptyDiv marginLeft={'16px'} marginRight={'16px'} title={t('cmpltd_prj_appear_here')} />
             }
      </CardHoc>
    </Container>
  );
};

export default ExportData;
