import { SET_ADMIN_PROJ_LISTS,LOGOUT } from "./../../../actionTypes/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    status: 0,  
    success : false ,
    updateSuccess : false,
    projDetails : null,
    projList : [],

}
const projManagement = (state = initialState, action) => {
    // console.log(action?.payload);
    switch (action.type) {
        case LOGOUT :
            return {
                ...state,
                isLoading: false,
                error: null,
                status: 0,  
                success : false ,
                updateSuccess : false,
                projDetails : null,
                projList : [],
            };
        // case OWNER_SUCCESS:
        //     return { 
        //         ...state ,
        //         success :  true
        //     }
        case SET_ADMIN_PROJ_LISTS :
            return { 
                 ...state ,
                 projList :  action?.payload
             }
        // case SET_OWNER_DETAILS:
        //     return { 
        //          ...state ,
        //          projDetails :  action?.payload
        //      }
 
        default:
            return state;

    }
}

export default projManagement;