import {
    GET_ANNOTATIONS_LISTS,
    SAVE_ANNOTATIONS_USER_PENDING,
    ACCEPT_REJECT_ANNOTATIONS,
    SAVE_VALIDATIONS_USER_PENDING
} from "../actionTypes/actionTypes"
 
export const getAnnotationLists = (payload) => {
    return {
        type: GET_ANNOTATIONS_LISTS ,
        payload: payload
    }
}
export const saveAnnotations = (payload) => {
    return {
        type: SAVE_ANNOTATIONS_USER_PENDING ,
        payload: payload
    }
}
export const saveValidations = (payload) => {
    return {
        type: SAVE_VALIDATIONS_USER_PENDING ,
        payload: payload
    }
}
// VALIDATOR PENDING TASK DETAILS
export const acceptRejectAnnotations = (payload) => {
    return {
        type: ACCEPT_REJECT_ANNOTATIONS ,
        payload: payload
    }
} 
 