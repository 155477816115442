import { AppImage, NavigateTo } from "."; 
import { useTranslation } from "react-i18next"; 

const Menus=(userType)=>{
  const { t } = useTranslation();  
  
    switch (userType) {
        case 'user' :
            //change the user value as 0 or 1
            return(
                [
                    {
                      icon : AppImage.Activity,
                      label : t('dashboard'),
                      link : NavigateTo.USER_DASHBOARD
                    },
                    {
                      icon : AppImage.notification,
                      label : t('invites'),
                      link : NavigateTo.USER_NOTIFICATIONS
                    },
                    {
                      icon:AppImage.taskManagement,
                      label: t('task_management'),
                      link: NavigateTo.USER_TASK_MANAGEMENT
                    },
                    {
                      icon:AppImage.Profile_1,
                      label:t('profile'),
                      link: NavigateTo.USER_PROFILE
                    },
                    {
                      icon:AppImage.Logout,
                      label:t('logout'),
                      link:NavigateTo.LOGIN
                    }
                  ] 
            )
        case 'owner' :
              //change the user value as 0 or 1
              return(
                  [
                      {
                        icon : AppImage.Activity,
                        label : t('dashboard'),
                        link : NavigateTo.OWNER_DASHBOARD
                      },
                      {
                        icon : AppImage.PaperPlus,
                        label : t('create_new_prj'),
                        link : NavigateTo.CREATE_NEW_PROJECT
                      },
                      // {
                      //   icon:AppImage.frame,
                      //   label: t('grp_mngmnt'),
                      //   link: NavigateTo.GROUP_MANAGEMENT
                      // },
                      {
                        icon:AppImage.Paper,
                        label: t('projects'),
                        link: NavigateTo.PROJECT
                      },
                      // {
                      //   icon:AppImage.PaperUpload,
                      //   label: t('export_data'),
                      //   link:NavigateTo.EXPORT_DATA
                      // },
                      {
                        icon:AppImage.Profile_1,
                        label: t('profile'),
                        link:NavigateTo.PROFILE
                      },
                      {
                        icon:AppImage.Logout,
                        label: t('logout'),
                        link:NavigateTo.LOGIN
                      }
                    ] 
              )
        case 'admin' : return(
          [
            { 
              icon : AppImage.Activity,//images.Activity
              label : t('dashboard'), 
              link : NavigateTo.ADMIN_DASH_BOARD
            },
            // {
            //   icon : AppImage.ownermanagement,//images.PaperPlus
            //   label : t('owner_mngmnt'),
            //   link : NavigateTo.ADMIN_OWNER_MANAGEMENT,  
            // },
            {
              icon : AppImage.usermanagement,//images.frame
              label : t('user_mngmnt'),
              link : NavigateTo.ADMIN_USER_MANAGEMENT, 
            },
            {
              icon : AppImage.text12,//images.Paper
              label : t('prj_mngmnt'),
              link : NavigateTo.ADMIN_PROJECT_MANAGEMENT, 
            },
            {
              icon : AppImage.Unlock,//images.PaperUpload
              label : t('change_password'),
              link : NavigateTo.ADMIN_CHANGE_PASSWORD, 
            } ,
            {
              icon : AppImage.Logout,//images.Logout
              label : t('logout'),
              link : NavigateTo.LOGIN
            }
          ]
        )
        default:
            break;
    }
  return null;
}
export default Menus

 