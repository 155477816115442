import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Container from "../../../hoc/Container";
import { CustomEmptyDiv } from "../../../molecules";
import { NavigateTo, SidebarMenus } from "../../../themes";
import { getUserDashboardData } from "../../../redux/actions/Dashboard";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { useTranslation } from "react-i18next";
import { Row, Col, Tooltip, /* Timeline  */ } from "antd";
import TasksChart from "./tasks_chart";
import { CardHoc } from "../../../hoc";
import { Card, Table, Rate, Space, Skeleton } from "antd";
import {
  faRunning,
  faTasks,
  faBars,
  faTags
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppImage } from "../../../themes";


const UserDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");
  const name = localStorage.getItem("fullName");
  const { t } = useTranslation();

  const [taskInfo, setTaskInfo] = useState({
    tasks: null,
    task_count: 0,
    complete_task_count: 0,
    last_active: null,
    total_annotations: 0,
    task_status: [],
    leaderboard: [],
  });
  const [iWidth, setIWidth] = useState(window.innerWidth);
  const columnsWorkers = [
    {
      title: "#",
      dataIndex: "rank",
      key: "rank",
      width: 100,
      render: (value, record, index) => {
        return (
          <div>
            {index + 1}
          </div>
        );
      }
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 400,
      render: (value, record) => {
        return (
          <>
            <img
              width={45}
              height={45}
              style={{ borderRadius: 10, marginLeft: 10, marginRight: 10 }}
              src={record?.image ? record?.image : AppImage.defaultImage}
            /> {value}
          </>
        );
      },
    },
    {
      title: t("total_annotations"),
      dataIndex: "total_annotations",
      key: "total_annotations",
      width: 200,
      render: (value) => {
        return (
          <div>
            {thousands_separators(value)}
          </div>
        );
      },
    },
    {
      title: t("rating"),
      dataIndex: "last_active",
      key: "last_active",
      width: 300,
      render: (value) => {
        return (
          <div>
            <Rate
              style={{
                fontSize: "20px",
              }}
              className="edit-group-star"
              allowHalf
              value={value}
              disabled={true}
            />
          </div>
        );
      },
    }
  ];


  /* Container props */
  const breadCrumbItems = [
    {
      link: NavigateTo.DASHBOARD,
      label: t("dashboard"),
    },
  ];
  function thousands_separators(num) {
    if (num === 0 || num == NaN) return 0;
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }
  const iconStyle = {
    fontSize: "3vh",
    color: "#143c64",
    marginBottom: "10px",
  }
  const infoItems2 = [
    {
      "icon": <FontAwesomeIcon icon={faBars} style={iconStyle} />,
      "title": t("tasks"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: taskInfo.task_count
        }
      ]
    },
    {
      "icon": <FontAwesomeIcon icon={faTasks} style={iconStyle} />,
      "title": t("completed_tasks"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: taskInfo.complete_task_count
        }
      ]
    },
    {
      "icon": <FontAwesomeIcon icon={faRunning} style={iconStyle} />,
      "title": t("last_active"),
      "items": [
        {
          label: t("date"),
        },
        {
          value: taskInfo?.last_active?.toLocaleDateString(lang, { year: '2-digit', month: 'numeric', day: 'numeric' })
        }
      ]
    },
    {
      "icon": <FontAwesomeIcon icon={faTags} style={iconStyle} />,
      "title": t("total_annotations"),
      "items": [
        {
          label: t("total"),
        },
        {
          value: thousands_separators(taskInfo?.total_annotations)
        }
      ]
    },

  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("user"),
    active: t("dashboard"),
  };
  /* Container props */
  useEffect(() => {
    document.title = "WosoM";

    window.addEventListener("resize", () => {
      setIWidth(window.innerWidth);
    });

    const payload = {
      success: (res) => {
        let cmp_tsk = 0;
        let ttl_ann = 0;
        res.tasks.map((item) => {
          if (item.document_count == item.total_annotated)
            cmp_tsk++;
          ttl_ann += item.total_annotated;
        })
        setTaskInfo({
          tasks: res?.tasks,
          task_count: res?.task_count,
          complete_task_count: cmp_tsk,
          total_annotations: ttl_ann,
          last_active: new Date(res.last_annotation),
          task_status: res?.task_status,
          leaderboard: res?.leaderboard,
        });
      },
      fail: () => { },
    };
    isInternetConnected() && dispatch(getUserDashboardData(payload));
  }, []);

  return (
    <Container {...containerProps}>
      {taskInfo?.tasks && taskInfo?.tasks?.length > 0 ? (
        <>
          <Row>
            <Col xs={24} md={24} lg={6}>
              <div
                className="card card-sm"
                style={{
                  margin: "20px 20px 0 20px",
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}>
                <h2 style={{
                  fontWeight: "600",
                  color: "#214c7d"
                }}>
                  {t("welcome")}
                </h2>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                }}>

                  <div
                    style={{
                      border: "2px solid #193d64",
                      borderRadius: "50px",
                      width: " 50px",
                      height: " 50px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(localStorage.getItem("user_type") == "owner" ? NavigateTo.OWNER_PROFILE : NavigateTo.USER_PROFILE);
                    }}
                  >
                    <img
                      style={{
                        borderRadius: "35px",
                        width: " 46px",
                        height: " 46px",
                      }}
                      alt=""
                      src={localStorage.getItem("userImage") && localStorage.getItem("userImage") != "" ? localStorage.getItem("userImage") : AppImage.defaultImage}
                    />
                  </div>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                  }}>

                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "25px",
                        color: "#316293",
                        paddingRight: "8px",
                        paddingLeft: "8px",
                        cursor: "pointer",
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // width: name.length > 8 ? 103 : 82,
                      }}
                    >
                      <Tooltip title={name} className="userName">
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                        // onClick={() => setShowSkillModal(true)}
                        >
                          {name?.length > 18 ? name?.substring(0, 8) + "..." : name || ""}
                        </span>
                      </Tooltip>
                      {/* {localStorage.getItem('fullName')} */}
                    </label>
                    <label
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "#333",
                        paddingRight: "8px",
                        paddingLeft: "8px",
                        cursor: "pointer",
                        textTransform: "capitalize",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // width: name.length > 8 ? 103 : 82,
                      }}
                    >
                      {new Date().toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' })}, {new Date().toLocaleDateString(lang, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </label>
                  </div>
                </div>
                <div
                  className="card card-sm"
                  style={{
                    margin: "20px 20px 0 20px",
                    border: "none",
                    boxShadow: "none",
                  }}>
                </div>
              </div>

            </Col>
            <Col xs={24} md={24} lg={18}>
              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "100%",
              }}>
                <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                  <Card className="border-radius-lg ">
                    <div style={{
                      fontSize: "1.2rem",
                      color: "#214c7d",
                      marginBottom: "10px",
                    }}>
                      {t("overview")}
                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }} >
                      {/* stat cards */}
                      <div style={{
                        display: "flex",
                        flexDirection: iWidth > 1900 ? "row" : "column",
                      }}>

                        {infoItems2.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="card card-sm"
                              style={{
                                margin: "10px",
                              }}
                            >
                              <h1 style={{
                                fontSize: "1rem",
                                color: "#214c7d",
                                fontWeight: "600",
                                padding: " 8px"
                              }}>
                                {item.title}
                              </h1>
                              <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}>
                                <div
                                  style={{
                                    position: "relative",
                                    padding: "0 24px",
                                  }}
                                >
                                  {item.icon}
                                </div>
                                <div className="stats" style={{ display: "flex" }}>
                                  {item.items.map((item, index2) => {
                                    return (
                                      <div
                                        key={index2}
                                        style={{
                                          position: "relative",
                                          padding: "0 24px",
                                        }}
                                        className="stat-card"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h3
                                            style={{
                                              fontSize: "1.2rem",
                                              color: "#214c7d",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {item.value}
                                          </h3>
                                          <p
                                            style={{
                                              fontSize: "1rem",
                                              color: "#3a3a3a",
                                            }}
                                          >
                                            {item.label}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </Card>
                </CardHoc>
              </div>
            </Col>
          </Row>
          <Row
            className="site-layout-background dashboard"
          >
            <Col xs={24} md={12} lg={14}>
              <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                <Card className="border-radius-lg "
                  style={{
                    marginBottom: "20px",
                  }}>
                  <div style={{
                    fontSize: "1.2rem",
                    color: "#214c7d",
                    marginBottom: "10px",
                  }}>
                    {t("tasks")}
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    {taskInfo?.tasks ? (<TasksChart data={taskInfo?.tasks} />) :
                      <p>{t("pend_task_appear_here")}</p>}
                  </div>
                </Card>
              </CardHoc>
            </Col>
            <Col xs={24} md={12} lg={10}>
              <CardHoc cardInnerPadding={"content-e tabs_button removeTopMagin"} minHeight={0}>
                <Card className="border-radius-lg "
                  style={{
                    marginBottom: "20px",
                  }}>
                  <div style={{
                    fontSize: "1.2rem",
                    color: "#214c7d",
                    marginBottom: "10px",
                  }}>
                    {t("leader_board")}
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} >
                    {taskInfo?.leaderboard.length != 0 ? (
                      iWidth > 1500 ? (
                        <div id="paginationOfgroup">
                          <Table
                            className="table table-sm"
                            style={{
                              boxShadow: "rgb(204, 204, 204) 1px 1px 6px 4px",
                              borderRadius: "10px",
                            }}
                            dataSource={taskInfo?.leaderboard}
                            columns={columnsWorkers}
                            pagination={false}
                            showSorterTooltip={false}
                          />
                        </div>) : (
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        >
                          {taskInfo?.leaderboard?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="card card-sm"
                                style={{
                                  margin: "2px",
                                }}
                              >
                                <h5 style={{
                                  fontSize: "1rem",
                                  color: "#214c7d",
                                  fontWeight: "600",
                                  padding: " 18px"
                                }}>
                                  # {index + 1} {item.name} | <b>{thousands_separators(item.total_annotations)}</b>
                                </h5>

                              </div>
                            )
                          })}
                        </div>
                      )
                    ) : (
                      <p>{t('wrkr_appear_here')}</p>
                    )}
                  </div>
                </Card>
              </CardHoc>
            </Col>
          </Row>
        </>) :
        taskInfo?.tasks && taskInfo?.tasks?.length == 0 ?
          (
            <CustomEmptyDiv title={t("all_annots_appear_here")} />
          ) : (
            <div style={{
              padding: "50px",
            }}>
              <Space >
                <Skeleton.Button active={true} size={'large'} shape={'round'} block={true} />
                <Skeleton.Avatar active={true} size={'large'} shape={'round'} />
                <Skeleton.Input active={true} size={'large'} />
              </Space>
              <br />
              <br />
              <Skeleton.Button active={true} size={'large'} shape={'round'} block={true} />
              <br />
              <br />
              <Skeleton.Input active={true} size={'large'} block={true} />
              <br />
              <br />
              <br />
              <br />
              <Skeleton.Button active={true} size={'large'} shape={'round'} block={true} />
              <br />
              <br />
              <Skeleton.Input active={true} size={'large'} block={true} />
              <br />
              <br />
              <br />
              <br />
              <Skeleton.Button active={true} size={'large'} shape={'round'} block={true} />
              <br />
              <br />
              <Skeleton.Input active={true} size={'large'} block={true} />
              <br />
              <br />
              <br />
              <br />
              <Skeleton.Button active={true} size={'large'} shape={'round'} block={true} />
              <br />
              <br />
              <Skeleton.Input active={true} size={'large'} block={true} />
              <br />
              <br />
            </div>
          )}
    </Container>
  );
};

export default UserDashboard;
