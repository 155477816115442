import {
  fieldIds,
  NavigateTo,
  SidebarMenus,
} from "../../../themes";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { TextField } from "../../../molecules/Admin";
import { useDispatch, useSelector } from "react-redux";
import { CardAdmin, ContainerAdmin } from "../../../hoc";
import { changePassword, logout } from "../../../redux/actions/Common";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import { constants } from "../../../common/fileConstants/Admin/ChangePassword";

const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const passSuccess = useSelector(state => state?.common?.passSuccess);
  const [lang, setLang] = useState(localStorage.getItem('lang'));
  const breadCrumbItems = [
    {
      link: NavigateTo.ADMIN_DASH_BOARD,
      label: t('dashboard'),
    },
    {
      link: "",
      label: t('change_password'),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("admin"),
    active: t('change_password'),
  };
  const initialValues = {
    OldPassword: "",
    NewPassword: "",
    ConfirmNewPassword: "",
  };

  const onSubmit = (values) => {
    const payloadForChangePass = {
      password: values?.OldPassword,
      new_password: values?.NewPassword
    };
    isInternetConnected() && dispatch(changePassword(payloadForChangePass, navigate));
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: constants.formikValidationSchema(localStorage.lang),
  });
  const passwordFormFields = [
    {
      error: formik.errors.OldPassword,
      placeholder: t('old_password'),
      inputTrue: true,
      type: "password",
      id: fieldIds.OLD_PASSWORD,
      fieldLabel: t('old_password'),
      value: formik.values.OldPassword,
    },
    {
      error: formik.errors.NewPassword,
      placeholder: t('new_password'),
      inputTrue: true,
      type: "password",
      id: fieldIds.NEW_PASSWORD,
      inputMaxLength: 20,
      fieldLabel: t('new_password'),
      value: formik.values.NewPassword,
    },
    {
      error: formik.errors.ConfirmNewPassword,
      placeholder: t('confrm_password'),
      inputTrue: true,
      type: "password",
      inputMaxLength: 20,
      id: fieldIds.CONFIRM_NEW_PASSWORD,
      fieldLabel: t('confrm_password'),
      value: formik.values.ConfirmNewPassword,
    },
  ];
  useEffect(() => {
    if (passSuccess) {
      navigate(NavigateTo.LOGIN);
      dispatch(logout('i'));
    }
  }, [passSuccess])
  useEffect(() => {
    window.document.title = 'WosoM';
  })
  useEffect(() => {
    Object.keys(formik.errors).forEach(fieldName => {
      formik.setFieldTouched(fieldName)
    });
  }, [lang])
  return (
    <ContainerAdmin {...containerProps}
      languageChanged={
        () => {
          setLang(localStorage.getItem('lang'));
        }
      }
      layoutClassName={localStorage.getItem('lang') == 'ar' ? 'new_layout ar' : 'new_layout en'}
    >
      <CardAdmin>
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
          <h6 className="text-white text-capitalize ps-3 Pb-12">
            {t('change_password')}
          </h6>
          <input
            type="text"
            autoComplete={'off'}
            className="search-bar"
            placeholder={t("search")}
            style={{ visibility: "hidden" }}
          />
        </div>
        <form noValidate onSubmit={formik.handleSubmit}>
          {passwordFormFields.map((items) => {
            return (
              <div key={items.fieldLabel} className="wrapper_line password_size">
                <TextField
                  {...items}
                  errorClassName={localStorage.getItem('lang') == 'ar' ? 'error ar' : 'error en'}
                  labelTitle={items.fieldLabel}
                  onChange={formik.handleChange}
                />
              </div>
            );
          })}
          <button
            type="submit"
            className="button-list bottom_button pt-10 focus"
            style={{
              marginLeft: "20px",
              marginBottom: "20px",
            }}
          >
            {t('update')}
          </button>
        </form>
      </CardAdmin>
    </ContainerAdmin>
  );
};

export default ChangePassword;
