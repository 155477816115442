import React, { useEffect, useRef, useState } from "react";
import { Container } from "../../../../../hoc";
import { useLocation } from "react-router";
import { CSVLink } from "react-csv";
import {
  ImageAnnotate,
  AudioTranscript,
  QuestionAnnotate,
  SegmentationAnnotate,
  TextTranscript,
} from "../Udts";
import { SidebarMenus } from "../../../../../themes";
import { useTranslation } from "react-i18next";

const UDT_Screen = () => {
  const csvLink = useRef();
  const location = useLocation();
  const { t } = useTranslation();

  let classLists,
    images,
    imageIndex,
    // audio,c
    // audioUrl,c
    workerType,
    // questions,
    // pdfUrl,
    apiJsonImage,
    task_id,
    anotation_id,
    worker_id,
    media_id,
    classes,
    segmentAllAnnots,
    // textAllAnnots,
    paragraph,
    paragraphs,
    isCompleted,// for segmentation and question
    totalTasks, // for segmentation and question
    unAnnotated = false
    ;

  let tempContainerProps = location?.state?.udtContainerProps;
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [toggle, setToggle] = useState(false);

  const containerProps = {
    breadCrumbItems: localStorage.lang == 'en' ? tempContainerProps?.breadCrumbItems?.en : tempContainerProps?.breadCrumbItems?.ar,
    menuProps: localStorage.user_type == 'performer' ? SidebarMenus('user') : localStorage.user_type == 'admin' ? SidebarMenus('admin') : SidebarMenus('owner'), //tempContainerProps.menuProps,
    active: localStorage.user_type == 'performer' ? t('task_management') : localStorage.user_type == 'admin' ? t('prj_mngmnt') : t('projects')//tempContainerProps.active
  };

  const taskType = localStorage.getItem("taskType"); // Image,Audio,Text
  const textType = localStorage.getItem("textType"); // segmentation,questions

  if (taskType == "Image") {
    classLists = location?.state?.classLists;
    images = location?.state?.images;
    imageIndex = location?.state?.imageIndex;
    apiJsonImage = location?.state?.apiJsonImage;
  }
  if (taskType == "Audio") {
    // audio = location?.state?.audio;
    // audioUrl = location?.state?.audio?.url;
    workerType = location?.state?.workerType;
  }
  if (taskType == "Text") {
    task_id = location?.state?.task_id;
    anotation_id = location?.state?.anotation_id;
    worker_id = location?.state?.worker_id;
    media_id = location?.state?.media_id;
    unAnnotated = location?.state?.unAnnotated;
    isCompleted = location?.state?.isCompleted;
    totalTasks = location?.state?.totalTasks;

    if (textType == "questions") {
      // questions = location?.state?.questions;
      // pdfUrl = location?.state?.pdfUrl;
      // textAllAnnots = location?.state?.textAllAnnots;
    }
    if (textType == "segmentation") {
      paragraph = location?.state?.paragraph;
      classes = location?.state?.classes;
      segmentAllAnnots = location?.state?.segmentAllAnnots;
    }
    if (textType == "text_transcription") {
      paragraphs = location?.state?.paragraphs;
      workerType = location?.state?.workerType;
    }
  }
  useEffect(() => {
    setToggle(!toggle);
  }, [lang])

  return (
    <Container {...containerProps}
      languageChanged={() => {
        setLang(localStorage.getItem("lang"));
      }}
    >
      <div className="udt-annotate keep-save-button">
        {taskType == "Image" ? (
          <ImageAnnotate
            classLists={classLists}
            images={images}
            imageIndex={imageIndex}
            apiJsonImage={apiJsonImage}
            task_id={task_id}
          />
        ) : taskType == "Text" ? (
          textType == "segmentation" ? (
            <SegmentationAnnotate
              isCompleted={isCompleted}
              totalTasks={totalTasks}
              paragraph={paragraph}
              classes={classes}
              task_id={task_id}
              anotation_id={anotation_id}
              worker_id={worker_id}
              media_id={media_id}
              segmentAllAnnots={segmentAllAnnots}
            /> //paragraph={paragraph}
          ) : textType == "classification" ? (
            <QuestionAnnotate unAnnotated={unAnnotated} />
          ) : (
            <TextTranscript texts={paragraphs} textUrl={paragraphs} workerType={workerType} media_id={media_id} />
          )
        ) : (
          <AudioTranscript />
        )}
      </div>

      {/* to download csv data */}
      <CSVLink
        data={[] || JSON.parse(localStorage.getItem("csvData"))}
        filename="transactions.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </Container>
  );
};

export default UDT_Screen;
