import Fetch from "../Fetch/Api";
import { call, put } from 'redux-saga/effects';
import { toast } from "react-toastify";
import { USER_UPDATE_ERROR, USER_UPDATE_SUCCESS } from "../actionTypes/actionTypes";
function* authanicationUser(result, history) {
    localStorage.clear()
    history.push("/")
    toast.error(result.result.message,{

        className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
            position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

    })
}

function* updateUserDetailsApi(action) {

    try {
        const result = yield call(Fetch.updateUserDetailsApi, action.newData, action.history)

        if (result.status === 1) {
            yield put({
                type: USER_UPDATE_SUCCESS,
                result: result?.result,
                status: result.status,
                value: action.payload
            })
            action.history.push('/usermanagement');
            toast.success(result?.result?.message, { toastId: "est",
            className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
                position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
     })
        }
        else if (result.status === 4) {
            yield call(authanicationUser, result, action.history)
        }
        else {
            yield put({
                type: USER_UPDATE_ERROR,
                result: result?.result?.data?.data,
            })
            toast.error(result.result.message,{

        className : localStorage.lang == 'ar' ? 'Select_data ar' : 'Select_data en',
        position : localStorage.lang == 'ar' ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,

            })
        }
    }
    catch (error) {
        yield put({
            type: USER_UPDATE_ERROR,
            result: error
        })
    }
}

export default updateUserDetailsApi;