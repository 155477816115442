import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { CardHoc, Container } from "../../../hoc";
import React, { useEffect, useState } from "react";
import { CustomEmptyDiv, CustomPageHeader } from "../../../molecules";
import { Typography, Row, Col, Pagination, Tooltip, Card } from "antd";
import { NavigateTo, SidebarMenus } from "../../../themes";
import {
  getOngoingProjectLists,
} from "../../../redux/actions/Project";
import {
  EyeOutlined,
  CodeSandboxOutlined,
  RadiusUprightOutlined,
  TranslationOutlined,
  PartitionOutlined,
  SnippetsOutlined,
  EditOutlined
} from "@ant-design/icons";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";

const { Meta } = Card;
const { Text } = Typography;
const ProjectManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageNum, setPageNum] = useState(1);

  const [searchValue, setSearchValue] = useState("");
  const [totalProjects, setTotalProjects] = useState(0);
  const [onGoingProjectsDataSource, setOnGoingProjectsDataSource] = useState(
    []
  );

  // FORMIK start
  const initialValues = {
    searchValue: searchValue,
  };
  const formik = useFormik({
    initialValues,
  });
  // FORMIK end
  function thousands_separators(num) {
    if (num === 0 || num == NaN) return 0;
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return num_parts.join(".");
  }
  const breadCrumbItems = [
    {
      link: NavigateTo.OWNER_DASHBOARD,
      label: t("dashboard"),
    },
    {
      link: "",
      label: t("projects"),
    },
  ];
  const containerProps = {
    breadCrumbItems: breadCrumbItems,
    menuProps: SidebarMenus("owner"),
    active: t("projects"),
  };


  // USE-EFFECT
  useEffect(() => {
    document.title = "WosoM";
  });

  useEffect(() => {
    const payloadForOngoingProj = {
      body: {
        pageNum,
        searchValue,
      },
      success: (res) => {
        const modifiedRes = [];
        setTotalProjects(res?.[0]?.total_projects);
        res?.filter((item, index) => {
          modifiedRes.push({
            key: index + 1,
            Projectname: item?.name, //"Dummmy Project",
            projectdate: item?.start_date
              .split("T")[0]
              .split("-")
              .reverse()
              .join("-"),
            id: item?.id,
            task_types: item?.task_types,
            total_tasks: item?.total_tasks,
            total_annotations: item?.total_annotations,
          });
        });
        setOnGoingProjectsDataSource(modifiedRes);
      },
      fail: (err) => {
        console.log(err, "err");
      },
    };
    isInternetConnected() && dispatch(getOngoingProjectLists(payloadForOngoingProj));

  }, [pageNum, searchValue]);
  // USE-EFFECT

  return (
    <Container {...containerProps}>
      {/* tabs and search bar */}
      <CustomPageHeader
        wrapperClassName={"top_button_item d-flex justify-content-between"}
      >
        <div className="button_left contain">
          {/* search bar */}
          <input
            type="text"
            autoComplete="off"
            className="search-bar"
            placeholder={t("search")}
            value={formik.values.searchValue}
            onChange={(e) => {
              formik.setValues({
                searchValue: e.target.value,
              });
              setSearchValue(e.target.value);
              setPageNum(1);
            }}
          />
          {/* search bar */}
        </div>
      </CustomPageHeader>

      {/* table container */}
      <CardHoc cardInnerPadding={"content-e owner-export-card owner-projects"}>
        {/* Projects Header */}
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
          <h6 className="text-white text-capitalize ps-3">{t("projects")}</h6>
        </div>
        <>
          {/* ongoing projects */}
          {onGoingProjectsDataSource.length ? (
            <>
              <div
                className="table-responsive"
                style={{ padding: "2% 1% 4% 1%", marginBottom: "10%" }}
              >
                <Row gutter={[16, 16]}>
                  {onGoingProjectsDataSource.map((item, index) => {
                    const types = item.task_types;
                    const iconStyle = {
                      color: "#143c64",
                      fontSize:
                        types.length > 2 ? 35 : types.length > 1 ? 40 : 45,
                      margin: 5,
                    };
                    const taskIcons = types.map((taskType, index) => {
                      let icon = null;
                      switch (taskType) {
                        case "bbox":
                          icon = (
                            <CodeSandboxOutlined
                              key={index}
                              style={iconStyle}
                            />
                          );
                          break;
                        case "polygon":
                          icon = (
                            <RadiusUprightOutlined
                              key={index}
                              style={iconStyle}
                            />
                          );
                          break;
                        case "transcription":
                          icon = (
                            <TranslationOutlined
                              key={index}
                              style={iconStyle}
                            />
                          );
                          break;
                        case "segmentation":
                          icon = <PartitionOutlined style={iconStyle} />;
                          break;
                        case "classification":
                          icon = <SnippetsOutlined style={iconStyle} />;
                          break;
                        case "text_transcription":
                          icon = <EditOutlined style={iconStyle} />;
                          break;
                        default:
                          return icon;
                      }
                      return (
                        <Tooltip
                          key={index}
                          title={t(taskType)}
                          placement={
                            localStorage.getItem("lang") == "ar"
                              ? "topRight"
                              : "topLeft"
                          }
                        >
                          {icon}
                        </Tooltip>
                      );
                    });
                    return (
                      <Col xs={24} md={24} lg={12} key={index}>
                        <Card
                          className="card"
                          style={{
                            width: "100%",
                            marginTop: 10,
                            borderRadius: 16,
                          }}
                        >
                          <Meta
                            avatar={
                              <div
                                style={{
                                  display: "flex",
                                  width: "12vh",
                                  flexWrap: "wrap",
                                }}
                              >
                                {taskIcons}
                              </div>
                            }
                          />
                          <div
                            style={{
                              position: "absolute",
                              left: "25%",
                              top: "25%",
                            }}
                          >
                            <h6 className="text-white text-capitalize ps-3">
                              <b>{item.Projectname}</b>
                            </h6>
                            <Text type="secondary">
                              <b>{item.projectdate}</b>
                            </Text>
                          </div>
                          <div
                            className="mobile-hide"
                            style={{
                              position: "absolute",
                              left: "50%",
                              top: "25%",
                            }}
                          >
                            <h6 className="text-white text-capitalize ps-3 mr-2">
                              {t("total_tasks")}: <b>{item.total_tasks}</b>
                            </h6>
                            <Text type="secondary">
                              {t("total_annotations")}: <b>{thousands_separators(item.total_annotations)}</b>
                            </Text>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              right: localStorage.getItem("lang") == "ar" ? "auto" : "5%",
                              left: localStorage.getItem("lang") == "ar" ? "5%" : "auto",
                              top: "25%",
                            }}
                          >
                            <button
                              className="btn btn-lg"
                              type="button"
                              onClick={() => {
                                localStorage.setItem(
                                  "ongoingProjectId",
                                  item?.id
                                ); // implement it if api will be hit for project details page
                                navigate(NavigateTo.ONGOING_PROJECT_DETAILS);
                              }}
                            >
                              <EyeOutlined /> {t("view")}
                            </button>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <Pagination
                showTitle={false}
                style={{
                  maxWidth: "fit-content",
                  right: localStorage.getItem("lang") == "ar" ? "auto" : "5%",
                  left: localStorage.getItem("lang") == "ar" ? "5%" : "auto",
                  position: "absolute",
                  bottom: "2%",
                }}
                className="pagination-component"
                defaultCurrent={1}
                total={totalProjects}
                size="medium"
                onChange={(item) => {
                  setPageNum(item);
                }}
              />
            </>
          ) : (
            <CustomEmptyDiv
              marginLeft={"16px"}
              marginRight={"16px"}
              title={t("ongoing_prj_appear_here")}
            />
          )}
        </>
      </CardHoc>
    </Container>
  );
};

export default ProjectManagement;
