import {
  getLanguageList,
  getWorkersValidatorsList,
  stopLoading,
} from "../../../redux/actions/Common";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AppImage } from "../../../themes";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { CustomEmptyDiv } from "../../../molecules";
import appconstant from "../../../themes/appconstant";
import { Modal, Pagination, Rate, Select, Table } from "antd";
import { getGroupInvitations, updateGroup } from "../../../redux/actions/Group";
import { isInternetConnected } from "../../../themes/utils/internetConnectionn";
import PropTypes from "prop-types";

const { Option } = Select;

const AddWorkerModal = ({
  show,
  hideWorkerModal = () => { },
  RemovedaddedGrpMembers,
  RemovedselectedIds,
  groupId,
  grpName,
}) => {
  const dispatch = useDispatch();

  const childrenEmail = [];
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false); // add worker modal
  const [isModalVisibleWorker, setIsModalVisibleWorker] = useState(false); // currently active workers modal
  const [isModalRemove, setIsModalRemove] = useState(false); // remove modal
  const [removalId, setRemovalId] = useState("");
  const [inviteWorkerEmails, setInviteWorkerEmails] = useState([]);

  const [totalUsers, setTotalUsers] = useState(0); // total workers and validators
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [languageChildren, setLanguageChildren] = useState([]);

  const [languageVal, setLanguageVal] = useState(null);
  const [ratingVal, setRatingVal] = useState(null);
  const [selectedIds, setselectedIds] = useState([]);

  const [addedGroupMembers, setAddedGroupMembers] = useState([]);
  const [invitedWorkers, setInvitedWorkers] = useState([]);
  const [currentlyActive, setCurrentlyActive] = useState([]);
  const validateEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const testIncludes = (prevVal, passedinstring) => {
    var regex = new RegExp(prevVal.join("|"), "i");
    return regex.test(passedinstring);
  };
  const onSubmit = () => {

    let bodyTobeSend;
    if (selectedIds.length > 0) {
      bodyTobeSend = {
        name: grpName,
        members: selectedIds,
        groupId: groupId,
      };
    }
    if (inviteWorkerEmails && inviteWorkerEmails.length > 0) {
      bodyTobeSend = {
        name: grpName,
        non_users: inviteWorkerEmails,
        groupId: groupId,
      };
    }
    if (
      selectedIds.length > 0 &&
      inviteWorkerEmails &&
      inviteWorkerEmails.length > 0
    ) {
      bodyTobeSend = {
        name: grpName,
        members: selectedIds,
        non_users: inviteWorkerEmails,
        groupId: groupId,
      };
    }
    const payloadForGroupUpdate = {
      success: () => { },
      fail: (res) => {
        if (res.result.errors?.name) {
          toast.error(res.result.errors.name[0], {
            className:
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
            position:
              localStorage.lang == "ar"
                ? toast.POSITION.TOP_LEFT
                : toast.POSITION.TOP_RIGHT,
            toastId: "samegroupnameError",
          });
        } else {
          toast.error(res.result.errors.non_field_errors[0], {
            className:
              localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
            position:
              localStorage.lang == "ar"
                ? toast.POSITION.TOP_LEFT
                : toast.POSITION.TOP_RIGHT,
            toastId: "samegroupnameError",
          });
        }
      },
      body: bodyTobeSend,
    };

    if (selectedIds.length == 0) {
      toast.error(t("add_min_1_wrkr"), {

        position:
          localStorage.lang == "ar"
            ? toast.POSITION.TOP_LEFT
            : toast.POSITION.TOP_RIGHT,
      });
    }
    else {
      grpName
        ?
        isInternetConnected() && dispatch(updateGroup(payloadForGroupUpdate))
        :
        toast.error(t('plz_add_grp_name'), {
          position: toast.POSITION.TOP_RIGHT,
          toastId: 'enterGrpname'
        });
      setIsModalVisible(false);
      hideWorkerModal(addedGroupMembers, inviteWorkerEmails, selectedIds);
    }

    // if (addedGroupMembers.length == 0) {
    //   toast.error(t("add_min_1_wrkr"), {
    //     className:
    //       localStorage.lang == "ar" ? "Select_data ar" : "Select_data en",
    //     position:
    //       localStorage.lang == "ar"
    //         ? toast.POSITION.TOP_LEFT
    //         : toast.POSITION.TOP_RIGHT,
    //   });
    // } else {
    //   setIsModalVisible(false);
    //   hideWorkerModal(addedGroupMembers, inviteWorkerEmails, selectedIds);
    // }
  };

  const columns = [
    {
      title: t("sr_no"),
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => index + 1,
    },
    {
      title: t("profile_img"),
      dataIndex: "profile",
      key: "profile",
      render: (value) => {
        return (
          <img
            style={{
              width: "44px",
              height: "44px",
              borderRadius: "44px",
            }}
            src={value}
          />
        );
      },
    },
    {
      title: t("user_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,

      sorter: (a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      },
    },
    {
      title: t("rating"),
      dataIndex: "rating",
      key: "rating",
      sorter: true,
      width: 280,

      sorter: (a, b) => a.rating - b.rating,
      render: (value) => {
        return (
          // <div>
          <Rate
            style={{
              fontSize: "20px",
              position: "absolute",
              top: "24px",
              right: "105px",
              maxWidth: "fit-content",
            }}
            className="edit-group-star"
            allowHalf
            value={value}
            disabled={true}
          />
          // </div>
        );
      },
    },
    {
      title: t("action"),
      dataIndex: "Posted",
      key: "Posted",
      render: (text, record) => {
        return (
          <div>
            <button
              className="focus"
              type="button"
              onClick={() => {
                setRemovalId(record.id);
                setIsModalRemove(true);
              }}
            >
              {t("remove")}
            </button>
          </div>
        );
      },
    },
  ];

  const EmptyData = () => {
    return (
      <div
        style={{
          borderRadius: 20,
          backgroundColor: "#f0f4ff",
          boxShadow: "0px 20px 34px rgb(20 60 100 / 34%)",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "250px",
            color: "#c3bcbc",
            fontWeight: 400,
            fontSize: "17px",
            alignItems: "center",
            justifyContent: "center",

            flexDirection: "column",
          }}
        >
          <img
            style={{
              height: "76px",
              width: "95px !important",
            }}
            src={AppImage.emptyImg}
          />
          <div style={{}}>{t("added_wrkr_appear_here")}</div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setIsModalVisible(show);
    const payloadForGroupInvitations = {
      success: (res) => {
        setInvitedWorkers(res?.result?.data);
      },
      fail: (res) => {
        console.log(res);
      },
      groupId: groupId,
    };
    isInternetConnected() &&
      dispatch(getGroupInvitations(payloadForGroupInvitations));
  }, [show]);
  useEffect(() => {
    setTimeout(() => {
      const modalPopUpCrossButton = document.querySelectorAll(
        'button[aria-label="Close"]'
      );

      if (modalPopUpCrossButton && localStorage.getItem("lang") == "ar") {
        for (let i = 0; i < modalPopUpCrossButton.length; i++) {
          modalPopUpCrossButton[i].style.right = "unset";
          modalPopUpCrossButton[i].style.left = "0";
        }
      }
      if (modalPopUpCrossButton && localStorage.getItem("lang") == "en") {
        for (let i = 0; i < modalPopUpCrossButton.length; i++) {
          modalPopUpCrossButton[i].style.right = "0";
          modalPopUpCrossButton[i].style.left = "unset";
        }
      }
    }, 100);
  }, [show, isModalVisibleWorker]);

  useEffect(() => {
    const payloadForLanguages = {
      success: (res) => {
        var modifiedResult = [];
        res.filter((item) => {
          modifiedResult.push({ name: item.name, value: item.id });
        });
        setLanguageChildren(modifiedResult);
      },
      fail: (err) => {
        toast(err);
      },
    };
    isInternetConnected() && dispatch(getLanguageList(payloadForLanguages));
  }, []);

  useEffect(() => {
    const payload = {
      body: {
        pageNum: languageVal || min || max ? 1 : pageNum,
        min,
        max,
        languageVal,
      },
      success: (res) => {
        setTotalUsers(res?.count);
        const modifiedRes = [];
        res?.results?.filter((item ) => {
          modifiedRes.push({
            id: item.id,
            profile: item?.image
              ? item?.image.startsWith("h")
                ? item?.image
                : item?.image.includes("static")
                  ? AppImage.defaultImage
                  : appconstant.imageBaseUrl + item?.image
              : AppImage.defaultImage,
            name: item.first_name + " " + item.last_name,
            rating: item.average_rating || 0,
            tickTrue: false,
          });
        });
        localStorage.setItem("tempArr", JSON.stringify(modifiedRes));
        setCurrentlyActive(modifiedRes);
        dispatch(stopLoading());
      },
      fail: (resf) => {
        console.log(resf);
      },
    };
    isInternetConnected() && dispatch(getWorkersValidatorsList(payload));
  }, [pageNum, min, max, languageVal, isModalVisible]); //selectedIds
  useEffect(() => {
    if (RemovedselectedIds) setselectedIds(RemovedselectedIds);
    if (RemovedaddedGrpMembers) setAddedGroupMembers(RemovedaddedGrpMembers);
  }, [RemovedaddedGrpMembers, RemovedselectedIds]);
  return (
    <div>
      {/* add worker modal */}
      <Modal
        title={t("add_wrkr")}
        open={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
          hideWorkerModal(addedGroupMembers, inviteWorkerEmails, selectedIds);
        }}
        wrapClassName="poolworkers"
        className={
          localStorage.lang == "ar" ? " new_layout ar star-right" : " new_layout en "
        }
        footer={null}
        width={923}
        centered
      >
        {/* <form noValidate onSubmit={formik.handleSubmit}> */}
        <div className="text-center">
          <div className="wrapper_line models_data">
            {/* select from currently active */}
            <div className="form-group new_pool">
              <label>{t("select_crntly_active")}</label>
              <input
                type="button"
                className={
                  localStorage.lang == "en"
                    ? "form-control"
                    : "form-control select_from_pool_of_validator ar"
                }
                value={t("select_crntly_active")}
                autoComplete="off"
                onClick={() => {
                  setIsModalVisibleWorker(true);
                  setPageNum(0);
                }}
              />
            </div>

            {/* added workers list */}
            <div className="form-group">
              <label>{t("wrkr_list")}</label>
              {addedGroupMembers.length != 0 ? (
                <div className="inner_box_box">
                  <Table
                    dataSource={addedGroupMembers}
                    columns={columns}
                    showSorterTooltip={false}
                  />
                </div>
              ) : (
                <EmptyData />
              )}
            </div>

            {/* Previously invited workers through email will appear here. */}
            <div className="form-group">
              <label>{t("prev_invtd_wrkrs")}</label>
              {invitedWorkers.length != 0 ? (
                <div className="inner_box_box">
                  <div
                    style={{
                      height: "fit-content",
                      padding: "2px",
                      fontSize: "14px",
                      color: "#1a3f68",
                      fontWeight: "500",
                      marginInline: "17px",
                      borderRadius: "6px",
                    }}
                  >
                    <table>
                      <tbody>
                        {invitedWorkers.map((item) => {
                          return (
                            <tr className="top_bar" key={item}>
                              <td>{item.email}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <CustomEmptyDiv title={t("prev_invtd_wrkrs_appear_here")} />
              )}
            </div>
            {/* Add worker through email */}
            <div className="form-group email_selecter">
              <label>{t("add_wrkr_thr_email")}</label>
              <Select
                mode="tags"
                tokenSeparators={[","]}
                style={{ width: "100%" }}
                value={inviteWorkerEmails}
                onChange={(val) => {
                  let prevVal = [...inviteWorkerEmails];

                  let hasit =
                    val.length != 0
                      ? testIncludes(prevVal, val[val.length - 1])
                      : true;

                  if (
                    (val.length == 1 && validateEmail(val[val.length - 1])) ||
                    (!hasit && validateEmail(val[val.length - 1]))
                  ) {
                    setInviteWorkerEmails(val);
                  } else if (val.length === 0) {
                    setInviteWorkerEmails(val);
                  } else {
                    if (hasit && validateEmail(val[val.length - 1]))
                      toast.error(t("email_already_entered"), {
                        className:
                          localStorage.lang == "ar"
                            ? "Select_data ar"
                            : "Select_data en",
                        position:
                          localStorage.lang == "ar"
                            ? toast.POSITION.TOP_LEFT
                            : toast.POSITION.TOP_RIGHT,
                        toastId: "wrongEmail",
                      });
                    if (validateEmail(val[val.length - 1]) == false)
                      toast.error(t("entr_vld_email"), {
                        className:
                          localStorage.lang == "ar"
                            ? "Select_data ar"
                            : "Select_data en",
                        position:
                          localStorage.lang == "ar"
                            ? toast.POSITION.TOP_LEFT
                            : toast.POSITION.TOP_RIGHT,
                        toastId: "wrongEmail",
                      });
                  }
                }}
                onKeyDown={() => {
                  if (localStorage.getItem("lang") == "ar") {
                    setTimeout(() => {
                      const ele = document.querySelectorAll(
                        ".rc-virtual-list-holder-inner"
                      );
                      ele.forEach((element) => {
                        element.classList.add("new_layout");
                        element.classList.add("ar");
                      });
                    }, 10);
                  } else {
                    const ele = document.querySelectorAll(
                      ".rc-virtual-list-holder-inner"
                    );
                    ele.forEach((element) => {
                      element.classList.remove("new_layout");
                      element.classList.remove("ar");
                    });
                  }
                }}
                onClick={() => {
                  if (localStorage.getItem("lang") == "ar") {
                    setTimeout(() => {
                      const ele = document.querySelectorAll(
                        ".rc-virtual-list-holder-inner"
                      );
                      ele.forEach((element) => {
                        element.classList.add("new_layout");
                        element.classList.add("ar");
                      });
                    }, 10);
                  } else {
                    const ele = document.querySelectorAll(
                      ".rc-virtual-list-holder-inner"
                    );
                    ele.forEach((element) => {
                      element.classList.remove("new_layout");
                      element.classList.remove("ar");
                    });
                  }
                }}
                placeholder={t("add_wrkr_thr_email_adrs")}
                className="owner-grpManagement-add-worker-email"
              >
                {childrenEmail}
              </Select>
            </div>

            {/* add worker button its function appear at onSubmit method see above*/}
            <div className="text-center  model1">
              <button
                // type="submit"
                onClick={() => { onSubmit() }}
                className="button-list top_new color_new pt-50 bottom_button sp focus"
              >
                {t("add")}
              </button>
            </div>
            <div></div>
          </div>
        </div>

        {/* </form> */}

        {/* remove modal */}
        <Modal
          title={t("remove")}
          open={isModalRemove}
          onOk={() => setIsModalRemove(false)}
          onCancel={() => setIsModalRemove(false)}
          footer={null}
          // className="success_data"
          centered
        >
          {/* remove title */}
          <div className="text-center  model1">
            <p>{t("u_sure_to_rmv_this_usr")}</p>
          </div>
          <div
            className="text-center  model1"
            style={{
              direction: localStorage.getItem("lang") == "ar" ? "rtl" : "ltr",
            }}
          >
            {/* yes button */}
            <button
              type="button"
              className="button-list top_new focus"
              onClick={() => {
                const tempSelectedIds = [...selectedIds];
                const tempAddedWorkerList = [...addedGroupMembers];
                tempSelectedIds.splice(tempSelectedIds.indexOf(removalId), 1);

                let indexx;
                for (
                  let index = 0;
                  index < tempAddedWorkerList.length;
                  index++
                ) {
                  const element = tempAddedWorkerList[index];
                  if (element.id == removalId) {
                    indexx = index;
                    break;
                  }
                }
                if (indexx > -1) {
                  // only splice tempAddedWorkerList when item is found
                  tempAddedWorkerList.splice(indexx, 1); // 2nd parameter means remove one item only
                }
                setAddedGroupMembers(tempAddedWorkerList);

                setselectedIds(tempSelectedIds);
                setIsModalRemove(false);
              }}
            >
              {t("yes")}
            </button>
            {/* no button */}
            <button
              type="button"
              className="focus"
              onClick={() => setIsModalRemove(false)}
            >
              {t("no")}
            </button>
          </div>
        </Modal>
      </Modal>

      {/* currently active worker modal*/}
      <Modal
        title={t("crntly_active")}
        open={isModalVisibleWorker}
        onOk={() => {
          setIsModalVisibleWorker(false);
          localStorage.removeItem("selectedFromPool");
        }}
        onCancel={() => {
          let temp = [];
          addedGroupMembers.map((item) => {
            temp.push(item.id);
          });
          setselectedIds(temp);
          setIsModalVisibleWorker(false);
          localStorage.removeItem("selectedFromPool");
        }}
        wrapClassName="poolworkers"
        footer={null}
        className={
          localStorage.lang == "ar" ? "Select_data  ar" : "Select_data  en"
        }
        width={646}
        centered
      >
        <div className="text-center  model1">
          <div className="form-group wrapper_line models">
            <div className="new_new_data">
              <div
                className="top_data_1"
                style={{
                  direction:
                    localStorage.getItem("lang") == "en" ? "ltr" : "rtl",
                }}
              >
                <Select
                  placeholder={t("lang")}
                  onChange={(val) => {
                    setLanguageVal(val);
                    setPageNum(0);
                  }}
                  value={languageVal}
                  onClick={() => {
                    if (localStorage.getItem("lang") == "ar") {
                      setTimeout(() => {
                        const ele = document.querySelectorAll(
                          ".rc-virtual-list-holder-inner"
                        );
                        ele.forEach((element) => {
                          element.classList.add("new_layout");
                          element.classList.add("ar");
                        });
                      }, 10);
                    } else {
                      const ele = document.querySelectorAll(
                        ".rc-virtual-list-holder-inner"
                      );
                      ele.forEach((element) => {
                        element.classList.remove("new_layout");
                        element.classList.remove("ar");
                      });
                    }
                  }}
                >
                  {languageChildren.map((item) => {
                    return <Option key={item} value={item.value}>{item.name}</Option>;
                  })}
                </Select>
                <Select
                  placeholder={t("rating")}
                  value={ratingVal}
                  onChange={(val) => {
                    setMin(val.split("-")[0]);
                    setMax(val.split("-")[1]);
                    setRatingVal(val);
                    setPageNum(0);
                    if (localStorage.getItem("lang") == "ar") {
                      document.querySelectorAll(
                        ".rc-virtual-list"
                      )[0].style.direction = "rtl";
                    } else {
                      document.querySelectorAll(
                        ".rc-virtual-list"
                      )[0].style.direction = "ltr";
                    }
                  }}
                  onClick={() => {
                    if (localStorage.getItem("lang") == "ar") {
                      setTimeout(() => {
                        const ele = document.querySelectorAll(
                          ".rc-virtual-list-holder-inner"
                        );
                        ele.forEach((element) => {
                          element.classList.add("new_layout");
                          element.classList.add("ar");
                        });
                      }, 10);
                    } else {
                      const ele = document.querySelectorAll(
                        ".rc-virtual-list-holder-inner"
                      );
                      ele.forEach((element) => {
                        element.classList.remove("new_layout");
                        element.classList.remove("ar");
                      });
                    }
                  }}
                >
                  <Option value="0-1">0-1</Option>
                  <Option value="1-2">1-2</Option>
                  <Option value="2-3">2-3</Option>
                  <Option value="3-4">3-4</Option>
                  <Option value="4-5">4-5</Option>
                </Select>
              </div>
              <label className="label_data">{t("select_crntly_active")}</label>
              {currentlyActive.length > 0 ? (
                <>
                  <div className="data_news poolworkers_ppop">
                    {currentlyActive.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="sperator_pool"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const tempSelectedIds = [...selectedIds];
                            const tempAddedWorkerList = [...addedGroupMembers];
                            if (tempSelectedIds.indexOf(item.id) == -1) {
                              tempSelectedIds.push(item.id);

                              tempAddedWorkerList.push(item);
                              setAddedGroupMembers(tempAddedWorkerList);

                              setselectedIds(tempSelectedIds);
                            } else {
                              tempSelectedIds.splice(
                                tempSelectedIds.indexOf(item.id),
                                1
                              );

                              let indexx;
                              for (
                                let index = 0;
                                index < tempAddedWorkerList.length;
                                index++
                              ) {
                                const element = tempAddedWorkerList[index];
                                if (element.id == item.id) {
                                  indexx = index;
                                  break;
                                }
                              }
                              if (indexx > -1) {
                                // only splice tempAddedWorkerList when item is found
                                tempAddedWorkerList.splice(indexx, 1); // 2nd parameter means remove one item only
                              }
                              setAddedGroupMembers(tempAddedWorkerList);
                              setselectedIds(tempSelectedIds);
                            }
                          }}
                        >
                          {/* worker profile img */}

                          <div className="first_text">
                            <img
                              src={item.profile}
                              style={{
                                width: "27px",
                                height: "27px",
                                borderRadius: "27px",
                              }}
                            ></img>
                          </div>
                          {/* worker name */}

                          <div
                            className="first_text"
                            style={{
                              width: "215px",
                              paddingLeft: "5px",
                              textAlign: "start",
                              paddingRight: "5px",
                            }}
                          >
                            {item.name}
                          </div>

                          {/* rating  */}
                          <div>
                            <Rate
                              style={{
                                fontSize: "20px",
                              }}
                              className="edit-group-star"
                              allowHalf
                              value={item.rating}
                              disabled={true}
                            />
                            {/* <img src={AppImage.star}></img> */}
                          </div>

                          {selectedIds.map((items, index) =>
                            items == item.id ? (
                              <div
                                key={index}
                                className={
                                  localStorage.getItem("lang") == "en"
                                    ? "star_new"
                                    : "star_new ar"
                                }
                              >
                                <img src={AppImage.tick}></img>
                              </div>
                            ) : null
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <Pagination
                    showTitle={false}
                    style={{
                      paddingTop: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      left: "0",
                      right: "0",
                    }}
                    className={
                      localStorage.lang == "ar"
                        ? "pagination-component ar"
                        : "pagination-component"
                    }
                    defaultCurrent={1}
                    current={pageNum}
                    total={totalUsers}
                    size="medium"
                    onChange={(item) => {
                      setPageNum(item);
                    }}
                  />
                </>
              ) : (
                <CustomEmptyDiv marginTop={"30px"} title={t("no_user_found")} />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

AddWorkerModal.propTypes = {
  show: PropTypes.bool,
  hideWorkerModal: PropTypes.func,
  RemovedaddedGrpMembers: PropTypes.array,
  RemovedselectedIds: PropTypes.array,
  groupId: PropTypes.number,
  grpName: PropTypes.string,
};

export default AddWorkerModal;
